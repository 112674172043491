import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ButtonDefault from "../buttonDefault.component";
import classNames from "classnames";
import CustomLink from "../link.component";
import { makeStyles } from "mui-styles";
import RemoveIcon from "../removeIcon.component";
import { AddCircleOutlineOutlined, RemoveCircleOutlineOutlined } from "@mui/icons-material";
import { downloadFileByRef } from "services/util/file.utils"
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import { Prompt } from "react-router";
import { acceptableExtensionTypes } from "constants/file.constants";
import Tooltip from "components/utils/tooltip.component";
import TextField from "components/utils/form-elements/textField.component";
import { isReadOnly } from "utils/roles.utils";

const useStyles = makeStyles(theme => {
  const itemSeparatorStyles = {
    borderTop: `1px solid ${theme.palette.border.main}`,
    marginTop: 4,
    paddingTop: 0,
  };
  const itemSeparatorDenseStyles = {
    borderTop: `1px solid ${theme.palette.border.main}`,
    marginTop: 0,
    paddingTop: 0,
  };
  return {
    addButtonWrapper: {
      display: "flex",
      marginTop: 4,
      marginBottom: 4
    },
    addButtonWrapperHorizontal: {
      [theme.breakpoints.up("lg")]: {
        alignSelf: "center",
        order: -1,
        marginRight: 32
      }
    },
    addIcon: {
      fontSize: theme.typography.body1.fontSize,
      marginRight: 4,
    },
    container: {
      marginBottom: 20,
    },
    containerDense: {
      marginBottom: 0
    },
    containerHorizontal: {
      [theme.breakpoints.up("lg")]: {
        display: "flex",
      }
    },
    deletedNote: {
      display: "inline-flex",
      alignItems: "center",
      fontStyle: "italic",
      wordBreak: "normal"
    },
    fileLink: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
      marginTop: 4,
      paddingRight: 16,
    },
    fileLinkDense: {
      marginTop: 2,
      marginBottom: 2
    },
    fileLinkNoWrap: {
      whiteSpace: "nowrap"
    },
    linkContentContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    linkItem: {
      display: "flex",
      alignItems: "center",
      paddingRight: 8,
      "&:not(:first-of-type)": itemSeparatorStyles,
      "&:hover": {
        "& $attachmentIcon": {
          fill: theme.palette.primary.light,
        },
      },
    },
    linkItemDense: {
      lineHeight: 1.2,
      "&:not(:first-of-type)": itemSeparatorDenseStyles,
    },
    linkItemLink: {
      display: "flex",
      alignItems: "center",
      flex: 1,
      width: "calc(100% - 8px)",
      paddingRight: 8,
      "&:hover": {
        "& $attachmentIcon": {
          fill: theme.palette.primary.light,
        },
      },
    },
    linkItemContent: {
      width: "100%",
      paddingRight: 16
    },
    undoLink: {
      lineHeight: "1.5",
      marginLeft: 4,
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      textDecoration: "underline",
      pointerEvents: "all",
      wordBreak: "normal",
      cursor: "pointer"
    },
    titleTextField: {
      maxWidth: 400,
      width: "100%",
      paddingRight: 24
    },
    titleTextInput: {
      paddingBottom: 4
    },
    titleTextInputDense: {
      paddingTop: 2,
      paddingBottom: 2
    },
    attachmentIcon: {
      fontSize: theme.typography.h4.fontSize,
      marginRight: 8,
      fill: theme.palette.secondary.dark,
      display: "inline-block",
    },
    attachmentIconDeleted: {
      fill: theme.palette.grey.medium
    },
    removeIcon: {
      marginLeft: "auto"
    },
    uploading: {
      pointerEvents: "none",
      opacity: 0.5
    },
    uploadsList: {
      listStyle: "none",
      padding: 0,
      marginTop: 0,
      marginBottom: 16
    },
    uploadsListDense: {
      marginBottom: 0
    },
     //warning not being applied correctly if defined higher in the styles object
    warning: {
      color: theme.palette.text.error,
      fontSize: theme.typography.body2.fontSize,
      fontWeight: "bold",
      lineHeight: theme.typography.body2.lineHeight,
    },
    uploadsListWithSeparator: itemSeparatorStyles
  }
});

/*
 * The DataTransfer constructor allows adding/removing individual Files
 *   from the form's FileList. Browser support for this isn't perfect,
 *   so when not supported, the user has to use the native functionality,
 *   only adding all or removing all at once.
 */
const isDataTransferSupported = (() => {
  try {
    new DataTransfer();
    return true;
  } catch (error) {
    return false;
  }
})();

const initialDataTransfer = (
  isDataTransferSupported ? new DataTransfer() : null
);

const getFileRowName = fileRow => (
  fileRow.referenceName || fileRow.fileName
);


/*
 * MultiFileUploadInput, but with editable title fields, acting like nicknames
 */
export default function TitledMultiFileUploadInput(props) {
  // Form field props
  const {
    disabled, name, errorMessage: errorMessageProp
  } = props;
  // General props
  const {
    buttonColor, children, className, dense, horizontal,
    inputFileButtonDisabled, fileNamePrefix, validExtensions, wrapFileName
  } = props;
  // Handling for nav/saving page with unsaved file changes
  const {
    setHasUnsavedFileChanges, promptMessage, currentPathSubstring
  } = props;
  // Uploaded file display and delete
  const {
    deletedFiles, deleteDisabled, editDisabled, isUploading,
    onChange = null, onDelete, onDeleteUndo,
    onDownloadFile, onTitleAutosave, uploadedFiles,
    uploadedFileTitleColumnName = "title"
  } = props;
  // Props only sent by AttachementsUploader
  const {
    textFieldProps
  } = props;
  const classes = useStyles();
  const fileInputRef = useRef();

  const [dataTransfer, setDataTransfer] = useState(initialDataTransfer);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isClearing, setIsClearing] = useState(false);
  const hasUploadedFiles = !!uploadedFiles?.length;
  const hasPendingFiles = !!fileInputRef.current?.files?.length;
  const nothingToSave = (
    fileInputRef.current &&
    !fileInputRef.current.files.length &&
    !deletedFiles?.length
  );

  const isReadOnlyUser = useMemo(isReadOnly, []);

  useEffect(() => {
    if (setHasUnsavedFileChanges) {
      if (!nothingToSave) {
        setHasUnsavedFileChanges(true)
      } else {
        setHasUnsavedFileChanges(false)
      }
    }
  }, [setHasUnsavedFileChanges, nothingToSave])


  // Render blank value on file input for one render to clear files
  useEffect(() => {
    if ((isClearing || isUploading) && dataTransfer.items.length) {
      setDataTransfer(initialDataTransfer);
    }
    if (isClearing) {
      setIsClearing(false);
    }
  }, [isClearing, isUploading, dataTransfer]);

  const handleFileInputChange = useCallback((event) => {
    let chosen = null;
    if (event.target.files instanceof File) {
      chosen = [event.target.files];
    } else {
      chosen = Array.from(event.target.files);
    }

    const isExtensionValid = chosen.every(file => (
      !validExtensions?.length ||
      validExtensions.includes(file.name.replace(/^.+\./i, ""))
    ));
    if (!isExtensionValid) {
      const extensionString = validExtensions.join(", ");
      if (validExtensions.length === 1) {
        setErrorMessage(`Must be a ${extensionString} file.`);
      } else {
        setErrorMessage(
          `File extension must be one of the following: ${extensionString}`
        );
      }
      return;
    }

    const uploadedFileNames = (
      uploadedFiles?.map?.(file => getFileRowName(file)) || []
    );

    // Handle natively instead
    if (!isDataTransferSupported) {
      const invalidFiles = chosen.filter(file => (
        uploadedFileNames.includes(file.name) ||
        uploadedFileNames.includes(`${fileNamePrefix || ""}${file.name}`)
      ));
      if (invalidFiles.length) {
        const invalidNames = invalidFiles.map(file => file.name).join(", ");
        setErrorMessage(`Cannot upload duplicate file names: "${invalidNames}".`);
        setIsClearing(true);
      }
      onChange?.(chosen);
      return;
    }

    const dataTransferFileNames = (
      Array.from(dataTransfer.files || []).map(file => file.name)
    );

    chosen = chosen.filter(file => (
      !uploadedFileNames.includes(file.name) &&
      !uploadedFileNames.includes(`${fileNamePrefix || ""}${file.name}`) &&
      !dataTransferFileNames.includes(file.name) &&
      !dataTransferFileNames.includes(`${fileNamePrefix || ""}${file.name}`)
    ));
    if (!chosen.length) {
      // Reset input.files to dataTransfer.files
      const newDataTransfer = new DataTransfer();
      for (const file of dataTransfer.files) {
        newDataTransfer.items.add(file);
      }
      event.target.files = newDataTransfer.files;
      return;
    }

    /*
     * Separate instances needed so that FileList isn't shared between
     * ref and state. That would cause ref (<input>) changes to affect
     * component state outside of React.
     */
    const refDataTransfer = new DataTransfer();
    const stateDataTransfer = new DataTransfer();
    for (const file of dataTransfer.files) {
      let namedFile = file;
      if (fileNamePrefix && (!file.name.includes(fileNamePrefix))) {
        namedFile = new File([file], `${fileNamePrefix || ""}${file.name}`, {
          type: file.type,
          lastModified: file.lastModified,
        });
      }
      refDataTransfer.items.add(namedFile);
      stateDataTransfer.items.add(namedFile);
    }
    for (const file of chosen) {
      let namedFile = file;
      if (fileNamePrefix && (!file.name.includes(fileNamePrefix))) {
        namedFile = new File([file], `${fileNamePrefix || ""}${file.name}`, {
          type: file.type,
          lastModified: file.lastModified,
        });
      }
      refDataTransfer.items.add(namedFile);
      stateDataTransfer.items.add(namedFile);
    }
    event.target.files = refDataTransfer.files;
    setDataTransfer(stateDataTransfer);
    onChange?.(chosen);
  }, [
    dataTransfer, fileNamePrefix, onChange, uploadedFiles, validExtensions
  ]);

  const handleRemoveOne = useCallback((event, file) => {
    event.preventDefault();

    /*
     * Rebuild a new DataTransfer without "removed" item.
     * The existing DataTransfer can't be used here due to API constraints.
     * Separate instances needed for same reason described in change handler
     */
    const refDataTransfer = new DataTransfer();
    const stateDataTransfer = new DataTransfer();
    for (const currentFile of dataTransfer.files) {
      if (currentFile !== file) {
        refDataTransfer.items.add(currentFile);
        stateDataTransfer.items.add(currentFile);
      }
    }
    fileInputRef.current.files = refDataTransfer.files;
    setDataTransfer(stateDataTransfer);
  }, [dataTransfer, fileInputRef])

  const handleRemoveAll = () => {
    setIsClearing(true);
  }

  const handleTitleAutosave = useCallback(async (_name, value, event) => {
    if (!onTitleAutosave) {
      return;
    }
    const fileRef = event.target.dataset.fileRef;
    return onTitleAutosave("title", value, fileRef, event);
  }, [onTitleAutosave])

  return (
    <div className={classNames(isUploading && classes.uploading)}>
      {(!!errorMessage || !!errorMessageProp) && (
        <div className={classes.warning}>
          {errorMessage || errorMessageProp}
        </div>
      )}
      <div
        className={
          classNames(
            classes.container,
            dense && classes.containerDense,
            horizontal && classes.containerHorizontal,
            className
          )
        }
      >
        <div className={classNames(classes.content)}>
          {!!hasUploadedFiles && (
            <ul
              className={
                classNames(
                  classes.uploadsList,
                  dense && classes.uploadsListDense
                )
              }
            >
              {uploadedFiles.map((file, index) => {
                const isDeleted = !!deletedFiles?.includes(file);
                const isFieldDisabled = (
                  isDeleted || isReadOnlyUser ||
                  disabled || editDisabled
                );
                return (
                  <li
                    className={
                      classNames(
                        classes.linkItem,
                        dense && classes.linkItemDense
                      )
                    }
                    key={file.fileRef}
                    data-cy={`uploaded-file-${index}`}
                  >
                    <div className={classes.linkItemLink}>
                      <AttachmentOutlinedIcon
                        className={
                          classNames(
                            classes.attachmentIcon,
                            isDeleted && classes.attachmentIconDeleted
                          )
                        }
                      />
                      <div className={classes.linkItemContent}>
                        <TextField
                          name={`title-${file.fileRef}`}
                          defaultValue={file[uploadedFileTitleColumnName]}
                          placeholder={
                            !isFieldDisabled &&
                            (textFieldProps?.placeholder || "Title (optional)")
                          }
                          variant="standard"
                          disabled={isFieldDisabled}
                          className={
                            classNames(
                              classes.titleTextField,
                              textFieldProps?.className
                            )
                          }
                          inputProps={{
                            "data-file-ref": file.fileRef,
                            ...(textFieldProps?.inputProps || {}),
                            className: classNames(
                              classes.titleTextInput,
                              textFieldProps?.inputProps?.className,
                              dense && classes.titleTextInputDense
                            )
                          }}
                          onAutosave={handleTitleAutosave}
                        />
                        <Tooltip
                          title={wrapFileName ? undefined : file.fileName}
                          enterDelay={2000}
                        >
                          <div>
                            <CustomLink
                              onClick={() => (
                                typeof onDownloadFile === "function" ?
                                  onDownloadFile?.(file) :
                                  downloadFileByRef(file.fileRef, getFileRowName(file))
                              )}
                              variant="noHRef"
                              className={
                                classNames(
                                  classes.fileLink,
                                  dense && classes.fileLinkDense,
                                  !wrapFileName && classes.fileLinkNoWrap
                                )
                              }
                              disabled={isDeleted}
                              containerClassName={
                                classNames(classes.linkContentContainer)
                              }
                            >
                              {getFileRowName(file)}
                              {!!isDeleted && (
                                <span className={classNames(classes.deletedNote)}>
                                  &nbsp;(deleted)
                                </span>
                              )}
                            </CustomLink>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    {isDeleted ? (
                      <span
                        className={classNames(classes.undoLink)}
                        onClick={event => {
                          event.stopPropagation();
                          onDeleteUndo(file);
                        }}
                        data-cy={`undo-remove-upload-${index}`}
                      >
                        Undo
                      </span>
                    ) : (
                      !disabled && !deleteDisabled && (
                        <RemoveIcon
                          aria-label="Remove"
                          data-name={getFileRowName(file)}
                          onClick={event => {
                            event.stopPropagation();
                            onDelete(file);
                          }}
                          test={`remove-upload-${index}`}
                        />
                      )
                    )}
                  </li>
                );
              })}
            </ul>
          )}
          {!!hasPendingFiles && (
            <ul
              className={classNames(
                classes.uploadsList,
                dense && classes.uploadsListDense,
                hasPendingFiles && hasUploadedFiles && (
                  classes.uploadsListWithSeparator
                )
              )}
            >
              {Array.from(fileInputRef.current.files).map((file, index) => {
                const fileDisplayName = (
                  fileNamePrefix ?
                    file.name.replace(fileNamePrefix, "") :
                    file.name
                );
                return (
                  <li
                    className={
                      classNames(
                        classes.linkItem,
                        dense && classes.linkItemDense
                      )
                    }
                    key={file.name}
                    data-cy={`pending-file-${index}`}
                  >
                    <div className={classes.linkItemLink}>
                      <AttachmentOutlinedIcon
                        className={classes.attachmentIcon}
                      />
                      <div className={classes.linkItemContent}>
                        <TextField
                          name={`${textFieldProps?.name || "title"}-${fileDisplayName}`}
                          placeholder={
                            textFieldProps?.placeholder || "Title (optional)"
                          }
                          multiline={textFieldProps?.multiline}
                          variant="standard"
                          className={
                            classNames(
                              classes.titleTextField,
                              textFieldProps?.className
                            )
                          }
                          inputProps={{
                            ...(textFieldProps?.inputProps || {}),
                            className: classNames(
                              classes.titleTextInput,
                              textFieldProps?.inputProps?.className,
                              dense && classes.titleTextInputDense
                            )
                          }}
                        />
                        <Tooltip
                          title={wrapFileName ? undefined : fileDisplayName}
                          enterDelay={2000}
                        >
                          <div>
                            <CustomLink
                              href={file ? URL.createObjectURL(file) : ""}
                              target="_blank"
                              variant="iconLink"
                              className={
                                classNames(
                                  classes.fileLink,
                                  dense && classes.fileLinkDense,
                                  !wrapFileName && classes.fileLinkNoWrap
                                )
                              }
                              containerClassName={
                                classNames(classes.linkContentContainer)
                              }
                              disabled={disabled}
                            >
                              {fileDisplayName}
                            </CustomLink>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    {!!isDataTransferSupported && !disabled && (
                      <RemoveIcon
                        aria-label="Remove"
                        className={classes.removeIcon}
                        data-name={file.name}
                        onClick={event => handleRemoveOne(event, file)}
                        test={`remove-pending-${index}`}
                      />
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <div
          className={
            classNames(
              classes.addButtonWrapper,
              horizontal && classes.addButtonWrapperHorizontal
            )
          }
        >
          {disabled ? null : (
            <ButtonDefault
              component="label"
              variant="small"
              background={buttonColor || "white"}
              color={
                !buttonColor || buttonColor === "white" ? "secondary" : "white"
              }
              startIcon={(
                <AddCircleOutlineOutlined
                  className={classNames(classes.addIcon)}
                />
              )}
              disabled={disabled || inputFileButtonDisabled}
              disableReadOnlyUsers
              data-cy="btn-add-file"
            >
              <span>
                {children}
              </span>
              <input
                name={name}
                id={name}
                type="file"
                accept={acceptableExtensionTypes.toString()}
                ref={fileInputRef}
                style={{ display: "none" }}
                key={isUploading || isClearing ? new Date() : undefined}
                onChange={handleFileInputChange}
                data-cy="fileinput-attach-files"
                multiple
              />
            </ButtonDefault>
          )}
          {!isDataTransferSupported && (
            <ButtonDefault
              component="label"
              variant="small"
              background="red"
              onClick={handleRemoveAll}
              startIcon={(
                <RemoveCircleOutlineOutlined
                  className={classNames(classes.addIcon)}
                />
              )}
              disabled={(hasPendingFiles || disabled)}
              data-cy="btn-clear-all-files"
              disableReadOnlyUsers
            >
              <span>
                Clear all
              </span>
            </ButtonDefault>
          )}
          {!!promptMessage && (
            <Prompt
              when={nothingToSave === false}
              message={(location) => {
                if (location.pathname.includes(currentPathSubstring)) {
                  return;
                }
                return promptMessage
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}
