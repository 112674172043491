import React, { useContext, useEffect, useMemo, useRef } from "react";
import Loader from "components/utils/loader.components";
import { generatePath, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Grid } from "@mui/material";
import RemediationStatsCard from "components/consentOrder/shared/remediationStatsCard.component";
import ConsentOrderService from "services/ConsentOrder.service";
import StatisticsContext from "contexts/statistics.context";

const PLACEHOLDER_POPULATION_LABELS = [
  { name: "populationRecords", label: "Records from all Populations" },
  { name: "1", label: "Population --" },
  { name: "2", label: "Population --" },
  { name: "3", label: "Population --" }
];


const ConsentOrderDashboard = () => {
  const {
    state: { populations, summaryCounts }
  } = useContext(StatisticsContext);
  const { programId } = useParams();
  const statsRequestAbortRef = useRef();

  useEffect(() => (
    function cancelRequestOnUnmount() {
      if (statsRequestAbortRef.current) {
        ConsentOrderService.cancel(statsRequestAbortRef.current)
      }
    }
  ), []);

  const labels = useMemo(() => ({
    populationSummary: (
      !populations ? PLACEHOLDER_POPULATION_LABELS : ([
        { name: "populationRecords", label: "Records from all Populations" },
        ...Object.keys(populations).sort().map(population => (
          { name: population, label: `Population ${population} Claims` }
        ))
      ])
    ),
    fileTransfers: [
      { name: "fileTransfers", label: "Files received" },
      { name: "fileTransferAttachments", label: "Supporting Documents" }
    ],
    claims: [
      { name: "customers", label: "Customer Registrations" },
      { name: "claimsSubmitted", label: "Claims submitted" },
      { name: "claimsPaid", label: "Claims paid" },
    ],
    initialPaymentWorkflow: [
      { name: "distributionPendingPayments", label: "Uncashed Checks sent" },
      { name: "distributionPaidPayments", label: "Checks cashed" },
      { name: "initialDistributions", label: "Total initial Distributions" }
    ],
    statusReporting: [
      { name: "nextReport", label: "Next Report" },
      { name: "lastReport", label: "Last Report" }
    ],
    findingsConclusions: [
      { name: "draftFindings", label: "Draft Findings" },
      { name: "draftConclusions", label: "Draft Conclusions" },
    ]
  }), [populations]);

  const populationsAsStats = useMemo(() => (
    !populations ? {} : Object.entries(populations).reduce(
      (accumulator, [name, population]) => ({
        ...accumulator,
        [name]: { count: population.distributionCount },
      }),
      { populationRecords: { count: summaryCounts?.populationRecords?.count }}
    )
  ), [populations, summaryCounts?.populationRecords])

  const populationPath = useMemo(() => (
    generatePath(
      "/home/program/:programId/data-analysis/populations",
      { programId }
    )
  ), [programId]);

  const fileTransferPath = useMemo(() => (
    generatePath("/home/program/:programId/file-transfers", { programId })
  ), [programId]);

  const claimsPath = useMemo(() => (
    generatePath("/home/program/:programId/claims", { programId })
  ), [programId]);

  const initialPaymentsPath = useMemo(() => (
    generatePath("/home/program/:programId/data-analysis", { programId })
  ), [programId]);

  const statusReportingPath = useMemo(() => (
    generatePath("/home/program/:programId/status-reporting", { programId })
  ), [programId]);

  const findingsLinkPath = useMemo(() => (
    generatePath(
      "/home/program/:programId/findings-conclusions",
      { programId }
    )
  ), [programId]);

  if (!labels) {
    return <Loader />;
  }
  return (
    <Grid container spacing={2} paddingBottom={10}>
      <SummaryCardsGridItem>
        <RemediationStatsCard
          title="Population Summary"
          statsValues={populationsAsStats}
          statsLabels={labels.populationSummary}
          path={populationPath}
        />
      </SummaryCardsGridItem>

      <SummaryCardsGridItem>
        <RemediationStatsCard
          title="File Transfers"
          statsValues={summaryCounts}
          statsLabels={labels.fileTransfers}
          path={fileTransferPath}
        />
      </SummaryCardsGridItem>

      <SummaryCardsGridItem>
        <RemediationStatsCard
          title="Claims"
          statsValues={summaryCounts}
          statsLabels={labels.claims}
          path={claimsPath}
        />
      </SummaryCardsGridItem>

      <SummaryCardsGridItem>
        <RemediationStatsCard
          title="Initial Distributions"
          statsValues={summaryCounts}
          statsLabels={labels.initialPaymentWorkflow}
          path={initialPaymentsPath}
        />
      </SummaryCardsGridItem>

      <SummaryCardsGridItem>
        <RemediationStatsCard
          title="Status Reporting"
          statsValues={summaryCounts}
          statsLabels={labels.statusReporting}
          path={statusReportingPath}
        />
      </SummaryCardsGridItem>

      <SummaryCardsGridItem>
        <RemediationStatsCard
          title="Findings - Conclusions"
          statsValues={summaryCounts}
          statsLabels={labels.findingsConclusions}
          path={findingsLinkPath}
        />
      </SummaryCardsGridItem>
    </Grid>
  );
};

export default ConsentOrderDashboard;

const SummaryCardsGridItem = ({ children }) => {
  return (
    <Grid item xl={3} lg={4} md={6} xs={12}>
      {children}
    </Grid>
  );
};
