const convertToFloat = value => {
  if (!value) {
    return 0;
  }
  if (typeof value === "number") {
    return value;
  }
  const newVal = value.replace(/[^\d.]/g, "");
  return Number(newVal);
};

const convertToInteger = value => {
  const number = convertToFloat(value);
  if (Number.isInteger(number)) {
    return number;
  }
  return Math.round(number);
};

const findMaxByField = (array, key) => {
  return Math.max.apply(
    Math,
    array.map((ele) => ele[key])
  );
};

const findMaxValueByField = (arr, fieldName) => {
  if (!arr.length) {
    return;
  }
  let max = arr[0][fieldName];
  for (const val of arr) {
    const compareValue = val[fieldName];
    if (compareValue > max) {
      max = compareValue;
    }
  }
  return max;
};

export {
  convertToFloat,
  convertToInteger,
  findMaxByField,
  findMaxValueByField,
};
