

/*
 * Cancel state updates if component has unmounted.
 *
 * When our service files and Axios implementation use AbortController,
 *   the ajax requests themselves can be cancelled directly instead.
 *
 * These can be chained, where the setStateFn can call this function again.
 *   The return value of the inner setStateFetchEffect call must be returned a
 */
export function setStateFetchEffect(
  fetchPromiseArray,
  setStateFn,
  errorSetStateFn = null
) {
  let isMounted = true;
  const getIsMounted = () => isMounted;

  async function fetchAndMaybeSetState() {
    try {
      const responses = await Promise.all(
        [].concat(fetchPromiseArray).map(promiseOrFn => (
          typeof promiseOrFn === "function" ? promiseOrFn() : promiseOrFn
        ))
      );
      if (isMounted) {
        setStateFn(responses, getIsMounted);
      }
    } catch (error) {
      if (isMounted && errorSetStateFn) {
        errorSetStateFn(error);
      }
      throw error;
    }
  }

  fetchAndMaybeSetState();
  return () => {
    isMounted = false;
  }
}
