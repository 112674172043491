import AjaxService from "services/base/Ajax.service.js";

class DataTypeServiceBase extends AjaxService {
  shouldExtractResponseData() {
    return true;
  }

  async getCustomerInfoMap(formData) {
    return this.get("/dataType/customerInfoMap", formData);
  }
}

const DataTypeService = new DataTypeServiceBase();
export default DataTypeService;
