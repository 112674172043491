export const BASE_PATH_AUTHENTICATED = "/home";

export const PATH_COMPONENT_OBJECT = (
  BASE_PATH_AUTHENTICATED +
  "/program/:programId/:programComponentId/:componentObjectId"
);
export const PATH_PROGRAM_COMPONENT = (
  BASE_PATH_AUTHENTICATED +
  "/program/:programId/:programComponentId"
);
export const PATH_PROGRAM = `${BASE_PATH_AUTHENTICATED}/program/:programId`;

export const PATHS_HOME = [BASE_PATH_AUTHENTICATED];

export const PATH_CLAIMS_BY_POPULATION = (
  `${PATH_PROGRAM_COMPONENT}/population`
);
export const PATH_CLAIMS_BY_CUSTOMER = (
  `${PATH_PROGRAM_COMPONENT}/customer`
);
export const PATH_CLAIMS_REVIEW_TRACKING = (
  `${PATH_PROGRAM_COMPONENT}/review-tracking`
);
export const PATH_CLAIM_RECEIPT_TRACKING = (
  `${PATH_PROGRAM_COMPONENT}/receiptTracking`
);
export const PATH_CLAIM_PAYMENTS = (
  `${PATH_PROGRAM_COMPONENT}/claimPayments`
);
export const PATH_CONTACT_CHANGES_TRACKING = (
  `${PATH_PROGRAM_COMPONENT}/contactChanges`
);

export const PATH_DISTRIBUTIONS_BY_POPULATION = (
  `${PATH_PROGRAM_COMPONENT}/populations`
)

export const PATH_DISTRIBUTIONS_BY_WORKFLOW = (
  `${PATH_PROGRAM_COMPONENT}/workflows`
)

export const PATHS_ADMIN = [
  `${BASE_PATH_AUTHENTICATED}/admin/program/:programId`,
  `${BASE_PATH_AUTHENTICATED}/admin`,
];

export const PATHS_DATA_ANALYSIS = [
  PATH_DISTRIBUTIONS_BY_WORKFLOW,
  PATH_DISTRIBUTIONS_BY_POPULATION
]

export const PATHS_SEARCH = [
  `${BASE_PATH_AUTHENTICATED}/search/program/:programId`,
  `${BASE_PATH_AUTHENTICATED}/search/program/:programId/:programComponentId`,
  `${BASE_PATH_AUTHENTICATED}/search/:programId/search`,
  `${BASE_PATH_AUTHENTICATED}/search/:programId/:programComponentId/search`,
  `${BASE_PATH_AUTHENTICATED}/search`
];

export const PATHS_TASKS = [
  `${BASE_PATH_AUTHENTICATED}/programTasks/:taskId/program/:programId`,
  `${BASE_PATH_AUTHENTICATED}/wpTasks/:wpId/program/:programId`,
  `${BASE_PATH_AUTHENTICATED}/programTasks`,
  `${BASE_PATH_AUTHENTICATED}/wpTasks`
];

export const PATHS_PROGRAMS_CLAIMS = [
  PATH_CLAIMS_BY_POPULATION,
  PATH_CLAIMS_BY_CUSTOMER,
  PATH_CLAIM_PAYMENTS,
  PATH_CLAIMS_REVIEW_TRACKING,
  PATH_CLAIM_RECEIPT_TRACKING,
  PATH_CONTACT_CHANGES_TRACKING
];

export const PATHS_PROGRAMS = [
  ...PATHS_PROGRAMS_CLAIMS,
  ...PATHS_DATA_ANALYSIS,
  PATH_COMPONENT_OBJECT,
  PATH_PROGRAM_COMPONENT,
  PATH_PROGRAM,
];

export const PATHS_HIDDEN_TITLE_SUBPAGES = [
  ...PATHS_PROGRAMS_CLAIMS,
  ...PATHS_DATA_ANALYSIS
]

export const STANDARD_PATHS_ALL = [
  ...PATHS_DATA_ANALYSIS,
  ...PATHS_PROGRAMS_CLAIMS,
  ...PATHS_PROGRAMS,
  ...PATHS_ADMIN,
  ...PATHS_SEARCH,
  ...PATHS_TASKS,
  ...PATHS_HOME,
];
