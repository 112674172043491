import FileTransferComponentObject from "components/pages/consentOrder/fileTransferSummary/fileTransferComponentObject.component";
import TestComponentObject from "components/pages/global/testComponentObject.component";
import ProgramDashboard from "components/types/programDashboard.component";
import ClaimsDashboard from "components/pages/consentOrder/claims/ClaimsDashboard.component";
import ClaimReviewPage from "components/claims/ClaimReviewPage.component";
import WorkflowsPage from "components/pages/workflows/WorkflowsPage.component";
import DataAnalysisPage from "components/dataAnalysis/DataAnalysisPage.component";
import UserMgmtCentral from "components/pages/admin/userMgmtCentral.component";


const registry = {
  /* DASHBOARDS */
  PrivDash: <ProgramDashboard />,
  OrchDash: <ProgramDashboard />,
  ClaimsDashboard: <ClaimsDashboard />,
  /* CUSTOM PROGRAM COMPONENTS  */
  UserManagement: <UserMgmtCentral />,

  /* CUSTOM COMPONENT OBJECTS */
  ClaimReview: <ClaimReviewPage />,
  FileTransfer: <FileTransferComponentObject />,
  Test: <TestComponentObject />,
  Workflows: <WorkflowsPage />,
  DataAnalysis: <DataAnalysisPage />

  /* PROGRAM-SPECIFIC COMPONENT OBJECTS */
}

const CustomComponent = (props) => {
  // Todo: Re-enable when navigation is pretty firmly established
  // if (isDevelopmentEnv && registry[props.typeRef] === undefined) {
  //   throw new Error(
  //     `Unrecognized Custom Component/ComponentObject TypeRef: ${props.typeRef}`
  //   );
  // }
  if (registry[props.typeRef] === undefined) {
    console.warn("No component object exists for program component.");
    return null;
  }
  return registry[props.typeRef] || "";
}

export { registry, CustomComponent }
