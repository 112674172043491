import { OutlinedInput } from "@mui/material";
import classNames from "classnames";
import { makeStyles } from "mui-styles";

const useStyles = makeStyles(theme => ({
  root: {
    "&.Mui-focused fieldset": {
      borderLeftWidth: "6px !important",
      padding: "4px !important",
    },
    "& fieldset": {
      borderColor: theme.palette.secondary.main,
      borderWidth: 2,
    },
    "&:hover fieldset": {
      borderColor: `${theme.palette.primary.main} !important;`,
    },
    "& input:valid + fieldset": {
      borderColor: theme.palette.secondary.main,
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: "6px !important",
      padding: "4px !important", // override inline-style
    },
    "& input:invalid + fieldset": {
      borderColor: `${theme.palette.error.light} !important;`,
      borderWidth: 2,
    },
  },
  denseInput: {
    "&:not(textarea)": {
      padding: `10px 12px`
    }
  },
  denseMultiline: {
    "&:not(textarea)": {
      padding: `10px 12px`
    }
  },
  disabled: {
    color: theme.palette.text.black,
    "&:hover fieldset": {
      borderColor: `${theme.palette.grey.disabled} !important`,
    },
  }
}));


export default function CustomOutlinedInput(props) {
  const classes = useStyles();
  const { classes: classesProp = {}, margin } = props;
  return (
    <OutlinedInput
      {...props}
      classes={{
        ...classesProp,
        root: classNames(classes.root, classesProp.root),
        // MUI prop not being applied for some reason
        input: margin === "dense" && classes.denseInput,
        multiline: margin === "dense" && classes.denseMultiline,
        disabled: classNames(classes.disabled, classesProp.disabled)
      }}
    />
  );
}
