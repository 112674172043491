import { Divider } from "@mui/material";
import SearchFormStandalone from "components/pages/consentOrder/claims/shared/SearchFormStandalone.component";
import SubpageHeader from "components/pages/consentOrder/claims/shared/SubpageHeader.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";

const COLUMN_SPACING_ROOT_GRID = { xs: 0, md: 4, lg: 8 };

export default function SubpageSearchbarHeader(props) {
  const { disabled, formName, onChange, onSubmit, placeholder, title } = props;
  return (
    <GridContainer
      columnSpacing={COLUMN_SPACING_ROOT_GRID}
      alignItems="end"
      justifyContent="space-between"
    >
      <GridItem xs={12} md lg={5} xl={7}>
        <SubpageHeader title={title} />
      </GridItem>
      <GridItem
        xs={12}
        md
        lg={7}
        xl={5}
        minWidth="480px"
        marginBottom={3.5}
      >
        <SearchFormStandalone
          name={formName}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          onSubmit={onSubmit}
          autoFocus
        />
      </GridItem>
      <GridItem xs={12} marginTop={0.5}>
        <Divider />
      </GridItem>
    </GridContainer>
  )
}
