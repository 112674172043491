import React, { useContext, useMemo } from "react";
import { makeStyles } from "mui-styles";
import { Grid } from "@mui/material";
import { Error } from "@mui/icons-material";
import ConsentOrderCard from "components/consentOrder/consentOrderCard.component";
import { H3 } from "components/utils/headerV2.component";
import ConsentOrderContext from "contexts/consentOrder.context";

const useStyles = makeStyles((theme) => ({
  mainContentContainer: {
    paddingTop: 16
  },
  noContentContainer: {
    display: "flex",
    alignItems: "center",
  },
  noContentIcon: {
    fontSize: theme.typography.subtitle1.fontSize,
    marginRight: 8,
  },
}));

export default function ConsentOrderCards() {
  const classes = useStyles();
  const { state } = useContext(ConsentOrderContext);

  const consentOrders = useMemo(() => (
    Object.values(state.consentOrdersById).sort((c1, c2) => (
      c1.title.localeCompare(c2.title)
    ))
  ), [state.consentOrdersById]);

  if (consentOrders?.length) {
    return (
      <>
        <H3>Remediations</H3>
        <Grid
          container
          spacing={3}
          className={classes.mainContentContainer}
          data-cy="remediation-cards"
        >
          {consentOrders.map((consentOrder) => (
            <Grid
              item
              xl={3}
              lg={4}
              md={6}
              xs={12}
              minWidth={400}
              key={consentOrder.consentOrderId}
              data-cy="remediation-card-wrapper"
            >
              <ConsentOrderCard
                consentOrder={consentOrder}
                key={consentOrder.consentOrderId}
              />
            </Grid>
          ))}
        </Grid>
      </>
    );
  } else {
    return (
      <div className={classes.noContentContainer}>
        <Error className={classes.noContentIcon} />
        <p>No Remediations are currently available.</p>
      </div>
    );
  }
}
