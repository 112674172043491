import React, { useState, useEffect, useMemo, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import SearchResult from "./searchResult.component";
import PageHeader from "components/utils/pageHeader.component";
import { makeStyles } from "mui-styles";
import CustomContainer from "components/utils/container.component";
import SearchService from "services/Search.service";
import Loader from "components/utils/loader.components";
import TitleMuiIcon from "components/utils/titleMuiIcon.component";
import { isGlobalUser } from "utils/roles.utils";
import CollapsableAlert from "components/utils/collapsableAlert.component";
import { contentTypeFilterOptions, searchFilterContentTypeMap } from "./constants/search.constants";
import SearchFilter from "./searchFilter.component";
import ProgramsContext from "contexts/programs.context";
import { sortByStringKey } from "utils/sort.utils";
import { PROGRAM_TYPE_DEFAULT } from "components/constants/program.constants";
import { pluralize } from "utils/string.utils";
import OrganizationContext from "contexts/organization.context";

const useStyles = makeStyles((theme) => ({
  searchResultsContainer: {
    paddingBottom: 5,
    margin: "0px auto",
  },
  searchAlertContent: {
    marginTop: 24
  },
  searchStatus: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 3,
    minHeight: 44,
    display: "flex",
    alignItems: "center"
  },
  searchTitle: {
    display: "block",
    color: "white",
    paddingLeft: 24,
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: 800,
    width: "100%",
  },
  resultsColumn: {
    flex: 1,
  }
}));

const makeInitialFilterParameters = () => {
  return contentTypeFilterOptions.reduce((accum, option) => {
    accum[option.name] = true;
    return accum;
  }, {});
};

const makeProgramOptions = (programs) => {
  if (!programs) {
    return;
  }
  let orchestrationOption;
  const optionList = Object.values(programs).reduce((accum, program) => {
    if (program.status === "Active") {
      if (program.type === PROGRAM_TYPE_DEFAULT) {
        orchestrationOption = {
          name: program.name,
          label: "Enterprise",
        };
      } else {
        accum.push({
          name: program.name,
          label: program.label,
        });

      }
    }
    return accum;
  }, []);
  const sortedOptions = sortByStringKey(optionList, "label");
  return orchestrationOption
    ? [orchestrationOption, ...sortedOptions]
    : sortedOptions;
};

const makeInitialProgramFilters = (programOptions) => {
  return programOptions.reduce((accum, option) => {
    accum[option.name] = true;
    return accum;
  }, {});
};

const SearchResultPage = () => {
  const classes = useStyles();
  const { state: programState } = useContext(ProgramsContext);
  const { state: organizationState } = useContext(OrganizationContext);
  const [results, setResults] = useState();
  const [isSearching, setIsSearching] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [categoryFilters, setCategoryFilters] = useState(
    makeInitialFilterParameters()
  );
  const [programFilters, setProgramFilters] = useState();
  const query = new URLSearchParams(useLocation().search);
  const search = query.get("keyword");

  const programFilterOptions = useMemo(() => (
    makeProgramOptions(programState.programs)),
      [programState.programs]);

  useEffect(() => {
    if (!programFilters && !!programFilterOptions) {
      setProgramFilters(makeInitialProgramFilters(programFilterOptions));
    }
  }, [programFilterOptions, programFilters]);

  useEffect(() => {
    const querySearch = async () => {
      setErrorMessage();
      /*
       * TODO: when Portfolio Program is searchable,
       *   we need to allow non-global users to retrieve results but refine
       *   them by the program scope
       */
      if (!isGlobalUser()) {
        setResults([]);
        setIsSearching(false);
        return;
      }
      try {
        setIsSearching(true)
        if (search) {
          const searchRes = await SearchService.getByKeyword(
            search,
            organizationState.activeOrganizationId
          );
          const searchPayload = searchRes.data.payload;
          // Can be removed it query on backend is updated to include program
          const scopedProgramResults = searchPayload.filter(result => (
            programState.validProgramIds.has(result.data.programId)
          ))
          setResults(scopedProgramResults);
        } else if (!search || search.trim() === "") {
          setResults();
        }
      } catch (error) {
        setErrorMessage("Search functionality is currently unavailable.");
      } finally {
        setIsSearching(false);
      }
    };

    querySearch();
  }, [search, programState, organizationState.activeOrganizationId]);

  const filteredResults = useMemo(() => {
    if (
      !Object.values({ ...categoryFilters, ...programFilters }).some(
        (parameter) => {
          return !parameter;
        }
      )
    ) {
      return results;
    } else {
      return results.filter((result) => {
        const hideContentTypeList = new Set(
          Object.keys(categoryFilters).filter(
            (parameterName) => !categoryFilters[parameterName]
          )
        );
        const hideProgramList = new Set(
          Object.keys(programFilters).filter(
            (parameterName) => !programFilters[parameterName]
          )
        );
        return (
          !hideContentTypeList.has(
            searchFilterContentTypeMap[result.data.contentType]
          ) && !hideProgramList.has(result.data.programId?.toString())
        );
      });
    }
  }, [categoryFilters, programFilters, results]);

  const message = useMemo(() => {
    if (!filteredResults) {
      return "Enter Search Above";
    }
    if (!filteredResults.length && !search) {
      return `No results for "${search}"`;
    } else {
      return `${filteredResults.length} ${pluralize("result", filteredResults.length)} for "${search}":`;
    }
  }, [filteredResults, search]);

  return (
    <CustomContainer className={classes.mainContentContainer}>
      <Toolbar />
      <PageHeader
        title="Search"
        titleOnly
        startIcon={<TitleMuiIcon variant="search" />}
      />
      <Box display="flex">
        {/* ==== FILTERS ==== */}
        <Box width="300px">
          {!!programFilters && (
            <SearchFilter
              categoryFilters={categoryFilters}
              setCategoryFilters={setCategoryFilters}
              setProgramFilters={setProgramFilters}
              programFilters={programFilters}
              programFilterOptions={programFilterOptions}
              error={!!errorMessage}
            />
          )}
        </Box>
        {/* ==== RESULTS ==== */}
        <Box className={classes.resultsColumn}>
          <div className={classes.searchStatus}>
            <div
              className={classes.searchTitle}
              data-cy="search-result-message"
            >
              {message}
            </div>
          </div>
          <CollapsableAlert
            showAlert={!!errorMessage}
            closeClick={setErrorMessage}
            message={errorMessage}
            contentClass={classes.searchAlertContent}
            severity="error"
          />
          {(!!isSearching || !programFilters) && <Loader variant="search" size={14} />}
          {filteredResults ? (
            <div
              className={classes.searchResultsContainer}
              data-cy="container-searchResults"
            >
              {filteredResults.map((result, index) => (
                <SearchResult
                  result={result}
                  key={result.id}
                  searchTerm={search}
                  resultProgram={result.data.programId ? programState.programs[result.data.programId] : null}
                  isLastResult={filteredResults.length === index + 1}
                />
              ))}
            </div>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </CustomContainer>
  );
};

export default SearchResultPage;
