import AxiosService from "./base/Axios.service";
import authHeader from "services/util/authHeader.util";

class BuilderServiceBase {

  // get all active Builder by type
  getAllActiveBuilderByType(type) {
    return AxiosService
      .get(
        `/builder/allActiveBuilderByType/${type}`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      });
  }

  getPublishedVersions(type) {
    return AxiosService
      .get(
        `/builder/publishedVersions/${type}`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      });
  }

  // get current ComponentObject Builder
  getCurrentCompObjectBuilder(compObjId) {
    const path = (
      `/builder/currentCompObjBuilder/` +
      compObjId
    );
    return AxiosService
      .get(path, { headers: authHeader() })
      .then((response) => response.data);
  }

  // get Builder by Builder_ID
  getBuilderById(builderId) {
    return AxiosService
      .get(
        `/builder/${builderId}`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      });
  }

  // get list of Previous ComponentObject Builders
  getListOfPreviousCompObjectBuilders(compObjId) {
    const path = (
      `/builder/previousCompObjBuilders/` +
      compObjId
    );
    return AxiosService
      .get(path, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get list of Builder Sections
  getBuilderSections(builderId) {
    return AxiosService
      .get(`/builder/builderSections/${builderId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get Framework Group Reference
  getFrameworkGroupRefs(tableRef, groupName, tableName) {
    return AxiosService
      .get(
        `/builder/framework/${tableRef}/group/${groupName}/${tableName}`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      });
  }

  // get list of RegFrameworks
  getRegFrameworks() {
    return AxiosService
      .get(`/builder/policy/regFrameworks`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  /*
   * add version as argument
   *  get list of Policy Template Group
   */
  getListPolicyTemplateGroup(objRef, version) {
    return AxiosService
      .get(
        `/builder/policy/templateGroup/${objRef}/${version}`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      });
  }

  // get Builder RegRefereces
  getBuilderRegRefs = (builderId) => {
    return AxiosService
      .get(`/builder/${builderId}/regRefs/`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  // get Practice Builder RegRefereces
  getPracticeBuilderRegRefs = (builderId) => {
    return AxiosService
      .get(`/builder/${builderId}/practiceRegRefs/`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

 // get list of Programs that have workpackages available
  getWpPrograms = () => {
    return AxiosService
      .get(
        `/builder/wp/programs`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      });
  }

  // update a builder section
  updateBuilderSection(builderSection, builderSectionId) {
    return AxiosService.put(
      `/builder/builderSections/${builderSectionId}`,
      builderSection,
      { headers: authHeader() }
    );
  }

  createBuilder(builder, builderSections, regRefs) {
    return AxiosService
      .post(
        `/builder`,
        { builder, builderSections, regRefs },
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      });
  }

  updateBuilder(builderId, builder, builderSections, regRefs) {
    return AxiosService
      .put(
        `/builder/${builderId}`,
        { builder, builderSections, regRefs },
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      });
  }

  createPlanBuilder(builderData, builderSections, workpackages, tasks) {
    return AxiosService
      .post(
        `/builder/plan`,
        { builderData, builderSections, workpackages, tasks },
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      });
  }

  updatePlanBuilder(
    builderId, builderData, builderSections, workpackages, tasks
  ) {
    return AxiosService
      .put(
        `/builder/plan/${builderId}`,
        { builderData, builderSections, workpackages, tasks },
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      });
  }

  // save new builder history
  saveNewBuilderHistory(builderHistory) {
    return AxiosService.post(
      `/builder/saveNewBuilderHistory/`,
      { builderHistory },
      { headers: authHeader() }
    );
  }

  // publish builder
  publish(builder) {
    return AxiosService.post(
      `/builder/publish`,
      { builder },
      { headers: authHeader() }
    );
  }

  // get list of Builer WP Tasks
  getBuilderWpTasks(builderId) {
    return AxiosService
      .get(`/builder/wp/tasks/${builderId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

}

const BuilderService = new BuilderServiceBase();
export default BuilderService;
