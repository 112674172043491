import React from "react";
import { makeStyles } from "mui-styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import classNames from "classnames";
import { isReadOnly } from "utils/roles.utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "fit-content",
    lineHeight: "normal",
    alignItems: "center",
    fontWeight: theme.typography.fontSizeRegular,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.light,
    },
  },
  dark: {
    color: theme.palette.primary.main
  },
  addIconMedium: {
    fontSize: 20,
    marginRight: 6,
  },
  addIconSmall: {
    fontSize: 16,
    marginRight: 4
  },
  addIconExtraSmall: {
    fontSize: 12,
    marginRight: 4
  },
  disabled: {
    color: theme.palette.text.disabled,
    "&:hover": {
      cursor: "default",
      color: theme.palette.text.disabled,
    },
  },
  textExtraSmall: {
    fontSize: theme.typography.caption.fontSize
  }
}));

const SIZE_MEDIUM = "medium";
const SIZE_SMALL = "small";
const SIZE_EXTRA_SMALL = "extra-small";


export const AddListItem = (props) => {
  const classes = useStyles();
  const readOnly = isReadOnly();
  const {
    children,
    className,
    dark = false,
    disabled = false,
    onClick,
    size = SIZE_MEDIUM,
    test,
    text,
  } = props;

  if (readOnly || disabled) {
    return (
      <span
        className={classNames(classes.root, className, classes.disabled)}
        data-cy={`addListIcon-disabled-${test}`}
      >
        <AddCircleOutlineIcon
          className={
            classNames(
              classes.disabled,
              size === SIZE_EXTRA_SMALL && classes.addIconExtraSmall,
              size === SIZE_SMALL && classes.addIconSmall,
              size === SIZE_MEDIUM && classes.addIconMedium
            )
          }
        />
        {text || children}
      </span>
    );
  }
  return (
    <span
      onClick={onClick}
      className={
        classNames(
          classes.root,
          dark && classes.dark,
          size === SIZE_EXTRA_SMALL && classes.textExtraSmall,
          className
        )
      }
      data-cy={`addListIcon-${test}`}
    >
      <AddCircleOutlineIcon
        className={
          classNames(
            size === SIZE_SMALL && classes.addIconSmall,
            size !== SIZE_SMALL && classes.addIconMedium
          )
        }
      />
      {text || children}
    </span>
  );
};

export default AddListItem;
