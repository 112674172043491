import React, { useCallback } from "react";
import { H4 } from "components/utils/headerV2.component";
import LabelValuePair from "components/utils/labelValuePair.component";
import { Box } from "@mui/material";


export default function CustomerPopulationSection(props) {
  const {
    customerDistributions,
    customerClaims,
    populationTypes
  } = props;

  const findPopulationOccurrences = useCallback((population) => {
    return customerClaims.filter((claim) => {
      const associatedDistribution = customerDistributions.find((dist) => (
        dist.distributionDatasetId === claim.distributionDatasetId
      ))
      return associatedDistribution.population === population
    })?.length || "0"
  }, [customerDistributions, customerClaims])

  return (
    <>
      <Box lineHeight="30px">
        <H4>Population Groups</H4>
      </Box>
      {populationTypes.map((population) => {
        return (
          <div key={population}>
            <LabelValuePair
              label={`Population ${population}`}
              value={findPopulationOccurrences(population)}
            />
          </div>
        )
      })}
    </>
  );
}
