import { Box } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import { makeStyles } from "mui-styles";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    display: "flex",
    marginTop: 16,
    marginBottom: 8,
    paddingLeft: 55,
    lineHeight: "normal",
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 35
    },
    "@media (min-height: 960px)": {
      marginTop: 16,
    },
  },
  bold: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
  subheading: {
    marginTop: 4,
    fontSize: theme.typography.body1.fontSize,
  }
}));

// TODO: Remove if/when title is split into two columns
const formatConsentTitle = (title) => {
  const splitTitle = title.split("-");
  return splitTitle?.[0]?.trim?.();
};

function ConsentOrderMenuTitle(props) {
  const classes = useStyles();
  const { activeConsentOrder } = props;

  return (
    <ListItem className={classes.root}>
      <Box display="flex" flexDirection="column">
        <Box className={classes.bold}>{activeConsentOrder.subject}</Box>
        <div className={classes.subheading}>
          {formatConsentTitle(activeConsentOrder.title)}
        </div>
      </Box>
    </ListItem>
  );
}

export default ConsentOrderMenuTitle;
