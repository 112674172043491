import { CloseOutlined } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import AttachmentPasswordViewer from "components/claims/documents/attachmentPasswordViewer.component";
import Loader from "components/utils/loader.components";
import { makeStyles } from "mui-styles";
import { useLayoutEffect, useState } from "react";
import DocumentService from "services/Document.service";
import PaymentService from "services/Payment.service";
import { b64toBlob, blobToBase64 } from "services/util/file.utils";

const useStyles = makeStyles((theme) => ({
  mediaContainer: {
    display: "flex",
    margin: "2vh auto",
    maxWidth: "80vw",
    maxHeight: "92vh",
    width: "100%",
    height: "100%",
    justifyContent: "center",
  },
  dialogPaper: {
    backgroundColor: theme.palette.tertiaryGrey.light,
    backdropFilter: "blur(20px)",
    justifyContent: "flex-start",
  },
  galleryPageWrapper: {
    display: "flex",
    flexFlow: "column",
    height: "100%",
    width: "100%",
  },
  closeButtonWrapper: {
    display: "flex",
    position: "absolute",
    top: "1em",
    right: "2em",
    zIndex: 20,
    height: 120,
    width: 120,
    justifyContent: "flex-end",
    [theme.breakpoints.down("lg")]: {
      width: 100,
      height: 100,
    },
  },
  photoItem: {
    maxHeight: "inherit",
    maxWidth: "inherit",
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },
  closeButtonRoot: {
    borderRadius: 0,
    height: 50,
    width: 50,
  },
}));

const AttachmentViewer = (props) => {
  const classes = useStyles();
  const {
    attachmentToView,
    setAttachmentToView,
    copiedPasswordDocId,
    setCopiedPasswordDocId,
  } = props;

  return (
    <Dialog
      fullScreen
      open={!!attachmentToView}
      onClose={() => setAttachmentToView()}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent>
        <Box className={classes.galleryPageWrapper}>
          <div className={classes.closeButtonWrapper}>
            <IconButton
              color="inherit"
              onClick={() => setAttachmentToView()}
              aria-label="close"
              disableTouchRipple
              disableRipple
              className={classes.closeButtonRoot}
            >
              <CloseOutlined />
              <div className={classes.buttonLabel}>
                <Typography className={classes.fontXxs}>Close</Typography>
              </div>
            </IconButton>
          </div>
          {!!attachmentToView && (
            <AttachmentDisplay
              variant="text"
              documentData={attachmentToView}
              copiedPasswordDocId={copiedPasswordDocId}
              setCopiedPasswordDocId={setCopiedPasswordDocId}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const isPdfDoc = (fileRef) => {
  return fileRef.endsWith?.("pdf") || fileRef.endsWith?.("PDF");
};

const AttachmentDisplay = (props) => {
  const classes = useStyles();
  const { documentData, copiedPasswordDocId, setCopiedPasswordDocId } = props;
  const isReissueDocument = !!documentData.reissueDocumentId;

  const [base64, setBase64] = useState();
  const [pdfBlob, setPdfBlob] = useState();

  useLayoutEffect(() => {
    const fetchAttach = async () => {
      const blob = isReissueDocument
        ? await PaymentService.downloadReissueDocumentFile(
            documentData.reissueDocumentId
        )
        : await DocumentService.downloadDocumentFile(documentData.documentId);
      const base64Data = await blobToBase64(blob);

      if (isPdfDoc(documentData.fileRef)) {
        const base64ForPdf = base64Data.substr(base64Data.indexOf(",") + 1);
        const newBlob = b64toBlob(base64ForPdf);
        const newBlobUrl = URL.createObjectURL(newBlob);
        setPdfBlob(newBlobUrl);
      } else {
        setBase64(base64Data);
      }
    };
    fetchAttach();
  }, [documentData, isReissueDocument]);

  if (!base64 && !pdfBlob) {
    return <Loader />;
  }

  return (
    <Box className={classes.mediaContainer}>
      <div>
        <Box display="flex">
          {!!documentData.descriptionPassword && (
            <AttachmentPasswordViewer
              documentData={documentData}
              copiedPasswordDocId={copiedPasswordDocId}
              setCopiedPasswordDocId={setCopiedPasswordDocId}
            />
          )}
          <Typography fontWeight="bold">{documentData.fileName}</Typography>
        </Box>
        {isPdfDoc(documentData.fileRef) && (
          <iframe
            type="application/pdf"
            src={pdfBlob}
            width="900px"
            height="100%"
            sandbox
            // eslint-disable-next-line
            title={documentData.fileName}
            aria-label={documentData.fileNam}
          />
        )}
        {!isPdfDoc(documentData.fileRef) && (
          <img
            src={base64}
            alt={documentData.fileName}
            className={classes.photoItem}
          />
        )}
      </div>
    </Box>
  );
};

export default AttachmentViewer;
