import CustomCard, { VARIANT_DASHBOARD_PROGRAM, VARIANT_DASHBOARD_PROGRAM_DISABLED } from "components/utils/card.component";
import LabelValuePair from "components/utils/labelValuePair.component";
import { makeStyles } from "mui-styles";
import { generatePath, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { formatDisplayNumber } from "utils/localization.utils";

const useStyles = makeStyles(theme => ({
  card: {
    width: 210,
    maxWidth: "none",
    [theme.breakpoints.up("md")]: {
      width: 224,
    },
    [theme.breakpoints.up("lg")]: {
      width: 240,
    },
    [theme.breakpoints.up("xl")]: {
      width: 280,
    },
  },
  cardContent: {
    flex: 1,
    width: "100%",
    paddingLeft: 16,
    paddingRight: 16,
    textAlign: "center",
    pointerEvents: "none",
  },
  countItemList: {
    padding: "12px 16px 8px 16px",
    background: theme.palette.grey.light,
    fontSize: theme.typography.h6.fontSize
  },
  countItem: {
    display: "flex",
    alignItems: "center",
    paddingTop: 0,
    paddingBottom: 4,
    justifyContent: "space-between",
    lineHeight: theme.typography.lineHeight.proportional2,
  }
}));


export default function ClaimsDashboardCard(props) {
  const {
    statLabels, statValues, title, description, path, disabled = false
  } = props;
  const classes = useStyles();
  const params = useParams();

  return (
    <CustomCard
      variant={
        disabled ?
          VARIANT_DASHBOARD_PROGRAM_DISABLED :
          VARIANT_DASHBOARD_PROGRAM
      }
      className={classes.card}
      title={title}
      url={generatePath(path, params)}
    >
      <div className={classes.cardContent}>
        <p>{description}</p>
      </div>
      {!!statLabels?.length && (
        <div className={classes.countItemList}>
          {statLabels.map(({ name, label, calculateValue }) => (
            <LabelValuePair
              label={label}
              value={
                !statValues ? null : (
                  formatDisplayNumber(
                    calculateValue ?
                      calculateValue(statValues[name]?.count) :
                      statValues[name]?.count
                  )
                )
              }
              className={classes.countItem}
              labelHeaderVariant="h6Tertiary"
              isLoading={!statValues}
              key={name}
            />
          ))}
        </div>
      )}
    </CustomCard>
  );
}
