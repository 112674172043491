import React, { useMemo } from "react";
import { Modal } from "@mui/material";
import { makeStyles } from "mui-styles";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  backdropRoot: {
    cursor: "pointer",
  },
  paper: {
    position: "absolute",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    outline: "none",
    borderRadius: 3,
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}


const CustomModal = (props) => {
  const { children, invisible, open, onClose, className, ...muiProps } = props;
  const classes = useStyles();
  const modalStyle = useMemo(getModalStyle, []);
  return (
    <Modal
      {...muiProps}
      open={!!open}
      onClose={onClose}
      BackdropProps={{
        className: classNames(classes.backdropRoot),
        invisible
      }}
    >
      <div style={modalStyle} className={classNames(classes.paper, className)}>
        {children}
      </div>
    </Modal>
  );
};

export default CustomModal;
