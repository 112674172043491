export function formatFullNameInitials(name) {
  const strArr = name.split(" ");
  let initials = strArr[0].substring(0, 1).toUpperCase();

  if (strArr.length > 1) {
    initials += strArr[1].substring(0, 1).toUpperCase();
  }
  return initials;
}


/*
 * https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript/41318684
 *
 * formatPhoneNum("+12345678900") // => "+1 (234) 567-8900"
 * formatPhoneNum("2345678900")   // => "(234) 567-8900"
 */

export function formatPhoneNum(phoneNumberString) {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{1,3}|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? `+${match[1]} ` : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}
