import React from "react";
import MainLayout from "components/layout/main/layout.component";
import SearchResultPage from "components/search/searchResultPage.component";
import HomeRouter from "components/layout/dynamic/homeRouter.component";
import AdminPage from "components/pages/admin/adminPage.component";
import WpAndProgramTasksPage from "components/pages/tasks/wpTasksTable.component";
import RoleProgramRedirects from "components/layout/dynamic/roleProgramRedirects.component.js";
import { Route, Switch } from "react-router-dom";
import ProgramPage from "components/layout/dynamic/programPage";
import AuthenticatedContextProviders from "components/layout/context/contextProviders/authenticatedContextProviders.component";
import { PATHS_ADMIN, PATHS_HOME, PATHS_PROGRAMS, PATHS_SEARCH, PATHS_TASKS } from "components/layout/constants/standardPaths.constants";

export default function AuthenticatedPageRoutes() {
  return (
    <AuthenticatedContextProviders>
      <MainLayout>
        <Switch>
          <Route exact strict path={PATHS_HOME}>
            <RoleProgramRedirects autoRedirect>
              <HomeRouter />
            </RoleProgramRedirects>
          </Route>
          <Route exact path={PATHS_ADMIN}>
            <AdminPage />
          </Route>
          <Route exact path={PATHS_SEARCH}>
            <SearchResultPage />
          </Route>
          <Route exact path={PATHS_TASKS}>
            <RoleProgramRedirects path={PATHS_TASKS}>
              <WpAndProgramTasksPage />
            </RoleProgramRedirects>
          </Route>
          <Route path={PATHS_PROGRAMS}>
            <RoleProgramRedirects>
              <ProgramPage />
            </RoleProgramRedirects>
          </Route>
        </Switch>
      </MainLayout>
    </AuthenticatedContextProviders>
  )
}
