import { Box, Divider, InputBase, Paper, Typography } from "@mui/material";
import classNames from "classnames";
import ClaimDocumentTable from "components/claims/documents/claimDocumentTable.component";
import { OPTIONS_REISSUE_REASON } from "components/constants/payment.constants";
import ButtonDefault from "components/utils/buttonDefault.component";
import DateInput from "components/utils/form-elements/dateInput.component";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import Form from "components/utils/form-elements/form.component";
import FormLabel from "components/utils/form-elements/formLabel.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import CustomSelect from "components/utils/form-elements/select.component";
import TitledMultiFileUploadInput from "components/utils/form-elements/titledMultiFileUploadInput.component";
import { VALIDATION_REQUIRED } from "components/utils/form-elements/utils/validation.utils";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import { DATE_FORMAT_ISO } from "constants/date.constants";
import { isDevelopmentEnv } from "core/environment";
import moment from "moment";
import { makeStyles } from "mui-styles";
import { useCallback, useMemo, useState } from "react";
import { HTTP_STATUS_CONTENT } from "services/constants/response.constants";
import PaymentService from "services/Payment.service";
import { downloadFileBlob } from "utils/download.utils";
import { isReadOnly } from "utils/roles.utils";
import { stringsAreIdentical } from "utils/string.utils";

const useStyles = makeStyles(theme => ({
  root: {
    width: 1024,
    maxWidth: "100%"
  },
  documentsPaper: {
    marginBottom: 24,
    padding: "12px 24px",
    maxHeight: 376,
    minHeight: 136,
    overflow: "auto",
    [theme.breakpoints.down("lg")]: {
      maxHeight: 280
    },
    [theme.breakpoints.down("md")]: {
      minHeight: 0,
      maxHeight: 152,
    }
  },
  documentsPaperReissued: {
    maxHeight: 320,
    [theme.breakpoints.down("lg")]: {
      maxHeight: 256
    }
  },
  fileInputTextField: {
    marginBottom: -4,
    lineHeight: theme.typography.lineHeight.proportional1
  },
  dualFormButtons: {
    paddingBottom: 8
  }
}));

const TODAY_ISO = moment().format(DATE_FORMAT_ISO);

const CORRECTION_ENABLED_REASON_SET = new Set(
  ["checkReturned", "payeeCorrection", "payeeDeath"]
);

const CORRECTION_FIELD_NAMES_REQUIRED = (
  ["payeeFirstName", "payeeLastName", "payeeAddress"]
);


export default function ReissueCheckForm(props) {
  const {
    claim = null, distributionDataset = null, payment, onClose, onSuccess
  } = props;
  const dbReissueRequest = payment?._associations?.PaymentReissueRequest?.[0];

  const classes = useStyles();
  const [formError, setFormError] = useState(false);
  const [isReasonError, setIsReasonError] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [reasonValue, setReasonValue] = useState(
    dbReissueRequest?.justification || ""
  );

  const isReadOnlyAll = useMemo(isReadOnly, []);

  const isReadOnlyFields = payment?.status !== "Pending" || isReadOnlyAll;

  const updateDescriptor = useMemo(() => {
    switch (reasonValue) {
      case "payeeCorrection":
        return "Corrected";
      case "checkReturned":
      case "checkDamaged":
        return "Updated";
      case "payeeDeath":
      default:
        return "New";
    }
  }, [reasonValue]);

  const formValidation = useMemo(() => {
    const validations = {
      requestDate: { type: VALIDATION_REQUIRED },
      justification: { type: VALIDATION_REQUIRED }
    };
    if (CORRECTION_ENABLED_REASON_SET.has(reasonValue)) {
      validations.payeeFirstName = { type: VALIDATION_REQUIRED };
      validations.payeeLastName = { type: VALIDATION_REQUIRED };
      validations.payeeAddress = { type: VALIDATION_REQUIRED };
    }
    return validations;
  }, [reasonValue]);

  const handleFormChange = useCallback(event => {
    const { name, value } = event.target;
    if (value) {
      setIsSubmitDisabled(false);
    }
    if (isReasonError && (
      (name === "justification" && value !== "payeeCorrection") ||
      CORRECTION_FIELD_NAMES_REQUIRED.includes(name)
    )) {
      setIsReasonError(false);
      setFormError(false);
    }
  }, [isReasonError]);

  const handleReasonChange = useCallback(event => {
    setIsSubmitDisabled(false);
    setIsReasonError(false);
    setFormError(false);
    setReasonValue(event.target.value);
  }, []);

  const handleDownloadAttachment = useCallback(async file => {
    try {
      const blob = await PaymentService.downloadReissueDocumentFile(
        file.reissueDocumentId
      );
      return downloadFileBlob(blob, file.fileName);
    } catch (error) {
      if (
        isDevelopmentEnv &&
        error?.response?.status === HTTP_STATUS_CONTENT
      ) {
        alert(
          "DEVELOPMENT ERROR: File missing from shared cloud storage. " +
          "This file was likely deleted in another developer's local instance."
        );
      }
    }
  }, []);

  const handleSubmit = useCallback(async (data, formData) => {
    if (
      data.justification === "payeeCorrection" &&
      CORRECTION_FIELD_NAMES_REQUIRED.every(name => (
        stringsAreIdentical(data[name], payment[name])
      ))
    ) {
      setIsReasonError(true);
      return;
    }

    const requestFormData = new FormData();
    if (!data.paymentReissueRequestId) {
      requestFormData.set(
        "payment",
        JSON.stringify({
          claimId: data.claimId,
          distributionDatasetId: data.distributionDatasetId,
          issueDate: data.issueDate,
          payeeFirstName: data.payeeFirstName || payment.payeeFirstName,
          payeeLastName: data.payeeLastName || payment.payeeLastName,
          payeeAddress: data.payeeAddress || payment.payeeAddress,
        })
      );
      requestFormData.set(
        "paymentReissueRequest",
        JSON.stringify({
          paymentReissueRequestId: data.paymentReissueRequestId,
          paymentId: data.paymentId,
          justification: data.justification,
          requestDate: data.requestDate,
          payeeFirstName: data.payeeFirstName,
          payeeLastName: data.payeeLastName,
          payeeAddress: data.payeeAddress,
        })
      );
    }
    const reissueDocuments = [];
    const documentFiles = formData.getAll("reissueDocument");
    if (documentFiles?.[0]?.name) {
      documentFiles.forEach(file => {
        reissueDocuments.push({
          paymentReissueRequestId: data.paymentReissueRequestId,
          claimId: data.claimId,
          distributionDatasetId: data.distributionDatasetId,
          descriptionPassword: data[`title-${file.name}`] || null,
          fileName: file.name,
        });
        requestFormData.append("reissueDocumentFiles", file, file.name);
      });
    }
    requestFormData.set("reissueDocuments", JSON.stringify(reissueDocuments));

    try {
      const response = await PaymentService.upsertPaymentReissueRequest(
        payment.paymentId,
        requestFormData
      );
      onSuccess(response);
      onClose();
    } catch (error) {
      setFormError(
        "An unexpected error occurred. Please check your inputs and try again."
      );
      setIsSubmitDisabled(true);
    }
  }, [payment, onClose, onSuccess]);

  if (!payment) {
    return null;
  }
  return (
    <Form
      name="reissue-payment"
      className={classes.root}
      validations={formValidation}
      onChange={handleFormChange}
      onSubmit={handleSubmit}
    >
      <GridContainer spacing={5}>
        <GridItem xs={12} md={4} marginBottom={1}>
          <GridContainer spacing={2}>
            <GridItem xs={12} sm={6} md={12}>
              <DateInput
                name="requestDate"
                label="Date of Request"
                placeholder={TODAY_ISO}
                defaultValue={
                  dbReissueRequest ? dbReissueRequest?.requestDate : TODAY_ISO
                }
                readOnly={isReadOnlyFields}
                required
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={12}>
              <DateInput
                name="issueDate"
                label="Check issued Date"
                placeholder={TODAY_ISO}
                defaultValue={
                  (isReadOnlyFields || dbReissueRequest) ?
                    payment?.issueDate :
                    TODAY_ISO
                }
                readOnly={isReadOnlyFields}
                required
              />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} md={8}>
          {isReadOnlyFields ? (
            <ClaimDocumentTable
              attachmentDocs={dbReissueRequest?._associations?.ReissueDocument}
              handleDownloadDocument={handleDownloadAttachment}
              payment={payment}
              reissueRequest={dbReissueRequest}
              onReissuePaymentSuccess={onSuccess}
            />
          ) : (
            <Paper
              className={classNames(
                classes.documentsPaper,
                isReadOnlyFields && classes.documentsPaperReissued
              )}
            >
              <FormLabel
                label="Supporting Document"
                className={classes.documentsFormLabel}
              />
              <TitledMultiFileUploadInput
                name="reissueDocument"
                label="Supporting Documents"
                readOnly={isReadOnlyAll}
                textFieldProps={{
                  inputProps: { className: classes.fileInputTextField },
                  placeholder: "Description and/or Password",
                  multiline: true,
                }}
                uploadedFiles={dbReissueRequest?._associations?.ReissueDocument}
                uploadedFileTitleColumnName="descriptionPassword"
                onDownloadFile={handleDownloadAttachment}
                editDisabled
                deleteDisabled
                dense
              >
                Attach Files
              </TitledMultiFileUploadInput>
            </Paper>
          )}
        </GridItem>
      </GridContainer>
      <Box paddingTop={1} paddingBottom={3}>
        <Divider />
      </Box>
      <GridContainer spacing={{ xs: 3, lg: 2 }}>
        <GridItem xs={12} lg={4}>
          <Box maxWidth={300} marginRight={2}>
            <CustomSelect
              name="justification"
              label="Reissue reason"
              placeholder="Choose why this payment should be reissued"
              value={reasonValue}
              errorMessage={
                !!isReasonError &&
                "At least one correction must be made below"
              }
              options={OPTIONS_REISSUE_REASON}
              margin="dense"
              variant="default"
              onChange={handleReasonChange}
              readOnly={isReadOnlyFields}
              required
            />
          </Box>
        </GridItem>
        {CORRECTION_ENABLED_REASON_SET.has(reasonValue) && (
          <>
            <GridItem xs={12} sm={6} md={3} lg={2}>
              <LabelInput
                name="payeeFirstName"
                label={`${updateDescriptor} First Name`}
                placeholder={payment.payeeFirstName}
                defaultValue={
                  dbReissueRequest?.payeeFirstName || payment.payeeFirstName
                }
                margin="dense"
                variant="default"
                readOnly={isReadOnlyFields}
              />
              {!!dbReissueRequest && payment?.status !== "Pending" && (
                <LabelInput
                  name="previousPayeeFirstName"
                  label="Previous First Name"
                  value={payment.payeeFirstName}
                  margin="dense"
                  variant="default"
                  readOnly
                />
              )}
            </GridItem>
            <GridItem xs={12} sm={6} md={3} lg={2}>
              <LabelInput
                name="payeeLastName"
                label={`${updateDescriptor} Last Name`}
                placeholder={payment.payeeLastName}
                defaultValue={
                  dbReissueRequest?.payeeLastName || payment.payeeLastName
                }
                margin="dense"
                variant="default"
                readOnly={isReadOnlyFields}
              />
              {!!dbReissueRequest && payment?.status !== "Pending" && (
                <LabelInput
                  name="previousPayeeLastName"
                  label="Previous Last Name"
                  value={payment.payeeLastName}
                  margin="dense"
                  variant="default"
                  readOnly
                />
              )}
            </GridItem>
            <GridItem xs={12} md={6} lg={4}>
              <LabelInput
                name="payeeAddress"
                label={`${updateDescriptor} Address`}
                placeholder={payment.payeeAddress}
                defaultValue={
                  dbReissueRequest?.payeeAddress || payment.payeeAddress
                }
                margin="dense"
                variant="default"
                readOnly={isReadOnlyFields}
                multiline
              />
              {!!dbReissueRequest && payment?.status !== "Pending" && (
                <LabelInput
                  name="previousPayeeAddress"
                  label="Previous Address"
                  value={payment.payeeAddress}
                  margin="dense"
                  variant="default"
                  readOnly
                  multiline
                />
              )}
            </GridItem>
          </>
        )}
      </GridContainer>
      {!isReadOnlyFields && (
        <Typography variant="body1" align="center" color="error">
          {formError || (
            <>&nbsp;</>
          )}
        </Typography>
      )}
      <Box height={0}>
        <InputBase
          type="hidden"
          name="paymentId"
          value={payment.paymentId}
        />
        {claim ? (
          <InputBase
            type="hidden"
            name="claimId"
            value={claim.claimId}
          />
        ) : (
          <InputBase
            type="hidden"
            name="distributionDatasetId"
            value={distributionDataset.distributionDatasetId}
          />
        )}
        {!!dbReissueRequest?.paymentReissueRequestId && (
          <InputBase
            type="hidden"
            name="paymentReissueRequestId"
            value={dbReissueRequest.paymentReissueRequestId}
          />
        )}
      </Box>
      {!isReadOnlyAll && (
        onClose ? (
          <DualFormButtons
            className={classes.dualFormButtons}
            cancelOnClick={onClose}
            disabled={isSubmitDisabled}
          />
        ) : (
          <ButtonDefault
            type="submit"
            disabled={isSubmitDisabled}
          >
            Submit
          </ButtonDefault>
        )
      )}
    </Form>
  )
}
