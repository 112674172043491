/* DEPRECATED, use HeaderV2 instead. */
/* eslint-disable */



import React from "react";
import { makeStyles } from "mui-styles";
import classNames from "classnames";
import CustomIcons from "components/utils/inlineSVGIcons.component";
/*
Accepts optional props called "className" which is a classname to be passed to headerWrapper
which defaults to having padding.
ex.
<HeaderPrimary variant="h4Primary" className={classes.noPadding}>
*/

const useStyles = makeStyles((theme) => ({
  headerH1Wrapper: {
    paddingTop: 15,
    paddingBottom: 15,
    marginTop: 15,
  },
  headerWrapper: {
    paddingTop: 5,
    paddingBottom: 5,
    display: "inline-flex",
    alignItems: "center",
  },
  header: {
    fontWeight: "bold",
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
  },
  noTextTransform: {
    textTransform: "none"
  },
  h1: {
    fontSize: theme.typography.h1.fontSize,
  },
  h2: {
    fontSize: theme.typography.h2.fontSize,
  },
  h3: {
    fontSize: theme.typography.h3.fontSize,
  },
  h4: {
    fontSize: theme.typography.h4.fontSize,
    lineHeight: "1.2rem",
  },
  h5: {
    fontSize: theme.typography.h5.fontSize,
  },
  h6: {
    fontSize: theme.typography.h6.fontSize,
  },
  fontXL: {
    fontSize: theme.typography.subtitle1.fontSize,
  },
  primary: {
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main,
  },
  primaryLight: {
    color: theme.palette.primary.light,
    fill: theme.palette.primary.light,
  },
  secondary: {
    color: theme.palette.secondary.main,
    fill: theme.palette.secondary.main,
  },
  tertiary: {
    color: theme.palette.tertiaryGrey.main,
    fill: theme.palette.tertiaryGrey.main,
  },
  secondaryDark: {
    color: theme.palette.secondary.dark,
    fill: theme.palette.secondary.dark,
  },
  white: {
    color: "#ffffff",
    fill: "#ffffff",
  },
  disabled: {
    color: "white",
    fill: "white",
  },
  icon: {
    display: "inline-flex",
    alignItems: "center",
  },
  h2Icon: {
    height: 22,
    paddingRight: 10
  },
}));

const Header = (props) => {
  const classes = useStyles();
  switch (props.variant) {
    case "h1Primary":
      return (
        <div className={classNames(classes.headerH1Wrapper, props.className)} >
          <span className={classNames(classes.h1, classes.header, classes.primary, props.noTransform && classes.noTextTransform)} data-cy="pageTitle">{props.children}</span>
        </div>
      );
    case "h1Secondary":
      return (
        <div className={classNames(classes.headerH1Wrapper, props.className)}>
          <span className={classNames(classes.h1, classes.header, classes.secondary)} data-cy="pageTitle">{props.children}</span>
        </div>
      );
    case "h1Tertiary":
      return (
        <div className={classes.headerWrapper} data-cy={props.test}>
          <span className={classNames(classes.h1, classes.header, classes.tertiary)} >{props.children}</span>
        </div>
      );
    case "h1White":
      return (
        <div className={classes.headerWrapper} data-cy={props.test}>
          <span className={classNames(classes.h1, classes.header, classes.white)} >{props.children}</span>
        </div>
      );
    case "h2Primary":
      return (
        <div className={classNames(classes.headerWrapper, props.className)} data-cy={props.test}>
          <span className={classNames(classes.icon, classes.primary)}>{props.startIcon}</span>
          <span className={classNames(classes.h2, classes.header, classes.primary)} >{props.children}</span>
        </div>
      );
    case "h2Secondary":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          <span className={classNames(classes.icon, classes.secondary)}>{props.startIcon}</span>
          {!!props.customIcon && (
            <CustomIcons
              variant={props.customIcon}
              className={classNames(classes.secondary, classes.h2Icon)}
            />
          )}
          <span className={classNames(classes.h2, classes.header, classes.secondary)} >{props.children}</span>
        </div>
      );
    case "h2SecondaryDark":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          <span className={classNames(classes.icon, classes.secondaryDark)}>{props.startIcon}</span>
          <span className={classNames(classes.h2, classes.header, classes.secondaryDark)} >{props.children}</span>
        </div>
      );
    case "h2Tertiary":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          <span className={classNames(classes.icon, classes.tertiary)}>{props.startIcon}</span>
          <span className={classNames(classes.h2, classes.header, classes.tertiary)} >{props.children}</span>
        </div>
      );
    case "h2White":
      return (
        <div className={classNames(classes.headerWrapper, props.className)} data-cy={props.test}>
          <span className={classNames(classes.icon, classes.white)}>{props.startIcon}</span>
          {!!props.customIcon && (
            <CustomIcons
              variant={props.customIcon}
              className={classNames(classes.white, classes.h2Icon)}
            />
          )}
          <span className={classNames(classes.h2, classes.header, classes.white)} >{props.children}</span>
        </div>
      );
    case "h3Primary":
      return (
        <div className={classNames(classes.headerWrapper, props.className)} data-cy={props.test}>
          <span className={classNames(classes.icon, classes.primary)}>{props.startIcon}</span>
          <span className={classNames(classes.h3, classes.header, classes.primary, props.noTransform && classes.noTextTransform)} >{props.children}</span>
        </div>
      );
    case "h3Secondary":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          <span className={classNames(classes.icon, classes.secondary)}>{props.startIcon}</span>
          <span className={classNames(classes.h3, classes.header, classes.secondary)} >{props.children}</span>
        </div>
      );
    case "h3SecondaryDark":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          <span className={classNames(classes.icon, classes.secondaryDark)}>{props.startIcon}</span>
          <span className={classNames(classes.h3, classes.header, classes.secondaryDark)} >{props.children}</span>
        </div>
      );
    case "h3Tertiary":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          <span className={classNames(classes.icon, classes.tertiary)}>{props.startIcon}</span>
          <span className={classNames(classes.h3, classes.header, classes.tertiary)} >{props.children}</span>
        </div>
      );
    case "h3Disabled":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          <span className={classNames(classes.h3, classes.header, classes.disabled)} >{props.children}</span>
        </div>
      );
    case "h3White":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          <span className={classNames(classes.icon, classes.white)}>{props.startIcon}</span>
          {!!props.customIcon && (
            <CustomIcons
              variant={props.customIcon}
              className={classNames(classes.white, classes.h2Icon)}
            />
          )}
          <span className={classNames(classes.h3, classes.header, classes.white)} >{props.children}</span>
        </div>
      );
    case "h4Custom":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          <span className={classNames(classes.icon, props.className)}>{props.startIcon}</span>
          <span className={classNames(classes.h4, classes.header, props.className)} >{props.children}</span>
        </div>
      );
    case "h4Custom2":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          <span className={classNames(classes.icon, props.className)}>{props.startIcon}</span>
          <span className={classNames(classes.h6, classes.header, props.className)} >{props.children}</span>
        </div>
      );
    case "h4Primary":
      return (
        <div className={classNames(classes.headerWrapper, props.className)} data-cy={props.test}>
          <span className={classNames(classes.icon, classes.primary)}>{props.startIcon}</span>
          <span className={classNames(classes.h4, classes.header, classes.primary, props.noTransform ? classes.noTextTransform : undefined)} >{props.children}</span>
        </div>
      );
    case "h4Secondary":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          <span className={classNames(classes.icon, classes.secondary)}>{props.startIcon}</span>
          <span className={classNames(classes.h4, classes.header, classes.secondary,props.noTransform ? classes.noTextTransform : undefined)} >{props.children}</span>
        </div>
      );
    case "h4SecondaryDark":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          <span className={classNames(classes.icon, classes.secondaryDark)}>{props.startIcon}</span>
          <span className={classNames(classes.h4, classes.header, classes.secondaryDark)} >{props.children}</span>
        </div>
      );
    case "h4Tertiary":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          <span className={classNames(classes.icon, classes.tertiary)}>{props.startIcon}</span>
          <span className={classNames(classes.h4, classes.header, classes.tertiary, props.noTransform ? classes.noTextTransform : undefined)} >{props.children}</span>
        </div>
      );
    case "h4Disabled":
      return (
        <div className={classes.headerWrapper}>
          <span className={classNames(classes.h4, classes.header, classes.disabled)} >{props.children}</span>
        </div>
      );
    case "h4White":
      return (
        <div className={classNames(classes.headerWrapper, props.className)} data-cy={props.test}>
          <span className={classNames(classes.icon, classes.white)}>{props.startIcon}</span>
          <span className={classNames(classes.h4, classes.header, classes.white)} >{props.children}</span>
        </div>
      );
    case "h5Primary":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          {!!props.startIcon && (
            <span className={classNames(classes.icon, classes.primary)}>
              {props.startIcon}
            </span>
          )}
          <span className={classNames(classes.h5, classes.header, classes.primary, props.noTransform ? classes.noTextTransform : undefined)} >{props.children}</span>
        </div>
      );
    case "h5PrimaryLight":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          {!!props.startIcon && (
            <span className={classNames(classes.icon, classes.primaryLight)}>
              {props.startIcon}
            </span>
          )}
          <span className={classNames(classes.h5, classes.header, classes.primaryLight, props.noTransform ? classes.noTextTransform : undefined)} >{props.children}</span>
        </div>
      );
    case "h5Secondary":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          {!!props.startIcon && (
            <span className={classNames(classes.icon, classes.secondary)}>
              {props.startIcon}
            </span>
          )}
          <span className={classNames(classes.h5, classes.header, classes.secondary, props.noTransform ? classes.noTextTransform : undefined)} >{props.children}</span>
        </div>
      );
    case "h5SecondaryDark":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          {!!props.startIcon && (
            <span className={classNames(classes.icon, classes.secondaryDark)}>
              {props.startIcon}
            </span>
          )}
          <span className={classNames(classes.h5, classes.header, classes.secondaryDark)} >{props.children}</span>
        </div>
      );
    case "h5Tertiary":
      return (
        <div className={classNames(classes.headerWrapper, props.className)} data-cy={props.test}>
          {!!props.startIcon && (
            <span className={classNames(classes.icon, classes.tertiary)}>
              {props.startIcon}
            </span>
          )}
          <span className={classNames(classes.h5, classes.header, classes.tertiary, props.noTransform && classes.noTextTransform)} >{props.children}</span>
        </div>
      );
    case "h5TertiaryFramework":
      return (
        <div >
          <span className={classNames(classes.h5, classes.header, classes.tertiary)} >{props.children}</span>
        </div>
      );
    case "h5White":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          {!!props.startIcon && (
            <span className={classNames(classes.icon, classes.white)}>
              {props.startIcon}
            </span>
          )}
          <span className={classNames(classes.h5, classes.header, classes.white)} >{props.children}</span>
        </div>
      );
    case "h6Primary":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          <span className={classNames(classes.icon, classes.primary)}>{props.startIcon}</span>
          <span className={classNames(classes.h6, classes.header, classes.primary, props.noTransform && classes.noTextTransform)} >{props.children}</span>
        </div>
      );
    case "h6Secondary":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          <span className={classNames(classes.icon, classes.secondary)}>{props.startIcon}</span>
          <span className={classNames(classes.h6, classes.header, classes.secondary)} >{props.children}</span>
        </div>
      );
    case "h6SecondaryDark":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          <span className={classNames(classes.icon, classes.secondaryDark)}>{props.startIcon}</span>
          <span className={classNames(classes.h6, classes.header, classes.secondaryDark)} >{props.children}</span>
        </div>
      );
    case "h6Tertiary":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          <span className={classNames(classes.icon, classes.tertiary)}>{props.startIcon}</span>
          <span className={classNames(classes.h6, classes.header, classes.tertiary)} >{props.children}</span>
        </div>
      );
    case "h6White":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          <span className={classNames(classes.icon, classes.white)}>{props.startIcon}</span>
          <span className={classNames(classes.h5, classes.header, classes.white)} >{props.children}</span>
        </div>
      );
    case "xlWhite":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          <span className={classNames(classes.icon, classes.white)}>{props.startIcon}</span>
          <span className={classNames(classes.fontXL, classes.header, classes.white)} >{props.children}</span>
        </div>
      );
    case "xlPrimary":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          <span className={classNames(classes.icon, classes.primary)}>{props.startIcon}</span>
          <span className={classNames(classes.fontXL, classes.header, classes.primary)} >{props.children}</span>
        </div>
      );
    case "xlDisabled":
      return (
        <div className={classNames(classes.headerWrapper, props.className)}>
          <span className={classNames(classes.icon, classes.disabled)}>{props.startIcon}</span>
          <span className={classNames(classes.fontXL, classes.header, classes.disabled)} >{props.children}</span>
        </div>
      );
    default:
      return (
        <div>
          <span className={classes.subHeaderPrimary}>{props.children}</span>
        </div>
      );
  }
};

export default Header;
