import { mapObjectArrayByKey } from "utils/arrayOfObjects.utils"
import { mergeOneSourceAssociationSetList } from "reducers/utils/payload.utils"

export const ACTION_SET_CLAIMS = "claims__set"
export const ACTION_REPLACE_CLAIM_PAYMENTS = "claims__payments__replace";
export const ACTION_SET_CLAIM_VALUE_TYPES = "claimValueTypes__set"
export const ACTION_SET_CLAIM_DOCUMENTS = "claimDocuments__set"
export const ACTION_ADD_CLAIM_DOCUMENTS = "claimDocuments__add"
export const ACTION_SET_CUSTOMER = "customer__set"
export const ACTION_SET_EVALUATION_TEMPLATES = "evaluationTemplates__set"
export const ACTION_RESET_CLAIMS = "claims__reset";
export const ACTION_REPLACE_WORKFLOW_INSTANCES = "workflowInstances__replace";
export const ACTION_SET_WORKFLOW_INSTANCES = "workflowInstances__set";
export const ACTION_REPLACE_CONTACT_LOG = "contactLog__replace";
export const ACTION_REPLACE_EVALUATION_DC = "evaluationDC__replace"

const mergePaymentAssociations = (parent, payloadPayments) => {
  const existingPaymentIds = new Set(
    parent._associations.Payment?.map?.(payment => payment.paymentId)
  );
  const payloadClaimPayments = payloadPayments.filter(payment => (
    payment.claimId === parent.claimId
  ));
  const payloadPaymentsById = (
    mapObjectArrayByKey(payloadClaimPayments, "paymentId")
  );
  return {
    ...parent,
    _associations: {
      ...parent._associations,
      Payment: parent._associations.Payment?.map?.(prevPayment => (
        payloadPaymentsById[prevPayment.paymentId] || prevPayment
      )).concat(payloadClaimPayments.filter(payment => (
        !existingPaymentIds.has(payment.paymentId)
      )))
    }
  };
};


export const claimReviewInitialState = {
  claims: null,
  claimValueTypesByName: null,
  documents: null,
  customer: null,
  evaluationTemplates: null,
  wfInstances: null,
}

export default function claimReviewReducer(state, action) {
  switch (action.type) {
    case ACTION_SET_CLAIMS:
      return {
        ...state,
        claims: action.payload
      }
    case ACTION_RESET_CLAIMS: {
      return {
        ...state,
        claims: claimReviewInitialState.claims
      }
    }
    case ACTION_REPLACE_CONTACT_LOG:
      return {
        ...state,
        claims: mergeOneSourceAssociationSetList(
          state.claims,
          action.payload.contactLog,
          "claimId",
          action.payload.claimId,
          "ContactLog",
        )
      }
    case ACTION_REPLACE_EVALUATION_DC:
      return {
        ...state,
        claims: mergeOneSourceAssociationSetList(
          state.claims,
          action.payload.evaluationDC,
          "claimId",
          action.payload.claimId,
          "EvaluationDC",
        )
      }
    case ACTION_REPLACE_CLAIM_PAYMENTS:
      return {
        ...state,
        claims: state.claims.map(claim => (
          mergePaymentAssociations(claim, action.payload)
        ))
      };
    case ACTION_SET_CLAIM_VALUE_TYPES:
      return {
        ...state,
        claimValueTypesByName: mapObjectArrayByKey(action.payload, "name")
      }
    case ACTION_SET_CLAIM_DOCUMENTS:
      return {
        ...state,
        documents: action.payload
      }
    case ACTION_ADD_CLAIM_DOCUMENTS:
      return {
        ...state,
        documents: [...state.documents, ...action.payload]
      }
    case ACTION_SET_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
      }
    case ACTION_SET_EVALUATION_TEMPLATES:
      return {
        ...state,
        evaluationTemplates: action.payload
      }
    case ACTION_REPLACE_WORKFLOW_INSTANCES:
      const wfInstancesById = mapObjectArrayByKey(action.payload, "wfInstanceId");
      const payloadWfInstanceIds = action.payload
        .map((wfInstance) => (wfInstance.wfInstanceId));
      const stateWfInstanceIds = state.wfInstances
        .map((wfInstance) => (wfInstance.wfInstanceId));
      const newWfInstanceId = payloadWfInstanceIds.find((wfInstanceId) => (
        !stateWfInstanceIds.includes(wfInstanceId)
      ))
      const newWfInstance = wfInstancesById[newWfInstanceId]
      const replacedInstanceList =
        state.wfInstances.map((wfInstance) => {
          if (wfInstancesById[wfInstance.wfInstanceId]) {
            return wfInstancesById[wfInstance.wfInstanceId]
          } else {
            return wfInstance;
          }
        })
      const newWfInstanceList = (newWfInstance ? [
        ...replacedInstanceList, newWfInstance
      ] : replacedInstanceList)
      return {
        ...state,
        wfInstances: newWfInstanceList
      }
    case ACTION_SET_WORKFLOW_INSTANCES:
      return {
        ...state,
        wfInstances: action.payload
      }
    default:
      return state;
  }
}