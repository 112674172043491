import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { makeStyles } from "mui-styles";
/*
 * Used for Title Icons that use MUI Icons
 */

const useStyles = makeStyles((theme) => ({
  icon: {
    height: 20,
    fill: "white",
  },
}));

const TitleMuiIcon = ({ variant }) => {
  const classes = useStyles();
  if (variant === "search") {
    return <SearchOutlinedIcon className={classes.icon} />;
  }
  if (variant === "admin") {
    return <SettingsOutlinedIcon className={classes.icon} />;
  }
};

export default TitleMuiIcon;
