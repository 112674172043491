import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import ButtonDefault from "components/utils/buttonDefault.component";
import { makeStyles } from "mui-styles";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    padding: "30px 30px 20px 30px",
  },
  singleActionContainer: {
    display: "flex",
    justifyContent: "center",
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "center",
    padding: 5,
    fontSize: theme.typography.subtitle1.fontSize,
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
}));

const NotificationDialog = (props) => {
  const classes = useStyles();
  const { open, onClose, dialogTitle, prompt, children, ...muiProps } = props;

  return (
    <Dialog
      open={!!open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ classes: { root: classes.dialogPaper }}}
      {...muiProps}
    >
      {!!dialogTitle && (
        <div id="alert-dialog-title" className={classes.dialogTitle}>
          {dialogTitle}
        </div>
      )}
      <DialogContent>
        {!!prompt && (
          <DialogContentText
            id="alert-dialog-description"
            data-cy="dialog-notification"
          >
            {prompt}
          </DialogContentText>
        )}
        {children}
      </DialogContent>
      <DialogActions className={classes.singleActionContainer}>
        <ButtonDefault
          variant="small"
          background="secondary"
          onClick={onClose}
          data-cy="dialog-ok"
        >
          OK
        </ButtonDefault>
      </DialogActions>
    </Dialog>
  );
};

export default NotificationDialog;
