import { useCallback, useContext, useMemo, useRef, useState } from "react";
import WorkflowTemplateContext from "contexts/workflowTemplate.context";
import Loader from "components/utils/loader.components";
import ClaimReviewAccordion from "./ClaimReviewAccordion.component";
import { groupObjectArrayByKey, mapObjectArrayByKey } from "utils/arrayOfObjects.utils";
import { Box, Divider } from "@mui/material";
import CustomerInformationCard from "./CustomerInformationCard.component";
import AccordionListView from "components/utils/accordionListView.component";
import { H1, H3, H4 } from "components/utils/headerV2.component";
import LabelValuePair from "components/utils/labelValuePair.component";
import moment from "moment";
import { DATE_FORMAT_DISPLAY_NUMERIC_SHORT } from "constants/date.constants";
import theme from "theme/theme";

const CLAIM_SECTION_PADDING_Y = 16;

const ClaimReview = (props) => {
  const {
    claims,
    customer,
    documents,
    evaluationTemplates,
    workflowInstances,
    claimValueTypesByName,
    dispatch,
  } = props;

  const wfTemplates = useContext(WorkflowTemplateContext);
  const [activeClaimId, setActiveClaimId] = useState(null);
  const scrollAnchorRef = useRef();

  const workflowTemplatesById = useMemo(() => (
    mapObjectArrayByKey(wfTemplates, "wfTemplateId")
  ), [wfTemplates]);

  const documentsByClaimId = useMemo(() => {
    return groupObjectArrayByKey(documents, "claimId")
  }, [documents])

  const claimIdsByDistributionDatasetId = useMemo(() => {
    return claims.reduce((acc, claimItem) => {
      return {
        ...acc,
        [claimItem.distributionDatasetId]: claimItem.claimId
      }
    }, {})
  }, [claims])

  const workflowInstancesByClaimId = useMemo(() => {
    return workflowInstances.reduce((acc, instance) => {
      const claimId = claimIdsByDistributionDatasetId[instance.distributionDatasetId]

      return {
        ...acc,
        [claimId]: [...(acc[claimId] || []), instance]
      }
    }, {})
  }, [workflowInstances, claimIdsByDistributionDatasetId])

  const handleScrollToClaims = useCallback((claimId) => {
    setActiveClaimId(claimId)
    if (scrollAnchorRef?.current) {
      const scrollYDestination = (
        scrollAnchorRef.current.getBoundingClientRect().top -
        theme.layout.height.appBar -
        CLAIM_SECTION_PADDING_Y
      )
      window.scrollTo(0, scrollYDestination);
    }
  }, [scrollAnchorRef]);

  const handleAccordionChange = useCallback((itemId, _event) => {
    if (activeClaimId === itemId) {
      setActiveClaimId(null);
    } else {
      setActiveClaimId(itemId)
    }
  }, [activeClaimId])

  const claimAccordionOptions = useMemo(() => {
    return claims.map((claim) => {
      const claimWorkflowInstances = workflowInstancesByClaimId[claim.claimId]
      const orderedWorkflowInstances = claimWorkflowInstances.sort((wf1, wf2) => {
        if (workflowTemplatesById[wf1.wfTemplateId].displayOrder >
          workflowTemplatesById[wf2.wfTemplateId].displayOrder
        ) {
          return 1;
        }
        if (workflowTemplatesById[wf1.wfTemplateId].displayOrder <
          workflowTemplatesById[wf2.wfTemplateId].displayOrder
        ) {
          return -1;
        }
        return 0;
      })

      const claimDocuments = documentsByClaimId[
        claim.claimId
      ] || [];
      const claimData = claim._derivedData.ClaimData;
      const formattedSubmittedDate = moment(
        claim.submittedDate
      ).format(DATE_FORMAT_DISPLAY_NUMERIC_SHORT)
      return {
        id: claim.claimId,
        label: (
          <div>
            <H4 color="tertiary">Loan #{claimData.gsLoanNumber}</H4>
          </div>
        ),
        sublabel: (
          <LabelValuePair
            label="Submitted Date"
            value={formattedSubmittedDate}
          />
        ),
        value: (
          <>
            <Divider />
            <Box
              key={claim.claimId}
              marginTop={4}
              backgroundColor={theme.palette.background.default}
              borderRadius={2}
              padding={5}
              boxShadow={theme.shadow.inset2}
            >
              <ClaimReviewAccordion
                claim={claim}
                dispatch={dispatch}
                claimDocuments={claimDocuments}
                claimValueTypesByName={claimValueTypesByName}
                evaluationTemplates={evaluationTemplates}
                workflowTemplatesById={workflowTemplatesById}
                workflowInstances={orderedWorkflowInstances}
              />
            </Box>
          </>
        )
      }
    })
  }, [claims, workflowTemplatesById,
    workflowInstancesByClaimId, documentsByClaimId,
    claimValueTypesByName, dispatch, evaluationTemplates
  ])

  if (!dispatch) {
    return <Loader inline />
  }

  return (
    <>
      <Box paddingBottom={1}>
        <H1>Claim Review</H1>
      </Box>
      <Box margin="2em 0em 0em 0em">
        <Box paddingBottom={3}>
          <H3 color="tertiary">Customer Information</H3>
        </Box>
        <CustomerInformationCard
          customer={customer}
          customerSubmittedClaims={claims}
          setActiveClaimId={handleScrollToClaims}
        />
        <Box paddingTop={4} minHeight="100vh">
          <div ref={scrollAnchorRef}>
            <Box paddingBottom={3}>
              <H3 color="tertiary">Customer Claims</H3>
            </Box>
            <AccordionListView
              options={claimAccordionOptions}
              onChange={handleAccordionChange}
              controlledAccordionId={activeClaimId}
              controlled
            />
          </div>
        </Box>
      </Box>
    </>

  )
}

export default ClaimReview;