import AjaxService from "./base/Ajax.service";

class WorkflowServiceBase extends AjaxService {

  get shouldExtractResponseData() {
    return true;
  }

  async getAllWfTemplates() {
    return this.get(`/workflow/wfTemplate`);
  }

  async getWorkflowStatistics(abortController) {
    return this.getCancellable("/workflow/statistics/index", abortController);
  }

  async moveWFInstanceToNextWFNode(wfInstanceId, wfOptionId) {
    return this.post(`/workflow/wfInstance/${wfInstanceId}`, { wfOptionId })
  }

  async getWorkflowInstancesByCifId(cifId) {
    return this.get(`/workflow/wfInstances/${cifId}`)
  }
}

const WorkflowService = new WorkflowServiceBase();
export default WorkflowService;
