import AjaxService from "services/base/Ajax.service.js";

class ContactLogServiceBase extends AjaxService {
  shouldExtractResponseData() {
    return true;
  }

  async upsertContactLog(formData) {
    return this.post("/contactLog/upsert", formData);
  }
}

const ContactLogService = new ContactLogServiceBase();
export default ContactLogService;
