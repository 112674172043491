import React, { useMemo } from "react";
import { Box, TableRow } from "@mui/material";
import { makeStyles } from "mui-styles";
import AlertIcon from "components/shared/alerts/alertIcon.component";
import AlertDueText from "components/shared/alerts/alertDueText.component";
import CustomLink from "components/utils/link.component";
import { alertDueMessage } from "components/shared/alerts/utils/alerts.utils";
import IconMessage from "components/utils/iconMessage.component";
import classNames from "classnames";
import { truncate } from "components/shared/workpackages/utils/workpackage.utils";
import TableCell from "components/utils/tables/shared/tableCell.component";
import { ALERT_TYPE_ID_NEW_CLAIM } from "components/shared/alerts/constants/alerts.constants";
import useActiveConsentOrder from "hooks/useActiveConsentOrder";

const useStyles = makeStyles((theme) => ({
  alertContent: {
    padding: 10,
  },
  emptyAlertContent: {
    color: theme.palette.text.secondary,
  },
  cellRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  leftColumn: {
    display: "flex",
    flexDirection: "column",
    paddingRight: 10,
  },
  rightColumn: {
    display: "flex",
    flexDirection: "column"
  },
  alertHeader: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 3,
  },
  alertLabel: {
    fontWeight: "bold",
  },
  iconWrapper: {
    height: 15,
    marginRight: 10,
    pointerEvents: "auto",
  },
  infoBox: {
    marginLeft: 25,
  },
  dismiss: {
    display: "flex",
    paddingTop: 10,
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flex: 1
  },
  warning: {
    color: theme.palette.error.main,
    fill: theme.palette.error.main,
  },
  caution: {
    color: theme.palette.warning.dark,
    fill: theme.palette.warning.dark,
  },
  assigned: {
    color: theme.palette.success.main,
    fill: theme.palette.success.main,
  },
  taskLink: {
    color: theme.palette.tertiaryGrey.main,
    fontSize: theme.typography.body2.fontSize,
    paddingTop: 5,
  },
  dismissLink: {
    color: theme.palette.tertiaryGrey.main,
    fontWeight: "normal",
    fontSize: theme.typography.body2.fontSize,
  },
}));

const AlertBox = ({
  alert,
  deleteUserAlert,
  empty,
  handleCloseAlert,
}) => {
  const classes = useStyles();

  if (empty) {
    return (
      <TableRow className={classes.emptyAlertContent}>
        <TableCell>
          <IconMessage
            sectionName="Alerts"
            variant="emptyAlerts"
            dataCy="no-alerts-message"
          />
        </TableCell>
      </TableRow>
    );
  }

  const TEMP_URL = `/home/program/remediation-management?remediationId=${alert.consentOrderId}`
  return (
    <TableRow
      className={classes.alertContent}
      data-cy={`alertRow-${alert.message}`}
    >
      <TableCell className={classes.cellRow}>
        <div className={classes.leftColumn}>
          <AlertHeader alert={alert} />
          <div className={classes.infoBox}>
            <CustomLink
              className={classes.taskLink}
              variant="routerLink"
              to={TEMP_URL}
              test={`alert-${alert.message}`}
              onClick={handleCloseAlert}
            >
              <AlertLinkDisplay
                message={alert.message}
              />
            </CustomLink>
          </div>
        </div>
        <div className={classes.rightColumn}>
          <AlertDueText endDate={alert.endDate} />
          <div className={classes.dismiss}>
            <CustomLink
              onClick={() => deleteUserAlert(alert.userAlertId)}
              test={`dismiss-alert-${alert.message}`}
              variant="noHRef"
              className={classes.dismissLink}
            >
              Dismiss
            </CustomLink>
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default AlertBox;

const AlertLinkDisplay = ({ message, messageHeader }) => {
  if (messageHeader) {
    return (
      <Box display="flex" flexDirection="column">
        <strong>{truncate(messageHeader, 35)}</strong>
        <span>{message}</span>
      </Box>
    );
  }
  return message;
};

const AlertHeader = ({ alert }) => {
  const classes = useStyles();
  const [activeConsentOrder] = useActiveConsentOrder();

  const { color } = useMemo(() => alertDueMessage(alert?.endDate), [alert]);

  const alertTypeLabel = useMemo(() => {
    switch (alert?.alertTypeId) {
      case ALERT_TYPE_ID_NEW_CLAIM:
      case undefined:
      default:
        return "New Alert";
    }
  }, [alert]);

  const colorClass = useMemo(() => {
    switch (color) {
      case "assigned":
        return classes.assigned;
      case "warning":
        return classes.warning;
      case "caution":
        return classes.caution;
      default:
        return "";
    }
  }, [classes, color]);

  return (
    <span className={classes.alertHeader}>
      <div className={classes.iconWrapper}>
        <AlertIcon endDate={alert.endDate} noToolTip />
      </div>
      <div className={classNames(colorClass, classes.alertLabel)}>
        {activeConsentOrder?.name} {alertTypeLabel}
      </div>
    </span>
  );
};
