import React, { useState } from "react";
import HeaderPrimary from "components/utils/header.component.js";
import CompTasksTable from "components/pages/tasks/compTasksTable.component";
import { makeStyles } from "mui-styles";
import { Paper, Tab, Tabs, Typography } from "@mui/material";
import BadgeStyled from "components/utils/badgeStyled.component";

const useStyles = makeStyles((theme) => ({
  popoverWrapper: {
    padding: "10px 10px 15px 10px",
  },
  headerWrapper: {
    marginBottom: 10,
  },
  tabsWrapper: {
    borderRadius: "4px 4px 0px 0px",
    [theme.breakpoints.up("xl")]: {
      marginRight: 0,
    },
  },
  tabs: {
    width: "fit-content",
  },
  indicator: {
    visibility: "hidden",
    align: "center",
  },
  tabRoot: {
    borderRadius: "3px 3px 0 0",
    borderColor: "white",
    color: theme.palette.tertiaryGrey.main,
    fontSize: theme.typography.caption.fontSize,
    padding: "0px 25px",
    margin: 0,
    minWidth: 90,
    fontWeight: "bold",
    opacity: 1,
    "&:hover": {
      backgroundColor: theme.palette.tertiaryGrey.light,
    },
  },
  tabSelected: {
    backgroundColor: theme.palette.tertiaryGrey.light,
  },
  tabText: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: "bold",
  },
}));


export const CompTasksContainer = ({
  title,
  allTasks,
  userTasks,
  refresh,
  setRefresh,
}) => {
  const classes = useStyles();
  const [viewMode, setViewMode] = useState("userTasks");

  const viewHandler = (event, newVal) => {
    setViewMode(newVal);
  };

  return (
    <Paper className={classes.popoverWrapper} data-cy="popover-container-compTasks">
      <div className={classes.headerWrapper}>
        <HeaderPrimary variant="h3Primary">{`${title} Tasks`}</HeaderPrimary>
      </div>
      <Paper className={classes.tabsWrapper}>
        <Tabs
          className={classes.tabs}
          textColor="primary"
          indicatorColor="primary"
          value={viewMode}
          elevation={0}
          onChange={viewHandler}
          TabIndicatorProps={{ className: classes.indicator }}
        >
          <Tab
            label={
              <BadgeStyled
                alertCount={userTasks.length}
                active={viewMode === "userTasks"}
              >
                <Typography className={classes.tabText}>My Tasks</Typography>
              </BadgeStyled>
            }
            value="userTasks"
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
            }}
            data-cy="tab-myTasks"
          />
          <Tab
            label={
              <BadgeStyled
                alertCount={allTasks.length}
                active={viewMode === "allTasks"}
              >
                <Typography className={classes.tabText}>All Tasks</Typography>
              </BadgeStyled>
            }
            value="allTasks"
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
            }}
            data-cy="tab-allTasks"
          />
        </Tabs>
      </Paper>
      <CompTasksTable
        tableData={viewMode === "userTasks" ? userTasks : allTasks}
        userTasks={userTasks}
        title={title}
        refresh={refresh}
        setRefresh={setRefresh}
        viewMode={viewMode}
      />
    </Paper>
  );
};

export default CompTasksContainer;
