const assessmentBuilderModes = {
  MODE_EDIT: "editCurrent",
  MODE_LOAD_TEMPLATE: "loadTemplate",
  MODE_REVIEW: "review",
  MODE_COMPLETE: "complete",
  MODE_REPORT: "report",
};

const abSetupSteps = [
  { name: "Assessment Basic Information", stepNum: 1 },
  { name: "Set Assessment Items", stepNum: 2 },
  { name: "Select Assessment Type", stepNum: 3 },
  { name: "Add Checklist Items", stepNum: 4 },
  { name: "Assign Assessors", stepNum: 5 },
  { name: "Assessment Setup Confirmation", stepNum: 6 },
];

// ViewType Categorization
const controlsCompNames = ["Controls"];
const regFrameworkScopedComponentNames = ["Controls"];
const programDocCompNames = [
  "Policies & Procedures",
  "Charter",
  "Plans & Roadmaps",
  "Tools"
];
const programsCompNames = [ "Programs" ]
const complianceCompNames = ["Compliance"]
const requirementCompNames = ["Requirements"]

// All valid Component Object names to select Items from
const allViewCompNames = new Set([
  ...controlsCompNames,
  ...programDocCompNames,
  ...programsCompNames,
  ...complianceCompNames,
  ...requirementCompNames,
]);

const compNamesByAssessRefType = {
  controls: controlsCompNames,
  programDocs: programDocCompNames,
};

const customCompObj = {
  // Custom info with similar fields as a component object
  Component_ID: "custom",
  Name: "Custom",
  Nav_Icon: "Orchestration",
};

/*
 * These values should correspond with a progComp Name.
 * Down the line, it may make sense to have it correspond
 * to refViewTypes
 */
const assesssRefItemsWithTabDisplay = ["Controls"];

const refViewTypes = {
  CONTROLS: "Controls",
  PROGRAM_DOCS: "ProgramDocs",
  PROGRAMS: "Programs",
  COMPLIANCE: "Compliance",
  REQUIREMENTS: "Requirements"
};

const regFrameworkAssessmentItemTypes = [
  refViewTypes.CONTROLS, refViewTypes.COMPLIANCE
];

//Second Value should be primaryKey
const keysByViewType = {
  [refViewTypes.CONTROLS]: ["Name", "Control_ID"],
  [refViewTypes.PROGRAM_DOCS]: ["Document_Type", "Doc_ID"],
  [refViewTypes.PROGRAMS]: ["Name", "Program_ID"],
  [refViewTypes.COMPLIANCE]: ["Requirement_Type", "Compliance_ID"],
  [refViewTypes.REQUIREMENTS]: [ "Type", "Requirement_ID"]
};

export const REG_FRAMEWORK_SCOPE_ALL = "*"

const reviewPageModes = {
  SUMMARY_TABLE: "",
  DESIGN_DATA_COLLECTION: "designDataCollection",
  PERFORMANCE_DATA_COLLECTION: "performanceDataCollection",
  SUMMARY_RECOMMENDATION: "summaryRecommendation",
  SUMMARY_RESPONSES: "summaryResponses",
  ROADMAP_TABLE: "roadmaps",
  ROADMAP_EDIT: "roadmapEdit"
};

const dataCollectionStatus = {
  STATUS_NOT_STARTED: "Not Started",
  STATUS_COMPLETED: "Completed",
  STATUS_IN_PROGRESS: "In Progress",
};

const dataCollectionTypeIds = {
  DATA_COLLECTION_TYPE_DESIGN: 1,
  DATA_COLLECTION_TYPE_PERFORMANCE: 2,
  DATA_COLLECTION_TYPE_ALL: 3,
};

const assessBuilderStatus = {
  BUILDER_STATUS_DRAFT: "Draft",
  BUILDER_STATUS_ACTIVE: "Active",
  BUILDER_STATUS_ASSESSED: "Assessed",
  BUILDER_STATUS_RESPONDED: "Responded",
}

const assessmentTypeIds = {
  ASSESS_TYPE_DESIGN: 1,
  ASSESS_TYPE_PERFORMANCE: 2,
  ASSESS_TYPE_ALL: 3,
};

const reviewResponseTypes = {
  RESP_TYPE_REC: "Recommendation",
  RESP_TYPE_FINDING: "Finding",
}

const assessmentTableIdMap = {
  ASSESSMENT_BUILDER: 1,
  ASSESSMENT_ITEM: 2,
  ASSESSMENT_CHECKLIST_ITEM: 3,
  ASSESSMENT_ASSIGNMENT: 4,
  DESIGN_DATA_COLLECTION: 5,
  PERFORMANCE_DATA_COLLECTION: 6,
  FINDING_RECOMMENDATION: 9,
  SUMMARY_RECOMMENDATIONS: 11,
  ROADMAPS: 63
}

const updateAssessmentMapping = {
  /*
   * ASSESSMENT_BUILDER: {
   *   tableID: assessmentTableIdMap.ASSESSMENT_BUILDER,
   *   idFieldName: "Builder_ID",
   *   name: "ASSESSMENT_BUILDER"
   * },
   */
  ASSESSMENT_ITEM: {
    tableID: assessmentTableIdMap.ASSESSMENT_ITEM,
    idFieldName: "AssessmentItem_ID",
    name: "ASSESSMENT_ITEM",
  },
  ASSESSMENT_CHECKLIST_ITEM: {
    tableID: assessmentTableIdMap.ASSESSMENT_CHECKLIST_ITEM,
    idFieldName: "ChecklistItem_ID",
    name: "ASSESSMENT_CHECKLIST_ITEM",
  },
  ASSESSMENT_ASSIGNMENT: {
    tableID: assessmentTableIdMap.ASSESSMENT_ASSIGNMENT,
    idFieldName: "AssessmentAssignment_ID",
    name: "ASSESSMENT_ASSIGNMENT",
  },
};

const responseSources = {
  SUMMARY_RECOMMENDATION: {
    sourceTableID: assessmentTableIdMap.SUMMARY_RECOMMENDATION,
    sourceDisplayField: "Recommendation",
    sourceIDName: "Recommendation_ID",
    sourceTypeField: null,
    constFieldName: "SUMMARY_RECOMMENDATION",
    taskTitle: "Summary Recommendation"
  },
  DESIGN_FINDING: {
    sourceTableID: assessmentTableIdMap.DESIGN_DATA_COLLECTION,
    sourceDisplayField: "Finding",
    sourceIDName: "Finding_ID",
    sourceTypeField: reviewResponseTypes.RESP_TYPE_FINDING,
    constFieldName: "DESIGN_FINDING",
    taskTitle: "Design Review",
    reviewType: "Design"
  },
  DESIGN_REC: {
    sourceTableID: assessmentTableIdMap.DESIGN_DATA_COLLECTION,
    sourceDisplayField: "Recommendation",
    sourceIDName: "Recommendation_ID",
    sourceTypeField: reviewResponseTypes.RESP_TYPE_REC,
    constFieldName: "DESIGN_REC",
    taskTitle: "Design Review",
    reviewType: "Design"
  },
  PERFORMANCE_FINDING: {
    sourceTableID: assessmentTableIdMap.PERFORMANCE_DATA_COLLECTION,
    sourceDisplayField: "Finding",
    sourceIDName: "Finding_ID",
    sourceTypeField: reviewResponseTypes.RESP_TYPE_FINDING,
    constFieldName: "PERFORMANCE_FINDING",
    taskTitle: "Performance Review",
    reviewType: "Performance"
  },
  PERFORMANCE_REC: {
    sourceTableID: assessmentTableIdMap.PERFORMANCE_DATA_COLLECTION,
    sourceDisplayField: "Recommendation",
    sourceIDName: "Recommendation_ID",
    sourceTypeField: reviewResponseTypes.RESP_TYPE_REC,
    constFieldName: "PERFORMANCE_REC",
    taskTitle: "Performance Review",
    reviewType: "Performance"
  },
};

const controlAbbreviatedField = {
  CMMC: "Domain_Name",
  PRIVACY_53: "Fam_ID",
  NIST_171: "Security_Req_Group"
}

const assessmentSourceTableInfo = {
  [refViewTypes.PROGRAM_DOCS]: {
    filterable: true,
    fields: ["Doc_ID", "Title", "Version", "Document_Type"],
  },
  [refViewTypes.PROGRAMS]: {
    filterable: false,
    fields: ["Program_ID", "Name"],
  },
  [refViewTypes.REQUIREMENTS]: {
    filterable: true,
    fields: ["Requirement_ID", "Description", "Type"],
  },
  [refViewTypes.COMPLIANCE]: {
    filterable: true,
    fields: ["Compliance_ID","Statement", "Requirement_Description", "Requirement_Type"],
  },
  [refViewTypes.CONTROLS]: {
    filterable: true,
    fields: ["Control_ID", "Identifier", "Group_Name"],
  }
};

export {
  assessmentBuilderModes,
  abSetupSteps,
  controlsCompNames,
  regFrameworkScopedComponentNames,
  programDocCompNames,
  programsCompNames,
  complianceCompNames,
  requirementCompNames,
  allViewCompNames,
  compNamesByAssessRefType,
  customCompObj,
  assesssRefItemsWithTabDisplay,
  refViewTypes,
  regFrameworkAssessmentItemTypes,
  keysByViewType,
  reviewPageModes,
  dataCollectionStatus,
  assessBuilderStatus,
  dataCollectionTypeIds,
  assessmentTypeIds,
  responseSources,
  assessmentTableIdMap,
  updateAssessmentMapping,
  reviewResponseTypes,
  assessmentSourceTableInfo,
  controlAbbreviatedField,
};
