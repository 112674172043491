import { Box } from "@mui/material";
import React from "react";
import { PulseLoader } from "react-spinners";
import { CheckCircleOutline } from "@mui/icons-material";
import { makeStyles, useTheme } from "mui-styles";

const useStyles = makeStyles((theme) => ({
  saveSuccess: {
    color: theme.palette.success.dark,
    fontSize: theme.typography.h4.fontSize,
  },
  publishSuccess: {
    color: theme.palette.secondary.dark,
    fontSize: theme.typography.h4.fontSize,
  },
  error: {
    color: theme.palette.text.error,
  },
}));

const SaveStatus = props => {
  const classes = useStyles();
  const theme = useTheme();
  const { isSaving, errorMessage, variant, infoMessage, saveMessage } = props;

  if (variant === "builder") {
    return (
      <Box display="flex" height={16} alignItems="center" alignSelf="center">
        {errorMessage ? (
          <span className={classes.error}>{errorMessage}</span>
        ) : (
          <>
            {isSaving === "saving" && (
              <>
                <Box paddingRight={0.5}>Saving</Box>
                <PulseLoader
                  size={6}
                  color={theme.palette.tertiaryGrey.main}
                />
              </>
            )}
            {!!saveMessage && (
              <>
                <Box paddingRight={0.5} data-cy="builder-saved">
                  {saveMessage}
                </Box>
                <CheckCircleOutline className={classes.saveSuccess} />
              </>
            )}
            {!!infoMessage && (
              <>
                <Box paddingRight={0.5}>{infoMessage}</Box>
                <CheckCircleOutline className={classes.publishSuccess} />
              </>
            )}
          </>
        )}
      </Box>
    );
  } else {
    return "";
  }
};

export default SaveStatus;
