import React, { useContext, useEffect, useMemo } from "react";
import useNumericParams from "hooks/useNumericParams";
import { Box } from "@mui/material";
import BackToLink from "components/utils/backToLink.component";
import BuilderType from "components/layout/comp-types/builderType.component";
import { CustomComponent } from "components/types/registry.component";
import DataTableType from "components/layout/comp-types/dataTableType.component";
import DocumentType from "components/layout/comp-types/documentType.component";
import TableType from "components/layout/comp-types/tableType.component";
import PageHeader from "components/utils/pageHeader.component";
// import { isGlobalUser } from "utils/roles.utils";
// services
import { generatePath, matchPath, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  customComponentObjectHeadings,
  PROGRAM_COMPONENT_TYPE_REDIRECT,
  COMPONENT_OBJECT_TYPE_CUSTOM,
  COMPONENT_OBJECT_TYPE_BUILDER,
  COMPONENT_OBJECT_TYPE_DATA_TABLE,
  COMPONENT_OBJECT_TYPE_DOCUMENT_LIBRARY,
  COMPONENT_OBJECT_TYPE_DOCUMENT,
  COMPONENT_OBJECT_TYPE_TABLE,
} from "components/constants/program.constants";
import { DocumentLibraryType } from "../comp-types/documentLibraryType.component";
import { PATH_COMPONENT_OBJECT, PATH_PROGRAM_COMPONENT } from "components/layout/constants/standardPaths.constants";
import { isDevelopmentEnv } from "core/environment";
import ProgramsContext from "contexts/programs.context";

const HIDDEN_TITLE_PROGRAM_COMPONENTS_SET = new Set(["claims", "workflows"]);

const titleOnlyIds = [
];

const excludedBackLinkIds = [
];


const ComponentObjectPage = () => {
  const location = useLocation();
  const { programId, programComponentId, componentObjectId } = useNumericParams();
  const { state } = useContext(ProgramsContext);

  const programComponent = state.programComponents?.[programComponentId];
  const componentObject = state.componentObjects?.[componentObjectId];
  const customHeading = customComponentObjectHeadings[componentObjectId];

  const isComponentObjectPageMatch = useMemo(() => (
    matchPath(
      location.pathname,
      { path: PATH_COMPONENT_OBJECT, strict: true, exact: true }
    )
  ), [location.pathname]);

  const isHiddenTitleComponent = useMemo(() => (
    HIDDEN_TITLE_PROGRAM_COMPONENTS_SET.has(programComponentId)
  ), [programComponentId]);

  // const isGlobalUserRole = useMemo(isGlobalUser, []);

  useEffect(function checkInvalidParamsPair() {
    if (isDevelopmentEnv && programComponent && componentObject) {
      const parentComponentId = programComponent.programComponentId;
      if (componentObject.programComponentId !== parentComponentId) {
        throw new Error(
          "Invalid URL: program component and component object do not match."
        );
      }
    }
  }, [componentObject, programComponent]);

  if (programComponent && componentObject?.type) {
    return (
      <div>
        {(
          componentObject.type !== COMPONENT_OBJECT_TYPE_BUILDER &&
          !isHiddenTitleComponent &&
          isComponentObjectPageMatch
        ) ? (
            <>
              <PageHeader
                title={customHeading || componentObject.label}
                titleOnly={
                  !!customHeading ||
                  titleOnlyIds.includes(componentObject.componentObjectId)
                }
              />
              {(
                programComponent.type !== PROGRAM_COMPONENT_TYPE_REDIRECT &&
                componentObject.type === COMPONENT_OBJECT_TYPE_CUSTOM &&
                programComponent.typeRef !== componentObject.typeRef &&
                !excludedBackLinkIds.includes(
                  componentObject.componentObjectId
                )
              ) && (
                <BackToLink
                  href={generatePath(PATH_PROGRAM_COMPONENT, {
                    programId,
                    programComponentId
                  })}
                  parentPage={programComponent.label}
                />
              )}
            </>
          ) : (
            <Box height={8} />
          )
        }
        <Box marginTop={2}>
          <ComponentObject
            componentObject={componentObject}
            programComponent={programComponent}
            programId={programId}
          />
        </Box>
      </div>
    );
  }
  return null;
};

function ComponentObject(props) {
  const { programId, programComponent, componentObject } = props;
  switch (componentObject?.type) {
    case COMPONENT_OBJECT_TYPE_CUSTOM:
      return (
        <CustomComponent
          typeRef={componentObject?.typeRef || programComponent?.typeRef}
        />
      );
    case COMPONENT_OBJECT_TYPE_DATA_TABLE:
      return (
        <DataTableType
          componentObject={componentObject}
          programId={programId}
        />
      );
    case COMPONENT_OBJECT_TYPE_DOCUMENT_LIBRARY:
      return (
        <DocumentLibraryType
          componentObject={componentObject}
        />
      );
    case COMPONENT_OBJECT_TYPE_DOCUMENT:
      return (
        <DocumentType
          componentObject={componentObject}
        />
      );
    case COMPONENT_OBJECT_TYPE_BUILDER:
      return (
        <BuilderType
          componentObject={componentObject}
          parentComponent={programComponent}
        />
      );
    case COMPONENT_OBJECT_TYPE_TABLE:
      return (
        <TableType componentObject={componentObject} />
      );
    default:
      return null;
  }
}

export default ComponentObjectPage;
