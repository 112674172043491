import React, { useMemo } from "react";
import { makeStyles, useTheme } from "mui-styles";
import { Grid } from "@mui/material";
import PulseLoader from "react-spinners/PulseLoader";
import MoonLoader from "react-spinners/MoonLoader";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
  loadWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  fullPage: {
    height: "calc(100vh - 240px)",
    alignSelf: "center",
    flex: 1,
  },
}));

/*
 * Todo: This component is broken without very specific parent elements
 * containing it (temporary workarounds are applied when prop `inline` = true)
 */
export const Loader = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const color = useMemo(() => {
    if (props.color) {
      return props.color;
    }
    if (props.variant === "search") {
      if (props.inline) {
        return theme.palette.tertiaryGrey.light;
      }
      return theme.palette.tertiaryGrey.main;
    }
    return theme.palette.primary.main;
  }, [props.color, props.inline, props.variant, theme]);

  if (props.variant === "search") {
    return (
      <div
        className={
          classNames(
            classes.loadWrap,
            props.inline && classes.inline,
            !props.inline && classes.fullPage
          )
        }
      >
        <PulseLoader
          size={props.size ? props.size : 17}
          color={color}
        />
      </div>
    );
  } else {
    return (
      <Grid
        item xs={12}
        padding={props.inline && !props.noPadding ? 4 : undefined}
        className={
          classNames(classes.loadWrap, !props.inline && classes.fullPage)
        }
      >
        <div
          style={{ height: props.height }}
          className={props.className}
        >
          <MoonLoader
            size={props.size || 50}
            color={color}
          />
        </div>
      </Grid>
    );
  }
};

export default Loader;
