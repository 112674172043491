import React, { useMemo } from "react";
import { makeStyles } from "mui-styles";
import { Box, Divider, Paper } from "@mui/material";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import AccordionView from "components/utils/accordionView.component";
import ClaimsTable from "components/claims/ClaimsTable.component";
import CustomerInfoSection from "components/pages/consentOrder/claims/customer/card/CustomerInfoSection.component";
import CustomerPopulationSection from "components/pages/consentOrder/claims/customer/card/CustomerPopulationSection.component";
import CustomerStatusSection from "components/pages/consentOrder/claims/customer/card/CustomerStatusSection.component";
import ContactLog from "components/claims/contactLog/contactLog.component";
import CustomerLoansSection from "components/pages/consentOrder/claims/customer/card/CustomerLoansSection.component";
import CustomerLoansAccordionSection from "components/pages/consentOrder/claims/customer/accordion/CustomerLoansAccordionSection.component";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px 24px 0",
    fontSize: theme.typography.body2.fontSize,
  },
  accordion: {
    boxShadow: "none"
  },
  accordionSummary: {
    display: "flex",
    justifyContent: "center",
    padding: 0,
  },
  accordionSummaryContent: {
    flex: "0 0 auto",
    fontWeight: theme.typography.body2.fontWeight
  },
  accordionDetail: {
    paddingRight: 0,
    paddingLeft: 0
  }
}));

export default function CustomerClaimCard(props) {
  const classes = useStyles();
  const {
    customer,
    state,
    workflowNodesById,
    workflowTemplates,
    populationTypes,
    customerDistributions
  } = props;

  const customerClaims = customer._associations.Claim;

  const customerClaimLogs = useMemo(() => (
    customerClaims.flatMap((claim) => claim._associations.ContactLog)
  ), [customerClaims]);

  return (
    <Paper className={classes.root} elevation={2} padding={3} spacing={3}>
      <GridContainer spacing={2} hasVerticalSpacing marginBottom={2}>
        <GridItem xl={4} md={6} xs={12}>
          <CustomerInfoSection
            customer={customer}
          />
        </GridItem>
        <GridItem xl={4} md={6} xs={12}>
          <GridContainer spacing={2} hasVerticalSpacing>
            <GridItem xs={12} lg={6}>
              <CustomerStatusSection
                workflowNodesById={workflowNodesById}
                workflowTemplates={workflowTemplates}
                customerClaims={customerClaims}
              />
            </GridItem>
            <GridItem xs={12} lg={6}>
              <CustomerPopulationSection
                customerClaims={customerClaims}
                customerDistributions={customerDistributions}
                populationTypes={populationTypes}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xl={4} xs={12}>
          <CustomerLoansSection customer={customer} />
        </GridItem>
      </GridContainer>
      <Divider />
      <AccordionView
        classes={{
          root: classes.accordion,
          summary: classes.accordionSummary,
          content: classes.accordionSummaryContent,
          detail: classes.accordionDetail
        }}
        mainLabel={(
          <Box marginLeft="auto" marginRight={2}>
            Claims
            ({customerClaims.length})
          </Box>
        )}
        autoScroll
        dense
        noHoverHighlight
      >
        <ClaimsTable
          claims={customerClaims}
          state={state}
          title="Customer Claims"
        />
      </AccordionView>
      <Divider />
      <AccordionView
        classes={{
          root: classes.accordion,
          summary: classes.accordionSummary,
          content: classes.accordionSummaryContent,
          detail: classes.accordionDetail
        }}
        mainLabel={(
          <Box marginLeft="auto" marginRight={2}>
            Loan Distributions
            ({customer?._associations?.DistributionDataset?.length || "0"})
          </Box>
        )}
        autoScroll
        dense
        noHoverHighlight
      >
        <CustomerLoansAccordionSection customer={customer} />
      </AccordionView>
      <Divider />
      <AccordionView
        classes={{
          root: classes.accordion,
          summary: classes.accordionSummary,
          content: classes.accordionSummaryContent,
          detail: classes.accordionDetail
        }}
        mainLabel={(
          <Box marginLeft="auto" marginRight={2}>
            Transmission Log
            ({customerClaimLogs?.length})
          </Box>
        )}
        autoScroll
        dense
        noHoverHighlight
      >
        <ContactLog
          contactLog={customerClaimLogs}
          customerClaims={customerClaims}
        />
      </AccordionView>
    </Paper>
  );
}
