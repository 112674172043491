import { FormHelperText } from "@mui/material";
import classNames from "classnames";
import { makeStyles } from "mui-styles";

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.badge.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: theme.typography.lineHeight.proportional1,
    textAlign: "right",
    borderRadius: "0px 0px 3px 3px",
    padding: "2px 5px 4px",
    margin: "-2px 0 0",
    minHeight: 17,
    width: "100%",
    "&.Mui-error": {
      color: `white`,
      backgroundColor: `${theme.palette.error.main}`,
    },
  },
  emptyErrorMessage: {
    padding: 0,
    minHeight: 0
  },
}));


export default function CustomFormHelperText(props) {
  const classes = useStyles();
  const { children, className, error, ...muiProps } = props;
  return (
    <FormHelperText
      {...muiProps}
      className={
        classNames(
          classes.root,
          !!error && !children && classes.emptyErrorMessage,
          className
        )
      }
      error={error}
    >
      {children}
    </FormHelperText>
  );
}
