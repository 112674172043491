export const WORKFLOW_TEMPLATE_ID_RECEIPT = (
  "ce7d9c05-15bf-4f52-94c0-aae0981fcc86"
);
export const WORKFLOW_TEMPLATE_ID_ADJUDICATION = (
  "ef744d85-fda7-40e1-ad9b-c7ebecafdcf5"
);
export const WORKFLOW_TEMPLATE_ID_CLAIMS_PAYMENT = (
  "1b15e0c1-a72a-4ae7-a09c-c038c46743d6"
);
export const WORKFLOW_TEMPLATE_ID_INITIAL_PAYMENT = (
  "8862312d-12bd-489d-af19-9b9fbe2db64e"
)

export const WORKFLOW_NODE_ID_INITIAL_PAYMENT_PAID = (
  "e125fa0f-1ce2-4b06-a787-b8f4242d724b"
);

export const WORKFLOW_NODE_ID_FIRST_REVIEW = (
  "d1ddfd47-5c5e-4806-810b-5f39e680cd98"
);
export const WORKFLOW_NODE_ID_ENGAGE_CLAIMANT = (
  "6001e0c9-80db-4f13-8177-73b5931a4372"
)
export const WORKFLOW_NODE_ID_UPLOAD_MAILED_DOCS = (
  "a452e6c6-4713-472f-abc0-2185a3066269"
);
export const WORKFLOW_NODE_ID_SECOND_REVIEW = (
  "87c97405-538b-4609-91c5-2a9c699083b9"
);

export const WORKFLOW_NODE_ID_UNDER_REVIEW = (
  "ba765e8e-7a71-4034-8672-b2b9e2c00c17"
);
export const WORKFLOW_NODE_ID_ENGAGE_CLAIMANT_ADJUDICATION = (
  "aff53bcc-4f00-43cc-8634-1a49b089387b"
);
export const WORKFLOW_NODE_ID_TRANSMIT_DECISION = (
  "747517ce-457d-4146-8048-3691efd60732"
);

export const WORKFLOW_NODE_ID_INITIATE_PAYMENT = (
  "f284ab5c-5038-4015-8796-a9a2f3a41b4a"
);
export const WORKFLOW_NODE_ID_REISSUING_PAYMENT = (
  "ed0b8eb5-25ba-43f2-8447-607d152fe3d6"
);
export const WORKFLOW_NODE_ID_REISSUE_PAYMENT_DETERMINE_SUCCESS = (
  "2c1ac0b7-9f52-4490-8a28-bc2ec2e4fe5c"
);
export const WORKFLOW_NODE_ID_ADDITIONAL_PAYMENT_INFO = (
  "76c634eb-6b73-4510-b871-41b73070a494"
);
