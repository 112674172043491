import TaskService from "services/Task.service";
import { getCurrentUserId } from "services/util/authSession.utils";
import moment from "moment";

const updateOrCreateTask = async (formMode, task, taskIdToUpdate, taskReferences) => {
  if (formMode === "create") {
    const createResponse = await TaskService.saveNewTask(task, taskReferences);
    const newTask = { ...task, Task_ID: createResponse.data.newTaskId };
    return newTask;
  } else {
    const updateResponse = await TaskService.updateTask(task, taskIdToUpdate);
    return updateResponse.payload;
  }
};

const taskParentIDFinder = (formType, formMode, task, parent) => {
  if (formType === "task") {
    return null;
  } else {
    return formMode === "create" ? task.Task_ID : parent.id;
  }
};

const taskProgramIdFinder = (wpProgramId, programID, wpProp) => {
  if (wpProp) {
    return wpProp.Program_Program_ID;
  } else {
    return !wpProgramId ? programID : wpProgramId;
  }
};

const taskFormTitle = (type, mode, comp, programName, referenceTitle) => {
  let action = mode;
  let taskType = "";
  let compOrProgName = "Program";
  if (programName && programName !== "Orchestration") {
    compOrProgName = programName;
  }
  if (mode === "create") {
    action = "Add";
  } else if (mode === "edit") {
    action = "Edit";
  }

  if (type === "subTask") {
    taskType = "Sub-Task";
  } else {
    taskType = "Task";
  }

  if (referenceTitle) {
    return `${action} ${referenceTitle} ${taskType}`
  }

  if (comp) {
    compOrProgName = comp.Name;
  }

  return `${action} ${compOrProgName} ${taskType}`;
};

const wpTaskFormTitle = (formType, formMode) => {
  let action = formMode;
  let taskType = "";
  if (formMode === "create") {
    action = "Add New";
  }
  if (formMode === "edit") {
    action = "Edit";
  }
  if (formType === "subTask") {
    taskType = "Sub-Task";
  } else {
    taskType = "Task";
  }
  return `${action} ${taskType}`;
};

// Creates the taskReferences object with Cheange_User and Change_Date values
const makeTaskReferenceBasicData = () => ({
  Reference_ID: null,
  Task_ID: null,
  TableMap_ID: null,
  Ref_ID: null,
  Column_Name: null,
  Change_User: getCurrentUserId(),
  Change_Date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
});

export {
  updateOrCreateTask,
  taskParentIDFinder,
  taskProgramIdFinder,
  taskFormTitle,
  wpTaskFormTitle,
  makeTaskReferenceBasicData,
};
