import { makeStyles } from "mui-styles";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import LabelValuePair from "components/utils/labelValuePair.component";
import { useContext, useMemo } from "react";
import classNames from "classnames";
import FilterRadioCard from "components/pages/consentOrder/claims/shared/FilterRadioCard.component";
import { formatDisplayNumber } from "utils/localization.utils";
import StatisticsContext from "contexts/statistics.context";

const useStyles = makeStyles(theme => ({
  overallTotalsGrid: {
    alignItems: "baseline",
    height: "100%",
    paddingTop: 4,
    textAlign: "left",
    [theme.breakpoints.down("xl")]: {
      paddingTop: 0
    },
  },
  detailColumn: {
    marginTop: "auto",
    [theme.breakpoints.only("md")]: {
      marginTop: 0
    }
  },
  detailsLine: {
    whiteSpace: "nowrap",
  },
  detailsLineLarge: {
    [theme.breakpoints.down("xl")]: {
      fontSize: theme.typography.h5.fontSize
    }
  },
  detailsEmpty: {
    display: "flex",
    alignItems: "center",
  }
}));


export default function PopulationRadioCard(props) {
  const classes = useStyles();
  const { checked = false, claims, handleChange, populationType } = props;

  const { state: { populations }} = useContext(StatisticsContext);

  const isDisabled = !claims?.length;

  const populationStats = useMemo(() => {
    const populationCount = populations[populationType]?.claimCount;
    return [{
      label: "Total Claims",
      value: (
        !populationCount && ![0, "0"].includes(populationCount) ?
          null :
          formatDisplayNumber(populationCount)
      )
    }];
  }, [populations, populationType]);

  return (
    <FilterRadioCard
      checked={checked}
      disabled={isDisabled}
      label={`Population ${populationType}`}
      name={populationType}
      onChange={handleChange}
      sublabel={(
        <LabelValuePair
          label="Results"
          value={
            !claims ? null : formatDisplayNumber(claims?.length || 0)
          }
        />
      )}
      value={populationType}
    >
      <GridContainer
        columnSpacing={2}
        className={classes.overallTotalsGrid}
      >
        {!populationStats?.length ? (
          <GridItem
            className={
              classNames(classes.detailColumn, classes.detailsEmpty)
            }
          >
            <em>Overall Totals Unavailable</em>
          </GridItem>
        ) : (
          <>
            {populationStats.map(childGroup => (
              <GridItem
                xs={12}
                xl="auto"
                className={classes.detailColumn}
                key={childGroup.label}
              >
                <LabelValuePair
                  label={childGroup.label}
                  value={childGroup.value ?? "Unknown"}
                  className={classNames(
                    classes.detailsLine,
                    childGroup.isLarge && classes.detailsLineLarge
                  )}
                />
              </GridItem>
            ))}
          </>
        )}
      </GridContainer>
    </FilterRadioCard>
  );
}
