import React, { useCallback, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "mui-styles";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import FormBanner from "components/utils/form-elements/formBanner.component";
import Form from "components/utils/form-elements/form.component";
import { getUploadHttpErrorMessage } from "services/util/http.util";
import useActiveConsentOrder from "hooks/useActiveConsentOrder";
import FileTransferService from "services/FileTransfer.service";
import KeyValueList from "components/pages/consentOrder/fileTransferSummary/shared/keyValueList.component";
import KeyValueListItemStacked from "components/pages/consentOrder/fileTransferSummary/shared/keyValueListItemStacked.component";


const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderRadius: 8,
  },
  content: {
    maxHeight: "90vh",
    padding: "0 32px 24px 32px",
    overflow: "auto",
    marginTop: 8,
    marginBottom: theme.layout.height.appBar
  },
  form: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  errorMessage: {
    marginTop: 8,
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "center"
  },
  dualButtonsBar: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: theme.layout.height.appBar,
    background: theme.palette.background.default,
    boxShadow: theme.shadow.appBar
  },
}));

const defaultNoTagsItemList = [{
  displayOrder: 0,
  _meta: {
    clientId: `${(new Date()).getTime()}-pendingItem`
  }
}];

const FIELD_NAME_DELIMITER = "|";


export default function FileTransferTagModal(props) {
  const classes = useStyles();
  const [activeConsentOrder] = useActiveConsentOrder();

  const { onClose, fileTransfer, setFileTransfer } = props;

  const uploadedTagAttachments = useMemo(() => (
    fileTransfer?._associations?.FileTransferTag?.flatMap?.(tag => (
      tag._associations?.FileTransferAttachment
    )) || []
  ), [fileTransfer?._associations?.FileTransferTag]);

  const [deletedAttachments, setDeletedAttachments] = useState([]);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [message, setMessage] = useState("");

  const handleChange = useCallback(() => {
    setIsFormChanged(true);
  }, []);

  const handleDeleteItem = useCallback(fileTransferTag => {
    const attachments = fileTransferTag?._associations?.FileTransferAttachment;
    if (attachments?.length) {
      setDeletedAttachments(prev => ([...prev, ...attachments]));
    }
    setIsFormChanged(true);
  }, [setDeletedAttachments]);

  const handleDeleteAttachment = useCallback(attachment => {
    setDeletedAttachments(prev => {
      return [...prev, attachment]
    });
    setIsFormChanged(true);
  }, [setDeletedAttachments]);

  const handleUndoDeleteAttachment = useCallback(attachment => {
    setDeletedAttachments(prev => {
      const next = [...prev];
      const deleteIndex = next.findIndex(deletedAttachment => (
        deletedAttachment.name === attachment.name
      ));
      next.splice(deleteIndex, 1);
      return next;
    });
    setIsFormChanged(true);
  }, [setDeletedAttachments]);

  const handleSubmit = useCallback(async (data, formDataInstance) => {
    try {
      setIsUploading(true);
      const body = new FormData();

      const fileTransferData = {
        fileTransferId: fileTransfer.fileTransferId,
        consentOrderId: activeConsentOrder.consentOrderId
      };
      body.append("fileTransfer", JSON.stringify(fileTransferData));

      const deleteAttachmentFileIds = deletedAttachments.map(attachment => (
        attachment.fileTransferAttachmentId
      ));
      body.append(
        "deleteAttachmentFileIds",
        JSON.stringify(deleteAttachmentFileIds)
      );

      const dataEntries = Object.entries(data);
      const tagsByClientId = dataEntries
        .filter(([key]) => key.includes(FIELD_NAME_DELIMITER))
        .map(([key, value]) => {
          const [_firstMatch, id] = key.match(
            /^([\w\d-]+)-((pendingItem)|(savedItem))/
          ) || [];
          if (!id) {
            return [];
          }
          const [_secondMatch, columnName] = key.match(
            new RegExp(`.+\\${FIELD_NAME_DELIMITER}(.+)`)
          ) || [];
          return [columnName, value, id];
        })
        .filter(([_columnName, _value, id]) => id)
        .reduce((accumulator, [columnName, value, id]) => ({
          ...accumulator,
          [id]: {
            ...accumulator[id],
            [columnName]: value
          }
        }), {});
      const tags = Object.values(tagsByClientId || {})
        .filter(tag => tag.label)
        .sort((t1, t2) => t1.displayOrder - t2.displayOrder);
      body.append("fileTransferTags", JSON.stringify(tags));

      const fileTransferAttachments = [];
      const presentAttachmentsSet = new Set();
      const formAttachments = formDataInstance.getAll("attachments");
      if (formAttachments?.length) {
        formAttachments.filter(file => file.name).forEach(file => {
          const [index, fileName] = file.name.split(FIELD_NAME_DELIMITER);
          body.append(index, file, fileName);
          const tag = tags[parseInt(index)];
          fileTransferAttachments.push({
            fileTransferTagId: tag.fileTransferTagId,
            title: data[`title-${fileName}`],
            fileName,
          });
          presentAttachmentsSet.add(fileName);
        });
      }

      const attachmentTitlesByFileRef = Object.fromEntries(
        dataEntries.filter(([key]) => (
          key.startsWith("title-")
        )).map(([key, value]) => {
          const [_title, fileRef] = key.split("title-");
          if (presentAttachmentsSet.has(fileRef)) {
            return null;
          }
          return [fileRef, value];
        }).filter(entry => entry)
      );
      const titleOnlyAttachmentUpdates = (
        uploadedTagAttachments.filter(attachment => (
          attachmentTitlesByFileRef[attachment.fileRef]
        )).map(attachment => ({
          ...attachment,
          title: attachmentTitlesByFileRef[attachment.fileRef]
        }))
      );
      fileTransferAttachments.push(...titleOnlyAttachmentUpdates);

      body.append(
        "fileTransferAttachments",
        JSON.stringify(fileTransferAttachments)
      );

      const response = await FileTransferService.batchUpsertFileTransferTags(body);
      setIsUploading(false);
      await setFileTransfer(response.payload);
      onClose()
    } catch (error) {
      const { status } = error.response || {};
      setMessage(getUploadHttpErrorMessage(status));
      setIsUploading(false);
      throw error;
    }
  }, [
    activeConsentOrder, deletedAttachments, fileTransfer, setFileTransfer,
    onClose, uploadedTagAttachments
  ]);

  return (
    <Grid className={classes.formContainer}>
      <FormBanner>
        Update File Tags
      </FormBanner>
      <div className={classes.content}>
        <Form className={classes.form} onSubmit={handleSubmit}>
          <div>
            <KeyValueList
              primaryKey="fileTransferTagId"
              defaultItems={
                fileTransfer?._associations?.FileTransferTag ||
                defaultNoTagsItemList
              }
              onChange={handleChange}
              onItemDelete={handleDeleteItem}
              onFileDelete={handleDeleteAttachment}
              onFileUndoDelete={handleUndoDeleteAttachment}
              ListItemComponent={KeyValueListItemStacked}
              hasUploads
            />
            {!!message && (
              <div className={classes.errorMessage}>
                {message}
              </div>
            )}
          </div>
          <div className={classes.dualButtonsBar}>
            <DualFormButtons
              cancelOnClick={onClose}
              variant="publish"
              disabled={!isFormChanged}
              publishLabel="Update"
              isPublishing={isUploading}
              type="submit"
            />
          </div>
        </Form>
      </div>
    </Grid>
  );
}

