import React, { useContext, useMemo } from "react";
import { makeStyles } from "mui-styles";
import InlineSVGIcons from "components/utils/inlineSVGIcons.component";
import classNames from "classnames";
import useNumericParams from "hooks/useNumericParams";
import ProgramsContext from "contexts/programs.context";
import { componentObjectIconMapping } from "components/constants/program.constants";

const useStyles = makeStyles((theme) => ({
  messageWrapper: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    minHeight: 100,
    justifyContent: "center",
    color: theme.palette.text.secondary,
    fill: theme.palette.text.secondary,
    paddingTop: 160,
    fontSize: theme.typography.h4.fontSize,
    opacity: 0.8,
  },
  enterpriseMessage: {
    paddingTop: 0,
    marginTop: 48,
    fontSize: theme.typography.h5.fontSize,
  },
  messageIcon: {
    height: 22,
    paddingLeft: 8,
  },
}));

const NoBuildersMessage = ({ componentObject, isEnterpriseBuilder }) => {
  const classes = useStyles();
  const { programId } = useNumericParams();
  const { state } = useContext(ProgramsContext);
  const compTypeRef = componentObject.typeRef.split("|")[0];

  const displayMessage = useMemo(() => {
    const builderType = compTypeRef.replace("Program", "");
    if (isEnterpriseBuilder) {
      return `No Enterprise Level ${builderType} drafts to display.`;
    }
    const programName = state.programs?.[programId]?.label;
    return `No ${programName} ${builderType} drafts to display.`;
  }, [compTypeRef, isEnterpriseBuilder, programId, state.programs]);

  return (
    <div
      className={classNames(
        classes.messageWrapper,
        isEnterpriseBuilder ? classes.enterpriseMessage : undefined
      )}
    >
      {displayMessage}
      <span className={classes.messageIcon}>
        {!!componentObjectIconMapping[componentObject.name] && (
          <InlineSVGIcons
            variant={componentObjectIconMapping[componentObject.name]}
          />
        )}
      </span>
    </div>
  );
};

export default NoBuildersMessage;
