import { Search } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import ButtonDefault from "components/utils/buttonDefault.component";
import Form from "components/utils/form-elements/form.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import { makeStyles } from "mui-styles";

const useStyles = makeStyles(theme => ({
  adornment: {
    "&:focus": {
      background: theme.palette.secondary.highlight
    }
  }
}));

export default function SearchFormStandalone(props) {
  const { disabled = false, name, onChange, onSubmit, placeholder } = props;
  const classes = useStyles();

  return (
    <Form name={name.trim()} onSubmit={onSubmit}>
      <GridContainer
        spacing={2}
        alignItems="center"
        width="100%"
        flex={1}
      >
        <GridItem xs>
          <LabelInput
            name="search"
            placeholder={placeholder}
            defaultValue=""
            variant="default"
            onChange={onChange}
            startAdornment={(
              <InputAdornment position="start" className={classes.adornment}>
                <Search />
              </InputAdornment>
            )}
            readOnly={false}
            autoFocus
            hideHelperText
          />
        </GridItem>
        <GridItem>
          <ButtonDefault
            type="submit"
            background="primary"
            disabled={disabled}
          >
            Search
          </ButtonDefault>
        </GridItem>
      </GridContainer>
    </Form>
  )
}
