import { HTTP_STATUS_TOO_LARGE, HTTP_STATUS_UNSUPPORTED_MEDIA_TYPE, HTTP_STATUS_VIRUS_DETECTED } from "services/constants/response.constants";

export function getUploadHttpErrorMessage(status) {
  switch (status) {
    case HTTP_STATUS_TOO_LARGE:
      return (
        "Unable to upload file. File is likely too large, " +
        "please reduce file size or choose a different one and try again."
      );
    case HTTP_STATUS_UNSUPPORTED_MEDIA_TYPE:
      return "Invalid file type. Please verify the file extension is correct.";
    case undefined:
    case null:
      return null;
    case HTTP_STATUS_VIRUS_DETECTED:
      return "Virus detected. Please inspect the file."
    default:
      return "An unknown error occured. Please refresh the page and try again.";
  }
}
