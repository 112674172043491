import theme from "theme/theme";
import ClipLoader from "components/utils/clipLoad.component";
import { Box } from "@mui/material";
import { makeStyles } from "mui-styles";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
  loaderWrapper: {
    paddingLeft: 4,
  },
  height: {
    height: 16,
  },
}));

const IsProcessing = (props) => {
  const classes = useStyles();
  const {
    message,
    classes: classesProp = {},
    loaderSize,
    loaderColor,
    height,
    show = false,
  } = props;
  return (
    <Box
      display="inline-flex"
      className={classNames(height || classes.height, classesProp.root)}
    >
      {!!show && (
        <>
          <Box>{message}</Box>
          <Box className={classesProp.loaderWrapper || classes.loaderWrapper}>
            <ClipLoader
              size={loaderSize || 12}
              color={loaderColor || theme.palette.text.secondary}
            />
          </Box>{" "}
        </>
      )}
    </Box>
  );
};

export default IsProcessing;
