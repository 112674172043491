//Takes an array of object, and a string to sort through, and sorts STRING values
const sortByStringKey = (arrOfObj, key) => {
  return [...arrOfObj].sort((a, b) => {
    const aValue = a[key];
    const bValue = b[key];
    const aString = (typeof aValue === "string") ? aValue.toLowerCase() : aValue;
    const bString = (typeof bValue === "string") ? bValue.toLowerCase() : bValue;

    if (aString < bString) {
      return -1;
    }
    if (aString > bString) {
      return 1;
    }
    return 0;
  });
};

const reverseSortByStringKey = (arrOfObj, key) => {
  return arrOfObj.sort((a, b) => {
    if (a[key] > b[key]) {
      return -1;
    }
    if (a[key] < b[key]) {
      return 1;
    }
    return 0;
  });
};

//Takes an array of object, and a string to sort through, and sorts INTEGER values
const sortIntByStringKey = (arrOfObj, key) => {
  return arrOfObj.sort((a, b) => {
    const intA = parseInt(a[key]);
    const intB = parseInt(b[key]);
    if (intA < intB) {
      return -1;
    }
    if (intA > intB) {
      return 1;
    }
    return 0;
  });
};

const reverseSortIntByStringKey = (arrOfObj, key) => {
  return arrOfObj.sort((a, b) => {
    const intA = parseInt(a[key]);
    const intB = parseInt(b[key]);
    if (intA > intB) {
      return -1;
    }
    if (intA < intB) {
      return 1;
    }
    return 0;
  });
};

// Simillar to sortIntByStringKey and reverseSortIntByStringKey,
//   but with more checks/options.
const sortValuesByStringKey = (arrOfObj, key, replaceNull) => {
  return arrOfObj.sort((a, b) => {
    let itemA = a[key];
    let itemB = b[key];

    if (!itemA) {
      itemA = replaceNull || "";
    }
    if (!itemB) {
      itemB = replaceNull || "";
    }

    itemA = itemA.toLowerCase();
    itemB = itemB.toLowerCase();

    if (itemA < itemB) {
      return -1;
    }
    if (itemA > itemB) {
      return 1;
    }
    return 0;
  });
};

const reverseSortValuesByStringKey = (arrOfObj, key, replaceNull) => {
  return arrOfObj.sort((a, b) => {
    let itemA = a[key];
    let itemB = b[key];

    if (!itemA) {
      itemA = replaceNull || "";
    }
    if (!itemB) {
      itemB = replaceNull || "";
    }

    itemA = itemA.toLowerCase();
    itemB = itemB.toLowerCase();

    if (itemA > itemB) {
      return -1;
    }
    if (itemA < itemB) {
      return 1;
    }
    return 0;
  });
};

const sortByNestedJSONKey = (arrOfObj, jsonKey, key) => {
  return arrOfObj.sort((a, b) => {
    const parsedA = JSON.parse(a[jsonKey]);
    const parsedB = JSON.parse(b[jsonKey]);
    if (parsedA[key] < parsedB[key]) {
      return -1;
    }
    if (parsedA[key] > parsedB[key]) {
      return 1;
    }
    return 0;
  });
};

const sortArrayOfStrings = (arr) => {
  return arr.sort((a, b) => {
    const aString = (typeof a === "string") ? a.toLowerCase() : a;
    const bString = (typeof b === "string") ? b.toLowerCase() : b;
    if (aString < bString) {
      return -1;
    }
    if (aString > bString) {
      return 1;
    }
    return 0;
  });
};

//Sorts an array of objects by First Name + Last Name
const sortByFirstAndLastName = (
  list,
  firtNameField = "First_Name",
  lastNameField = "Last_Name"
) => {
  return list.sort((a, b) => {
    const fullNameA = (
      `${a[firtNameField] || ""}${a[lastNameField] || ""}`
    ).toLowerCase();
    const fullNameB = (
      `${b[firtNameField] || ""}${b[lastNameField] || ""}`
    ).toLowerCase();
    if (fullNameA < fullNameB) {
      return -1;
    }
    if (fullNameA > fullNameB) {
      return 1;
    }
    return 0;
  });
}

// Ex. Arr of objects, where "Orchestration" related objects need to be listed first
const moveToFrontByKey = (arrOfObj, key, matcher) => {
  const filteredByMatcher = arrOfObj.filter((ele) => ele[key] === matcher);
  const nonMatches = arrOfObj.filter((ele) => ele[key] !== matcher);
  return [...filteredByMatcher, ...nonMatches];
};

// Sorts by value returned by Object.entries [key, value]
const sortByObjectEntryValue = (objectEntryArray) => {
  return objectEntryArray.sort((a, b) => {
    if (a[1] < b[1]) {
      return -1;
    }
    if (a[1] > b[1]) {
      return 1;
    }
    return 0;
  });
};

const sortByDateKeys = (arrayOfObj, key, asc = true) => {
  if (asc) {
    return arrayOfObj.sort((objA, objB) => (compareDates(objA[key], objB[key])))
  } else {
    return arrayOfObj.sort((objA, objB) => (compareDates(objB[key], objA[key])))
  }
}

const compareDates = (dateA, dateB) => {
  const aMilliseconds = dateA.valueOf();
  const bMilliseconds = dateB.valueOf();
  if (!aMilliseconds) {
    return bMilliseconds ? 1 : 0;
  }
  if (aMilliseconds && !bMilliseconds) {
    return -1;
  }
  if (aMilliseconds === bMilliseconds) {
    return 0;
  }
  return aMilliseconds < bMilliseconds ? 1 : -1;
};

const compareStringsByLocale = (string1, string2) => (
  string1.localeCompare(string2, "en", { sensitivity: "base" })
);

export {
  sortByStringKey,
  sortIntByStringKey,
  reverseSortIntByStringKey,
  moveToFrontByKey,
  reverseSortByStringKey,
  sortValuesByStringKey,
  sortByNestedJSONKey,
  sortArrayOfStrings,
  reverseSortValuesByStringKey,
  sortByFirstAndLastName,
  sortByObjectEntryValue,
  compareDates,
  compareStringsByLocale,
  sortByDateKeys
};
