import AllClaimsTable from "../dashboard/AllClaimsTable.component";
import SubpageHeader from "../shared/SubpageHeader.component";
import { useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import { H3 } from "components/utils/headerV2.component";
import ReceiptTrackingTable from "components/dataAnalysis/ReceiptTrackingTable.component";
import { ACTION_REPLACE_UPLOAD_ERROR } from "reducers/shared/claim.reducer";
import FileTransferService from "services/FileTransfer.service";

export default function ClaimReceiptTrackingPage(props) {
  const { state, dispatch } = props;

  const uploadErrors = useMemo(() => {
    return (state.fileTransfers || []).flatMap(fileTransfer => (
      fileTransfer._associations.UploadError
    )).filter((item) => (!!item))
  }, [state.fileTransfers])

  const handleNotesChange = useCallback(async (_name, value, id) => {
    try {
      const uploadError = {
        notes: value
      }
      const updatedUploadErrorRes = await FileTransferService.updateUploadErrorNotes(
        id,
        uploadError
      )
      dispatch({
        type: ACTION_REPLACE_UPLOAD_ERROR,
        payload: updatedUploadErrorRes.payload
      })
    } catch (error) {
      console.error(error)
    }
  }, [dispatch])

  return (
    <>
      <SubpageHeader
        title="Claim Receipt Tracking"
      />
      <Box paddingBottom={4}>
        <Box paddingBottom={2}>
          <H3 color="tertiary">Upload Errors</H3>
        </Box>
        <ReceiptTrackingTable
          fileTransfers={state.fileTransfers}
          uploadErrors={uploadErrors}
          handleNotesChange={handleNotesChange}
        />
      </Box>
      <AllClaimsTable state={state} titleHeader="Filed Claims" />
    </>
  )
}