import { isDevelopmentEnv } from "core/environment";
import { removeObjInArray } from "utils/arrayOfObjects.utils";

export const ACTION_SET_USER_ALERTS = "user_alerts__set";
export const ACTION_SET_PROGRAM_ALERT_COUNT = "alerts__program__count__set";
export const ACTION_SET_COMPONENT_OBJECT_ALERT_COUNT =
  "alerts__component_object__count__set";
export const ACTION_ADD_USER_ALERT = "user_alerts__add";
export const ACTION_ADD_USER_ALERTS = "user_alerts__add_many";

// For legacy endpoints that don't return relevant alerts
export const ACTION_REFETCH_USER_ALERTS = "user_alerts__refetch";

export const ACTION_REMOVE_USER_ALERT = "user_alerts__remove";

export const alertInitialState = {
  componentObjectAlertCount: 0,
  isRefetching: false,
  programAlertCount: 0,
  userAlerts: null
};


export default function alertReducer(state, action) {
  switch (action.type) {
    case ACTION_SET_COMPONENT_OBJECT_ALERT_COUNT:
      return {
        ...state,
        componentObjectAlertCount: action.payload
      };
    case ACTION_SET_PROGRAM_ALERT_COUNT:
      return {
        ...state,
        programAlertCount: action.payload
      };
    case ACTION_SET_USER_ALERTS:
      return {
        ...state,
        isRefetching: false,
        userAlerts: action.payload
      };
    case ACTION_ADD_USER_ALERT:
      return {
        ...state,
        isRefetching: false,
        userAlerts: [...state.userAlerts, action.payload]
      }
    case ACTION_ADD_USER_ALERTS:
      return {
        ...state,
        isRefetching: false,
        userAlerts: [...state.userAlerts, ...action.payload]
      }
    case ACTION_REFETCH_USER_ALERTS: {
      return {
        ...state,
        isRefetching: true,
        userAlerts: null
      };
    }
    case ACTION_REMOVE_USER_ALERT:
      return {
        ...state,
        isRefetching: false,
        userAlerts: [
          ...removeObjInArray(
            state.userAlerts || [],
            "userAlertId",
            action.payload
          )
        ]
      };
    default:
      if (isDevelopmentEnv) {
        throw new Error(
          `Unrecognized action in AlertReducer: "${action.type}"`
        )
      }
      return state;
  }
}
