import { Box } from "@mui/material";
import { PATH_PROGRAM_COMPONENT } from "components/layout/constants/standardPaths.constants";
import BackToLink from "components/utils/backToLink.component";
import PageHeader from "components/utils/pageHeader.component";
import { useMemo } from "react";
import { generatePath, useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function SubpageHeader(props) {
  const { title, backToPath = PATH_PROGRAM_COMPONENT, parentPage = "Overview" } = props;
  const { programId, programComponentId } = useParams();

  const backPath = useMemo(() => (
    generatePath((backToPath), {
      programId,
      programComponentId
    })
  ), [programId, programComponentId, backToPath]);

  return (
    <Box whiteSpace="nowrap">
      <PageHeader
        title={title}
        titleOnly
      />
      <Box width="fit-content" position="relative" zIndex={1}>
        <BackToLink
          href={backPath}
          parentPage={parentPage}
        />
      </Box>
    </Box>
  );
}
