import ButtonDefault from "components/utils/buttonDefault.component";
import React from "react";
import { makeStyles } from "mui-styles";
import { H2, H3 } from "components/utils/headerV2.component";
import rmsLogo from "assets/images/rms-logo.png";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 48
  },
  logoContainer: {
    height: 80,
    width: "100%",
    display: "block",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundImage: "url(" + rmsLogo + ")",
    marginBottom: 48
  },
  textContainer: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: "600px",
    margin: 20
  },
  logoutButton: {
    marginTop: 48
  }
}))

const ContactAdmin = (props) => {
  const { errorMessage } = props;
  const classes = useStyles();

  return (
    <div
      className={classes.formContainer}
    >
      <div className={classes.logoContainer} />
      <H2>
        <em>Error:</em> {errorMessage || "Requested Program Unavailable"}
      </H2>
      <H3 className={classes.textContainer}>
        Sorry, an unexpected error has occurred.
        Please contact the website administrator and try again later.
      </H3>
      <ButtonDefault
        href="/logout"
        variant="large"
        className={classes.logoutButton}
      >
        Log out
      </ButtonDefault>
    </div>
  )
}

export default ContactAdmin;