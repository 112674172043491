import { PATH_DISTRIBUTIONS_BY_POPULATION, PATH_DISTRIBUTIONS_BY_WORKFLOW } from "components/layout/constants/standardPaths.constants";
import CustomCard, { VARIANT_DASHBOARD_PROGRAM } from "components/utils/card.component";
import { makeStyles } from "mui-styles";
import { generatePath, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import { formatDisplayNumber } from "utils/localization.utils";
import { pluralize } from "utils/string.utils";
import LabelValuePair from "components/utils/labelValuePair.component";
import Loader from "components/utils/loader.components";
import { Box } from "@mui/material";
import { useContext, useMemo } from "react";
import StatisticsContext from "contexts/statistics.context";

const useStyles = makeStyles(theme => ({
  cardContent: {
    flex: 1,
    paddingLeft: 16,
    paddingRight: 16,
    textAlign: "center",
    pointerEvents: "none",
  },
  countItemList: {
    padding: "8px 16px",
    background: theme.palette.grey.light,
    fontSize: theme.typography.h6.fontSize,
  },
  countItem: {
    display: "flex",
    justifyContent: "space-between",
    padding: 0,
  },
  card: {
    minWidth: 300,
  }
}));

export default function DataAnalysisDashboard() {
  const params = useParams();
  const classes = useStyles();

  const {
    state: { populations, summaryCounts }
  } = useContext(StatisticsContext);

  const {
    initialPaymentSent,
    initialPaymentCheckCancelled,
    initialPaymentCheckReturned,
    initialPaymentCheckCashed,
  } = (summaryCounts || {});

  const populationNames = useMemo(() => (
    populations && Object.keys(populations).sort()
  ), [populations]);

  return (
    <GridContainer spacing={2} hasVerticalSpacing>
      <GridItem>
        <CustomCard
          variant={VARIANT_DASHBOARD_PROGRAM}
          title="Distribution Workflows"
          url={generatePath(PATH_DISTRIBUTIONS_BY_WORKFLOW, params)}
          className={classes.card}
        >
          <div className={classes.cardContent}>
            <p>View Distribution Information by Workflow status</p>
          </div>
          <div className={classes.countItemList}>
            <LabelValuePair
              label="Initial Check sent"
              value={
                `${formatDisplayNumber(initialPaymentSent?.count)} ` +
                pluralize("Distribution", initialPaymentSent?.count)
              }
              className={classes.countItem}
              isLoading={!populations || !summaryCounts}
            />
            <LabelValuePair
              label="Initial Check cancelled"
              value={
                `${formatDisplayNumber(initialPaymentCheckCancelled?.count)} ` +
                pluralize("Distribution", initialPaymentCheckCancelled?.count)
              }
              className={classes.countItem}
              isLoading={!populations || !summaryCounts}
            />
            <LabelValuePair
              label="Initial Check returned"
              value={
                `${formatDisplayNumber(initialPaymentCheckReturned?.count)} ` +
                pluralize("Distribution", initialPaymentCheckReturned?.count)
              }
              className={classes.countItem}
              isLoading={!populations || !summaryCounts}
            />
            <LabelValuePair
              label="Check cashed"
              value={
                `${formatDisplayNumber(initialPaymentCheckCashed?.count)} ` +
                pluralize("Distribution", initialPaymentCheckCashed?.count)
              }
              className={classes.countItem}
              isLoading={!populations || !summaryCounts}
            />
          </div>
        </CustomCard>
      </GridItem>
      <GridItem>
        <CustomCard
          variant={VARIANT_DASHBOARD_PROGRAM}
          title="Distribution Populations"
          url={generatePath(PATH_DISTRIBUTIONS_BY_POPULATION, params)}
          className={classes.card}
        >
          <div className={classes.cardContent}>
            <p>View Distribution Information by Population</p>
          </div>
          <div className={classes.countItemList}>
            {populationNames ? (
              populationNames.map(name => (
                <LabelValuePair
                  label={`Population ${name}`}
                  value={
                    formatDisplayNumber(populations[name].distributionCount) +
                    " " +
                    pluralize(
                      "Distribution",
                      populations[name].distributionCount
                    )
                  }
                  className={classes.countItem}
                  isLoading={!populations}
                  key={name}
                />
              ))
            ) : (
              <Box marginTop={2} marginBottom={2}>
                <Loader size={32} inline noPadding />
              </Box>
            )}
          </div>
        </CustomCard>
      </GridItem>
    </GridContainer>
  )
}