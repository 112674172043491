import ClaimEvaluation from "components/claims/ClaimEvaluation.component";
import { Box } from "@mui/material";
import { useMemo } from "react";
import SubmittedClaimCard from "./SubmittedClaimCard.component";
import WorkflowDiagramProvider from "components/layout/context/contextProviders/workflowDiagramContextProvider.component";
import ClaimReviewWorkflowDiagram from "./ClaimReviewWorkflowDiagram.component";
import ClaimOutcomesCard from "./ClaimOutcomesCard.component";
import ContactLog from "./contactLog/contactLog.component";
import { H3 } from "components/utils/headerV2.component";
import theme from "theme/theme";
import ClaimReviewWorkflowHistory from "./ClaimReviewWorkflowHistory.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";

const ClaimReviewAccordion = (props) => {
  const {
    claim,
    dispatch,
    claimDocuments,
    claimValueTypesByName,
    evaluationTemplates,
    workflowInstances,
    workflowTemplatesById
  } = props;

  const activeWfInstances = useMemo(() => {
    return workflowInstances.filter((instance) => (instance.status === "Active"))
  }, [workflowInstances])

  return (
    <>
      <Box paddingBottom={6}>
        <Box paddingBottom={2}>
          <H3>Contact Log</H3>
        </Box>
        <ContactLog
          contactLog={claim?._associations?.ContactLog}
          claim={claim}
          dispatch={dispatch}
        />
      </Box>
      <GridContainer spacing={1}>
        <GridItem xs={12} sm={6}>
          <H3>Workflows</H3>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <ClaimReviewWorkflowHistory
            workflowInstances={workflowInstances}
          />
        </GridItem>
      </GridContainer>
      <Box paddingBottom={4}>
        {activeWfInstances.map((wfInstance) => (
          <Box
            key={wfInstance.wfInstanceId}
            marginBottom={4}
            backgroundColor={theme.palette.grey[200]}
            boxShadow={theme.shadow.inset2}
            borderRadius={2}
          >
            <WorkflowDiagramProvider
              workflowTemplate={workflowTemplatesById[
                wfInstance.wfTemplateId
              ]}
            >
              <ClaimReviewWorkflowDiagram
                claim={claim}
                claimWorkflowInstance={wfInstance}
                dispatch={dispatch}
                workflowTemplatesById={workflowTemplatesById}
              />
            </WorkflowDiagramProvider>
          </Box>
        ))}
      </Box>
      <Box
        paddingBottom={2}
      >
        <SubmittedClaimCard
          claim={claim}
          stateDocuments={claimDocuments}
          claimValueTypesByName={claimValueTypesByName}
          dispatch={dispatch}
        />
      </Box>
      <Box
        paddingBottom={2}
      >
        <ClaimEvaluation
          claim={claim}
          claimDocuments={claimDocuments}
          evaluationTemplate={evaluationTemplates[0]}
          dispatch={dispatch}
        />
      </Box>
      <Box
        paddingBottom={2}
      >
        <ClaimOutcomesCard
          claim={claim}
          dispatch={dispatch}
          evaluationTemplate={evaluationTemplates[0]}
        />
      </Box>
    </>
  )
}
export default ClaimReviewAccordion;