import { PATH_COMPONENT_OBJECT, PATH_PROGRAM, PATH_PROGRAM_COMPONENT } from "components/layout/constants/standardPaths.constants";
import moment from "moment";
import { generatePath } from "react-router-dom/cjs/react-router-dom.min";

const alertDueMessage = (
  endDate, startDate = null, alertCount = null, fullDateThreshold = null
) => {
  let days = null;
  let message = "";
  let color = "";
  if (endDate) {
    days = moment(endDate).diff(startDate || moment(), "days");
    if (days > fullDateThreshold) {
      color = "assigned";
      message = `Due ${moment(endDate).format("MMMM DD, YYYY")}`;
    } else if (days > 0) {
      color = "assigned";
      message = `Due in ${days} ${Math.abs(days) === 1 ? "day" : "days"}`;
    } else if (days === 0) {
      color = "caution";
      message = `Due today`;
    } else {
      color = "warning";
      message = `Overdue by ${Math.abs(days)} ${
        Math.abs(days) === 1 ? "day" : "days"
      }`;
    }
  }
  if (alertCount) {
    message = `${alertCount} Alerts`;
  }
  return {
    color,
    message
  }
};

const alertPathFinder = (userAlert) => {
  const {
    Program_Program_ID,
    ProgramComponents_Component_ID,
    ComponentObjects_ComponentObject_ID,
  } = userAlert;
  const associatedTask = userAlert._associations.Tasks?.[0]

  if (associatedTask) { // For task alerts
    const taskId = associatedTask.Task_ID;
    const workpackageId = associatedTask.Workpackages_Workpackage_ID;
    if (workpackageId) {
      return {
        pathname: `/wpTasks/${workpackageId}/program/${Program_Program_ID}`,
        state: { taskID: taskId },
      };
    } else {
      return {
        pathname: `/programTasks/${taskId}/program/${Program_Program_ID}`,
        state: { taskID: taskId }, //Task_Id
      };
    }
  } else if (
    ComponentObjects_ComponentObject_ID &&
    ProgramComponents_Component_ID
  ) {
    return {
      pathname: generatePath(PATH_COMPONENT_OBJECT, {
        programId: Program_Program_ID,
        programComponentId: ProgramComponents_Component_ID,
        componentObjectId: ComponentObjects_ComponentObject_ID
      })
    };
  } else if (ProgramComponents_Component_ID) {
    return {
      pathname: generatePath(PATH_PROGRAM_COMPONENT, {
        programId: Program_Program_ID,
        programComponent: ProgramComponents_Component_ID
      })
    };
  } else {
    return generatePath(PATH_PROGRAM, { programId: Program_Program_ID });
  }
};

export {
  alertDueMessage,
  alertPathFinder,
};
