import ConsentOrderContext from "contexts/consentOrder.context";
import { useContext } from "react";

/*
 * Shortcut for getting the current consent order stored in reducer state.
 * Active order is saved in session storage, data is loaded on page load.
 */
export default function useActiveConsentOrder() {
  const { state } = useContext(ConsentOrderContext);
  const { activeConsentOrderId, consentOrdersById } = state;
  const activeConsentOrder = (
    !activeConsentOrderId ?
      null :
      consentOrdersById?.[activeConsentOrderId]
  );
  return [activeConsentOrder, !consentOrdersById];
}
