import { useCallback, Fragment } from "react";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import { H5 } from "components/utils/headerV2.component";
import CustomLink from "components/utils/link.component";
import { Box, Divider } from "@mui/material";
import theme from "theme/theme";

const FolderJumpLinks = (props) => {
  const { folders, folderHeaderRef } = props;

  const scrollClick = useCallback(
    (folderId) => {
      const targetId = `folder-${folderId}`;
      try {
        const target = folderHeaderRef.current[targetId];
        const scrollYDestination =
          target.getBoundingClientRect().top - theme.layout.height.appBar;
        window.scrollTo(0, scrollYDestination);
      } catch (error) {
        throw new Error(`No ref element found for target "${targetId}".`);
      }
    },
    [folderHeaderRef]
  );

  return (
    <Box marginTop={-8} data-cy="folder-jump-links">
      <Box marginBottom={1}>
        <H5>Jump to Document Group:</H5>
      </Box>
      <GridContainer spacing={2}>
        {folders.map((folder) => {
          return (
            <Fragment key={folder.ProgramDocsFolder_ID}>
              <GridItem>
                <CustomLink
                  onClick={() => scrollClick(folder.ProgramDocsFolder_ID)}
                >
                  {folder.Title}
                </CustomLink>
              </GridItem>

              <GridItem>
                <Divider orientation="vertical" />
              </GridItem>
            </Fragment>
          );
        })}
        <GridItem>
          <CustomLink onClick={() => scrollClick("other")}>Other</CustomLink>
        </GridItem>
      </GridContainer>
    </Box>
  );
};

export default FolderJumpLinks;
