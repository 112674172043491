import { PATH_PROGRAM } from "components/layout/constants/standardPaths.constants";
import useNumericParams from "hooks/useNumericParams";
import { useEffect, useMemo, useRef } from "react";
import { generatePath, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getRoleSingleProgramId } from "utils/roles.utils";

// Program-based authentication redirects based on current user's Role
export default function RoleProgramRedirects(props) {
  const { autoRedirect = false, children, path = PATH_PROGRAM } = props;
  const history = useHistory();
  const { programId } = useNumericParams();
  const isRedirectingRef = useRef(false);

  const singleProgramId = useMemo(getRoleSingleProgramId, []);

  useEffect(function redirectSingleProgramRoles() {
    if (
      singleProgramId &&
      (autoRedirect || singleProgramId !== programId)
    ) {
      isRedirectingRef.current = true;
      history.replace(
        generatePath(path, { programId: singleProgramId })
      );
    }
    isRedirectingRef.current = false;
  }, [autoRedirect, history, path, programId, singleProgramId]);

  if (isRedirectingRef.current) {
    return null;
  }
  return children;
}
