import React, { useMemo } from "react";
import { makeStyles } from "mui-styles";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.secondary.dark,
    fontWeight: "bold",
    alignItems: "center",
    fontSize: theme.typography.body2.fontSize,
    display: "inline-block",
    "&:hover": {
      color: theme.palette.primary.light,
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  iconLink: {
    fill: theme.palette.secondary.dark,
    "&:hover": {
      fill: theme.palette.primary.light,
    },
  },
  linkBar: {
    width: "100%",
    display: "inline-flex",
    color: "white",
    padding: "5px 10px",
    borderRadius: 5,
    fontWeight: "bold",
    alignItems: "center",
    fontSize: theme.typography.caption.fontSize,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  linkBarIcon: {
    display: "inline-flex",
    alignItems: "center",
    fill: "white",
    fontSize: theme.typography.h4.fontSize,
    marginRight: 6,
  },
  secondaryGradient: {
    background:
      `linear-gradient(
        180deg,
        rgba(0, 116, 194, 1) 0%,
        rgba(64,145,206,1) 50%,
        rgba(0, 116, 194, 1) 100%
      )`,
    "&:hover": {
      background: (
        `linear-gradient(180deg,
          rgba(64,145,206,1) 0%,
          rgba(0, 116, 194, 1) 50%,
          rgba(64,145,206,1) 100%
        )`
      ),
    },
  },
  greenGradient: {
    background:
      "linear-gradient(180deg, #393880 0%, #4d4ca4 50%, #393880 100%)",
    "&:hover": {
      background:
        "linear-gradient(180deg, #4d4ca4 0%, #393880 50%, #4d4ca4 100%)",
    },
  },
  primaryGradient: {
    background:
      `linear-gradient(180deg,
        rgba(17, 56, 108, 1) 0%,
        rgba(22, 76, 147, 1) 50%,
        rgba(17, 56, 108, 1) 100%
      )`,
    "&:hover": {
      background:
        `linear-gradient(180deg,
          rgba(22, 76, 147, 1) 0%,
          rgba(17, 56, 108, 1) 50%,
          rgba(22, 76, 147, 1) 100%
        )`,
    },
  },
  tertiaryGradient: {
    background:
      `linear-gradient(180deg,
        rgba(72, 90, 106, 1) 0%,
        rgba(95, 119, 140, 1) 50%,
        rgba(72, 90, 106, 1) 100%
      )`,
    "&:hover": {
      background:
        `linear-gradient(180deg,
          rgba(95, 119, 140, 1) 0%,
          rgba(72, 90, 106, 1) 50%,
          rgba(95, 119, 140, 1) 100%
        )`,
    },
  },
  tealGradient: {
    background:
      `linear-gradient(180deg,
        rgba(0, 130, 155, 1) 0%,
        rgba(0, 163, 182, 1) 50%,
        rgba(0, 130, 155, 1) 100%
      )`,
    "&:hover": {
      background:
        `linear-gradient(180deg,
          rgba(0, 163, 182, 1) 0%,
          rgba(0, 130, 155, 1) 50%,
          rgba(0, 163, 182, 1) 100%
        )`,
    },
  },
  icon: {
    fill: "inherit",
  },
  iconBefore: {
    marginRight: 2,
    order: 0,
    display: "flex",
  },
  iconAfter: {
    marginLeft: 2,
    display: "flex",
    order: 2,
  },
  iconLinkContainer: {
    display: "flex",
    alignItems: "center",
  },
  routerLink: {
    color: theme.palette.secondary.dark,
    textDecoration: "none",
    display: "inline-block",
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  routerLinkBold: {
    color: theme.palette.secondary.dark,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: "bold",
    textDecoration: "none",
    display: "inline-block",
    "&:hover": {
      color: theme.palette.primary.light,
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  underline: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
  children: {
    display: "flex",
    alignItems: "center",
  },
  disabled: {
    color: theme.palette.text.secondary,
    opacity: "0.5",
    "&:hover": {
      cursor: "default",
      color: theme.palette.text.secondary,
      pointerEvents: "none",
      userSelect: "text"
    }
  },
  readOnly: {
    color: theme.palette.text.secondary,
    cursor: "default",
    pointerEvents: "none",
    userSelect: "text",
    "&:hover": {
      color: theme.palette.text.secondary,
    }
  },
  disabledVariant: {
    color: theme.palette.text.secondary,
    opacity: ".5",
    fontSize: theme.typography.body1.fontSize,
    fontWeight: "bold",
    paddingTop: 10,
    display: "block",
    "&:hover": {
      cursor: "default",
    },
  },
  disabledBar: {
    backgroundColor: theme.palette.grey.disabled,
    "&:hover": {
      cursor: "not-allowed",
    },
  },
  iconLinkLabel: {
    order: 1,
    display: "flex",
    marginRight: 16,
    lineHeight: "normal",
  },
}));

const CustomLink = (props) => {
  const classes = useStyles();

  const disabled = useMemo(() => (
    props.disabled || props.readOnly
  ), [props.disabled, props.readOnly]);

  const onClick = useMemo(() => (
    disabled ? () => {} : props.onClick
  ), [disabled, props.onClick])

  const dataProps = useMemo(() => (
    Object.fromEntries(
      Object.entries(props).filter(([key]) => key.startsWith("data-"))
    )
  ), [props]);

  //Overrides all linkBar variants if readOnly
  if (
    props.variant?.includes?.("linkBar") &&
    (props.readOnly || props.disableLinkBar)
  ) {
    return (
      <div
        className={
          classNames(classes.linkBar, props.className, classes.disabledBar)
        }
        data-cy={`disabledLinkBar-${props.test}`}
        {...dataProps}
      >
        <span className={classNames(classes.linkBarIcon, classes.iconBefore)}>
          {props.startIcon}
        </span>
        <span>{props.children}</span>
      </div>
    );
  }

  switch (props.variant) {
    case "iconLink":
    case "iconBeforeLink":
      return (
        <Link
          href={props.href}
          target={props.target}
          className={
            classNames(
              classes.link,
              classes.iconLink,
              disabled && classes.disabled,
              props.className
            )
          }
          onClick={onClick}
          data-cy={`${props.variant}-${props.test}`}
          {...dataProps}
        >
          <div
            className={
              classNames(
                classes.iconLinkContainer,
                props.readOnly && classes.readOnly,
                disabled && classes.disabled,
                props.containerClassName
              )
            }
          >
            <span
              className={classNames(classes.iconLinkLabel, props.className)}
            >
              {props.children}
            </span>
            <span
              className={
                classNames(
                  classes.icon,
                  props.variant === "iconBeforeLink"
                    ? classes.iconBefore
                    : classes.iconAfter,
                  props.iconMarginRight
                )
              }
            >
              {props.startIcon}
            </span>
          </div>
        </Link>
      );
    case "customUnderline":
      return (
        <Link
          href={props.href}
          target={props.target}
          className={
            classNames(
              classes.link,
              props.className,
              classes.underline,
              disabled && classes.disabled
            )
          }
          onClick={onClick}
          data-cy={props.test ? `underlineLink-${props.test}` : undefined}
          {...dataProps}
        >
          <span>{props.children}</span>
        </Link>
      );
    case "underline":
      return (
        <Link
          href={props.href}
          to={props.to}
          target={props.target}
          className={
            classNames(
              classes.link,
              classes.underline,
              props.className
            )
          }
          onClick={props.onClick}
          data-cy={props.test ? `underlineLink-${props.test}` : undefined}
          {...dataProps}
        >
          <span>{props.children}</span>
        </Link>
      );
    case "linkBar":
      return (
        <Link
          href={props.href}
          className={
            classNames(
              classes.linkBar,
              props.className,
              classes.secondaryGradient
            )
          }
          onClick={props.onClick}
          data-cy={`linkBar-${props.test}`}
          {...dataProps}
        >
          <span className={classNames(classes.linkBarIcon, classes.iconBefore)}>
            {props.startIcon}
          </span>
          <span>{props.children}</span>
        </Link>
      );
    case "linkBarGreen":
      return (
        <Link
          href={props.href}
          className={
            classNames(
              classes.linkBar,
              props.className,
              classes.greenGradient
            )
          }
          onClick={props.onClick}
          data-cy={`linkBarGreen-${props.test}`}
          {...dataProps}
        >
          <span className={classNames(classes.linkBarIcon, classes.iconBefore)}>
            {props.startIcon}
          </span>
          <span>{props.children}</span>
        </Link>
      );
    case "linkBarDark":
      return (
        <Link
          href={props.href}
          className={
            classNames(
              classes.linkBar,
              props.className,
              classes.primaryGradient
            )
          }
          onClick={props.onClick}
          data-cy={`linkBarDark-${props.test}`}
          {...dataProps}
        >
          <span className={classNames(classes.linkBarIcon, classes.iconBefore)}>
            {props.startIcon}
          </span>
          <span>{props.children}</span>
        </Link>
      );
    case "linkBarTeal":
      return (
        <Link
          href={props.href}
          className={
            classNames(
              classes.linkBar,
              props.className,
              classes.tealGradient
            )
          }
          onClick={props.onClick}
          data-cy={`linkBarTeal-${props.test}`}
          {...dataProps}
        >
          <span className={classNames(classes.linkBarIcon, classes.iconBefore)}>
            {props.startIcon}
          </span>
          <span>{props.children}</span>
        </Link>
      );
    case "linkBarGray":
      return (
        <Link
          href={props.href}
          className={
            classNames(
              classes.linkBar,
              props.className,
              classes.tertiaryGradient
            )
          }
          onClick={props.onClick}
          data-cy={`linkBarGray-${props.test}`}
          {...dataProps}
        >
          <span className={classNames(classes.linkBarIcon, classes.iconBefore)}>
            {props.startIcon}
          </span>
          <span>{props.children}</span>
        </Link>
      );
    // Todo: Remove and implement real disabled prop for all variants
    case "disabled":
      return (
        <div
          className={classNames(classes.link, classes.disabledVariant)}
          {...dataProps}
        >
          <span data-cy={`disabledLink-${props.test}`}>{props.children}</span>
        </div>
      );
    // Uses React-Router to allow for passing state
    case "routerLink":
      return (
        <RouterLink
          to={props.to}
          onClick={props.onClick}
          className={classNames(classes.routerLink, props.className)}
          data-cy={`routerLink-${props.test}`}
          {...dataProps}
        >
          <span className={classes.children}>
            {!!props.startIcon && (
              <span className={classes.iconBefore}>
                {props.startIcon}
              </span>
            )}
            {props.children}
          </span>
        </RouterLink>
      );
    // Uses React-Router to allow for passing state
    case "routerLinkBold":
      return (
        <RouterLink
          to={props.to}
          className={classNames(classes.routerLinkBold, props.className)}
          onClick={props.onClick}
          data-cy={`routerLinkBold-${props.test}`}
          {...dataProps}
        >
          <span className={classes.children}>{props.children}</span>
        </RouterLink>
      );
    case "noHRef":
      return (
        <div
          className={
            classNames(
              classes.link,
              props.readOnly && classes.readOnly,
              props.disabled && classes.disabled,
              props.className
            )
          }
          onClick={disabled ? () => {} : props.onClick}
          onFocus={disabled ? () => {} : props.onFocus}
          onMouseDown={disabled ? () => {} : props.onMouseDown}
          data-cy={`noHRefLink-${props.test}`}
          tabIndex={disabled ? -1 : props.tabIndex}
          role="button"
          {...dataProps}
        >
          {props.children}
        </div>
      );
    default:
      return (
        <Link
          href={props.href}
          target={props.target}
          className={classNames(classes.link, props.className)}
          onClick={props.onClick}
          data-cy={`defaultLink-${props.test}`}
          {...dataProps}
        >
          {props.children}
        </Link>
      );
  }
};

export default CustomLink;
