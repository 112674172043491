import StorageService from "services/Storage.service";
import UserService from "services/User.service";
const { decodeJwt } = require("jose");

const currentUserInfo = () => {
  const parsedToken = StorageService.getSessionItem("accessToken");
  const decodedToken = decodeJwt(parsedToken);
  if (decodedToken) {
    return decodedToken
  } else {
    return parsedToken;
  }
};

const getCurrentUserId = () => {
  return decodeJwt(StorageService.getSessionItem("accessToken")).id;
};

const findUserNameByID = async (userId) => {
  if (userId) {
    const user = await UserService.getUserById(userId);
    return `${user.payload.First_Name} ${user.payload.Last_Name}`
  }

}

const setUserInfo = (user) => {
  return StorageService.setSessionItem("accessToken", user);
}

const currentUserToken = () => {
  return StorageService.getSessionItem("accessToken");
}

const setRefreshToken = (token) => {
  return StorageService.setSessionItem("refreshToken", token);
}

const currentRefreshToken = () => {
  return StorageService.getSessionItem("refreshToken");
}

const isActiveUser = (user) => user.status === "Active"

export {
  currentUserInfo,
  getCurrentUserId,
  findUserNameByID,
  setUserInfo,
  currentUserToken,
  setRefreshToken,
  currentRefreshToken,
  isActiveUser,
};
