import { PATH_DISTRIBUTIONS_BY_POPULATION, PATH_DISTRIBUTIONS_BY_WORKFLOW, PATH_PROGRAM_COMPONENT } from "components/layout/constants/standardPaths.constants";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";
import { useContext, useEffect } from "react";
import DataAnalysisDashboard from "./DataAnalysisDashboard.component";
import DistributionPopulations from "./DistributionPopulations.component";
import DistributionWorkflows from "./DistributionWorkflows.component";
import StatisticsContext from "contexts/statistics.context";

export default function DataAnalysisPage() {
  const { cancelStatsRequests } = useContext(StatisticsContext);

  useEffect(() => (
    function cancelLargeRequestsOnUnmount() {
      cancelStatsRequests();
    }
  ), [cancelStatsRequests]);

  return (
    <Switch>
      <Route path={PATH_DISTRIBUTIONS_BY_WORKFLOW}>
        <DistributionWorkflows />
      </Route>
      <Route path={PATH_DISTRIBUTIONS_BY_POPULATION}>
        <DistributionPopulations />
      </Route>
      <Route path={PATH_PROGRAM_COMPONENT}>
        <DataAnalysisDashboard />
      </Route>
    </Switch>
  )
}