import React from "react";

/*
 * Value:
 * {
 *   state: statisticsReducer,
 *   dispatch,
 *   cancelStatsRequests,
 * }
 */
const StatisticsContext = React.createContext();

export default StatisticsContext;
