import AjaxService from "services/base/Ajax.service.js";
import { handleSuccess } from "services/util/downloadFile.util";

class FileServiceBase extends AjaxService {
  constructor() {
    super();
    this.logoCache = {};
  }

  // get File details by fileId
  getFileById(id) {
    return this.get(`/file/${id}`).then((response) => response.data);
  }

  // download link for one file by id
  // referenceName should be the desired output file's name
  async downloadFile(id, referenceName) {
    // download file
    const blob = await this.get(`/file/${id}/download`, {
      responseType: "blob",
      timeout: 30000,
    });
    return handleSuccess(blob, referenceName);
  }

  // download link for one file by fileRef
  // referenceName should be the desired output file's name
  async downloadFileByRef(fileRef, referenceName) {
    const blob = await this.get(`/file/ref/${fileRef}/download`, {
      responseType: "blob",
      timeout: 30000,
    });
    return handleSuccess(blob, referenceName);
  }

  // upload logo
  async uploadLogo(data) {
    const response = await this.post(`/file/logo`, data);
    if (response.data.payload?.logoId) {
      delete this.logoCache[response.data.payload.logoId];
    }
    return response;
  }

  // get logo base64
  async getLogo(id) {
    const cachedLogo = this.logoCache[id];
    if (cachedLogo) {
      return Promise.resolve({ data: { payload: cachedLogo }});
    }
    const response = await this.get(`/file/${id}/logo`);
    this.logoCache[id] = response.data.payload;
    return response;
  }

  async getExcelTable(rows, headers, fileName, footerCells = null) {
    const requestData = {
      data: { headers, rows, footerCells },
      fileName
    };
    return this.post(
      `/file/report/excelTable/`,
      { excelData: requestData },
      { responseType: "blob", timeout: 30000 }
    );
  }

  // referenceName should be the desired output file's name
  async downloadApplication(applicationId, referenceName) {
    const blob = await this.get(`/file/application/${applicationId}/pdf`, {
      responseType: "blob",
      timeout: 30000,
    });
    return handleSuccess(blob, referenceName);
  }

  async downloadAwardLetter(awardId, referenceName) {
    const blob = await this.get(`/file/awardLetter/${awardId}/pdf`, {
      responseType: "blob",
      timeout: 30000,
    });
    return handleSuccess(blob, referenceName);
  }

  async downloadBoardMemo(boardMemoId, referenceName) {
    const blob = await this.get(`/file/boardMemo/${boardMemoId}/pdf`, {
      responseType: "blob",
      timeout: 30000,
    });
    return handleSuccess(blob, referenceName);
  }
}

const FileService = new FileServiceBase();
export default FileService;
