import React from "react";
/*
 * import { useEffect } from "react";
 * import { Grid, Link } from "@mui/material";
 * import { makeStyles } from "mui-styles";
 */

const DataTableType = () => {
  return (
    <div>
        DATATABLE TYPE CONTENT
    </div>
  )
}

export default DataTableType;
