import React, { useContext, useMemo } from "react";
import { makeStyles } from "mui-styles";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import InlineSVGIcons from "components/utils/inlineSVGIcons.component";
import CustomLink from "components/utils/link.component";
import ProgramsContext from "contexts/programs.context";
import { COMPONENT_OBJECT_TYPE_BUILDER } from "components/constants/program.constants";
import { builderTypes } from "./constants/builder.constants";

const useStyles = makeStyles((theme) => ({
  messageWrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    marginTop: 60,
    flexDirection: "column",
    justifyContent: "center",
    color: theme.palette.text.secondary,
  },
  linkWrapper: {
    paddingTop: 10,
    display: "flex",
    fill: theme.palette.secondary.main,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
  },
  iconWrapper: {
    height: 18,
    paddingRight: 10,
  },
}));

export const NoPolicyProcLanding = ({ listByProgram }) => {
  const classes = useStyles();
  const { state } = useContext(ProgramsContext);
  const { programId, programComponentId } = useParams();

  const compLink = useMemo(() => {
    const programComponentPath = `/program/${programId}/${programComponentId}`;
    const objects = state.componentObjectsByComponentId?.[programComponentId];
    const policyBuilder = objects?.find?.(componentObject => (
      componentObject.type === COMPONENT_OBJECT_TYPE_BUILDER &&
      componentObject.typeRef.includes(builderTypes.POLICY)
    ))
    if (policyBuilder) {
      return `${programComponentPath}/${policyBuilder.componentObjectId}`;
    }
    return programComponentPath;
  }, [programId, programComponentId, state.componentObjectsByComponentId]);

  return (
    <div className={classes.messageWrapper}>
      {!!listByProgram && (
        <Typography variant="h6">
          No Enterprise Level Procedures to display.
        </Typography>
      )}
      <Typography variant="h6">
        To create a Procedure, a corresponding Policy must be created first.
      </Typography>
      <CustomLink to={compLink} variant="routerLink">
        <span className={classes.linkWrapper}>
          <div className={classes.iconWrapper}>
            <InlineSVGIcons variant="Policies-procedures" />
          </div>
          <Typography variant="body1">Go to Policy Builder</Typography>
        </span>
      </CustomLink>
    </div>
  );
};

export default NoPolicyProcLanding;
