import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Typography,
  Container,
} from "@mui/material";
import { makeStyles } from "mui-styles";
import { validPassword } from "utils/stringValidation.utils";
import Checkbox from "components/utils/form-elements/checkboxDeprecated.component";
import AuthTextField from "components/utils/form-elements/authTextField.component";
import ButtonDefault from "components/utils/buttonDefault.component";
import AuthFormWrapper from "components/pages/auth/shared/authFormWrapper.component";
import RMSLogo from "components/utils/rmsLogo.component";
import PasswordReqs from "components/pages/auth/passwordReqs.component";

// custom imports
import AuthService from "services/base/Auth.service";
import AuthBackground from "components/pages/auth/shared/authBackground.component";

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    marginTop: 10,
  },
  bodyText: {
    fontSize: theme.typography.subtitle2.fontSize,
    color: theme.palette.primary.main,
    textAlign: "center",
  },
  bodyTextWarning: {
    fontSize: theme.typography.body1.fontSize,
    textAlign: "center",
    color: theme.palette.error.light,
    fontWeight: "bold",
    paddingTop: 5,
  },
  link: {
    fontSize: theme.typography.subtitle2.fontSize,
    textAlign: "center",
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
    textDecoration: "none",
    paddingTop: 5,
    display: "inline-block",
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
  linkWrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    textAlign: "center",
  },
  checkRoot: {
    paddingLeft: 20,
  },
  checkLabel: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.primary.main,
  },
}));

const Reset = () => {
  const classes = useStyles();

  const { userId, key } = useParams();

  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [disabledField, setDisabledField] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    AuthService.verifyEmail(userId, key)
      .then(() => {
        setDisabledField(false);
      })
      .catch((error) => {
        console.error(error);
        setMessage("The link you have clicked on is invalid");
        setDisabledField(true);
      });
  }, [key, userId]);

  const changeHandler = (event) => {
    if (event.target.name === "password") {
      setPassword(event.target.value);
    } else {
      setConfirm(event.target.value);
    }
    if (message !== "") {
      setMessage("");
    }
  };

  // submit the form
  const handleSubmit = useCallback(async event => {
    event.preventDefault();
    setMessage("");
    if (!validPassword(password)) {
      setMessage("You've entered an invaild password");
    } else if (confirm !== password) {
      setMessage("Passwords do not match");
    } else {
      try {
        await AuthService.resetPassword(userId, key, password);
        setSubmitted(true);
      } catch (error) {
        setMessage(
          "Something went wrong. Please check your password and try again."
        )
      }
    }
  }, [userId, key, confirm, password]);

  return (
    <AuthBackground>
      <AuthFormWrapper>
        <Container component="main" maxWidth="xs">
          <RMSLogo />
          {!submitted ? (
            <form noValidate data-cy="form-reset">
              {!disabledField && (
                <>
                  <Typography className={classes.bodyText}>
                    Please enter a new password
                  </Typography>
                  <PasswordReqs />
                </>
              )}

              <Typography
                className={classes.bodyTextWarning}
                data-cy="update-password-invalid"
              >
                {message}
              </Typography>
              <AuthTextField
                disabled={disabledField}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                type={showPassword ? "text" : "password"}
                autoFocus
                onChange={changeHandler}
              />
              <AuthTextField
                disabled={disabledField}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="confirm"
                label="Re-enter Password"
                name="confirm"
                type={showPassword ? "text" : "password"}
                onChange={changeHandler}
              />
              <Checkbox
                disabled={disabledField}
                checked={showPassword}
                onChange={() => setShowPassword(!showPassword)}
                name="create"
                label="Show Password"
                labelClass={classes.checkLabel}
                rootClass={classes.checkRoot}
                test="show-password"
              />
              <div className={classes.buttonWrapper}>
                <ButtonDefault
                  fullWidth
                  disabled={password.trim() === "" || confirm.trim() === ""}
                  variant="large"
                  background="secondary"
                  onClick={handleSubmit}
                  type="submit"
                  data-cy="btn-reset-password"
                >
                  Update Password
                </ButtonDefault>
              </div>
            </form>
          ) : (
            <>
              <Typography className={classes.bodyText}>
                Password has been reset.
              </Typography>
              <div className={classes.linkWrapper}>
                <Link className={classes.link} to="/">
                  Click Here to Login
                </Link>
              </div>
            </>
          )}
        </Container>
      </AuthFormWrapper>
    </AuthBackground>
  );
};

export default Reset;
