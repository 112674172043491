import AjaxService from "services/base/Ajax.service";

class DocumentServiceBase extends AjaxService {
  shouldExtractResponseData() {
    return true;
  }

  async downloadDocumentFile(documentId) {
    return this.get(`/document/${documentId}/file/download`, {
      responseType: "blob",
      timeout: 30000,
    });
  }

  async getDocumentsByCifId(cifId) {
    return this.get(`/document/${cifId}`)
      .then((response) => {
        return response;
      })
  }

  async batchCreateDocuments(formData) {
    return this.post(`/document/batch`, formData)
      .then((response) => {
        return response;
      })
  }
}

const DocumentService = new DocumentServiceBase();
export default DocumentService;