import React, { useState, useEffect, useCallback, useReducer, useContext } from "react";
import { makeStyles } from "mui-styles";
import {
  attachMetaToBuilderSections,
  isSectionHeaderMissing,
  makeSectionClientId,
  isMissingVersion,
  dispatchSaveAjaxError,
} from "components/builder/utils/builder.utils";
import BuilderService from "services/Builder.service";
import classNames from "classnames";
import Loader from "components/utils/loader.components";
import CustomLink from "components/utils/link.component";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import BuilderHeaderInfo from "components/builder/shared/builderHeaderInfo.component";
import DeleteIconButton from "components/utils/deleteIconButton.component";
import BuilderSectionHeader from "../shared/builderSectionHeader.component";
import BuilderSectionRTE from "../shared/builderSectionRTE.component";
import useNumericParams from "hooks/useNumericParams";
import { builderModes } from "components/builder/constants/builder.constants";
import builderReducer, {
  ACTION_ADD_BUILDER_SECTION,
  ACTION_DELETE_BUILDER_SECTION,
  ACTION_SET_BUILDER_IS_SAVING,
  ACTION_SET_BUILDER_AFTER_SAVE,
  builderInitialState,
  ACTION_SET_MISSING_HEADER_MESSAGE,
  ACTION_SET_BUILDER_LOAD_TEMPLATE,
  ACTION_SET_BUILDER_EDIT_CURRENT,
} from "reducers/shared/builder/builder.reducer";
import BuilderButtonsColumn from "../shared/builderButtonsColumn.component";
import BuilderHeader from "../shared/builderHeader.component";
import OrganizationContext from "contexts/organization.context";
const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  innerContentContainer: {
    paddingRight: 170,
    paddingBottom: 20,
  },
  sectionWrapper: {
    marginBottom: 40,
  },
  addSectionWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "left",
    marginBottom: 50,
    marginTop: 20,
  },
  deleteSectionWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  addSectionIcon: {
    fontSize: theme.typography.body1.fontSize,
    color: "white",
    marginRight: 6,
  },
}));

const BasicBuilder = (props) => {
  const classes = useStyles();
  const componentObject = props.componentObject;
  const { programId } = useNumericParams();
  const [openDialog, setOpenDialog] = useState(false);
  const { state: organizationState } = useContext(OrganizationContext);

  const [state, dispatch] = useReducer(builderReducer, builderInitialState);
  const {
    builderInfo,
    builderSections,
    builderInfoErrors,
    activeForm,
    statusMessage,
    disableBuilder,
    previousVersions
  } = state;

  useEffect(() => {
    if (props.mode === builderModes.LOAD_TEMPLATE) {
      const newBuilder = {
        ComponentObjects_ComponentObject_ID:
          props.chosenProgram.compObjId || componentObject.ComponentObject_ID,
        Title: "",
        Description: null,
        Version: "0.1",
        Approval_Date: moment(new Date()).format("YYYY-MM-DD"),
        Status: "Active",
        Type: props.type,
        ParentBuilderDoc_ID: null,
        BuilderDoc_ID: null,
        Show_Approval_Sections: 1,
      };
      dispatch({
        type: ACTION_SET_BUILDER_LOAD_TEMPLATE,
        payload: {
          builderInfo: newBuilder,
          builderSections: [],
        },
      });
    }
  }, [
    componentObject.ComponentObject_ID,
    props.chosenProgram.compObjId,
    props.mode,
    props.type,
  ]);

  useEffect(() => {
    if (props.mode === builderModes.EDIT_CURRENT) {
      const fetchBuilderAndSections = async () => {
        const builderDataRes = await BuilderService.getBuilderById(
          props.builder.Builder_ID,
          organizationState.activeOrganizationId
        );
        const builderSectionData = await BuilderService.getBuilderSections(
          props.builder.Builder_ID
        );
        const sections = attachMetaToBuilderSections(
          builderSectionData.payload
        );
        dispatch({
          type: ACTION_SET_BUILDER_EDIT_CURRENT,
          payload: {
            builderInfo: builderDataRes.payload,
            builderSections: sections,
            builderInfoErrors: isMissingVersion(builderDataRes.payload.Version),
            previousVersions: props.builder.previousVersions
          },
        });
      };
      fetchBuilderAndSections();
    }
  }, [props.builder, props.mode, organizationState.activeOrganizationId]);

  const addNewSection = (sectionOrder) => {
    const newSection = {
      Section_Order: sectionOrder + 1,
      SectionContent: "",
      SectionHeader: "",
      Type: null,
      _meta: {
        clientId: makeSectionClientId(),
      },
    };
    dispatch({
      type: ACTION_ADD_BUILDER_SECTION,
      payload: newSection,
    });
  };

  const deleteSection = (section) => {
    dispatch({
      type: ACTION_DELETE_BUILDER_SECTION,
      payload: section,
    });
  };

  const submitNewBuilder = useCallback(async () => {
    dispatch({
      type: ACTION_SET_BUILDER_IS_SAVING,
    });
    try {
      if (isSectionHeaderMissing(builderSections)) {
        dispatch({
          type: ACTION_SET_MISSING_HEADER_MESSAGE,
        });
        return;
      }
      const builderData = {
        ...builderInfo,
        Program_ID: props.chosenProgram.id || programId,
      };

      const builderRes = await BuilderService.createBuilder(
        builderData,
        builderSections
      );
      // RE-ATTACH CLIENT ID AFTER SAVE
      const formattedBuilderSections = attachMetaToBuilderSections(
        builderRes.payload.builderSectionData
      );
      dispatch({
        type: ACTION_SET_BUILDER_AFTER_SAVE,
        payload: {
          builderInfo: builderRes.payload.builderData,
          builderSections: formattedBuilderSections,
        },
      });
    } catch (error) {
      dispatchSaveAjaxError(dispatch, error);
    }
  }, [
    builderSections, builderInfo, dispatch, programId, props.chosenProgram.id
  ]);

  const submitUpdatedBuilder = useCallback(async () => {
    dispatch({
      type: ACTION_SET_BUILDER_IS_SAVING,
    });
    try {
      if (isSectionHeaderMissing(builderSections)) {
        dispatch({
          type: ACTION_SET_MISSING_HEADER_MESSAGE,
        });
        return;
      }

      const builderRes = await BuilderService.updateBuilder(
        builderInfo.Builder_ID,
        builderInfo,
        builderSections
      );
      const formattedBuilderSections = attachMetaToBuilderSections(
        builderRes.payload.builderSectionData
      );
      dispatch({
        type: ACTION_SET_BUILDER_AFTER_SAVE,
        payload: {
          builderInfo: builderRes.payload.builderData,
          builderSections: formattedBuilderSections,
        },
      });
    } catch (error) {
      dispatchSaveAjaxError(dispatch, error);
    }
  }, [builderSections, builderInfo, dispatch]);

  if (builderSections && builderInfo) {
    return (
      <>
        <BuilderHeader
          readOnly={props.readOnly}
          mode={props.mode}
          type={props.type}
          setBuilderMode={props.setBuilderMode}
          activeForm={activeForm}
          setOpenDialog={setOpenDialog}
          statusMessage={statusMessage}
          dispatch={dispatch}
        />

        <div className={classes.innerContentContainer}>
          <BuilderHeaderInfo
            previousVersions={previousVersions}
            builderInfo={builderInfo}
            builderInfoErrors={builderInfoErrors}
            dispatch={dispatch}
            disableBuilder={disableBuilder}
          />
          <div>
            {builderSections.length === 0 && (
              <div className={classes.addSectionWrapper}>
                <CustomLink
                  onClick={() => {
                    addNewSection(0);
                  }}
                  variant="linkBar"
                  startIcon={
                    <AddCircleOutlineOutlinedIcon
                      className={classes.addSectionIcon}
                    />
                  }
                  disableLinkBar={disableBuilder}
                  test="AddSection"
                >
                  Add Section
                </CustomLink>
              </div>
            )}
            {/* ======== BUILDER SECTIONS ======== */}
            {builderSections.map(section => {
              return (
                <div
                  key={`${section._meta.clientId}_${section.Section_Order}`}
                  className={classNames(classes.sectionWrapper)}
                >
                  {/* ======== SECTION HEADER ======== */}
                  <BuilderSectionHeader
                    section={section}
                    dispatch={dispatch}
                    disableBuilder={disableBuilder}
                  />
                  <BuilderSectionRTE
                    section={section}
                    dispatch={dispatch}
                    disableBuilder={disableBuilder}
                  />

                  {!props.readOnly && (
                    <div className={classes.deleteSectionWrapper}>
                      <DeleteIconButton
                        target="Section"
                        onClick={() => {
                          const deleteNotice =
                            "Are you sure you want to delete this section?";
                          if (window.confirm(deleteNotice)) {
                            deleteSection(section);
                          }
                        }}
                        disabled={disableBuilder}
                      />
                    </div>
                  )}
                  {/* ADD BUTTON */}
                  <div className={classes.addSectionWrapper}>
                    <CustomLink
                      onClick={() => {
                        addNewSection(section.Section_Order);
                      }}
                      variant="linkBar"
                      startIcon={
                        <AddCircleOutlineOutlinedIcon
                          className={classes.addSectionIcon}
                        />
                      }
                      test="AddSection"
                      disableLinkBar={disableBuilder}
                    >
                      Add Section
                    </CustomLink>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* SAVE & CANCEL BUTTONS */}
        <BuilderButtonsColumn
          readOnly={props.readOnly}
          state={state}
          setBuilderMode={props.setBuilderMode}
          sectionLess={builderSections.length === 0}
          mode={props.mode}
          submitNewBuilder={submitNewBuilder}
          submitUpdatedBuilder={submitUpdatedBuilder}
          setOpenDialog={setOpenDialog}
          openDialog={openDialog}
          componentObject={componentObject}
          chosenProgram={props.chosenProgram}
          associatedProgramId={
            props.chosenProgram.id ? props.chosenProgram.id : programId
          }
          dispatch={dispatch}
          statusMessage={statusMessage}
        />
      </>
    );
  } else {
    return <Loader />;
  }
};

export default BasicBuilder;
