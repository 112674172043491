import { Box } from "@mui/material";
import ClaimsTable from "components/claims/ClaimsTable.component";
import SearchResultsWrapper from "components/pages/consentOrder/claims/shared/SearchResultsWrapper.component";
import WorkflowNodeSearchFilter from "components/pages/consentOrder/claims/shared/WorkflowNodeSearchFilter.component";
import { ID_URL_HASH_SCROLL_TARGET, URL_SEARCH_PARAM_NODE_ID } from "components/pages/consentOrder/constants/consentOrderPage.constants";
import ScrollTargetContainer from "components/shared/scroll/ScrollTargetContainer.component";
import { H3 } from "components/utils/headerV2.component";
import useUrlSearchParams from "hooks/useUrlSearchParams";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ClaimService from "services/Claim.service";

export default function AllClaimsTable(props) {
  const { state, titleHeader } = props;
  const history = useHistory();
  const { location } = history;
  const searchAbortRef = useRef();
  const scrollTargetRef = useRef();

  const [isRequestPending, setIsRequestPending] = useState(false);
  const [hasSearchError, setHasSearchError] = useState(false);
  const [searchedClaims, setSearchedClaims] = useState(null);
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const [searchParams, hash] = useUrlSearchParams();

  const urlFilterNodeId = useMemo(() => (
    searchParams.get(URL_SEARCH_PARAM_NODE_ID)
  ), [searchParams]);

  const [activeNodeIdSet, setActiveNodeIdSet] = useState(
    urlFilterNodeId ? new Set([urlFilterNodeId]) : new Set()
  );
  const [searchQuery, setSearchQuery] = useState("")

  useLayoutEffect(function scrollToAnchorAndReset() {
    if (scrollTargetRef.current && hash) {
      if (hash === ID_URL_HASH_SCROLL_TARGET) {
        scrollTargetRef.current.scrollIntoView(true);
      }
    }
    if (urlFilterNodeId) {
      history.replace(location.pathname);
    }
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const handleFindClaims = useCallback(async (_event, isMount = false) => {
    try {
      setIsRequestPending(true)
      setHasSearchError(false);

      searchAbortRef.current = new AbortController();
      const results = await ClaimService.getClaimsBySearch(
        searchAbortRef.current,
        searchQuery,
        Array.from(activeNodeIdSet).join(","),
      )
      if (results.payload) {
        setAccordionExpanded(false)
        setSearchedClaims(results.payload)
        if (!isMount && scrollTargetRef.current) {
          scrollTargetRef.current.scrollIntoView();
        }
      }
    } catch (error) {
      setSearchedClaims(null);
      console.error(error);
      setHasSearchError(true);
    } finally {
      setIsRequestPending(false);
    }

  }, [searchQuery, activeNodeIdSet, setIsRequestPending]);

  useLayoutEffect(function fetchUrlFilterNodeResults() {
    if (urlFilterNodeId && !isRequestPending) {
      if (scrollTargetRef.current) {
        scrollTargetRef.current.scrollIntoView();
      }
      handleFindClaims(null, true)
    }
  }, [urlFilterNodeId, handleFindClaims, isRequestPending])

  useEffect(() => (
    function cancelSearchOnUnmount() {
      ClaimService.cancel(searchAbortRef.current);
    }
  ), []);

  return (
    <ScrollTargetContainer
      id={ID_URL_HASH_SCROLL_TARGET}
      scrollTargetRef={scrollTargetRef}
    >
      <Box display="inline-block" position="relative" zIndex={1}>
        <H3 color="tertiary" withMargin>{titleHeader}</H3>
      </Box>
      <WorkflowNodeSearchFilter
        activeNodeIdSet={activeNodeIdSet}
        setActiveNodeIdSet={setActiveNodeIdSet}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        searchResults={searchedClaims}
        accordionExpanded={accordionExpanded}
        setAccordionExpanded={setAccordionExpanded}
        onSubmit={handleFindClaims}
      />
      <Box paddingTop={4}>
        <SearchResultsWrapper
          error={hasSearchError}
          loading={isRequestPending}
          results={searchedClaims}
        >
          <ClaimsTable
            title="Search Results"
            claims={searchedClaims}
            state={state}
            fullPage
          />
        </SearchResultsWrapper>
      </Box>
    </ScrollTargetContainer>
  );
}
