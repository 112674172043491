import classNames from "classnames";
import Badge from "components/utils/badge.component";
import { makeStyles } from "mui-styles";

const useStyles = makeStyles((theme) => ({
  badge: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 20,
    padding: "0 2px",
    backgroundColor: theme.palette.teal.main,
    border: `1px solid white`,
    borderRadius: 40,
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
  badgeActive: {
    top: -2,
    right: -5,
    height: 20,
    padding: "0 2px",
    backgroundColor: theme.palette.teal.main,
    border: `1px solid white`,
    borderRadius: 40,
    color: "white",
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
}));


export default function BadgeStyled(props) {
  const classes = useStyles();
  const { classes: classesProp = {}, active = false, ...otherProps } = props;
  return (
    <Badge
      {...otherProps}
      classes={{
        ...classesProp,
        badge: classNames(
          !active && classes.badge,
          active && classes.badgeActive,
          classesProp.badge
        )
      }}
    />
  );
}
