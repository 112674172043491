import { Grid } from "@mui/material";


export function GridItem({ children, className, ...props }) {
  return (
    <Grid
      className={className}
      {...props}
      item
    >
      {children}
    </Grid>
  );
}
