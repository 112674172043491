import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "mui-styles";
import { MenuItem } from "@mui/material";
import LabelInput from "components/utils/form-elements/labelInput.component";
import CustomLink from "components/utils/link.component";
import BuilderSubTask from "components/builder/plan/builderSubTask.component";
import HeaderPrimary from "components/utils/header.component";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import WrapTextOutlinedIcon from "@mui/icons-material/WrapTextOutlined";
import UserService from "services/User.service";
import Alert from "@mui/lab/Alert";
import DeleteIconButton from "components/utils/deleteIconButton.component";
import Checkbox from "components/utils/form-elements/checkboxDeprecated.component";
import classNames from "classnames";
import DateInput from "components/utils/form-elements/dateInput.component";
import { ACTION_SET_PLAN_TASKS } from "reducers/shared/builder/builder.reducer";
import { activeUsersFilter } from "utils/roles.utils";
import { sortByStringKey } from "utils/sort.utils";

const useStyles = makeStyles((theme) => ({
  taskContainer: {
    paddingBottom: 30,
    maxWidth: 800,
    marginLeft: "auto",
    position: "relative",
  },
  taskWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 20px 20px 20px",
    borderRadius: 10,
    maxWidth: 800,
    marginLeft: "auto",
    position: "relative",
    marginBottom: 20,
    marginTop: 20,
  },
  activeBorder: {
    "&:hover": {
      boxShadow: `1px 1px 8px 2px ${theme.palette.teal.dark}`,
    },
  },
  alertMessage: {
    color: "red",
    backgroundColor: "white",
    padding: "0px 16px",
    marginBottom: 10,
    marginTop: 0,

    "& .MuiAlert-icon": {
      fill: "white",
      color: "red",
    },
  },
  readOnlyBorder: {
    border: `1px solid ${theme.palette.grey.disabled}`,
  },
  textfieldWrapper: {
    marginTop: 20,
  },
  dateWrapper: {
    paddingLeft: 10,
    paddingBottom: 5,
    maxWidth: 200,
    [theme.breakpoints.down("md")]: {
      padding: "15px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "unset",
    },
  },
  dateContainer: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  dateRow: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      marginLeft: "auto",
      marginBottom: 30,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
      marginLeft: "unset",
    },
  },
  flexRow: {
    marginTop: 30,
    display: "flex",
    marginLeft: "auto",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "flex-end",
    },
  },
  taskInfo: {
    paddingBottom: 20,
  },
  milestoneWrapper: {
    paddingLeft: 20,
    display: "flex",
    [theme.breakpoints.down("md")]: {
      paddingRight: 30,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px 10px",
      justifyContent: "center",
    },
  },
  selectWrapper: {
    maxWidth: 300,
    width: "100%",
    paddingLeft: 20,
    minWidth: 160,
    [theme.breakpoints.down("md")]: {
      marginLeft: "auto",
      paddingRight: 15,
      maxWidth: 360,
    },
  },
  taskHeaderWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 5,
  },
  taskIconLarge: {
    fontSize: theme.typography.h3.fontSize,
    marginRight: 5,
  },
  taskIcon: {
    fontSize: theme.typography.subtitle2.fontSize,
    marginRight: 5,
  },
  subTaskIcon: {
    fontSize: theme.typography.subtitle2.fontSize,
    marginRight: 5,
  },
  addTaskWrapper: {
    display: "flex",
    bottom: 0,
    right: 0,
    position: "absolute",
    width: "100%",
  },
  addSubTaskContainer: {
    maxWidth: 600,
    marginLeft: "auto",
    position: "relative",
    width: "100%",
  },
  teal: {
    color: theme.palette.teal.dark,
    fill: theme.palette.teal.dark,
  }
}));


const BuilderTask = (props) => {
  const classes = useStyles();
  const [scopedSubTasks, setScopedSubTasks] = useState();
  const [possibleAssignees, setPossibleAssignees] = useState([]);
  const [dateErrorText, setDateErrorText] = useState("")
  const { dispatch, disableBuilder } = props;

  useEffect(() => (async function fetchBuilderUsers() {
    let isSubscribed = true;
    UserService.getTaskAssigneeUsers().then((userRes) => {
      const activeUsers = activeUsersFilter(userRes.payload)
      if (isSubscribed) {
        setPossibleAssignees(activeUsers);
      }
    });
    const childTasks = props.subTasks.filter(subTask => (
      subTask._meta.parentTaskId === props.taskClientId
    ));
    setScopedSubTasks(childTasks);
    return () => {
      isSubscribed = false;
    };
  })(), [props.subTasks, props.taskClientId]);

  const taskHandler = (event, tempTaskId) => {
    const indexToUpdate = props.tasks.findIndex(
      (task) => task._meta.clientId === tempTaskId
    );
    const updateSections = [...props.tasks];

    if (event.target.name === "Type") {
      updateSections[indexToUpdate] = {
        ...updateSections[indexToUpdate],
        Type: event.target.checked ? "Milestone" : "Task",
        Proposed_Start: null,
        isUpdated: true,
      };
    } else if (event.currentTarget.id === "Assigned_User") {
      updateSections[indexToUpdate] = {
        ...updateSections[indexToUpdate],
        [event.currentTarget.id]: event.target.value,
      };

    } else {
      updateSections[indexToUpdate] = {
        ...updateSections[indexToUpdate],
        [event.target.id]: event.target.value,
      };
    }

    dispatch({
      type: ACTION_SET_PLAN_TASKS,
      payload: updateSections
    })
  };

  const onDateChange = (event) => {
    if (event.target.id === "Proposed_Start") {
      if ((event.target.value > props.task.Proposed_Completion) && (props.task.Proposed_Completion !== "")) {
        setDateErrorText("Task start date must come before end date.")
      } else {
        setDateErrorText("")
      }
    } else if (event.target.id === "Proposed_Completion") {
      if ((event.target.value < props.task.Proposed_Start) && (props.task.Proposed_Start !== "")) {
        setDateErrorText("Task start date must come before end date.")
      } else {
        setDateErrorText("")
      }
    }
  }

  const possibleAssigneesSelectOptions = useMemo(() => {
    const formattedSelectAssignees = possibleAssignees.map((assignee) => ({
      ...assignee,
      _displayLabel: `${assignee.First_Name} ${assignee.Last_Name}`,
    }));
    return sortByStringKey(formattedSelectAssignees, "_displayLabel");
  }, [possibleAssignees]);


  return (
    <div
      className={classes.taskContainer}
      data-cy={
        "taskContainer-" +
        (typeof props.taskClientId === "string" ? "BLANK-NEW" : props.task.Name)
      }
    >
      <div
        className={
          classNames(
            classes.taskWrapper,
            props.readOnly ? classes.readOnlyBorder : classes.activeBorder
          )
        }
      >
        <div className={classes.taskHeaderWrapper}>
          <HeaderPrimary
            variant="h4Custom"
            className={classes.teal}
            startIcon={
              <FormatListBulletedOutlinedIcon
                className={classes.taskIconLarge}
              />
            }
          >
            Task
          </HeaderPrimary>
          <DeleteIconButton
            testProp="Task"
            target="Task"
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure you want to delete this task? Any associated sub-tasks will be deleted as well."
                )
              ) {
                props.deleteTask(props.task, "");
              }
            }}
            disabled={disableBuilder}
          />
        </div>
        <div className={classes.taskInfo}>
          <div className={classes.textfieldWrapper}>
            <LabelInput
              label="Task Description"
              variant="default"
              multiline
              required
              defaultValue={props.task.Name || ""}
              id="Name"
              onBlur={(event) => taskHandler(event, props.taskClientId)}
              test="taskDesc"
              disabled={disableBuilder}
            />
          </div>
          <div className={classes.flexRow}>
            <div className={classes.dateRow}>
              <div className={classes.milestoneWrapper}>
                <Checkbox
                  checked={props.task.Type === "Milestone"}
                  onChange={(event) => taskHandler(event, props.taskClientId)}
                  name="Type"
                  label="Milestone"
                  test="Milestone"
                  disabled={disableBuilder}
                />
              </div>

              {/* DATES AND SELECT ROW */}
              <div className={classes.dateContainer}>
                <div className={classes.dateWrapper}>
                  <DateInput
                    helperText={dateErrorText}
                    error={dateErrorText.length > 0}
                    label="Proposed Start"
                    disabled={disableBuilder || props.task.Type === "Milestone"}
                    shrink
                    defaultValue={props.task.Proposed_Start}
                    id="Proposed_Start"
                    onBlur={(event) => taskHandler(event, props.taskClientId)}
                    onChange={(event) => onDateChange(event)}
                    placeholder="yyyy-mm-dd" //For Safari
                    test="task-ProposedStart"
                  />
                </div>
                <div className={classes.dateWrapper}>
                  <DateInput
                    helperText={dateErrorText}
                    error={dateErrorText.length > 0}
                    defaultValue={props.task.Proposed_Completion}
                    id="Proposed_Completion"
                    onBlur={(event) => taskHandler(event, props.taskClientId)}
                    onChange={(event) => onDateChange(event)}
                    label="Proposed Completion"
                    placeholder="yyyy-mm-dd" //For Safari
                    test="task-ProposedCompletion"
                    disabled={disableBuilder}
                  />
                </div>
              </div>
            </div>
            <div className={classes.selectWrapper}>
              {possibleAssigneesSelectOptions?.length ? (
                <LabelInput
                  variant="defaultTextfield"
                  select
                  label="Assignment"
                  defaultValue={props.task.Assigned_User || ""}
                  onBlur={(event) => taskHandler(event, props.taskClientId)}
                  id="Assigned_User"
                  name="Assigned_User"
                  disabled={disableBuilder || possibleAssigneesSelectOptions.length === 0}
                  test="assignUser"
                >
                  <MenuItem
                    value={null}
                    id="emptyUser"
                  >
                    Not Assigned
                  </MenuItem>

                  {possibleAssigneesSelectOptions.map((item) => (
                    <MenuItem
                      value={item.User_ID}
                      key={item.User_ID}
                      id={item.User_ID}
                      data-cy={`menu-item-assignment-${item.First_Name}${item.Last_Name}`}
                    >
                      {`${item.First_Name} ${item.Last_Name}`}
                    </MenuItem>
                  ))}
                </LabelInput>
              ) : (
                <LabelInput
                  variant="defaultTextfield"
                  label="Assignment"
                  defaultValue=""
                  name="Assigned_User"
                  key="loading"
                  disabled
                  select
                >
                  <MenuItem value="">
                    --
                  </MenuItem>
                </LabelInput>
              )}
            </div>
          </div>
          <div>
            {!!dateErrorText && (
              <Alert
                severity="error"
                className={classes.alertMessage}
                data-cy="dateError"
              >
                {dateErrorText}
              </Alert>
            )}
          </div>
        </div>
        {/* SUB TASK */}
        {!!scopedSubTasks && scopedSubTasks.length > 0 && scopedSubTasks.map((subTask) => (
          <React.Fragment key={subTask._meta.clientId}>
            <BuilderSubTask
              subTask={subTask}
              deleteTask={props.deleteTask}
              taskHandler={taskHandler}
              possibleAssigneesSelectOptions={possibleAssigneesSelectOptions}
              readOnly={props.readOnly}
              disableBuilder={disableBuilder}
            />
          </React.Fragment>
        ))}
        <div className={classes.addSubTaskContainer}>
          <CustomLink
            onClick={() => (
              props.addTask(props.wpClientId, props.taskClientId)
            )}
            variant="linkBarGray"
            startIcon={
              <WrapTextOutlinedIcon className={classes.subTaskIcon} />
            }
            test="addSubTask"
            disableLinkBar={disableBuilder}
          >
            Add a Sub-Task
          </CustomLink>
        </div>
      </div>

      <div className={classes.addTaskWrapper}>
        <CustomLink
          onClick={() => (
            props.addTask(props.wpClientId, null, props.task.Order)
          )}
          variant="linkBarTeal"
          startIcon={
            <FormatListBulletedOutlinedIcon className={classes.taskIcon} />
          }
          test="addTask"
          disableLinkBar={disableBuilder}
        >
          Add a Task
        </CustomLink>
      </div>
    </div>
  );
};

export default BuilderTask;
