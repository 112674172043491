import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Loader from "components/utils/loader.components";
import useNumericParams from "hooks/useNumericParams";
import { generatePath, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import OrganizationContext from "contexts/organization.context";
import DocumentLibraryPage from "components/pages/documentLibrary/documentLibraryPage.component";
import { isActiveStatus } from "utils/status.utils";
import FileService from "services/File.service";
import { PATH_COMPONENT_OBJECT, PATH_PROGRAM_COMPONENT } from "components/layout/constants/standardPaths.constants";

const REMOVE_PATH_PARAMS = new Set(["holdingId"]);

export function DocumentLibraryType(props) {
  const history = useHistory();
  const params = useNumericParams();
  const { state: organizationState } = useContext(OrganizationContext);
  const [allCompFiles, setAllCompFiles] = useState();
  const [refresh, setRefresh] = useState();
  const [folders, setFolders] = useState()

  useEffect(function removePathParams() {
    const shouldRedirect = Object.keys(params).some(param => (
      REMOVE_PATH_PARAMS.has(param)
    ));
    if (shouldRedirect) {
      if (params.componentObjectId) {
        history.replace(generatePath(PATH_COMPONENT_OBJECT, params));
      }
      history.replace(generatePath(PATH_PROGRAM_COMPONENT, params));
    }
  }, [history, params]);

  useEffect(() => {
    (async function fetchLibraryData() {
      const [filesByComponentObjectsRes, foldersRes] = await Promise.all([
        FileService.getAllByComponentObjectId(
          props.componentObject.componentObjectId,
          organizationState.activeOrganizationId
        ),
        FileService.getProgramDocsFoldersByProgramId(params.programId),
      ]);
      setAllCompFiles(filesByComponentObjectsRes.payload);
      setFolders(foldersRes.payload.filter(isActiveStatus));
    })();
  }, [
    props.componentObject.componentObjectId,
    refresh,
    organizationState.activeOrganizationId,
    params,
  ]);

  if (!allCompFiles) {
    return <Loader />;
  }
  return (
    <Grid item sm={12}>
      <DocumentLibraryPage
        documents={allCompFiles}
        setDocuments={setAllCompFiles}
        data={allCompFiles}
        tableTitle={props.tableTitle}
        componentObject={props.componentObject}
        programId={params.programId}
        setRefresh={setRefresh}
        folders={folders}
      />
    </Grid>
  );
}
