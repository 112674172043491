import React, { useMemo } from "react";
import { Card, CardContent, Divider } from "@mui/material";
import EditIcon from "components/utils/editIcon.component";
import ResetPasswordForm from "components/pages/auth/shared/resetPasswordForm.component";
import { makeStyles } from "mui-styles";
import { H3, H5 } from "components/utils/headerV2.component";
import { mapObjectArrayByKey } from "utils/arrayOfObjects.utils";
import { Person } from "@mui/icons-material";
import { formatPhoneNum } from "utils/formatters.utils";

const useStyles = makeStyles((theme) => ({
  profileTitleSection: {
    display: "flex",
    alignItems: "center",
    marginTop: -2,
    marginBottom: 4,
  },
  profileTitle: {
    display: "flex",
    alignItems: "center",
  },
  profileIcon: {
    marginRight: 8
  },
  editIconWrapper: {
    marginTop: -4,
    paddingLeft: 12,
  },
  cardWrapper: {
    display: "flex",
  },
  userContent: {
    marginTop: 8,
  },
  contentWrapper: {
    display: "flex",
    paddingLeft: 10,
    paddingRight: 10,
  },
  infoContainer: {
    border: `2px solid ${theme.palette.grey.light}`,
    paddingTop: 12,
    paddingLeft: 24,
    paddingRight: 24,
    borderRadius: 4,
    marginRight: 4,
    paddingBottom: 8,
  },
  controlsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `2px solid ${theme.palette.grey.light}`,
    borderRadius: 4,
  },
  label: {
    padding: 0,
  },
  infoSection: {
    padding: "8px 0 0 0",
  },
  info: {
    paddingTop: 4,
    paddingRight: 32,
    paddingBottom: 8,
    paddingLeft: 8,
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
  },
}));

const UserProfile = ({ user, openEditForm, roles }) => {
  const classes = useStyles();

  const phoneDisplay = useMemo(() => (
    formatPhoneNum(user.phone)
  ), [user.phone]);

  const roleDisplay = useMemo(() => {
    const rolesById = mapObjectArrayByKey(roles, "roleId");
    return rolesById?.[user.roleId]?.label
  }, [roles, user]);

  return (
    <div className={classes.profileWrapper}>
      <div className={classes.profileTitleSection}>
        <H3
          className={classes.profileTitle}
          startIcon={(
            <Person className={classes.profileIcon} />
          )}
        >
          My Account
        </H3>
        <div className={classes.editIconWrapper}>
          <EditIcon
            variant="iconButton"
            tooltipPlacement="right"
            onClick={() => openEditForm(user.userId, "currentUser")}
            test="admin"
          />
        </div>
      </div>
      <div className={classes.cardWrapper}>
        <Card>
          <CardContent className={classes.userContent}>
            <div className={classes.contentWrapper}>
              <div className={classes.infoContainer}>
                <UserInformation label="First Name" value={user.firstName} />
                <UserInformation label="Last Name" value={user.lastName} />
                <UserInformation label="Email" value={user.email} />
                <UserInformation label="Phone" value={phoneDisplay} />
                <UserInformation label="Role" value={roleDisplay} />
              </div>
              <div className={classes.controlsContainer}>
                <ResetPasswordForm user={user} />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};


function UserInformation({ label, value }) {
  const classes = useStyles();
  return (
    <div className={classes.infoSection}>
      <H5 className={classes.label}>
        {label}
      </H5>
      <div className={classes.info}>
        {value || <>--</>}
      </div>
      <Divider />
    </div>
  );
}

export default UserProfile;
