import React from "react";
import { H4 } from "components/utils/headerV2.component";
import LabelValuePair from "components/utils/labelValuePair.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import { Box } from "@mui/material";
import moment from "moment";
import { DATE_FORMAT_DISPLAY_NUMERIC } from "constants/date.constants";


export default function CustomerLoansSection(props) {
  const { customer } = props;
  return (
    <>
      <Box lineHeight="30px">
        <H4>Loan Distributions</H4>
      </Box>
      <GridContainer marginTop={0} spacing={4}>
        {customer._associations.DistributionDataset.map(distribution => (
          <GridItem
            xs={12}
            sm={6}
            lg={3}
            xl="auto"
            marginBottom={1}
            key={distribution.distributionDatasetId}
          >
            <LabelValuePair
              label="Loan Number"
              value={distribution.gsLoanNumber}
              stacked
            />
            <LabelValuePair
              label="Check Sent"
              value={!distribution.originationDate ? "N/A" : (
                moment(distribution.originationDate)
                  .format(DATE_FORMAT_DISPLAY_NUMERIC)
              )}
            />
          </GridItem>
        ))}
      </GridContainer>
    </>
  );
}
