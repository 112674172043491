import { makeStyles } from "mui-styles";
import { TableSortLabel } from "@mui/material";
import classNames from "classnames";
import { useCallback } from "react";

/*
 * const useStyles = makeStyles(_theme => ({
 *   sortDisabled: {
 *     cursor: "default",
 *     color: "white",
 *     fill: "white",
 *     "&:hover": {
 *       color: "white",
 *       fill: "white"
 *     },
 *     "&:focus": {
 *       color: "white",
 *       fill: "white"
 *     }
 *   },
 *   sortEnabledCentered: {
 *     marginRight: -19,
 *   },
 *   sortDisabledCentered: {
 *     marginRight: 0
 *   }
 * }));
 */

const makeRootStyles = color => ({
  color:  `${color} !important`,
  "& div": { color: `${color} !important` },
  "& path": { color: `${color} !important` },
});

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: "center",
    justifyContent: "start",
    paddingLeft: 0,
    width: "100%",
    transition: "color ease 0.1s",
    color: "#ffffff",
    "&:hover": makeRootStyles(theme.palette.secondary.light),
    "&$icon": {
      color: "#ffffff",
    },
  },
  icon: {
    color: theme.palette.secondary.light,
    marginRight: 0,
    opacity: 0.5,
    fontSize: theme.typography.body1.fontSize,
    height: "1rem",
    "@media print": {
      display: "none"
    }
  },
  active: {
    ...makeRootStyles(theme.palette.secondary.light),
    "&:hover": makeRootStyles(theme.palette.secondary.light),
    "& $icon": {
      "@media print": {
        display: "block"
      },
    }
  },
  sortAlignLeft: {
    alignItems: "center",
    justifyContent: "start",
    paddingLeft: 0,
    width: "100%",
  },
  sortAlignCenter: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },

  sortEnabled: {
    color: "#ffffff",
    "&:hover": makeRootStyles(theme.palette.secondary.light),
    "&$active:hover": makeRootStyles(theme.palette.secondary.light),
  },
  sortDisabled: {
    color: "#ffffff",
    fill: "white",
    cursor: "default",
    "&:hover": {
      color: "white",
      fill: "white"
    },
    "&:focus": {
      color: "white",
      fill: "white"
    }
  },
  sortEnabledCentered: {
    marginRight: -19,
  },
  sortDisabledCentered: {
    marginRight: 0
  }
}));

/*
 * const activeRootStyles = theme => ({
 *   color: theme.palette.secondary.light,
 *   "& div": {
 *     color: theme.palette.secondary.light,
 *   },
 *   "& path": {
 *     fill: theme.palette.secondary.light,
 *   },
 * });
 */

/*
 * const StyledTableSortLabelLeft = withStyles((theme) => ({
 *   icon: {
 *     color: theme.palette.secondary.light,
 *     marginRight: 0,
 *     fontSize: theme.typography.body1.fontSize,
 *     height: "1rem",
 *   },
 *   active: {
 *     color: theme.palette.secondary.light,
 *   },
 *   root: {
 *     alignItems: "center",
 *     justifyContent: "start",
 *     paddingLeft: 0,
 *     width: "100%",
 */

/*
 *     color: "#ffffff",
 *     "&:hover": activeRootStyles(theme.palette.secondary.light),
 *     "&$active": activeRootStyles(theme.palette.secondary.light),
 *     "&$icon": {
 *       color: theme.palette.secondary.light,
 *     },
 *   },
 * }))(TableSortLabel);
 */

/*
 * const StyledTableSortLabelCenter = withStyles((theme) => ({
 *   icon: {
 *     color: theme.palette.secondary.light,
 *     marginRight: 0,
 *     fontSize: theme.typography.body1.fontSize,
 *     height: "1rem",
 *   },
 *   active: {
 *     color: theme.palette.secondary.light,
 *   },
 *   root: {
 *     alignItems: "center",
 *     justifyContent: "center",
 *     width: "100%",
 */

/*
 *     color: "#ffffff",
 *     "&:hover": activeRootStyles(theme.palette.secondary.light),
 *     "&$active": activeRootStyles(theme.palette.secondary.light),
 *     "&$icon": {
 *       color: theme.palette.secondary.light,
 *     },
 *   },
 * }))(TableSortLabel);
 */

export const SORT_ASCENDING = "asc";
export const SORT_DESCENDING = "desc";


export default function StyledTableSortLabel(props) {
  const classes = useStyles();
  const {
    active, align, children, className, classNameRoot, direction, hideSortIcon,
    onClick, sortBy, sortDisabled, ...labelProps
  } = props;

  const handleClick = useCallback(event => (
    !sortDisabled && onClick?.(event, sortBy)
  ), [onClick, sortBy, sortDisabled]);

  return (
    <TableSortLabel
      {...labelProps}
      active={!!active && !sortDisabled}
      direction={direction}
      hideSortIcon={sortDisabled || hideSortIcon}
      classes={{
        root: classNames(
          classes.root,
          classNameRoot,
          align === "left" && classes.sortAlignLeft,
          align === "center" && classes.sortAlignCenter,
          (sortDisabled || hideSortIcon) && classes.sortDisabled,
          !(sortDisabled || hideSortIcon) && classes.sortEnabled,
          sortDisabled && align === "center" && classes.sortDisabledCentered,
          !sortDisabled && align === "center" && classes.sortEnabledCentered
        ),
        active: classes.active,
        icon: classes.icon,
      }}
      onClick={handleClick}
      tabIndex={sortDisabled ? -1 : undefined}
    >
      <div
        className={
          classNames(
            (sortDisabled || hideSortIcon) && classes.sortDisabled, className
          )
        }
      >
        {children}
      </div>
    </TableSortLabel>
  );
}
