import { builderSectionTypes } from "../constants/builder.constants";

export const itemizeBuilderSections = (sections = []) => {
  const builderSections = [];
  const procElementSections = [];
  const procSteps = [];
  for (const section of sections) {
    if (!section.Type) {
      builderSections.push(section);
    } else if (section.Type === builderSectionTypes.PROC_ELEMENT) {
      procElementSections.push(section);
    } else if (section.Type === builderSectionTypes.PROC_STEP) {
      procSteps.push(section);
    }
  }

  return {
    builderSections,
    procElementSections,
    procSteps,
  };
};

export const attachMetaToFrameworks = (frameworks, noPrimaryKey) => (
  frameworks.map(frame => ({
    ...frame,
     // TODO: "CHECK THIS" (originally from commit 773b658)
    Reg_Refs_ID: noPrimaryKey ? null : frame.Reg_Refs_ID,
    _meta: {
      clientId: `procEleRefId-${frame.GroupName}-${frame.RegFramework_ID}`,
    },
  }))
);
