import React, { useState, useCallback, useRef } from "react";
import { makeStyles } from "mui-styles";
import ButtonDefault from "components/utils/buttonDefault.component";
import BackupRoundedIcon from "@mui/icons-material/BackupRounded";
import { Box, Divider } from "@mui/material";
import DisplayHTML from "components/utils/displayHTML.component";
import DocumentLibraryTable from "components/pages/documentLibrary/documentLibraryTable.component";
import { H3 } from "components/utils/headerV2.component";
import { makeElementRefs } from "utils/refElement.utils";
import FolderJumpLinks from "./folderJumpLinks.component";
import CustomModal from "components/utils/modal.component";
import UploadGenericDocumentForm from "components/shared/forms/uploadGenericDocumentForm.component";

const useStyles = makeStyles((theme) => ({
  buttonContainerTop: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: 20,
    position: "absolute",
    right: 0,
    top: 175,
    zIndex: 101, //PortCoCard is set at 100
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
      marginBottom: 30,
    },
  },
  uploadIconSmall: {
    fontSize: 12,
    marginRight: 4,
  },
}));

export default function DocumentLibraryPage(props) {
  const classes = useStyles();
  const { documents, folders = [], setRefresh, componentObject } = props;
  const [openModalDocument, setOpenModalDocument] = useState(null);
  const folderHeaderRef = useRef({});

  const openNewUploadForm = useCallback((initialEditObj = {}) => {
    setOpenModalDocument(initialEditObj);
  }, []);

  const handleUploadSuccess = useCallback(() => {
    setRefresh(new Date());
    setOpenModalDocument(null);
  }, [setRefresh, setOpenModalDocument]);

  const handleCloseForm = useCallback(() => {
    setOpenModalDocument(null);
  }, [setOpenModalDocument]);

  return (
    <>
      {!folders?.length ? (
        <>
          <DocumentLibraryTable
            {...props}
            setOpenModalDocument={setOpenModalDocument}
            openModalDocument={openModalDocument}
          />
          <div className={classes.buttonContainerTop}>
            <ButtonDefault
              variant="medium"
              background="tertiary"
              className={classes.newUploadButton}
              startIcon={
                <BackupRoundedIcon className={classes.uploadIconMedium} />
              }
              onClick={openNewUploadForm}
              data-cy="btn-upload-new"
              disableReadOnlyUsers
              fixedRight
            >
              New Document
            </ButtonDefault>
          </div>
        </>
      ) : (
        <>
          <Box marginBottom={6}>
            <FolderJumpLinks
              folders={folders}
              folderHeaderRef={folderHeaderRef}
            />
          </Box>
          {folders.map((folder) => {
            return (
              <Box
                display="flex"
                key={folder.ProgramDocsFolder_ID}
                flexDirection="column"
              >
                <Box display="flex">
                  <span
                    ref={(el) => (
                      makeElementRefs(
                        el,
                        folderHeaderRef,
                        `folder-${folder.ProgramDocsFolder_ID}`
                      )
                    )}
                  >
                    <H3>{folder.Title}</H3>
                  </span>
                  <Box marginLeft={3}>
                    <ButtonDefault
                      variant="small"
                      startIcon={
                        <BackupRoundedIcon
                          className={classes.uploadIconSmall}
                        />
                      }
                      onClick={() => (
                        openNewUploadForm({
                          ProgramDocsFolder_ID: folder.ProgramDocsFolder_ID,
                        })
                      )}
                    >
                      Upload
                    </ButtonDefault>
                  </Box>
                </Box>
                <DisplayHTML html={folder.Description} />
                <DocumentLibraryTable
                  {...props}
                  folder={folder}
                  setOpenModalDocument={setOpenModalDocument}
                  openModalDocument={openModalDocument}
                  documents={documents.filter(
                    (document) => (
                      document.ProgramDocsFolder_ID ===
                      folder.ProgramDocsFolder_ID
                    )
                  )}
                />
                <Box marginTop={7} marginBottom={6}>
                  <Divider />
                </Box>
              </Box>
            );
          })}
          <Box display="flex">
            <span ref={(el) => makeElementRefs(el, folderHeaderRef, "folder-other")}>
              <H3>Other Documents</H3>
            </span>
            <Box marginLeft={3}>
              <ButtonDefault
                variant="small"
                startIcon={
                  <BackupRoundedIcon className={classes.uploadIconSmall} />
                }
                onClick={() => openNewUploadForm({})}
              >
                Upload
              </ButtonDefault>
            </Box>
          </Box>
          <DocumentLibraryTable
            {...props}
            setOpenModalDocument={setOpenModalDocument}
            openModalDocument={openModalDocument}
            documents={documents.filter(
              (document) => !document.ProgramDocsFolder_ID
            )}
          />
        </>
      )}{" "}
      <CustomModal open={!!openModalDocument} onClose={handleCloseForm}>
        <UploadGenericDocumentForm
          onClose={handleCloseForm}
          onSuccess={handleUploadSuccess}
          componentObject={componentObject}
          editingDocument={openModalDocument}
          folders={folders}
        />
      </CustomModal>
    </>
  );
}
