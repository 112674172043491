import classNames from "classnames";
import { makeStyles } from "mui-styles";
import { AddCircleOutlineOutlined } from "@mui/icons-material";

/**
 * This is designed to be a reusable way to use the startIcon prop
 * Currently only handles the most common use of it -
 * On a button with variant small
 * ex.
 * <ButtonDefault
 *  variant="small"
 *  startIcon={
 *       <IconForButton
 *         MuiIcon={AddCircleOutlineOutlined}
 *       />
 *    }
 * />
 *
 */

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: 4,
    fontSize: 18,
  },
}));

export default function IconForButton(props) {
  const classes = useStyles();
  const {
    MuiIcon = AddCircleOutlineOutlined,
    className = "",
    ...iconProps
  } = props;

  return (
    <MuiIcon className={classNames(classes.icon, className)} {...iconProps} />
  );
}
