
import { buildEventChain, getNodesFromEventChain } from "components/utils/workflows/workflow.utils";
import WorkflowDiagramContext from "contexts/workflowDiagram.context";
import { useMemo } from "react";
import { groupObjectArrayByKey } from "utils/arrayOfObjects.utils";


/*
 * Supplies Workflow event and node data formatted for flowchart diagrams
 */
export default function WorkflowDiagramProvider(props) {
  const { children, workflowTemplate } = props;
  const wfEventFlows = workflowTemplate?._associations?.WFEventFlow;

  const nodes = useMemo(() => {
    if (!wfEventFlows) {
      return null;
    }
    const nodesBeforeDedupe = wfEventFlows.flatMap(event => (
      [event._associations.WFReferenceNode, event._associations.WFTargetNode]
    ));
    return Object.values(
      Object.fromEntries(
        nodesBeforeDedupe.map(node => [node.wfNodeId, node])
      )
    );
  }, [wfEventFlows]);

  const eventChain = useMemo(() => {
    if (workflowTemplate) {
      return buildEventChain(workflowTemplate);
    }
    return null;
  }, [workflowTemplate]);

  const eventFlowsByReferenceNodeId = useMemo(() => {
    return groupObjectArrayByKey(wfEventFlows, "referenceNodeId")
  }, [wfEventFlows])

  const nodeChain = useMemo(() => (
    getNodesFromEventChain(eventChain)
  ), [eventChain]);

  const eventAlternativeSet = useMemo(() => {
    if (!eventChain || wfEventFlows?.length) {
      return [];
    }
    const chainIds = new Set(eventChain.map(event => event.wfEventFlowId));
    return new Set(
      wfEventFlows.filter(event => !chainIds.has(event.wfEventFlowId))
    );
  }, [eventChain, wfEventFlows]);

  const nodeOrphanSet = useMemo(() => {
    if (!nodes?.length) {
      return [];
    }
    const chainedIds = new Set(nodeChain.map(node => node.wfNodeId));
    return new Set(
      nodes.filter(node => !chainedIds.has(node.wfNodeId))
    );
  }, [nodeChain, nodes]);

  return (
    <WorkflowDiagramContext.Provider
      value={{
        workflowTemplate, //current template id
        eventChain, //the get result from backend
        nodeChain,
        eventAlternativeSet,
        nodeOrphanSet,
        eventFlowsByReferenceNodeId
      }}
    >
      {children}
    </WorkflowDiagramContext.Provider>
  );
}
