import { mapObjectArrayByKey } from "utils/arrayOfObjects.utils";

export const ACTION_SET_POPULATIONS = "populations__set";
export const ACTION_SET_SUMMARY_COUNT = "summary_count__set";
export const ACTION_RESET_POPULATIONS = "populations__reset";
export const ACTION_RESET_SUMMARY_COUNT = "summary_count__reset";
export const ACTION_REPLACE_POPULATION = "population__replace"
export const statisticsReducerInitialState = {
  populations: null,
  summaryCounts: null,
  summaryCountsByWorkflow: null,
};

export default function statisticsReducer(state, action) {
  switch (action.type) {
    case ACTION_SET_POPULATIONS:
      return {
        ...state,
        populations: mapObjectArrayByKey(action.payload, "population"),
      };
    case ACTION_REPLACE_POPULATION:
      return {
        ...state,
        populations: {
          ...state.populations,
          [action.payload.population]: action.payload
        }
      }
    case ACTION_RESET_POPULATIONS:
      return {
        ...state,
        populations: statisticsReducerInitialState.populations
      };
    case ACTION_SET_SUMMARY_COUNT:
      return {
        ...state,
        summaryCounts: mapObjectArrayByKey(action.payload, "summaryCountType"),
        summaryCountsByWorkflow: mapObjectArrayByKey(
          action.payload.filter(summary => summary.wfNodeId),
          "wfNodeId"
        )
      };
    case ACTION_RESET_SUMMARY_COUNT:
      return {
        ...state,
        summaryCounts: statisticsReducerInitialState.summaryCounts,
        summaryCountsByWorkflow: null
      };
    default:
      throw new Error(`Unrecognized action ${action.type}`)
  }
}
