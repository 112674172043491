import { Divider } from "@mui/material";
import KeyValueListItem from "components/pages/consentOrder/fileTransferSummary/shared/keyValueListItem.component";
import AddListItem from "components/utils/addListItem.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import { makeStyles } from "mui-styles";
import { useCallback, useMemo, useState } from "react";
import { findMaxValueByField } from "utils/number.utils";

const useStyles = makeStyles((theme) => ({
  addItem: {
    paddingTop: 40,
    paddingBottom: 8,
    justifyContent: "center",
    width: "100%",
    color: theme.palette.secondary.dark,
    textAlign: "center"
  }
}));


export default function KeyValueList(props) {
  const classes = useStyles();
  const {
    defaultItems, onChange, onItemDelete, onFileDelete, onFileUndoDelete,
    options, primaryKey, hasUploads, ListItemComponent = KeyValueListItem
  } = props;

  const sortedDefaultItems = useMemo(() => (
    defaultItems?.sort((i1, i2) => i1.displayOrder - i2.displayOrder)
  ), [defaultItems]);

  const [items, setItems] = useState(sortedDefaultItems);

  const highestItemOrder = useMemo(() => {
    if (!items) {
      return 0;
    }
    const max = findMaxValueByField(items, "displayOrder");
    if (!max) {
      return 0;
    }
    return max;
  }, [items]);

  const handleAddItem = useCallback(formData => {
    const updatedItem = { ...formData };
    updatedItem._meta = {
      clientId: (
        updatedItem["_meta.clientId"] ||
        `${(new Date()).getTime()}-pendingItem`
      )
    };
    delete updatedItem["_meta.clientId"];
    const newItems = [...(items || [])];
    if (formData._meta?.clientId) {
      const index = newItems.findIndex(item => (
        item?._meta?.clientId === updatedItem._meta.clientId ||
        item?.[primaryKey] === parseInt(updatedItem._meta.clientId, 10)
      ));
      newItems.splice(index, 1, updatedItem);
    } else {
      newItems.push(updatedItem);
    }
    setItems(newItems);
  }, [primaryKey, items]);

  const handleDeleteItem = useCallback(removedId => {
    const newItems = [...(items || [])];
    const index = newItems.findIndex(item => {
      const itemId = item._meta?.clientId || item?.[primaryKey];
      return itemId === removedId;
    });
    newItems.splice(index, 1);
    setItems(newItems);
    onItemDelete?.(items[index]);
  }, [onItemDelete, primaryKey, items]);

  return (
    <div className={classes.linkSection}>
      <GridContainer spacing={4}>
        {(items || [])?.map?.((item, index) => (
          <GridItem
            xs={12}
            key={
              item?.[primaryKey] ?
                `${item[primaryKey]}-savedItem` :
                item?._meta?.clientId || index
            }
          >
            {index > 0 && (
              <Divider />
            )}
            <ListItemComponent
              index={index}
              item={item}
              onChange={onChange}
              onDelete={handleDeleteItem}
              onFileDelete={onFileDelete}
              onFileUndoDelete={onFileUndoDelete}
              options={options}
              primaryKey={primaryKey}
              hasUploads={hasUploads}
            />
          </GridItem>
        ))}
      </GridContainer>
      <AddListItem
        text={items?.length ? "Add another Tag" : "Add Tag"}
        className={classes.addItem}
        onClick={() => (
          handleAddItem({ displayOrder: highestItemOrder + 1 })
        )}
        test="add-item"
      />
    </div>
  );
}
