import React, { useState, useRef, useMemo, useContext, useCallback } from "react";
import { makeStyles } from "mui-styles";
import { Paper, Popover } from "@mui/material";
import CustomModal from "components/utils/modal.component";
import TaskForm from "components/shared/forms/taskForm.component";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CompTasksContainer from "components/pages/tasks/compTasksContainer.component";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import Tooltip from "components/utils/tooltip.component";
import { isReadOnly } from "utils/roles.utils";
import classNames from "classnames";
import { currentUserInfo } from "services/util/authSession.utils";
import useNumericParams from "hooks/useNumericParams";
import WorkpackageTasksContext from "contexts/workpackageTasks.context";
import BadgeStyled from "components/utils/badgeStyled.component";

const useStyles = makeStyles((theme) => ({
  tasksContainer: {
    padding: "15px 35px 10px 25px",
    position: "absolute",
    right: 0,
    maxWidth: 155,
    width: "100%",
    marginTop: 75,
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    borderRadius: "10px 0px 0px 10px",
  },
  tasksWrapper: {
    display: "flex",
    alignItems: "center",
    minWidth: 100,
    width: "100%",
    justifyContent: "space-around",
  },
  buttonContainer: {
    lineHeight: "normal",
  },
  taskIcon: {
    fontSize: 28,
    fill: theme.palette.teal.main,
    "&:hover": {
      cursor: "pointer",
      fill: theme.palette.primary.main,
    },
  },
  addTaskIcon: {
    marginTop: 3,
    fontSize: 33,
    fill: theme.palette.teal.main,
    "&:hover": {
      cursor: "pointer",
      fill: theme.palette.primary.main,
    },
  },
  disabled: {
    marginTop: 3,
    fontSize: 33,
    fill: theme.palette.grey.disabled,
  },
  iconBadgeWrapper: {
    display: "flex",
    alignItems: "center",
  },
  fullScreenView: { // used for fullScreenView, when full screen dialog is used
    padding: "7px 28px 7px 18px",
    marginTop: 10,
    right: 0
  }
}));


export const TaskManager = (props) => {
  const classes = useStyles();
  const { state } = useContext(WorkpackageTasksContext);

  const user = useMemo(currentUserInfo, []);
  const readOnly = useMemo(isReadOnly, []);
  // for modal
  const [editModal, setEditModal] = useState(false);
  const anchorRef = useRef();
  //for popover
  const [anchorEl, setAnchorEl] = useState(null);
  const [refresh, setRefresh] = useState(true);

  const open = Boolean(anchorEl);
  const id = open ? "comp-task-popover" : undefined;
  const { programId, componentObjectId } = useNumericParams();

  const componentObjectTasks = useMemo(() => {
    const programTasks = state.tasksByProgramId?.[programId];
    return programTasks?.filter?.(task => (
      task.Component_Component_Object_ID === componentObjectId
    )) || [];
  }, [state.tasksByProgramId, programId, componentObjectId]);

  const userTasks = useMemo(() => (
    componentObjectTasks?.filter?.(task => (
      task.Assigned_User === user.id
    )) || []
  ), [user, componentObjectTasks]);

  const toggleForm = () => {
    setEditModal(!editModal);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openTasks = useCallback(() => {
    setAnchorEl(anchorRef.current);
  }, []);

  return (
    <Paper
      className={
        classNames(
          classes.tasksContainer,
          props.fullScreenView && classes.fullScreenView
        )
      }
      data-cy="container-header-tasks"
    >
      <div className={classes.tasksWrapper}>
        {/* BADGE */}
        <Tooltip
          title={`View ${props.title} Tasks`}
          placement="bottom"
          data-cy="tooltip-viewTask"
        >
          <div
            ref={anchorRef}
            className={classes.iconBadgeWrapper}
            onClick={() => openTasks()}
            data-cy="icon-click-task"
          >
            <ListAltIcon className={classes.taskIcon} />
            {!!userTasks && (
              <BadgeStyled
                alertCount={userTasks.length}
                color="secondary"
                data-cy="badge-assignedTask"
              />
            )}
          </div>
        </Tooltip>

        <div className={classes.buttonContainer}>
          {readOnly ? (
            <Tooltip
              title={`Add ${props.title} Task`}
              placement="bottom"
              disabled
            >
              <PlaylistAddIcon
                className={classes.disabled}
                data-cy="icon-add-task-readOnly"
              />
            </Tooltip>
          ) : (
            <Tooltip title={`Add ${props.title} Task`} placement="bottom">
              <PlaylistAddIcon
                className={classes.addTaskIcon}
                onClick={() => setEditModal(true)}
                data-cy="icon-add-task"
              />
            </Tooltip>
          )}
        </div>
        <CustomModal open={editModal} onClose={toggleForm}>
          <TaskForm
            setEditModal={setEditModal}
            setRefresh={setRefresh}
            formMode="create"
            formType="task"
            parentComponent="TaskManager"
          />
        </CustomModal>
        {!!componentObjectTasks && !!userTasks && (
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <CompTasksContainer
              allTasks={componentObjectTasks}
              userTasks={userTasks}
              title={props.title}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </Popover>
        )}
      </div>
    </Paper>
  );
};

export default TaskManager;
