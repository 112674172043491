import FileService from "services/File.service";
import { downloadFileBlob } from "utils/download.utils";


export const downloadFile = async (id, fileRef) => (
  FileService.downloadFile(id, fileRef).then(blob => (
    downloadFileBlob(blob.data, fileRef)
  ))
);

// download file by File_Ref attribute
export const downloadFileByRef = async (fileRef, referenceName = fileRef) => (
  FileService.downloadFileByRef(fileRef, referenceName).then(blob => (
    downloadFileBlob(blob.data, referenceName)
  ))
);

export const b64toBlob = (
  b64Data,
  contentType = "application/pdf",
  sliceSize = 512
) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export function blobToBase64(blob) {
  return new Promise((resolve, _nothing) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}