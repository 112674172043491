import React, { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles } from "mui-styles";
import WpAndProgramTasksTable from "components/pages/tasks/wpAndProgramTasksTable.component";
import EditIcon from "components/utils/editIcon.component";
import CustomModal from "components/utils/modal.component";
import BackToLink from "components/utils/backToLink.component";
import AddListItem from "components/utils/addListItem.component";
import TaskForm from "components/shared/forms/taskForm.component";
import AssignTaskForm from "components/shared/forms/assignTaskForm.component";
import RadioInputGroup from "components/utils/form-elements/radioInputGroup.component";
import UserService from "services/User.service";
import moment from "moment";
import AssignButton from "components/utils/assignButton.component";
import CustomContainer from "components/utils/container.component";
import PageHeader from "components/utils/pageHeader.component";
import { Toolbar } from "@mui/material";
import EnteringPortal from "components/utils/enteringPortal.component";
import usePortalEntry from "hooks/usePortalEntry";
import ToggleButton from "@mui/lab/ToggleButton";
import ToggleButtonGroup from "@mui/lab/ToggleButtonGroup";
import ProgramsContext from "contexts/programs.context";
import { mapObjectArrayByKey } from "utils/arrayOfObjects.utils";
import WorkpackageTasksContext from "contexts/workpackageTasks.context";
import useNumericParams from "hooks/useNumericParams";
import { ArrowUpward } from "@mui/icons-material";


/*
 * Version 1: Lists tasks based on workpackage
 * Version 2: Lists tasks not associated with a workpackage
 */

const headers = [
  "",
  "Name",
  "Sub Tasks",
  "Proposed Start",
  "Proposed Completion",
  "Status",
  "Actual Start",
  "Actual Completion",
  "Task Notes",
  "Type",
  "Assignment",
];

const alignment = [
  "center",
  "left",
  "left",
  "center",
  "center",
  "center",
  "center",
  "center",
  "left",
  "center",
  "center",
];

const useStyles = makeStyles((theme) => ({
  contents: {
    paddingTop: 10,
    paddingBottom: 30,
  },
  addListItemWrapper: {
    paddingTop: 15,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
    paddingRight: 10,
  },
  radioGroupClass: {
    flexDirection: "row",
    textTransform: "uppercase",
    marginBottom: 5,
    "& label": {
      margin: 0,
    },
    "& .MuiFormControlLabel-label": {
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: theme.typography.body2.fontSize,
    },
  },
  filtersContainer: {
    alignItems: "center",
    display: "flex",
    padding: "5px 0px 5px 0px",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  buttonHeight: {
    height: "2rem",
    margin: "5px 0px 5px 0px",
  },
  arrowIcon: {
    fontSize: 20,
  },
  toggleButton: {
    padding: "3px 10px 3px 10px",
    fontSize: theme.typography.body2.fontSize,
    "&.Mui-selected": {
      backgroundColor: theme.palette.teal.dark,
      color: "white",
      "&:hover": {
        backgroundColor: theme.palette.teal.dark,
        color: "white",
      },
    },
    "&:hover": {
      color: theme.palette.teal.dark,
      backgroundColor: "unset",
      borderColor: theme.palette.teal.main,
    },
  },
}));

const WpTasksTable = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { programId, wpId, taskId } = useNumericParams();
  const [tableData, setTableData] = useState();
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [taskData, setTaskData] = useState();
  const [refresh, setRefresh] = useState(false);
  const [parent, setParent] = useState();
  const [formMode, setFormMode] = useState(""); //edit, create
  const [formType, setFormType] = useState(""); //task, subTask
  const [readyToLoad, setReadyToLoad] = useState(false);
  const [nonWpTasks, setNonWpTasks] = useState(false);
  const [assignMode, setAssignMode] = useState(false);
  const [highlightRow, setHighlightRow] = useState(null);
  //MODAL
  const [editModal, setEditModal] = useState(false);
  const [workpackage, setWorkpackage] = useState({});
  const [enteringProgram, activeProgram] = usePortalEntry();
  const { state: programState } = useContext(ProgramsContext);
  const { state: wpTaskState } = useContext(WorkpackageTasksContext);
  const [filteredBy, setFilteredBy] = useState("");
  //PARENT TASKS
  const [showParents, setShowParents] = useState(false);

  const toggleParents = (event, newToggle) => {
    if (newToggle === true) {
      setShowParents(true);
    } else {
      setShowParents(false);
    }
  };
  const toggleForm = () => {
    setEditModal(!editModal);
    setAssignMode(false);
  };

  const radioOptions = [
    { label: "Show All", value: "" },
    { label: "Open Tasks", value: "Open" },
    { label: "Completed Tasks", value: "Completed" },
  ];

  useEffect(() => {
    const targetId = location?.state?.taskID || location?.state?.itemId;
    if (location.state && targetId) {
      setHighlightRow(targetId);
      history.replace(); // Clears location.state which the highlight row depends on
    }
    if (taskId && !wpId) {
      setNonWpTasks(true);
    } else {
      const match = wpTaskState.workpackages?.find?.(workpackageMatch => (
        workpackageMatch.Workpackage_ID === wpId
      ));
      setWorkpackage(match);
    }
  }, [wpId, wpTaskState?.workpackages, taskId, location.state, history]);

  useEffect(() => {
    setRefresh(false);

    // fetch Tasks based on WP or programID
    const getTasks = async () => {
      setReadyToLoad(false);
      let scopedTasks = null;
      if (taskId && !wpId) {
        scopedTasks = wpTaskState.tasksByProgramId?.[programId]
          ?.filter?.(task => !task.Workpackages_Workpackage_ID);
      } else {
        scopedTasks = wpTaskState.tasks?.filter?.(task => (
          task.Workpackages_Workpackage_ID === wpId
        ));
      }
      if (!scopedTasks) {
        return [];
      }


      // Not a good thing to fetch all by id like this.
      const userIds = Array.from(
        new Set(
          scopedTasks
            .map(task => task.Assigned_User)
            .filter(id => id)
        )
      );
      const users = await Promise.all(
        userIds.map(async id => {
          const user = await UserService.getUserById(id);
          return user.payload;
        })
      );
      const usersById = mapObjectArrayByKey(users, "User_ID");

      return scopedTasks.map(task => {
        if (task.Assigned_User) {
          const assignedUser = usersById[task.Assigned_User];
          task.Assigned_UserName = (
            `${assignedUser.First_Name} ${assignedUser.Last_Name}`
          );
        }
        return task;
      });
    };

    getTasks()
      .then((tableTasks) => {
        // Separate Parent tasks and Sub tasks into respoective arrays
        let parentTasks = tableTasks.filter((task) => task.Parent_ID === null);
        const subTasks = tableTasks.filter((task) => task.Parent_ID !== null);
        /*
         * Identify Parent tasks which have at least 1 Completed Sub task
         * and then attach a field called hasCompletedSubTask
         */
        const parentIdsWithCompletedSubtasks = [
          ...new Set(
            subTasks
              .filter((sub) => sub.Status === "Completed")
              .map((subs) => subs.Parent_ID)
          ),
        ];
        parentTasks = parentTasks.map((pTask) => {
          if (parentIdsWithCompletedSubtasks.includes(pTask.Task_ID)) {
            return { ...pTask, hasCompletedSubTask: true };
          } else {
            return { ...pTask, hasCompletedSubTask: false };
          }
        });

        const tableRows = [];
        const openAssignForm = (assignTask) => {
          setAssignMode(true);
          setEditModal(true);
          setTaskData(assignTask);
        };
        parentTasks.forEach(task => {
          tableRows.push({
            HiddenTask_ID: task.Task_ID,
            Edit: (
              <EditIcon
                variant="matrix"
                onClick={() => openForm("task", "edit", task)}
                test={task.Name}
              />
            ),
            Name: task.Name,
            Sub_Tasks: (
              <AddListItem
                text="New Sub-Task"
                onClick={() => openForm("subTask", "create", task, task.Name)}
                test={`SubTask-for-${task.Name}`}
                dataTest="SubTask"
              />
            ),
            Proposed_Start:
              task.Proposed_Start === null
                ? ""
                : moment(task.Proposed_Start).format("M/D/YYYY"),
            Proposed_Completion:
              task.Proposed_Completion === null ?
                "" :
                moment(task.Proposed_Completion).format("M/D/YYYY"),
            Status: task.Status,
            Actual_Start:
              task.Actual_Start === null
                ? ""
                : moment(task.Actual_Start).format("M/D/YYYY"),
            Actual_Completion:
              task.Actual_Completion === null
                ? ""
                : moment(task.Actual_Completion).format("M/D/YYYY"),
            Task_Notes: task.Task_Notes,
            Type: task.Type,
            Assignment: task.Assigned_UserName ? (
              task.Assigned_UserName
            ) : (
              <AssignButton
                onClick={() => openAssignForm(task)}
                testProp={task.Name}
              />
            ),
            hasCompletedSubTask: task.hasCompletedSubTask,
          });
          subTasks
            .filter(subtask => subtask.Parent_ID === task.Task_ID)
            .forEach(subtask => {
              tableRows.push({
                HiddenTask_ID: subtask.Task_ID,
                Edit: (
                  <EditIcon
                    variant="matrix"
                    onClick={() => (
                      openForm(
                        "subTask",
                        "edit",
                        subtask,
                        task.Name,
                        task.Task_ID
                      )
                    )}
                    test={subtask.Name}
                  />
                ),
                Name: <ArrowUpward className={classes.arrowIcon} />,
                Sub_Tasks: subtask.Name,
                Proposed_Start:
                  subtask.Proposed_Start === null
                    ? ""
                    : moment(subtask.Proposed_Start).format("M/D/YYYY"),
                Proposed_Completion:
                  subtask.Proposed_Completion === null
                    ? ""
                    : moment(subtask.Proposed_Completion).format("M/D/YYYY"),
                Status: subtask.Status,
                Actual_Start:
                  subtask.Actual_Start === null
                    ? ""
                    : moment(subtask.Actual_Start).format("M/D/YYYY"),
                Actual_Completion:
                  subtask.Actual_Completion === null
                    ? ""
                    : moment(subtask.Actual_Completion).format("M/D/YYYY"),
                Task_Notes: subtask.Task_Notes,
                Type: subtask.Type,
                Assignment: subtask.Assigned_UserName ? (
                  subtask.Assigned_UserName
                ) : (
                  <AssignButton
                    taskToAssign={subtask}
                    onClick={() => openAssignForm(subtask)}
                  />
                ),
                isSubtask: true,
              });
            });
        });
        setFilteredTableData(tableRows);
        setTableData(tableRows);
      })
      .then(() => setReadyToLoad(true));
  }, [
    wpId, taskId, wpTaskState.tasks, wpTaskState.tasksByProgramId,
    refresh, programId, classes
  ]);

  useEffect(() => {
    if (readyToLoad && location.state && location.state.filterBy) {
      setFilteredBy(location.state.filterBy);
      history.replace();
    }
  }, [location.state, readyToLoad, history]);

  useEffect(() => {
    if (filteredBy === "Completed" && showParents) {
      setFilteredTableData(
        [...tableData].filter(task => (
          task.Status === "Completed" || task.hasCompletedSubTask === true
        ))
      );
    } else if (filteredBy === "Completed" && !showParents) {
      setFilteredTableData(
        [...tableData].filter((task) => task.Status === "Completed")
      );
    } else if (filteredBy === "Open") {
      setFilteredTableData(
        [...tableData].filter(task => (
          task.Status !== "Completed" && task.Status !== "Closed"
        ))
      );
    } else {
      setFilteredTableData(tableData);
    }
  }, [filteredBy, tableData, showParents]);

  const filterChange = (event) => {
    setFilteredBy(event.target.value);
  };

  const openForm = (type, mode, task, parentTask, parentId) => {
    setEditModal(true);
    if (task) {
      setTaskData(task);
    } else {
      setTaskData();
    }
    setFormMode(mode);
    setFormType(type);
    setParent({ name: parentTask, id: parentId });
  };

  const titleLogic = (type) => {
    if (filteredBy) {
      return `${type} ${filteredBy} Tasks`;
    }
    return `${type} Tasks`;
  };

  if (enteringProgram) {
    return <EnteringPortal activeProgram={activeProgram} />;
  }

  if (readyToLoad && wpTaskState.workpackages && !enteringProgram) {
    const programName = programState.programs?.[programId]?.Name || "";
    return (
      <CustomContainer>
        <Toolbar />
        <PageHeader
          title={titleLogic(nonWpTasks ? programName : workpackage.Name)}
          titleOnly
        />
        <BackToLink
          text="Return to Dashboard"
          href={`/program/${programId}`}
          parentPage={programName ? `${programName} Dashboard` : "Dashboard"}
        />
        <div className={classes.contents}>
          <div className={classes.filtersContainer}>
            <div className={classes.radioGroupContainer}>
              <RadioInputGroup
                options={radioOptions}
                value={filteredBy}
                radioGroupClass={classes.radioGroupClass}
                onChange={filterChange}
                hideHelperText
                size="small"
              />
            </div>
            <div className={classes.buttonHeight}>
              {filteredBy === "Completed" && (
                <ToggleButtonGroup
                  color="primary"
                  value={showParents}
                  exclusive
                  onChange={toggleParents}
                  className={classes.toggleButtonGroup}
                >
                  <ToggleButton
                    className={classes.toggleButton}
                    classes={{
                      selected: classes.toggleSelected,
                      root: classes.toggleRoot,
                    }}
                    value={false}
                    key="hide"
                    data-cy="toggle-option-hide-parents"
                  >
                    Hide Open Parent Tasks
                  </ToggleButton>
                  <ToggleButton
                    className={classes.toggleButton}
                    classes={{
                      selected: classes.toggleSelected,
                      root: classes.toggleRoot,
                    }}
                    value
                    key="show"
                    data-cy="toggle-option-show-parents"
                  >
                    Show Open Parent Tasks
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            </div>
          </div>
          <WpAndProgramTasksTable
            header={headers}
            data={filteredTableData}
            alignment={alignment}
            denseHeader
            denseBody
            defaultRefKey="HiddenTask_ID"
            tableType="wpTasks"
            taskRefId={highlightRow}
            filteredBy={filteredBy}
            showParents={showParents}
            test="wpAndProgramTasks-table"
          />
          <div className={classes.addListItemWrapper}>
            <AddListItem
              dark
              text={nonWpTasks ? `Add Task` : `Add New Workpackage Task`}
              onClick={() => openForm("task", "create")}
              test="Task"
            />
          </div>
        </div>

        <CustomModal open={editModal} onClose={toggleForm}>
          {!!assignMode && (
            <AssignTaskForm
              setEditModal={setEditModal}
              taskData={taskData}
              setTaskData={setTaskData}
              setAssignMode={setAssignMode}
              setHighlightRow={setHighlightRow}
            />
          )}
          {!assignMode && (
            <TaskForm
              setEditModal={setEditModal}
              workpackageProp={!nonWpTasks && workpackage}
              taskData={taskData}
              setTaskData={setTaskData}
              setRefresh={setRefresh}
              formMode={formMode} //create edit
              formType={formType} //task or subTask
              setFormType={setFormType}
              parentTaskInfo={parent}
              setHighlightRow={setHighlightRow}
              programName={!!nonWpTasks && programName}
              variant={!nonWpTasks && "tasksByWorkpackage"}
            />
          )}
        </CustomModal>
      </CustomContainer>
    );
  } else {
    return "";
  }
};

export default WpTasksTable;
