import { isDevelopmentEnv } from "core/environment";
import { groupObjectArrayByKey } from "utils/arrayOfObjects.utils";

export const ACTION_SET_PROGRAMS = "programs__set";
export const ACTION_SET_VALID_PROGRAM_COMPONENT_IDS =
  "program_component_ids__valid__set";
export const ACTION_SET_VALID_COMPONENT_OBJECT_IDS =
  "component_object_ids__valid__set";

export const ACTION_PARTIAL_REPLACE_PROGRAMS =
  "programs_partial__replace";
export const ACTION_FULL_REPLACE_PROGRAM_COMPONENTS =
  "program_components__replace";
export const ACTION_PARTIAL_REPLACE_PROGRAM_COMPONENTS =
  "program_components_partial__replace";
export const ACTION_FULL_REPLACE_COMPONENT_OBJECTS =
  "component_objects_full__replace";
export const ACTION_PARTIAL_REPLACE_COMPONENT_OBJECTS =
  "component_objects_partial__replace";
export const ACTION_REPLACE_COMPONENT_OBJECT_FAMILY = (
  "component_object__family__replace"
);


const replaceToName = (stateItem, action) => {
  const list = [].concat(action.payload);
  const mappedById = Object.fromEntries(
    list.map(item => (
      [item.name, item]
    ))
  );
  return {
    ...(stateItem || {}),
    ...mappedById
  };
};

const setToName = action => {
  const list = [].concat(action.payload);
  const mappedById = Object.fromEntries(
    list.map(item => (
      [item.name, item]
    ))
  );
  return mappedById
}

const replaceToIdGroup = (
  stateItem, action, groupPrimaryKey, persistExistingData = true
) => {
  const list = [].concat(action.payload);
  const groupedPayload = groupObjectArrayByKey(list, groupPrimaryKey);
  if (!stateItem) {
    return groupedPayload;
  }
  return Object.entries(groupedPayload).reduce(
    (accumulator, [key, group]) => ({
      ...accumulator,
      [key]: [
        ...(persistExistingData ? stateItem[key] || [] : []),
        ...(group || [])
      ].sort((item1, item2) => (
        item1.order - item2.order
      ))
    }),
    stateItem
  );
};

const mergeReplaceToIdGroup = (
  stateItem, action, groupPrimaryKey, primaryKey
) => {
  const list = [].concat(action.payload);
  const groupedPayload = groupObjectArrayByKey(list, groupPrimaryKey);
  if (!stateItem) {
    return groupedPayload;
  }
  return Object.entries(groupedPayload).reduce(
    (accumulator, [key, group]) => {
      const payloadKeyValues = new Set(
        (group || []).map(groupItem => groupItem[primaryKey])
      );
      return {
        ...accumulator,
        [key]: [
          ...(stateItem[key] || []).filter(item => (
            !payloadKeyValues.has(item[primaryKey])
          )),
          ...(group || [])
        ].sort((item1, item2) => (
          item1.order - item2.order
        ))
      }
    },
    stateItem
  );
};

const updateFullyLoadedProgramComponents = (stateSet, action) => {
  const parentProgramComponentId = (
    action.payload?.[0]?.programComponentId
  );
  if (parentProgramComponentId) {
    const stateArray = stateSet?.values?.() || [];
    return new Set([stateArray, parentProgramComponentId]);
  }
  return stateSet;
};

export const programInitialState = {
  programs: null,
  programComponents: null,
  componentObjects: null,

  programComponentsByProgramId: null,
  componentObjectsByComponentId: null,
  fullyLoadedProgramComponents: new Set([]),

  validProgramIds: new Set([]),
  validProgramComponentIds: null,
  validComponentObjectIds: null,
};

/*
 * Unlike other reducers, items are mapped by primary key.
 * This is for efficiency since Program data is used so often on each page.
 */
export default function programReducer(state, action) {
  switch (action.type) {
    case ACTION_SET_PROGRAMS:
      return {
        ...state,
        programs: setToName(action),
        validProgramIds: new Set(
          action.payload
            .filter(program => program.status === "Active")
            .map(program => program.name)
        ),
      };
    case ACTION_PARTIAL_REPLACE_PROGRAMS:
      return {
        ...state,
        programs: replaceToName(state.programs, action),
      };
    case ACTION_SET_VALID_PROGRAM_COMPONENT_IDS:
      return {
        ...state,
        validProgramComponentIds: Object.fromEntries(action.payload)
      };
    case ACTION_SET_VALID_COMPONENT_OBJECT_IDS:
      return {
        ...state,
        validComponentObjectIds: Object.fromEntries(action.payload)
      };
    case ACTION_FULL_REPLACE_PROGRAM_COMPONENTS:
      return {
        ...state,
        programComponents: replaceToName(
          state.programComponents, action
        ),
        programComponentsByProgramId: replaceToIdGroup(
          state.programComponentsByProgramId, action, "programId", false
        )
      };
    case ACTION_PARTIAL_REPLACE_PROGRAM_COMPONENTS:
      return {
        ...state,
        programComponents: replaceToName(
          state.programComponents, action
        ),
        programComponentsByProgramId: mergeReplaceToIdGroup(
          state.programComponentsByProgramId,
          action,
          "programId",
          "programComponentId"
        )
      };
    case ACTION_FULL_REPLACE_COMPONENT_OBJECTS:
      return {
        ...state,
        componentObjects: replaceToName(
          state.componentObjects, action
        ),
        componentObjectsByComponentId: replaceToIdGroup(
          state.componentObjectsByComponentId,
          action,
          "programComponentId",
          false
        ),
        fullyLoadedProgramComponents:
          updateFullyLoadedProgramComponents(
            state.fullyLoadedProgramComponents,
            action
          ),
      };
    case ACTION_PARTIAL_REPLACE_COMPONENT_OBJECTS:
      return {
        ...state,
        componentObjects: replaceToName(
          state.componentObjects, action
        ),
        componentObjectsByComponentId: mergeReplaceToIdGroup(
          state.componentObjectsByComponentId,
          action,
          "programComponentId",
          "componentObjectId",
        ),
      };
    case ACTION_REPLACE_COMPONENT_OBJECT_FAMILY:
      return {
        ...state,
        programs: replaceToName(
          state.programs,
          { payload: action.payload.programs }
        ),
        programComponents: replaceToName(
          state.programComponents,
          { payload: action.payload.programComponents }
        ),
        programComponentsByProgramId: mergeReplaceToIdGroup(
          state.programComponentsByProgramId,
          { payload: action.payload.programComponents },
          "programId",
          "programComponentId"
        ),
        componentObjects: replaceToName(
          state.componentObjects,
          { payload: action.payload.componentObjects }
        ),
        componentObjectsByComponentId: mergeReplaceToIdGroup(
          state.componentObjectsByComponentId,
          { payload: action.payload.componentObjects },
          "programComponentId",
          "componentObjectId",
        ),
      };
    default:
      if (isDevelopmentEnv) {
        throw new Error(
          `Unrecognized action in ProgramReducer: "${action.type}"`
        )
      }
      return state;
  }
}
