import ComsCard from "components/utils/cards/comsCard.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import LabelValuePair from "components/utils/labelValuePair.component";
import CustomLink from "components/utils/link.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import ButtonDefault from "components/utils/buttonDefault.component";
import CustomSelect from "components/utils/form-elements/select.component";
import { makeStyles, useTheme } from "mui-styles";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import { useCallback, useMemo } from "react";
import { sortByStringKey } from "utils/sort.utils";
import { H3 } from "components/utils/headerV2.component";
import EvaluationService from "services/Evaluation.service";
import { ACTION_REPLACE_EVALUATION_DC } from "reducers/shared/claimReview.reducer";
import { INPUT_TYPE_MULTILINE_TEXT, INPUT_TYPE_MULTISELECT } from "components/constants/evaluation.constants";
import Form from "components/utils/form-elements/form.component";
import { downloadFileBlob } from "utils/download.utils";
import DocumentService from "services/Document.service";
import { useMediaQuery } from "@mui/material";
import ClaimDocumentTable from "components/claims/documents/claimDocumentTable.component";
import { formatCash } from "utils/localization.utils";

const useStyles = makeStyles((theme) => ({
  processButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  selectFormLabel: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  selectInputLabel: {
    paddingRight: 32
  },
  // To vertically align with select fields
  multilineInput: {
    paddingTop: 16,
    paddingBottom: 16
  },
  documentLabel: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
  },
  downloadFileIcon: {
    maxHeight: 12,
    maxWidth: 12,
    marginLeft: "-2px",
    marginBottom: -2,
  },
  linkText: {
    whiteSpace: "normal",
  },
  link: {
    color: theme.palette.secondary.dark,
    fill: theme.palette.secondary.dark,
    whiteSpace: "noWrap",
    "&:hover": {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
      cursor: "pointer",
    },
    "& $downloadFileIcon": {
      color: "inherit",
    },
  },
}))

const ClaimEvaluation = (props) => {
  const classes = useStyles();
  const { claim, claimDocuments, evaluationTemplate, dispatch } = props;
  const theme = useTheme();
  const isXl = useMediaQuery(theme.breakpoints.up("xl"));
  const derivedClaimData = claim._derivedData.ClaimData;

  const inputFieldList = useMemo(() => {
    if (!evaluationTemplate?._associations?.EvaluationTemplateDCField) {
      return []
    } else {
      return sortByStringKey(
        evaluationTemplate._associations.EvaluationTemplateDCField,
        "position"
      )
    }
  }, [evaluationTemplate])

  const inputFieldColumns = useMemo(() => {
    const columnNumber = isXl ? 3 : 2;
    const count = inputFieldList.length;
    const itemsPerColumn = Math.ceil(count / columnNumber);
    const columns = [];
    for (let i = 0; i < columnNumber; i++) {
      const firstIndex = i * itemsPerColumn;
      const lastIndex = ((i + 1) * itemsPerColumn);
      columns.push(inputFieldList.slice(firstIndex, lastIndex));
    }
    return columns;
  }, [inputFieldList, isXl])

  const currentEvaluationDCList = useMemo(() => (
    claim._associations.EvaluationDC
  ), [claim._associations.EvaluationDC])

  const handleDownloadDocument = useCallback(async document => {
    const blob = await DocumentService.downloadDocumentFile(
      document.documentId,
      document.fileRef
    );
    return downloadFileBlob(blob, document.fileRef);
  }, []);

  const handleAutosave = useCallback(async (
    event, type, evaluationDCId
  ) => {
    const isCreate = !evaluationDCId
    let evaluationData = {
      evaluationTemplateDCFieldId: event.target.name,
      evaluationDCId,
      claimId: claim.claimId,
    };
    if ( type === INPUT_TYPE_MULTISELECT ) {
      evaluationData = {
        ...evaluationData,
        evaluationTemplateDCResponseOptionId: event.target.value
      }
    } else if ( type === INPUT_TYPE_MULTILINE_TEXT ) {
      evaluationData = {
        ...evaluationData,
        value: event.target.value
      }
    }
    if (isCreate) {
      evaluationData = {
        ...evaluationData,
        consentOrderId: claim.consentOrderId
      }
    }
    const result = await EvaluationService.upsertEvaluationDC(evaluationData);
    dispatch({
      type: ACTION_REPLACE_EVALUATION_DC,
      payload: {
        evaluationDC: result.payload,
        claimId: claim.claimId
      }
    });
  }, [claim, dispatch])

  const generateInputField = useCallback((input) => {
    const link = claimDocuments.find(
      doc => doc.evaluationTemplateDCFieldId === input.evaluationTemplateDCFieldId
    );
    const currentValueField = currentEvaluationDCList.find(
      (value) => value.evaluationTemplateDCFieldId === input.evaluationTemplateDCFieldId
    );

    if (input.type === INPUT_TYPE_MULTISELECT) {
      const options = input._associations.EvaluationTemplateDCResponseOption.map(
        (option) => ({
          key: option.evaluationTemplateDCResponseOptionId,
          label: option.responseOption,
          value: option.evaluationTemplateDCResponseOptionId
        })
      )
      return (
        <CustomSelect
          label={(
            <div className={classes.selectFormLabel}>
              <span className={classes.selectInputLabel}>
                {input.label}
              </span>
              {!!link && (
                <span>
                  <span className={classes.documentLabel}>Attachment: </span>
                  <CustomLink
                    className={classes.link}
                    onClick={() => handleDownloadDocument(link)}
                  >
                    <AttachFileOutlinedIcon
                      className={classes.downloadFileIcon}
                    />
                    <span className={classes.linkText}>
                      {link.fileRef}
                    </span>
                  </CustomLink>
                </span>
              )}
            </div>
          )}
          options={options}
          value={currentValueField?.evaluationTemplateDCResponseOptionId}
          name={input.evaluationTemplateDCFieldId}
          onChange={(event) => (
            handleAutosave(
              event,
              INPUT_TYPE_MULTISELECT,
              currentValueField?.evaluationDCId
            )
          )}
        />
      )
    } else if (input.type === INPUT_TYPE_MULTILINE_TEXT) {
      return (
        <LabelInput
          variant="default"
          label={input.label}
          multiline
          defaultValue={currentValueField?.value}
          rows={6}
          name={input.evaluationTemplateDCFieldId}
          className={classes.multilineInput}
          onAutosave={(_name, _value, event) => (
            handleAutosave(
              event,
              INPUT_TYPE_MULTILINE_TEXT,
              currentValueField?.evaluationDCId
            )
          )}
        />
      )
    }
  }, [
    currentEvaluationDCList, claimDocuments, classes,
    handleAutosave, handleDownloadDocument
  ])

  return (
    <ComsCard>
      <Form
        name="evaluation-form"
      >
        <GridContainer spacing={2} hasVerticalSpacing>
          <GridItem xs={6} xl={4}>
            <H3>
              Claim Evaluation
            </H3>
          </GridItem>
          <GridItem xs={3} xl={4}>
            <CustomLink variant="noHRef" disabled>
              Add Status Report Item
            </CustomLink>
          </GridItem>
          <GridItem xs={3} xl={4}>
            <CustomLink variant="noHRef" disabled>
              Add Final Report Item
            </CustomLink>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <LabelValuePair
              label="GS Loan Number"
              value={derivedClaimData.gsLoanNumber}
            />
            <LabelValuePair
              label="Request Type"
              value={derivedClaimData.requestType}
            />
            <LabelValuePair
              label="Requested Dollar Amount"
              value={
                formatCash(derivedClaimData.requestedDollarAmt)
              }
            />
            <LabelValuePair
              label="Actual Cost Description"
              value={derivedClaimData.actualCostDesc}
            />
          </GridItem>
          {/*  ======== DOCUMENTS TABLE ========== */}
          <GridItem xs={12} sm={6}>
            <ClaimDocumentTable
              handleDownloadDocument={handleDownloadDocument}
              attachmentDocs={claimDocuments.filter(
                (doc) => !doc.evaluationTemplateDCFieldId
              )}
              claim={claim}
              dispatch={dispatch}
            />
          </GridItem>
          {/* ============= DC INPUTS  ============*/}
          {inputFieldColumns.map((inputs) => (
            <GridItem xs={6} xl={4} key={inputs[0]?.evaluationTemplateDCFieldId}>
              <GridContainer spacing={2}>
                {inputs.map((input) => (
                  <GridItem
                    xs={12}
                    key={input.evaluationTemplateDCFieldId}
                  >
                    {generateInputField(input)}
                  </GridItem>
                ))}
              </GridContainer>
            </GridItem>
          ))}
          <GridItem xs={12} className={classes.processButton}>
            <ButtonDefault
              disabled
            >
              Process
            </ButtonDefault>
          </GridItem>
        </GridContainer>
      </Form>
    </ComsCard>
  )
}

export default ClaimEvaluation;