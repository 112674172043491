import { Box } from "@mui/material";
import { DATE_FORMAT_DISPLAY_NUMERIC, DATE_FORMAT_DISPLAY_NUMERIC_SHORT, TIME_FORMAT_12H_SHORT } from "constants/date.constants";
import moment from "moment";
import CustomLink from "components/utils/link.component";

export function makeDateCell(date, displayFormat = null) {
  if (!date) {
    return {
      node: "--",
      metadata: {
        sortValue: 0
      },
      toString: "--"
    };
  }
  const momentDate = moment(date);
  const display = (
    momentDate.format(displayFormat || DATE_FORMAT_DISPLAY_NUMERIC)
  );
  return {
    node: display,
    metadata: {
      sortValue: momentDate.valueOf(),
      textValue: display
    },
    toString: display
  };
}

export function makeDatetimeCell(date, formats = {}, onClick = null) {
  const dateFormat = formats?.dateFormat || DATE_FORMAT_DISPLAY_NUMERIC_SHORT;
  const timeFormat = formats?.timeFormat || TIME_FORMAT_12H_SHORT;
  if (!date) {
    return {
      node: "--",
      metadata: {
        sortValue: 0,
      },
      toString: "--",
    };
  }
  const formattedDate = moment(date).format(dateFormat);
  const formattedTime = moment(date).format(timeFormat);

  const display = onClick ? (
    <CustomLink onClick={onClick}>
      <Box display="flex" flexDirection="column">
        <div>{formattedDate}</div>
        <div>{formattedTime}</div>
      </Box>
    </CustomLink>
  ) : (
    <Box display="flex" flexDirection="column">
      <div>{formattedDate}</div>
      <div>{formattedTime}</div>
    </Box>
  );
  return {
    node: display,
    metadata: {
      sortValue: formattedDate.valueOf(),
      textValue: `${formattedDate}|${formattedTime}`,
    },
    toString: () => formattedDate,
  };
}
