import AjaxService from "services/base/Ajax.service";


class CustomerServiceBase extends AjaxService {

  async searchCustomers(
    abortController, searchQuery, requireHasClaims = null
  ) {
    let path = `/customer/search?query=${searchQuery}`;
    if (requireHasClaims !== null) {
      path = path + `&hasClaims=${requireHasClaims}`;
    }
    return this
      .getCancellable(
        path,
        abortController,
        { cancelUnfinishedRequests: true }
      )
      .then(response => response.data);
  }

  getCustomerCorrectionsAuditLog(abortController) {
    return this
      .getCancellable(
        `/customer/corrections/auditLog`,
        abortController,
        { preventDuplicateRequests: true }
      )
      .then((response) => response.data);
  }

  getCustomerByCifId(cifId) {
    return this
      .get(`/customer/${cifId}`)
      .then((response) => {
        return response.data;
      })
  }
}

const CustomerService = new CustomerServiceBase();
export default CustomerService;