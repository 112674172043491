import { makeStyles } from "mui-styles";
import { Box, ButtonBase, Divider, Paper } from "@mui/material";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import { H4 } from "components/utils/headerV2.component";
import RadioInput from "components/utils/form-elements/radioInput.component";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    width: "100%",
  },
  paper: {
    display: "flex",
    height: "100%",
    width: "100%",
    padding: "16px 24px",
    fontSize: theme.typography.body2.fontSize,
    boxShadow: theme.shadows[3],
    "&:hover": {
      boxShadow: theme.shadows[4]
    },
    [theme.breakpoints.only("md")]: {
      padding: 8
    }
  },
  paperDisabled: {
    boxShadow: theme.shadows[1],
  },
  radioDisabled: {
    opacity: theme.opacity.disabled
  },
  heading: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "100%",
    paddingRight: 24,
    "& $downloadFileIcon": {
      color: "inherit",
    },
    [theme.breakpoints.up("xl")]: {
      paddingRight: 0,
      flexDirection: "row",
      justifyContent: "space-between"
    }
  },
  headingOnly: {
    paddingRight: 0,
    [theme.breakpoints.up("xl")]: {
      flexDirection: "column"
    }
  },
}));


export default function FilterRadioCard(props) {
  const classes = useStyles();
  const {
    checked = false,
    children,
    disabled = false,
    label,
    onChange,
    sublabel = null,
    value
  } = props;

  return (
    <ButtonBase
      className={classes.root}
      tabIndex={-1}
      disabled={disabled}
      onClick={onChange}
      value={value}
      disableRipple
    >
      <Paper
        className={
          classNames(classes.paper, disabled && classes.paperDisabled)
        }
      >
        <GridContainer
          spacing={children ? 1 : 0}
          alignItems="stretch"
          flexWrap="nowrap"
        >
          <GridItem display="flex" alignItems="center">
            <RadioInput
              name={value}
              value={value}
              checked={!!checked && !disabled}
              disabled={disabled}
              onChange={onChange}
            />
          </GridItem>
          <GridItem display="flex" alignItems="center" xs>
            <GridContainer spacing={2} alignItems="stretch">
              <GridItem
                xs={12}
                lg="auto"
                xl={12}
                className={
                  classNames(classes.heading, !children && classes.headingOnly)
                }
              >
                <H4
                  align="left"
                  color={disabled ? "tertiary" : "primary"}
                  className={classNames(disabled && classes.radioDisabled)}
                >
                  {label}
                </H4>
                {!!sublabel && (
                  <div className={classes.sublabel}>
                    {sublabel}
                  </div>
                )}
              </GridItem>
              {!!children && (
                <>
                  <GridItem xs={false} md={12} lg="auto" xl={12}>
                    <Box marginTop={0.5} marginBottom={0.5}>
                      <Divider />
                    </Box>
                  </GridItem>
                  <GridItem align="left" xs={12} lg>
                    {children}
                  </GridItem>
                </>
              )}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Paper>
    </ButtonBase>
  );
}
