import classNames from "classnames";
import { makeStyles } from "mui-styles";

const useStyles = makeStyles(theme => ({
  scrollContainerLastSibling: {
    minHeight: "100vh"
  },
  scrollTarget: {
    marginTop: -1 * theme.layout.height.header,
    paddingTop: theme.layout.height.header
  }
}));


export default function ScrollTargetContainer(props) {
  const {
    children,
    id,
    // Container doesn't take full screen height when true
    isBeforeSibling = false,
    scrollTargetRef = null
  } = props;
  const classes = useStyles();
  return (
    <div
      className={
        classNames(!isBeforeSibling && classes.scrollContainerLastSibling)
      }
    >
      <div
        className={classes.scrollTarget}
        id={id}
        ref={scrollTargetRef}
      />
      {children}
    </div>
  )
}
