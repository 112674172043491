import { CheckCircleOutline, OpenInBrowser, Refresh } from "@mui/icons-material";
import { Box, Dialog, DialogContent, Paper, useMediaQuery, useTheme } from "@mui/material";
import classNames from "classnames";
import { REISSUE_REASON_LABELS } from "components/constants/payment.constants";
import ReissueCheckForm from "components/shared/payments/ReissueCheckForm.component";
import { comparePayments } from "components/shared/payments/utils/payments.utils";
import ButtonDefault from "components/utils/buttonDefault.component";
import ConfirmationDialog from "components/utils/dialogs/confirmationDialog.component";
import FormBanner from "components/utils/form-elements/formBanner.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import LabelValuePair from "components/utils/labelValuePair.component";
import Tooltip from "components/utils/tooltip.component";
import { DATE_FORMAT_DISPLAY_NUMERIC } from "constants/date.constants";
import moment from "moment";
import { makeStyles } from "mui-styles";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import PaymentService from "services/Payment.service";
import { formatCash } from "utils/localization.utils";
import StatisticsContext from "contexts/statistics.context";
import SummaryCountService from "services/SummaryCount.service";
import { ACTION_REPLACE_POPULATION, ACTION_SET_SUMMARY_COUNT } from "reducers/global/statistics.reducer";
import { isReadOnly } from "utils/roles.utils";

const useStyles = makeStyles(theme => ({
  checkPaper: {
    height: "100%",
    maxWidth: 480,
    padding: "4px 12px",
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight
  },
  checkPaperSmall: {
    padding: "0px 8px 2px",
    fontSize: theme.typography.body2.fontSize,
  },
  previousCheck: {
    background: theme.palette.grey[100],
    transition: "opacity 0.2s ease",
  },
  row: {
    paddingTop: 3,
    paddingBottom: 0,
    marginBottom: 3,
  },
  status: {
    fontWeight: theme.typography.fontWeightBold
  },
  statusPending: {
    color: theme.palette.secondary.main,
  },
  statusReissued: {
    color: theme.palette.error.light,
  },
  statusCancelled: {
    borderBottom: `1px dotted ${theme.palette.error.light}88`,
  },
  statusPaid: {
    color: theme.palette.success.main,
  },
  cashedDate: {
    fontWeight: theme.typography.fontWeightRegular
  },
  payeeLine: {
    flexWrap: "wrap",
    marginBottom: 2,
    paddingBottom: 0,
    borderBottom: `1px dotted ${theme.palette.border.dark}`,
    wordBreak: "break-word",
  },
  lineTooltipTrigger: {
    cursor: "pointer"
  },
  lineTooltipContent: {
    fontWeight: 400
  },
  lineTooltipLabel: {
    color: theme.palette.common.white,
  },
  cash: {
    border: `1px solid ${theme.palette.border.light}`,
    minWidth: 80,
    textAlign: "right",
    marginTop: 1,
    marginBottom: 2,
    padding: "1px 4px 0",
    fontWeight: theme.typography.fontWeightBold
  },
  reissueRow: {
    display: "flex",
    alignItems: "stretch",
    flexWrap: "wrap",
    marginBottom: 4
  },
  reissueButton: {
    marginTop: 4,
  },
  reissueButtonSmall: {
    marginTop: 2,
    paddingTop: 6,
    paddingBottom: 6
  },
  reissueButtonPending: {
    marginRight: 6,
  },
  markPaidButton: {
    marginRight: -8
  },
  markPaidButtonSmall: {
    marginRight: -12
  },
  reissueIcon: {
    marginTop: -5,
    marginRight: 4,
    marginBottom: -5,
    fontSize: 14
  },
  reissueIconSmall: {
    marginRight: 4,
    marginBottom: -5,
    fontSize: 12
  },
  checkNumberRow: {
    whiteSpace: "normal"
  },
}))

const statusDisplay = {
  Pending: "Pending Deposit",
  Cancelled: "Cancelled",
  Returned: "Returned",
  Paid: "Paid",
};

const BREAKPOINT_HIGH_LG = 1400;
const BREAKPOINT_HIGH_LG_SMALL = 1700;


export default function CheckListing(props) {
  const {
    claim = null, distributionDataset = null, size = "medium",
    setClaimPayments, setDistributionPayments, setWfInstances = null
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const readOnly = useMemo(isReadOnly, []);

  const is3ColLg = useMediaQuery(
    theme.breakpoints.up(
      size === "small" ? BREAKPOINT_HIGH_LG_SMALL : BREAKPOINT_HIGH_LG
    )
  );
  const { dispatch: summaryCountsDispatch } = useContext(StatisticsContext);
  const summaryRequestAbortRef = useRef(null);
  const [paidConfirmPaymentId, setPaidConfirmPaymentId] = useState(null);
  const [reissueModalPaymentId, setReissueModalPaymentId] = useState(null);

  useEffect(() => {
    if (!claim && !distributionDataset) {
      throw new Error(
        "Either a Claim or Distribution must be associated to a Payment."
      );
    }
  }, [claim, distributionDataset]);

  const distributionPayments = useMemo(() => (
    [].concat(
      distributionDataset?._associations?.Payment || []
    ).sort(comparePayments)
  ), [distributionDataset]);

  const claimPayments = useMemo(() => (
    [].concat(claim?._associations?.Payment || []).sort(comparePayments)
  ), [claim]);

  const payments = useMemo(() => (
    (distributionPayments || []).concat(claimPayments || [])
  ), [distributionPayments, claimPayments]);

  const classesByStatus = useMemo(() => ({
    Pending: classes.statusPending,
    Cancelled: classNames(classes.statusReissued, classes.statusCancelled),
    Returned: classes.statusReissued,
    Paid: classes.statusPaid,
  }), [classes]);

  const reissuePayment = useMemo(() => (
    payments?.find?.(payment => payment.paymentId === reissueModalPaymentId)
  ), [payments, reissueModalPaymentId]);

  const confirmMarkPaidPayment = useMemo(() => (
    payments?.find?.(payment => payment.paymentId === paidConfirmPaymentId)
  ), [payments, paidConfirmPaymentId]);

  const handleReissueClick = useCallback(event => {
    const paymentId = event.currentTarget.dataset?.paymentId;
    setReissueModalPaymentId(paymentId);
  }, []);

  const handleMarkPaidClick = useCallback(event => {
    const paymentId = event.currentTarget.dataset?.paymentId;
    setPaidConfirmPaymentId(paymentId);
  }, []);

  const handleReissueSuccess = useCallback(async (response) => {
    try {
      const { payments: newPayments, wfInstances } = response.payload;
      if (newPayments[0].claimId) {
        setClaimPayments(newPayments)
      } else {
        setDistributionPayments(newPayments)
      }
      if (response.payload.wfInstances && setWfInstances) {
        setWfInstances?.(wfInstances);
      }
      setReissueModalPaymentId(null);
    } finally {
      summaryRequestAbortRef.current = new AbortController();
      const statsResponse = await SummaryCountService.getAll(
        summaryRequestAbortRef.current
      );
      summaryCountsDispatch({
        type: ACTION_SET_SUMMARY_COUNT,
        payload: statsResponse.payload
      });
    }
  },
  [
    setClaimPayments,
    setDistributionPayments,
    setWfInstances,
    summaryRequestAbortRef,
    summaryCountsDispatch,
  ]);

  const handleMarkPaidConfirm = useCallback(async () => {
    try {
      const response = (
        await PaymentService.updatePaymentPaid(confirmMarkPaidPayment.paymentId)
      );
      if (confirmMarkPaidPayment?.claimId) {
        setClaimPayments([response.payload.payment]);
      } else {
        setDistributionPayments([response.payload.payment]);
      }
      if (response.payload.wfInstances && setWfInstances) {
        setWfInstances(response.payload.wfInstances);
      }
      if (response.payload.population) {
        summaryCountsDispatch({
          type: ACTION_REPLACE_POPULATION,
          payload: response.payload.population
        })
      }
      setPaidConfirmPaymentId(null);

    } finally {
      summaryRequestAbortRef.current = new AbortController();
      const statsResponse = await SummaryCountService.getAll(
        summaryRequestAbortRef.current
      );
      summaryCountsDispatch({
        type: ACTION_SET_SUMMARY_COUNT,
        payload: statsResponse.payload
      });
    }
  }, [
    confirmMarkPaidPayment, setClaimPayments, setDistributionPayments,
    setWfInstances, summaryRequestAbortRef, summaryCountsDispatch
  ]);

  return (
    <div>
      {!payments?.length ? (
        <Box marginTop={-2} marginBottom={-2}>
          <p>No associated payments found</p>
        </Box>
      ) : (
        <GridContainer
          marginTop={-1}
          spacing={{ xs: size === "small" ? 1 : 2, lg: 2 }}
          alignItems="stretch"
          hasVerticalSpacing
        >
          {payments.map((payment, _index, all) => {
            const {
              amount, issueDate, paymentId,
              payeeAddress, payeeFirstName, payeeLastName, status
            } = payment;
            const reissueRequest = (
              payment?._associations?.PaymentReissueRequest?.[0]
            );
            const claimPaymentIndex = claimPayments.indexOf(payment);
            const distributionPaymentIndex = (
              distributionPayments.indexOf(payment)
            );
            const isPrevious = (
              claimPaymentIndex > -1 &&
              (claimPaymentIndex + 1) !== claimPayments.length
            ) || (
              distributionPaymentIndex > -1 &&
              (distributionPaymentIndex + 1) !== distributionPayments.length
            );
            return (
              <GridItem
                xs={12}
                md={all.length > 1 ? 6 : 12}
                lg={(all.length > 2 && is3ColLg) ? 4 : 6}
                xl={(all.length <= 3 || size === "small") ? 4 : 3}
                key={paymentId}
              >
                <Paper
                  className={
                    classNames(
                      classes.checkPaper,
                      isPrevious && classes.previousCheck,
                      size === "small" && classes.checkPaperSmall
                    )
                  }
                  square
                >
                  <Box height="100%" display="flex" flexDirection="column">
                    <GridContainer
                      spacing={2}
                      alignItems="baseline"
                      justifyContent="space-between"
                    >
                      <GridItem>
                        <span
                          className={
                            classNames(
                              classes.row,
                              classes.status,
                              classesByStatus[status]
                            )
                          }
                        >
                          <Tooltip
                            enterDelay={500}
                            className={classes.lineTooltipTrigger}
                            placement="top"
                            title={
                              status === "Cancelled" && !!reissueRequest && (
                                <div className={classes.lineTooltipContent}>
                                  <LabelValuePair
                                    label={(
                                      <span
                                        className={classes.lineTooltipLabel}
                                      >
                                        Cancellation Reason
                                      </span>
                                    )}
                                    value={
                                      REISSUE_REASON_LABELS[
                                        reissueRequest?.justification
                                      ]
                                    }
                                    className={classes.lineTooltipLabel}
                                  />
                                </div>
                              )
                            }
                          >
                            <span>
                              {statusDisplay[status]}
                            </span>
                          </Tooltip>
                          {status === "Paid" && !!payment.cashedDate && (
                            <span className={classes.cashedDate}>
                              :&nbsp;
                              {moment(payment.cashedDate)
                                .format(DATE_FORMAT_DISPLAY_NUMERIC)
                              }
                            </span>
                          )}
                        </span>
                      </GridItem>
                      <GridItem>
                        <LabelValuePair
                          label="Issued"
                          value={
                            moment(issueDate)
                              .format(DATE_FORMAT_DISPLAY_NUMERIC)
                          }
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer spacing={2} flex={1} flexWrap="nowrap">
                      <GridItem
                        xs={12}
                        display="flex"
                        flexDirection="column"
                      >
                        <Box marginBottom="auto" cursor="default">
                          <Tooltip
                            enterDelay={500}
                            className={classes.lineTooltipTrigger}
                            title={(
                              <div className={classes.lineTooltipContent}>
                                <LabelValuePair
                                  label={(
                                    <span className={classes.lineTooltipLabel}>
                                      First Name
                                    </span>
                                  )}
                                  value={payeeFirstName}
                                />
                                <LabelValuePair
                                  label={(
                                    <span className={classes.lineTooltipLabel}>
                                      Last Name
                                    </span>
                                  )}
                                  value={payeeLastName}
                                />
                                <LabelValuePair
                                  label={(
                                    <span className={classes.lineTooltipLabel}>
                                      Address
                                    </span>
                                  )}
                                  value={payeeAddress}
                                />
                              </div>
                            )}
                          >
                            <div>
                              <LabelValuePair
                                label="Payee"
                                value={
                                  (payeeFirstName && payeeLastName) ?
                                    `${payeeFirstName} ${payeeLastName}` :
                                    "Unknown"
                                }
                                className={classes.payeeLine}
                              />
                            </div>
                          </Tooltip>
                        </Box>
                        {status !== "Paid" && (
                          <span
                            className={
                              classNames(
                                classes.reissueRow,
                                size === "small" && classes.reissueRowSmall,
                              )
                            }
                          >
                            {((!!readOnly && status !== "Pending") || !readOnly) && (
                              <ButtonDefault
                                color="primary"
                                className={classNames(
                                  classes.reissueButton,
                                  size === "small" &&
                                    classes.reissueButtonSmall,
                                  status === "Pending" &&
                                    classes.reissueButtonPending
                                )}
                                variant="small"
                                startIcon={
                                  status === "Pending" ? (
                                    <Refresh
                                      className={classNames(
                                        classes.reissueIcon,
                                        size === "small" &&
                                          classes.reissueIconSmal
                                      )}
                                    />
                                  ) : (
                                    <OpenInBrowser
                                      className={classNames(
                                        classes.reissueIcon,
                                        size === "small" &&
                                          classes.reissueIconSmall
                                      )}
                                    />
                                  )
                                }
                                data-payment-id={paymentId}
                                onClick={handleReissueClick}
                              >
                                {status === "Pending"
                                  ? "Reissue"
                                  : "View Reissue Request"}
                              </ButtonDefault>
                            )}
                            {status === "Pending" && !readOnly && (
                              <ButtonDefault
                                color="success"
                                className={
                                  classNames(
                                    classes.reissueButton,
                                    classes.markPaidButton,
                                    size === "small" &&
                                      classes.reissueButtonSmall,
                                    size === "small" &&
                                      classes.markPaidButtonSmall
                                  )
                                }
                                variant="small"
                                startIcon={
                                  <CheckCircleOutline
                                    className={
                                      classNames(
                                        classes.reissueIcon,
                                        size === "small" &&
                                          classes.reissueIconSmall
                                      )
                                    }
                                  />
                                }
                                data-payment-id={paymentId}
                                onClick={handleMarkPaidClick}
                              >
                                Paid
                              </ButtonDefault>
                            )}
                          </span>
                        )}
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm="auto"
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-end"
                      >
                        <span
                          className={classNames(classes.row, classes.cash)}
                        >
                          {formatCash(amount, true)}
                        </span>
                        <Box
                          marginTop="auto"
                          className={
                            classNames(classes.row, classes.checkNumberRow)
                          }
                        >
                          {claimPaymentIndex > -1 ?
                            `Claim Check #${claimPaymentIndex + 1}` :
                            <>
                              Initial Distribution&nbsp;#
                              {distributionPaymentIndex + 1}
                            </>
                          }
                        </Box>
                      </GridItem>
                    </GridContainer>
                  </Box>
                </Paper>
              </GridItem>
            );
          })}
        </GridContainer>
      )}
      <Dialog
        open={!!reissueModalPaymentId}
        onClose={() => setReissueModalPaymentId(null)}
        maxWidth="lg"
        TransitionProps={{ exit: false }}
      >
        <FormBanner>Reissue Check Request</FormBanner>
        <DialogContent>
          <ReissueCheckForm
            claim={claim}
            distributionDataset={distributionDataset}
            payment={reissuePayment}
            onClose={() => setReissueModalPaymentId(null)}
            onSuccess={handleReissueSuccess}
          />
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        open={!!paidConfirmPaymentId}
        onClose={() => setPaidConfirmPaymentId(null)}
        confirm={handleMarkPaidConfirm}
        dialogTitle="Confirmation"
        continueAndCancel
      >
        <Box align="center">
          <p>Please confirm that the recipient has deposited this check.</p>
          <p>This action cannot be undone.</p>
        </Box>
      </ConfirmationDialog>
    </div>
  )
}
