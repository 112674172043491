import {
  currentRefreshToken,
  currentUserToken,
} from "services/util/authSession.utils";
import AuthService from "services/base/Auth.service";
import { decodeJwt } from "jose";
import StorageService from "services/Storage.service";

export default function authHeader() {
  let accessToken = currentUserToken();
  const refreshToken = currentRefreshToken();
  const consentOrderId = StorageService.getSessionItem(
    StorageService.STORAGE_KEY_ACTIVE_CONSENT_ORDER_ID,
    false
  );

  if (accessToken && refreshToken) {
    const currentTime = Math.floor(new Date().getTime() / 1000);
    const isAlmostExpired = decodeJwt(refreshToken).exp - currentTime < 960;
    if (!isAlmostExpired) {
      AuthService.refreshToken(refreshToken)
        .then((response) => {
          accessToken = response.updatedAccessToken;
        })
        .catch(() => {
          AuthService.logout();
        });
    }
    if (consentOrderId) {
      return {
        "x-access-token": accessToken,
        "x-consent-order-id": consentOrderId,
      };
    } else {
      return { "x-access-token": accessToken };
    }
  } else {
    return {};
  }
}
