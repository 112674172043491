export const builderSectionTypes = {
  PROC_ELEMENT: "procedureElement",
  PROC_STEP: "procedureStep",
  WORKPACKAGE: "workpackage",
  WP_BUTTONS: "wpButtons",
  REFERENCE: "reference",
  REFERENCE_TABLE: "referenceTable"
};

export const builderModes = {
  COPY_PREV_BUILDER: "copyPrevBuilder",
  EDIT_CURRENT: "editCurrent",
  LOAD_TEMPLATE: "loadTemplate"
}

export const builderTypes = {
  CHARTER: "Charter",
  PLAN: "Plan",
  POLICY: "Policy",
  PROCEDURE: "Procedure",
  DOCUMENT: "Document",
};

export const missingSectionError = {
  type: "error",
  message: "Section headers are required for each section.",
}

export const defaultBuilderSaveError = {
  type: "error",
  message: "There was a problem saving the builder. Please exit and try again.",
}

export const wpButtonSectionHeader = "Add a Workpackage and Supporting Tasks";

export const wpButtonDefaultSectionHeader = "Add Section"

export const wpButtonTypeClientIds = {
  FIRST: "wpButtons-first",
  WP: "wpButtons-wp",
  LAST: "wpButtons-last",
}

export const firstWPButtonsSection = {
  Section_Order: 0,
  SectionContent: "",
  SectionHeader: "Add Section",
  Type: builderSectionTypes.WP_BUTTONS,
  _meta: {
    clientId: wpButtonTypeClientIds.FIRST,
  },
}
