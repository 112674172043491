import DataTable from "components/utils/tables/dataTable.component";

const ContactLogTable = (props) => {
  const { columns, data } = props;

  return (
    <DataTable
      columns={columns}
      data={data}
      options={{
        download: false,
        filterType: "checkbox",
        fixedHeader: true,
        filter: false,
        print: true,
        search: true,
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        tableBodyMaxHeight: "calc(60vh)",
        responsive: "simple",
        pagination: true,
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10],
      }}
    />
  );
};

export default ContactLogTable;
