import classNames from "classnames";
import { makeStyles, useTheme } from "mui-styles";
import { Fragment, useLayoutEffect, useMemo } from "react";
import { debounce, useMediaQuery } from "@mui/material";
import { GridItem } from "components/utils/grid/gridItem.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import LinearFlowchartMultiLineGridItem from "./LinearFlowchartMultiLineGridItem.component";
import { useRef, useCallback, useState } from "react";

const BORDER_WIDTH_ARROW = 2;
const PADDING_MULTI_ROW_GUTTER = 35;

const SPACING_UNITS_ROW = 3;
const SPACING_UNITS_COLUMN = 1;

const BREAKPOINT_XS = "xs";
const BREAKPOINT_SM = "sm";
const BREAKPOINT_MD = "md";
const BREAKPOINT_LG = "lg";
const BREAKPOINT_XL = "xl";
const BREAKPOINT_XXL = "xxl";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `15px 0 0`,
    paddingTop: 6 // 5px + 1px for box shadow of child workflow IconButton
  },
  gridAutoWidth: {
    flexWrap: "nowrap",
    justifyContent: "start",
    paddingRight: 0,
    paddingLeft: 0,
  },
  arrowNextRowLine: {
    display: "grid",
    gridTemplateColumns: "minmax(30px, 1fr) max-content minmax(30px, 1fr)"
  },
  arrowRowEndLine: {
    borderTop: `${BORDER_WIDTH_ARROW}px solid ${theme.palette.grey.medium}`,
    position: "absolute",
    bottom: 0,
    left: 35,
    right: 35,
  },
  arrowRowEndBottom: {
    position: "absolute",
    top: -10,
    right: 0,
    bottom: 0,
    width: PADDING_MULTI_ROW_GUTTER,
    border: `${BORDER_WIDTH_ARROW}px solid ${theme.palette.grey.medium}`,
    borderTop: 0,
    borderLeft: 0,
    borderBottomRightRadius: 4
  },
  arrowNextRowStart: {
    position: "absolute",
    top: "calc(100% - 2px)",
    height: theme.spacing(SPACING_UNITS_ROW + 2),
    width: 30,
    border: `${BORDER_WIDTH_ARROW}px solid ${theme.palette.grey.medium}`,
    borderRight: 0,
    borderBottom: 0,
    borderTopLeftRadius: 4
  },
}));

const calculateSizeForColumns = columnCount => (
  12 / parseInt(columnCount, 10)
);


const LinearFlowchartMultiLine = (props) => {
  const {
    columns, // One number or an object of breakpoints mapped to numbers
    childItems,
    disconnectedItems,
    items,
    ItemComponent,
    scrollContainerRef,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const gridRowCellsRef = useRef([]);

  const [isCellsRefReady, setIsCellsRefReady] = useState(false);
  const [isWidthUpdatePending, setIsWidthUpdatePending] = useState(false);

  const isXs = useMediaQuery(theme.breakpoints.up(BREAKPOINT_XS));
  const isSm = useMediaQuery(theme.breakpoints.up(BREAKPOINT_SM));
  const isMd = useMediaQuery(theme.breakpoints.up(BREAKPOINT_MD));
  const isLg = useMediaQuery(theme.breakpoints.up(BREAKPOINT_LG));
  const isXl = useMediaQuery(theme.breakpoints.up(BREAKPOINT_XL));
  const isXxl = useMediaQuery(theme.breakpoints.up(BREAKPOINT_XXL));

  const columnsByBreakpoint = useMemo(() => {
    if (!columns) {
      return null;
    } else if (["string", "number"].includes(typeof columns)) {
      return { xs: Math.min(columns, items.length) };
    }
    const itemCount = (items?.length || 0) + (disconnectedItems?.length || 0);
    return Object.fromEntries(
      Object.entries(columns).map(([breakpoint, value]) => (
        [breakpoint, Math.max(Math.min(value, itemCount), 1)]
      ))
    );
  }, [columns, disconnectedItems?.length, items.length]);

  const gridItemSizeProps = useMemo(() => {
    if (!columnsByBreakpoint) {
      return { xs: "auto" };
    }
    return Object.fromEntries(
      Object.entries(columnsByBreakpoint).map(([breakpoint, columnCount]) => (
        [breakpoint, calculateSizeForColumns(columnCount)]
      ))
    );
  }, [columnsByBreakpoint]);

  const currentColumns = useMemo(() => {
    if (!columnsByBreakpoint) {
      return null;
    }
    return (
      (isXxl && columnsByBreakpoint[BREAKPOINT_XXL]) ||
      (isXl && columnsByBreakpoint[BREAKPOINT_XL]) ||
      (isLg && columnsByBreakpoint[BREAKPOINT_LG]) ||
      (isMd && columnsByBreakpoint[BREAKPOINT_MD]) ||
      (isSm && columnsByBreakpoint[BREAKPOINT_SM]) ||
      (isXs && columnsByBreakpoint[BREAKPOINT_XS])
    ) || null
  }, [columnsByBreakpoint, isXxl, isXl, isLg, isMd, isSm, isXs]);

  const rowCount = useMemo(() => (
    currentColumns ? Math.ceil(items.length / currentColumns) : 1
  ), [items, currentColumns]);

  const itemsRows = useMemo(() => {
    if (rowCount === 1) {
      return [items];
    }
    const rows = [];
    const remainingItems = [...items];
    for (let i = 0; i < rowCount; i++) {
      rows.push(remainingItems.splice(0, currentColumns));
    }
    return rows;
  }, [items, currentColumns, rowCount]);

  const isDisconnectedItemsNewLine = useMemo(() => {
    if (!disconnectedItems?.length) {
      return false;
    }
    const lastRowRemainderCount = (rowCount * currentColumns) - items.length;
    return lastRowRemainderCount < disconnectedItems.length;
  }, [currentColumns, disconnectedItems, items, rowCount]);

  const handleGridItemRef = useCallback((node, rowIndex, index, isLast) => {
    if (node) {
      if (!gridRowCellsRef.current[rowIndex]) {
        gridRowCellsRef.current[rowIndex] = [];
      }
      gridRowCellsRef.current[rowIndex][index] = node;
      if (isLast) {
        setIsCellsRefReady(true);
      }
    }
  }, []);

  const rowHeights = useMemo(() => {
    if (isWidthUpdatePending) {
      return [];
    }
    if (isCellsRefReady && gridRowCellsRef.current?.length) {
      return gridRowCellsRef.current.map(cellNodes => (
        cellNodes.reduce((max, node) => (
          Math.max(max, node.clientHeight)
        ), 0)
      ));
    }
    return [];
  }, [isCellsRefReady, isWidthUpdatePending]);

  const scrollXTargetNode = useMemo(() => {
    if (!isCellsRefReady) {
      return null;
    }
    const refRowLength = gridRowCellsRef.current?.length;
    if (
      refRowLength !== itemsRows?.length ||
      !gridRowCellsRef.current?.[refRowLength - 1]?.length
    ) {
      return null;
    }
    for (const rowItems of itemsRows) {
      const rowIndex = itemsRows.indexOf(rowItems);
      const columnIndex = rowItems.findIndex(itemData => (
        itemData?.isLastActiveNode
      ));
      if (columnIndex > -1) {
        return gridRowCellsRef.current[rowIndex][columnIndex];
      }
    }
  }, [isCellsRefReady, itemsRows]);

  useLayoutEffect(function setDebouncedResizeUpdate() {
    if (isWidthUpdatePending) {
      setIsWidthUpdatePending(false);
    }
    let onDebounce = () => {
      setIsWidthUpdatePending(true)
    };
    const resizeListener = debounce(onDebounce, 100);

    window.addEventListener("resize", resizeListener);

    return () => {
      onDebounce = () => {};
      window.removeEventListener("resize", resizeListener)
    };
  }, [isWidthUpdatePending, scrollContainerRef]);

  useLayoutEffect(function scrollToLatestNode() {
    if (scrollContainerRef?.current && scrollXTargetNode) {
      const containerElement = scrollContainerRef.current;
      const containerBox = containerElement.getBoundingClientRect();
      const targetBoundingBox = scrollXTargetNode.getBoundingClientRect();
      const containerRadius = containerBox.width / 2;
      const targetRadius = targetBoundingBox.width / 2;

      const gridPadding = parseInt(theme.spacing(SPACING_UNITS_COLUMN * 2));
      const containerLeftOrigin = (
        containerElement.scrollLeft - containerBox.left - gridPadding
      );
      const targetOffsetLeft = targetBoundingBox.left + containerLeftOrigin;
      const scrollToX = targetOffsetLeft - containerRadius + targetRadius;

      containerElement.scrollTo({ left: scrollToX });
    }
  }, [scrollContainerRef, scrollXTargetNode, theme]);

  return (
    <div className={classes.root}>
      <GridContainer
        className={
          classNames(!currentColumns && classes.gridAutoWidth)
        }
        alignItems="stretch"
        justifyContent="stretch"
        spacing={SPACING_UNITS_COLUMN}
        rowSpacing={SPACING_UNITS_ROW * (currentColumns ? 1 : 1.5)}
      >
        {itemsRows.map((rowItems, rowIndex, allRows) => (
          <Fragment key={rowIndex}>
            {rowItems.map((itemData, columnIndex, allCells) => (
              <LinearFlowchartMultiLineGridItem
                allRowItems={rowItems}
                childItems={childItems}
                currentColumns={currentColumns}
                height={rowHeights[rowIndex]}
                itemCount={items.length}
                itemData={itemData}
                columnIndex={columnIndex}
                ItemComponent={ItemComponent}
                gridItemSizeProps={gridItemSizeProps}
                paperRef={node => handleGridItemRef(
                  node,
                  rowIndex,
                  columnIndex,
                  (
                    allRows.length - 1 === rowIndex &&
                    allCells.length - 1 === columnIndex
                  )
                )}
                rowIndex={rowIndex}
                key={itemData?.wfNodeId || columnIndex}
              />
            ))}
            {rowIndex !== (allRows.length - 1) && (
              <GridItem xs={12} marginRight="auto" position="relative">
                <div className={classes.arrowNextRowLine}>
                  <div className={classes.arrowNextRowStart} />
                  <div className={classes.arrowRowEndLine} />
                  <div className={classes.arrowRowEndBottom} />
                </div>
              </GridItem>
            )}
          </Fragment>
        ))}
        {!!isDisconnectedItemsNewLine && (
          <GridItem xs={12}>
            <div />
          </GridItem>
        )}
        {disconnectedItems?.map?.((itemData, index, all) => (
          <GridItem
            marginLeft="auto"
            key={itemData?.wfNodeId || index}
            {...gridItemSizeProps}
          >
            <ItemComponent
              allRowItems={[
                ...all,
                ...(isDisconnectedItemsNewLine ? [] : itemsRows[rowCount - 1])
              ]}
              childFlowchart={childItems?.[itemData.wfNodeId]}
              connectors={{
                left: (
                  index === 0 &&
                  !columns ?
                    true :
                    !isDisconnectedItemsNewLine &&
                    ((rowCount * currentColumns) - 1) === items.length
                ),
                right: rowCount > 1
              }}
              height={
                rowHeights?.length ?
                  rowHeights[rowHeights.length - 1] :
                  undefined
              }
              item={itemData}
              tooltipPlacement="bottom"
              disconnected
            />
          </GridItem>
        ))}
      </GridContainer>
    </div>
  );
};


export default LinearFlowchartMultiLine;
