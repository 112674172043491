import React, { useState, useEffect, useMemo, useCallback } from "react";
import { MenuItem } from "@mui/material";
import { makeStyles } from "mui-styles";
import LabelInput from "components/utils/form-elements/labelInput.component";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import CustomSelect from "components/utils/form-elements/select.component";
import FormBanner from "components/utils/form-elements/formBanner.component";
import FormLabel from "components/utils/form-elements/formLabel.component";
import { validEmail, validPhone } from "utils/stringValidation.utils";
import { onlyNumericString } from "utils/string.utils";
//Services
import UserService from "services/User.service";
import { ROLES, ROLE_GROUPS } from "constants/roles.constants";
import { HTTP_STATUS_CONTENT } from "services/constants/response.constants";
import authService from "services/base/Auth.service";
import { getCurrentUserId } from "services/util/authSession.utils";
import Form from "components/utils/form-elements/form.component";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 16,
    paddingBottom: 16,
    width: 420,
  },
  form: {
    padding: "0px 32px 0px 32px",
    align: "center",
    display: "block",
    margin: "0px auto",
    width: "100%",
  },
  fieldsWrapper: {
    padding: "0px 40px",
  },
  formSection: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 8,
    paddingBottom: 8,
  },
  editEmailWrapper: {
    padding: "8px 0 0 0",
    /*
     * backgroundColor: theme.palette.grey.light,
     * borderRadius: 5
     */
  },
  editEmail: {
    padding: 8,
    paddingBottom: 16,
    fontSize: theme.typography.body2.fontSize,
  },
}));

const emailErrorMsg =
  "This email already exists in the system. Please enter a unique email address.";


const UserMgmtForm = ({
  mode,
  userToEdit,
  roles,
  setEmailSentMessage,
  editFormType,
  onClose,
  onUserCreate,
  onUserUpdate,
}) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const currentUserId = useMemo(getCurrentUserId, []);

  const availableRoles = useMemo(() => {
    const isGlobalRolesAvailable = (
      !userToEdit.userId ||
      userToEdit?.roleId === ROLES.NO_ACCESS ||
      ROLE_GROUPS.GLOBAL_USERS.includes(userToEdit?.roleId)
    );
    if (isGlobalRolesAvailable) {
      return roles.filter((role) => (
        [...ROLE_GROUPS.GLOBAL_USERS, ROLES.NO_ACCESS].includes(role.roleId)
      ));
    }
    return [].concat(
      roles.find(role => role.roleId === userToEdit?.roleId) || []
    );
  }, [roles, userToEdit]);

  const isRoleReadOnly = useMemo(() => (
    !availableRoles?.length ||
    (
      userToEdit &&
      currentUserId === userToEdit.userId &&
      authService.Type.ADMIN === userToEdit.roleId
    )
  ), [availableRoles, currentUserId, userToEdit]);

  useEffect(() => {
    if (mode === "edit") {
      setFormData({
        firstName: userToEdit.firstName,
        lastName: userToEdit.lastName,
        email: userToEdit.email,
        phone: userToEdit.phone,
        roleId: userToEdit.roleId,
      });
    }
  }, [mode, userToEdit]);

  const validateFormErrors = useCallback((data) => ({
    firstName: !data.firstName,
    lastName: !data.lastName,
    email: !data.email?.trim?.() || !validEmail(data.email) || !!errorMessage,
    phone: data.phone?.trim?.() && !validPhone(data.phone),
    roleId: !data.roleId
  }), [errorMessage]);

  const getHasErrors = useCallback((validations) => {
    return Object.values(validations).some(isError => isError)
  }, []);

  const hasErrors = useMemo(() => {
    return getHasErrors(formErrors)
  }, [getHasErrors, formErrors]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (errorMessage) {
      setErrorMessage("");
    }
    const newFormData = {
      ...formData,
      [name]: value
    };
    setFormData(newFormData);

    if (formErrors[name]) {
      const validationErrors = validateFormErrors(newFormData);
      setFormErrors(validationErrors);
    }
  };

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    const validationErrors = validateFormErrors(formData);
    if (getHasErrors(validationErrors)) {
      setFormErrors(validationErrors);
      return;
    }
    const { firstName, lastName, email, phone, roleId } = formData;
    const userBody = {
      firstName,
      lastName,
      email,
      phone: onlyNumericString(phone),
      roleId,
    };
    try {
      if (mode === "create") {
        const userResponse = await UserService.create(userBody);
        setEmailSentMessage(`A reset password email has been sent to ${email}`)
        onUserCreate(userResponse.payload);
        onClose();
      } else if (mode === "edit") {
        const userResponse = (
          await UserService.update(userBody, userToEdit.userId)
        );
        onUserUpdate(userResponse.payload);
        onClose();
      }
    } catch (error) {
      console.error(error)
      if (error?.response?.status === HTTP_STATUS_CONTENT) {
        setErrorMessage(emailErrorMsg);
      }
    }
  }, [
    formData, getHasErrors, mode, onClose, onUserCreate, onUserUpdate,
    setEmailSentMessage, userToEdit.userId, validateFormErrors
  ]);

  const formTitle = useMemo(() => {
    if (editFormType) {
      return "Edit My Profile";
    } else {
      return `${mode === "create" ? "Add" : "Edit"} User`;
    }
  }, [editFormType, mode]);

  return (
    <>
      <FormBanner>{formTitle}</FormBanner>
      <div className={classes.formContainer} data-cy="form-userMgmt">
        <Form name="user-info" className={classes.form}>
          <div className={classes.fieldsWrapper}>
            {/* FIRST NAME */}
            <div className={classes.formSection}>
              <LabelInput
                variant="default"
                label="First Name*"
                onChange={handleChange}
                value={formData.firstName}
                id="firstName"
                name="firstName"
                margin="dense"
                error={formErrors.firstName}
              />
            </div>
            {/* LAST NAME */}
            <div className={classes.formSection}>
              <LabelInput
                variant="default"
                label="Last Name*"
                onChange={handleChange}
                value={formData.lastName}
                id="lastName"
                name="lastName"
                margin="dense"
                error={formErrors.lastName}
              />
            </div>
            {/* EMAIL */}
            {mode === "create" ? (
              <div className={classes.formSection}>
                <LabelInput
                  variant="default"
                  label="Email*"
                  type="email"
                  onChange={handleChange}
                  value={formData.email}
                  id="email"
                  name="email"
                  margin="dense"
                  errorMessage={errorMessage}
                  error={formErrors.email || !!errorMessage}
                />
              </div>
            ) : (
              <div className={classes.editEmailWrapper}>
                <FormLabel label="Email" variant="default" />
                <div className={classes.editEmail}>{formData.email}</div>
              </div>
            )}
            {/* PHONE */}
            <div className={classes.formSection}>
              <LabelInput
                variant="default"
                label="Phone"
                type="tel"
                onChange={handleChange}
                value={formData.phone}
                error={formErrors.phone}
                id="phone"
                name="phone"
                margin="dense"
              />
            </div>
            {/* ROLE */}
            <div className={classes.formSection}>
              <CustomSelect
                label="Role*"
                value={formData.roleId}
                id="roleId"
                name="roleId"
                onChange={handleChange}
                readOnly={isRoleReadOnly}
                error={formErrors.roleId}
              >
                {availableRoles?.map?.((role) => (
                  <MenuItem value={role.roleId} key={role.roleId}>
                    {role.label}
                  </MenuItem>
                ))}
              </CustomSelect>
            </div>
          </div>
          {/* BUTTONS */}
          <DualFormButtons
            variant="smallButton"
            saveOnClick={handleSubmit}
            cancelOnClick={onClose}
            disabled={!!hasErrors && !!Object.keys(formData).length}
          />
        </Form>
      </div>
    </>
  );
};

export default UserMgmtForm;
