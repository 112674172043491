import authHeader from "services/util/authHeader.util";
import AxiosService from "services/base/Axios.service"
import { isDevelopmentEnv } from "core/environment";
import { stripQueryParams } from "utils/url.utils";

// For testing
const shouldLogAjaxCancels = false;

/*
 * TODO: Outstanding bugs:
 * - cancelUnfinishedRequests doesn't work for every other duplicate request.
 */

export default class AjaxService {

  get defaultConfig() {
    return { headers: authHeader() };
  }

  get defaultPreventDuplicateRequests() {
    return false;
  }

  get shouldExtractResponseData() {
    return false;
  }

  constructor() {
    /*
     * Format: {
     *   [normalized url without query params]: Set(AbortController)
     * }
     */
    this._abortControllerSetByUrl = new Set();
  }

  /* __________________________________________________________________________
   * Additional config options, not covered by Axios:
   * - cancelUnfinishedRequests: Cancel any prior pending requests to same url
   * - preventDuplicateRequests: Block requests to same url if prior is pending
   *   - Automatically done if `this.defaultPreventDuplicateRequests` is true
   */

  async get(url, config = {}) {
    return this._doRequest(AxiosService.get, url, config);
  }

  async post(url, data, config = {}) {
    return this._doRequest(AxiosService.post, url, config, data);
  }

  async put(url, data, config = {}) {
    return this._doRequest(AxiosService.put, url, config, data);
  }

  async delete(url, config = {}) {
    return this._doRequest(AxiosService.delete, url, config);
  }

  /* ________________________________________________________________________
   * "Cancellable" versions of the above methods.
   * These allow a custom AbortController to be passed to cancel requests
   *   from the caller Component.
   *
   *   The standard methods above will still auto-cancel requests via config.
   */

  async getCancellable(url, abortController, config = {}) {
    return this._doCancellableRequest(
      AxiosService.get, url, abortController, config
    );
  }

  async postCancellable(url, data, abortController, config = {}) {
    return this._doCancellableRequest(
      AxiosService.post, url, abortController, config, data
    );
  }

  async putCancellable(url, data, abortController, config = {}) {
    return this._doCancellableRequest(
      AxiosService.put, url, abortController, config, data
    );
  }

  async deleteCancellable(url, abortController, config = {}) {
    return this._doCancellableRequest(
      AxiosService.delete, url, abortController, config
    );
  }

  async cancel(abortController) {
    if (abortController) {
      abortController.abort();
      if (isDevelopmentEnv && shouldLogAjaxCancels) {
        console.debug(`Canceled ${this.constructor.name} request.`);
      }
    } else if (isDevelopmentEnv && shouldLogAjaxCancels) {
      console.debug("Request already finished");
    }
    this._cleanStalePendingRequests(abortController);
  }

  async cancelAll(normalizedUrl) {
    const controllers = this._abortControllerSetByUrl[normalizedUrl];
    if (controllers?.size) {
      if (isDevelopmentEnv && shouldLogAjaxCancels) {
        console.debug(
          `Cancelling all ${controllers.size} requests for "${normalizedUrl}".`
        );
      }
      Array.from(controllers).forEach(controller => (
        controller.abort()
      ));
      this._abortControllerSetByUrl[normalizedUrl] = new Set();
    }
  }

  _formatResponse(response) {
    if (this.shouldExtractResponseData) {
      return response.data
    }
    return response;
  }

  async _doRequest(axiosMethod, url, customConfig = {}, data = null) {
    const abortController = (
      customConfig.abortController || new AbortController()
    );
    const config = this._makeRequestConfig(abortController, customConfig);
    const normalizedUrl = stripQueryParams(url);
    const shouldContinueRequest = (
      this._beforeRequest(normalizedUrl, abortController, config)
    );
    if (shouldContinueRequest === false) {
      return { data: { message: "Duplicate Request" }};
    }
    const response = await (
      data ? axiosMethod(url, data, config) : axiosMethod(url, config)
    );
    this._cleanStalePendingRequests(abortController, normalizedUrl);
    return this._formatResponse(response);
  }

  async _doCancellableRequest(
    axiosMethod, url, abortController, config = {}, data = null
  ) {
    if (!abortController || !(abortController instanceof AbortController)) {
      throw new Error("Instance of AbortController must be passed.");
    }
    const requestConfig = { ...config, abortController };
    return this._doRequest(axiosMethod, url, requestConfig, data);
  }

  _beforeRequest(normalizedUrl, abortController, config) {
    const existingControllers = this._abortControllerSetByUrl[normalizedUrl];
    if (config.cancelUnfinishedRequests && existingControllers?.size) {
      this.cancelAll(normalizedUrl);
      return true;
    } else {
      const canPrevent = (
        config.preventDuplicateRequests ?? this.defaultPreventDuplicateRequests
      );
      if (canPrevent && existingControllers?.size) {
        if (isDevelopmentEnv && shouldLogAjaxCancels) {
          console.debug(`Prevented duplicate request for "${normalizedUrl}".`);
        }
        return false;
      }
    }
    if (!this._abortControllerSetByUrl[normalizedUrl]) {
      this._abortControllerSetByUrl[normalizedUrl] = new Set();
    }
    this._abortControllerSetByUrl[normalizedUrl].add(abortController);
    return true;
  }

  _cleanStalePendingRequests(abortController, normalizedUrl = null) {
    const urlToClean = (
      normalizedUrl ||
      Object.entries(this._abortControllerSetByUrl)
        .find(([_url, controllers]) => controllers.has(abortController))
        ?.[0]
    );
    if (this._abortControllerSetByUrl[urlToClean]) {
      this._abortControllerSetByUrl[urlToClean].delete(abortController);
    }
  }

  _makeRequestConfig(abortController, customConfig = {}) {
    const config = {
      ...this.defaultConfig,
      signal: abortController.signal,
      ...customConfig
    };
    delete config.abortController;
    return config;
  }
}
