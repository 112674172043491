import React, { useContext, useEffect, useRef, useState } from "react";
import Loader from "components/utils/loader.components";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import FileTransferPage from "components/pages/consentOrder/fileTransferSummary/fileTransferPage.component";
import ProgramsContext from "contexts/programs.context";
import useActiveConsentOrder from "hooks/useActiveConsentOrder";
import FileTransferService from "services/FileTransfer.service";


export default function FileTransferComponentObject(props) {
  const { state } = useContext(ProgramsContext);
  const { componentObjectId, programId } = useParams();
  const [activeConsentOrder] = useActiveConsentOrder();
  const fileTransferAjaxAbort = useRef();

  const [fileTransfers, setFileTransfers] = useState(null);
  const [uploadErrors, setUploadErrors] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    (async function fetchLibraryData() {
      fileTransferAjaxAbort.current = new AbortController();
      const [
        fileTransferResponse,
        uploadErrorResponse,
      ] = await Promise.all([
        FileTransferService.getFileTransfers(fileTransferAjaxAbort.current),
        FileTransferService.getUploadErrors()
      ]);
      if (fileTransferResponse.payload) {
        setFileTransfers(fileTransferResponse.payload);
      }
      if (uploadErrorResponse.payload) {
        setUploadErrors(uploadErrorResponse.payload);
      }
    })();
  }, [activeConsentOrder, refresh]);

  useEffect(() => (
    function cancelFileRequestsOnUnmount() {
      if (fileTransferAjaxAbort.current) {
        FileTransferService.cancel(fileTransferAjaxAbort.current);
      }
    }
  ), []);

  if (!fileTransfers || !uploadErrors) {
    return <Loader />;
  }
  return (
    <FileTransferPage
      fileTransfers={fileTransfers}
      setFileTransfers={setFileTransfers}
      uploadErrors={uploadErrors}
      setUploadErrors={setUploadErrors}
      tableTitle={props.tableTitle}
      componentObject={state.componentObjects[componentObjectId]}
      programId={programId}
      setRefresh={setRefresh}
    />
  );
}