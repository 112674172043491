import Alert from "@mui/material/Alert";
import { Collapse, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "mui-styles";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  alertInner: {
    marginTop: 0,
    marginBottom: 0,
  },
  denseIcon: {
    fontSize: 12,
    paddingTop: 4,
    paddingBottom: 4,
    "& .MuiAlert-icon": {
      fontSize: 18,
    }
  }
}));


const CollapsableAlert = (props) => {
  const { showAlert, closeClick, message, dense } = props; //required
  const { contentClass, severity } = props;
  const classes = useStyles()

  return (
    <Collapse in={!!showAlert}>
      <Alert severity={severity || "info"}
        className={classNames(classes.alertInner, contentClass)}
        action={closeClick ? (
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={closeClick}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        ) : null}
        classes={{ root: dense ? classes.denseIcon : undefined }}
      >
        {message}
      </Alert>
    </Collapse>
  )
}

export default CollapsableAlert;
