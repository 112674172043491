import AxiosService from "services/base/Axios.service";
import { HTTP_STATUS_INTERNAL_ERROR, HTTP_STATUS_NOT_AUTHORIZED, HTTP_STATUS_OK } from "services/constants/response.constants";
import StorageService from "services/Storage.service";
import authHeader from "services/util/authHeader.util";
import { currentUserInfo, currentUserToken, setRefreshToken, setUserInfo } from "services/util/authSession.utils";


class AuthServiceBase {
  get defaultConfig() {
    return {};
  }

  async login(email, password) {
    const response = await AxiosService.post("/auth/signIn", { email, password });

    setUserInfo(response.data.accessToken);
    setRefreshToken(response.data.refreshToken);

    return response;
  }

  logout() {
    StorageService.clearSession();
  }

  async forgot(email) {
    return AxiosService.post("/auth/forgot", { email });
  }

  async create(user) {
    const response = await AxiosService.post(`/auth/createUser`, user);
    return response.data;
  }

  async updateUser(data) {
    const response = await AxiosService.put(
      `/auth/user`,
      data,
      { headers: authHeader() }
    );
    setUserInfo(response.data.payload.accessToken);
    setRefreshToken(response.data.payload.refreshToken);
    return response.data;
  }

  async updatePassword(userId, password) {
    return AxiosService.put(
      `/auth/user/${userId}/password`,
      { password },
      { headers: authHeader() }
    ).then(response => response);
  }

  async resetPassword(userId, key, password) {
    return AxiosService
      .put(`/auth/user/${userId}/password/key/${key}/reset`, { password })
      .then(response => response);
  }

  async verifyEmail(userId, key) {
    return AxiosService
      .get(`/auth/user/${userId}/key/${key}/verify`)
      .then(response => response);
  }

  async isUserLoggedIn() {
    try {
      const accessToken = currentUserToken();
      if ((typeof accessToken === "object") || !accessToken) {
        return false;
      }
      const response = await AxiosService.post(
        "auth/isUserLoggedIn", { accessToken }, { headers: authHeader() }
      );
      return response.status === HTTP_STATUS_OK;
    } catch (error) {
      return error?.status === HTTP_STATUS_NOT_AUTHORIZED;
    }
  }

  async setupMFA() {
    const user = currentUserInfo();
    return AxiosService
      .post(`/auth/setup-mfa`, { user })
      .then(response => response);
  }

  async verifyMFA(token) {
    const user = currentUserInfo();
    const response = await AxiosService.post(`/auth/verify-mfa`, { user, token }, {
      validateStatus(status) {
        // Resolve only if the status code is less than 500
        return status < HTTP_STATUS_INTERNAL_ERROR;
      }
    });

    if (response.status === HTTP_STATUS_OK) {
      setUserInfo(response.data.data.accessToken);
      setRefreshToken(response.data.data.refreshToken);
    }
    return response;
  }

  checkPermissions(requiredRolesList) {
    if (sessionStorage.getItem("accessToken")) {
      const user = currentUserInfo();
      return requiredRolesList.includes(user.role)
    } else {
      return false
    }
  }

  async refreshToken(refreshToken) {
    if (!refreshToken) {
      return this.logout();
    } else {
      const response = await AxiosService.post(`auth/refreshToken`, { refreshToken });
      if (response.status === HTTP_STATUS_OK) {
        const { data: { data }} = response;
        setRefreshToken(data.updatedRefreshToken);
        setUserInfo(data.updatedAccessToken);
        return data;
      } else {
        return this.logout();
      }
    }
  }

  Type = {
    ADMIN: "9fb432a7-562d-45ab-9b28-f4acd138193a",
    READONLY: "8e7bfb4f-b6a5-4b90-84f2-0bc7e6d67b3f",
    EDIT: "4221f1df-9a5e-475e-a034-afa2413a9794",
    NO_ACCESS: null
  };
}

const AuthService = new AuthServiceBase();
export default AuthService;
