import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { filterClaimsByNodeIdSet, groupClaimsByInstanceNodeIds } from "components/claims/utils/claimWorkflow.utils";
import { WORKFLOW_NODE_ID_ENGAGE_CLAIMANT, WORKFLOW_NODE_ID_FIRST_REVIEW, WORKFLOW_NODE_ID_SECOND_REVIEW, WORKFLOW_NODE_ID_UPLOAD_MAILED_DOCS, WORKFLOW_TEMPLATE_ID_ADJUDICATION, WORKFLOW_TEMPLATE_ID_CLAIMS_PAYMENT } from "components/utils/workflows/workflow.constants";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import ReviewRadioCard from "components/pages/consentOrder/claims/reviewTracking/ReviewRadioCard.component";
import SearchResultsWrapper from "components/pages/consentOrder/claims/shared/SearchResultsWrapper.component";
import ClaimsTable from "components/claims/ClaimsTable.component";
import ClaimService from "services/Claim.service";
import { makeStyles } from "mui-styles";
import { H3 } from "components/utils/headerV2.component";
import classNames from "classnames";
import SubpageSearchbarHeader from "components/pages/consentOrder/claims/shared/SubpageSearchbarHeader.component";
import ScrollTargetContainer from "components/shared/scroll/ScrollTargetContainer.component";

const useStyles = makeStyles(theme => ({
  radiosDisabled: {
    opacity: theme.opacity.disabled
  },
  radiosNoSearch: {
    display: "none"
  },
}));

const CLAIMS_INCOMPLETE_SET = new Set([
  WORKFLOW_NODE_ID_FIRST_REVIEW,
  WORKFLOW_NODE_ID_ENGAGE_CLAIMANT,
  WORKFLOW_NODE_ID_UPLOAD_MAILED_DOCS,
]);


const FILTER_SLUG_INCOMPLETE = "incomplete";
const FILTER_SLUG_COMPLETE = "complete";

const DEFAULT_FILTER_PRIORITY = [
  FILTER_SLUG_INCOMPLETE,
  FILTER_SLUG_COMPLETE,
  WORKFLOW_NODE_ID_FIRST_REVIEW,
  WORKFLOW_NODE_ID_ENGAGE_CLAIMANT,
  WORKFLOW_NODE_ID_UPLOAD_MAILED_DOCS,
];

export default function ReviewTrackingPage(props) {
  const { state } = props;
  const classes = useStyles();
  const searchAbortRef = useRef();
  const reviewTableRef = useRef();

  const [activeFilterSlug, setActiveFilterSlug] = useState(
    FILTER_SLUG_INCOMPLETE
  );
  const [hasSearchError, setHasSearchError] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [claimResults, setClaimResults] = useState(null);

  const activeClaimsByNodeId = useMemo(() => (
    groupClaimsByInstanceNodeIds(claimResults || [])
  ), [claimResults]);

  const incompleteClaims = useMemo(() => (
    filterClaimsByNodeIdSet(claimResults || [], CLAIMS_INCOMPLETE_SET)
  ), [claimResults]);

  const completeClaims = useMemo(() => (
    !claimResults ? [] : claimResults.filter(claim => (
      claim._associations.WFInstance?.some(instance => (
        instance.status === "Active" && (
          instance.currentNodeId === WORKFLOW_NODE_ID_SECOND_REVIEW ||
          instance.wfTemplateId === WORKFLOW_TEMPLATE_ID_ADJUDICATION ||
          instance.wfTemplateId === WORKFLOW_TEMPLATE_ID_CLAIMS_PAYMENT
        )
      ))
    ))
  ), [claimResults]);

  const activeClaimsByFilterSlug = useMemo(() => ({
    ...activeClaimsByNodeId,
    [FILTER_SLUG_COMPLETE]: completeClaims,
    [FILTER_SLUG_INCOMPLETE]: incompleteClaims,
  }), [activeClaimsByNodeId, completeClaims, incompleteClaims]);

  const handleFindClaims = useCallback(async () => {
    try {
      setIsSearchLoading(true);
      searchAbortRef.current = new AbortController();
      const results = await ClaimService.getClaimsBySearch(
        searchAbortRef.current,
        searchQuery
      );
      if (results.payload) {
        setHasSearchError(false);
        setClaimResults(results.payload);
        if (reviewTableRef.current && results.payload?.length) {
          reviewTableRef.current.scrollIntoView();
        }
      }
    } catch (error) {
      setHasSearchError(true);
      console.error(error);
    } finally {
      setIsSearchLoading(false);
    }
  }, [searchQuery]);

  useEffect(function selectDefaultActiveFilter() {
    if (
      activeClaimsByFilterSlug &&
      !activeClaimsByFilterSlug[activeFilterSlug]?.length
    ) {
      const filterWithClaims = DEFAULT_FILTER_PRIORITY.find(filterSlug => (
        activeClaimsByFilterSlug[filterSlug]?.length
      ));
      if (filterWithClaims) {
        setActiveFilterSlug(filterWithClaims);
      }
    }
  }, [activeClaimsByFilterSlug, activeFilterSlug, claimResults]);

  useEffect(() => (
    function cancelSearchOnUnmount() {
      ClaimService.cancel(searchAbortRef.current);
    }
  ), []);

  return (
    <div>
      <SubpageSearchbarHeader
        title="Review Tracking"
        formName="review-tracking-search"
        placeholder="Search by CIF ID, Loan Number, Claimant Name or Email"
        disabled={searchQuery?.length < 2}
        onChange={event => setSearchQuery(event.target.value)}
        onSubmit={handleFindClaims}
      />
      <ScrollTargetContainer scrollTargetRef={reviewTableRef}>
        <div className={classNames(!claimResults && classes.radiosNoSearch)}>
          <H3
            color={claimResults?.length ? "primary" : "tertiary"}
            className={
              classNames(!claimResults?.length && classes.radiosDisabled)
            }
            withMargin
          >
            Select Review Stage
          </H3>
          <GridContainer
            spacing={2}
            columns={30}
            paddingBottom={4}
            hasVerticalSpacing
          >
            <GridItem xs={30} md={15} lg={10} xl={6}>
              <ReviewRadioCard
                label="Waiting for First Line Review"
                value={WORKFLOW_NODE_ID_FIRST_REVIEW}
                activeFilter={activeFilterSlug}
                setActiveFilter={setActiveFilterSlug}
                claims={
                  activeClaimsByNodeId?.[WORKFLOW_NODE_ID_FIRST_REVIEW]
                }
              >
                Entered review process,
                currently under initial automated review.
              </ReviewRadioCard>
            </GridItem>
            <GridItem xs={30} md={15} lg={10} xl={6}>
              <ReviewRadioCard
                label="Claimant engagement in progress"
                value={WORKFLOW_NODE_ID_ENGAGE_CLAIMANT}
                activeFilter={activeFilterSlug}
                setActiveFilter={setActiveFilterSlug}
                claims={
                  activeClaimsByNodeId?.[WORKFLOW_NODE_ID_ENGAGE_CLAIMANT]
                }
              >
                Missing data from Claimant which is
                required before Second Line Review.
              </ReviewRadioCard>
            </GridItem>
            <GridItem xs={30} md={15} lg={10} xl={6}>
              <ReviewRadioCard
                label="Pending documents"
                value={WORKFLOW_NODE_ID_UPLOAD_MAILED_DOCS}
                activeFilter={activeFilterSlug}
                setActiveFilter={setActiveFilterSlug}
                claims={
                  activeClaimsByNodeId?.[WORKFLOW_NODE_ID_UPLOAD_MAILED_DOCS]
                }
              >
                Waiting for Mailed Documents to be uploaded
                before Second Line Review can begin.
              </ReviewRadioCard>
            </GridItem>
            <GridItem xs={30} md={15} lg={10} xl={6}>
              <ReviewRadioCard
                label="All incomplete Claims"
                value={FILTER_SLUG_INCOMPLETE}
                activeFilter={activeFilterSlug}
                setActiveFilter={setActiveFilterSlug}
                claims={incompleteClaims}
              >
                Claims found to have missing or incomplete data
                upon First Line Review.
              </ReviewRadioCard>
            </GridItem>
            <GridItem xs={30} md={15} lg={10} xl={6}>
              <ReviewRadioCard
                label="Complete Claims"
                value={FILTER_SLUG_COMPLETE}
                activeFilter={activeFilterSlug}
                setActiveFilter={setActiveFilterSlug}
                claims={completeClaims}
              >
                Successfully passed through First Line Review.
              </ReviewRadioCard>
            </GridItem>
          </GridContainer>
        </div>

        <SearchResultsWrapper
          error={hasSearchError}
          loading={isSearchLoading}
          results={claimResults}
        >
          <ClaimsTable
            claims={activeClaimsByFilterSlug[activeFilterSlug]}
            state={state}
            size="small"
            key={activeFilterSlug}
            fullPage
          />
        </SearchResultsWrapper>
      </ScrollTargetContainer>
    </div>
  );
}
