import useNumericParams from "hooks/useNumericParams";
import { PROGRAM_TYPE_CONSENT_ORDERS } from "components/constants/program.constants";
import { useContext } from "react";
import ProgramsContext from "contexts/programs.context";
import WorkflowTemplateContextProvider from "components/layout/context/contextProviders/programProviders/workflowTemplateContextProvider.component";
import StatisticsContextProvider from "components/layout/context/contextProviders/programProviders/statisticsContextProvider.component";
import Loader from "components/utils/loader.components";
import useActiveConsentOrder from "hooks/useActiveConsentOrder";

/*
 * Context Providers for specific Programs.
 * Use for data shared across all ProgramComponents of a Program.
 */
export default function InnerProgramContextProviders({ children }) {
  const { state } = useContext(ProgramsContext);
  const { programId } = useNumericParams(true);
  const [activeConsentOrderId] = useActiveConsentOrder();

  switch (state.programs?.[programId]?.type) {
    case PROGRAM_TYPE_CONSENT_ORDERS:
      if (!activeConsentOrderId) {
        return (
          <Loader />
        );
      }
      return (
        <WorkflowTemplateContextProvider>
          <StatisticsContextProvider>
            {children}
          </StatisticsContextProvider>
        </WorkflowTemplateContextProvider>
      );
    case null:
    default:
      return children;
  }
}
