import CheckboxGroup from "components/utils/form-elements/checkboxGroup.component";
import CheckboxV2 from "components/utils/form-elements/checkboxV2.component";
import { useState } from "react";
import AccordionView from "components/utils/accordionView.component";
import { makeStyles } from "mui-styles";
import { H5 } from "components/utils/headerV2.component";
import FilterSvg from "assets/icons/Filter.svg";

const useStyles = makeStyles(theme => ({
  accordionSummary: {
    display: "flex",
    justifyContent: "center",
    padding: 0,
  },
  accordionSummaryContent: {
    display: "block",
    flex: "0 0 auto",
    paddingRight: 16,
    paddingLeft: 32,
    marginLeft: -32,
    fontWeight: theme.typography.body2.fontWeight,
    textAlign: "right"
  },
  accordionDetail: {
    textAlign: "center"

  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  checkboxItem: {
    display: "flex",
    justifyContent: "flex-start",

  },
  activeSublabel: {
    color: theme.palette.secondary.main
  },
  filterMainLabelIcon: {
    position: "relative",
    "&:before": {
      content: "''",
      position: "absolute",
      top: 8,
      left: -40,
      height: 24,
      width: 24,
      backgroundImage: "url(" + FilterSvg + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      fill: theme.palette.text.primary,
    }
  }
}));


export default function PopulationFilterCheckboxes(props) {
  const classes = useStyles();
  const { populationFilters, populations, onChange } = props;

  const [accordionExpanded, setAccordionExpanded] = useState(false);


  if (!populations) {
    return null;
  }
  return (
    <AccordionView
      mainLabel={(
        <div className={classes.filterMainLabelIcon}>
          <H5 color="tertiary">Filter by Population</H5>
        </div>
      )}
      subLabel={
        populationFilters.size ? (
          <div className={classes.activeSublabel}>
            {populationFilters.size || 0} selected
          </div>
        ) : (
          "No filters applied"
        )
      }
      classes={{
        summary: classes.accordionSummary,
        content: classes.accordionSummaryContent,
        detail: classes.accordionDetail
      }}
      expanded={accordionExpanded}
      onChange={() => setAccordionExpanded(prevExpanded => !prevExpanded)}
      dense
      noHoverHighlight
    >
      <CheckboxGroup
        name="populations"
        label="Populations"
      >
        <div
          className={classes.checkboxContainer}
        >
          {populations.map(population => (
            <div
              className={classes.checkboxItem}
              key={population}
            >
              <CheckboxV2
                name={population}
                label={`Population ${population}`}
                checked={populationFilters.has(population)}
                readOnly={false}
                onChange={onChange}
              />
            </div>
          ))}
        </div>
      </CheckboxGroup>
    </AccordionView>
  )
}