
import { DATE_FORMAT_DISPLAY_TEXT_LONG } from "constants/date.constants";
import moment from "moment";
import { groupObjectArrayByKey, mapObjectArrayByKey } from "utils/arrayOfObjects.utils";

export const findHighestPriorityEvent = flowEvents => (
  flowEvents.reduce((highest, flowEvent) => {
    if (!flowEvent.priority) {
      return highest;
    }
    if (!highest) {
      return flowEvent;
    }
    return flowEvent.priority < highest.priority ? flowEvent : highest;
  }, null)
);

const findMostRecentNodeTrailItem = (auditTrail) => {
  if (!auditTrail.length) {
    return null;
  }
  let mostRecentTrailItem;
  let latestStartDate;
  for (const trailItem of auditTrail) {
    if (
      !mostRecentTrailItem ||
      moment(trailItem.startDatetime).isAfter(latestStartDate)
    ) {
      mostRecentTrailItem = trailItem;
      latestStartDate = trailItem.startDatetime;
    }
  }
  return mostRecentTrailItem;
};

const isNodeSkipped = (diagramItems, index, isCurrentOrphaned) => {
  const latestNode = diagramItems.find((item) => (item.isLatestNode))
  const latestNodeIndex = diagramItems.indexOf(latestNode);
  const currentNodeHasAuditTrail = diagramItems[index].auditTrail?.length > 0

  return (((index < latestNodeIndex) &&
    !currentNodeHasAuditTrail) ||
    isCurrentOrphaned)
};

const findNodeStatus = (auditTrail, latestAuditTrailItem, endDateOnly) => {
  if (!auditTrail.length || !latestAuditTrailItem) {
    return null;
  }
  const start = latestAuditTrailItem.startDatetime
    ? moment(latestAuditTrailItem.startDatetime).format(
      DATE_FORMAT_DISPLAY_TEXT_LONG
    )
    : null;
  const end = latestAuditTrailItem.endDatetime
    ? moment(latestAuditTrailItem.endDatetime).format(
      DATE_FORMAT_DISPLAY_TEXT_LONG
    )
    : null;

  if (endDateOnly) {
    return end;
  }

  return end ? `Completed: ${end}` : `Started: ${start}`;
};

export function buildEventChain(workflowTemplate) {
  const flowEvents = workflowTemplate._associations.WFEventFlow.filter(flow => (
    workflowTemplate.startNodeId === flow.referenceNodeId
  ));
  const firstEvent = findHighestPriorityEvent(flowEvents)
  if (!firstEvent) {
    return [];
  }
  return buildEventChainFromPoint(workflowTemplate, firstEvent);
}

export function buildEventChainFromPoint(workflowTemplate, startEvent) {
  const eventsByReferenceNodeId = groupObjectArrayByKey(
    workflowTemplate._associations.WFEventFlow,
    "referenceNodeId"
  );
  return recursiveAddNextChainEvent(eventsByReferenceNodeId, startEvent);
}

const EVENT_RECURSION_MAX = 100;

function recursiveAddNextChainEvent(
  eventsByReferenceNodeId,
  currentEvent,
  accumulatedEvents = []
) {
  const accumulatedChain = [...accumulatedEvents, currentEvent];
  if (accumulatedEvents.length > EVENT_RECURSION_MAX) {
    console.error("Attempted event chain:\n", accumulatedChain);
    throw new Error("Max events reached, infinite loop found.");
  }
  if (currentEvent?.targetNodeId) {
    const nextEvents = eventsByReferenceNodeId[currentEvent.targetNodeId];
    if (nextEvents) {
      const targetEvent = findHighestPriorityEvent(nextEvents);
      return recursiveAddNextChainEvent(
        eventsByReferenceNodeId,
        targetEvent,
        accumulatedChain
      );
    }
  }
  return accumulatedChain;
}

export function getNodesFromEventChain(eventChain) {
  if (!eventChain) {
    return [];
  }
  const referenceNodes = eventChain.map(flowEvent => (
    flowEvent?._associations?.WFReferenceNode
  ));
  const lastEvent = eventChain[eventChain.length - 1];
  if (!lastEvent) {
    return [];
  }
  return referenceNodes.concat(lastEvent._associations?.WFTargetNode);
}

export const makeWorkflowTimeline = (nodeChain, workflowInstance) => {
  let wasLatestNodeProcessed = false
  const diagramItems = nodeChain.map((node) => {
    const nodeAuditTrail = (
      workflowInstance?._associations?.WFAuditTrail?.filter?.(
        (trailItem) => trailItem.wfNodeId === node.wfNodeId
      )
    ) || [];

    const isLatestNode = workflowInstance?.currentNodeId === node.wfNodeId
    if (!wasLatestNodeProcessed && isLatestNode) {
      wasLatestNodeProcessed = true
    }

    return {
      wfNodeId: node.wfNodeId,
      isLatestNode,
      label: node.nodeName,
      instanceData: workflowInstance,
      auditTrail: nodeAuditTrail,
      latestAuditTrailItem: (!wasLatestNodeProcessed || isLatestNode) ?
        findMostRecentNodeTrailItem(nodeAuditTrail) :
        null,
    };
  });

  const isCurrentNodeOrphaned = !wasLatestNodeProcessed;

  const workflowDiagramItems = diagramItems.map((item, index, _all) => {
    return {
      ...item,
      isCurrentNode: (
        item.isLatestNode
        && (
          // This was in the original code but was taken out to account for
          // last nodes that have been "completed"
          // (index === all.length - 1) ||
          item.latestAuditTrailItem?.status === "Active"
        )
      ),
      isSkipped: isNodeSkipped(diagramItems, index, isCurrentNodeOrphaned),
      status: findNodeStatus(item.auditTrail, item.latestAuditTrailItem)
    };
  });
  return workflowDiagramItems;
}


export const makeDisconnectedItems = (nodeOrphanSet, workflowInstance) => {
  const nodeOrphanList = Array.from(nodeOrphanSet)
  let processedLatestNode = false

  const orphanDiagramList = [];

  for (const orphanNode of nodeOrphanList) {
    const orphanNodeAuditTrail =
      workflowInstance?._associations?.WFAuditTrail?.filter?.(
        (trailItem) => trailItem.wfNodeId === orphanNode.wfNodeId
      ) || [];
    const isLatestNode =
      workflowInstance?.currentNodeId === orphanNode.wfNodeId;
    if (!processedLatestNode && isLatestNode) {
      processedLatestNode = true;
    }
    if (orphanNodeAuditTrail.length) {
      orphanDiagramList.push({
        wfNodeId: orphanNode.wfNodeId,
        isLatestNode,
        label: orphanNode.nodeName,
        auditTrail: orphanNodeAuditTrail,
        latestAuditTrailItem:
          !processedLatestNode || isLatestNode
            ? findMostRecentNodeTrailItem(orphanNodeAuditTrail)
            : null,
      });
    }
  }

  const disconnectedItems = orphanDiagramList.map((item) => {
    return {
      ...item,
      isCurrentNode: !!item.isLatestNode && !!item.latestAuditTrailItem,
      status: findNodeStatus(item.auditTrail, item.latestAuditTrailItem, true),
    };
  });
  return disconnectedItems;
}

export function makeWorkflowScriptScene(
  workflowScript, workflowTimeline, disconnectedItems = []
) {
  const workflowDiagramItemsById = mapObjectArrayByKey(
    [...workflowTimeline, ...disconnectedItems],
    "wfNodeId"
  );
  return Object.fromEntries(
    Object.entries(workflowScript).map(([name, data]) => {
      const nodeIds = [].concat(data.nodeId);
      const isVisible = nodeIds.some(nodeId => {
        const item = workflowDiagramItemsById[nodeId];
        return item && (
          (data.enabledSkipped && !!item.isSkipped) ||
          (data.enabledCurrent && !!item.isCurrentNode) ||
          (data.enabledCompleted && !item.isCurrentNode && (
            !!item.latestAuditTrailItem
          ))
        );
      });
      return [name, isVisible];
    })
  );
}
