import { Box } from "@mui/material";
import ContactLogTable from "components/claims/contactLog/contactLogTable.component";
import ButtonDefault from "components/utils/buttonDefault.component";
import IconForButton from "components/utils/buttons/iconForButton.component";
import { makeDatetimeCell } from "components/utils/tables/utils/cell.utils";
import {
  createNodeCellParams,
  createNodeHeaderParams,
  createValueHeaderParams,
} from "components/utils/tables/utils/dataTable.utils";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { useCallback, useMemo, useState } from "react";
import CustomModal from "components/utils/modal.component";
import ContactLogForm from "components/claims/contactLog/contactLogForm.component";
import { isReadOnly } from "utils/roles.utils";
import TableCellClaim from "components/claims/table/TableCellClaim.component";
import { generatePath, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { PATH_COMPONENT_OBJECT } from "components/layout/constants/standardPaths.constants";

const defaultHeaders = [
  createNodeHeaderParams("contactDatetime", "Date", {
    align: "left",
    filter: false,
  }),
  createValueHeaderParams("action", "Action", {
    align: "left",
    filter: false,
  }),
  createValueHeaderParams("notes", "Notes", {
    align: "left",
    filter: false,
    sort: false,
  }),
];

const claimHeader = createNodeHeaderParams("claim", "Claim", {
  filter: false,
});

/**
 * This table has 2 main versions of display
 * 1. Contact Logs scoped to 1 claim - editable
 * 2. Contact Logs by Customer (multiple claims) - not editable
 */

const ContactLog = (props) => {
  const { contactLog, claim, dispatch, customerClaims } = props;
  const params = useParams();

  const [initialFormState, setInitialFormState] = useState(null);

  const isScopedByClaim = !!claim;

  const readOnly = useMemo(isReadOnly, []);

  const isEditable = !readOnly && !!isScopedByClaim;

  const claimsReviewPath = useMemo(() => (
    generatePath(PATH_COMPONENT_OBJECT, {
      ...params,
      componentObjectId: "claims-review-component-object"
    })
  ), [params]);

  const openEditFormClick = useCallback((editData) => {
    setInitialFormState(editData);
  }, []);

  const headers = useMemo(() => {
    const headerList = [...defaultHeaders];
    if (!isScopedByClaim) {
      headerList.splice(3, 0, claimHeader)
    }
    return headerList;
  }, [isScopedByClaim]);

  const tableCells = useMemo(() => {
    if (!contactLog) {
      return null;
    }
    return contactLog.map((contactLogEntry) => {
      const row = {
        contactDatetime: makeDatetimeCell(
          contactLogEntry.contactDatetime,
          null,
          isEditable ? () => openEditFormClick(contactLogEntry) : null
        ),
        action: contactLogEntry.action,
        notes: contactLogEntry.notes,
      };
      if (!isScopedByClaim) {
        const associatedClaim = customerClaims.find((customerClaim) => {
          return contactLogEntry.claimId === customerClaim.claimId;
        });
        const claimData = associatedClaim._derivedData.ClaimData;
        row.claim = createNodeCellParams(
          claimData.cifId,
          claimData.cifId,
          <TableCellClaim
            claims={[associatedClaim]}
            componentObjectPath={claimsReviewPath}
          />
        );
      }
      return row;
    });
  }, [
    contactLog,
    openEditFormClick,
    isEditable,
    isScopedByClaim,
    customerClaims,
    claimsReviewPath,
  ]);

  return (
    <Box display="flex" flexDirection="column">
      <ContactLogTable columns={headers} data={tableCells || []} />
      {!!isEditable && (
        <Box alignSelf="end" marginTop={1}>
          <ButtonDefault
            variant="small"
            startIcon={<IconForButton MuiIcon={AddCircleOutlineOutlined} />}
            onClick={() => setInitialFormState({})}
          >
            Add Log Entry
          </ButtonDefault>
        </Box>
      )}
      {!!isEditable && (
        <CustomModal
          open={!!initialFormState}
          onClose={() => setInitialFormState(null)}
        >
          <ContactLogForm
            initialFormState={initialFormState}
            onClose={() => setInitialFormState(null)}
            claimId={claim.claimId}
            claim={claim}
            dispatch={dispatch}
          />
        </CustomModal>
      )}
    </Box>
  );
};
export default ContactLog;
