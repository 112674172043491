import { FormControl } from "@mui/material";
import DeleteIconButton from "components/utils/deleteIconButton.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import TitledMultiFileUploadInput from "components/utils/form-elements/titledMultiFileUploadInput.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import { isDevelopmentEnv } from "core/environment";
import { useCallback, useMemo, useRef, useState } from "react";
import { HTTP_STATUS_CONTENT } from "services/constants/response.constants";
import FileTransferService from "services/FileTransfer.service";
import { downloadFileBlob } from "utils/download.utils";
const { makeStyles } = require("mui-styles");


const useStyles = makeStyles((theme) => ({
  itemFieldset: {
    width: "100%",
    padding: "8px 0 8px 8px",
  },
  itemNumber: {
    width: 16,
    marginRight: 8,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: "bold"
  },
  itemDelete: {
    height: 32,
    marginTop: 24,
    marginLeft: 24
  },
  labelInput: {
    width: 200,
    [theme.breakpoints.down("lg")]: {
      maxWidth: 160
    }
  },
  tagNameInput: {
    fontWeight: theme.typography.fontWeightBold,
    whiteSpace: "nowrap"
  },
  multiFileUpload: {
    paddingLeft: 8
  },
  verticalDividers: {
    height: "100%",
    marginTop: 8,
    marginLeft: 32,
    paddingTop: 16,
    paddingLeft: 32,
    paddingBottom: 16,
    borderLeft: `1px solid ${theme.palette.border.main}`,
    borderRight: `1px solid ${theme.palette.border.main}`,
  },
}));

const FIELD_NAME_DELIMITER = "|";


// Not really stacked, just an alternate version of KeyValueListItem
export default function KeyValueListItemStacked(props) {
  const classes = useStyles();
  const valueInputRef = useRef();
  const {
    index, item, onChange, onDelete, onFileDelete, onFileUndoDelete, primaryKey
  } = props;

  const [deletedAttachments, setDeletedAttachments] = useState([]);

  const clientId = useMemo(() => (
    item?.[primaryKey] || item?._meta?.clientId
  ), [item, primaryKey]);

  const fieldNamePrefix = useMemo(() => (
    item?.[primaryKey] ?
      `${item[primaryKey]}-savedItem` :
      item._meta?.clientId
  ), [item, primaryKey]);

  const uploadedAttachments = useMemo(() => (
    item?._associations?.FileTransferAttachment || []
  ), [item?._associations?.FileTransferAttachment]);

  const handleDeleteAttachment = useCallback(attachment => {
    setDeletedAttachments(prev => {
      return [...prev, attachment]
    });
    onFileDelete?.(attachment);
  }, [onFileDelete, setDeletedAttachments]);

  const handleUndoDeleteAttachment = useCallback(attachment => {
    setDeletedAttachments(prev => {
      const next = [...prev];
      const deleteIndex = next.findIndex(deletedAttachment => (
        deletedAttachment.name === attachment.name
      ));
      next.splice(deleteIndex, 1);
      return next;
    });
    onFileUndoDelete?.(attachment);
  }, [onFileUndoDelete, setDeletedAttachments]);

  const handleDownloadAttachment = useCallback(async file => {
    try {
      const blob = await FileTransferService.downloadFileTransferAttachment(
        file.fileTransferAttachmentId
      )
      return downloadFileBlob(blob, file.fileName);
    } catch (error) {
      if (isDevelopmentEnv && error?.response?.status === HTTP_STATUS_CONTENT) {
        alert(
          "DEVELOPMENT ERROR: File missing from shared cloud storage. " +
          "This file was likely deleted in another developer's local instance."
        );
      }
    }
  }, []);

  const getItemFieldName = useCallback(columnName => {
    return `${fieldNamePrefix}${FIELD_NAME_DELIMITER}${columnName}`;
  }, [fieldNamePrefix]);

  const handleLabelKeyDown = useCallback(event => {
    if (event.key === "Enter") {
      valueInputRef.current.focus();
      event.preventDefault();
    }
  }, []);

  const handleDeleteItem = useCallback(() => {
    onDelete(clientId)
  }, [clientId, onDelete]);

  const itemOrder = index + 1;

  return (
    <FormControl
      component="fieldset"
      name={`item-${clientId}`}
      className={classes.itemFieldset}
      onChange={onChange}
    >
      <GridContainer
        spacing={2}
        alignItems="center"
        flexWrap="nowrap"
        marginTop={2}
      >
        <GridItem>
          <div className={classes.itemNumber}>{itemOrder}.</div>
        </GridItem>
        <GridItem>
          <GridContainer
            spacing={2}
            alignItems="center"
            flexWrap="nowrap"
            marginTop={2}
          >
            <GridItem xs={12} xl={6}>
              <LabelInput
                name={getItemFieldName("label")}
                label="Tag Name"
                placeholder="Label"
                defaultValue={item?.label}
                className={classes.labelInput}
                onKeyDown={handleLabelKeyDown}
                inputProps={{
                  className: classes.tagNameInput
                }}
                test={`${item?.[primaryKey]}-label`}
                margin="dense"
                variant="default"
                hideHelperText
                required
              />
            </GridItem>
            <GridItem xs={12} xl={6}>
              <LabelInput
                name={getItemFieldName("value")}
                label="Value"
                placeholder="Value"
                defaultValue={item?.value}
                margin="dense"
                variant="default"
                className={classes.labelInput}
                test={`${item?.[primaryKey]}-value`}
                inputRef={valueInputRef}
                hideHelperText
                multiline
              />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem
          flex="1 1 auto"
          overflow="hidden"
          className={classes.verticalDividers}
        >
          <TitledMultiFileUploadInput
            name="attachments"
            label="Attachments"
            fileNamePrefix={`${index}${FIELD_NAME_DELIMITER}`}
            className={classes.multiFileUpload}
            deletedFiles={deletedAttachments}
            uploadedFiles={uploadedAttachments}
            onDelete={handleDeleteAttachment}
            onDeleteUndo={handleUndoDeleteAttachment}
            onDownloadFile={handleDownloadAttachment}
            dense
          >
            Add Attachments
          </TitledMultiFileUploadInput>
        </GridItem>
        <GridItem>
          <DeleteIconButton
            className={classes.itemDelete}
            onClick={handleDeleteItem}
            test={`order-${itemOrder}`}
            center
          />
        </GridItem>
      </GridContainer>
      <input
        type="hidden"
        name={getItemFieldName(primaryKey)}
        value={item?.[primaryKey]}
      />
      <input
        type="hidden"
        name={getItemFieldName("displayOrder")}
        value={item?.displayOrder}
      />
    </FormControl>
  );
}
