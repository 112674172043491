
import AuthenticatedPageRoutes from "components/layout/routing/AuthenticatedPageRoutes.component";
import { Box, CssBaseline } from "@mui/material";
import { Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import PublicRoutes from "components/layout/routing/PublicRoutes.component";
import { STANDARD_PATHS_ALL } from "components/layout/constants/standardPaths.constants";

const history = createBrowserHistory();

export default function AppRouter() {
  return (
    <Router history={history}>
      <div>
        <Box display="flex" minHeight="100vh">
          <CssBaseline />
          <Switch>
            <Route path={STANDARD_PATHS_ALL}>
              <AuthenticatedPageRoutes />
            </Route>
            <Route>
              <PublicRoutes />
            </Route>
          </Switch>
        </Box>
      </div>
    </Router>
  );
}
