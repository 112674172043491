import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Box, Divider } from "@mui/material";
import WorkflowNodeSearchFilter from "components/pages/consentOrder/claims/shared/WorkflowNodeSearchFilter.component";
import ClaimService from "services/Claim.service";
import PopulationRadioCard from "components/pages/consentOrder/claims/population/PopulationRadioCard.component";
import ClaimsTable from "components/claims/ClaimsTable.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import { H3, H5 } from "components/utils/headerV2.component";
import SearchResultsWrapper from "components/pages/consentOrder/claims/shared/SearchResultsWrapper.component";
import classNames from "classnames";
import { makeStyles } from "mui-styles";
import SubpageHeader from "components/pages/consentOrder/claims/shared/SubpageHeader.component";
import ScrollTargetContainer from "components/shared/scroll/ScrollTargetContainer.component";
import Loader from "components/utils/loader.components";
import StatisticsContext from "contexts/statistics.context";

const useStyles = makeStyles(theme => ({
  groupHeader: {
    marginTop: 32,
    marginBottom: 16
  },
  radiosDisabled: {
    opacity: theme.opacity.disabled
  },
  loader: {
    display: "flex"
  }
}));


export default function ClaimsByPopulationPage(props) {
  const { state } = props;
  const classes = useStyles();
  const searchAbortRef = useRef();
  const populationTableRef = useRef();
  const { state: { populations }} = useContext(StatisticsContext);

  const [activeNodeIdSet, setActiveNodeIdSet] = useState(new Set());
  const [activePopulation, setActivePopulation] = useState(null);
  const [claimResults, setClaimResults] = useState(null);
  const [hasSearchError, setHasSearchError] = useState(false);
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const populationNames = useMemo(() => (
    Object.keys(populations).sort()
  ), [populations])

  const claimsByPopulation = useMemo(() => (
    Object.fromEntries(
      populationNames?.map?.(populationName => ([
        populationName,
        (claimResults || []).filter(claim => (
          populationName === claim._derivedData.DistributionDataset.population
        ))
      ])) || []
    )
  ), [claimResults, populationNames]);

  useEffect(function selectDefaultActivePopulation() {
    const firstEnabledEntry = (
      Object.entries(claimsByPopulation).find(([_type, results]) => (
        results.length
      ))
    );
    setActivePopulation(firstEnabledEntry?.[0] || null);
  }, [claimsByPopulation]);

  const handleFindClaims = useCallback(async () => {
    try {
      setIsSearchLoading(true);
      searchAbortRef.current = new AbortController();
      const results = await ClaimService.getClaimsBySearch(
        searchAbortRef.current,
        searchQuery,
        Array.from(activeNodeIdSet).join(",")
      );
      if (results.payload) {
        setClaimResults(results.payload);
        setAccordionExpanded(false);
        if (populationTableRef.current) {
          populationTableRef.current.scrollIntoView();
        }
      }
    } catch (error) {
      setHasSearchError(true);
      console.error(error);
    } finally {
      setIsSearchLoading(false);
    }
  }, [searchQuery, activeNodeIdSet])

  const handlePopulationRadioChange = useCallback(event => {
    setActivePopulation(event.currentTarget.value)
  }, []);

  useEffect(() => (
    function cancelSearchOnUnmount() {
      ClaimService.cancel(searchAbortRef.current);
    }
  ), []);

  return (
    <Box marginBottom={2}>
      <SubpageHeader title="Claims by Population Group" />
      <WorkflowNodeSearchFilter
        activeNodeIdSet={activeNodeIdSet}
        setActiveNodeIdSet={setActiveNodeIdSet}
        accordionExpanded={accordionExpanded}
        setAccordionExpanded={setAccordionExpanded}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        searchResults={claimResults}
        onSubmit={handleFindClaims}
        autoFocus
      />
      <Divider />
      {!populations ? (
        <Box align="center" margin="auto" paddingTop={8}>
          <H5 color="tertiary" withMargin>Loading population data...</H5>
          <GridContainer>
            <Loader className={classes.loader} inline />
          </GridContainer>
        </Box>
      ) : (
        <ScrollTargetContainer scrollTargetRef={populationTableRef}>
          <H3
            color={claimResults?.length ? "primary" : "tertiary"}
            className={
              classNames(
                classes.groupHeader,
                !claimResults?.length && classes.radiosDisabled
              )
            }
          >
            Select Population Group
          </H3>
          <GridContainer
            maxWidth={1400}
            marginBottom={4}
            spacing={{ xs: 2, lg: 3, xl: 4 }}
            justifyContent="center"
            hasVerticalSpacing
          >
            {populationNames.map(populationName => (
              <GridItem
                xs={12}
                md
                alignItems="stretch"
                key={populationName}
              >
                <PopulationRadioCard
                  checked={activePopulation === populationName}
                  claims={claimsByPopulation?.[populationName]}
                  handleChange={handlePopulationRadioChange}
                  populationType={populationName}
                  state={state}
                />
              </GridItem>
            ))}
          </GridContainer>
          <SearchResultsWrapper
            error={hasSearchError}
            loading={isSearchLoading}
            results={claimResults}
          >
            <ClaimsTable
              claims={claimsByPopulation[activePopulation]}
              state={state}
              size="small"
              title={`Population ${activePopulation} Claims`}
              key={activePopulation}
              fullPage
            />
          </SearchResultsWrapper>
        </ScrollTargetContainer>
      )}
    </Box>
  );
}
