import { FormControlLabel, Radio } from "@mui/material";
import classNames from "classnames";
import { makeStyles } from "mui-styles";

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.grey.dark,
    "& > span.MuiTypography-root": {
      padding: "0px 20px 0px 6px",
    },
  },
  labelHidden: {
    marginRight: 6
  },
  labelText: {
    fontSize: theme.typography.subtitle2.fontSize,
  },
  labelTextHidden: {
    display: "none"
  },
  radioRootClass: {
    color: `${theme.palette.teal.main}!important`,
  },
  disabled: {
    color: `${theme.palette.background.disabled}!important`,
  }
}));

export default function RadioInput(props) {
  const classes = useStyles();
  const {
    checked, disabled, inputProps, label, onChange,
    radioLabelClass, size = "small", value, test
  } = props;
  return (
    <FormControlLabel
      className={classNames(classes.label, !label && classes.labelHidden)}
      classes={{
        label: classNames(
          classes.labelText, !label && classes.labelTextHidden, radioLabelClass
        ),
      }}
      checked={checked}
      value={value}
      control={
        <Radio
          size={size}
          classes={{
            label: classes.radioLabel,
            disabled: classNames(
              disabled && classes.disabled, !disabled && classes.radioRootClass
            )
          }}
          inputProps={inputProps}
          disabled={disabled}
        />
      }
      label={label}
      onChange={onChange}
      data-cy={`radio-option-${label || test}`}
    />
  )
}