import React from "react";
import { makeStyles } from "mui-styles";
import EditIcon from "@mui/icons-material/Edit";
import ViewIcon from "@mui/icons-material/RemoveRedEye";
import classNames from "classnames";
import { isReadOnly } from "utils/roles.utils";
import { IconButton } from "@mui/material";
import Tooltip from "components/utils/tooltip.component";

const useStyles = makeStyles((theme) => ({
  activeMatrix: {
    "&:active": {
      fill: "white",
      strokeWidth: 2,
      stroke: theme.palette.secondary.main,
    }
  },
  editIconWrapper: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  editIconHeadWrapper: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 20,
    width: 20,
    right: 2,
    bottom: 0,
    "&:hover": {
      cursor: "pointer",
    },
  },
  editIconHeadWrapperInline: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 20,
    width: 20,
  },
  editIcon: {
    fill: theme.palette.secondary.main,
    fontSize: "1.5em",
    position: "relative",
  },
  editIconHead: {
    fill: "white",
    fontSize: theme.typography.body1.fontSize,
    position: "relative",
  },
  hoverLabel: {
    backgroundColor: "white",
    // width: "fit-content", //not showing proerly on safari
    borderRadius: 3,
    right: "-3px",
    padding: "3px 7px",
    position: "absolute",
    zIndex: 7000,
    opacity: 0,
    display: "flex",
    "&:hover": {
      opacity: 100,
      cursor: "pointer",
    },
  },
  hoverLabelMatrix: {
    right: "unset",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 2
  },
  hoverLabelInline: {
    right: "unset",
  },
  labelText: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    whiteSpace: "nowrap",
    "&:active": {
      color: theme.palette.primary.light,
    },
  },
  label: {
    fontSize: theme.typography.caption.fontSize,
  },
  button: {
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.secondary.main
    },
  },
  disableIcon: {
    pointerEvents: "none",
    fill: theme.palette.grey.disabled,
    fontSize: "1.5em",
    position: "relative",
    strokeWidth: 2,
  },
  disableIconWrapper: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
}));

const CustomEditIcon = (props) => {
  const classes = useStyles();
  const readOnly = isReadOnly();

  if (props.variant === "matrix") {
    return (
      <div
        className={
          classNames(
            !props.disabled && classes.editIconWrapper,
            props.disabled && classes.disableIconWrapper
          )
        }
        onClick={props.disabled ? null : props.onClick}
        data-id={props.name}
        data-cy={
          readOnly ? `view-icon-${props.test}` : `edit-icon-${props.test}`
        }
      >
        {readOnly ? (
          <ViewIcon
            className={classNames(classes.editIcon, classes.activeMatrix)}
          />
        ) : (
          <EditIcon
            className={
              classNames(
                !props.disabled && classes.editIcon,
                !props.disabled && classes.activeMatrix,
                props.disabled && classes.disableIcon
              )
            }
          />
        )}
        {!props.disabled && (
          <div
            className={
              classNames(classes.hoverLabel, classes.hoverLabelMatrix)
            }
          >
            <span className={classNames(classes.label, classes.labelText)}>
              {readOnly ? "View" : "Edit"}
            </span>
          </div>
        )}
      </div>
    );
  } else if (props.variant === "matrixSmall") {
    return (
      <div
        className={classes.editIconWrapper}
        onClick={props.onClick}
        data-id={props.name}
        data-cy={readOnly ? `view-icon-${props.test}` : `edit-icon-${props.test}`}
      >
        {readOnly ? (
          <ViewIcon className={classNames(classes.editIcon, classes.activeMatrix)} />
        ) : (
          <EditIcon className={classNames(classes.editIcon, classes.activeMatrix)} />
        )}
        <div className={classNames(classes.hoverLabel, classes.hoverLabelMatrix)}>
          <span className={classNames(classes.label, classes.labelText)}>
            {readOnly ? "View" : "Edit"}
          </span>
        </div>
      </div>
    );
  } else if (props.variant === "header") {
    return (
      <div className={classes.editIconHeadWrapper}>
        {readOnly ? <ViewIcon className={classes.editIconHead} />
          : <EditIcon className={classes.editIconHead} />}
        <div
          className={classes.hoverLabel}
          onClick={props.onClick}
          data-id={props.name}
        >
          <span className={classNames(classes.label, classes.labelText)}>
            {readOnly ? "View" : "Edit"}
          </span>
        </div>
      </div>
    );
  } else if (props.variant === "headerInline") {
    return (
      <div
        className={
          classNames(classes.editIconHeadWrapperInline, classes.editIconWrapper)
        }
      >
        {readOnly ? <ViewIcon className={classes.editIconHead} />
          : <EditIcon className={classes.editIconHead} />}
        <div
          className={classNames(classes.hoverLabel, classes.hoverLabelInline)}
          onClick={props.onClick}
          data-id={props.name}
          data-cy={readOnly ?
            `viewIcon-headerInline-${props.test}` :
            `editIcon-headerInline-${props.test}`
          }
        >
          <span className={classNames(classes.label, classes.labelText)}>
            {readOnly ? "View" : "Edit"}
          </span>
        </div>
      </div>
    );
  } else if (props.variant === "iconButton") { //CURRENTLY NOT USED
    const defaultTooltipMessage = readOnly ? "View" : "Edit";
    return (
      <Tooltip
        id="tooltip-edit"
        title={props.tooltipMessage ? props.tooltipMessage : defaultTooltipMessage}
        placement={props.tooltipPlacement ? props.tooltipPlacement : "left"}
      >
        <IconButton
          onClick={props.onClick}
          className={classes.button}
          data-id={props.name}
          data-cy={readOnly ? `viewIcon--${props.test}` : `editIcon--${props.test}`}
        >
          {readOnly ? <ViewIcon /> : <EditIcon />}
        </IconButton>
      </Tooltip>
    )
  } else {
    return <EditIcon />;
  }
};

export default CustomEditIcon;
