// Content Types
export const SEARCH_CONTENT_TYPE_DOC = "document";
export const SEARCH_CONTENT_TYPE_WP = "workpackage";
export const SEARCH_CONTENT_TYPE_TASK = "task";
export const SEARCH_CONTENT_TYPE_RB = "runbook";
export const SEARCH_CONTENT_TYPE_RB_TASK = "runbookTask";
export const SEARCH_CONTENT_TYPE_CONTROL = "controlMatrix";
export const SEARCH_CONTENT_TYPE_REQ = "requirement";

// Content Filter Names
export const CATEGORY_FILTER_NAME_CONTROL = "controlMatrix";
export const CATEGORY_FILTER_NAME_DOC = "documents";
export const CATEGORY_FILTER_NAME_REQ = "requirements";
export const CATEGORY_FILTER_NAME_RB = "runbooks";
export const CATEGORY_FILTER_NAME_TASK_WP = "tasksAndWorkpackages";

export const searchFilterContentTypeMap = {
  [SEARCH_CONTENT_TYPE_DOC]: CATEGORY_FILTER_NAME_DOC,
  [SEARCH_CONTENT_TYPE_WP]: CATEGORY_FILTER_NAME_TASK_WP,
  [SEARCH_CONTENT_TYPE_TASK]: CATEGORY_FILTER_NAME_TASK_WP,
  [SEARCH_CONTENT_TYPE_RB]: CATEGORY_FILTER_NAME_RB,
  [SEARCH_CONTENT_TYPE_RB_TASK]: CATEGORY_FILTER_NAME_RB,
  [SEARCH_CONTENT_TYPE_CONTROL]: CATEGORY_FILTER_NAME_CONTROL,
  [SEARCH_CONTENT_TYPE_REQ]: CATEGORY_FILTER_NAME_REQ,
};

export const contentTypeFilterOptions = [
  {
    name: CATEGORY_FILTER_NAME_CONTROL,
    label: "Controls",
  },
  {
    name: CATEGORY_FILTER_NAME_DOC,
    label: "Documents",
  },
  {
    name: CATEGORY_FILTER_NAME_REQ,
    label: "Requirements",
  },
  {
    name: CATEGORY_FILTER_NAME_RB,
    label: "Runbooks and Runbook Tasks",
  },
  {
    name: CATEGORY_FILTER_NAME_TASK_WP,
    label: "Tasks and Workpackages",
  },
];
