import moment from "moment";
import { DATETIME_FORMAT_24H } from "constants/date.constants";

const MULTIPLE_REGEX = /^\[(.*)\]$/g;
const DATETIME_REGEX = /^\d{4}-\d\d-\d\dT\d\d:\d\d:?\d?\d?\s?\w?\w?$/g;


// given list of field names and two objects,
//   compares values of initial and edited version
export const oneOrMoreFieldsChanged = (fieldNameArr, initialObj, newObj) => {
  for (const fieldName of fieldNameArr) {
    if (initialObj[fieldName] !== newObj[fieldName]) {
      return true;
    }
  }
  return false;
};

export const serializeFormInput = value => {
  if (typeof value === "string" && value) {
    if (!isNaN(value)) {
      return parseInt(value, 10);
    }
    const isMultipleValueString = (
      value.startsWith("[") && value.endsWith("]")
    );
    if (isMultipleValueString) {
      const valuesArray = value.replace(MULTIPLE_REGEX, "$1").split(",");
      if (valuesArray.every(arrayItem => !arrayItem)) {
        return [];
      }
      const isAllNumeric = valuesArray.every(arrayItem => (
        arrayItem && !isNaN(arrayItem)
      ));
      if (isAllNumeric) {
        return valuesArray.map(arrayItem => parseInt(arrayItem));
      }
      return valuesArray;
    }
    if (value === "true") {
      return 1;
    }
    if (value === "false") {
      return 0;
    }
    if (value.match(DATETIME_REGEX)) {
      return moment(value).format(DATETIME_FORMAT_24H);
    }
  }
  return value;
};

const stripAssociationField = (obj) => {
  delete obj._associations;
};

export const stripAssociationFields = (list) => {
  for (const obj of list) {
    stripAssociationField(obj);
  }
  return list;
};
