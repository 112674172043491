import React from "react";
import { TextField as MuiTextField } from "@mui/material";
import FormElementBase from "./shared/formElementBase.component";

export default function TextField(props) {
  const { errorMessage, onAutosave, ...muiProps } = props;
  return (
    <FormElementBase
      errorMessage={errorMessage}
      onAutosave={onAutosave}
      {...muiProps}
    >
      <TextFieldBase {...muiProps} />
    </FormElementBase>
  );
}

function TextFieldBase(props) {
  const {
    errorMessage: _errorMessage,
    labelError: _labelError,
    onAutosave: _onAutosave,
    ...muiProps
  } = props;
  return (
    <MuiTextField {...muiProps} />
  );
}
