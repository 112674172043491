import StatisticsContext from "contexts/statistics.context";
import statisticsReducer, { ACTION_SET_POPULATIONS, ACTION_SET_SUMMARY_COUNT, statisticsReducerInitialState } from "reducers/global/statistics.reducer";
import { useCallback, useEffect, useLayoutEffect, useReducer, useRef } from "react";
import SummaryCountService from "services/SummaryCount.service";
import DistributionDatasetService from "services/DistributionDataset.service";

/*
 * Loads and provides statistics as program-based context for app.
 * Pages are expected to load and dispatch statistics on their own.
 */
export default function StatisticsContextProvider(props) {
  const { children } = props;
  const populationRequestAbortRef = useRef(null);
  const summaryRequestAbortRef = useRef(null);

  const [state, dispatch] = useReducer(
    statisticsReducer,
    statisticsReducerInitialState
  );

  const { populations, summaryCounts } = state;

  const requireFetch = useCallback(async (
    fetchFn, action, abortRef, fetchedData
  ) => {
    if (fetchedData || abortRef.current) {
      return;
    }
    abortRef.current = new AbortController();
    const response = await fetchFn(abortRef.current);
    if (response.payload) {
      dispatch({
        type: action,
        payload: response.payload
      });
    }
  }, [dispatch]);

  const requireFetchPopulations = useCallback(async () => (
    requireFetch(
      DistributionDatasetService.getPopulationStatsForDistributions.bind(
        DistributionDatasetService
      ),
      ACTION_SET_POPULATIONS,
      populationRequestAbortRef,
      populations
    )
  ), [requireFetch, populations]);

  const requireFetchSummaryCounts = useCallback(async () => (
    requireFetch(
      SummaryCountService.getAll.bind(SummaryCountService),
      ACTION_SET_SUMMARY_COUNT,
      summaryRequestAbortRef,
      summaryCounts
    )
  ), [requireFetch, summaryCounts]);

  const cancelRequest = useCallback((abortRef, Service) => {
    if (abortRef.current) {
      Service.cancel(abortRef.current);
      abortRef.current = null;
    }
  }, []);

  const cancelStatsRequests = useCallback(() => {
    cancelRequest(populationRequestAbortRef, DistributionDatasetService);
    cancelRequest(summaryRequestAbortRef, SummaryCountService);
  }, [cancelRequest]);

  useEffect(() => {
    requireFetchPopulations();
    requireFetchSummaryCounts();
  }, [requireFetchPopulations, requireFetchSummaryCounts]);

  useLayoutEffect(() => (
    function cancelRequestsOnUnmount() {
      cancelStatsRequests();
    }
  ), [cancelStatsRequests]);

  return (
    <StatisticsContext.Provider
      value={{
        state,
        dispatch,
        cancelStatsRequests,
      }}
    >
      {children}
    </StatisticsContext.Provider>
  );
}
