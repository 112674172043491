import React, { useCallback, useMemo } from "react";
import { makeStyles } from "mui-styles";
import Link from "components/utils/link.component";
import DisplayHTML from "components/utils/displayHTML.component";
import { Typography, Divider, Paper } from "@mui/material";
import { SEARCH_CONTENT_TYPE_CONTROL } from "./constants/search.constants";

const useStyles = makeStyles((theme) => ({
  resultBox: {
    padding: "4px 24px",
    width: "100%",
  },
  resultContent: {
    color: theme.palette.text.secondary,
    margin: "8px 16px 16px",
  },
}));

const SearchResult = (props) => {
  const classes = useStyles();
  const {
    result: { data: result },
    resultProgram,
    searchTerm,
    isLastResult,
  } = props;

  const linkState = useMemo(() => {
    switch (result.contentType) {
      /*
       * Todo: These contentTypes are unused. They may be used in the future.
       *
       * case "Charter-Section-Builder":
       * case "Matrix-Table":
       *   return result.section;
       * case "Data-Inventory":
       *   return {
       *     refId: parseInt(result.section),
       *     productId: result.contentId
       *   };
       * case "Data-Interfaces":
       *   return { productId: result.contentId };
       */
      case "charterSectionBuilder":
      case "matrixTable":
      case "dataInventory":
      case "dataInterfaces":
      case SEARCH_CONTENT_TYPE_CONTROL:
        return { groupId: result.frameworkId, itemId: result.contentId };
      default:
        return { itemId: result.contentId };
    }
  }, [result]);

  const makePrgramValueHtml = useCallback(() => {
    if (!resultProgram) {
      return "";
    } else {
      return `<b>Program: </b> ${resultProgram.Name} </br>`;
    }
  }, [resultProgram]);

  return (
    <Paper
      className={classes.resultBox}
      data-cy={`resultBox-${result.contentType}-${result.section}`}
      square
    >
      <Link
        variant="routerLinkBold"
        to={{
          pathname: result.pageURL,
          state: linkState,
        }}
        test={`search-result-${result.pageTitle}`}
      >
        <Typography variant="h5">{result.pageTitle}</Typography>
      </Link>
      {!!result && (
        <div className={classes.resultContent}>
          <DisplayHTML
            html={result.sectionDetailHtml + makePrgramValueHtml()}
            searchTerm={searchTerm}
          />
        </div>
      )}
      {!isLastResult && <Divider />}
    </Paper>
  );
};

export default SearchResult;
