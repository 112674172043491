import React from "react";
/*
 * import { useEffect } from "react";
 * import { Grid, Link } from "@mui/material";
 * import { makeStyles } from "mui-styles";
 */


const TableType = () => {
  return (
    <div>
      TABLE CONTENT
    </div>
  )
}

export default TableType;
