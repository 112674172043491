import AjaxService from "services/base/Ajax.service.js";

class ConsentOrderServiceBase extends AjaxService {
  shouldExtractResponseData() {
    return true;
  }

  async getConsentOrderList() {
    return this.get("/consentOrder");
  }
}

const ConsentOrderService = new ConsentOrderServiceBase();
export default ConsentOrderService;
