
import { makeStyles } from "mui-styles";

const useStyles = makeStyles((theme) => ({
  passwordReqs: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "white",
    padding: "10px 15px 10px 15px",
    background: "linear-gradient(170deg, rgba(0,122,204,1) 36%, rgba(8,106,184,1) 100%)",
    borderRadius: 6,
    marginTop: 5,
    boxShadow: theme.shadow.elevation2,
  },
  list: {
    margin: 5,
    marginRight: "auto",
    paddingLeft: 20,
    textAlign: "left",
  },

}));

const PasswordReqs = () => {
  const classes = useStyles();
  return (
    <div className={classes.passwordReqs}>
      A valid password must be 12-20 characters in length and include one of
      each:
      <ul className={classes.list}>
        <li>number</li>
        <li>lowercase letter</li>
        <li>uppercase letter</li>
        <li>punctuation symbol</li>
      </ul>
    </div>
  );
};

export default PasswordReqs;
