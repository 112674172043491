import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Container } from "@mui/material";
import Alert from "@mui/lab/Alert";
import { makeStyles } from "mui-styles";
import ButtonDefault from "components/utils/buttonDefault.component";
import RMSLogo from "components/utils/rmsLogo.component";
import AuthTextField from "components/utils/form-elements/authTextField.component";
import SetupMFA from "components/pages/auth/setupMFA.component";
import { currentUserInfo } from "services/util/authSession.utils";
import AuthService from "services/base/Auth.service";
import { HTTP_STATUS_OK } from "services/constants/response.constants";
import StorageService from "services/Storage.service";

const useStyles = makeStyles((theme) => ({
  alertMessage: {
    color: "white",
    backgroundColor: theme.palette.error.light,
    padding: "0px 16px",
    marginBottom: 10,

    "& .MuiAlert-icon": {
      fill: "white",
      color: "white",
    },
  },
  linkWrapper: {
    marginTop: 30,
    textAlign: "center",
  },
  buttonWrapper: {
    marginTop: 10,
  },
}));


const VerifyForm = () => {
  const history = useHistory();
  const classes = useStyles();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [message, setMessage] = useState();
  const [token, setToken] = useState("");

  // handle the form change
  const handleChange = (event) => {
    if (event.target.value.length <= 6) {
      setToken(event.target.value);
    }
  };

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    const [isLoggedInResponse] = await Promise.all([AuthService.verifyMFA(token)]);
    setIsLoggedIn(isLoggedInResponse.status === HTTP_STATUS_OK);
    if (isLoggedInResponse.status === HTTP_STATUS_OK) {
      const requestLocation = StorageService.getSessionItem("originalPath");
      if (requestLocation) {
        history.push("/home", { requestLocation });
      } else {
        history.push("/home");
      }
      StorageService.removeSessionItem("originalPath");
      return "";
    } else {
      invalid();
    }
  }, [history, token]);

  const invalid = () => {
    setMessage("Invalid Token");
    setToken("");
  };

  if (isLoggedIn) {
    const requestLocation = StorageService.getSessionItem("originalPath");
    if (requestLocation) {
      history.push("/home", {
        requestLocation
      })
    } else {
      history.push("/home");
    }
    return "";
  } else {
    const user = currentUserInfo(true);

    return (
      <Container component="main" maxWidth="xs">
        <RMSLogo />
        {user.mfaEnabled === 0 && (
          <div className={classes.linkWrapper}>
            <SetupMFA />
          </div>
        )}
        {!!message && (
          <Alert
            severity="error"
            className={classes.alertMessage}
            data-cy="error-InvalidToken"
          >
            {message}
          </Alert>
        )}
        <form noValidate>
          <AuthTextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="6 Digit Token"
            name="token"
            autoFocus
            size="small"
            value={token}
            onChange={handleChange}
            test="MFA-code"
          />
          <div className={classes.buttonWrapper}>
            <ButtonDefault
              fullWidth
              variant="large"
              background="secondary"
              onClick={handleSubmit}
              data-cy="loginButton"
              type="submit"
              disabled={token.length !== 6}
            >
              Verify Token
            </ButtonDefault>
          </div>
        </form>
      </Container>
    );
  }
};

export default VerifyForm;