import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "mui-styles";
import ButtonDefault from "components/utils/buttonDefault.component";
import CustomTable from "components/utils/tables/sortableTable.component";
import CustomLink from "components/utils/link.component";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import ClipLoader from "components/utils/clipLoad.component";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { useParams } from "react-router-dom";
import ProgramService from "services/Program.service";
import ProgramsContext from "contexts/programs.context";
import { findProgramBuilderComponent } from "components/builder/utils/builderProgram.utils";
import { ACTION_PARTIAL_REPLACE_COMPONENT_OBJECTS } from "reducers/global/program.reducer";
import { COMPONENT_OBJECT_TYPE_BUILDER } from "components/constants/program.constants";
import OrganizationContext from "contexts/organization.context";

const useStyles = makeStyles((theme) => ({
  createLink: {
    color: "white",
  },
  createIconSmall: {
    fontSize: "12px",
    marginRight: 6,
    color: theme.palette.secondary.main,
  },
  reportIconSmall: {
    fontSize: "12px",
    marginRight: 6,
    color: "white",
  },
}));

const headers = [
  { name: "policy", value: "Policies" },
  { name: "procedure", value: "Procedures" },
  { name: "action", value: "Actions" }
];


export const ProcLandingTable = (props) => {
  const classes = useStyles();
  const { state, dispatch } = useContext(ProgramsContext);
  const [tableData, setTableData] = useState();
  const { programId, programComponentId } = useParams();
  const { state: organizationState } = useContext(OrganizationContext);

  useEffect(() => {
    const loadProcBuilder = async (mode, policy, builder) => {
      if (props.setChosenProgram) {
        let componentObject = findProgramBuilderComponent(
          policy.programId,
          "Procedure",
          state.programComponentsByProgramId,
          state.componentObjectsByComponentId,
        );
        if (!componentObject) {
          componentObject = (
            await ProgramService.getComponentObjectByProgramAndTypeValues(
              policy.programId,
              COMPONENT_OBJECT_TYPE_BUILDER,
              "Procedure",
              organizationState.activeOrganizationId
            )
          )?.payload;
          dispatch({
            type: ACTION_PARTIAL_REPLACE_COMPONENT_OBJECTS,
            payload: componentObject,
          });
        }
        props.setChosenProgram({
          id: policy.programId,
          name: "",
          compObjId: componentObject.componentObjectId,
        });
      }
      props.setCurrentPolicy(policy);
      props.setMode(mode, builder);//builder is null if loadTemplate
    }

    const data = [];
    props.policies.forEach(policy => {
      const builders = props.activeProcBuilders.filter(
        (b) => b.ParentBuilderDoc_ID === policy.BuilderDoc_ID
      );

      const createNewButton = (
        <ButtonDefault
          variant="small"
          color="secondary"
          className={classes.createLink}
          data-cy={`btn-createProcedure-${policy.Title}`}
          startIcon={
            <AddCircleOutlineOutlinedIcon className={classes.createIconSmall} />
          }
          onClick={() => loadProcBuilder("loadTemplate",policy)}
          disableReadOnlyUsers
        >
          Procedure
        </ButtonDefault>
      );
      // Create New Proc Row
      data.push({
        policy: `${policy.Title} - Version ${policy.Version}`,
        procedure: "",
        action: createNewButton, //disabledButton,
        Level: "1", // makes background of row blue
      });
      // No Corresponding Procedures Row
      if (builders.length === 0) {
        data.push({
          policy: "",
          procedure: "No Corresponding Procedures",
          action: (
            <ButtonDefault
              variant="small"
              background="primary"
              disabled
              startIcon={
                <PictureAsPdfOutlinedIcon className={classes.reportIconSmall} />
              }
            >
              Export PDF
            </ButtonDefault>
          ),
        });
      }
      // Corresponding Procedures
      builders.forEach((builder) => {
        const link = (
          <CustomLink
            onClick={() => loadProcBuilder("editCurrent",policy, builder)}
            test={`procedure-${builder.Title}`}
          >
            {`${builder.Title} - Version ${builder.Version}`}
          </CustomLink>
        );

        const button = (
          <ButtonDefault
            variant="small"
            background="primary"
            disabled={!builder}
            startIcon={
              props.loadingDownload === builder.Builder_ID ? (
                <ClipLoader />
              ) : (
                <PictureAsPdfOutlinedIcon className={classes.reportIconSmall} />
              )
            }
            onClick={() => {
              props.downloadBuilderFile(
                builder.Builder_ID,
                `${builder.Title}-Version${builder.Version}-${builder.Approval_Date}`
              );
            }}
          >
            Export PDF
          </ButtonDefault>
        );
        // Active Proc Row
        data.push({
          policy: "",
          procedure: link,
          action: button,
        });
      });
      setTableData(data);
    });
  }, [
    props,
    classes.createIconSmall,
    classes.createLink,
    classes.reportIconSmall,
    dispatch,
    programComponentId,
    programId,
    state.componentObjectsByComponentId,
    state.programComponentsByProgramId,
    organizationState.activeOrganizationId
  ]);

  if (tableData) {
    return (
      <CustomTable
        header={headers}
        data={tableData}
        alignment={["left", "center", "center"]}
        procLevelOne
        maxHeight="calc(100vh - 280px)"
      />
    );
  } else {
    return "";
  }
};

export default ProcLandingTable;
