import React from "react";
import { makeStyles } from "mui-styles";
import { Container } from "@mui/material";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  customContainer: {
    maxWidth: "calc(100vw - 400px)",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "calc(100vw - 275px)",
    },
  },
  containerGutters: {
    paddingLeft: 40,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 20,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 10,
    },
  }
}));

const CustomContainer = (props) => {
  const classes = useStyles();
  const { children, disableGutters } = props;
  return (
    <Container
      className={
        classNames(
          classes.customContainer,
          !disableGutters && classes.containerGutters
        )
      }
      disableGutters={disableGutters}
    >
      {children}
    </Container>
  );
};

export default CustomContainer;
