import { DATA_TYPE_CASH, DATA_TYPE_DATE, DATA_TYPE_NUMBER, DATA_TYPE_TEXT } from "components/constants/claim.constants";
import { makeDateCell } from "components/utils/tables/utils/cell.utils";
import { createNodeCellParams } from "components/utils/tables/utils/dataTable.utils";
import { formatCash } from "utils/localization.utils";

export function renderBasicDataTypeCell(dataType, dataValue) {
  switch (dataType) {
    case DATA_TYPE_DATE:
      return makeDateCell(dataValue);
    case DATA_TYPE_CASH:
      const displayCash = formatCash(dataValue) || "$0";
      return createNodeCellParams(dataValue, displayCash, displayCash);
    case DATA_TYPE_NUMBER:
      return `${dataValue || 0}`;
    case DATA_TYPE_TEXT:
    default:
      return dataValue || "--";
  }
}
