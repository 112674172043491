import React, { useMemo, useRef, useState } from "react";
import { makeStyles } from "mui-styles";
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import TableCell from "components/utils/tables/shared/tableCell.component";
import classNames from "classnames";
import moment from "moment";
import { getComparator, stableSort } from "components/utils/tables/utils/rowSort.utils";
import TableSortLabel from "components/utils/tableSortLabel.component";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import { useCallback } from "react";
import ButtonDefault from "components/utils/buttonDefault.component";
import CheckIcon from "@mui/icons-material/Check";
import { PictureAsPdfOutlined } from "@mui/icons-material";
import { HTTP_STATUS_OK } from "services/constants/response.constants";
import FileService from "services/File.service";
import ClipLoader from "components/utils/clipLoad.component";
import useNumericParams from "hooks/useNumericParams";
import useTargetTableRowViaLink from "hooks/useTargetTableRowViaLink";
import ConfirmationDialog from "components/utils/dialogs/confirmationDialog.component";
import NotificationDialog from "components/utils/dialogs/notificationDialog.component";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: 10,
  },
  tableContainerFullPage: {
    marginTop: 40,
  },
  maxHeight: {
    maxHeight: 300,
    overflowX: "hidden"
  },
  maxHeightFullPage: {
    maxHeight: "calc(100vh - 220px)",
    overflowX: "hidden"
  },
  tablePrimaryHeaderCellLarge: {
    backgroundColor: theme.palette.purple.main,
    color: "white",
    fontWeight: "bold",
    fontSize: theme.typography.body2.fontSize,
    padding: "10px 15px",
    lineHeight: "normal",
  },
  previous: {
    backgroundColor: theme.palette.tertiaryGrey.main,
  },
  tableBodyCellLarge: {
    padding: "10px 15px",
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
  },
  link: {
    color: theme.palette.secondary.dark,
    fill: theme.palette.secondary.dark,
    "&:hover": {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
      cursor: "pointer",
    },
    "& $downloadFileIcon": {
      color: "inherit",
    },
  },
  centerAlign: {
    textAlign: "center",
  },
  leftAlign: {
    textAlign: "left",
  },
  emptyMessage: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption.fontSize,
    textAlign: "center",
  },
  downloadFileIcon: {
    maxHeight: 10,
    maxWidth: 10,
    marginLeft: "-2px",
    marginRight: 2,
  },
  uploadIconSmall: {
    fontSize: theme.typography.body2.fontSize,
    marginRight: 6,
  },
  actionsCell: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    color: "white",
    alignItems: "center"
  },
  buttonWidth: {
    width: 140,
  }
}));

const baseColumns = [
  {
    sortKey: "DocName",
    label: "Name",
    align: "left"
  },
  {
    sortKey: "Description",
    label: "Description",
    align: "left"
  },
]

const previousDocColumns = [
  ...baseColumns,
  {
    sortKey: "Version",
    label: "Version",
    align: "center"
  },
  {
    sortKey: "PublishDate",
    label: "Approved",
    align: "center"
  },
  {
    sortKey: "Review_Date",
    label: "Reviewed",
    align: "center"
  }
];

const currentDocColumns = [
  ...previousDocColumns,
  {
    sortKey: "Actions",
    label: "Actions",
    align: "center"
  },
];


const DocumentControlTable = (props) => {
  const classes = useStyles();
  const { componentObjectId } = useNumericParams();
  const isFullPage = !!componentObjectId && !props.tempFromTrainingProp;

  const rowRef = useRef({});
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(props.data.length > 0 ? "DocName" : "");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState({});
  const [loadingDownload, setLoadingDownload] = useState(null);
  const [updatingReviewDate, setUpdatingReviewDate] = useState(null);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const { targetedRowId } = useTargetTableRowViaLink(rowRef, !!props.data?.length);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleConfirmReview = useCallback(async () => {
    try {
      setUpdatingReviewDate(selectedDoc.DocID)
      setOpenDialog(false)
      const today = moment().format("YYYY-MM-DD")
      const updateDoc = {
        ProgramDoc_ID: selectedDoc.DocID,
        File_Name: selectedDoc.File_Name,
        Description: selectedDoc.Description,
        PublishDate: selectedDoc.PublishDate,
        Publisher: selectedDoc.Publisher,
        Builder_ID: selectedDoc.Builder_ID,
        Review_Date: today,
      }
      const updateFileResponse = (
        await FileService.updateReviewDateForProgramDoc(updateDoc)
      );
      const currentDocs = [...props.allCompFiles]
      const index = currentDocs.findIndex((item) => (
        updateFileResponse.payload.ProgramDoc_ID === item.ProgramDoc_ID
      ));
      currentDocs.splice(index, 1, { ...updateFileResponse.payload });
      props.setAllCompFiles(currentDocs)
      setSelectedDoc({})
    } catch (error) {
      //Set error message here
      setOpenErrorDialog(true)
    } finally {
      setUpdatingReviewDate(null)
    }

  }, [setSelectedDoc, setOpenDialog, selectedDoc, props])

  const downloadProgramDoc = (id, name) => {
    if (!id) {
      setOpenErrorDialog(true);
      setLoadingDownload(null);
      return;
    }
    setLoadingDownload(id);
    setOpenErrorDialog(false);
    // download file
    FileService.builderDocDownload(id)
      .then((blob) => {
        if (blob.status === HTTP_STATUS_OK) {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${name}.pdf`);
          // Append to html page
          document.body.appendChild(link);
          // Force download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        } else {
          setOpenErrorDialog(true);
          setLoadingDownload(null);

        }
      })
      .then(() => {
        setLoadingDownload(null);
      });
  };

  const columns = useMemo(() => {
    if (props.tempFromTrainingProp) {
      return baseColumns;
    } else if (props.isCurrentProgramDoc) {
      return currentDocColumns;
    }
    return previousDocColumns;
  }, [props.tempFromTrainingProp, props.isCurrentProgramDoc]);

  return (
    <div
      className={
        isFullPage ? classes.tableContainerFullPage : classes.tableContainer
      }
    >
      {props.tableTitle}
      <TableContainer
        component={Paper}
        data-cy="charterCard"
        className={isFullPage ? classes.maxHeightFullPage : classes.maxHeight}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  className={classNames(
                    classes.tablePrimaryHeaderCellLarge,
                    props.isCurrentProgramDoc ? classes.current : classes.previous,
                    column.align === "left" && classes.leftAlign,
                    column.align === "center" && classes.centerAlign
                  )}
                  key={column.label}
                >
                  <TableSortLabel
                    active={orderBy === column.sortKey}
                    direction={orderBy === column.sortKey ? order : "asc"}
                    onClick={createSortHandler(column.sortKey)}
                    align={column.align}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {(!props.data || props.data.length === 0) && (
            <TableBody>
              <TableRow>
                <TableCell
                  className={classes.tableSmallBodyCell}
                  colSpan="6"
                  scope="row"
                >
                  <div className={classes.emptyMessage}>No {props.title}</div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {!!props.data && props.data.length > 0 && (
            <TableBody>
              {stableSort(props.data, getComparator(order, orderBy)).map(row => (
                <TableRow
                  key={row.key}
                  id={row.DocID}
                  ref={(el) => (rowRef.current[row.DocID] = el)}
                  selected={targetedRowId === row.DocID}
                  data-cy={`docTable-docID-${row.DocID}`}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    className={
                      classNames(classes.tableBodyCellLarge, classes.leftAlign)
                    }
                  >
                    <span className={classes.link}>
                      <AttachFileOutlinedIcon
                        className={classes.downloadFileIcon}
                      />
                      {row.Title}
                    </span>
                  </TableCell>
                  <TableCell
                    className={
                      classNames(classes.tableBodyCellLarge, classes.leftAlign)
                    }
                  >
                    {row.Description}
                  </TableCell>
                  {!props.tempFromTrainingProp && (
                    <>
                      <TableCell
                        className={classNames(
                          classes.tableBodyCellLarge,
                          classes.centerAlign
                        )}
                      >
                        {row.Version}
                      </TableCell>
                      <TableCell
                        className={classNames(
                          classes.tableBodyCellLarge,
                          classes.centerAlign
                        )}
                      >
                        {!!row.PublishDate &&
                          moment(row.PublishDate).format("M/D/YYYY")}
                      </TableCell>
                      <TableCell
                        className={classNames(classes.tableBodyCellLarge)}
                      >
                        {row.Review_Date}
                      </TableCell>
                    </>
                  )}
                  {!!props.isCurrentProgramDoc && !props.tempFromTrainingProp && (
                    <TableCell
                      className={
                        classNames(
                          classes.tableBodyCellLarge,
                          classes.centerAlign
                        )
                      }
                    >
                      <div className={classes.actionsCell}>
                        <ButtonDefault
                          variant="small"
                          background="secondary"
                          startIcon={(
                            (
                              row.Builder_ID &&
                              loadingDownload === row.Builder_ID
                            ) ? (
                                <ClipLoader />
                              ) : (
                                <PictureAsPdfOutlined
                                  className={classes.uploadIconSmall}
                                />
                              )
                          )}
                          onClick={() => downloadProgramDoc(
                            row.Builder_ID,
                            `${row.DocName}-Version${row.Version}-${row.PublishDate}`
                          )} //Download PDF document
                          data-cy={`btn-review-${row.DocName}`}
                          disableReadOnlyUsers
                          disabled={!row.Builder_ID}
                          className={classes.buttonWidth}
                        >
                          Review
                        </ButtonDefault>
                        <ButtonDefault
                          variant="small"
                          background="primary"
                          startIcon={
                            updatingReviewDate === row.DocID ? (
                              <ClipLoader />
                            ) : (
                              <CheckIcon className={classes.uploadIconSmall} />
                            )
                          }
                          onClick={() => {
                            setSelectedDoc(row);
                            setOpenDialog(true);
                          }}
                          data-cy={`btn-review-${row.DocName}`}
                          disableReadOnlyUsers
                          disabled={!row.Builder_ID}
                          className={classes.buttonWidth}
                        >
                          Mark Reviewed
                        </ButtonDefault>
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <ConfirmationDialog
        open={openDialog}
        confirm={handleConfirmReview}
        onClose={() => setOpenDialog(false)}
        prompt={
          `Are you sure you want to mark "${selectedDoc.DocName}" as reviewed?`
        }
      />
      <NotificationDialog
        open={openErrorDialog}
        onClose={() => setOpenErrorDialog(false)}
        prompt="An error occurred. Please refresh the page and try again."
      />
    </div>
  )
}

export default DocumentControlTable
