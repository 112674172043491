import { TextField, FormControl } from "@mui/material";
import { makeStyles } from "mui-styles";
import FormLabel from "components/utils/form-elements/formLabel.component"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 2,
    marginBottom: 15,
    "& label.Mui-focused": {
      color: theme.palette.secondary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.secondary.main,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.secondary.main,
      },
    },
    "& input:invalid + fieldset": {
      borderColor: theme.palette.error.main,
      borderWidth: 2,
    },
  },
  inputRoot: {
    borderRadius: 20,
  },
  inputInput: {
    padding: "17px 14px",
    borderRadius: 20,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
  formControlRoot: {
    width: "100%",
  },
  formLabel: {
    marginLeft: 8,
  },
}));


const AuthTextField = (props) => {
  const classes = useStyles();
  return (
    <FormControl variant="outlined" className={classes.formControlRoot}>
      <FormLabel
        className={classes.formLabel}
        htmlFor={props.id}
        label={props.required ? `${props.label}*` : props.label}
        variant="default"
        error={props.error}
      />
      <TextField
        disabled={props.disabled}
        error={props.error}
        helperText={props.helperText}
        variant={props.variant}
        margin={props.margin}
        required={props.required}
        fullWidth={props.fullWidth}
        id={props.id}
        name={props.name}
        autoFocus={props.autoFocus}
        autoComplete={props.autoComplete}
        size={props.size}
        onChange={props.onChange}
        type={props.type}
        value={props.value}
        classes={{
          root: classes.root,
        }}
        InputProps={{
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput
          }
        }}
        data-cy={`input-${props.test}`}
      />
    </FormControl>
  );
};

export default AuthTextField;
