import { TableCell as MuiTableCell } from "@mui/material";
import { forwardRef } from "react";

// MUI TableCell with problematic props filtered out
const TableCell = forwardRef(({ isEmpty: _isEmpty, ...muiProps }, ref) => {
  return (
    <MuiTableCell ref={ref} {...muiProps} />
  );
});

TableCell.displayName = "TableCell";

export default TableCell;
