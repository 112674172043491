export const WF_EVENT_NODE_FIRST_LINE_REVIEW = "d1ddfd47-5c5e-4806-810b-5f39e680cd98"
export const WF_EVENT_NODE_ENGAGE_CLAIMANT = "6001e0c9-80db-4f13-8177-73b5931a4372"
export const WF_EVENT_NODE_SECOND_LINE_REVIEW = "87c97405-538b-4609-91c5-2a9c699083b9"
export const WF_EVENT_NODE_RECEIVE_ADD_DATADOCS = "9e81fac5-0df5-4531-9da4-d2f64d046978"
export const WF_EVENT_NODE_UPLOAD_MAILED_DOCS = "a452e6c6-4713-472f-abc0-2185a3066269"

export const WF_SKIP_OPTION_ID = "a509bec6-981a-4ce1-8273-36fe29765d0b";
export const WF_CLAIM_ISSUE_SUCCESSFUL_OPTION_ID = (
  "8db428c3-5bf4-4df9-bf9d-ae72de54bcdf"
);
export const WF_CLAIM_ISSUE_UNSUCCESSFUL_OPTION_ID = (
  "05b2efcb-cd11-4c8a-8b48-dc89ee2f14e7"
);
export const WF_CLAIM_REISSUE_SUCCESSFUL_OPTION_ID = (
  "4bac220b-e435-4e5f-84fc-2cd229c64b6a"
);
export const WF_CLAIM_REISSUE_UNSUCCESSFUL_OPTION_ID = (
  "66e21efa-2362-45a0-a649-97ab85aa6ed6"
);
