import React from "react";
import AssignmentIcon from "@mui/icons-material/Assignment";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import PeopleIcon from "@mui/icons-material/People";
import { Typography } from "@mui/material";
import { makeStyles } from "mui-styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  messageWrapper: {
    display: "flex",
    justifyContent: "center",
    color: theme.palette.text.secondary
  },
  iconWrapper: {
    fontSize: 18,
    marginRight: "5px",
  },
}));

export const IconMessage = (props) => {
  const classes = useStyles();
  const { dataCy, sectionName, variant } = props;

  switch (variant) {
    case "emptyAlerts":
      return (
        <span className={classes.messageWrapper}>
          <NotificationsOffIcon className={classes.iconWrapper} />
          <Typography variant="subtitle2" data-cy={dataCy}>
            No {sectionName} Available as of {moment(new Date()).format("MMMM Do, h:mma")}
          </Typography>
        </span>
      );
    case "emptyCommittee":
      return (
        <span className={classes.messageWrapper}>
          <PeopleIcon className={classes.iconWrapper} />
          <Typography variant="subtitle2">
            No {sectionName} Available
          </Typography>
        </span>
      );
    case "empty":
      return (
        <span className={classes.messageWrapper}>
          <AssignmentIcon className={classes.iconWrapper} />
          <Typography variant="subtitle2">
            No {sectionName} Available
          </Typography>
        </span>
      );
    default:
      return null;
  }
};

export default IconMessage;
