import React, { useState, useContext, useMemo, useCallback } from "react";
import {
  Paper,
  Divider,
  TableContainer,
  Table,
  TableBody,
} from "@mui/material";
import { makeStyles } from "mui-styles";
import HeaderPrimary from "components/utils/header.component.js";
import AlertContext from "contexts/alert.context";
import Loader from "components/utils/loader.components";
import AlertBox from "components/shared/alerts/alertBox.component";
import SortControls from "components/utils/form-elements/sortControls.component";
import UserService from "services/User.service";
import {
  reverseSortValuesByStringKey,
  sortValuesByStringKey,
} from "utils/sort.utils";
import { ACTION_REMOVE_USER_ALERT } from "reducers/global/alert.reducer";
import useActiveConsentOrder from "hooks/useActiveConsentOrder";
import ConsentOrderContext from "contexts/consentOrder.context";
import StorageService from "services/Storage.service";

const SORT_ASCENDING = "up"

const useStyles = makeStyles((theme) => ({
  headerBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
  controlBox: {
    padding: 5,
  },
  headerWrapper: {
    paddingTop: 8,
    paddingLeft: 16,
  }
}));

const sortFields = [
  { value: "endDate", label: "Date" },
  { value: "message", label: "A/Z" },
];

const AlertsFeed = props => {
  const classes = useStyles();
  const { maxHeight, test, handleCloseAlert, consentOrder } = props;
  const {
    state: alertState,
    dispatch: alertDispatch
  } = useContext(AlertContext);
  const { state: consentOrderState } = useContext(ConsentOrderContext);
  const [activeConsentOrder] = useActiveConsentOrder();
  const [sortBy, setSortBy] = useState("endDate");
  const [direction, setDirection] = useState(SORT_ASCENDING);

  const isMultiplePrograms = !consentOrderState.activeConsentOrderId || consentOrder;

  const alerts = useMemo(() => {
    if (!alertState.userAlerts) {
      return [];
    }
    let filteredAlerts = [...alertState.userAlerts];
    if (consentOrderState.activeConsentOrderId || consentOrder) {
      filteredAlerts = filteredAlerts.filter(alert => (
        alert.consentOrderId === (consentOrderState.activeConsentOrderId || consentOrder.consentOrderId)
      ));
    }
    if (direction === SORT_ASCENDING) {
      return sortValuesByStringKey(filteredAlerts, sortBy, "z");
    } else {
      return reverseSortValuesByStringKey(filteredAlerts, sortBy, "a");
    }
  }, [alertState.userAlerts, consentOrderState, consentOrder, direction, sortBy]);

  const deleteUserAlert = useCallback(async (userAlertId) => {
    await UserService.deleteUserAlert(userAlertId);
    alertDispatch({
      type: ACTION_REMOVE_USER_ALERT,
      payload: userAlertId
    });
    const dismissedAlert = alerts?.find?.(alert => (
      alert.userAlertId === userAlertId
    ));
    if (dismissedAlert?.consentOrderId) {
      const storageKey = (
        StorageService.STORAGE_KEY_LAST_VISIBLE_ALERTS_COUNT +
        `--${dismissedAlert.consentOrderId}`
      );
      const previousAlertCount = parseInt(
        StorageService.getItem(storageKey, false)
      ) || 1;
      StorageService.setItem(storageKey, previousAlertCount - 1, false);
    }
    StorageService.setItem(
      StorageService.STORAGE_KEY_LAST_VISIBLE_ALERTS_COUNT,
      alertState.userAlerts?.length - 1,
      false
    );
  }, [alertDispatch, alerts, alertState.userAlerts?.length]);

  const sortHandler = useCallback((event, dir = SORT_ASCENDING) => {
    if (event?.target?.value) {
      setSortBy(event.target.value);
    }
    setDirection(dir);
  }, []);

  const title = useMemo(() => {
    if (activeConsentOrder || consentOrder) {
      return `${activeConsentOrder?.title || consentOrder?.title} Alerts`
    } else if (isMultiplePrograms) {
      return "All Alerts";
    } else {
      return "Alerts";
    }
  }, [activeConsentOrder, isMultiplePrograms, consentOrder]);

  return (
    <>
      <div className={classes.headerWrapper}>
        <HeaderPrimary variant="h3Primary">{title}</HeaderPrimary>
      </div>
      <Paper>
        <div className={classes.headerBox}>
          <div className={classes.controlBox}>
            <SortControls
              value={sortBy}
              sortFields={sortFields}
              sortHandler={sortHandler}
              direction={direction}
              disabled={!alerts || alerts.length === 0}
            />
          </div>
        </div>
        <Divider />
        { !alerts ? (
          <div className={classes.loaderWrapper}>
            <Loader size={30} height={100} />
          </div>
        ) : (
          <TableContainer style={{ maxHeight }}>
            <Table
              className={classes.alertBoxContainer}
              data-cy={`alerts-feed-${test}`}
            >
              <TableBody>
                {alerts.length === 0 ? (
                  <AlertBox empty />
                ) : (
                  alerts.map((alert) => {
                    return (
                      <AlertBox
                        key={alert.userAlertId}
                        alert={alert}
                        deleteUserAlert={deleteUserAlert}
                        handleCloseAlert={handleCloseAlert}
                      />
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </>
  );
};

export default AlertsFeed;
