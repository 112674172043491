import React from "react";
import { makeStyles } from "mui-styles";
/*
 * Used as a wrapper for AlertsTable popups
 */

const useStyles = makeStyles({
  alertsContainer: {
    width: 600,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: 20,
    margin: 25,
    maxHeight: 600,
    overflowY: "auto"
  },
  feedViewContainer: {
    width: 420,
    display: "flex",
    flexDirection: "column",
    overflowY: "auto"
  },
});

const AlertsContainer = (props) => {
  const classes = useStyles();
  if (props.variant === "feedView") {
    return (
      <div className={classes.feedViewContainer} data-cy={props.test}>
        {props.children}
      </div>
    );

  } else {

    return (
      <div className={classes.alertsContainer} data-cy={props.test}>
        {props.children}
      </div>
    );
  }
};

export default AlertsContainer;
