import React from "react";
import { IconButton, Select, MenuItem } from "@mui/material";
import classNames from "classnames";
import { makeStyles } from "mui-styles";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const useStyles = makeStyles((theme) => ({
  sortControls: {
    display: "flex",
    margin: 3
  },
  iconButton: {
    padding: 5,
  },
  arrowIcon: {
    fontSize: 20,
  },
  activeArrow: {
    color: "DodgerBlue",
  },
  selectWrapper: {
    width: 110,
    paddingRight: 10,
  },
  select: {
    width: "100%",
    fontSize: theme.typography.body2.fontSize,
  },
  selectRoot: {
    color: theme.palette.text.secondary,
  },
}));

const SortControls = ({ value, sortFields, sortHandler, direction, disabled }) => {
  const classes = useStyles();
  return (
    <div className={classes.sortControls}>
      <div className={classes.selectWrapper}>
        <Select
          value={value}
          name="sort-items"
          onChange={(event) => sortHandler(event)}
          className={classes.select}
          disabled={disabled}
          classes={{ root: classes.selectRoot }}
        >
          {sortFields.map((item) => (
            <MenuItem key={item.value} value={item.value} id={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </div>
      <IconButton
        className={classes.iconButton}
        onClick={() => sortHandler(null, "up")}
        disabled={disabled}
      >
        <ArrowUpwardIcon
          className={classNames(
            classes.arrowIcon,
            direction === "up" && !disabled && classes.activeArrow
          )}
        />
      </IconButton>
      <IconButton
        className={classes.iconButton}
        onClick={() => sortHandler(null, "down")}
        disabled={disabled}
      >
        <ArrowDownwardIcon
          className={classNames(
            classes.arrowIcon,
            direction === "down" && !disabled && classes.activeArrow
          )}
        />
      </IconButton>
    </div>
  );
};

export default SortControls;
