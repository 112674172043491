import { useState, useEffect, useContext, useMemo, useRef } from "react";
import ProgramsContext from "contexts/programs.context";
import useNumericParams from "./useNumericParams";
// import AlertContext from "contexts/alert.context";
// import WorkpackageTasksContext from "contexts/workpackageTasks.context";


// TODO: Add back Alert and WorkpackageTasks behavior


/**
 * Use this custom hook to provide state of when the
 * EnteringPortal component should be displayed
 */
const usePortalEntry = () => {
  const { state: programState } = useContext(ProgramsContext);
  // const { state: alertState } = useContext(AlertContext);
  // const { state: wpTaskState } = useContext(WorkpackageTasksContext);
  const { programId } = useNumericParams();

  const isMountedRef = useRef();

  const isMissingGlobalData = (
    !programState.programs // ||
    // (!alertState.userAlerts && !alertState.isRefetching) ||
    // !wpTaskState.tasks ||
    // !wpTaskState.workpackages
  );

  const [isEnteringProgram, setIsEnteringProgram] = useState(
    isMissingGlobalData
  );
  const [isInitialProgramsLoad, setIsInitialProgramsLoad] = useState(
    isMissingGlobalData
  );

  const activeProgram = useMemo(() => (
    programState.programs?.[programId]
  ), [programState.programs, programId]);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    }
  }, []);

  useEffect(() => {
    if (isMissingGlobalData) {
      return setIsInitialProgramsLoad(true);
    }
    if (isInitialProgramsLoad || isMissingGlobalData) {
      setIsInitialProgramsLoad(false);
      setIsEnteringProgram(true);

      setTimeout(() => {
        if (isMountedRef.current) {
          setIsEnteringProgram(false);
        }
      }, 900);
    }
  }, [isInitialProgramsLoad, programId, isMissingGlobalData]);

  return [isEnteringProgram, activeProgram];
};

export default usePortalEntry;
