import {
  defaultBuilderSaveError,
  missingSectionError,
} from "components/builder/constants/builder.constants";
import { deleteBuilderSection } from "components/builder/utils/builder.utils";
import {
  deletePolicyBuilderSection,
  reOrderedSectionsWithNewSectionPolicy,
} from "reducers/shared/builder/utils/policyBuilder.utils";
import { addProcStep, deleteProcStep } from "reducers/shared/builder/utils/procedureBuilder.utils";
import {
  reOrderedSectionsViaWpButtonClick,
  reOrderedSectionsWithAddedSectionPlan,
  sectionsAfterRemovingPlanSection,
  sectionsAfterRemovingWpSection,
  tasksAfterAdd,
} from "reducers/shared/builder/utils/planBuilder.utils";

export const ACTION_SET_ANY_BUILDER = "any_builder__set";
export const ACTION_SET_BUILDER_INFO = "builder_info__set";
export const ACTION_SET_BUILDER_INFO_ERRORS = "builder_info_errors__set";
export const ACTION_SET_BUILDER_IS_SAVING = "builder_is_saving__set";
export const ACTION_SET_BUILDER_ERROR = "builder_failed_error__set";
export const ACTION_SET_BUILDER_CRITICAL = "builder_failed_critical__set";
export const ACTION_SET_BUILDER_AFTER_SAVE = "builder_after_save__set";
export const ACTION_SET_BUILDER_STATUS_MESSAGE = "builder_status_message__set";
export const ACTION_SET_MISSING_HEADER_MESSAGE = "builder_missing_header__set";
export const ACTION_SET_BUILDER_LOAD_TEMPLATE = "builder_load_template__set";
export const ACTION_SET_BUILDER_EDIT_CURRENT = "builder_edit_current__set";
export const ACTION_SET_BUILDER_COPY_PREVIOUS = "builder_copy_previouse__set";
export const ACTION_REPLACE_BUILDER_SECTION = "builder_section__replace";
export const ACTION_REPLACE_SECTION_CONTENT = "section_content__replace";
export const ACTION_DELETE_BUILDER_SECTION = "builder_section__delete";
export const ACTION_ADD_BUILDER_SECTION = "builder_section__add";
export const ACTION_SET_BUILDER_AFTER_PUBLISH = "builder_after_publish__set";

//POLICY BUILDER
export const ACTION_ADD_POLICY_BUILDER_SECTION = "policy_builder_section__add";
export const ACTION_ADD_POLICY_REF_SECTIONS =
  "policy_builder_ref_sections__add";
export const ACTION_DELETE_POLICY_BUILDER_SECTION =
  "policy_builder_section__delete";
export const ACTION_UPDATE_REFERENCE_ITEM_STATUS =
  "reference_item_status__update";
export const ACTION_SET_REFERENCE_SUBGROUPS = "reference_subgroups__set";

//PROCEDURE BUILDER
export const ACTION_ADD_PROC_STEP_SECTION = "proc_step_section__add";
export const ACTION_REPLACE_PROC_ELE_SECTION = "proc_ele_section__replace";
export const ACTION_REPLACE_PROC_ELE_CONTENT = "proc_ele_content_replace";
export const ACTION_REPLACE_PROC_STEP_SECTION = "proc_step_section__replace";
export const ACTION_REPLACE_PROC_STEP_CONTENT = "proc_step_content__replace";
export const ACTION_DELETE_PROC_STEP_SECTION = "proc_step_section__delete";

//PLAN BUILDER
export const ACTION_ADD_PLAN_BUILDER_SECTION = "plan_builder_section__add";
export const ACTION_ADD_PLAN_SECTION_WP_BUTTON = "plan_section_wp_button__add";
export const ACTION_SET_PLAN_WORKPACKAGES = "plan_workpackages__set";
export const ACTION_SET_PLAN_TASKS = "plan_tasks__set";
export const ACTION_SET_PLAN_WPS_AND_TASKS = "plan_wps_and_tasks__set";
export const ACTION_DELETE_WP_SECTION = "wp_section__delete";
export const ACTION_DELETE_PLAN_SECTION = "plan_section__delete";
export const ACTION_ADD_PLAN_WP = "plan_wp__add";
export const ACTION_ADD_PLAN_TASK = "plan_task__add";
// export const ACTION_DELETE_PLAN_WP = "plan_wp__delete";
export const ACTION_DELETE_PLAN_TASK = "plan_task__delete";
export const ACTION_SET_MISSING_WP_OR_TASK_NAME = "builder_missing_wp_or_task_name__set";

const statusMessageInitialValue = {
  type: null,
  message: null,
};

const statusMessageSuccessfulSave = {
  type: "success",
  message: "Saved",
};

const statusMessageError = {
  type: "error",
  message: "Invalid or missing fields. Please review and try again."
}

export const builderInitialState = {
  builderInfo: null,
  builderSections: null,
  activeForm: false,
  statusMessage: statusMessageInitialValue,
  builderInfoErrors: null,
  isSaving: null,
  loadedVersion: null,
  previousVersions: null,
  disableBuilder: false,
};

export const policyBuilderInitialState = {
  ...builderInitialState,
  referenceGroups: null,
  referenceSubGroups: null,
};

export const procedureBuilderInitialState = {
  ...builderInitialState,
  procElementSections: null,
  procSteps: null,
  referenceGroups: null,
  referenceSubGroups: null,
};

export const planBuilderInitialState = {
  ...builderInitialState,
  workpackages: null,
  tasks: null,
  tasksToDeleteOnCreate: null,
};

export default function builderReducer(state, action) {
  switch (action.type) {
    case ACTION_SET_ANY_BUILDER:
      return {
        ...state,
        ...action.payload,
      };

    case ACTION_SET_BUILDER_INFO:
      return {
        ...state,
        builderInfo: action.payload,
        activeForm: true,
        isSaving: null,
      };

    case ACTION_SET_BUILDER_INFO_ERRORS:
      return {
        ...state,
        builderInfoErrors: action.payload,
      };

    case ACTION_SET_BUILDER_IS_SAVING:
      return {
        ...state,
        statusMessage: statusMessageInitialValue,
        isSaving: "saving",
      };

    case ACTION_SET_BUILDER_ERROR:
      return {
        ...state,
        activeForm: false,
        isSaving: "saved",
        statusMessage: {
          ...statusMessageError,
          message: action.payload.displayMessage || statusMessageError.message
        },
      };

    case ACTION_SET_BUILDER_CRITICAL:
      return {
        ...state,
        statusMessage: defaultBuilderSaveError,
        isSaving: null,
        disableBuilder: true,
        activeForm: false,
      };

    case ACTION_SET_BUILDER_AFTER_SAVE:
      return {
        ...state,
        ...action.payload,
        statusMessage: statusMessageSuccessfulSave,
        activeForm: false,
        isSaving: "saved",
        tasksToDeleteOnCreate: null,
      };

    case ACTION_SET_BUILDER_STATUS_MESSAGE:
      return {
        ...state,
        statusMessage: action.payload,
      };

    case ACTION_SET_MISSING_HEADER_MESSAGE:
      return {
        ...state,
        statusMessage: missingSectionError,
        isSaving: null,
      };

    case ACTION_SET_BUILDER_LOAD_TEMPLATE:
      return {
        ...state,
        ...action.payload,
        builderInfoErrors: { Title: "Please enter a title." },
      };

    case ACTION_SET_BUILDER_EDIT_CURRENT:
      return {
        ...state,
        ...action.payload,
        builderInfoErrors: action.payload.builderInfoErrors
          ? { Version: "Please enter a version." }
          : {},
      };

    case ACTION_SET_BUILDER_COPY_PREVIOUS:
      return {
        ...state,
        ...action.payload,
        activeForm: true,
        builderInfoErrors: { Version: "Please enter a version." }
      };

    case ACTION_REPLACE_BUILDER_SECTION:
      return {
        ...state,
        builderSections: mergeReplaceByMetaId(
          state.builderSections,
          action.payload,
          "clientId"
        ),
        activeForm: true,
        isSaving: null,
      };

    case ACTION_REPLACE_SECTION_CONTENT:
      const sectionToUpdate = state.builderSections.find((section) => {
        return section._meta.clientId === action.payload.id;
      });
      return {
        ...state,
        builderSections: mergeReplaceByMetaId(
          state.builderSections,
          { ...sectionToUpdate, SectionContent: action.payload.content },
          "clientId"
        ),
        activeForm: true,
        isSaving: null,
      };

    case ACTION_DELETE_BUILDER_SECTION:
      const builderSectionsAfterDelete = deleteBuilderSection(
        [...state.builderSections],
        action.payload
      );
      return {
        ...state,
        builderSections: builderSectionsAfterDelete,
        activeForm: true,
        isSaving: null,
      };

    case ACTION_ADD_BUILDER_SECTION:
      const sectionsAfterAdd = reOrderedSectionsWithNewSection(
        [...state.builderSections],
        action.payload
      );
      return {
        ...state,
        builderSections: [...sectionsAfterAdd],
        activeForm: true,
        isSaving: null,
      };

    case ACTION_SET_BUILDER_AFTER_PUBLISH:
      return {
        ...state,
        statusMessage: action.payload,
        activeForm: false,
        isSaving: null,
        disableBuilder: true,
      };
    /*  POLICY BUILDER  */
    case ACTION_ADD_POLICY_BUILDER_SECTION:
      const updatedPolicyBuilderSections =
        reOrderedSectionsWithNewSectionPolicy(
          [...state.builderSections],
          action.payload
        );
      return {
        ...state,
        builderSections: [...updatedPolicyBuilderSections],
        activeForm: true,
        isSaving: null,
      };

    case ACTION_ADD_POLICY_REF_SECTIONS:
      // Policy Ref Sections are always a pair of sections attached at the end
      return {
        ...state,
        builderSections:
          state.builderSections?.length > 0
            ? [...state.builderSections, ...action.payload]
            : action.payload,
        activeForm: true,
        isSaving: null,
      };

    case ACTION_DELETE_POLICY_BUILDER_SECTION:
      const polBuilderSectionsAfterDelete = deletePolicyBuilderSection(
        [...state.builderSections],
        action.payload
      );
      return {
        ...state,
        builderSections: polBuilderSectionsAfterDelete,
        activeForm: true,
        isSaving: null,
      };

    case ACTION_UPDATE_REFERENCE_ITEM_STATUS:
      return {
        ...state,
        referenceSubGroups: action.payload,
        activeForm: true,
        isSaving: null,
      };

    case ACTION_SET_REFERENCE_SUBGROUPS:
      return {
        ...state,
        referenceSubGroups: action.payload,
      };

    /*  PROCEDURE BUILDER  */
    case ACTION_ADD_PROC_STEP_SECTION:
      const updatedProcSections = addProcStep(
        [...state.procSteps],
        action.payload
      );
      return {
        ...state,
        procSteps: [...updatedProcSections],
        activeForm: true,
        isSaving: null,
      };

    case ACTION_REPLACE_PROC_ELE_SECTION:
      return {
        ...state,
        procElementSections: mergeReplaceByMetaId(
          state.procElementSections,
          action.payload,
          "clientId"
        ),
        activeForm: true,
        isSaving: null,
      };

    case ACTION_REPLACE_PROC_ELE_CONTENT:
      const procSectionToUpdate = state.procElementSections.find((section) => {
        return section._meta.clientId === action.payload.id;
      });
      return {
        ...state,
        procElementSections: mergeReplaceByMetaId(
          state.procElementSections,
          { ...procSectionToUpdate, SectionContent: action.payload.content },
          "clientId"
        ),
        activeForm: true,
        isSaving: null,
      };

    case ACTION_REPLACE_PROC_STEP_SECTION:
      return {
        ...state,
        procSteps: mergeReplaceByMetaId(
          state.procSteps,
          action.payload,
          "clientId"
        ),
        activeForm: true,
        isSaving: null,
      };

    case ACTION_REPLACE_PROC_STEP_CONTENT:
      const procStepToUpdate = state.procSteps.find((section) => {
        return section._meta.clientId === action.payload.id;
      });
      return {
        ...state,
        procSteps: mergeReplaceByMetaId(
          state.procSteps,
          { ...procStepToUpdate, SectionContent: action.payload.content },
          "clientId"
        ),
        activeForm: true,
        isSaving: null,
      };

    case ACTION_DELETE_PROC_STEP_SECTION:
      const procSectionsAfterDelete = deleteProcStep(
        [...state.procSteps],
        action.payload
      );
      return {
        ...state,
        procSteps: procSectionsAfterDelete,
        activeForm: true,
        isSaving: null,
      };

    /*  PLAN BUILDER  */
    case ACTION_ADD_PLAN_BUILDER_SECTION:
      const updatedPlanBuilderSections = reOrderedSectionsWithAddedSectionPlan(
        [...state.builderSections],
        action.payload
      );
      return {
        ...state,
        builderSections: [...updatedPlanBuilderSections],
        activeForm: true,
        isSaving: null,
      };

    case ACTION_ADD_PLAN_SECTION_WP_BUTTON:
      const wpClickUpdatedSections = reOrderedSectionsViaWpButtonClick(
        [...state.builderSections],
        action.payload
      );
      return {
        ...state,
        builderSections: [...wpClickUpdatedSections],
        activeForm: true,
        isSaving: null,
      };

    case ACTION_SET_PLAN_WORKPACKAGES:
      return {
        ...state,
        workpackages: action.payload,
        activeForm: true,
        isSaving: null,
      };

    case ACTION_SET_PLAN_TASKS:
      return {
        ...state,
        tasks: action.payload,
        activeForm: true,
        isSaving: null,
      };

    case ACTION_SET_PLAN_WPS_AND_TASKS:
      return {
        ...state,
        workpackages: action.payload.workpackages,
        tasks: action.payload.tasks,
        activeForm: true,
        isSaving: null,
      };

    case ACTION_DELETE_WP_SECTION:
      const updatedAfterWpSectionDelete = sectionsAfterRemovingWpSection(
        [...state.builderSections],
        action.payload
      );
      return {
        ...state,
        builderSections: updatedAfterWpSectionDelete,
        workpackages: [],
        tasks: [],
        activeForm: true,
        isSaving: null,
      };

    case ACTION_DELETE_PLAN_SECTION:
      return {
        ...state,
        builderSections: sectionsAfterRemovingPlanSection(
          [...state.builderSections],
          action.payload
        ),
        activeForm: true,
        isSaving: null,
      };

    case ACTION_ADD_PLAN_TASK:
      return {
        ...state,
        tasks: tasksAfterAdd([...state.tasks], action.payload),
        activeForm: true,
        isSaving: null,
      };

    case ACTION_DELETE_PLAN_TASK:
      return {
        ...state,
        tasks: action.payload.tasks,
        tasksToDeleteOnCreate: state.tasksToDeleteOnCreate
          ? [
            ...state.tasksToDeleteOnCreate,
            ...action.payload.tasksToDeleteOnCreate,
          ]
          : null,
        activeForm: true,
        isSaving: null,
      };

    case ACTION_SET_MISSING_WP_OR_TASK_NAME:
      return {
        ...state,
        statusMessage: {
          type: "error",
          message: "Name is required for every Workpackage and Task.",
        },
        isSaving: null,
      };

    default:
      return state;
  }
}

export const mergeReplaceByMetaId = (source, payload, primaryKey) => {
  let didReplace = false;

  if (!payload._meta[primaryKey]) {
    console.error("Client Id of new section is missing");
  }
  const merged = [...source].map((current) => {
    if (!current._meta[primaryKey]) {
      console.error("Client Id is missing from existing section");
    }

    if (current._meta[primaryKey] === payload._meta[primaryKey]) {
      didReplace = true;
      return payload;
    }
    return current;
  });
  if (!didReplace) {
    return [...merged, payload];
  }
  return merged;
};

const reOrderedSectionsWithNewSection = (sections, payload) => {
  const existingSectionsWithUpdatedOrder = sections.map((item) => {
    return {
      ...item,
      Section_Order: item.Section_Order >= payload.Section_Order ? item.Section_Order + 1 : item.Section_Order,
    };
  });

  const sorted = [...existingSectionsWithUpdatedOrder, payload].sort(
    (a, b) => a.Section_Order - b.Section_Order
  );
  const updatedSections = sorted.map((sec, index) => {
    return { ...sec, Section_Order: index + 1 };
  });
  return updatedSections;
};
