export const taskStatuses = {
  NOT_STARTED: "Not Started",
  STARTED: "Started",
  CLOSED: "Closed",
  COMPLETED: "Completed",
}

export const taskAndWpIDFieldNames = {
  task: "Task_ID",
  workpackage: "Workpackage_ID"
}
