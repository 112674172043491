import { useMemo } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function useUrlSearchParams() {
  const location = useLocation();

  const [hash, searchParamString] = useMemo(() => (
    location.search ?
      [location.hash, location.search] :
      location.hash?.slice?.(1)?.split?.("?")
  ), [location.hash, location.search]);

  const searchParams = useMemo(() => (
    new URLSearchParams(searchParamString || "")
  ), [searchParamString]);

  return [searchParams, hash];
}
