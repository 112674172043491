export const AUTH_PATH_LOGIN = "/";
export const AUTH_PATH_VERIFY = "/verify";
export const AUTH_PATH_FORGOT = "/forgot";
export const AUTH_PATH_RESET = "/reset/:userId/:key";
export const AUTH_PATH_LOGOUT = "/logout";

export const ERROR_PATH_NOT_FOUND = "/404";
export const ERROR_PATH_CONTACT_ADMIN = "/contact-admin";

export const AUTH_PATHS_ALL = [
  AUTH_PATH_LOGIN,
  AUTH_PATH_VERIFY,
  AUTH_PATH_FORGOT,
  AUTH_PATH_RESET,
  AUTH_PATH_LOGOUT,
];

export const ERROR_PATHS_ALL = [
  ERROR_PATH_NOT_FOUND,
  ERROR_PATH_CONTACT_ADMIN
];

export const PUBLIC_PATHS_ALL = [
  ...AUTH_PATHS_ALL,
  ...ERROR_PATHS_ALL,
];
