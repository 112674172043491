import { useContext, useMemo } from "react";
import SubpageHeader from "components/pages/consentOrder/claims/shared/SubpageHeader.component";
import { createNodeCellParams, createNodeHeaderParams, createValueHeaderParams } from "components/utils/tables/utils/dataTable.utils";
import DataTable from "components/utils/tables/dataTable.component";
import moment from "moment";
import { DATETIME_DISPLAY_NUMERIC_LONG } from "constants/date.constants";
import CustomLink from "components/utils/link.component";
import { generatePath, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { PATH_COMPONENT_OBJECT } from "components/layout/constants/standardPaths.constants";
import { Box } from "@mui/system";
import DataTypeContext from "contexts/dataType.context";
import Loader from "components/utils/loader.components";

const TABLE_COLUMNS = [
  createNodeHeaderParams("cifid", "CIF ID", {
    align: "center",
    filter: false,
    sort: false,
    setCellProps: () => ({ align: "center" })
  }),
  createValueHeaderParams("changeType", "Change Type", {
    align: "center",
    filter: true,
    sort: false,
    setCellProps: () => ({ align: "center" })
  }),
  createValueHeaderParams("previousValue", "Previous Value", {
    align: "center",
    filter: false,
    sort: false,
    setCellProps: () => ({ align: "left" })
  }),
  createValueHeaderParams("newValue", "New Value", {
    align: "center",
    filter: false,
    sort: false,
    setCellProps: () => ({ align: "left" })
  }),
  createValueHeaderParams("changeDatetime", "Date of Change", {
    align: "center",
    filter: false,
    setCellProps: () => ({ align: "center" }),
    sortDescFirst: true
  })
];

export default function ContactInfoChangePage(props) {
  const { state } = props;
  const { customerInfoMapById } = useContext(DataTypeContext);
  const params = useParams();

  const claimsReviewPath = useMemo(() => (
    generatePath(PATH_COMPONENT_OBJECT, {
      ...params,
      componentObjectId: "claims-review-component-object"
    })
  ), [params]);

  const tableCells = useMemo(() => (
    state.customerCorrections?.map?.((correction) => {
      const { prevRowData, newRowData, changeDatetime } = correction;
      const cifId = correction._associations.Customer?.[0]?.cifId;

      return ({
        cifid: createNodeCellParams(
          cifId,
          cifId,
          (
            <Box marginTop={1}>
              <CustomLink
                to={`${claimsReviewPath}?cifId=${cifId}`}
                variant="routerLink"
              >
                {cifId}
              </CustomLink>
            </Box>
          )
        ),
        changeType: customerInfoMapById?.[newRowData?.customerInfoMapId]?.label,
        previousValue: prevRowData.value,
        newValue: newRowData.value,
        changeDatetime: (
          moment(changeDatetime).format(DATETIME_DISPLAY_NUMERIC_LONG)
        ),
      })
    })
  ), [state.customerCorrections, claimsReviewPath, customerInfoMapById]);

  return (
    <div>
      <SubpageHeader title="Customer Info Change Log" />
      {(!state.customerCorrections || !customerInfoMapById) ? (
        <Loader />
      ) : (
        <DataTable
          columns={TABLE_COLUMNS}
          data={tableCells}
          options={{
            filterType: "checkbox",
            fixedHeader: true,
            fixedSelectColumn: true,
            filter: true,
            pagination: true,
            rowsPerPage: 15,
            selectableRowsHideCheckboxes: true,
            viewColumns: false,
            tableBodyMaxHeight: "calc(60vh)",
            responsive: "simple"
          }}
        />
      )}
    </div>
  );
}
