import { setStateFetchEffect } from "utils/ajax.utils";
import AlertContext from "contexts/alert.context";
import useNumericParams from "hooks/useNumericParams";
import { useCallback, useEffect, useReducer, useState } from "react";
import alertReducer, { ACTION_SET_COMPONENT_OBJECT_ALERT_COUNT, ACTION_SET_PROGRAM_ALERT_COUNT, ACTION_SET_USER_ALERTS, alertInitialState } from "reducers/global/alert.reducer";
import UserService from "services/User.service";

/*
 * Loads and provides Alert reducer as global context for app
 */
export default function AlertContextProvider(props) {
  const { programId, componentObjectId } = useNumericParams();
  const { children } = props;
  const [state, dispatch] = useReducer(alertReducer, alertInitialState);
  const { userAlerts } = state;
  const [isResettingContext, setIsResettingContext] = useState(false)

  const resetAlerts = useCallback(() => {
    setIsResettingContext(true)
  },[])

  useEffect(function fetchValidIds() {
    if (userAlerts && !isResettingContext) {
      return;
    }
    setStateFetchEffect(
      UserService.getActiveCurrentUserAlerts(),
      ([ userAlertsResponse ]) => {
        dispatch({
          type: ACTION_SET_USER_ALERTS,
          payload: userAlertsResponse.payload
        })
      }
    );
    setIsResettingContext(false)
  }, [dispatch, userAlerts, isResettingContext]);

  useEffect(function setProgramAlertCount() {
    if ((!programId || !userAlerts) && !isResettingContext) {
      return;
    }
    const programAlerts = userAlerts.filter(alert => (
      alert.programId === programId
    ));
    dispatch({
      type: ACTION_SET_PROGRAM_ALERT_COUNT,
      payload: programAlerts.length
    });
    setIsResettingContext(false)
  }, [dispatch, programId, userAlerts, isResettingContext]);

  useEffect(function setComponentObjectAlertCount() {
    if ((!componentObjectId || !userAlerts) && !isResettingContext) {
      return;
    }
    const componentObjectAlerts = userAlerts.filter(alert => (
      alert.componentObjectId === componentObjectId
    ));
    dispatch({
      type: ACTION_SET_COMPONENT_OBJECT_ALERT_COUNT,
      payload: componentObjectAlerts.length
    });
    setIsResettingContext(false)
  }, [dispatch, componentObjectId, userAlerts, isResettingContext]);

  return (
    <AlertContext.Provider
      value={{
        dispatch,
        state,
        resetAlerts
      }}
    >
      {children}
    </AlertContext.Provider>
  )
}