import { createNodeCellParams, createNodeHeaderParams } from "components/utils/tables/utils/dataTable.utils";
import { useCallback, useMemo, useRef, useState } from "react";
import DataTable from "components/utils/tables/dataTable.component";
import { formatCash } from "utils/localization.utils";
import { DATE_FORMAT_DISPLAY_NUMERIC_SHORT } from "constants/date.constants";
import moment from "moment";
import LabelValuePair from "components/utils/labelValuePair.component";
import { makeStyles } from "mui-styles";
import TableCellClaim from "components/claims/table/TableCellClaim.component";
import { groupRowsByColumn, mapObjectArrayByKey } from "utils/arrayOfObjects.utils";
import ScrollTargetContainer from "components/shared/scroll/ScrollTargetContainer.component";
import { Box } from "@mui/material";
import DistributionDatasetService from "services/DistributionDataset.service";
import SearchResultsWrapper from "components/pages/consentOrder/claims/shared/SearchResultsWrapper.component";
import SubpageSearchbarHeader from "components/pages/consentOrder/claims/shared/SubpageSearchbarHeader.component";
import CustomLink from "components/utils/link.component";
import { generatePath, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { pluralize } from "utils/string.utils";
import classNames from "classnames";
import { H5 } from "components/utils/headerV2.component";

const useStyles = makeStyles(theme => ({
  claimPaymentListItem: {
    textAlign: "left",
    alignItems: "center",
    justifyContent: "space-between",
  },
  claimPaymentListItemPending: {
    opacity: theme.opacity.disabled
  },
  amountRow: {
    paddingTop: `${theme.typography.lineHeight.proportional1}rem`,
  },
  amountLoanNumber: {
    display: "block",
    marginTop: `${-1 * theme.typography.lineHeight.proportional1}rem`,
  },
  amountLoanDate: {
    fontWeight: theme.typography.fontWeightRegular
  },
  amountTotal: {
    paddingTop: 8
  }
}))

const TABLE_COLUMNS = [
  createNodeHeaderParams("gsLoanNumber", "Loan Number", {
    align: "left",
    filter: true,
  }),
  createNodeHeaderParams("remediationAmount", "Remediation Amounts", {
    align: "center",
    filter: false
  }),
  createNodeHeaderParams("paidAmount", "Claim Payment Amounts", {
    align: "center",
    filter: false
  }),
]
const CONSENT_ORDER_PROGRAM_NAME = "remediation-management";
const CLAIMS_REVIEW_PATH = `/home/program/${CONSENT_ORDER_PROGRAM_NAME}/claims/claims-review-component-object`

export default function ClaimPaymentsPage() {
  const classes = useStyles();
  const { programId } = useParams();

  const [distributionDatasets, setDistributionDatasets] = useState(null);
  const [hasSearchError, setHasSearchError] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [
    unfilteredDistributionCount,
    setUnfilteredDistributionCount
  ] = useState(null);

  const scrollTargetRef = useRef();

  const populationPath = useMemo(() => (
    generatePath(
      "/home/program/:programId/data-analysis/populations",
      { programId }
    )
  ), [programId]);

  const handleSearch = useCallback(async () => {
    try {
      setIsSearchLoading(true);
      const response = (
        await DistributionDatasetService.searchDistributionDatasetsWithClaim(
          searchQuery
        )
      );
      setDistributionDatasets(response.payload);
      setUnfilteredDistributionCount(response._meta?.unfilteredResultCount);
      setHasSearchError(false);
      if (scrollTargetRef.current) {
        scrollTargetRef.current.scrollIntoView();
      }
    } catch (error) {
      setHasSearchError(true);
      console.error(error);
    } finally {
      setIsSearchLoading(false);
    }
  }, [searchQuery]);

  const claimsById = useMemo(() => (
    mapObjectArrayByKey(
      distributionDatasets?.flatMap?.(distribution => (
        distribution._associations.Claim
      )) || [],
      "claimId"
    )
  ), [distributionDatasets]);

  const cifIdDistributionEntries = useMemo(() => (
    Object.entries(groupRowsByColumn(distributionDatasets || [], "cifId"))
      .filter(([_cifId, distributions]) => (
        distributions?.some?.(distribution => (
          distribution?._associations?.Claim?.length
        ))
      ))
  ), [distributionDatasets]);

  const tableCells = useMemo(() => {
    if (!cifIdDistributionEntries) {
      return [];
    }
    return cifIdDistributionEntries.map(([cifId, distributions]) => {
      const claims = distributions.flatMap(distribution => (
        distribution?._associations?.Claim
      ));
      const claimPayments = claims.flatMap(claim => (
        claim._associations.Payment
      ));
      const pendingClaimPayments = claimPayments.filter(payment => (
        payment.status === "Pending"
      ));
      const paidClaimPayments = claimPayments.filter(payment => (
        payment.status === "Paid"
      ));

      const gsLoanNumbers = distributions.map(distribution => (
        distribution.gsLoanNumber
      )).join("|");

      const remediationAmountTotal = (
        distributions.reduce((acc, distributionDS) => {
          const floatValue = parseFloat(
            distributionDS.remediationAmount.replace(/[$,]/g, "")
          )
          return (parseFloat(acc) + floatValue)
        }, 0)
      );
      const totalClaimPayments = paidClaimPayments.reduce((acc, payment) => (
        parseFloat(acc) +
        parseFloat(payment.amount.replace(/[$,]/g, ""))
      ), 0)

      const remediationSearchString = (
        remediationAmountTotal +
        distributions.map(distribution => (
          formatCash(distribution.remediationAmount, true) +
          moment(distribution.originationDate)
            .format(DATE_FORMAT_DISPLAY_NUMERIC_SHORT)
        ))
      );
      const paidAmountSearchString = (
        totalClaimPayments +
        paidClaimPayments.map((payment) => (
          moment(payment.paidDate).format(DATE_FORMAT_DISPLAY_NUMERIC_SHORT) +
          formatCash(payment.amount, true)
        ))
      );

      return {
        gsLoanNumber: createNodeCellParams(
          `${gsLoanNumbers}|${cifId}`,
          `${gsLoanNumbers}|${cifId}`,
          (
            <TableCellClaim
              claims={claims}
              componentObjectPath={CLAIMS_REVIEW_PATH}
            />
          )
        ),
        remediationAmount: createNodeCellParams(
          remediationAmountTotal,
          remediationSearchString,
          (
            <>
              {distributions.map((distribution) => (
                <Box
                  className={classes.amountRow}
                  key={distribution.distributionDatasetId}
                >
                  <LabelValuePair
                    label={(
                      <>
                        <H5
                          color="tertiary"
                          className={classes.amountLoanNumber}
                        >
                          {distribution.gsLoanNumber}
                        </H5>
                        <span className={classes.amountLoanDate}>
                          Distributed {
                            moment(distribution.originationDate).format(
                              DATE_FORMAT_DISPLAY_NUMERIC_SHORT
                            )
                          }
                        </span>
                      </>
                    )}
                    value={formatCash(distribution.remediationAmount, true)}
                    className={classes.claimPaymentListItem}
                  />
                </Box>
              ))}
              <LabelValuePair
                label="Total"
                value={formatCash(remediationAmountTotal, true)}
                className={
                  classNames(classes.amountTotal, classes.claimPaymentListItem)
                }
              />
            </>
          )
        ),
        paidAmount: createNodeCellParams(
          totalClaimPayments,
          paidAmountSearchString,
          (
            <>
              {pendingClaimPayments.map(payment => (
                <Box
                  className={classes.amountRow}
                  key={payment.paymentId}
                >
                  <LabelValuePair
                    label={(
                      <>
                        <H5
                          color="tertiary"
                          className={classes.amountLoanNumber}
                        >
                          {claimsById[payment.claimId]
                            ?._derivedData?.ClaimData?.gsLoanNumber}
                        </H5>
                        <span className={classes.amountLoanDate}>
                          Pending Payment
                        </span>
                      </>
                    )}
                    value={formatCash(payment.amount, true)}
                    className={
                      classNames(
                        classes.claimPaymentListItem,
                        classes.claimPaymentListItemPending
                      )
                    }
                  />
                </Box>
              ))}
              {paidClaimPayments.map(payment => (
                <Box
                  className={classes.amountRow}
                  key={payment.paymentId}
                >
                  <LabelValuePair
                    label={(
                      <>
                        <H5
                          color="tertiary"
                          className={classes.amountLoanNumber}
                        >
                          {claimsById[payment.claimId]
                            ?._derivedData?.ClaimData?.gsLoanNumber}
                        </H5>
                        <span className={classes.amountLoanDate}>
                          Paid ${moment(payment.paidDate).format(
                            DATE_FORMAT_DISPLAY_NUMERIC_SHORT
                          )}
                        </span>
                      </>
                    )}
                    value={formatCash(payment.amount, true)}
                    className={classes.claimPaymentListItem}
                  />
                </Box>
              ))}
              <LabelValuePair
                label="Total Paid"
                value={formatCash(totalClaimPayments, true)}
                className={
                  classNames(classes.amountTotal, classes.claimPaymentListItem)
                }
              />
            </>
          )
        ),
      }
    })
  }, [classes, cifIdDistributionEntries, claimsById]);

  return (
    <ScrollTargetContainer
      scrollTargetRef={scrollTargetRef}
    >
      <SubpageSearchbarHeader
        title="Claim Payments"
        formName="claim-payments"
        placeholder="Search by CIF ID, Loan Number, Claimant Name or Email"
        disabled={searchQuery.length < 2 || isSearchLoading}
        onChange={event => setSearchQuery(event.target.value)}
        onSubmit={handleSearch}
      />
      <Box paddingTop={4}>
        <SearchResultsWrapper
          error={hasSearchError}
          loading={isSearchLoading}
          results={distributionDatasets}
          emptyMessage={(
            !unfilteredDistributionCount ? undefined : (
              <div>
                <p>No results were found.</p>
                <p>However, your search matches&nbsp;
                  <CustomLink to={populationPath} variant="routerLinkBold">
                    {unfilteredDistributionCount}&nbsp;
                    {pluralize("Distribution", unfilteredDistributionCount)}
                  </CustomLink>
                  &nbsp;without any associated Claims.
                </p>
              </div>
            )
          )}
        >
          <DataTable
            columns={TABLE_COLUMNS}
            data={tableCells}
            options={{
              filterType: "checkbox",
              fixedHeader: true,
              fixedSelectColumn: true,
              filter: true,
              pagination: true,
              rowsPerPage: 15,
              selectableRowsHideCheckboxes: true,
              viewColumns: false,
              tableBodyMaxHeight: "calc(60vh)",
              responsive: "simple"
            }}
          />
        </SearchResultsWrapper>
      </Box>
    </ScrollTargetContainer>
  )
}