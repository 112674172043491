import { isDevelopmentEnv } from "core/environment";


export function findRedirectComponentObjectName(
  programComponent,
  componentObjects
) {
  if (!componentObjects.length && isDevelopmentEnv) {
    throw new Error(
      `No ComponentObjects found for Redirect ProgramComponent
      '${programComponent.programComponentId}'.`
    );
  }
  const componentObjectIdTypeRef = programComponent.typeRef;
  if (componentObjectIdTypeRef) {
    const match = componentObjects.find(componentObject => (
      componentObject.componentObjectId === componentObjectIdTypeRef
    ));
    if (match) {
      return match.name;
    } else if (isDevelopmentEnv) {
      throw new Error(
        `Unable to find ComponentObject match for Redirect TypeRef
        value '${componentObjectIdTypeRef}'.`
      );
    }
  }
  if (componentObjects.length > 1 && isDevelopmentEnv) {
    const possibleIds = (
      componentObjects.map(({ componentObjectId }) => componentObjectId)
    );
    throw new Error(
      `Ambiguous ProgramComponent Redirect. Found ComponentObjects: ` +
      `'${possibleIds}'.`
    )
  }
  return componentObjects[0].name;
}
