import { setStateFetchEffect } from "utils/ajax.utils";
import { useEffect, useMemo, useState } from "react";
import DataTypeContext from "contexts/dataType.context";
import { mapObjectArrayByKey } from "utils/arrayOfObjects.utils";
import Loader from "components/utils/loader.components";
import DataTypeService from "services/DataType.service";


export default function DataTypeContextProvider(props) {
  const { children } = props;

  const [customerInfoMap, setCustomerInfoMap] = useState(null);

  const customerInfoMapById = useMemo(() => (
    mapObjectArrayByKey(customerInfoMap, "customerInfoMapId")
  ), [customerInfoMap]);

  useEffect(function fetchDataTypes() {
    setStateFetchEffect(
      DataTypeService.getCustomerInfoMap(),
      ([ customerInfoMapResponse ]) => {
        setCustomerInfoMap(customerInfoMapResponse.payload)
      }
    );
  }, []);

  if (!customerInfoMap) {
    return (
      <Loader />
    );
  }
  return (
    <DataTypeContext.Provider
      value={{ customerInfoMap, customerInfoMapById }}
    >
      {children}
    </DataTypeContext.Provider>
  )
}
