import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "mui-styles";
import { FormControlLabel, Checkbox as MUICheckbox } from "@mui/material";
import { isReadOnly as getIsReadOnly } from "utils/roles.utils";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.secondary,
    userSelect: "none"
  },
  colorTeal: {
    color: theme.palette.teal.dark,
    "& .MuiIconButton-label": {
      color: theme.palette.teal.main,
    },
  },
  readOnly: {
    "& .MuiInputBase-input.Mui-disabled": {
      color: "#000"
    }
  },
  noMargin: {
    margin: 0,
  },
}));

export const COLOR_DEFAULT = "default";
export const COLOR_TEAL = "teal";


/*
 * Main difference is that this is compatible with FormData submission
 *   and <Form /> and its autosave handling
 */
const CheckboxV2 = ({
  name,
  label,
  children,
  checked,
  defaultChecked,
  disabled,
  readOnly,
  onAutosave,
  onChange,
  onClick,
  color,
  size,
  className,
  labelClassName,
  ...muiProps
}) => {
  const classes = useStyles();
  const isReadOnly = useMemo(() => readOnly ?? getIsReadOnly(), [readOnly]);

  const [controlledValue, setControlledValue] = useState(
    checked || defaultChecked || false
  );

  const handleChange = useCallback((event, ...args) => {
    const { checked: isChecked } = event.target;
    setControlledValue(!!isChecked);
    onChange?.(event, !!isChecked, ...args);
    onAutosave?.(name, !!isChecked, event);
  }, [name, onAutosave, onChange]);

  return (
    <FormControlLabel
      control={(
        <MUICheckbox
          checked={checked}
          defaultChecked={defaultChecked}
          onChange={handleChange}
          name={name}
          size={size}
          value={controlledValue}
          data-cy={`checkbox-${name}`}
          className={classNames(
            !disabled && color === COLOR_TEAL && classes.colorTeal,
          )}
          {...muiProps}
        />
      )}
      label={children || label}
      classes={{
        label: classNames(classes.label, labelClassName),
        root: classNames(
          classes.noMargin,
          isReadOnly && classes.readOnly,
          className
        ),
        disabled: classes.disabled
      }}
      onClick={onClick}
      disabled={disabled || isReadOnly}
      readOnly={isReadOnly}
    />
  );
};

export default CheckboxV2;
