import React, { useState, useRef, useMemo } from "react";
import { useHistory } from "react-router";
import { makeStyles } from "mui-styles";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Grid,
  Popover,
} from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Logo from "components/utils/logo.component.js";
import HeaderAlert from "components/shared/alerts/headerAlert.component";
import InlineSVGIcons from "components/utils/inlineSVGIcons.component.js";
// import { MainSearchBar } from "components/search/searchBar.component";
// import CustomLink from "components/utils/link.component";
// import { isAdmin } from "utils/roles.utils";
import AlertsContainer from "components/shared/alerts/alertsContainer.component";
import AlertsFeed from "components/shared/alerts/alertsFeed.component";
import companyLogo from "assets/images/rms-logo-white.png";
// import PortfolioService from "services/Portfolio.service";
import { currentUserInfo } from "services/util/authSession.utils";
import { isGlobalUser } from "utils/roles.utils";
import { alertsFeedVariants } from "components/shared/alerts/constants/alerts.constants";

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: theme.layout.height.header,
    zIndex: theme.zIndex.drawer + 1,
    background: (
      `linear-gradient(
        180deg, rgba(23,93,181,1) 0%, rgba(23,93,181,1) 24%,
        rgba(17,56,108,1) 68%, rgba(12,41,79,1) 100%
      )`
    ),
  },
  navBarWrapper: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  logoCell: {
    display: "flex",
    alignItems: "center",
    maxWidth: 250,
    justifyContent: "center",
    [theme.breakpoints.down("lg")]: {
      maxWidth: 200,
    },
  },
  logoContainer: {
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  logoWrapper: {
    alignItems: "center",
    display: "flex",
    height: 50,
  },
  controlsCell: {
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: 1,
    alignItems: "center",
  },
  iconControls: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    minWidth: 130,
  },
  iconControlWrapper: {
    marginLeft: 12,
    marginRight: 12,
  },
  customIconWrapper: {
    marginLeft: 5,
    height: 20,
    display: "flex",
  },
  icon: {
    fill: "white",
  },
  arrowIconWrapper: {
    marginLeft: 5,
  },
  arrowIcon: {
    height: 22,
    width: 22,
    fontSize: theme.typography.h2.fontSize,
    fill: "white",
  },
  menuItem: {
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  const [anchorEl2, setAnchorEl2] = useState(null);
  // const { programId } = useParams();
  const history = useHistory();
  const alertRef = useRef(null);
  const [alertAnchorEl, setAlertAnchorEl] = useState(null);
  // const [input, setInput] = useState("");
    // const [logoId, setLogoId] = useState(null);
  const currentUser = useMemo(currentUserInfo, []);
  // const isAdminRole = useMemo(isAdmin, []);
  const isGlobalUserRole = useMemo(isGlobalUser, []);

  // const searchHandler = (event) => {
  //   setInput(event.target.value);
  // };

  const handleCloseAlert = () => {
    setAlertAnchorEl(null);
  };

  const openAlerts = () => {
    setAlertAnchorEl(alertRef.current);
  };

  // const handleSearchSubmit = (event) => {
  //   event.preventDefault();
  //   if (input.length === 0) {
  //     if (programComponentId) {
  //       history.push(`/search/program/${programId}/${programComponentId}`);
  //     } else {
  //       history.push(`/search/program/${programId}`);
  //     }
  //   } else if (programComponentId) {
  //     history.push(
  //       `/search/${programId}/${programComponentId}/search?keyword=${input}`
  //     );
  //   } else {
  //     history.push(`/search/${programId}/search?keyword=${input}`);
  //   }
  //   setInput("");
  // };

  // useEffect(function fetchCurrentHoldingCompanyLogo() {
  //   if (holdingId) {
  //     return setStateFetchEffect(
  //       PortfolioService.getHoldingCompanyDemographicsByHoldingID(holdingId),
  //       ([response]) => {
  //         setLogoId(response?.payload?.[0]?.Holder_Logo)
  //       }
  //     );
  //   } else {
  //     setLogoId(null);
  //   }
  // }, [programId, holdingId])

  const handleClick = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl2(null);
  };

  const logout = () => {
    history.push("/logout");
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.navBarWrapper}>
        <Grid item xs={6} md={3} lg={3} xl={3} className={classes.logoCell}>
          <div className={classes.logoContainer}>
            <div className={classes.logoWrapper}>
              <Logo
                logo64Prop={companyLogo}
                variant="mainHeader"
              />
            </div>
          </div>
        </Grid>
        <Grid item className={classes.controlsCell}>
          {/* Todo: Add search bar if needed for COMS */}
          {/* {!!isGlobalUserRole && (
            <MainSearchBar
              changeHandler={searchHandler}
              input={input}
              handleSubmit={handleSearchSubmit}
              mainSearch
            />
          )} */}
          <div className={classes.iconControls}>
            {/* === ADMIN === */}
            {/* {!!isAdminRole && (
              <div className={classes.iconControlWrapper}>
                <CustomLink href={`/admin/program/${programId}`} test="admin">
                  <div className={classes.customIconWrapper}>
                    <InlineSVGIcons
                      variant="settingsHeavy"
                      className={classes.icon}
                    />
                  </div>
                </CustomLink>
              </div>
            )} */}
            {/* === ALERTS === */}
            {!!isGlobalUserRole && (
              <>
                <div className={classes.iconControlWrapper} ref={alertRef}>
                  <HeaderAlert openAlerts={openAlerts} />
                </div>
                <Popover
                  open={Boolean(alertAnchorEl)}
                  anchorEl={alertAnchorEl}
                  onClose={handleCloseAlert}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <AlertsContainer test="header-alerts-container" variant="feedView">
                    <AlertsFeed
                      variant={alertsFeedVariants.HEADER}
                      maxHeight={455}
                      testProp="header"
                      handleCloseAlert={handleCloseAlert}
                    />
                  </AlertsContainer>
                </Popover>
              </>
            )}
            {/* === USER === */}
            <div>
              <IconButton
                disableRipple
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                aria-labelledby="mui-component-logout"
                onClick={handleClick}
                data-cy="userIcon-btn"
              >
                <div className={classes.customIconWrapper}>
                  <InlineSVGIcons variant="userHeavy" className={classes.icon} />
                </div>
                <div className={classes.arrowIconWrapper}>
                  <KeyboardArrowDownRoundedIcon className={classes.arrowIcon} />
                </div>
              </IconButton>
              <Menu
                className={classes.root}
                id="menu-appbar"
                anchorEl={anchorEl2}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                open={Boolean(anchorEl2)}
              >
                <MenuItem disabled divider>
                  {currentUser.name}
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  aria-labelledby="mui-list-item-logout"
                  onClick={logout}
                  onClose={handleClose}
                  data-cy="menuItem-logout"
                >
                  Logout
                </MenuItem>
              </Menu>
            </div>
          </div>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
