import CustomLink from "components/utils/link.component";
import AttachmentPasswordViewer from "components/claims/documents/attachmentPasswordViewer.component";
import { Box } from "@mui/material";
import DownloadFileIconButton from "components/claims/documents/downloadFileIconButton.component";
import { ellipsize } from "utils/string.utils";

const DocCell = (props) => {
  const {
    documentData,
    copiedPasswordDocId,
    setCopiedPasswordDocId,
    handleDownloadDocument,
    setAttachmentToView,
  } = props;
  const hasDescriptionPassword = !!documentData.descriptionPassword;

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" alignItems="center">
        {hasDescriptionPassword ? (
          <AttachmentPasswordViewer
            documentData={documentData}
            copiedPasswordDocId={copiedPasswordDocId}
            setCopiedPasswordDocId={setCopiedPasswordDocId}
          />
        ) : (
          <Box width={30} />
        )}
        <CustomLink onClick={() => setAttachmentToView(documentData)}>
          {ellipsize(documentData.fileName, 50)}
        </CustomLink>
      </Box>
      <DownloadFileIconButton
        onClick={() => handleDownloadDocument(documentData)}
      />
    </Box>
  );
};

export default DocCell;
