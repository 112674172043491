import { setStateFetchEffect } from "utils/ajax.utils";
import WorkflowTemplateContext from "contexts/workflowTemplate.context";
import { useEffect, useState } from "react";
import WorkflowService from "services/Workflow.service";

/*
 * Loads and provides wfTemplate data as program-based context for app
 */
export default function WorkflowTemplateContextProvider(props) {
  const { children } = props;

  const [workflowTemplates, setWorkflowTemplates] = useState(null);

  useEffect(function fetchWorkflowTemplates() {
    if (workflowTemplates) {
      return;
    }
    setStateFetchEffect(
      WorkflowService.getAllWfTemplates(),
      ([ workflowTemplatesResponse ]) => {
        setWorkflowTemplates(workflowTemplatesResponse.payload);
      }
    );
  }, [workflowTemplates]);

  return (
    <WorkflowTemplateContext.Provider value={workflowTemplates}>
      {children}
    </WorkflowTemplateContext.Provider>
  );
}
