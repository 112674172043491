import { convertToFloat } from "utils/number.utils";


const numberFormatter = new Intl.NumberFormat("en-us");

export const formatDisplayNumber = number => (
  isNaN(number) ? number : numberFormatter.format(number)
);

export const formatCash = (value, displayCents = false) => {
  const formatOptions = { style: "currency", currency: "USD" };
  if (displayCents) {
    formatOptions.minimumFractionDigits = 2;
  } else {
    formatOptions.maximumFractionDigits = 0;
  }
  const formatter = new Intl.NumberFormat("en-US", formatOptions)
  return formatter.format(convertToFloat(value));
};
