import { Switch } from "react-router-dom/cjs/react-router-dom.min";
import Forgot from "components/pages/auth/forgot.component";
import Login from "components/pages/auth/login.component";
import Logout from "components/pages/auth/logout.component";
import Reset from "components/pages/auth/reset.component";
import Verify from "components/pages/auth/verify.component";
import { AUTH_PATH_FORGOT, AUTH_PATH_LOGIN, AUTH_PATH_LOGOUT, AUTH_PATH_RESET, AUTH_PATH_VERIFY } from "components/layout/constants/publicPaths.constants";
import ContactAdmin from "components/pages/auth/contactAdmin.component";
import NotFound from "components/pages/auth/notFound.component";
import { ERROR_PATH_CONTACT_ADMIN, ERROR_PATH_NOT_FOUND } from "components/layout/constants/publicPaths.constants";
import { Route, Redirect } from "react-router-dom";


export default function PublicRoutes() {
  return (
    <Switch>
      <Route exact path={AUTH_PATH_LOGIN} key="login">
        <Login />
      </Route>
      <Route exact path={AUTH_PATH_VERIFY} key="verify">
        <Verify />
      </Route>
      <Route exact path={AUTH_PATH_FORGOT} key="forgot">
        <Forgot />
      </Route>
      <Route exact path={AUTH_PATH_RESET} key="reset">
        <Reset />
      </Route>
      <Route exact path={AUTH_PATH_LOGOUT} key="logout">
        <Logout />
      </Route>

      <Route exact path={ERROR_PATH_CONTACT_ADMIN} key="contact">
        <ContactAdmin />
      </Route>
      <Route exact path={ERROR_PATH_NOT_FOUND} key="not-found">
        <NotFound />
      </Route>
      <Redirect to={ERROR_PATH_NOT_FOUND} key="not-found-redirect" />
    </Switch>
  );
}
