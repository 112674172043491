import ComsCard from "components/utils/cards/comsCard.component";
import LabelValuePair from "components/utils/labelValuePair.component";
import { makeStyles } from "mui-styles";
import { formatDisplayNumber } from "utils/localization.utils";

const useStyles = makeStyles(theme => ({
  labelValuePair: {
    justifyContent: "space-between",
    whiteSpace: "pre-wrap"
  }
}));


export default function RemediationStatsCard(props) {
  const { title, statsValues, statsLabels, path } = props;
  const classes = useStyles();

  return (
    <ComsCard title={title} linkPath={path}>
      {statsLabels.map(labelData => (
        <LabelValuePair
          label={labelData.label}
          value={formatDisplayNumber(statsValues?.[labelData.name]?.count)}
          className={classes.labelValuePair}
          isLoading={!statsValues}
          key={labelData?.name}
        />
      ))}
    </ComsCard>
  );
}
