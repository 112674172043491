import React from "react";
import { Toolbar } from "@mui/material";
import CustomContainer from "components/utils/container.component";
import PageHeader from "components/utils/pageHeader.component";
import UserMgmtCentral from "components/pages/admin/userMgmtCentral.component";
import TitleMuiIcon from "components/utils/titleMuiIcon.component";
import { Redirect } from "react-router-dom";
import { isAdmin } from "utils/roles.utils";

const AdminPage = () => {
  if (!isAdmin()) {
    return <Redirect to="/home" />;
  }
  return (
    <CustomContainer>
      <Toolbar />
      <PageHeader
        title="Admin Page"
        titleOnly
        startIcon={<TitleMuiIcon variant="admin" />}
      />
      <UserMgmtCentral />
    </CustomContainer>
  );
};

export default AdminPage;
