import AxiosService from "./base/Axios.service";
import authHeader from "services/util/authHeader.util";


class SearchServiceBase {

  getByKeyword(keyword) {
    return AxiosService.get(
      `/search/search?keyword=${keyword}`,
      { headers: authHeader() }
    );
  }

  getTasksWorkpackagesByKeyword(keyword) {
    return AxiosService.get(
      `/search/searchTasksWorkpackages?keyword=${keyword}`,
      { headers: authHeader() }
    );
  }
}

const SearchService = new SearchServiceBase();
export default SearchService;
