import { Box, Paper } from "@mui/material";
import classNames from "classnames";
import Tooltip from "components/utils/tooltip.component";
import { makeStyles } from "mui-styles";
import { useMemo } from "react";

const CONNECTOR_X_MIN_WIDTH = 20;

const useStyles = makeStyles((theme) => ({
  connectorBase: {
  },
  connectorGridItem: {
    display: "flex",
    justifyContent: "stretch",
    width: "100%",
    alignItems: "center",
    position: "relative"
  },
  connectorGridItemBottom: {
    height: "100%"
  },
  connectorSpacingItem: {
    visibility: "hidden"
  },
  icon: {
    marginRight: 10,
  },
  label: {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.lineHeight.proportional2
  },
  // gridTemplateColumns set dynamically by inline styles
  grid: {
    display: "grid",
    height: "inherit",
    width: "100%",
    fontWeight: theme.typography.body2.fontWeight,
  },
  gridAutoHeight: {
    gridTemplateRows: "auto max-content",
    alignItems: "center",
  },
  gridFixedHeight: {
    alignItems: "start",
    gridTemplateRows: "max-content auto",
  },
  paper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 16
  },
  paperFixedHeight: {
    flex: 1,
  },
  paperWithTooltip: {
    cursor: "default"
  },
  itemSubsection: {
    marginBottom: "auto",
    paddingTop: 10,
  },
}));

const TOOLTIP_DELAY_MS = 1000;


export default function FlowchartMultiRowItem(props) {
  const classes = useStyles();
  const {
    children,
    classes: classesProp = {},
    connectors,
    height,
    IconComponent,
    item,
    paperRef,
    tooltipPlacement
  } = props;
  const { label, tooltip } = item;

  const minWidth = useMemo(() => {
    let output = 150;
    if (IconComponent) {
      output = output + 40;
    }
    if (!connectors?.left) {
      output = output + CONNECTOR_X_MIN_WIDTH;
    }
    if (!connectors?.right) {
      output = output + CONNECTOR_X_MIN_WIDTH;
    }
    return output;
  }, [connectors, IconComponent]);

  const gridTemplateColumns = useMemo(() => {
    let template = `minmax(${minWidth}px, 4fr)`;
    if (connectors?.left) {
      template = `minmax(${CONNECTOR_X_MIN_WIDTH}px, 1fr) ${template}`;
    }
    if (connectors?.right) {
      template = `${template} minmax(${CONNECTOR_X_MIN_WIDTH}px, 1fr)`;
    }
    return template;
  }, [connectors, minWidth]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      flex="1"
      height="100%"
      position="relative"
      zIndex={1}
    >
      <Box
        className={
          classNames(
            classes.grid,
            !height && classes.gridAutoHeight,
            !!height && classes.gridFixedHeight
          )
        }
        gridTemplateColumns={gridTemplateColumns}
      >
        {!!connectors?.left && (
          <Box
            className={classes.connectorGridItem}
            minHeight={height || "100%"}
          >
            {connectors?.left === true ? " " : connectors.left}
          </Box>
        )}
        <Tooltip
          title={tooltip}
          placement={tooltipPlacement}
          enterDelay={TOOLTIP_DELAY_MS}
        >
          <Box minHeight={height} display="flex" ref={paperRef}>
            <Paper
              className={
                classNames(
                  classes.paper,
                  classes.connectorBase,
                  classesProp.paper,
                  !!height && classes.paperFixedHeight,
                  tooltip && classes.paperWithTooltip
                )
              }
              elevation={1}
            >
              {!!IconComponent && (
                <IconComponent
                  className={classNames(classes.icon, classesProp.icon)}
                />
              )}
              <span className={classNames(classes.label, classesProp.label)}>
                {label}
              </span>
            </Paper>
          </Box>
        </Tooltip>
        {!!connectors?.right && (
          <Box
            className={classes.connectorGridItem}
            minHeight={height || "100%"}
          >
            {connectors?.right === true ? " " : connectors.right}
          </Box>
        )}
        <>
          {connectors?.bottomLeft ? (
            <div
              className={
                classNames(
                  classes.connectorGridItem,
                  classes.connectorGridItemBottom
                )
              }
            >
              {connectors.bottomLeft}
            </div>
          ) : (
            !!connectors?.left && (
              <div className={classes.connectorSpacingItem}>
                {connectors.left}
              </div>
            )
          )}
          <div
            className={
              classNames(classes.itemSubsection, classes.connectorBase)
            }
          >
            {children}
          </div>
          {connectors?.bottomRight ? (
            <div
              className={
                classNames(
                  classes.connectorGridItem,
                  classes.connectorGridItemBottom
                )
              }
            >
              {connectors.bottomRight}
            </div>
          ) : (
            !!connectors?.right && (
              <div className={classes.connectorSpacingItem}>
                {connectors.right}
              </div>
            )
          )}
        </>
      </Box>
    </Box>
  );
}
