// packages
import React from "react";
// component imports
import AuthFormWrapper from "components/pages/auth/shared/authFormWrapper.component";
import VerifyForm from "components/pages/auth/verifyForm.component";
import AuthBackground from "components/pages/auth/shared/authBackground.component";

const Verify = () => {

  return (
    <AuthBackground>
      <AuthFormWrapper>
        <VerifyForm />
      </AuthFormWrapper>
    </AuthBackground>
  );
};

export default Verify;
