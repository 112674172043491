import { removeObjInArray } from "utils/arrayOfObjects.utils";

export const flattenAssociations = (payload, associationName) => {
  if (!payload) {
    return [];
  }
  const children = [].concat(payload).flatMap(association => (
    flattenAssociations(
      association._associations?.[associationName],
      associationName
    )
  ));
  return payload.concat(children);
};

export const mergeReplace = (source, payload, primaryKey) => {
  let didReplace = false;
  const merged = [...source].map(current => {
    if (current[primaryKey] === payload[primaryKey]) {
      didReplace = true;
      if (!payload._associations && current._associations) {
        payload._associations = current._associations;
      }
      return payload;
    }
    return current;
  });
  if (!didReplace) {
    return [...merged, payload];
  }
  return merged;
};

export const mergeReplaceList = (source, payload, primaryKey) => {
  const actionItemsByKey = payload.reduce((accumulator, item) => ({
    ...accumulator,
    [item[primaryKey]]: item
  }), {});
  return (source || [])
    .filter(item => item[primaryKey])
    .map(current => {
      const match = actionItemsByKey[current[primaryKey]];
      if (match) {
        delete actionItemsByKey[current[primaryKey]];
        if (!match._associations && current._associations) {
          match._associations = current._associations;
        }
        return match;
      }
      return current;
    })
    .concat(Object.values(actionItemsByKey));
};

// Replace entire set of items by a Foreign Key while maintaining sort order
export const mergeSetForFk = (source, payload, foreignKey) => {
  if (!payload?.length) {
    throw new Error(
      "No replacements found for mergeSetForFk. Use mergeDeleteForFk instead."
    )
  }
  const payloadReplacements = [...payload];
  const foreignKeyValue = payload[0][foreignKey];
  if (!foreignKeyValue) {
    console.error(
      `No foreign key match found in payload for ${foreignKey}.`,
      payload[0]
    );
  }
  return source.map(item => {
    if (item[foreignKey] === foreignKeyValue) {
      return payloadReplacements.shift() || null;
    }
    return item;
  })
    .filter(item => item)
    .concat(payloadReplacements);
};

export const mergeDeleteForFk = (source, foreignKey, foreignKeyValue) => (
  removeObjInArray(source, foreignKey, foreignKeyValue)
)

// Source and association MUST share the `primaryKey` field for this to work
export const mergeAssociationReplace = (
  source, payload, primaryKey, associationName, associationPrimaryKey
) => (
  mergeParentKeyAssociationReplace(
    source,
    payload,
    primaryKey,
    primaryKey,
    associationName,
    associationPrimaryKey
  )
)

// Source and association MUST share the `primaryKey` field for this to work
export const mergeAssociationReplaceList = (
  source, payload, associationName, associationPrimaryKey
) => {
  return source.map(sourceItem => {
    const sourceAssociations = sourceItem._associations[associationName];
    if (sourceAssociations) {
      const replaced = mergeReplaceList(
        sourceAssociations,
        payload,
        associationPrimaryKey
      );
      sourceItem._associations[associationName] = replaced;
    }
    return sourceItem;
  });
};

export const mergeParentKeyAssociationReplace = (
  source, payload, primaryKey, parentForeignKey,
  associationName, associationPrimaryKey
) => (
  source && source.map(sourceItem => {
    if (sourceItem[primaryKey] === payload[parentForeignKey]) {
      if (!sourceItem._associations) {
        throw new Error(
          "Source item must include _associations attribute " +
          "from server to merge associations"
        );
      }
      if (!sourceItem._associations[associationName]) {
        sourceItem._associations[associationName] = [];
      }
      sourceItem._associations[associationName] = [
        ...mergeReplace(
          sourceItem._associations[associationName],
          payload,
          associationPrimaryKey
        )
      ];
    }
    return { ...sourceItem };
  })
);

export const mergeRecursiveReplace = (
  source, payload, tableName, primaryKey, associationName, associationPrimaryKey
) => {
  if (!source?._associations?.[tableName]) {
    return source; // Reached the lowest-level child
  }
  const matchedParent = source._associations[tableName].find(association => (
    association[primaryKey] === payload[primaryKey]
  ));
  if (matchedParent) {
    matchedParent._associations[associationName] = mergeReplace(
      matchedParent._associations[associationName] || [],
      payload,
      associationPrimaryKey
    );
  } else {
    source._associations[tableName] = (
      source._associations[tableName].map(table => (
        mergeRecursiveReplace(
          table,
          payload,
          tableName,
          primaryKey,
          associationName,
          associationPrimaryKey
        )
      ))
    );
  }
  return source;
};

export const mergeDelete = (source, id, primaryKey) => (
  removeObjInArray(source, primaryKey, id)
);

export const mergeAssociationDelete = (
  source, id, associationName, associationPrimaryKey
) => (
  source && source.map(sourceItem => {
    if (!sourceItem._associations) {
      throw new Error(
        "Source item must include _associations attribute to delete associations"
      );
    }
    if (!sourceItem._associations[associationName]) {
      sourceItem._associations[associationName] = [];
    }
    const indexMatch = (
      sourceItem._associations[associationName].findIndex(association => (
        association[associationPrimaryKey] === parseInt(id)
      ))
    );
    if (indexMatch >= 0) {
      const spliced = [...sourceItem._associations[associationName]]
      spliced.splice(indexMatch, 1);
      return {
        ...sourceItem,
        _associations: {
          ...sourceItem._associations,
          [associationName]: spliced
        }
      }
    }
    return sourceItem;
  })
)

export const mergeRecursiveDelete = (source, id, tableName, primaryKey) => {
  const associations = source?._associations?.[tableName];
  if (!associations?.length) {
    return source; // Reached the lowest-level child
  }
  const matchedIndex = associations.findIndex(association => (
    association[primaryKey] === id
  ));
  if (matchedIndex > -1) {
    associations.splice(matchedIndex, 1);
    source._associations[tableName] = [...associations];
  } else {
    source._associations[tableName] = associations.map(association => (
      mergeRecursiveDelete(
        association,
        id,
        tableName,
        primaryKey
      )
    ));
  }
  return source;
};

const recursiveMapNestedAssociations = (
  item, associationNames, nameIndex, callback
) => {
  const name = associationNames[nameIndex];
  const nextName = associationNames[nameIndex + 1];
  if (!nextName) {
    const lastNestedReplaced = callback(item, name);
    return lastNestedReplaced._associations[name];
  }
  return item._associations[name].map(nested => ({
    ...nested,
    _associations: {
      ...nested._associations,
      [nextName]: recursiveMapNestedAssociations(
        nested,
        associationNames,
        nameIndex + 1,
        callback
      )
    }
  }));
};

/*
 * Replace or append one item of multiple-level nested associations.
 * The source's last and second-to-last associations MUST share the
 *   `primaryKey` field for this to work.
 */
export const mergeNestedAssociationReplace = (
  source,
  payload,
  primaryKey,
  associationPrimaryKey,
  ...associationTableNames
) => (
  mergeNestedParentKeyAssociationReplace(
    source,
    payload,
    primaryKey,
    primaryKey,
    associationPrimaryKey,
    ...associationTableNames
  )
)

/*
 * Like mergeNestedAssociationReplace, but when the
 *   second-to-last association's foreign key to the last association
 *   has a different column name (typically for polymorphic relationships)
 */
export const mergeNestedParentKeyAssociationReplace = (
  source,
  payload,
  primaryKey,
  parentForeignKey,
  associationPrimaryKey,
  ...associationTableNames
) => (
  source.map(sourceItem => ({
    ...sourceItem,
    _associations: {
      ...sourceItem._associations,
      [associationTableNames[0]]: recursiveMapNestedAssociations(
        sourceItem,
        associationTableNames,
        0,
        (parentItem, associationName) => (
          mergeParentKeyAssociationReplace(
            [parentItem],
            payload,
            primaryKey,
            parentForeignKey,
            associationName,
            associationPrimaryKey
          )[0]
        )
      )
    }
  }))
)

// Delete one item of multiple-level nested associations.
export const mergeNestedAssociationDelete = (
  source,
  id,
  associationPrimaryKey,
  ...associationTableNames
) => (
  source.map(sourceItem => ({
    ...sourceItem,
    _associations: {
      ...sourceItem._associations,
      [associationTableNames[0]]: recursiveMapNestedAssociations(
        sourceItem,
        associationTableNames,
        0,
        (parentItem, associationName) => (
          mergeAssociationDelete(
            [parentItem],
            id,
            associationName,
            associationPrimaryKey
          )[0]
        )
      ),
    }
  }))
)

export const sortNestedAssociations = (
  sortFn, source, ...associationTableNames
) => (
  source.map(sourceItem => ({
    ...sourceItem,
    _associations: {
      ...sourceItem._associations,
      [associationTableNames[0]]: recursiveMapNestedAssociations(
        sourceItem,
        associationTableNames,
        0,
        sortFn,
      )
    }
  }))
)

export const mergeOneSourceAssociationSetList = (
  source, payload, primaryKeyName, primaryKeyValue, associationName
) => {
  let hasUpdated = false;
  const updatedSource = source && source.map(sourceItem => {
    if (primaryKeyValue === sourceItem[primaryKeyName]) {
      const newItem = {
        ...sourceItem,
        _associations: {
          ...sourceItem._associations,
          [associationName]: payload
        }
      }
      hasUpdated = true
      return newItem
    }
    return sourceItem;
  });
  return hasUpdated ?
    updatedSource :
    source
}
