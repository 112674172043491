//https://emailregex.com/
const validEmailMatcher = (
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
const validPhoneMatcher = (
  /^\+?[0-9]{0,3}\s?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
);
const validPasswordMatcher = (
  /(?=^.{12,20}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^\w])(?!.*\s).*$/
);

const validEmail = (email) => validEmailMatcher.test(email);

const validPhone = (phone) => validPhoneMatcher.test(phone);

const validPassword = (password) => validPasswordMatcher.test(password);

export { validEmail, validPhone, validPassword };
