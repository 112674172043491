

export default function TestComponentObject() {
  return (
    <div>
      This is a placeholder Custom component for the
      ComponentObject named "Test"
    </div>
  )
}
