export const PROGRAM_ID_ORCHESTRATION = 1;

export const PROGRAM_TYPE_DEFAULT = "default";
export const PROGRAM_TYPE_CONSENT_ORDERS = "remediation-management";

export const PROGRAM_COMPONENT_TYPE_COMPONENTS = "Components";
export const PROGRAM_COMPONENT_TYPE_CUSTOM = "Custom";
export const PROGRAM_COMPONENT_TYPE_DASHBOARD = "Dash";
export const PROGRAM_COMPONENT_TYPE_REDIRECT = "Redirect";

export const COMPONENT_OBJECT_TYPE_BUILDER = "Builder";
export const COMPONENT_OBJECT_TYPE_CUSTOM = "Custom";
export const COMPONENT_OBJECT_TYPE_DATA_TABLE = "DataTable";
export const COMPONENT_OBJECT_TYPE_DOCUMENT = "Document";
export const COMPONENT_OBJECT_TYPE_DOCUMENT_LIBRARY = "DocumentLibrary";
export const COMPONENT_OBJECT_TYPE_TABLE = "Table";

export const COMPONENT_OBJECT_BUILDER_TYPE_REF_ASSESSMENT = "Assessment";
export const COMPONENT_OBJECT_PORTFOLIO_DOC_LIBRARY_TYPEREF = "PortfolioDocLibrary";

/*
 * example: [COMPONENT_ID_TRAINING_ASSIGNMENTS]: "Training Assignments"
 */
export const customProgramComponentHeadings = {
};

/*
 * example: [COMPONENT_OBJECT_ID_TRAINING_ASSIGNMENTS]: "Training Assignments"
 */
export const customComponentObjectHeadings = {
};

/*
 * Substitute for Orchestration's use of Nav_Icon column.
 */
export const programComponentIconMapping = {
  "portfolio-portal": "Dashboard",
  "portfolio-user-management": "Admin",
  details: "Programs",
  "file-transfers": "Documents",
  "data-analysis": "Audits-assessments",
  "claims": "Tasks",
  "workflows": "Plans-and-roadmaps",
  "status-reporting": "Reporting",
  "findings-conclusions": "Findings",
  "reports-authoring": "Component-Builder",
  "project-management": "Organizations",
  "alerts": "Alerts"
};

// Not implemented.
export const componentObjectIconMapping = {
}
