import { H5 } from "components/utils/headerV2.component";
import Loader from "components/utils/loader.components";
import { makeStyles } from "mui-styles";
import CustomPagination from "./CustomPagination.component";

const useStyles = makeStyles(theme => ({
  messageContainer: {
    marginTop: 64,
    marginBottom: 64,
    textAlign: "center"
  },
  childrenContainer: {
    marginBottom: 32
  },
  bottomPaginationContainer: {
    marginBottom: 32
  }
}));

export default function SearchResultsWrapper(props) {
  const {
    children,
    emptyMessage,
    error,
    loading,
    results,
    handlePageChange,
    totalPageCount,
    paginationIndex = null,
    totalResults
  } = props;
  const classes = useStyles();

  if (loading) {
    return (
      <Loader />
    );
  }
  if (error) {
    return (
      <div className={classes.messageContainer}>
        <H5 color="red">
          There was a problem fetching your search results.
        </H5>
        <p>Please update your search query or try again later.</p>
      </div>
    );
  }
  if (!results) {
    return (
      <div className={classes.messageContainer}>
        <p>Your search results will appear here.</p>
      </div>
    );
  }
  if (!results.length) {
    return (
      <div className={classes.messageContainer}>
        {emptyMessage || (
          <p>No results were found.</p>
        )}
      </div>
    );
  }
  return (
    <>
      <CustomPagination
        totalPageCount={totalPageCount}
        paginationIndex={paginationIndex}
        handlePageChange={handlePageChange}
        totalResults={totalResults}
      />
      <div
        className={classes.childrenContainer}
        data-cy="valid-search-results-wrapper"
      >
        {children}
      </div>
      <div className={classes.bottomPaginationContainer}>
        <CustomPagination
          totalPageCount={totalPageCount}
          paginationIndex={paginationIndex}
          handlePageChange={handlePageChange}
          totalResults={totalResults}
        />
      </div>
    </>
  );
}
