// packages
import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { Container } from "@mui/material";
import Alert from "@mui/lab/Alert";
import { makeStyles } from "mui-styles";

// components
import ButtonDefault from "components/utils/buttonDefault.component";
import { validEmail } from "utils/stringValidation.utils";
import RMSLogo from "components/utils/rmsLogo.component";
import AuthTextField from "components/utils/form-elements/authTextField.component";

// services
import AuthService from "services/base/Auth.service";
import { decodeJwt } from "jose";
import { HTTP_STATUS_OK } from "services/constants/response.constants";
import StorageService from "services/Storage.service";

import { AUTH_PATH_VERIFY } from "components/layout/constants/publicPaths.constants.js";

const useStyles = makeStyles((theme) => ({
  alert: {
    color: "white",
    backgroundColor: theme.palette.error.light,
    padding: "0px 16px",
    marginBottom: 10,

    "& .MuiAlert-icon": {
      fill: "white",
      color: "white",
    },
  },
  linkWrapper: {
    marginTop: 30,
    textAlign: "center",
  },
  link: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
    textDecoration: "none",
    paddingTop: 10,
    paddingBottom: 10,
    display: "block",
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
  buttonWrapper: {
    marginTop: 10,
  },
}));

const LoginForm = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  const [login, setLogin] = useState({
    email: "",
    password: "",
  });
  const [message, setMessage] = useState();
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorText, setPasswordErrorText] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (location.state && location.state.message) {
      setMessage(location.state.message);
    }
    async function fetchData() {
      const loggedInRes = await AuthService.isUserLoggedIn();
      if (isMounted) {
        setLoggedIn(loggedInRes);
      }
    }
    fetchData();
    return () => {
      isMounted = false;
    };
  }, [location.state])

  useEffect(() => {
    if (loggedIn) {
      const originalPath = StorageService.getSessionItem("originalPath", false);
      if (originalPath) {
        history.push(originalPath);
      } else if (location.search !== "") {
        const requestLocation = location.search.split("?redirect=")[1];
        history.push("/home", {
          requestLocation
        })
      } else {
        history.push("/home")
      }
      StorageService.removeSessionItem("originalPath");
    }
  }, [loggedIn, location.search, history]);

  // handle form change
  const handleChange = (event) => {
    if (event.target.id === "email") {
      if (!validEmail(event.target.value)) {
        setEmailError(true);
        setEmailErrorText("Valid email required");
      } else {
        setEmailError(false);
        setEmailErrorText("");
      }
    }
    if (event.target.id === "password") {
      if (event.target.value === "") {
        setPasswordError(true);
        setPasswordErrorText("Password required");
      } else {
        setPasswordError(false);
        setPasswordErrorText("");
      }
    }
    setLogin({
      ...login,
      [event.target.id]: event.target.value,
    });
  };
  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    //verification
    if (Object.values(login).some((input) => input === null || input === "")) {
      if (login.email === "" || login.email === null) {
        setEmailError(true);
      }
      if (login.password === "" || login.password === null) {
        setPasswordError(true);
      }
      setMessage("The password or email you have entered is invalid");
    } else {
      try {
        const loginResult = await AuthService.login(login.email, login.password);
        if (loginResult.status === HTTP_STATUS_OK) {
          const decodedToken = decodeJwt(loginResult.data.accessToken);
          if (decodedToken?.mfaEnabled === 2) {
            const requestLocation = location.search.split("?redirect=")[1];
            history.push("/home", {
              requestLocation
            });
            return;
          } else {
            const requestLocation = location.search.split("?redirect=")[1];
            if (requestLocation) {
              StorageService.setSessionItem("originalPath", requestLocation, false);
            }
            history.push(AUTH_PATH_VERIFY);
            return;
          }
        }
      } catch (error) {
        console.error(error);
      }
      setMessage("The password or email you have entered is invalid");
    }
  }, [history, login, location.search]);

  return (
    <Container component="main" maxWidth="xs">
      <RMSLogo />
      {!!message && (
        <Alert severity="error" className={classes.alert}>
          {message}
        </Alert>
      )}
      <form noValidate data-cy="login-form">
        <AuthTextField
          error={emailError}
          helperText={emailErrorText}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoFocus
          size="small"
          onChange={handleChange}
        />
        <AuthTextField
          error={passwordError}
          helperText={passwordErrorText}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          size="small"
          className={classes.textField}
          onChange={handleChange}
        />
        <div className={classes.buttonWrapper}>
          <ButtonDefault
            fullWidth
            variant="large"
            background="secondary"
            onClick={handleSubmit}
            data-cy="loginButton"
            type="submit"
          >
            Sign In
          </ButtonDefault>
        </div>
        <div className={classes.linkWrapper}>
          <Link className={classes.link} to="/forgot" data-cy="reset/forgot-password">
            Reset Password
          </Link>
        </div>
      </form>
    </Container>
  );
};

export default LoginForm;
