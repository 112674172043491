import { Box } from "@mui/material";
import { H5 } from "components/utils/headerV2.component";

const ClaimIdentifiersDisplay = (props) => {
  const { claim } = props;
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex">
        <H5 color="tertiary">CIF ID:</H5>
        <Box marginLeft="2px">{claim._derivedData.ClaimData.cifId}</Box>
      </Box>
      <Box display="flex">
        <H5 color="tertiary">Loan&nbsp;Number:</H5>
        <Box marginLeft="2px">{claim._derivedData.ClaimData.gsLoanNumber}</Box>
      </Box>
    </Box>
  );
};

export default ClaimIdentifiersDisplay;
