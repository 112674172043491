import claimReducer, { ACTION_SET_CUSTOMER_CORRECTIONS, ACTION_SET_CLAIM_VALUE_TYPES, ACTION_SET_FILE_TRANSFERS, claimsInitialState } from "reducers/shared/claim.reducer";
import { useContext, useEffect, useMemo, useReducer, useRef } from "react";
import ClaimService from "services/Claim.service";
import Loader from "components/utils/loader.components";
import CustomerService from "services/Customer.service";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";
import { PATH_CLAIM_PAYMENTS, PATH_CLAIMS_BY_CUSTOMER, PATH_CLAIMS_BY_POPULATION, PATH_CLAIMS_REVIEW_TRACKING, PATH_CLAIM_RECEIPT_TRACKING, PATH_CONTACT_CHANGES_TRACKING, PATH_PROGRAM_COMPONENT } from "components/layout/constants/standardPaths.constants";
import ClaimsDashboardPage from "components/pages/consentOrder/claims/ClaimsDashboardPage.component";
import ClaimsByPopulationPage from "components/pages/consentOrder/claims/population/ClaimsByPopulationPage.component";
import ClaimsByCustomerPage from "components/pages/consentOrder/claims/customer/ClaimsByCustomerPage.component";
import ContactChangesPage from "components/pages/consentOrder/claims/contacts/ContactChangesPage.component"
import WorkflowTemplateContext from "contexts/workflowTemplate.context";
import ReviewTrackingPage from "components/pages/consentOrder/claims/reviewTracking/ReviewTrackingPage.component";
import { buildEventChain, getNodesFromEventChain } from "components/utils/workflows/workflow.utils";
import { mapObjectArrayByKey } from "utils/arrayOfObjects.utils";
import ClaimReceiptTrackingPage from "./receiptTracking/ClaimReceiptTrackingPage.component";
import FileTransferService from "services/FileTransfer.service";
import ClaimPaymentsPage from "./payments/ClaimPaymentsPage.component";
import StatisticsContext from "contexts/statistics.context";


export default function ClaimsDashboard() {
  const customerRequestAbortRef = useRef();
  const fileTransferRequestAbortRef = useRef();

  const { cancelStatsRequests } = useContext(StatisticsContext);

  const workflowTemplates = useContext(WorkflowTemplateContext);

  const [state, dispatch] = useReducer(
    claimReducer,
    claimsInitialState
  )

  useEffect(function fetchRequiredData() {
    const fetchClaimValueTypes = async () => {
      const [
        claimValueTypesResponse,
      ] = await Promise.all([
        ClaimService.getClaimValueTypes(),
      ])
      dispatch({
        type: ACTION_SET_CLAIM_VALUE_TYPES,
        payload: claimValueTypesResponse.payload
      })
    }
    fetchClaimValueTypes()
  }, [dispatch]);

  useEffect(function fetchPrioritizedLazyLoadData() {
    let isMounted = true;
    (async () => {
      if (!isMounted) {
        return;
      }
      customerRequestAbortRef.current = new AbortController();
      fileTransferRequestAbortRef.current = new AbortController();
      const [
        customerCorrectionsResponse,
        fileTransferResponse,
      ] = await Promise.all([
        CustomerService.getCustomerCorrectionsAuditLog(
          customerRequestAbortRef.current
        ),
        FileTransferService.getFileTransfers(
          fileTransferRequestAbortRef.current
        )
      ]);
      if (fileTransferResponse.payload) {
        dispatch({
          type: ACTION_SET_FILE_TRANSFERS,
          payload: fileTransferResponse.payload
        });
      }
      if (customerCorrectionsResponse.payload) {
        dispatch({
          type: ACTION_SET_CUSTOMER_CORRECTIONS,
          payload: customerCorrectionsResponse.payload
        });
      }
    })();
    return () => {
      isMounted = false;
    }
  /* eslint-disable-next-line  */
  }, []);

  useEffect(() => (
    function cancelLargeRequestsOnUnmount() {
      cancelStatsRequests();
      if (customerRequestAbortRef.current) {
        CustomerService.cancel(customerRequestAbortRef.current);
      }
      if (fileTransferRequestAbortRef.current) {
        FileTransferService.cancel(fileTransferRequestAbortRef.current);
      }
    }
  ), [cancelStatsRequests]);

  const workflowNodesById = useMemo(() => (
    mapObjectArrayByKey(
      (workflowTemplates || []).flatMap(wfTemplate => ([
        wfTemplate._associations.WFEventFlow.flatMap(eventFlow => ([
          eventFlow._associations.WFReferenceNode,
          eventFlow._associations.WFTargetNode
        ]))
      ])).flat(),
      "wfNodeId"
    )
  ), [workflowTemplates]);

  const workflowNodeChainsByTemplate = useMemo(() => (
    !workflowTemplates ? [] : Object.fromEntries(
      workflowTemplates.map(wfTemplate => ([
        wfTemplate.wfTemplateId,
        getNodesFromEventChain(buildEventChain(wfTemplate))
      ]))
    )
  ), [workflowTemplates]);

  if (!state.claimValueTypesByName) {
    return <Loader />
  }
  return (
    <Switch>
      <Route path={PATH_CLAIMS_BY_POPULATION}>
        <ClaimsByPopulationPage
          state={state}
          dispatch={dispatch}
        />
      </Route>
      <Route path={PATH_CLAIMS_BY_CUSTOMER}>
        <ClaimsByCustomerPage
          state={state}
          workflowNodesById={workflowNodesById}
          workflowTemplates={workflowTemplates}
        />
      </Route>
      <Route path={PATH_CLAIM_RECEIPT_TRACKING}>
        <ClaimReceiptTrackingPage
          state={state}
          dispatch={dispatch}
        />
      </Route>
      <Route path={PATH_CLAIM_PAYMENTS}>
        <ClaimPaymentsPage
          state={state}
        />
      </Route>
      <Route path={PATH_CLAIMS_REVIEW_TRACKING}>
        <ReviewTrackingPage
          state={state}
          dispatch={dispatch}
        />
      </Route>
      <Route path={PATH_CONTACT_CHANGES_TRACKING}>
        <ContactChangesPage state={state} />
      </Route>
      <Route path={PATH_PROGRAM_COMPONENT}>
        <ClaimsDashboardPage
          state={state}
          dispatch={dispatch}
          workflowNodeChainsByTemplate={workflowNodeChainsByTemplate}
        />
      </Route>
    </Switch>
  )
}
