import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "mui-styles";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { downloadFileByRef } from "services/util/file.utils"
import classNames from "classnames";
import ButtonDefault from "../buttonDefault.component";
import CustomLink from "../link.component";
import { Replay, WarningRounded } from "@mui/icons-material";
import { acceptableExtensionTypes } from "constants/file.constants";

const useStyles = makeStyles((theme) => ({
  containerInline: {
    display: "flex",
    alignItems: "center"
  },
  //UPLOAD
  attachIcon: {
    color: "white",
    marginRight: 4,
    marginLeft: -1,
  },
  attachIconDefault: {
    fontSize: 18,
    height: 18,
  },
  attachIconInline: {
    fontSize: 12,
    height: 16,
    width: 16,
  },
  reattachIconDefault: {
    height: 18,
    marginRight: 6,
    fontSize: 12
  },
  reattachIconInline: {
    height: 16,
    marginRight: 6
  },
  attachmentIcon: {
    maxHeight: 20,
    marginRight: 8,
    display: "inline-block",
    fill: theme.palette.secondary.dark,
    fontSize: theme.typography.h2.fontSize,
  },
  errorIcon: {
    marginBottom: 2,
    fill: theme.palette.error.main,
  },
  attachmentTextContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 34,
  },
  attachmentTextContainerDefault: {
    marginTop: 10
  },
  invisibleInput: {
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
    opacity: 0,
    height: 1
  },
  error: {
    color: theme.palette.text.error,
    fontWeight: 600
  },
  attachmentWrapperCentered: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginBottom: 4
  },
  attachmentWrapperInline: {
    marginRight: 16
  },
  longButton: {
    width: 600,
    maxWidth: "70vw",
  },
  attachmentText: {
    fontSize: theme.typography.body1.fontSize,
  },
  attachmentTextDefault: {
    lineHeight: "normal",
  },
  fileName: {
    wordBreak: "break-all"
  }
}));

export const VARIANT_DEFAULT = "default";
export const VARIANT_INLINE = "inline";

export const FILE_INPUT_NAME_DEFAULT = "single-file-upload";

const SingleFileUpload = ({
  name = FILE_INPUT_NAME_DEFAULT,
  buttonBackground = "primary",
  fileInputHandler,
  chosenFile,
  error,
  existingFile,
  renamedChosenFile,
  existingFileDisplayName,
  unsavedExistingFile,
  disabled,
  fileNameField = "File_Name",
  variant = VARIANT_DEFAULT
}) => {
  const classes = useStyles();
  const isFileAttached = !!chosenFile?.name || !!existingFile?.fileRef;

  const [isDisabledOverride, setIsDisabledOverride] = useState(false);

  const href = useMemo(() => {
    if (!chosenFile && !unsavedExistingFile) {
      return undefined
    }
    return URL.createObjectURL(unsavedExistingFile || chosenFile);
  }, [unsavedExistingFile, chosenFile])

  const fileNameDisplay = useMemo(() => {
    if (chosenFile) {
      if (renamedChosenFile) {
        return renamedChosenFile;
      }
      return chosenFile.name;
    }
    if (existingFile) {
      return (
        existingFileDisplayName || existingFile?.fileName || existingFile?.fileRef
      );
    }
  }, [renamedChosenFile, chosenFile, existingFile, existingFileDisplayName])

  const handleButtonClick = useCallback(() => {
    if (existingFile) {
      setIsDisabledOverride(true);
    }
  }, [existingFile])

  const handleFileDownload = useCallback(() => {
    if (existingFile && !chosenFile) {
      const fileRef = existingFile.fileRef
      return downloadFileByRef(
        fileRef,
        existingFileDisplayName || existingFile[fileNameField] || fileRef
      )
    }
  }, [chosenFile, existingFile, existingFileDisplayName, fileNameField]);

  const handleInputChange = useCallback(event => {
    setIsDisabledOverride(false);
    fileInputHandler(event);
  }, [fileInputHandler]);

  return (
    <div
      className={
        classNames(variant === VARIANT_INLINE && classes.containerInline)
      }
    >
      <div
        className={classNames(
          classes.attachmentTextContainer,
          variant === VARIANT_DEFAULT && classes.attachmentTextContainerDefault
        )}
        data-cy="linkContentContainer"
      >
        {!!isFileAttached && !!error && (
          <>
            <WarningRounded
              className={classNames(classes.attachmentIcon, classes.errorIcon)}
            />
            <div
              className={classNames(
                classes.attachmentText,
                variant === VARIANT_DEFAULT && classes.attachmentTextDefault,
                classes.error
              )}
            >
              {error}
            </div>
          </>
        )}
        {!!isFileAttached && !error && (
          <CustomLink
            target="_blank"
            href={href}
            onClick={handleFileDownload}
            variant="iconBeforeLink"
            className={classNames(classes.attachmentText, classes.fileName)}
            containerClassName={classes.linkInnerContainer}
            startIcon={
              <AttachmentOutlinedIcon className={classes.attachmentIcon} />
            }
          >
            {fileNameDisplay}
          </CustomLink>
        )}
        {!isFileAttached && (
          <div
            className={classNames(
              classes.attachmentText,
              variant === VARIANT_DEFAULT && classes.attachmentTextDefault,
              error && classes.error
            )}
          >
            {error?.length ? error : "No File Selected"}
          </div>
        )}
      </div>
      <div className={classNames(
        variant === VARIANT_DEFAULT && classes.attachmentWrapperCentered,
        variant === VARIANT_INLINE && classes.attachmentWrapperInline
      )}
      >
        <ButtonDefault
          background={buttonBackground}
          component="label"
          className={classNames(
            variant === VARIANT_DEFAULT && classes.longButton
          )}
          startIcon={isFileAttached ? (
            <Replay
              className={classNames(
                variant === VARIANT_DEFAULT && classes.reattachIconDefault,
                variant === VARIANT_INLINE && classes.reattachIconInline,
              )}
            />
          ) : (
            <AttachmentIcon
              className={classNames(
                classes.attachIcon,
                variant === VARIANT_DEFAULT && classes.attachIconDefault,
                variant === VARIANT_INLINE && classes.attachIconInline,
              )}
            />
          )}
          variant={variant === VARIANT_INLINE ? "small" : "default"}
          onClick={handleButtonClick}
          data-cy="btn-attach-file"
          disableReadOnlyUsers
          disabled={disabled}
        >
          {isFileAttached ? `Change File` : `Select File`}
          <input
            type="file"
            name={name}
            accept={acceptableExtensionTypes.toString()}
            className={classes.invisibleInput}
            onChange={handleInputChange}
            disabled={!isDisabledOverride && !!existingFile}
            data-cy="fileInput-attach-file"
          />
        </ButtonDefault>
      </div>
    </div>
  );
};

export default SingleFileUpload;
