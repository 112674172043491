import React, { useCallback } from "react";
import LabelInput from "components/utils/form-elements/labelInput.component";
import CustomSunEditor from "components/utils/sunEditor.component";
import { sunEditorBuilderLang } from "components/shared/forms/constants/sunEditor.constants";
import { makeStyles } from "mui-styles";
import { Grid } from "@mui/material";
import HeaderPrimary from "components/utils/header.component";
import CustomLink from "components/utils/link.component";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteIconButton from "components/utils/deleteIconButton.component";
import { makeSectionClientId, sectionLabel } from "components/builder/utils/builder.utils";
import {
  ACTION_ADD_PROC_STEP_SECTION,
  ACTION_DELETE_PROC_STEP_SECTION,
  ACTION_REPLACE_PROC_STEP_SECTION,
  ACTION_REPLACE_PROC_STEP_CONTENT,
} from "reducers/shared/builder/builder.reducer";

const useStyles = makeStyles((theme) => ({
  stepsWrapper: {
    display: "flex",
    padding: "25px 10px",
    borderBottom: "2px solid rgba(95, 119, 140, 0.15)",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  descriptionContainer: {
    padding: 20,
    [theme.breakpoints.down("md")]: {
      padding: "20px 0px",
    },
  },
  stepsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 10,
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
  },
  textFieldWrapper: {
    marginTop: 20,
    marginBottom: 10,
  },
  deleteSectionWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  addSectionWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "25px 0",
    width: "100%",
  },
  addIcon: {
    fontSize: theme.typography.body1.fontSize,
    color: "white",
    marginRight: 6,
  },
  sectionWrapper: {
    width: "100%",
  },
}));

export const ProcedureSteps = (props) => {
  const classes = useStyles();
  const {
    subGroup,
    scopedProcSteps,
    referenceGroup,
    readOnly,
    dispatch,
    disableBuilder,
  } = props;

  const procStepHeaderOnBlur = (event, procStep) => {
    event.preventDefault();
    if (event.target.value === procStep.SectionHeader) {
      return;
    }
    dispatch({
      type: ACTION_REPLACE_PROC_STEP_SECTION,
      payload: { ...procStep, SectionHeader: event.target.value },
    });
  };

  const deleteStep = (section) => {
    dispatch({
      type: ACTION_DELETE_PROC_STEP_SECTION,
      payload: section,
    });
  };

  const addNewStep = useCallback(
    (sectionOrder) => {
      let newSection = {
        Section_Order: sectionOrder + 1,
        SectionContent: "",
        SectionHeader: "",
        Type: "procedureStep",
      };
      if (subGroup?._meta) {
        newSection = {
          ...newSection,
          RegRefs_Reg_Refs_ID: null,
          RegFramework_ID: referenceGroup.RegFramework_ID,
          _meta: {
            clientId: `procStepSectionId-${new Date().getTime()}`,
            parentClientId: subGroup._meta.clientId,
            procEleClientId: subGroup._meta.parentClientId,
          },
        };
      } else {
        newSection = {
          ...newSection,
          RegRefs_Reg_Refs_ID: subGroup.Reg_Refs_ID,
          RegFramework_ID: referenceGroup.RegFramework_ID,
          _meta: {
            clientId: makeSectionClientId(),
          },
        };
      }
      dispatch({
        type: ACTION_ADD_PROC_STEP_SECTION,
        payload: newSection,
      });
    },
    [referenceGroup, subGroup, dispatch]
  );

  return (
    <Grid container className={classes.stepsWrapper}>
      {/* DESCRIPTION */}
      <Grid item md={12} lg={5} className={classes.descriptionContainer}>
        <HeaderPrimary variant="h5Primary">
          {`${subGroup.SubGroupName}: ${subGroup.Detail || subGroup.Content}`}
        </HeaderPrimary>
      </Grid>

      {/* LABEL INPUT AND EDITOR */}
      <Grid item md={12} lg={7} className={classes.stepsContainer}>
        {!!scopedProcSteps && scopedProcSteps.length === 0 && (
          <div className={classes.addSectionWrapper}>
            <CustomLink
              onClick={() => {
                addNewStep(0);
              }}
              variant="linkBar"
              startIcon={
                <AddCircleOutlineOutlinedIcon className={classes.addIcon} />
              }
              test={`AddStep-${referenceGroup.FrameworkName}-${subGroup.SubGroupName}`}
              disableLinkBar={disableBuilder}
            >
              Add Step
            </CustomLink>
          </div>
        )}
        {scopedProcSteps?.map((section, index, arr) => {
          const sectionId = (
            section?._meta?.clientId || section.BuilderSection_ID
          );
          return (
            <React.Fragment
              key={`${section.Section_Order}_${
                section?._meta?.clientId
                  ? section?._meta?.clientId
                  : section.Builder_ID
              }`}
            >
              <div
                className={classes.sectionWrapper}
                data-cy={`wrapper-procstep-${subGroup.SubGroupName}-Order:${section.Section_Order}`}
              >
                <div
                  className={classes.textFieldWrapper}
                  key={`Header_${section.Section_Order}`}
                >
                  <LabelInput
                    name={`section-${sectionId}`}
                    variant="default"
                    label={sectionLabel("procedureStep")}
                    defaultValue={section.SectionHeader}
                    onBlur={(event) => procStepHeaderOnBlur(event, section)}
                    test="ProcedureStep-header"
                    disabled={disableBuilder}
                  />
                </div>
                <div
                  className={classes.rteWrapper}
                  key={`Content_${section.Section_Order}`}
                >
                  <StepSectionRTE dispatch={dispatch} section={section} disableBuilder={disableBuilder} />
                </div>
                <div className={classes.deleteSectionWrapper}>
                  {!readOnly && (
                    <DeleteIconButton
                      target="Step"
                      onClick={() => {
                        if (
                          window.confirm(
                              "Are you sure you want to delete this step?"
                          )
                        ) {
                          deleteStep(section);
                        }
                      }}
                      disabled={disableBuilder}
                    />
                  )}
                </div>
                {/* ADD BUTTON */}
                <div className={classes.addSectionWrapper}>
                  <CustomLink
                    onClick={() => {
                      addNewStep(section.Section_Order);
                    }}
                    variant="linkBar"
                    startIcon={
                      <AddCircleOutlineOutlinedIcon
                        className={classes.addIcon}
                      />
                    }
                    test={`AddStep-${referenceGroup.FrameworkName}-${subGroup.SubGroupName}`}
                    disableLinkBar={disableBuilder}
                  >
                      Add Step
                  </CustomLink>
                </div>
              </div>
              {index !== arr.length - 1}
            </React.Fragment>
          );
        })}
      </Grid>
    </Grid>
  );
};

const StepSectionRTE = ({ section, dispatch, disableBuilder }) => {

  const procSectionContentOnBlur = (event, content) => {
    if (content === section.SectionContent) {
      return;
    }
    dispatch({
      type: ACTION_REPLACE_PROC_STEP_CONTENT,
      payload: {
        id: section._meta.clientId,
        content,
      },
    });
  };
  return (
    <CustomSunEditor
      name={`proc-step-content-${section._meta.clientId}`}
      height="auto"
      sectionDetail={section}
      defaultValue={section.SectionContent}
      sectionID={section.Builder_Section_ID}
      onBlur={procSectionContentOnBlur}
      lang={sunEditorBuilderLang}
      disabled={disableBuilder}
    />
  );
};

export default ProcedureSteps;
