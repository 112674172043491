import SubpageHeader from "components/pages/consentOrder/claims/shared/SubpageHeader.component";
import ComsCard from "components/utils/cards/comsCard.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import LabelValuePair from "components/utils/labelValuePair.component";
import { makeStyles } from "mui-styles";
import { formatCash, formatDisplayNumber } from "utils/localization.utils";
import PopulationDistributionSearch from "./PopulationDistributionSearch.component";
import { Box } from "@mui/material";
import { useContext, useMemo } from "react";
import Loader from "components/utils/loader.components";
import { H5 } from "components/utils/headerV2.component";
import StatisticsContext from "contexts/statistics.context";

const useStyles = makeStyles(() => ({
  stat: {
    display: "flex",
    justifyContent: "space-between"
  },
  loader: {
    display: "flex"
  },
}))

export default function DistributionPopulations() {
  const classes = useStyles();
  const { state: { populations }} = useContext(StatisticsContext);

  const populationNames = useMemo(() => (
    populations && Object.keys(populations).sort()
  ), [populations]);

  return (
    <>
      <SubpageHeader title="Populations" />
      <GridContainer spacing={2} hasVerticalSpacing>
        {populationNames ? (
          populationNames?.map?.(name => (
            <GridItem
              key={name}
              md={12}
              lg={4}
            >
              <ComsCard
                title={`Population ${name}`}
                key={name}
              >
                <LabelValuePair
                  label="Total Distributions"
                  value={
                    formatDisplayNumber(populations[name]?.distributionCount)
                  }
                  isLoading={!populations}
                  className={classes.stat}
                />
                <LabelValuePair
                  label="Total Cashed Distributions"
                  value={formatDisplayNumber(populations[name]?.cashedCount)}
                  isLoading={!populations}
                  className={classes.stat}
                />
                <LabelValuePair
                  label="Total Distribution Amount"
                  value={
                    formatCash(populations[name]?.remediationAmount, true)
                  }
                  isLoading={!populations}
                  className={classes.stat}
                />
                <LabelValuePair
                  label="Total Cashed Amount"
                  value={
                    formatCash(populations[name]?.cashedAmount, true)
                  }
                  isLoading={!populations}
                  className={classes.stat}
                />
              </ComsCard>
            </GridItem>
          ))
        ) : (
          <Box align="center" margin="auto" paddingTop={4} paddingBottom={5.5}>
            <H5 color="tertiary" withMargin>Loading population data...</H5>
            <GridContainer>
              <Loader className={classes.loader} inline />
            </GridContainer>
          </Box>
        )}
      </GridContainer>
      <Box paddingTop={2}>
        <PopulationDistributionSearch populationTypes={populationNames} />
      </Box>
    </>

  )
}