import React, { useEffect, useMemo, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "mui-styles";
import { Grid } from "@mui/material";
import CustomCard from "components/utils/card.component"
import { CustomComponent } from "components/types/registry.component";
import PageHeader from "components/utils/pageHeader.component";
import classNames from "classnames";
import { sortByStringKey } from "utils/sort.utils";
import InlineSVGIcons from "components/utils/inlineSVGIcons.component";
import ProgramDashboard from "components/types/programDashboard.component";
import DocumentType from "components/layout/comp-types/documentType.component";
import NotFound from "components/pages/auth/notFound.component";
import { generatePath, matchPath, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useNumericParams from "hooks/useNumericParams";
import {
  customProgramComponentHeadings,
  PROGRAM_COMPONENT_TYPE_DASHBOARD,
  PROGRAM_COMPONENT_TYPE_CUSTOM,
  PROGRAM_COMPONENT_TYPE_REDIRECT,
  COMPONENT_OBJECT_TYPE_DOCUMENT_LIBRARY,
  COMPONENT_OBJECT_TYPE_DOCUMENT,
  COMPONENT_OBJECT_TYPE_BUILDER,
  COMPONENT_OBJECT_TYPE_CUSTOM
} from "components/constants/program.constants";
import { DocumentLibraryType } from "../comp-types/documentLibraryType.component";
import ProgramsContext from "contexts/programs.context";
import { STANDARD_PATHS_ALL, PATH_COMPONENT_OBJECT, PATH_PROGRAM_COMPONENT, PATHS_HIDDEN_TITLE_SUBPAGES } from "components/layout/constants/standardPaths.constants";
import { findRedirectComponentObjectName } from "./utils/program.utils";
import { isDevelopmentEnv } from "core/environment";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "stretch",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(30),
      height: "",
    },
  },
  gridContainer: {
    marginLeft: 40,
  },
  groupWrapper: {
    paddingBottom: 50,
    paddingTop: 30,
    paddingLeft: 30,
  },
  groupHeader: {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    left: 0,
    color: theme.palette.primary.main,
  },
  customIconWrapper: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
    height: 30,
    width: 30,
    padding: 7,
    borderRadius: 50,
  },
  icon: {
    fill: "white",
  },
  builderIcon: {
    paddingLeft: 9,
    paddingRight: 6,
  },
  documentIcon: {
    padding: 8,
  },
  titleContainer: {
    marginBottom: 40
  },
  customColor: {
    color: theme.palette.secondary.dark,
  },
  builderColor: {
    color: theme.palette.teal.dark,
  },
  documentColor: {
    color: theme.palette.purple.main,
  },
  customColorBackground: {
    background: (
      `radial-gradient(
        ellipse farthest-side at 65% 38%,
        rgb(12, 133, 215) 14%,
        rgb(0, 122, 204) 58%,
        rgb(1, 92, 153) 93%,
        rgb(2, 87, 145) 100%
      )`
    )
  },
  builderColorBackground: {
    background: (
      `radial-gradient(
        ellipse farthest-side at 65% 38%, 
        #058faa 14%, #00829b 58%, #02697e 93%, #005c6f 100%
      )`
    )
  },
  documentColorBackground: {
    background: (
      `radial-gradient(
        ellipse farthest-side at 65% 38%,
        #5b5aba 14%, #4c4baa 58%, #444398 93%, #38367c 100%
      )`
    )
  },
  tableTitle: {
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: "bold",
    paddingBottom: 15,
  },
  tertiaryGrey: {
    color: theme.palette.tertiaryGrey.main,
  },
  left: {
    width: "49%",
    margin: 0,
    paddingBottom: 25,
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      paddingRight: 0,
    },
  },
  right: {
    width: "49%",
    margin: 0,
    marginLeft: "auto",
    paddingBottom: 25,
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      paddingLeft: 0,
    },
  },
}));

const hideLabelSet = new Set([COMPONENT_OBJECT_TYPE_DOCUMENT_LIBRARY]);

const hideLabelDisplay = (type) => {
  return hideLabelSet.has(type.type);
};

/*
 * List of ComponentObject types
 *   can add more here
 *   styling is done by adding a class above for `*type*-wrapper` and `*type*-header`
 */
const COMPONENT_TYPES = [
  { type: COMPONENT_OBJECT_TYPE_CUSTOM, label: "Data Management & Tools" }, // or no type
  { type: COMPONENT_OBJECT_TYPE_BUILDER, label: "Document Builders & Templates" },
  { type: COMPONENT_OBJECT_TYPE_DOCUMENT, label: "Published Documents" },
  { type: COMPONENT_OBJECT_TYPE_DOCUMENT_LIBRARY, label: "Document Library" },
];

const DOCUMENT_TYPE_REF_SET = new Set([
  COMPONENT_OBJECT_TYPE_DOCUMENT,
  COMPONENT_OBJECT_TYPE_DOCUMENT_LIBRARY
]);


//Function to determine cards mapped to ComponentType
const cardLogic = (type, cardsByStatus) => {
  let cards = cardsByStatus.filter((item) => (
    item.type === type.type ||
    (item.type === "" && type.type === COMPONENT_OBJECT_TYPE_CUSTOM)
  ));
  if (DOCUMENT_TYPE_REF_SET.has(type.type)) {
    // Puts typeRef "Current" ahead of "Previous"
    cards = sortByStringKey(cards, "typeRef")
  }
  return cards
}

const DOCUMENT_COMPONENT_TYPES = [
  COMPONENT_OBJECT_TYPE_DOCUMENT,
  COMPONENT_OBJECT_TYPE_DOCUMENT_LIBRARY
];


export default function ProgramComponentPage() {
  const classes = useStyles();
  const params = useNumericParams();
  const { programId: programName, programComponentId } = params;
  const history = useHistory();
  const { state } = useContext(ProgramsContext);
  const location = useLocation();

  const program = state.programs[programName];

  const programComponent = useMemo(() => {
    const pathProgramComponent = state.programComponents?.[programComponentId];
    if (pathProgramComponent) {
      return pathProgramComponent;
    }
    const allProgramComponents = (
      state.programComponentsByProgramId?.[program?.programId]
    );
    const dashboardProgramComponent = (
      allProgramComponents?.find?.(component => (
        component.type === PROGRAM_COMPONENT_TYPE_DASHBOARD
      ))
    );
    return dashboardProgramComponent || allProgramComponents?.[0];
  }, [
    program, programComponentId, state.programComponents,
    state.programComponentsByProgramId
  ]);

  const [isRedirectPending, setIsRedirectPending] = useState(
    !programComponent ||
    programComponent?.type === PROGRAM_COMPONENT_TYPE_REDIRECT
  );

  const componentObjects = useMemo(() => (
    state.componentObjectsByComponentId?.[programComponent?.programComponentId]
  ), [state.componentObjectsByComponentId, programComponent]);

  useEffect(function redirectProgramComponentPath() {
    // Todo: is the componentObjectsByComponentId check needed? chicken or egg situation
    // if (!programComponent || !state.componentObjectsByComponentId) {
    if (!programComponent) {
      return;
    }
    if (programComponent.type === PROGRAM_COMPONENT_TYPE_REDIRECT) {
      if (!componentObjects) {
        setIsRedirectPending(true);
        return;
      }
      const componentObjectName = findRedirectComponentObjectName(
        programComponent,
        componentObjects
      );
      const isBaseComponentPath = !!matchPath(
        location.pathname,
        { path: PATH_PROGRAM_COMPONENT, exact: true }
      );
      if (!isBaseComponentPath) {
        const isPartialComponentPathMatch = !!matchPath(
          location.pathname,
          { path: PATH_PROGRAM_COMPONENT, exact: false }
        );
        if (!isPartialComponentPathMatch) {
          return;
        }
        const compositePath = location.pathname.replace(
          /(program\/[\d]+\/[\d]+\/)(.+)/,
          `$1${componentObjectName}/$2`
        );
        if (isDevelopmentEnv) {
          const isValid = !!matchPath(compositePath, {
            path: STANDARD_PATHS_ALL
          });
          if (!isValid) {
            throw new Error(
              `Attempted to create invalid componentObject-based path from
              incomplete programObject-based child page path: ${compositePath}`
            );
          }
        }

        setIsRedirectPending(true);
        return history.replace(generatePath(compositePath, {
          ...params,
          programComponentId: programComponent.name,
          componentObjectId: componentObjectName
        }));
      }
      history.replace(generatePath(PATH_COMPONENT_OBJECT, {
        ...params,
        programComponentId: programComponent.name,
        componentObjectId: componentObjectName
      }));
      setIsRedirectPending(true);
      return;
    } else if (
      !programComponentId &&
      programComponent.name &&
      programComponent.type !== PROGRAM_COMPONENT_TYPE_DASHBOARD
    ) {
      history.replace(generatePath(
        PATH_PROGRAM_COMPONENT,
        { ...params, programComponentId: programComponent.name }
      ));
      setIsRedirectPending(true);
      return;
    }
    setIsRedirectPending(false);
  }, [
    programComponent, programComponentId, componentObjects, history, location,
    params, state.componentObjectsByComponentId
  ]);

  const readyToLoad = (
    !!state?.programComponents &&
    !isRedirectPending && (
      !!componentObjects ||
      programComponent?.type !== PROGRAM_COMPONENT_TYPE_REDIRECT
    )
  );

  const isProgramComponentLoadPending = (
    !programComponent &&
    !state.programComponentsByProgramId?.[program?.programId]
  );
  const isInvalidComponentObject = (
    !programComponent &&
    !!state.programComponentsByProgramId?.[program.programId]
  );

  const allCards = useMemo(() => (
    componentObjects &&
    sortByStringKey(sortByStringKey(componentObjects, "name"), "status")
  ), [componentObjects]);

  const cardsByType = useMemo(() => (
    allCards &&
    COMPONENT_TYPES.map(typeItem => ({
      type: typeItem.type,
      label: typeItem.label,
      cards: cardLogic(typeItem, allCards)
    }))
  ), [allCards]);

  const classesByType = useMemo(() => ({
    Custom: {
      background: classes.customColorBackground,
      color: classes.customColor,
      icon: "",
    },
    Builder: {
      background: classes.builderColorBackground,
      color: classes.builderColor,
      icon: classes.builderIcon,
    },
    Document: {
      background: classes.documentColorBackground,
      color: classes.documentColor,
      icon: classes.documentIcon,
    },
  }), [classes]);

  const pageTitle = useMemo(() => {
    if (!state.programComponents) {
      return "";
    }
    if (programComponentId) {
      const customTitle = customProgramComponentHeadings[programComponentId];
      if (customTitle) {
        return customTitle;
      }
    }
    if (programComponent) {
      return programComponent.label;
    }
    return state.programComponents[0]?.label;
  }, [programComponent, programComponentId, state.programComponents]);

  const cardAlertCounts = useMemo(() => {
    if (!allCards || !state.userAlerts) {
      return {};
    }
    const defaultCounts = Object.fromEntries(allCards.map(card => (
      [card.componentObjectId, 0]
    )));
    return state.userAlerts.reduce((sums, { componentObjectId }) => ({
      ...sums,
      [componentObjectId]: sums[componentObjectId] + 1
    }), defaultCounts);
  }, [allCards, state.userAlerts]);

  const hideTitle = useMemo(() => (
    matchPath(location.pathname, { path: PATHS_HIDDEN_TITLE_SUBPAGES, strict: true })
  ), [location.pathname]);

  if (isInvalidComponentObject) {
    return (
      <NotFound />
    );
  } else if (!readyToLoad || isProgramComponentLoadPending) {
    return null;
  }
  return (
    <div>
      {!hideTitle && (
        <div className={classes.titleContainer}>
          <PageHeader
            title={pageTitle}
            dashboardTitle={
              programComponent.type === PROGRAM_COMPONENT_TYPE_DASHBOARD
            }
          />
        </div>
      )}
      {(
        !!cardsByType &&
        programComponent?.type !== PROGRAM_COMPONENT_TYPE_CUSTOM &&
        programComponent?.type !== PROGRAM_COMPONENT_TYPE_DASHBOARD &&
        programComponent?.type !== PROGRAM_COMPONENT_TYPE_REDIRECT
      ) && (
        <Grid container spacing={8}>
          <Grid item sm={12} className={classes.gridContainer}>
            {cardsByType.map(type => (
              <div key={type.type}>
                {/* Component Object Grouping Labels */}
                {type.cards.length > 0 && (
                  <div
                    className={
                      classNames(
                        classes.groupHeader,
                        classesByType[type.type]?.color
                      )
                    }
                  >
                    <div
                      className={
                        classNames(
                          classes.customIconWrapper,
                          classesByType[type.type]?.icon,
                          classesByType[type.type]?.background
                        )
                      }
                    >
                      <InlineSVGIcons
                        variant={`component-${type.type}`}
                        className={classes.icon}
                      />
                    </div>
                    {!hideLabelDisplay(type) && type.label}
                  </div>
                )}
                {type.cards.length > 0 && (
                  <ProgramComponentCardWrapper>
                    {type.cards.map((card, index) => (
                      DOCUMENT_COMPONENT_TYPES.includes(card.type) ? (
                        <DocumentTypeComponent
                          componentObject={card}
                          index={index}
                          key={card.type}
                        />
                      ) : (
                        <CustomCard
                          variant={
                            card.status === "Active" ? "link" : "disabled"
                          }
                          key={card.type}
                          icon={card.navIcon}
                          title={card.label}
                          description={card.description}
                          alert={
                            cardAlertCounts[card.componentObjectId] ||
                            undefined
                          }
                          url={
                            generatePath(PATH_COMPONENT_OBJECT, {
                              programId: programName,
                              programComponentId: programComponent.name,
                              componentObjectId: card.name
                            })
                          }
                          type={card.type}
                        />
                      )
                    ))}
                  </ProgramComponentCardWrapper>
                )}
              </div>
            ))}
          </Grid>
        </Grid>
      )}
      {programComponent?.type === PROGRAM_COMPONENT_TYPE_DASHBOARD && (
        <ProgramDashboard />
      )}
      {programComponent?.type === PROGRAM_COMPONENT_TYPE_CUSTOM && (
        <CustomComponent
          typeRef={programComponent.typeRef}
        />
      )}
    </div>
  );
}

const DocumentTypeComponent = ({ componentObject, index }) => {
  const classes = useStyles();
  const { label, type } = componentObject;

  const isEven = useMemo(() => index % 2 === 0, [index]);
  const isPrevious = label.startsWith("Previous");

  switch (type) {
    case COMPONENT_OBJECT_TYPE_DOCUMENT_LIBRARY:
      return (
        <DocumentLibraryType
          componentObject={componentObject}
          tableTitle={
            <div
              className={classNames(classes.tableTitle, classes.documentColor)}
            >
              {label}
            </div>
          }
        />
      );
    case COMPONENT_OBJECT_TYPE_DOCUMENT:
    default:
      return (
        <div className={isEven ? classes.left : classes.right}>
          <DocumentType
            componentObject={componentObject}
            tableTitle={
              <div
                className={
                  classNames(
                    classes.tableTitle,
                    isPrevious ? classes.tertiaryGrey : classes.documentColor
                  )
                }
              >
                {label}
              </div>
            }
          />
        </div>
      );
  }
};

const ProgramComponentCardWrapper = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.root, classes.groupWrapper)}>
      {children}
    </div>
  );
};
