import { capitalize } from "@mui/material";

// Replaces first instance of a substring with replacement
const replaceSubstring = (string, subString, replacement) => (
  string.replace(subString, replacement)
);

// Replaces all instances of a substring with replacement
const replaceAllSubStrings = (string, subStrToReplace, newSubStr) => {
  const replacement = newSubStr || " ";
  return string.split(subStrToReplace).join(replacement);
};

const emptyStringToNull = (data) => {
  if (!data || data.trim() === "") {
    return null;
  }
  return data;
};

const pluralizedIrregularWords = {
  child: "children",
  goose: "geese",
  tooth: "teeth",
  foot: "feet",
  mouse: "mice",
};

/*
 *  Definitely not exhaustive or tested for all cases.
 *  Based on https://www.grammarly.com/blog/plural-nouns/
 */
export const pluralize = (singular, count) => {
  if (Number(count) === 1) {
    return singular;
  }
  // Unaltered special cases
  if (/[^aeiouh]ee[pr]$/i.test(singular) || singular.endsWith("ies")) {
    return singular;
  }
  // Latin / Greek roots
  if (singular.endsWith("us")) {
    return singular.replace(/us$/i, "i");
  }
  if (singular.endsWith("is")) {
    return singular.replace(/is$/i, "es");
  }
  // Irregular
  const lowerCaseString = singular.toLowerCase();
  const irregularMatch = pluralizedIrregularWords[lowerCaseString];
  if (irregularMatch) {
    if (/^[A-Z][a-z]/.test(singular)) {
      return capitalize(irregularMatch);
    } else if (singular === lowerCaseString) {
      return irregularMatch;
    }
    return irregularMatch.toUpperCase();
  }
  if (singular.endsWith("man")) {
    return singular.replace(/(m)an$/i, "$1en");
  }
  if (singular.endsWith("person")) {
    return singular.replace(/(p)erson$/i, "$1eople");
  }
  // Standard cases
  if (/[^aeiou]y$/i.test(singular)) {
    return singular.replace(/y$/i, "ies");
  }
  if (/[shxz]$/i.test(singular)) {
    if (/[^s][s]$/i.test(singular)) {
      return singular + "ses";
    }
    if (/[^z][z]$/i.test(singular)) {
      return singular + "zes";
    }
    return singular + "es";
  }

  return singular + "s";
};

/*
 * https://stackoverflow.com/a/1862219
 *
 * onlyNumericString('(123) 456-7890') // => "1234567890"
 */
const onlyNumericString = (string) => {
  return ("" + string).replace(/\D/g, "");
};

const ellipsize = (string, limit) => (
  string.length < limit ?
    string :
    `${string.slice(0, limit)}...`
);

const makePossessive = string => (
  string && (string.slice(-1) === "s" ? `${string}'` : `${string}'s`)
);

const camelcaseToSnakecase = string => (
  string.replace(/[A-Z]/g, (char, index) => index > 0 ? `_${char.toLowerCase()}` : char)
);

const camelcaseToPascalcase = string => (
  string.replace(/([^A-Z_])([A-Z])/g, "$1_$2")
);

const hyphenatedToCamelcase = string => (
  string.replace(/(-)([a-z])/g, (_fullMatch, _hyphen, startChar) => (
    startChar.toUpperCase()
  ))
);

const makeRandomString = (length) => {
  let stringLength = 5; //If no arg is present
  if (length) {
    stringLength = length;
  }
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < stringLength; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const HEX_CODE_REGEXP = /&#x([a-fA-F0-9]+);/g;

const decodeHexCodes = string => (
  string.replaceAll(HEX_CODE_REGEXP, (_match, group1) => (
    String.fromCharCode(parseInt(group1, 16))
  ))
);

const stringsAreIdentical = (string1, string2) => {
  return string1.trim() === string2.trim();
};

const isNotEmptyStringOrNull = (value) => value !== "" && value !== null

const replaceBlanksWithUnderscores = (string) => {
  return string.replaceAll(" ", "_");
};

const replaceUnderscoresWithBlanks = (string) => {
  return string.replaceAll("_", " ");
};

const validHttpsUrl = (string) => {
  if (string.startsWith("http://")) {
    return "https://" + string.substring(7, string.length);
  } else if (!string.startsWith("https://")) {
    return "https://" + string;
  } else {
    return string;
  }
}

const stripHTMLTags = (html) => {
  return html?.replace(/(<([^>]+)>)/gi, "");
};

const isHtml = (stringValue) => {
  if (typeof stringValue !== "string") {
    return false;
  }
  const regexForHTML = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
  return regexForHTML.test(stringValue);
};

export {
  replaceSubstring,
  replaceAllSubStrings,
  emptyStringToNull,
  onlyNumericString,
  ellipsize,
  makePossessive,
  camelcaseToSnakecase,
  camelcaseToPascalcase,
  hyphenatedToCamelcase,
  makeRandomString,
  decodeHexCodes,
  stringsAreIdentical,
  isNotEmptyStringOrNull,
  replaceBlanksWithUnderscores,
  replaceUnderscoresWithBlanks,
  validHttpsUrl,
  stripHTMLTags,
  isHtml,
};
