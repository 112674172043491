import ButtonDefault from "components/utils/buttonDefault.component";
import { makeStyles } from "mui-styles";
import DeleteIconRounded from "@mui/icons-material/Delete";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  deleteIcon: {
    fontSize: theme.typography.h3.fontSize,
  },
  deleteButton: {
    justifyContent: "flex-end",
    minWidth: "unset",
  },
  deleteButtonCentered: {
    justifyContent: "center"
  }
}));

const DeleteIconButton = ({
  target, onClick, center, testProp, disableReadOnlyUsers = true, disabled
}) => {
  const classes = useStyles();
  return (
    <ButtonDefault
      className={
        classNames(classes.deleteButton, center && classes.deleteButtonCentered)
      }
      variant="iconSmall"
      color="red"
      startIcon={<DeleteIconRounded className={classes.deleteIcon} />}
      component="label"
      onClick={onClick}
      data-cy={testProp ? `btn-delete-icon-${testProp}` : `btn-delete-icon`}
      disableReadOnlyUsers={disableReadOnlyUsers}
      disabled={disabled}
    >
      {`Delete${target ? ` ${target}` : ""}`}
    </ButtonDefault>
  );
};

export default DeleteIconButton;
