// import { useMemo } from "react";
import { useParams } from "react-router-dom";

/*
 * Stubs old behavior from orchestration when pks were ints
 */
const useNumericParams = () => {
  const params = useParams();
  // const numericParams = useMemo(() => (
  //   !params ? {} : (
  //     Object.entries(params).reduce((accumulator, [key, value]) => {
  //       const parsedValue = parseInt(value, 10);
  //       if (isNaN(parsedValue)) {
  //         if (isNumericOnly) {
  //           return accumulator;
  //         } else {
  //           accumulator[key] = value;
  //         }
  //       } else {
  //         accumulator[key] = parsedValue;
  //       }
  //       return accumulator;
  //     }, {})
  //   )
  // ), [isNumericOnly, params]);

  return params;
};

export default useNumericParams;
