export const downloadFileBlob = (blobData, name) => {
  // Create blob link to download
  const url = window.URL.createObjectURL(new Blob([blobData]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${name}`);
  // Append to html page
  document.body.appendChild(link);
  // Force download
  link.click();
  // Clean up and remove the link
  link.parentNode.removeChild(link);
}
