import { COMPONENT_OBJECT_TYPE_BUILDER } from "components/constants/program.constants";

export const findProgramBuilderComponent = (
  programId,
  typeRefName,
  programComponentsByProgramId,
  componentObjectsByComponentId
) => {
  const programComponents = (
    programComponentsByProgramId?.[programId]
  );
  if (programComponents && componentObjectsByComponentId) {
    for (const programComponent of programComponents) {
      const objects = componentObjectsByComponentId[
        programComponent.programComponentId
      ];
      if (objects) {
        const match = objects.find(programComponentObject => (
          programComponentObject.type === COMPONENT_OBJECT_TYPE_BUILDER &&
          programComponentObject.typeRef.includes(typeRefName)
        ));
        if (match) {
          return match;
        }
      }
    }
  }
  return null;
}

export const matchBuilderTypeRefParts = (dbTypeRef, matchTypeRefPart) => {
  if (!dbTypeRef || !matchTypeRefPart) {
    return false;
  }
  const dbTypeRefPart = dbTypeRef.split("|")[0]
  if (dbTypeRefPart === matchTypeRefPart) {
    return true;
  }
  const nonOrchestrationDbPart = (
    dbTypeRefPart.replace(/Program(?=\w)/i, "")
  );
  const nonOrchestrationMatchPart = (
    matchTypeRefPart.replace(/Program(?=\w)/i, "")
  );
  return nonOrchestrationDbPart === nonOrchestrationMatchPart;
}
