import AjaxService from "services/base/Ajax.service.js";

class UserServiceBase extends AjaxService {
  // get list of Users
  getAll() {
    return this.get(`/user/`).then(response => response.data);
  }

  // get User by userId
  getUserById(userId) {
    return this.get(`/user/${userId}`).then(response => response.data);
  }

  getAllRoles() {
    return this.get(`/user/role`).then(response => response.data);
  }

  getTaskAssigneeUsers() {
    return this
      .get(`/user/role/task/assignee`)
      .then(response => response.data);
  }

  async findUserNameById(userId) {
    if (userId) {
      const user = await this.getUserById(userId);
      return (
        `${user.payload.First_Name} ${user.payload.Last_Name}`
      );
    }
  }

  // validate a user's eligibility to be added to the system
  async validateUser(user) {
    try {
      const response = await this.post(`/user/validate`, user);
      return response.data;
    } catch (error) {
      return error?.response || error;
    }
  }

  async upsertUser(userId, userData) {
    const pathName = `/user/upsertUser${userId ? `/${userId}` : ""}`;
    const response = await this.post(pathName, userData);
    return response.data;
  }

  create(user) {
    return this
      .post(`/user/create`, { user })
      .then(response => response.data);
  }

  update(user, userId) {
    return this
      .put(`/user/update/${userId}`, { user })
      .then(response => response.data);
  }

  upsertNoAccessUser(user) {
    return this
      .post(`/user/role/noAccess/upsert`, user)
      .then(response => response.data);
  }

  getActiveCurrentUserAlerts() {
    return this
      .get(`/userAlert/activeUserAlerts`)
      .then((response) => response.data);
  }

  deleteUserAlert(userAlertId) {
    return this
      .delete(`/userAlert/dismiss/${userAlertId}`)
      .then(response => response.data);
  }

  async deactivateUser(userId) {
    const response = await this.put(
      `/auth/user/${userId}/deactivate`,
      { body: userId }
    );
    return response.data;
  }

  async reactivateUser(userId) {
    const response = await this.put(
      `/auth/user/${userId}/reactivate`,
      { body: userId }
    );
    return response.data;
  }

  async getUsersByRoleIds(roleIdsArray) {
    try {
      const roleIds = new URLSearchParams();
      roleIdsArray.forEach((roleId) => {
        roleIds.append("roleId", roleId)
      })
      const response = await this.get(
        `/user/roleIds/${roleIds}`,
        { ...this.defaultConfig }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
}

const UserService = new UserServiceBase();
export default UserService;
