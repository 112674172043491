import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "mui-styles";
import CustomLink from "components/utils/link.component";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import ButtonDefault from "components/utils/buttonDefault.component";
import { EditSharp } from "@mui/icons-material";
import FileTransferService from "services/FileTransfer.service";
import { downloadFileBlob } from "utils/download.utils";
import { Box, Divider, Paper } from "@mui/material";
import Form from "components/utils/form-elements/form.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import { H4, H5 } from "components/utils/headerV2.component";
import TitledMultiFileUploadInput from "components/utils/form-elements/titledMultiFileUploadInput.component";
import { getUploadHttpErrorMessage } from "services/util/http.util";
import useActiveConsentOrder from "hooks/useActiveConsentOrder";
import Tooltip from "components/utils/tooltip.component";
import { isDevelopmentEnv } from "core/environment";
import { HTTP_STATUS_CONTENT } from "services/constants/response.constants";
import NotificationDialog from "components/utils/dialogs/notificationDialog.component";
import { isReadOnly } from "utils/roles.utils";
import AccordionView from "components/utils/accordionView.component";
import FileTransferFileDetails from "components/pages/consentOrder/fileTransferSummary/listing/card/fileTransferFileDetails.component";
import { LOG_ACTION_TYPE_DOWNLOAD } from "components/pages/consentOrder/fileTransferSummary/constants/fileTransfer.constants";
import classNames from "classnames";

const highlightBoxShadow = `1px 3px 4px -2px rgb(0 0 0 / 20%),
5px 4px 3px -4px rgb(0 122 204 / 20%),
2px 3px 2px 1px rgb(64 145 206 / 20%),
2px 1px 10px 4px rgb(120 197 253 / 30%)`;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px 24px 0",
    fontSize: theme.typography.body2.fontSize,
  },
  errorMessage: {
    marginTop: 8,
    marginBottom: 8,
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  link: {
    display: "block",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.secondary.dark,
    fill: theme.palette.secondary.dark,
    whiteSpace: "noWrap",
    "&:hover": {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
      cursor: "pointer",
    },
    "& $downloadFileIcon": {
      color: "inherit",
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: 280,
    }
  },
  accordionSummary: {
    display: "flex",
    justifyContent: "center",
    padding: 0
  },
  accordionSummaryContent: {
    flex: "0 0 auto",
    fontWeight: theme.typography.body2.fontWeight
  },
  accordionDetail: {
    paddingRight: 0
  },
  editTagButton: {
    marginTop: 8,
  },
  sectionHeading: {
    marginBottom: 8
  },
  fileUploadInput: {
    width: "100%",
    maxWidth: 600,
    marginBottom: -16,
    paddingLeft: 16
  },
  downloadFileIcon: {
    maxHeight: 14,
    maxWidth: 14,
    marginBottom: -2,
    marginLeft: -4,
    marginRight: 8,
    color: theme.palette.primary.light,
    transform: "rotate(90deg)"
  },
  editIcon: {
    marginRight: 4,
    fontSize: 14,
  },
  tagList: {
    paddingLeft: 0
  },
  tagListItem: {
    lineHeight: "1.3",
    listStyle: "none",
  },
  tagPaper: {
    height: "100%",
    padding: 16
  },
  modalList: {
    padding: 0
  },
  modalListItem: {
    listStyle: "none",
  },
  tagLabelValue: {
    display: "flex",
    flexWrap: "wrap"
  },
  innerList: {
    marginLeft: 16,
    lineHeight: "1.3",
    listStyle: "none",
    paddingLeft: 0
  },
  innerListBlank: {
    marginTop: 4,
    fontStyle: "italic",
    lineHeight: "1.3",
    opacity: 0.6
  },
  tagAttachmentItem: {
    display: "flex",
    alignItems: "center",
    marginTop: 4
  },
  tagAttachmentBody: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%"
  },
  verticalDivider: {
    height: "100%",
    [theme.breakpoints.up("lg")]: {
      marginLeft: -8,
      paddingLeft: 24,
      borderLeft: `1px solid ${theme.palette.border.main}`,
    }
  },
  verticalDividerInline: {
    height: "100%",
    marginLeft: 16,
    marginRight: 16,
    borderLeft: `1px solid ${theme.palette.border.dark}`,
  },
  highlight: {
    boxShadow: highlightBoxShadow
  }
}));

const TOOLTIP_TAG_FILE_DELAY_MS = 1000;


export default function FileTransferCard(props) {
  const classes = useStyles();
  const {
    fileTransfer,
    setFileTransfer,
    setTagModalFileTransferId,
    highlight = false,
    readOnly = false
  } = props;
  const [activeConsentOrder] = useActiveConsentOrder();

  const [isLogOpen, setIsLogOpen] = useState(false);

  const isReadOnlyMode = useMemo(() => (
    readOnly || isReadOnly()
  ), [readOnly]);

  const uploadedAttachments = useMemo(() => (
    fileTransfer?._associations?.FileTransferAttachment || []
  ), [fileTransfer?._associations?.FileTransferAttachment]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [isAttachmentsUploading, setIsAttachmentsUploading] = useState(false);

  const lastAccessedLogItems = useMemo(() => (
    (fileTransfer?._associations?.FileTransferLog || []).filter(logItem => (
      logItem.actionType === LOG_ACTION_TYPE_DOWNLOAD
    ))
  ), [fileTransfer?._associations?.FileTransferLog]);

  const handleLogClick = useCallback(() => {
    setIsLogOpen(true)
  }, []);

  const handleDownloadAttachment = useCallback(async file => {
    try {
      const blob = await FileTransferService.downloadFileTransferAttachment(
        file.fileTransferAttachmentId
      );
      return downloadFileBlob(blob, file.fileName);
    } catch (error) {
      if (
        isDevelopmentEnv &&
        error?.response?.status === HTTP_STATUS_CONTENT
      ) {
        alert(
          "DEVELOPMENT ERROR: File missing from shared cloud storage. " +
          "This file was likely deleted in another developer's local instance."
        );
      }
    }
  }, []);

  const saveFileTransfer = useCallback(async formData => {
    try {
      setIsAttachmentsUploading(true);
      let fileTransferData = formData.get("fileTransfer") || {};
      if (typeof fileTransferData === "string") {
        fileTransferData = JSON.parse(fileTransferData);
      }
      fileTransferData.fileTransferId = fileTransfer.fileTransferId;
      fileTransferData.consentOrderId = activeConsentOrder.consentOrderId;
      formData.set("fileTransfer", JSON.stringify(fileTransferData));
      if (!formData.get("fileTransferAttachment")) {
        formData.set("fileTransferAttachment", JSON.stringify({}));
      }
      if (!formData.get("deleteAttachmentFileIds")) {
        formData.set("deleteAttachmentFileIds", JSON.stringify([]));
      }
      if (!formData.get("fileTransferTags")) {
        formData.set("fileTransferTags", JSON.stringify([]));
      }
      const response = await FileTransferService.upsertFileTransfer(formData);
      setIsAttachmentsUploading(false);
      setFileTransfer(response.payload);
    } catch (error) {
      const { status } = error.response || {};
      setErrorMessage(getUploadHttpErrorMessage(status));
      setIsAttachmentsUploading(false);
      throw error;
    }
  }, [activeConsentOrder, fileTransfer, setFileTransfer]);

  const handleFileTransferAutosave = useCallback(async (name, value) => {
    const body = new FormData();
    const fileTransferUpdates = {
      [name]: value
    };
    body.set("fileTransfer", JSON.stringify(fileTransferUpdates));
    return saveFileTransfer(body);
  }, [saveFileTransfer]);

  const handleAttachmentAdd = useCallback(async files => {
    const body = new FormData();
    if (files?.length) {
      files.forEach(attachment => {
        body.append("attachmentFiles", attachment);
      });
    }
    return saveFileTransfer(body);
  }, [saveFileTransfer]);

  const handleDeleteAttachment = useCallback(attachment => {
    const body = new FormData();
    const deletedIds = [attachment.fileTransferAttachmentId];
    body.append("deleteAttachmentFileIds", JSON.stringify(deletedIds));
    return saveFileTransfer(body);
  }, [saveFileTransfer]);

  const handleAttachmentFieldAutosave = useCallback((name, value, fileRef) => {
    const body = new FormData();
    const attachmentMatch = (
      fileTransfer._associations.FileTransferAttachment.find(attachment => (
        attachment.fileRef === fileRef
      ))
    );
    const fileTransferAttachment = {
      fileTransferAttachmentId: attachmentMatch.fileTransferAttachmentId,
      [name]: value
    }
    body.set(
      "fileTransferAttachment",
      JSON.stringify(fileTransferAttachment)
    );
    return saveFileTransfer(body);
  }, [fileTransfer._associations.FileTransferAttachment, saveFileTransfer]);

  const handleTagClick = useCallback(() => (
    setTagModalFileTransferId(fileTransfer.fileTransferId)
  ), [fileTransfer.fileTransferId, setTagModalFileTransferId]);

  return (
    <Paper
      className={classNames(
        classes.root,
        (highlight && classes.highlight)
      )}
      elevation={2}
      padding={3}
      spacing={3}
    >
      <Form name={`file-transfer-form-${fileTransfer.fileTransferId}`}>
        <FileTransferFileDetails
          fileTransfer={fileTransfer}
          lastAccessedLogItems={lastAccessedLogItems}
          onLogClick={handleLogClick}
          onTitleAutosave={handleFileTransferAutosave}
        />
        {!!errorMessage && (
          <div className={classes.errorMessage}>
            {errorMessage}
          </div>
        )}
        <Divider />
        <AccordionView
          classes={{
            summary: classes.accordionSummary,
            content: classes.accordionSummaryContent,
            detail: classes.accordionDetail
          }}
          mainLabel={(
            <Box marginLeft="auto" marginRight={2}>
              Attachments
              ({fileTransfer._associations?.FileTransferAttachment?.length || 0})
              <span className={classes.verticalDividerInline} />
              File Tags
              ({fileTransfer._associations?.FileTransferTag?.length || 0})
            </Box>
          )}
          dense
          noHoverHighlight
        >
          <GridContainer
            spacing={4}
            marginLeft={-6.5}
            hasVerticalSpacing
          >
            <GridItem xs={12} lg={5} xl={6}>
              <H4 className={classes.sectionHeading}>
                Attachments
              </H4>
              <div className={classes.fileUploadInput}>
                <TitledMultiFileUploadInput
                  name="attachments"
                  label="Attachments"
                  buttonColor="secondary"
                  isUploading={isAttachmentsUploading}
                  uploadedFiles={uploadedAttachments}
                  disabled={isReadOnlyMode}
                  onTitleAutosave={handleAttachmentFieldAutosave}
                  onChange={handleAttachmentAdd}
                  onDelete={handleDeleteAttachment}
                  onDownloadFile={handleDownloadAttachment}
                  dense
                >
                  Add Attachments
                </TitledMultiFileUploadInput>
              </div>
            </GridItem>
            <GridItem xs={12} lg={7} xl={6}>
              <div className={classes.verticalDivider}>
                <H4 className={classes.sectionHeading}>File Tags</H4>
                <GridContainer
                  component="ul"
                  className={classes.tagList}
                  spacing={2}
                  hasVerticalSpacing
                >
                  {!fileTransfer._associations.FileTransferTag?.length ? (
                    <GridItem component="li" className={classes.tagListItem}>
                      <span>None</span>
                    </GridItem>
                  ) : (
                    fileTransfer._associations.FileTransferTag.map(tag => {
                      const attachments = (
                        tag._associations?.FileTransferAttachment
                      );
                      return (
                        <GridItem
                          component="li"
                          xs={12}
                          md={6}
                          xl={12}
                          className={classes.tagListItem}
                          key={tag.fileTransferTagId}
                        >
                          <Paper className={classes.tagPaper}>
                            <div className={classes.tagLabelValue}>
                              <strong>
                                {tag.label}{tag.value ? <>:&nbsp;</> : ""}
                              </strong>
                              {!!tag.value && (
                                <span>{tag.value}</span>
                              )}
                            </div>
                            {attachments?.length ? (
                              <ul className={classes.innerList}>
                                {attachments?.map?.(attachment => (
                                  <li
                                    className={classes.tagAttachmentItem}
                                    key={attachment.fileTransferAttachmentId}
                                  >
                                    <AttachFileOutlinedIcon
                                      className={classes.downloadFileIcon}
                                    />
                                    <div className={classes.tagAttachmentBody}>
                                      {!!attachment.title && (
                                        <div
                                          className={classes.tagAttachmentTitle}
                                        >
                                          {attachment.title}
                                        </div>
                                      )}
                                      <Tooltip
                                        title={attachment.fileName}
                                        enterDelay={TOOLTIP_TAG_FILE_DELAY_MS}
                                      >
                                        <div>
                                          <CustomLink
                                            className={classes.link}
                                            variant="noHRef"
                                            onClick={() => (
                                              handleDownloadAttachment(attachment)
                                            )}
                                            key={
                                              attachment.fileTransferAttachmentId
                                            }
                                          >
                                            <span className={classes.linkText}>
                                              {attachment.fileName}
                                            </span>
                                          </CustomLink>
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <div className={classes.innerListBlank}>
                                No Attachments
                              </div>
                            )}
                          </Paper>
                        </GridItem>
                      );
                    })
                  )}
                </GridContainer>
                <ButtonDefault
                  variant="small"
                  className={classes.editTagButton}
                  disabled={isReadOnlyMode}
                  onClick={handleTagClick}
                  startIcon={<EditSharp className={classes.editIcon} />}
                >
                  Edit Tags
                </ButtonDefault>
              </div>
            </GridItem>
          </GridContainer>
        </AccordionView>
      </Form>
      {!!isLogOpen && (
        <NotificationDialog
          open={isLogOpen}
          onClose={() => setIsLogOpen(false)}
          dialogTitle="File Transfer Access Log"
          fullWidth
        >
          <ul className={classes.modalList}>
            <li className={classes.modalListItem}>
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                marginBottom={1}
              >
                <H5 color="tertiary">Accessed By</H5>
                <H5 color="tertiary">Timestamp (UTC)</H5>
              </Box>
              <Divider />
            </li>
            {lastAccessedLogItems?.map?.(logItem => (
              <li
                className={classes.modalListItem}
                key={logItem.fileTransferLogId}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                >
                  <span>{logItem.actionUser}</span>
                  <span>{logItem.actionDatetime}</span>
                </Box>
                <Divider />
              </li>
            ))}
          </ul>
        </NotificationDialog>
      )}
    </Paper>
  );
}
