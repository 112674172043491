import React from "react";
import { makeStyles } from "mui-styles";
import Title from "components/utils/title.component";
import TaskManager from "components/pages/tasks/taskManager.component";

const useStyles = makeStyles((theme) => ({
  topSectionContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const PageHeader = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.topSectionContainer}>
      <Title
        title={props.title}
        // titleOnly={props.titleOnly}
        titleOnly
        dashboardTitle={props.dashboardTitle}
        startIcon={props.startIcon}
        dense
      />
      {props.children}
      {/* Used by Component Object pages */}
      {!!props.withTasks && (
        <TaskManager
          title={props.title}
          fullScreenView={props.fullScreenView}
        />
      )}
    </div>
  );
};

export default PageHeader;
