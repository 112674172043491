import { Box } from "@mui/material";
import classNames from "classnames";
import FlowchartMultiRowItem from "components/shared/flowchart/shared/FlowchartMultiRowItem.component";
import CustomLink from "components/utils/link.component";
import { makeStyles, useTheme } from "mui-styles";
import { useMemo } from "react";

const useStyles = makeStyles((theme) => ({
  disconnectedContent: {
    color: theme.palette.error.main,
    fill: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightRegular
  },
  linksContent: {
    paddingRight: 16,
    paddingLeft: 16,
  },
}));


export default function FlowchartLinkMultiRowItem(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    allRowItems,
    connectors,
    disconnected,
    height,
    IconComponent,
    item,
    paperRef,
    tooltipPlacement
  } = props;
  const {
    link, /* Format: [{ label, sublabel, path }] */
  } = item;

  const linkHeightRems = theme.typography.body2.lineHeight;

  const minHeight = useMemo(() => {
    if (!allRowItems) {
      return;
    }
    const topLinkCount = allRowItems.reduce((max, rowItem) => (
      Math.max(
        max,
        (rowItem.links || []).reduce((sum, linkGroup) => (
          sum + (linkGroup.date ? 1 : 0) + (linkGroup.items?.length || 0)
        ), 0)
      )
    ), 0);
    const rems = topLinkCount * parseFloat(linkHeightRems);
    return `${rems}rem`;
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [allRowItems]);

  return (
    <FlowchartMultiRowItem
      classes={{
        icon: classNames(disconnected && classes.disconnectedContent),
        label: classNames(disconnected && classes.disconnectedContent),
      }}
      connectors={connectors}
      height={height}
      IconComponent={IconComponent}
      item={item}
      paperRef={paperRef}
      tooltipPlacement={tooltipPlacement}
    >
      {!!link && (
        <div className={classes.linksContent}>
          <Box minHeight={minHeight}>
            <Box
              lineHeight={linkHeightRems}
              display="flex"
              justifyContent="center"
              flexWrap="wrap"
            >
              {link.path ? (
                <CustomLink to={link.path}
                  variant="routerLink"
                  test="wf-count"
                >
                  {link.label}
                </CustomLink>
              ) : (
                <span>{link.label}</span>
              )}
              {!!link.sublabel && (
                <div className={classes.linkItemSublabel}>
                  {link.sublabel}
                </div>
              )}
            </Box>
          </Box>
        </div>
      )}
    </FlowchartMultiRowItem>
  );
}
