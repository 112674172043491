import { DATETIME_DISPLAY_NUMERIC_LONG, DATE_FORMAT_DISPLAY_NUMERIC, TIME_FORMAT_DISPLAY_SHORT } from "constants/date.constants";
import { isDevelopmentEnv } from "core/environment";
import moment from "moment";
import { findMomentHasTime } from "utils/date.utils";

/*
 * See form.component.js for expected format of `validations` config.
 */

export const VALIDATION_REQUIRED = "required";
export const VALIDATION_CHECKED = "checkboxChecked";
export const VALIDATION_GROUP_ITEM_CHECKED = "groupItemChecked";
export const VALIDATION_MAX_LENGTH = "maxLength";
export const VALIDATION_NUMERIC_MIN = "numericMin";
export const VALIDATION_NUMERIC_MAX = "numericMax";
export const VALIDATION_CASH = "cash";
export const VALIDATION_BEFORE_DATETIME = "beforeDatetime";
export const VALIDATION_AFTER_DATETIME = "afterDatetime";
export const VALIDATION_BEFORE_TIME = "beforeTime";
export const VALIDATION_AFTER_TIME = "afterTime";
export const VALIDATION_EMAIL = "email";
export const VALIDATION_PHONE = "phone";
export const VALIDATION_PHONE_LOCALE_US = "United States";

/*
 * Extremely lenient regex to avoid false negatives.
 *
 * The goal is to prevent user from entering completely different type of
 *   value, not to ensure it's formatted perfectly.
 */
const VALID_EMAIL_REGEX = /^\S+@\S+\.\S+$/;
const VALID_PHONE_REGEX = /^[^A-Za-z]{10,}$/;

const INVALID_FRACTIONAL_CENTS_REGEXP = /\..{3,}$/;

export function validate(validation, value) {
  switch (validation.type) {
    case VALIDATION_REQUIRED:
      return validateRequired(validation, value);
    case VALIDATION_MAX_LENGTH:
      return validateMaxLength(validation, value);
    case VALIDATION_NUMERIC_MIN:
      return validationNumericMin(validation, value);
    case VALIDATION_NUMERIC_MAX:
      return validationNumericMax(validation, value);
    case VALIDATION_CASH:
      return validateCash(validation, value);
    case VALIDATION_BEFORE_DATETIME:
      return validateBeforeDatetime(validation, value);
    case VALIDATION_AFTER_DATETIME:
      return validateAfterDatetime(validation, value);
    case VALIDATION_BEFORE_TIME:
      return validateBeforeTime(validation, value);
    case VALIDATION_AFTER_TIME:
      return validateAfterTime(validation, value);
    case VALIDATION_EMAIL:
      return validateEmail(validation, value);
    case VALIDATION_PHONE:
      return validatePhone(validation, value);
    case VALIDATION_CHECKED:
    case VALIDATION_GROUP_ITEM_CHECKED:
      return null;
    default:
      throw new Error(`Unsupported validation type: "${validation.type}"`);
  }
}

// Returns first error, if any
export function validateField(fieldValidations, value) {
  const fieldValidationList = [].concat(fieldValidations);
  for (const validation of fieldValidationList) {
    const valueList = [].concat(value);
    for (const valueItem of valueList) {
      const error = validate(validation, valueItem);
      if (error) {
        return error;
      }
    }
  }
  return null;
}

export function validateMissingField(fieldName, allValidations, allValues) {
  const fieldValidationList = [].concat(allValidations[fieldName] || []);
  for (const validation of fieldValidationList) {
    if (validation.type === VALIDATION_CHECKED) {
      const message = validation.message || `Must be checked`;
      return { message, validation, value: null };
    } else if (validation.type === VALIDATION_GROUP_ITEM_CHECKED) {
      const matchGroup = validation.group;
      const validationEntries = Object.entries(allValidations);
      const groupFieldNames = validationEntries
        .filter(([_name, matchValidation]) => (
          matchValidation.type === VALIDATION_GROUP_ITEM_CHECKED &&
          matchValidation.group === matchGroup
        ))
        .map(([name]) => name);

      const hasAnyGroupItem = groupFieldNames.some(name => allValues[name]);
      if (hasAnyGroupItem) {
        return;
      } else {
        const message = validation.message || `Must select at least one item`;
        return { message, validation, value: null }
      }
    }
  }
  return null;
}

export function validateRequired(validation, value) {
  let stringValue = value;
  if (value instanceof File) {
    stringValue = value.name;
  } else if (typeof value === "number") {
    if (isNaN(value)) {
      stringValue = "";
    } else {
      stringValue = `${value}`;
    }
  }
  const trimmed = stringValue ? stringValue.trim() : "";
  if ((validation.required || validation.type === VALIDATION_REQUIRED) && !trimmed) {
    const message = validation.message || `Required field`;
    return { message, validation, stringValue };
  }
  return null;
}

export function validateMaxLength(validation, value) {
  const max = validation.value;
  if (!max && isDevelopmentEnv) {
    throw new Error("`value` must be defined for max length form validation");
  }
  if (typeof value === "string" && value.length > max) {
    const message = validation.message || `Must be ${max} characters or less`;
    return { message, validation, value };
  }
  return null;
}

export function validationNumericMin(validation, value) {
  const min = validation.value;
  if (!min && isDevelopmentEnv) {
    throw new Error("`value` must be defined for minimum number validation.");
  }
  if (typeof value === "string" || typeof value === "number") {
    if (Number(value) < min) {
      const message = validation.message || `Must be $${min} or more`;
      return { message, validation, value };
    }
  }
  return null;
}

export function validationNumericMax(validation, value) {
  const max = validation.value;
  if (!max && isDevelopmentEnv) {
    throw new Error("`value` must be defined for maximum number validation.");
  }
  if (typeof value === "string" || typeof value === "number") {
    if (Number(value) > max) {
      const message = validation.message || `Must be $${max} or less`;
      return { message, validation, value };
    }
  }
  return null;
}

// Special `validation` attributes: { dollarsOnly }
export function validateCash(validation, value) {
  if (value && typeof value === "string") {
    let errorMessage = "";
    const numeric = Number(value);
    if (isNaN(numeric) || numeric < 0) {
      errorMessage = validation.message || "Invalid cash value.";
    }
    if (validation.dollarsOnly) {
      if (Math.ceil(numeric) === numeric) {
        errorMessage = validation.message || "Must be whole dollars only";
      }
    } else if (INVALID_FRACTIONAL_CENTS_REGEXP.test(value)) {
      errorMessage = validation.message || "Must be whole cents only.";
    }
    if (errorMessage) {
      return { validation, value, message: errorMessage };
    }
  }
  return null;
}

function formatErrorDatetime(validationMoment) {
  if (findMomentHasTime(validationMoment)) {
    return validationMoment.format(DATETIME_DISPLAY_NUMERIC_LONG);
  }
  return validationMoment.format(DATE_FORMAT_DISPLAY_NUMERIC);
}

export function validateBeforeDatetime(validation, value) {
  const datetime = moment(value);
  const before = moment(validation.value);
  if (!before && isDevelopmentEnv) {
    throw new Error(
      "`value` must be defined for `before datetime` form validation"
    );
  }
  if (datetime.isValid() && !datetime.isBefore(before, "seconds")) {
    const message = (
      validation.message ||
      `Must be before ${formatErrorDatetime(before)}`
    );
    return { message, validation, value };
  }
  return null;
}

export function validateAfterDatetime(validation, value) {
  const datetime = moment(value);
  const after = moment(validation.value);
  if (!after && isDevelopmentEnv) {
    throw new Error(
      "`value` must be defined for `after datetime` form validation"
    );
  }
  if (datetime.isValid() && !datetime.isAfter(after, "seconds")) {
    const message = (
      validation.message ||
      `Must be after ${formatErrorDatetime(after)}`
    );
    return { message, validation, value };
  }
  return null;
}

export function validateBeforeTime(validation, value) {
  const time = moment(`0001-01-01 ${value}`);
  const beforeTime = moment(`0001-01-01 ${validation.value}`);
  if (!beforeTime.isValid() && isDevelopmentEnv) {
    throw new Error(
      "`value` must be defined for `before time` form validation"
    );
  }
  if (time.isValid() && !time.isBefore(beforeTime, "seconds")) {
    const message = (
      validation.message ||
      `Must be before ${beforeTime.format(TIME_FORMAT_DISPLAY_SHORT)}`
    );
    return { message, validation, value };
  }
  return null;
}

export function validateAfterTime(validation, value) {
  const time = moment(`0001-01-01 ${value}`);
  const afterTime = moment(`0001-01-01 ${validation.value}`);
  if (!afterTime.isValid() && isDevelopmentEnv) {
    throw new Error(
      "`value` must be defined for `after time` form validation"
    );
  }
  if (time.isValid() && !time.isAfter(afterTime, "seconds")) {
    const message = (
      validation.message ||
      `Must be after ${afterTime.format(TIME_FORMAT_DISPLAY_SHORT)}`
    );
    return { message, validation, value };
  }
  return null;
}

export function validateEmail(validation, value) {
  if (value && typeof value === "string" && !value.match(VALID_EMAIL_REGEX)) {
    const message = validation.message || `Must be a valid email address.`;
    return { message, validation, value };
  }
  return null;
}

export const validPhone = (phone) => VALID_PHONE_REGEX.test(phone)

export function validatePhone(validation, value) {
  if (value) {
    const stringValue = typeof value === "string" ? value : value.toString();
    const errorMessage = validation.message || `Must be a valid phone number.`;
    if (!validPhone(stringValue.trim())) {
      return { message: errorMessage, validation, stringValue };
    }
    if (validation.locale) {
      const isLocaleValid = validatePhoneLocale(validation, stringValue);
      if (!isLocaleValid) {
        return { message: errorMessage, validation, stringValue };
      }
    }
  }
  return null;
}

export function validatePhoneLocale(validation, value) {
  if (value) {
    const requireCountryCode = !!validation.localeCodeRequired;
    const stringNumber = typeof value === "string" ? value : value.toString();
    const digitsOnly = stringNumber.replace(/[^0-9]*/g, "");
    switch (validation.locale) {
      case VALIDATION_PHONE_LOCALE_US:
        if (!requireCountryCode && digitsOnly.length === 10) {
          return true;
        }
        return digitsOnly.startsWith("1") && digitsOnly.length === 11;
      case undefined:
      default:
        return true;
    }
  }
}

