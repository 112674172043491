import React, { useContext, useEffect, useMemo, useState } from "react";
import { makeStyles } from "mui-styles";
import AlertContext from "contexts/alert.context";
import InlineSVGIcons from "components/utils/inlineSVGIcons.component.js";
import Badge from "components/utils/badge.component";
import useActiveConsentOrder from "hooks/useActiveConsentOrder";
import StorageService from "services/Storage.service";

const useStyles = makeStyles((theme) => ({
  alertsLinkWrapper: {
    height: 20,
    display: "flex",
    "&:hover": {
      cursor: "pointer",
    },
  },
  badge: {
    right: -2,
    top: 1,
    border: `1px solid white`,
    padding: "0 2px",
    fontSize: theme.typography.caption.fontSize,
    height: 20,
    fontWeight: "bold",
    borderRadius: 40,
    backgroundColor: theme.palette.teal.main,
  },
  icon: {
    fill: "white",
  },
}));


const HeaderAlert = ({ openAlerts }) => {
  const classes = useStyles();
  const { state } = useContext(AlertContext);
  const [activeConsentOrder] = useActiveConsentOrder();

  const [isBadgeAnimated, setIsBadgeAnimated] = useState(false);
  const [isBadgeVisible, setIsBadgeVisible] = useState(false);

  const programAlerts = useMemo(() => (
    state.userAlerts?.filter?.((alert) => (
      alert?.consentOrderId === activeConsentOrder?.consentOrderId
    ))
  ), [state.userAlerts, activeConsentOrder])

  const isAlertsPending = (
    activeConsentOrder ? !programAlerts : !state.userAlerts
  )
  const alertCount = (
    isAlertsPending ?
      null :
      activeConsentOrder ? programAlerts.length : state.userAlerts.length
  );

  useEffect(function makeBadgeVisibleOnceAnimated() {
    if (isBadgeAnimated) {
      setIsBadgeVisible(true);
    }
  }, [isBadgeAnimated]);

  useEffect(function decideBadgeAnimationAndLastViewed() {
    if (isAlertsPending) {
      return;
    }

    const allLastVisibleCount = parseInt(
      StorageService.getItem(
        StorageService.STORAGE_KEY_LAST_VISIBLE_ALERTS_COUNT,
        false
      )
    );
    if (state.userAlerts?.length !== allLastVisibleCount) {
      StorageService.setItem(
        StorageService.STORAGE_KEY_LAST_VISIBLE_ALERTS_COUNT,
        state.userAlerts?.length,
        false
      );
      if (!activeConsentOrder?.consentOrderId && !isNaN(allLastVisibleCount)) {
        setIsBadgeAnimated(true);
        return;
      }
    }
    if (activeConsentOrder?.consentOrderId) {
      const storageKey = (
        StorageService.STORAGE_KEY_LAST_VISIBLE_ALERTS_COUNT +
        `--${activeConsentOrder.consentOrderId}`
      );
      const programLastVisibleCount = parseInt(
        StorageService.getItem(storageKey, false)
      );
      if (programAlerts.length !== programLastVisibleCount) {
        StorageService.setItem(storageKey, programAlerts.length, false);
        if (!isNaN(programLastVisibleCount)) {
          setIsBadgeAnimated(true);
          return;
        }
      }
    }
    setIsBadgeVisible(true);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [activeConsentOrder, isAlertsPending]);

  return (
    <div
      className={classes.alertsLinkWrapper}
      onClick={openAlerts}
      data-cy="alert-icon-header"
    >
      <InlineSVGIcons variant="NotificationsHeavy" className={classes.icon} />
      {(!!isBadgeAnimated || !!isBadgeVisible) && (
        <Badge
          alertCount={alertCount}
          classes={{ badge: classes.badge }}
          color="secondary"
          data-cy="badge-header-alert"
          max={999}
          invisible={!isBadgeVisible}
          overlap="rectangular"
        />
      )}
    </div>
  );
};

export default HeaderAlert;
