import React from "react";
import BackToLink from "components/utils/backToLink.component";
import HeaderPrimary from "components/utils/header.component";
import CollapsableAlert from "components/utils/collapsableAlert.component";
import { builderSubHeader } from "components/builder/utils/builder.utils";
import Alert from "@mui/lab/Alert";
import { makeStyles } from "mui-styles";
import { ACTION_SET_BUILDER_STATUS_MESSAGE } from "reducers/shared/builder/builder.reducer";

const useStyles = makeStyles((theme) => ({
  contentClass: {
    marginTop: 4
  },
  modeTitle: {
    paddingBottom: 0,
  },
}));

const BuilderHeader = ({
  readOnly,
  mode,
  type,
  setBuilderMode,
  activeForm,
  setOpenDialog,
  statusMessage,
  dispatch,
}) => {
  const classes = useStyles();

  const closeAlert = () => {
    dispatch({
      type: ACTION_SET_BUILDER_STATUS_MESSAGE,
      payload: null,
    });
  };

  return (
    <>
      {readOnly ? (
        <Alert
          severity="info"
          data-cy="alert-builder-readOnly"
        >
          You are in Read-Only mode
        </Alert>
      ) : (
        <HeaderPrimary variant="h3Primary" className={classes.modeTitle}>
          {builderSubHeader(mode, type)}
        </HeaderPrimary>
      )}
      <BackToLink
        variant="subLink"
        text="Return to Builder Summary"
        onClick={
          activeForm && !readOnly
            ? () => setOpenDialog(true) //TODO IF NEEDS TO RETURN THIS
            : () => setBuilderMode()
        }
      />
      <div data-cy="builder-alert">
        <CollapsableAlert
          showAlert={!!statusMessage?.message}
          closeClick={closeAlert}
          message={statusMessage?.message}
          className={classes.contentClass}
          severity={statusMessage?.type}
        />
      </div>
    </>
  );
};
export default BuilderHeader;
