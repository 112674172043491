import React, { useCallback, useState } from "react";
import { makeStyles } from "mui-styles";
import ButtonDefault from "components/utils/buttonDefault.component";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import { Grid } from "@mui/material";
import classNames from "classnames";
import ClipLoader from "components/utils/clipLoad.component";
import CachedIcon from "@mui/icons-material/Cached";
import AddIcon from "@mui/icons-material/Add";
import FileService from "services/File.service";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteIconRounded from "@mui/icons-material/Delete";
import SaveStatus from "components/utils/saveStatus.component";
import { HTTP_STATUS_NO_CONTENT } from "services/constants/response.constants";
import NotificationDialog from "components/utils/dialogs/notificationDialog.component";
import { CheckCircleOutline } from "@mui/icons-material";


const useStyles = makeStyles((theme) => ({
  dualButtonWrapper: {
    width: "70%",
    minWidth: 300,
    margin: "0px auto",
    justifyContent: "space-around",
    display: "flex",
    padding: "20px 0px 20px 0px",
  },
  assessmentBuilderButtons: {
    width: 120,
    height: 120,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  builderButtonsWrapper: {
    width: 120,
    height: 340,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    position: "fixed",
    bottom: "3%",
    right: "3%",
    zIndex: 5,
  },
  animationWrapper: {
    display: "flex",
    marginRight: 8,
  },
  saveIcon: {
    fontSize: theme.typography.h4.fontSize,
    marginRight: 8,
    color: "white",
  },
  cancelIcon: {
    fontSize: theme.typography.h4.fontSize,
    marginRight: 8,
    color: "white",
  },
  printPreview: {
    backgroundColor: theme.palette.teal.main,
    "&:hover": {
      backgroundColor: theme.palette.teal.dark,
    },
  },
}));

const DualFormButtons = (props) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [openUnsavedDialog, setOpenUnsavedDialog] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showUnsavedError, setShowUnsavedError] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const downloadBuilderFile = useCallback(async () => {
    const { Approval_Date, Builder_ID, Title, Version } = props.builder;

    const id = Builder_ID;
    const name = `${Title}-Version${Version}-${Approval_Date}`;

    if (!id) {
      setShowUnsavedError(true);
      setLoadingDownload(null);
      setOpenUnsavedDialog(true);
      return;
    }
    setLoadingDownload(id);
    setShowError(false);

    // download file
    const blob = await FileService.builderDocDownload(id);

    if (blob.status === HTTP_STATUS_NO_CONTENT) {
      setShowError(true);
      setLoadingDownload(null);
      setOpenDialog(true);
    } else {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.pdf`);
      // Append to html page
      document.body.appendChild(link);
      // Force download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
    setLoadingDownload(null);
  }, [props.builder]);

  switch (props.variant) {
    case "publish":
      return (
        <Grid
          container
          item
          xs={12}
          className={classNames(classes.dualButtonWrapper, props.className)}
        >
          <ButtonDefault
            variant="medium"
            background="primary"
            startIcon={
              props.isPublishing ? (
                <span className={classes.animationWrapper}>
                  <ClipLoader />
                </span>
              ) : (
                <PublishOutlinedIcon className={classes.saveIcon} />
              )
            }
            type={props.type}
            onClick={props.saveOnClick}
            m={2}
            disabled={props.disabled}
            disableReadOnlyUsers
            data-cy="btn-publishDoc"
          >
            {props.publishLabel || "Publish"}
          </ButtonDefault>
          <ButtonDefault
            variant="medium"
            background="grey"
            startIcon={
              <BlockOutlinedIcon
                className={classes.cancelIcon}
              />
            }
            onClick={props.cancelOnClick}
            data-cy="btn-cancelPublish"
          >
            Cancel
          </ButtonDefault>
        </Grid>
      );
    case "builder":
      return (
        <Grid
          container
          item
          xs={12}
          className={classNames(classes.builderButtonsWrapper, props.className)}
        >
          <SaveStatus
            isSaving={props.isSaving}
            errorMessage={
              props.statusMessage?.type === "error" && props.statusMessage.message
            }
            infoMessage={
              props.statusMessage?.type === "info" && props.statusMessage.message
            }
            saveMessage={
              props.statusMessage?.type === "success" && props.statusMessage.message
            }
            variant="builder"
          />

          <ButtonDefault
            variant={props.buttonVariant}
            startIcon={
              props.isSaving && !props.disabled ? (
                props.isSaving === "saved" ? (
                  <CheckCircleOutline />
                ) : (
                  <span className={classes.animationWrapper}>
                    <ClipLoader />
                  </span>
                )
              ) : (
                <SaveOutlinedIcon className={classes.saveIcon} />
              )
            }
            onClick={props.saveOnClick}
            m={2}
            disabled={props.disabled}
            disableReadOnlyUsers
            form={props.form}
            type="submit"
            data-cy="btn-builder-save"
          >
            Save
          </ButtonDefault>
          <ButtonDefault
            className={classes.printPreview}
            startIcon={
              loadingDownload ? (
                <span className={classes.animationWrapper}>
                  <ClipLoader />
                </span>
              ) : (
                <VisibilityOutlinedIcon className={classes.saveIcon} />
              )
            }
            onClick={downloadBuilderFile}
            data-cy="btn-builder-preview"
          >
            Preview
          </ButtonDefault>
          {!!showError && (
            <NotificationDialog
              open={openDialog}
              onClose={() => setOpenDialog(false)}
              prompt={
                "Document does not contain saved sections. " +
                "Please add sections in order to preview."
              }
            />
          )}
          {!!showUnsavedError && (
            <NotificationDialog
              open={openUnsavedDialog}
              onClose={() => setOpenUnsavedDialog(false)}
              setOpenDialog={setOpenUnsavedDialog}
              prompt="Document was not saved. Please save in order to preview."
            />
          )}
          <ButtonDefault
            variant={props.buttonVariant}
            background="primary"
            startIcon={
              props.isPublishing === "published" ? (
                <CheckCircleOutline />
              ) : (
                <PublishOutlinedIcon className={classes.saveIcon} />
              )
            }
            disabled={props.disablePublish}
            onClick={props.publishOnClick}
            data-cy="btn-builder-publish"
            disableReadOnlyUsers
          >
            Publish
          </ButtonDefault>
          <ButtonDefault
            variant="medium"
            background="grey"
            startIcon={
              <BlockOutlinedIcon
                className={classes.cancelIcon}
              />
            }
            onClick={props.cancelOnClick}
            data-cy="btn-builder-cancel"
          >
            EXIT
          </ButtonDefault>
        </Grid>
      );
    case "loadTemp":
      return (
        <Grid
          container
          item
          xs={12}
          className={classNames(classes.dualButtonWrapper, props.className)}
        >
          <ButtonDefault
            variant="medium"
            background="primary"
            startIcon={<AddIcon className={classes.saveIcon} />}
            onClick={props.addOnClick}
            m={2}
            disabled={props.disabledFramework}
            type="submit"
            data-cy="btn-loadTemp-AddFramework"
            disableReadOnlyUsers
          >
            Add Framework
          </ButtonDefault>
          <ButtonDefault
            startIcon={<CachedIcon className={classes.saveIcon} />}
            onClick={props.saveOnClick}
            m={2}
            disabled={props.disabled}
            type="submit"
            data-cy="btn-LoadTemplate"
          >
            Load Template
          </ButtonDefault>
          <ButtonDefault
            variant="medium"
            background="grey"
            startIcon={<BlockOutlinedIcon className={classes.cancelIcon} />}
            onClick={props.cancelOnClick}
            data-cy="btn-Cancel"
          >
            Cancel
          </ButtonDefault>
        </Grid>
      );
    case "addFramework":
      return (
        <Grid
          container
          item
          xs={12}
          className={classNames(classes.dualButtonWrapper, props.className)}
        >
          <ButtonDefault
            variant="small"
            onClick={props.saveOnClick}
            m={2}
            disabled={props.disabled}
            form={props.form}
            type="submit"
            startIcon={props.startIcon}
            data-cy="btn-AddFramework"
            disableReadOnlyUsers
          >
            {props.addText ? props.addText : `Add Framework`}
          </ButtonDefault>
          <ButtonDefault
            variant="small"
            background="grey"
            startIcon={<BlockOutlinedIcon className={classes.cancelIcon} />}
            onClick={props.cancelOnClick}
            data-cy="btn-cancelFramework"
          >
            Cancel
          </ButtonDefault>
        </Grid>
      );
    case "assessmentBuilder":
      return (
        <Grid
          container
          item
          xs={12}
          className={classNames(
            classes.assessmentBuilderButtons,
            props.className
          )}
        >
          <ButtonDefault
            variant="small"
            startIcon={
              props.isSaving ? (
                props.isSaving === "saved" ? (
                  <CheckCircleOutline />
                ) : (
                  <span className={classes.animationWrapper}>
                    <ClipLoader />
                  </span>
                )
              ) : (
                <SaveOutlinedIcon className={classes.saveIcon} />
              )
            }
            onClick={props.saveOnClick}
            m={2}
            disabled={props.disabled}
            form={props.form}
            type="submit"
            data-cy={props.saveTest ? `btn-Save-${props.saveTest}` : "btn-Save"}
            disableReadOnlyUsers
          >
            {props.addText ? props.addText : `SAVE`}
          </ButtonDefault>
          <ButtonDefault
            variant="small"
            background="grey"
            startIcon={<BlockOutlinedIcon className={classes.cancelIcon} />}
            onClick={props.cancelOnClick}
            data-cy={
              props.cancelTest ? `btn-Cancel-${props.cancelTest}` : "btn-Cancel"
            }
          >
            {props.cancelText ? props.cancelText : `CANCEL`}
          </ButtonDefault>
        </Grid>
      );
    case "editCommitteeMember":
    default:
      return (
        <Grid
          container
          item
          xs={12}
          className={classNames(classes.dualButtonWrapper, props.className)}
        >
          <ButtonDefault
            startIcon={
              props.isSaving ? (
                props.isSaving === "saved" ? (
                  <CheckCircleOutline />
                ) : (
                  <span className={classes.animationWrapper}>
                    <ClipLoader />
                  </span>
                )
              ) : (
                <SaveOutlinedIcon className={classes.saveIcon} />
              )
            }
            onClick={props.saveOnClick}
            m={2}
            disabled={props.disabled}
            form={props.form}
            type="submit"
            data-cy={props.saveTest ? `btn-Save-${props.saveTest}` : "btn-Save"}
            variant="small"
            disableReadOnlyUsers
          >
            {props.addText ? props.addText : `SAVE`}
          </ButtonDefault>
          {!!props.deleteClick && (
            <ButtonDefault
              variant="small"
              background="red"
              onClick={props.deleteClick}
              startIcon={<DeleteIconRounded className={classes.saveIcon} />}
              data-cy="btn-Delete"
              disableReadOnlyUsers
            >
              {props.deleteText ? props.deleteText : "DELETE"}
            </ButtonDefault>
          )}
          {props.children}
          <ButtonDefault
            variant="small"
            background="grey"
            startIcon={<BlockOutlinedIcon className={classes.cancelIcon} />}
            onClick={props.cancelOnClick}
            data-cy={
              props.cancelTest ? `btn-Cancel-${props.cancelTest}` : "btn-Cancel"
            }
          >
            {props.cancelText ? props.cancelText : `CANCEL`}
          </ButtonDefault>
        </Grid>
      );
  }
};

export default DualFormButtons;
