// packages
import { makeStyles } from "mui-styles";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: "100%",
    height: "100%",
    display: "flex",
    backgroundColor: "rgba(235, 235, 235)",
    padding: 40,
    justifyContent: "center",
    alignItems: "center",
  },
}));

const AuthFormWrapper = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.drawer}>
      {props.children}
    </div>
  )
}

export default AuthFormWrapper;
