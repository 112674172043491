import AxiosService from "./base/Axios.service";
import authHeader from "services/util/authHeader.util";

class ProgramServiceBase {
  getAllBy() {
    return AxiosService
      .get(`/program`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getAllPrograms() {
    return AxiosService
      .get("/program", { headers: authHeader() })
      .then((response) => response.data)
  }

  getProgramComponentsByProgramId(programId) {
    return AxiosService
      .get(
        `/program/programComponents/${programId}`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      });
  }

  getProgramComponentObjectsByProgramId(programId) {
    return AxiosService
      .get(`/program/${programId}/componentObject`, { headers: authHeader() })
      .then((response) => response.data);
  }

  getComponentObjectById(componentObjectId) {
    const path = (
      `/program/componentObjectById/${componentObjectId}`
    )
    return AxiosService
      .get(path, { headers: authHeader() })
      .then((response) => response.data);
  }

  getComponentObjectByProgramAndTypeValues(
    programId, type, typeRef
  ) {
    const path = (
      `/program/${programId}/componentObjects/type/${type}/typeRef/${typeRef}`
    )
    return AxiosService
      .get(path, { headers: authHeader() })
      .then(response => response.data);
  }

  getComponentObjectTypeRefFamily(type, typeRef) {
    const path = (
      `/program/componentObject/type/${type}/typeRef/${typeRef}/family`
    )
    return AxiosService
      .get(path, { headers: authHeader() })
      .then(response => response.data);
  }

  getActiveProgramComponentIds() {
    return AxiosService.get(
      `/program/programComponents/active/index/ids`,
      { headers: authHeader() }
    ).then(response => response.data);
  }

  getActiveComponentObjectIds() {
    return AxiosService.get(
      `/program/componentObjects/active/index/ids`,
      { headers: authHeader() }
    ).then(response => response.data);
  }
}

const ProgramService = new ProgramServiceBase();
export default ProgramService;
