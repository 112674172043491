import React, { useMemo } from "react";
import { Select, InputLabel, FormControl, MenuItem } from "@mui/material";
import { makeStyles } from "mui-styles";
import classNames from "classnames";
import FormLabel from "components/utils/form-elements/formLabel.component";
import { isReadOnly } from "utils/roles.utils";
import FormElementBase from "./shared/formElementBase.component";
import CustomFormHelperText from "components/utils/form-elements/styled/formHelperText/customFormHelperText.component";

const useStyles = makeStyles((theme) => ({
  dropDownSelect: {
    width: "100%",
    fontSize: theme.typography.body2.fontSize,
  },
  selectOutlined: {
    "&.Mui-focused ~ fieldset": {
      borderLeftWidth: "6px !important",
      padding: "4px !important",
    },
    "& ~ fieldset": {
      borderColor: theme.palette.secondary.main,
      borderWidth: 2,
    },
    "&:hover:not(:.Mui-disabled) ~ fieldset": {
      borderColor: `${theme.palette.primary.main} !important;`,
    },
    "& ~ input:valid ~ fieldset": {
      borderColor: theme.palette.secondary.main,
      borderWidth: 2,
    },
    "& ~ input:valid:focus ~ fieldset": {
      borderLeftWidth: "6px !important",
      padding: "4px !important", // override inline-style
    },
    "& ~ input:invalid ~ fieldset": {
      borderColor: `${theme.palette.error.light} !important;`,
      borderWidth: 2,
    },
  },
  selectDense: {
    padding: "10px 12px"
  },
  formLabel: {
    flex: 1
  },
  customInputLabel: {
    whiteSpace: "nowrap",
    [theme.breakpoints.down("md")]: {
      fontSize: theme.typography.body2.fontSize,
    },
  },
  heightLabelInput: {
    height: 36,
  },
  readOnly: {
    "& .MuiInputBase-input.Mui-disabled": {
      color: "#000",
      "&.MuiInput-underline:before": {
        borderColor: theme.palette.border.dark
      }
    }
  },
}));


const CustomSelect = props => {
  const { name, onAutosave, onChange } = props;
  const handleChange = useMemo(() => (
    onChange ||
    (onAutosave && (
      event => onAutosave(name, event.target.value, event)
    ))
  ), [name, onAutosave, onChange]);

  return (
    <FormElementBase onChange={handleChange} {...props} >
      <CustomSelectBase {...props} />
    </FormElementBase>
  );
};

const CustomSelectBase = (props) => {
  const classes = useStyles();

  const isReadOnlyRole = useMemo(() => (
    props.readOnly ?? isReadOnly()
  ), [props.readOnly]);

  const isDefaultValueInProps = "defaultValue" in props;

  const defaultValue = useMemo(() => (
    isDefaultValueInProps ? (props.defaultValue || "") : undefined
  ), [props.defaultValue, isDefaultValueInProps]);


  /*
   * Special cases handled:
   * 1. If `options` prop is passed, render items based on them.
   * 2. If `children` items are contained in a Fragment, use inner items array
   */
  const children = useMemo(() => {
    if (!props.children) {
      if (Array.isArray(props.options)) {
        return props.options.map(option => (
          <MenuItem
            key={option.value}
            value={option.value}
            data-cy={option.label}
          >
            {option.label}
          </MenuItem>
        ));
      }
      return null;
    }
    if (!Array.isArray(props.children) && props.children.props?.children) {
      return props.children.props?.children;
    }
    return props.children;
  }, [props.children, props.options]);

  const value = useMemo(() => {
    if (props.value) {
      return props.value;
    } else {
      const isFalsyValueInOptions = props?.options?.some?.(option => (
        props.value === option.value
      ));
      if (isFalsyValueInOptions) {
        return props.value;
      }
    }
    if (isDefaultValueInProps) {
      return undefined;
    }
    return "";
  }, [isDefaultValueInProps, props.options, props.value]);

  if (props.variant === "stacked") {
    return (
      <FormControl className={props.className}>
        <InputLabel
          className={classes.customInputLabel}
          required={props.labelRequired}
          id={props.labelId}
          error={!!props.labelError && !isReadOnlyRole}
          /*
           * name={props.name}
           * value={props.value}
           */
          shrink={props.shrink}
          classes={props.inputLabelClasses}
        >
          {props.label}
        </InputLabel>
        <Select
          className={
            classNames(
              classes.dropDownSelect,
              isReadOnlyRole && classes.readOnly,
              props.labelInputRow && classes.heightLabelInput,
            )
          }
          classes={{
            outlined: classNames(
              classes.selectOutlined,
              props.margin === "dense" && classes.selectDense,
              props.classes?.status
            ),
            ...props.classes,
          }}
          error={!!props.error && !isReadOnlyRole}
          labelId={props.labelId}
          onChange={props.onChange}
          value={value}
          defaultValue={defaultValue}
          renderValue={props.renderValue}
          required={props.required}
          disabled={props.disabled || isReadOnlyRole}
          name={props.name}
          margin={props.margin}
          data-cy={`select-${props.test}`}
          MenuProps={props.MenuProps}
        >
          {children}
        </Select>
        {!!props.errorMessage && (
          <CustomFormHelperText
            error
          >
            {props.errorMessage}
          </CustomFormHelperText>
        )}
      </FormControl>
    )
  } else if (props.variant === "selectInner") {
    return (
      <>
        <InputLabel
          className={classes.customInputLabel}
          required={props.labelRequired}
          id={props.labelId}
          error={props.labelError}
          /*
           * name={props.name}
           * value={props.value}
           */
          shrink={props.shrink}
        >
          {props.label}
        </InputLabel>
        <Select
          className={
            classNames(
              classes.dropDownSelect,
              isReadOnlyRole && classes.readOnly,
              props.labelInputRow && classes.heightLabelInput,
              props.className
            )
          }
          classes={{
            outlined: classNames(
              classes.selectOutlined,
              props.margin === "dense" && classes.selectDense,
              props.classes?.status
            ),
            ...props.classes,
          }}
          error={props.error}
          labelId={props.labelId}
          onChange={props.onChange}
          value={value}
          defaultValue={defaultValue}
          renderValue={props.renderValue}
          required={props.required}
          disabled={props.disabled || isReadOnlyRole}
          name={props.name}
          data-cy={`select-${props.test}`}
          MenuProps={props.MenuProps}
        >
          {children}
        </Select>
        <CustomFormHelperText
          error={!!props.errorMessage}
        >
          {props.helperText || props.errorMessage}
        </CustomFormHelperText>
      </>
    );

  } else {
    const isRequired = props.required;
    return (
      <>
        {!!props.label && (
          <FormLabel
            label={<>{props.label}{props.required ? `*` : null}</>}
            htmlFor={props.labelId}
            labelClassName={classes.formLabel}
            variant="default"
            error={props.error}
            primaryMain={props.formLabelPrimaryMain}
          />
        )}
        <Select
          className={
            classNames(
              classes.dropDownSelect,
              isReadOnlyRole && classes.readOnly,
              props.labelInputRow && classes.heightLabelInput,
              props.className
            )
          }
          classes={{
            outlined: classNames(
              classes.selectOutlined,
              props.margin === "dense" && classes.selectDense,
              props.classes?.status
            ),
            ...props.classes,
          }}
          error={props.error}
          labelId={props.labelId}
          onChange={props.onChange}
          onClose={props.onClose}
          value={value}
          defaultValue={defaultValue}
          disabled={props.disabled || isReadOnlyRole}
          name={props.name}
          required={isRequired}
          renderValue={props.renderValue}
          data-cy={`select-${props.test}`}
          displayEmpty={props.displayEmpty}
          margin={props.margin}
          multiple={props.multiple}
          MenuProps={props.MenuProps}
        >
          {children}
        </Select>
        {!props.hideHelperText && (
          <CustomFormHelperText
            error={!!props.errorMessage}
            data-cy={`select-helper-${props.test}`}
          >
            {props.helperText || props.errorMessage}
          </CustomFormHelperText>
        )}
      </>
    );
  }
};

export default CustomSelect;
