
// Moment-compatible date format strings

export const DATE_FORMAT_ISO = "YYYY-MM-DD";
export const DATE_FORMAT_DISPLAY_NUMERIC = "MM/DD/YYYY";
export const DATE_FORMAT_DISPLAY_NUMERIC_SHORT = "M/D/YYYY";
export const DATE_FORMAT_DISPLAY_FULL = "dddd, MMMM Do YYYY";
export const DATE_FORMAT_DISPLAY_TEXT_LONG = "MMMM Do, YYYY";

export const DATETIME_DISPLAY_NUMERIC_LONG = "MM/DD/YYYY, hh:mm\xA0A";
export const DATETIME_FORMAT_24H = "YYYY-MM-DD HH:mm:ss";
export const DATETIME_FORMAT_ISO = "YYYY-MM-DDTHH:mm:ss";

export const TIME_FORMAT_24H = "HH:mm:ss";
export const TIME_FORMAT_12H_SHORT = "h:mm a";
export const TIME_FORMAT_DISPLAY_SHORT = "LT";
export const TIME_FORMAT_DISPLAY_SECONDS = "LTS";

export const MOMENT_INVALID_DATE = "Invalid date";
