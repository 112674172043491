import { Box, Divider } from "@mui/material";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import { H3, H5 } from "components/utils/headerV2.component";
import LabelValuePair from "components/utils/labelValuePair.component";
import { DATETIME_DISPLAY_NUMERIC_LONG } from "constants/date.constants";
import moment from "moment";
import { Fragment, useCallback, useMemo } from "react";
import { mapObjectArrayByKey } from "utils/arrayOfObjects.utils";
import ComsCard from "components/utils/cards/comsCard.component";
import { makeStyles } from "mui-styles";
import CheckListing from "components/shared/payments/CheckListing.component";
import { ACTION_REPLACE_CLAIM_PAYMENTS, ACTION_REPLACE_WORKFLOW_INSTANCES } from "reducers/shared/claimReview.reducer";

const useStyles = makeStyles(() => ({
  outcomeEvaluationDC: {
    justifyContent: "start"
  }
}))

const ClaimOutcomesCard = (props) => {
  const classes = useStyles();
  const {
    claim,
    dispatch,
    evaluationTemplate,
  } = props;

  const {
    ClaimFinding: claimFindings,
    EvaluationDC: evaluations,
  } = claim._associations;

  const evaluationTemplateDCFieldsById = useMemo(() => {
    const evaluationTemplateDCFields =
      evaluationTemplate._associations.EvaluationTemplateDCField;
    return mapObjectArrayByKey(evaluationTemplateDCFields, "evaluationTemplateDCFieldId")
  }, [evaluationTemplate])

  const evaluationTemplateDCResponseOptionById = useMemo(() => {
    const evaluationTemplateDCFields =
      evaluationTemplate._associations.EvaluationTemplateDCField;
    const responseOptions = evaluationTemplateDCFields
      .flatMap((dcField) => (dcField._associations.EvaluationTemplateDCResponseOption))
    return mapObjectArrayByKey(responseOptions, "evaluationTemplateDCResponseOptionId")
  }, [evaluationTemplate])

  const setWfInstances = useCallback(wfInstances => {
    dispatch({
      type: ACTION_REPLACE_WORKFLOW_INSTANCES,
      payload: wfInstances
    });
  }, [dispatch]);

  const setClaimPayments = useCallback(payments => {
    dispatch({
      type: ACTION_REPLACE_CLAIM_PAYMENTS,
      payload: payments
    });
  }, [dispatch]);

  return (
    <ComsCard>
      <Box paddingBottom={2}>
        <H3>
          Claim Outcomes
        </H3>
      </Box>
      <Box>
        <GridContainer spacing={2} hasVerticalSpacing>
          <GridItem xs={12} sm={6}>
            <Box paddingBottom={1}>
              <H5>Claim Findings</H5>
            </Box>
            {(claimFindings && claimFindings.length > 0) ? (
              claimFindings.map((finding, index) => {
                const findingRecommendationDatetime = (
                  moment(finding.changeDatetime).format(DATETIME_DISPLAY_NUMERIC_LONG)
                );
                return (
                  <Fragment key={finding.claimFindingId}>
                    <LabelValuePair
                      label="Finding/Recommendation Date"
                      value={findingRecommendationDatetime}
                      stacked
                    />
                    <LabelValuePair
                      label="Finding"
                      value={finding.finding}
                      stacked
                    />
                    <LabelValuePair
                      label="Recommendation"
                      value={finding.recommendation}
                      stacked
                    />
                    {(index !== (claimFindings.length - 1)) && (
                      <Box paddingBottom={2} paddingTop={2}>
                        <Divider />
                      </Box>
                    )}
                  </Fragment>
                )
              })
            ) : (
              "No Claim Findings"
            )}
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Box paddingBottom={1}>
              <H5>Claim Evaluation</H5>
            </Box>
            {evaluations && evaluations.length > 0 ? (
              evaluations.map((evaluation) => {
                const responseOptionId = evaluation.evaluationTemplateDCResponseOptionId;
                const responseOption = evaluationTemplateDCResponseOptionById[
                  responseOptionId
                ]?.responseOption
                const label = evaluationTemplateDCFieldsById[
                  evaluation.evaluationTemplateDCFieldId
                ].label
                const isStacked = (evaluation.value?.length > 45) || (label?.length > 30);
                return (
                  <LabelValuePair
                    label={
                      evaluationTemplateDCFieldsById[
                        evaluation.evaluationTemplateDCFieldId
                      ].label
                    }
                    value={evaluation.value || responseOption}
                    className={classes.outcomeEvaluationDC}
                    key={evaluation.evaluationDCId}
                    stacked={isStacked}
                  />
                )
              })
            ) : (
              "No Claim Evaluations"
            )}
          </GridItem>
          <GridItem xs={12} sm={12}>
            <Divider />
            <Box paddingBottom={1} paddingTop={3}>
              <H5>Claim Payments</H5>
            </Box>
            <CheckListing
              size="small"
              claim={claim}
              setClaimPayments={setClaimPayments}
              setWfInstances={setWfInstances}
            />
          </GridItem>
        </GridContainer>
      </Box>
    </ComsCard>
  )
}

export default ClaimOutcomesCard;