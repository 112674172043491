import { mergeAssociationReplace } from "reducers/utils/payload.utils"
import { mapObjectArrayByKey } from "utils/arrayOfObjects.utils"

export const ACTION_SET_CLAIM_FINDINGS = "claimFindings__set"
export const ACTION_SET_CLAIM_REPORTNOTES = "claimReportNotes__set"
export const ACTION_SET_CLAIM_VALUE_TYPES = "claimValueTypes__set"
export const ACTION_SET_CLAIM_DOCUMENTS = "claimDocuments__set"
export const ACTION_SET_EVALUATION_TEMPLATES = "evaluationTemplates__set"
export const ACTION_SET_CUSTOMER_CORRECTIONS = "customerCorrections__set";
export const ACTION_REPLACE_WORKFLOW_INSTANCE = "workflowInstance__replace";
export const ACTION_SET_FILE_TRANSFERS = "fileTransfers__set"
export const ACTION_REPLACE_UPLOAD_ERROR = "uploadError__replace"
/**
 * Claims Associations:
 *    WFInstance
 *    ClaimFinding
 *    EvaluationDC
 *    ClaimData
 *    ClaimValueType
 *    ClaimTemplateValue
 *    Payment
 *        PaymentReissueRequest
 *
 * FileTransfer Associations:
 *    UploadError
 */

export const claimsInitialState = {
  claimFindings: null,
  claimReportNotes: null,
  claimValueTypesByName: null,
  documents: null,
  customerCorrections: null,
  evaluationTemplates: null,
  fileTransfers: null,
}

export default function claimReducer(state, action) {
  switch (action.type) {
    case ACTION_SET_CLAIM_FINDINGS:
      return {
        ...state,
        claimFindings: action.payload
      }
    case ACTION_SET_CLAIM_REPORTNOTES:
      return {
        ...state,
        claimReportNotes: action.payload
      }
    case ACTION_SET_CLAIM_VALUE_TYPES:
      return {
        ...state,
        claimValueTypesByName: mapObjectArrayByKey(action.payload, "name")
      }
    case ACTION_SET_CLAIM_DOCUMENTS:
      return {
        ...state,
        documents: action.payload
      }
    case ACTION_SET_EVALUATION_TEMPLATES:
      return {
        ...state,
        evaluationTemplates: action.payload
      }
    case ACTION_SET_FILE_TRANSFERS:
      return {
        ...state,
        fileTransfers: action.payload
      }
    case ACTION_REPLACE_UPLOAD_ERROR:
      return {
        ...state,
        fileTransfers: mergeAssociationReplace(
          state.fileTransfers,
          action.payload,
          "fileTransferId",
          "UploadError",
          "uploadErrorId"
        )
      }
    case ACTION_SET_CUSTOMER_CORRECTIONS:
      return {
        ...state,
        customerCorrections: action.payload
      };
    default:
      return state;
  }
}