import React, { useCallback, useContext, useState } from "react";
import { Box, Grid } from "@mui/material";
import { makeStyles } from "mui-styles";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import FormBanner from "components/utils/form-elements/formBanner.component";
import moment from "moment";
import LabelInput from "components/utils/form-elements/labelInput.component";
import classNames from "classnames";
import DateInput from "components/utils/form-elements/dateInput.component";
import BuilderService from "services/Builder.service";
import { ACTION_SET_BUILDER_AFTER_PUBLISH, ACTION_SET_BUILDER_STATUS_MESSAGE } from "reducers/shared/builder/builder.reducer";
import OrganizationContext from "contexts/organization.context";
import IsProcessing from "components/utils/progress/isProcessing.component";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  form: {
    align: "center",
    paddingTop: 5,
    paddingBottom: 0,
  },
  contents: {
    paddingBottom: 15,
    margin: 20,
  },
  elementWrapper: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  inputWrapper: {
    width: 400,
  },
  xsInput: {
    maxWidth: 200,
    paddingBottom: 20,
  },
  dualButtons: {
    paddingBottom: 1,
  },
}));

const validatePubFormErrors = (formObj) => {
  const error = {};
  if (!formObj.Description) {
    error.Description = "Please enter a description.";
  }

  if (!formObj.Version) {
    error.Version = "Please enter a version.";
  }

  if (!formObj.Approval_Date) {
    error.Approval_Date = "Please enter a date.";
  }

  if (!formObj.Publisher) {
    error.Publisher = "Please enter a publisher.";
  }
  return error;
};

export const PublishDocForm = (props) => {
  const classes = useStyles();
  const {
    setIsPublishing,
    builderInfo,
    componentObject,
    chosenComponentObj,
    setEditModal,
    associatedProgramId,
    isPublishing,
    readOnly,
    dispatch,
  } = props;
  const [formData, setFormData] = useState({
    Approval_Date: builderInfo.Approval_Date || null,
    Version: builderInfo.Version || null
  });
  const [formErrors, setFormErrors] = useState({});
  const { state: organizationState } = useContext(OrganizationContext);
  const associatedCompponentObj = chosenComponentObj || componentObject;

  const handleChange = (event) => {
    const { target } = event;
    formData[target.name] = target.value;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
    if (formErrors[target.name]) {
      setFormErrors((prev) => ({
        ...prev,
        [target.name]: null,
      }));
    }
  };

  const handleSubmit = useCallback(async () => {
    const validatedErrors = validatePubFormErrors(formData);
    setFormErrors(validatedErrors);
    if (Object.values(validatedErrors).length > 0) {
      return;
    }
    try {
      setIsPublishing(true);
      const fileName = `${builderInfo.Title}-v${formData.Version}-approved${
        formData.Approval_Date
      }-published${moment(new Date()).format("YYYY-MM-DD_HH_mm")}`;
      const builder = {
        ...builderInfo,
        filename: fileName,
        Description: formData.Description,
        Approval_Date: formData.Approval_Date,
        Version: formData.Version,
        File_Ref: `${fileName}.pdf`,
        Program_Program_ID: associatedProgramId,
        Publisher: formData.Publisher,
        publishToCompObj: associatedCompponentObj.TypeRef.split("|")[1],
        prevCompObj: associatedCompponentObj.TypeRef.split("|")[2],
        Component_ID: associatedCompponentObj.ProgramComponents_Component_ID,
      };
      await BuilderService.publish(builder, organizationState.activeOrganizationId);
      dispatch({
        type: ACTION_SET_BUILDER_AFTER_PUBLISH,
        payload: {
          type: "info",
          message: "Published.",
        },
      });
    } catch (error) {
      dispatch({
        type: ACTION_SET_BUILDER_STATUS_MESSAGE,

        payload: {
          type: "error",
          message: "The document failed to publish.",
        },
      });
    } finally {
      setIsPublishing(false);
      setEditModal(false);
    }
  }, [
    dispatch,
    associatedProgramId,
    builderInfo,
    associatedCompponentObj,
    formData,
    setEditModal,
    setIsPublishing,
    organizationState.activeOrganizationId
  ]);

  return (
    <Grid className={classes.formContainer}>
      <FormBanner>
        Publish {componentObject.Name.split("Builder").join("")}
      </FormBanner>
      <form className={classes.form}>
        <div className={classes.contents}>
          <div
            className={classNames(classes.elementWrapper, classes.inputWrapper)}
          >
            <LabelInput
              variant="default"
              label="Description*"
              onChange={handleChange}
              value={formData.Description}
              name="Description"
              margin="dense"
              test="docDescription"
              error={!!formErrors.Description}
              errorMessage={formErrors.Description}
            />
          </div>
          <div
            className={classNames(classes.elementWrapper, classes.inputWrapper)}
          >
            <LabelInput
              variant="default"
              label="Version*"
              onChange={handleChange}
              value={formData.Version}
              name="Version"
              margin="dense"
              test="docVersion"
              error={!!formErrors.Version}
              errorMessage={formErrors.Version}
            />
          </div>
          <div className={classNames(classes.elementWrapper, classes.xsInput)}>
            <DateInput
              label="Approval Date*"
              defaultValue={formData.Approval_Date}
              id="Approval_Date"
              name="Approval_Date"
              onChange={handleChange}
              placeholder="mm/dd/yyyy" // For Safari
              margin="dense"
              test="docApprovalDate"
              error={!!formErrors.Approval_Date}
              errorMessage={formErrors.Approval_Date}
            />
          </div>
          <div
            className={classNames(classes.elementWrapper, classes.inputWrapper)}
          >
            <LabelInput
              variant="default"
              label="Publisher*"
              onChange={handleChange}
              value={formData.Publisher}
              name="Publisher"
              margin="dense"
              disabled={readOnly}
              test="docPublisher"
              error={!!formErrors.Publisher}
              errorMessage={formErrors.Publisher}
            />
          </div>
          <Box display="flex" justifyContent="center" width="100%">
            <IsProcessing
              message="Publishing. This may take a minute."
              show={!!isPublishing && isPublishing !== "published"}
            />
          </Box>
          <DualFormButtons
            className={classes.dualButtons}
            cancelOnClick={() => setEditModal(false)}
            variant="publish"
            saveOnClick={handleSubmit}
            disabled={!!isPublishing && isPublishing !== "published"}
          />
        </div>
      </form>
    </Grid>
  );
};

export default PublishDocForm;
