import { ArrowForward } from "@mui/icons-material";
import { Box, Dialog, DialogContent } from "@mui/material";
import ButtonDefault from "components/utils/buttonDefault.component";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { makeStyles } from "mui-styles";
import { WF_CLAIM_ISSUE_SUCCESSFUL_OPTION_ID, WF_CLAIM_ISSUE_UNSUCCESSFUL_OPTION_ID, WF_CLAIM_REISSUE_SUCCESSFUL_OPTION_ID, WF_CLAIM_REISSUE_UNSUCCESSFUL_OPTION_ID, WF_SKIP_OPTION_ID } from "components/constants/workflow.constants";
import { isReadOnly } from "utils/roles.utils";
import WorkflowTemplateContext from "contexts/workflowTemplate.context";
import { mapObjectArrayByKey } from "utils/arrayOfObjects.utils";
import ReissueCheckForm from "components/shared/payments/ReissueCheckForm.component";
import { ACTION_REPLACE_CLAIM_PAYMENTS, ACTION_REPLACE_WORKFLOW_INSTANCES } from "reducers/shared/claimReview.reducer";
import FormBanner from "components/utils/form-elements/formBanner.component";
import { comparePayments } from "components/shared/payments/utils/payments.utils";
import PaymentService from "services/Payment.service";
import SummaryCountService from "services/SummaryCount.service";
import StatisticsContext from "contexts/statistics.context";
import { ACTION_REPLACE_POPULATION, ACTION_SET_SUMMARY_COUNT } from "reducers/global/statistics.reducer";

const useStyles = makeStyles(() => ({
  optionButton: {
    width: 200,
    height: "100%"
  }
}));


const ClaimsWorkflowAction = (props) => {
  const {
    claim,
    dispatch,
    eventChainOptions,
    moveWfInstanceToNextWFNode,
  } = props;

  const classes = useStyles();
  const { dispatch: statisticsDispatch } = useContext(StatisticsContext);
  const summaryRequestAbortRef = useRef();
  const wfTemplates = useContext(WorkflowTemplateContext);

  const [isReissueModalOpen, setIsReissueModalOpen] = useState(false);

  const wfEventFlows = useMemo(() => (
    wfTemplates.flatMap((template) => template._associations.WFEventFlow)
  ), [wfTemplates]);

  const wfNodes = useMemo(() => (
    [...new Set(wfEventFlows.flatMap((eventFlow) => {
      return [
        JSON.stringify(eventFlow._associations.WFReferenceNode),
        JSON.stringify(eventFlow._associations.WFTargetNode)]
    }))].map((node) => (JSON.parse(node)))
  ), [wfEventFlows])

  const wfOptions = useMemo(() => (
    [...new Set(wfEventFlows.flatMap((eventFlow) => {
      return JSON.stringify(eventFlow._associations.WFOption)
    }))].map((node) => (JSON.parse(node)))
  ), [wfEventFlows])

  const wfOptionsById = useMemo(() => (
    mapObjectArrayByKey(wfOptions, "wfOptionId")
  ), [wfOptions]);

  const wfNodesById = useMemo(() => (
    mapObjectArrayByKey(wfNodes, "wfNodeId")
  ), [wfNodes]);

  const currentClaimPayment = useMemo(() => (
    [].concat(claim?._associations?.Payment || []).sort(comparePayments)?.[
      claim?._associations?.Payment?.length - 1
    ]
  ), [claim]);

  const dispatchPaymentSuccess = useCallback(async (
    wfInstances, payments, population
  ) => {
    if (wfInstances) {
      dispatch({
        type: ACTION_REPLACE_WORKFLOW_INSTANCES,
        payload: wfInstances
      });
    }
    if (payments) {
      dispatch({
        type: ACTION_REPLACE_CLAIM_PAYMENTS,
        payload: payments
      });
    }
    if (population) {
      statisticsDispatch({
        type: ACTION_REPLACE_POPULATION,
        payload: population
      })
    }
  }, [dispatch, statisticsDispatch]);

  const handleReissueSuccess = useCallback(async response => {
    try {
      const { wfInstances, payments } = response.payload;
      dispatchPaymentSuccess(wfInstances, payments);
      setIsReissueModalOpen(false);
    } finally {
      summaryRequestAbortRef.current = new AbortController();
      const statsResponse = await SummaryCountService.getAll(
        summaryRequestAbortRef.current
      );
      statisticsDispatch({
        type: ACTION_SET_SUMMARY_COUNT,
        payload: statsResponse.payload
      });
    }
  }, [dispatchPaymentSuccess, statisticsDispatch]);

  const handleMarkPaidClick = useCallback(async () => {
    try {
      if (!currentClaimPayment?.paymentId) {
        console.error("No Payment found for current Claim!");
        return moveWfInstanceToNextWFNode(WF_CLAIM_REISSUE_SUCCESSFUL_OPTION_ID);
      }
      const response = await PaymentService.updatePaymentPaid(
        currentClaimPayment.paymentId
      );
      const { wfInstances, payment, population } = response.payload;
      dispatchPaymentSuccess(wfInstances, [payment], population);
    } finally {
      summaryRequestAbortRef.current = new AbortController();
      const statsResponse = await SummaryCountService.getAll(
        summaryRequestAbortRef.current
      );
      statisticsDispatch({
        type: ACTION_SET_SUMMARY_COUNT,
        payload: statsResponse.payload
      });
    }
  }, [
    currentClaimPayment,
    dispatchPaymentSuccess,
    moveWfInstanceToNextWFNode,
    statisticsDispatch
  ]);

  const handleOptionChange = useCallback(async (wfOptionId) => {
    switch (wfOptionId) {
      case WF_CLAIM_ISSUE_UNSUCCESSFUL_OPTION_ID:
      case WF_CLAIM_REISSUE_UNSUCCESSFUL_OPTION_ID:
        if (!currentClaimPayment) {
          console.error("No Payment found to create Reissue Request for.");
          return moveWfInstanceToNextWFNode(wfOptionId);
        }
        setIsReissueModalOpen(true);
        return;
      case WF_CLAIM_ISSUE_SUCCESSFUL_OPTION_ID:
      case WF_CLAIM_REISSUE_SUCCESSFUL_OPTION_ID:
        return handleMarkPaidClick();
      default:
        return moveWfInstanceToNextWFNode(wfOptionId);
    }
  }, [currentClaimPayment, handleMarkPaidClick, moveWfInstanceToNextWFNode]);

  return (
    <Box
      display="flex"
      justifyContent="center"
    >
      {eventChainOptions?.map((wfEventFlow) => {
        const targetLabel = wfNodesById[wfEventFlow.targetNodeId].nodeName
        const optionId = wfEventFlow.wfOptionId;
        const optionLabel = wfOptionsById[optionId]?.decisionDescription
        const label = (optionId === WF_SKIP_OPTION_ID) ?
          targetLabel :
          (optionLabel || targetLabel);
        return (
          <Box
            key={wfEventFlow.wfEventFlowId}
            padding={2}
          >
            <ButtonDefault
              onClick={() => handleOptionChange(optionId)}
              endIcon={<ArrowForward />}
              background="primary"
              className={classes.optionButton}
              disabled={isReadOnly()}
            >
              {label}
            </ButtonDefault>
            <Dialog
              open={isReissueModalOpen}
              onClose={() => setIsReissueModalOpen(false)}
              maxWidth="lg"
              TransitionProps={{ exit: false }}
            >
              <FormBanner>Reissue Check Request</FormBanner>
              <DialogContent>
                <ReissueCheckForm
                  claim={claim}
                  payment={currentClaimPayment}
                  onClose={() => setIsReissueModalOpen(false)}
                  onSuccess={handleReissueSuccess}
                />
              </DialogContent>
            </Dialog>
          </Box>
        )
      })}
    </Box>
  )
}

export default ClaimsWorkflowAction;
