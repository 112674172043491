import React, { useMemo } from "react";
import { FormControl, TextField } from "@mui/material";
import { makeStyles } from "mui-styles";
import FormLabel from "components/utils/form-elements/formLabel.component";
import { isReadOnly } from "utils/roles.utils";
import classNames from "classnames";
import FormElementBase from "components/utils/form-elements/shared/formElementBase.component";
import CustomFormHelperText from "components/utils/form-elements/styled/formHelperText/customFormHelperText.component";

const useStyles = makeStyles((theme) => ({
  formControlRoot: {
    width: "100%",
  },
  formControlRootDatePicker: {
    "& .MuiFormControl-marginDense": {
      marginBottom: 0,
    }
  },
  placeholderVisible: {
    color: "rgba(0, 0, 0, 0.5)"
  },
  datepickerRoot: {
    "& input:valid + fieldset": {
      borderColor: theme.palette.secondary.main,
      borderWidth: 2,
    },
    "& input:hover": {
      cursor: "text !important",
    },
    "& input:invalid + fieldset": {
      borderColor: `${theme.palette.error.light} !important;`,
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important",
    },
    "&:hover": {
      borderColor: theme.palette.primary.main,
      "& input:valid + fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
    "& input:disabled": {
      color: theme.palette.text.black,
    },
    "& input:disabled + fieldset": {
      borderWidth: 2,
    },
  },
  datepickerErrorlessFixedHeight: {
    marginBottom: 15,
  },
  datepickerHelperFixedHeight: {
    color: "white!important",
    fontSize: theme.typography.badge.fontSize,
    fontWeight: theme.typography.badge.fontWeight,
    textAlign: "right",
    backgroundColor: theme.palette.error.main,
    borderRadius: "0px 0px 3px 3px",
    padding: "1px 5px 0px 0px",
    margin: "-2px 0px 0px 0px",
    height: 17,
    width: "100%",
  },
  datepickerHelper: {
    color: "white!important",
    fontSize: theme.typography.badge.fontSize,
    fontWeight: theme.typography.badge.fontWeight,
    textAlign: "right",
    backgroundColor: theme.palette.error.main,
    borderRadius: "0px 0px 3px 3px",
    padding: "1px 5px 0px 0px",
    margin: "-2px 0px 0px 0px",
    height: 17,
    width: "100%",
  },
  inputDense: {
    padding: `10px 12px !important`
  },
  readOnly: {
    "& .MuiInputBase-input.Mui-disabled": {
      color: "#000"
    }
  },
}));


export const TYPE_DATE = "date";
export const TYPE_DATETIME = "datetime-local";
export const TYPE_TIME = "time";

const DateInput = (props) => {
  const { onAutosave, onChange, ...otherProps } = props;

  /*
   * Prevent bug caused by native browser date input pickers blocking the case of:
   * 1) Select date from date picker
   * 2) Click on nearby checkbox without blurring date picker input beforehand
   * 3) Checkbox change event will not fire
   * 4) Checkbox value will not save, and user event will be undone as a result.
   *
   * In most cases, selecting a date by mouse is the onChange reason.
   * A special onKeyDown handler to block autosave could be a future
   *   option if this causes issues.
   */
  const controlledProps = useMemo(() => {
    if (!onChange && onAutosave) {
      return {
        onAutosave: undefined,
        onChange: event => onAutosave(event.target.name, event.target.value, event)
      };
    }
    return { onAutosave, onChange };
  }, [onAutosave, onChange]);

  return (
    <FormElementBase {...controlledProps} {...otherProps}>
      <DateInputBase {...controlledProps} {...otherProps} />
    </FormElementBase>
  );
};

/*
 * Very rough and rushed split from LabelInput
 * Much unused code
 */
const DateInputBase = (props) => {
  const classes = useStyles();

  const {
    autoFocus,
    className,
    defaultValue,
    disabled,
    error,
    errorMessage,
    fixedHeight,
    id,
    InputProps,
    inputProps,
    label,
    labelTooltip,
    margin = "dense",
    max,
    min,
    name,
    onBlur,
    onChange,
    onFocus,
    onKeyDown,
    onKeyUp,
    placeholder,
    readOnly: readOnlyProp,
    required,
    shrink,
    size,
    step,
    test,
    type = TYPE_DATE,
    value: valueProp
  } = props;

  const readOnly = useMemo(() => readOnlyProp || isReadOnly(), [readOnlyProp]);

  let value = valueProp;
  if (!value && !props.select) {
    if ("defaultValue" in props) {
      value = undefined;
    } else if ("value" in props) {
      value = "";
    }
  }

  return (
    <FormControl
      variant="outlined"
      className={
        classNames(classes.formControlRoot, classes.formControlRootDatePicker)
      }
    >
      {/* <InputLabel htmlFor={id}/> */}
      <FormLabel
        htmlFor={id}
        label={<>{label}{!!required && "*"}</>}
        labelTooltip={labelTooltip}
        error={error}
        variant="default"
      />
      <TextField
        error={error}
        helperText={errorMessage}
        className={
          classNames(
            classes.datepickerRoot,
            classes.margin,
            className,
            readOnly && classes.readOnly
          )
        }
        type={type}
        variant="outlined"
        id={id}
        name={name}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        margin={margin}
        autoFocus={autoFocus}
        required={required}
        disabled={disabled || readOnly}
        size={size}
        InputLabelProps={{ shrink }}
        InputProps={{
          className: classNames(
            placeholder && !value && classes.placeholderVisible,
            fixedHeight && !errorMessage && classes.datepickerErrorlessFixedHeight,
          ),
          ...InputProps
        }}
        inputProps={{
          className: classNames(
            margin === "dense" && classes.inputDense,
            inputProps?.className
          ),
          min,
          max,
          step,
          ...inputProps
        }}
        FormHelperTextProps={{
          className: classNames(
            classes.datepickerHelper,
            fixedHeight && errorMessage ? classes.datepickerHelperFixedHeight : undefined
          )
        }}
        data-cy={`input-${test}`}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
      />
      {!props.noHelper && (
        <CustomFormHelperText
          id={`helper-${props.id}`}
          className={props.fixedHeight ? classes.helperFixedHeight : undefined}
          error={props.error}
          data-cy={`input-helper-${props.test}`}
        >
          {props.errorMessage ? props.errorMessage : props.helperText}
        </CustomFormHelperText>
      )}
    </FormControl>
  );
};

export default DateInput;