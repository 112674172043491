import AjaxService from "services/base/Ajax.service.js";

class ImageServiceBase extends AjaxService {
  constructor() {
    super();
    this.imageCache = {};
  }

  shouldExtractResponseData() {
    return true;
  }

  // get logo as base64
  async getImage(id, beforeFetch = () => {}) {
    const cachedLogo = this.imageCache[id];
    if (cachedLogo) {
      return Promise.resolve({ payload: cachedLogo });
    }
    beforeFetch(id);
    const response = await this.get(`/image/${id}`);
    this.imageCache[id] = response.payload;
    return response;
  }

  isCached(id) {
    return !!this.imageCache[id];
  }

  clearCachedImage(id) {
    return delete this.imageCache[id];
  }
}

const ImageService = new ImageServiceBase();
export default ImageService;
