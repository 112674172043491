import React, { useMemo, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableCell from "components/utils/tables/shared/tableCell.component";
import TableSortLabel from "components/utils/tableSortLabel.component";
import { makeStyles } from "mui-styles";
import classNames from "classnames";
import { getComparator, stableSort } from "components/utils/tables/utils/rowSort.utils";
import EditIcon from "components/utils/editIcon.component";
import AssignButton from "components/utils/assignButton.component";
import CustomModal from "components/utils/modal.component";
import AssignTaskForm from "components/shared/forms/assignTaskForm.component";
import TaskForm from "components/shared/forms/taskForm.component";

/*
 *Component Object Tasks from dropdown
 */

import moment from "moment";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    overFlowX: "hidden",
    maxHeight: 430,
    [theme.breakpoints.down("md")]: {
      maxHeight: 292,
    },
  },
  tableSmallPrimaryHeaderCell: {
    backgroundColor: theme.palette.tertiaryGrey.main,
    color: "white",
    fontWeight: "bold",
    fontSize: theme.typography.body1.fontSize,
    padding: "7px 10px",
    lineHeight: "normal",
    [theme.breakpoints.down("md")]: {
      fontSize: theme.typography.body2.fontSize,
    },
  },
  tableSmallBodyCell: {
    padding: "7px 10px",
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body2.fontSize,
    [theme.breakpoints.down("md")]: {
      fontSize: theme.typography.caption.fontSize,
    },
  },
  editColumnPadding: {
    padding: "5px 0px 5px 10px",
  },

  centerAlign: {
    textAlign: "center",
  },
  leftAlign: {
    textAlign: "left",
  },
  addEmptyWrapper: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 3,
  },
}));

const CompTasksTable = ({ tableData, title, setRefresh, viewMode }) => {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(tableData.length > 0 ? "Name" : "");
  const [formType, setFormType] = useState("");//task subTask
  const [parent, setParent] = useState();
  const [taskData, setTaskData] = useState();
  const [assignMode, setAssignMode] = useState(false);

  //MODAL
  const [editModal, setEditModal] = useState(false);

  const sortedRows = useMemo(() => (
    stableSort(tableData, getComparator(order, orderBy))
  ), [order, orderBy, tableData]);

  const openAssignForm = (assignTask) => {
    setAssignMode(true);
    setEditModal(true);
    setTaskData(assignTask);
  };

  const openForm = (task) => {
    setEditModal(true);
    if (task) {
      setTaskData(task);
    } else {
      setTaskData();
    }
    if (task.Parent_ID) {
      setFormType("subTask");
      setParent({ id: task.Parent_ID });
    } else {
      setFormType("task");
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const toggleForm = () => {
    setEditModal(!editModal);
    setAssignMode(false)
  };

  const emptyMessage = useMemo(() => {
    if (viewMode === "userTasks") {
      return `There are no ${title} tasks assigned to you`;
    } else {
      return `There are no ${title} tasks`;
    }
  }, [title, viewMode]);

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader data-cy={`table-compTasks-${viewMode}`}>
          <TableHead>
            <TableRow>
              <TableCell
                className={classNames(
                  classes.tableSmallPrimaryHeaderCell,
                  classes.centerAlign,
                  classes.editColumnPadding
                )}
              />
              <TableCell
                className={classNames(
                  classes.tableSmallPrimaryHeaderCell,
                  classes.leftAlign
                )}
              >
                <TableSortLabel
                  active={orderBy === "Name"}
                  direction={orderBy === "Name" ? order : "asc"}
                  onClick={createSortHandler("Name")}
                  align="left"
                  data-cy="sortHeadCell-compObjTasks-Name"
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                className={classNames(
                  classes.tableSmallPrimaryHeaderCell,
                  classes.centerAlign
                )}
              >
                <TableSortLabel
                  active={orderBy === "Proposed_Start"}
                  direction={orderBy === "Proposed_Start" ? order : "asc"}
                  onClick={createSortHandler("Proposed_Start")}
                >
                  Proposed Start
                </TableSortLabel>
              </TableCell>

              <TableCell
                className={classNames(
                  classes.tableSmallPrimaryHeaderCell,
                  classes.centerAlign
                )}
              >
                <TableSortLabel
                  active={orderBy === "Proposed_Completion"}
                  direction={orderBy === "Proposed_Completion" ? order : "asc"}
                  onClick={createSortHandler("Proposed_Completion")}
                >
                  Proposed Completion
                </TableSortLabel>
              </TableCell>
              <TableCell
                className={classNames(
                  classes.tableSmallPrimaryHeaderCell,
                  classes.centerAlign
                )}
              >
                <TableSortLabel
                  active={orderBy === "Status"}
                  direction={orderBy === "Status" ? order : "asc"}
                  onClick={createSortHandler("Status")}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell
                className={classNames(
                  classes.tableSmallPrimaryHeaderCell,
                  classes.centerAlign
                )}
              >
                <TableSortLabel
                  active={orderBy === "Type"}
                  direction={orderBy === "Type" ? order : "asc"}
                  onClick={createSortHandler("Type")}
                >
                  Type
                </TableSortLabel>
              </TableCell>
              <TableCell
                className={classNames(
                  classes.tableSmallPrimaryHeaderCell,
                  classes.leftAlign
                )}
              >
                <TableSortLabel
                  active={orderBy === "Task_Notes"}
                  direction={orderBy === "Task_Notes" ? order : "asc"}
                  onClick={createSortHandler("Task_Notes")}
                  align="left"
                >
                  Notes
                </TableSortLabel>
              </TableCell>
              <TableCell
                className={classNames(
                  classes.tableSmallPrimaryHeaderCell,
                  classes.centerAlign
                )}
              >
                <TableSortLabel
                  active={orderBy === "WP_Name"}
                  direction={orderBy === "WP_Name" ? order : "asc"}
                  onClick={createSortHandler("WP_Name")}
                >
                  Workpackage
                </TableSortLabel>
              </TableCell>
              <TableCell
                className={classNames(
                  classes.tableSmallPrimaryHeaderCell,
                  classes.centerAlign
                )}
              >
                <TableSortLabel
                  active={orderBy === "Assigned_First_Name"}
                  direction={orderBy === "Assigned_First_Name" ? order : "asc"}
                  onClick={createSortHandler("Assigned_First_Name")}
                >
                  Assigned
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          {(!tableData || tableData.length === 0) && (
            <TableBody>
              <TableRow>
                <TableCell
                  className={classes.tableSmallBodyCell}
                  colSpan="9"
                  component="th"
                  scope="row"
                >
                  <div className={classes.addEmptyWrapper}>
                    {emptyMessage}
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {!!tableData && tableData.length > 0 && (
            <TableBody>
              {sortedRows.map(row => (
                <TableRow key={row.Task_ID}>
                  <TableCell
                    className={classNames(
                      classes.tableSmallBodyCell,
                      classes.leftAlign,
                      classes.editColumnPadding
                    )}
                  >
                    <EditIcon
                      variant="matrixSmall"
                      onClick={() => openForm(row)}
                      test={`compObjTask-${row.Name}`}
                    />
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes.tableSmallBodyCell,
                      classes.leftAlign
                    )}
                  >
                    {row.Name}
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes.tableSmallBodyCell,
                      classes.centerAlign
                    )}
                  >
                    {row.Proposed_Start
                      ? moment(row.Proposed_Start).format("M/D/YYYY")
                      : ""}
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes.tableSmallBodyCell,
                      classes.centerAlign
                    )}
                  >
                    {row.Proposed_Completion
                      ? moment(row.Proposed_Completion).format("M/D/YYYY")
                      : ""}
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes.tableSmallBodyCell,
                      classes.centerAlign
                    )}
                  >
                    {row.Status}
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes.tableSmallBodyCell,
                      classes.centerAlign
                    )}
                  >
                    {row.Type}
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes.tableSmallBodyCell,
                      classes.leftAlign
                    )}
                  >
                    {row.Task_Notes}
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes.tableSmallBodyCell,
                      classes.centerAlign
                    )}
                  >
                    {row.WP_Name}
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes.tableSmallBodyCell,
                      classes.centerAlign
                    )}
                  >
                    {row.Assigned_User ? (
                      `${row.Assigned_UserName || `User ${row.Assigned_User}`}`
                    ) : (
                      <AssignButton
                        onClick={() => openAssignForm(row)}
                        testProp={row.Name}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <CustomModal open={editModal} onClose={toggleForm}>
        {assignMode ? (
          <AssignTaskForm
            setEditModal={setEditModal}
            taskData={taskData}
            setTaskData={setTaskData}
            setAssignMode={setAssignMode}
          />
        ) : (
          <TaskForm
            setEditModal={setEditModal}
            taskData={taskData}
            setTaskData={setTaskData}
            setRefresh={setRefresh}
            formMode="edit" //create edit
            formType={formType} //task or subTask
            setFormType={setFormType}
            parentTaskInfo={parent}
          />
        )}
      </CustomModal>
    </>
  );
};

export default CompTasksTable;
