import { Pagination } from "@mui/material";
import { makeStyles } from "mui-styles";
import { useCallback } from "react";

const useStyles = makeStyles(() => ({
  paginationContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end"
  }
}));

export default function CustomPagination(props) {
  const {
    totalPageCount,
    paginationIndex,
    handlePageChange,
    totalResults
  } = props;
  const classes = useStyles();

  const handleChange = useCallback((_event, selectedPage) => {
    handlePageChange(selectedPage - 1)
  }, [handlePageChange])

  if (!!totalPageCount && (!!paginationIndex || paginationIndex === 0)) {
    return (
      <div className={classes.paginationContainer}>
        {`${totalResults} Results`}
        <Pagination
          count={totalPageCount}
          page={paginationIndex + 1}
          onChange={handleChange}
        />
      </div>
    )
  }
}