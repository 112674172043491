import React, { useCallback, useMemo, useRef, useState } from "react";
import { makeStyles } from "mui-styles";
import moment from "moment";
import { downloadFile } from "services/util/file.utils";
import CustomLink from "components/utils/link.component";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import { COMPONENT_OBJECT_PORTFOLIO_DOC_LIBRARY_TYPEREF } from "components/constants/program.constants";
import DataTable from "components/utils/tables/dataTable.component";
import {
  createNodeCellParams,
  createNodeHeaderParams,
  createValueHeaderParams,
  makeHighlightRowProp,
} from "components/utils/tables/utils/dataTable.utils";
import { DATE_FORMAT_DISPLAY_NUMERIC } from "constants/date.constants";
import EditIconButton from "components/utils/editIconButton.component";
import { Box } from "@mui/material";
import DeleteIconButton from "components/utils/deleteIconButton.component";
import ConfirmationDialog from "components/utils/dialogs/confirmationDialog.component";
import FileService from "services/File.service";
import useTargetTableRowViaLink from "hooks/useTargetTableRowViaLink";

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.secondary.dark,
    fill: theme.palette.secondary.dark,
    "&:hover": {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
      cursor: "pointer",
    },
    "& $downloadFileIcon": {
      color: "inherit",
    },
  },
  downloadFileIcon: {
    maxHeight: 10,
    maxWidth: 10,
    marginLeft: "-2px",
    marginRight: 2,
  },
}));

const defaultCellStyle = { textAlign: "center" };

const TABLE_HEADERS = [
  createNodeHeaderParams("Name", "Name", {
    filter: false,
    sort: true,
  }),
  createValueHeaderParams("Description", "Description", {
    filter: false,
    sort: true,
  }),
  createNodeHeaderParams("Date", "Date", {
    filter: false,
    sort: true,
    setCellProps: () => ({ style: { ...defaultCellStyle }}),
  }),
  createNodeHeaderParams("Action", "Action", {
    filter: false,
    sort: false,
    setCellProps: () => ({ style: { ...defaultCellStyle }}),
  }),
];

const PORTFOLIO_TABLE_HEADERS = [
  createNodeHeaderParams("Name", "Name", {
    filter: false,
    sort: true,
  }),
  createValueHeaderParams("Description", "Description", {
    filter: false,
    sort: true,
  }),
  createNodeHeaderParams("Date", "Date", {
    filter: false,
    sort: true,
    setCellProps: () => ({ style: { ...defaultCellStyle }}),
  }),
  createValueHeaderParams("PortCo", "PortCo", {
    filter: true,
    sort: true,
    setCellProps: () => ({ style: { ...defaultCellStyle }}),
  }),
  createNodeHeaderParams("Action", "Action", {
    filter: false,
    sort: false,
    setCellProps: () => ({ style: { ...defaultCellStyle }}),
  }),
];

export default function DocumentLibraryTable(props) {
  const classes = useStyles();
  const {
    componentObject,
    documents,
    setDocuments,
    setOpenModalDocument,
    folder,
  } = props;
  const rowRef = useRef({});
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(null);
  const { targetedRowId, highlightColor } = useTargetTableRowViaLink(
    rowRef,
    !!documents?.length
  );

  const isPortfolioDocTable =
    componentObject.typeRef === COMPONENT_OBJECT_PORTFOLIO_DOC_LIBRARY_TYPEREF;

  const deleteDocument = useCallback(
    async (id) => {
      try {
        const result = await FileService.deleteProgramDocument(id);
        setDocuments((prev) => (
          prev.filter((doc) => doc.ProgramDoc_ID !== result.payload)
        ));
        setOpenModalDocument(null);
      } catch (error) {
        console.error(error);
      }
    },
    [setDocuments, setOpenModalDocument]
  );

  const tableRows = useMemo(() => {
    if (!documents) {
      return null;
    }

    return documents.map((row) => {
      const publishDate = row.PublishDate
        ? moment(row.PublishDate).format(DATE_FORMAT_DISPLAY_NUMERIC)
        : "";
      return {
        Name: createNodeCellParams(
          row.Title,
          row.Title,
          <CustomLink
            className={classes.link}
            variant="noHRef"
            onClick={() => downloadFile(row.ProgramDoc_ID, row.Title)}
            test={`download-${row.Title}-${row.PublishDate}`}
            rowId={row.ProgramDoc_ID}
          >
            <AttachFileOutlinedIcon className={classes.downloadFileIcon} />
            <span ref={(el) => (rowRef.current[row.ProgramDoc_ID] = el)}>
              {row.Title}
            </span>
          </CustomLink>
        ),
        Description: row.Description,
        Date: createNodeCellParams(row.PublishDate, publishDate),
        PortCo: row._associations.PortCo.Name,
        Action: createNodeCellParams(
          null,
          null,
          <ActionButtons
            editClick={() => setOpenModalDocument(row)}
            deleteDocument={deleteDocument}
            row={row}
            setOpenConfirmationDialog={setOpenConfirmationDialog}
            openConfirmationDialog={openConfirmationDialog}
          />
        ),
      };
    });
  }, [
    documents,
    classes,
    deleteDocument,
    setOpenModalDocument,
    openConfirmationDialog,
    setOpenConfirmationDialog,
  ]);

  return (
    <DataTable
      data={tableRows}
      columns={isPortfolioDocTable ? PORTFOLIO_TABLE_HEADERS : TABLE_HEADERS}
      options={{
        filterType: "checkbox",
        fixedHeader: true,
        fixedSelectColumn: true,
        filter: !!isPortfolioDocTable,
        pagination: false,
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        download: false,
        print: false,
        sortOrder: { order: "desc" },
        textLabels: {
          body: {
            noMatch: !folder
              ? "No documents to display"
              : `No ${folder.Title} documents to display`,
          },
        },
        setRowProps: (row) => {
          return makeHighlightRowProp(
            row,
            "rowId",
            targetedRowId,
            highlightColor
          );
        },
      }}
    />
  );
}

const ActionButtons = ({
  editClick,
  deleteDocument,
  row,
  openConfirmationDialog,
  setOpenConfirmationDialog
}) => {
  return (
    <>
      <Box display="flex" justifyContent="space-around" flexWrap="wrap">
        <Box margin={1}>
          <EditIconButton onClick={editClick} testProp={row.Title} />
        </Box>
        <Box margin={1}>
          <DeleteIconButton
            onClick={() => setOpenConfirmationDialog(row.ProgramDoc_ID)}
          />
        </Box>
      </Box>
      <ConfirmationDialog
        open={openConfirmationDialog === row.ProgramDoc_ID}
        confirm={() => deleteDocument(row.ProgramDoc_ID)}
        onClose={() => setOpenConfirmationDialog(null)}
        prompt="Are you sure you'd like to delete this document?"
      />
    </>
  );
};
