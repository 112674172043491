import { PATHS_HOME } from "components/layout/constants/standardPaths.constants";
import { H3 } from "components/utils/headerV2.component";
import React from "react";

const NotFound = () => (
  <H3 color="tertiary" withMargin>
    Error: 404 Page Not Found.{" "}
    <a href={PATHS_HOME}>Return To Dashboard</a>
  </H3>
);

export default NotFound;