import { Box } from "@mui/material";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import Form from "components/utils/form-elements/form.component";
import TitledMultiFileUploadInput from "components/utils/form-elements/titledMultiFileUploadInput.component";
import { H4 } from "components/utils/headerV2.component";
import LabelValuePair from "components/utils/labelValuePair.component";
import { makeStyles } from "mui-styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: 20,
  },
  uploadInputWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 20,
    paddingBottom: 20,
    minWidth: 520,
    minHeight: 400,
    maxHeight: 420,
    overflow: "scroll"
  },
}));
const AttachmentsUploader = (props) => {
  const { onSubmit, onClose, claim, isSaving, payment } = props;
  const derivedClaimData = claim?._derivedData?.ClaimData;

  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <H4>Add Attachments</H4>
      {!!derivedClaimData?.gsLoanNumber && (
        <LabelValuePair
          label="GS Loan Number"
          value={derivedClaimData.gsLoanNumber}
        />
      )}
      <Form
        name="claim-attachments"
        onSubmit={onSubmit}
      >
        <Box className={classes.uploadInputWrapper}>
          <TitledMultiFileUploadInput
            name={payment ? "reissueDocument" : "attachments"}
            label="Attachments"
            textFieldProps={{
              name: "descriptionPassword",
              placeholder: "Description / Password (optional)",
              multiline: true
            }}
            dense
          >
            Select Attachments
          </TitledMultiFileUploadInput>
        </Box>
        <DualFormButtons cancelOnClick={onClose} isSaving={isSaving} />
      </Form>
    </Box>
  );
};

export default AttachmentsUploader;
