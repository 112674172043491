import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { useTheme } from "@mui/material";
/**
 * Scrolls to and returns target id + clear fucntion
 * Use this custom hook when targeting an element via a link to highlight
 */

const useTargetTableRowViaLink = (
  rowRef, readyToScroll = false, highlightItemId = undefined
) => {
  const theme = useTheme();
  const [targetedRowId, setTargetedRowId] = useState();
  const location = useLocation();

  const clearTargetedRow = () => {
    setTargetedRowId();
  };

  // upon load, scrolls to specified rows
  useEffect(() => {
    if (location.state?.itemId || highlightItemId) {
      const rowItemId = location.state?.itemId || highlightItemId
      setTargetedRowId(rowItemId);
      if (rowRef && rowRef.current[rowItemId] && readyToScroll) {
        rowRef.current[rowItemId].scrollIntoView({
          block: "center",
        });
      }
    }
  }, [location.state, readyToScroll, rowRef, highlightItemId]);

  return {
    targetedRowId,
    clearTargetedRow,
    highlightColor: theme.palette.secondary.highlight
  };
};

export default useTargetTableRowViaLink;
