import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import ButtonDefault from "components/utils/buttonDefault.component";
import { makeStyles } from "mui-styles";
import classNames from "classnames";
import NotInterestedOutlinedIcon from "@mui/icons-material/NotInterestedOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    paddingBottom: 20,
  },
  dialogPaper: {
    padding: "30px 30px 20px 30px",
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: 200,
    margin: "0px auto",
    width: "100%",
    paddingBottom: 20,
    paddingTop: 20,
  },
  label: {
    fontSize: theme.typography.subtitle1.fontSize,
    textAlign: "center",
  },
  icon: {
    fill: "white",
    fontSize: theme.typography.h4.fontSize,
    marginRight: 8,
  },
  approveIcon: {
    fontSize: theme.typography.h4.fontSize,
    marginRight: 8,
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "center",
    padding: 5,
    fontSize: theme.typography.subtitle1.fontSize,
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
}));

const ConfirmationDialog = (props) => {
  const classes = useStyles();
  const {
    open,
    onClose,
    confirm,
    className,
    dialogTitle,
    prompt,
    children,
    continueAndCancel,
    hideButtons,
  } = props;

  return (
    <Dialog
      open={!!open}
      onClose={onClose}
      className={classNames(className)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ classes: { root: classes.dialogPaper }}}
    >
      {!!dialogTitle && (
        <div id="alert-dialog-title" className={classes.dialogTitle}>
          {dialogTitle}
        </div>
      )}
      <DialogContent className={classes.dialogContent}>
        <DialogContentText
          className={classNames(
            classes.label
          )}
          id="alert-dialog-description"
          data-cy="dialog-option"
        >
          {prompt}
        </DialogContentText>
        {children}
      </DialogContent>
      {!hideButtons && (
        <DialogActions className={classes.actionsContainer}>
          {!continueAndCancel ? (
            <>
              <ButtonDefault
                variant="small"
                background="secondary"
                onClick={confirm}
                data-cy="dialog-yes"
                startIcon={
                  <ThumbUpAltOutlinedIcon
                    className={classNames(classes.approveIcon, classes.icon)}
                  />
                }
              >
                Yes
              </ButtonDefault>
              <ButtonDefault
                variant="small"
                background="red"
                onClick={onClose}
                data-cy="dialog-no"
                startIcon={
                  <CloseOutlinedIcon
                    className={classNames(classes.cancelIcon, classes.icon)}
                  />
                }
              >
                No
              </ButtonDefault>
            </>
          ) : (
            <>
              <ButtonDefault
                variant="small"
                background="secondary"
                onClick={confirm}
                data-cy="dialog-continue"
                startIcon={
                  <ThumbUpAltOutlinedIcon
                    className={classNames(classes.approveIcon, classes.icon)}
                  />
                }
              >
                Continue
              </ButtonDefault>
              <ButtonDefault
                variant="small"
                background="grey"
                onClick={onClose}
                data-cy="dialog-cancel"
                startIcon={
                  <NotInterestedOutlinedIcon
                    className={classNames(classes.cancelIcon, classes.icon)}
                  />
                }
              >
                Cancel
              </ButtonDefault>{" "}
            </>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ConfirmationDialog;
