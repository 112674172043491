import React, { useEffect, useState, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import InlineSVGIcons from "components/utils/inlineSVGIcons.component.js";
import classNames from "classnames";
import { makeStyles } from "mui-styles";
import { generatePath, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { isGlobalUser } from "utils/roles.utils";
import {
  programComponentIconMapping,
  PROGRAM_COMPONENT_TYPE_DASHBOARD,
  PROGRAM_TYPE_CONSENT_ORDERS,
  PROGRAM_TYPE_DEFAULT,
} from "components/constants/program.constants";
import useNumericParams from "hooks/useNumericParams";
import ProgramsContext from "contexts/programs.context";
import { PATH_PROGRAM, PATH_PROGRAM_COMPONENT } from "components/layout/constants/standardPaths.constants";
import { ExitToApp } from "@mui/icons-material";
import ConsentOrderContext from "contexts/consentOrder.context";
import { getActiveConsentOrderFromContext } from "components/consentOrder/utils/consentOrder.utils";
import ConsentOrderMenuTitle from "components/consentOrder/consentOrderMenuTitle.component";


const useStyles = makeStyles((theme) => ({
  drawer: {
    width: theme.layout.width.mainMenuXl,
    flexShrink: 0,
    [theme.breakpoints.down("lg")]: {
      width: theme.layout.width.mainMenu,
    },
  },
  drawerPaper: {
    width: theme.layout.width.mainMenuXl,
    boxShadow: theme.shadow.drawer,
    [theme.breakpoints.down("lg")]: {
      width: theme.layout.width.mainMenu,
    },
  },
  drawerContainer: {
    height: "100%",
    overflow: "auto",
  },
  listWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  customIconWrapper: {
    height: 18,
    justifyContent: "center",
    alignItems: "center",
    minWidth: 50,
  },
  bigIconWrapper: {
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    minWidth: 50,
  },
  icon: {
    fill: theme.palette.text.secondary,
  },
  activeIcon: {
    fill: theme.palette.primary.main,
  },
  iconFlipped: {
    transform: "scale(-1, 1)"
  },
  customListItem: {
    height: 52,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 40,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 20,
    },
    "@media (min-height: 960px)": {
      minHeight: 60,
      height: "auto"
    },
  },
  listItemText: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body1.fontSize,
    lineHeight: "normal",
  },
  activeListItemText: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.body1.fontSize,
    lineHeight: "normal",
  },
  activeListItem: {
    borderLeft: "5px solid #78C5FD",
    paddingLeft: 35,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 15,
    }
  },
  backToLink: {
    marginTop: "auto",
    marginBottom: 8,
    paddingTop: 8,
    paddingBottom: 8
  },
}));


const MainMenu = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = useNumericParams();
  const {
    programId: programName,
    programComponentId: programComponentName,
  } = params;
  const { state } = useContext(ProgramsContext);
  const { state: consentOrderState } = useContext(ConsentOrderContext);
  const [listItems, setListItems] = useState();
  const isGlobalRole = useMemo(isGlobalUser, []);

  const program = state.programs?.[programName];

  const activeConsentOrder = useMemo(() => (
    getActiveConsentOrderFromContext(consentOrderState)
  ), [consentOrderState])

  const defaultProgram = useMemo(() => (
    Object.values(state.programs || []).find(potentialProgram => (
      potentialProgram.type === PROGRAM_TYPE_DEFAULT &&
      potentialProgram.status === "Active"
    ))
  ), [state.programs]);

  const hasDefaultDashboardBackLink = useMemo(() => {
    return (
      defaultProgram &&
      programName !== defaultProgram.name &&
      isGlobalRole
    );
  }, [isGlobalRole, defaultProgram, programName]);

  useEffect(function setMenuItems() {
    const programComponents = (
      state.programComponentsByProgramId?.[program?.programId]
    );
    if (programComponents) {
      let activeName = programComponentName;
      const noActiveIdMatch = !programComponents.some(({ name }) => (
        name === activeName
      ))
      if (noActiveIdMatch) {
        activeName = programComponents.find(({ type }) => (
          type === PROGRAM_COMPONENT_TYPE_DASHBOARD
        ))?.name;
      }
      const menuListItems = programComponents.map(item => {
        const route = generatePath(PATH_PROGRAM_COMPONENT, {
          programId: programName,
          programComponentId: item.name
        });
        return {
          key: item.name,
          primary: item.label,
          route,
          icon: programComponentIconMapping[item.name],
          active: item.name === activeName,
          id: item.name,
          status: item.status
        };
      });
      setListItems(menuListItems);
    }
  }, [
    history,
    state.programs,
    state.programComponentsByProgramId,
    params,
    program?.programId,
    programName,
    programComponentName,
    isGlobalRole,
  ]);

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{ paper: classes.drawerPaper }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List className={classes.listWrapper} data-cy="menu-nav-list">
          {program?.type === PROGRAM_TYPE_CONSENT_ORDERS && !!activeConsentOrder && (
            <ConsentOrderMenuTitle activeConsentOrder={activeConsentOrder} />
          )}
          {listItems
            ?.filter?.(item => item?.status === "Active")
            ?.map?.(item => (
              <ListItem
                button
                component={Link}
                to={item.route}
                key={item.key}
                className={
                  classNames(
                    classes.customListItem,
                    item.active && classes.activeListItem
                  )
                }
                selected={item.active}
                data-cy={`menu-nav-${item.primary}`}
              >
                <ListItemIcon className={classes.customIconWrapper}>
                  <InlineSVGIcons
                    variant={item.icon}
                    className={
                      item.active
                        ? classes.activeIcon
                        : classes.icon
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={item.primary}
                  className={
                    item.active
                      ? classes.activeListItemText
                      : classes.listItemText
                  }
                />
              </ListItem>
            ))
          }
          {!!hasDefaultDashboardBackLink && (
            <ListItem
              className={
                classNames(classes.customListItem, classes.backToLink)
              }
              button
              component={Link}
              to={
                generatePath(PATH_PROGRAM, { programId: defaultProgram.name })
              }
              key="back-to-dashboard"
              data-cy="back-to-dashboard-navigation"
            >
              <ListItemIcon className={classes.bigIconWrapper}>
                <ExitToApp className={classes.iconFlipped} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={classes.listItemText}
                primary={`Back to ${defaultProgram.label}`}
              />
            </ListItem>
          )}
        </List>
      </div>
    </Drawer>
  );
};

export default MainMenu;
