import React, { useCallback, useMemo, useState } from "react";
import FileTransferListing from "components/pages/consentOrder/fileTransferSummary/listing/fileTransferListing.component";
import { makeStyles } from "mui-styles";
import { SORT_ASCENDING, SORT_DESCENDING } from "components/utils/tableSortLabel.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import RadioInputGroup from "components/utils/form-elements/radioInputGroup.component";
import CustomSelect from "components/utils/form-elements/select.component";
import { isDevelopmentEnv } from "core/environment";
import ButtonDefault from "components/utils/buttonDefault.component";
import { H4 } from "components/utils/headerV2.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import { Search } from "@mui/icons-material";
import { Box, debounce, InputAdornment } from "@mui/material";
import classNames from "classnames";
import AccordionView from "components/utils/accordionView.component";
import ReceiptTrackingTable from "components/dataAnalysis/ReceiptTrackingTable.component";
import FileTransferService from "services/FileTransfer.service";
import theme from "theme/theme";

const useStyles = makeStyles(() => ({
  radioGroup: {
    padding: 8,
    paddingLeft: 0
  },
  radioText: {
    fontSize: theme.typography.body2.fontSize
  },
  sortByItem: {
    [theme.breakpoints.up("lg")]: {
      minWidth: 264,
      marginLeft: "auto"
    },
    [theme.breakpoints.up("xl")]: {
      minWidth: 360
    }
  },
  toolbarItem: {
    marginTop: -16,
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      marginTop: 0
    }
  },
  debugSection: {
    marginBottom: 32
  },
}));


const PAGINATION_DEBUG_COUNT = 50;

const FILTER_TYPE_OPTION_DEFAULT = "all";

const FILTER_TYPE_OPTIONS = [{
  label: "All",
  value: "all"
}, {
  label: "Incoming",
  value: "incoming"
}, {
  label: "Outgoing",
  value: "outgoing"
}];

const SORT_BY_OPTIONS = [{
  label: "Uploaded At (Most Recent)",
  value: "uploaded_asc",
  direction: SORT_ASCENDING,
}, {
  label: "Uploaded At (Least Recent)",
  value: "uploaded_desc",
  direction: SORT_DESCENDING,
}, {
  label: "Last Accessed (Most Recent)",
  value: "downloaded_asc",
  direction: SORT_ASCENDING,
}, {
  label: "Last Accessed (Least Recent)",
  value: "downloaded_desc",
  direction: SORT_DESCENDING,
}, {
  label: "Name (A-Z)",
  value: "name_asc",
  direction: SORT_ASCENDING,
}, {
  label: "Name (Z-A)",
  value: "name_desc",
  direction: SORT_DESCENDING,
}, {
  label: "File Name (A-Z)",
  value: "fileName_asc",
  direction: SORT_ASCENDING,
}, {
  label: "File Name (Z-A)",
  value: "fileName_desc",
  direction: SORT_DESCENDING,
}];


export default function FileTransferPage(props) {
  const classes = useStyles();
  const {
    fileTransfers,
    setFileTransfers,
    uploadErrors,
    setUploadErrors
  } = props;

  const [isPaginationDebug, setIsPaginationDebug] = useState(false);
  const [filterTransferType, setFilterTransferType] = useState(
    FILTER_TYPE_OPTION_DEFAULT
  )
  const [searchFilter, setSearchFilter] = useState("");
  const [sortBy, setSortBy] = useState(SORT_BY_OPTIONS[0]?.value)

  const debugFriendlyFileTransfers = useMemo(() => {
    if (!isPaginationDebug || !fileTransfers?.length) {
      return fileTransfers;
    }
    const out = [];
    for (let i = 0; i < PAGINATION_DEBUG_COUNT; i++) {
      if (i === 0) {
        out.push(...fileTransfers);
      } else {
        out.push({
          ...fileTransfers[0],
          title: `[DEBUG] Copy ${i}: ${fileTransfers[0].title}`,
          fileName: `${i}_${fileTransfers[0].fileName}`,
          fileTransferId: `${fileTransfers[0].fileTransferId}-${i}`,
          _meta: { debug: true }
        });
      }
    }
    return out;
  }, [fileTransfers, isPaginationDebug]);

  const handleSearchChange = useMemo(() => {
    function debouncedSetSearch(event) {
      const { value } = event.target;
      setSearchFilter(value);
    }
    return debounce(debouncedSetSearch, 500);
  }, []);

  const handleNotesChange = useCallback(async (_name, value, id) => {
    try {
      const uploadError = {
        notes: value
      }
      const updatedUploadErrorRes = await FileTransferService.updateUploadErrorNotes(
        id,
        uploadError
      )
      setUploadErrors((prev) => {
        return (prev.map((error) => {
          if (error.uploadErrorId === id) {
            return updatedUploadErrorRes.payload
          } else {
            return error;
          }
        }))
      })
    } catch (error) {
      console.error(error)
    }
  }, [setUploadErrors])

  return (
    <>
      <GridContainer
        justifyContent="space-between"
        spacing={{ xs: 4, xl: 8 }}
      >
        <GridItem
          xs={12}
          md={4}
          lg={3}
          className={classes.toolbarItem}
        >
          <LabelInput
            name="search"
            label="Search"
            defaultValue=""
            variant="default"
            margin="dense"
            onChange={handleSearchChange}
            startAdornment={(
              <InputAdornment>
                <Search fontSize="small" />
              </InputAdornment>
            )}
            readOnly={false}
          />
        </GridItem>
        <GridItem xs={12} md={4} lg={5} className={classes.toolbarItem}>
          <RadioInputGroup
            name="transfer-type"
            groupLabel="Transfer Type"
            options={FILTER_TYPE_OPTIONS}
            value={filterTransferType}
            defaultOption={FILTER_TYPE_OPTIONS[0]?.value}
            radioGroupClass={classes.radioGroup}
            radioLabelTextClass={classes.radioText}
            size="small"
            onChange={event => setFilterTransferType(event.target.value)}
            disabled={debugFriendlyFileTransfers.length <= 1}
            row
          />
        </GridItem>
        <GridItem
          xs={12}
          md={4}
          lg="auto"
          className={classNames(classes.toolbarItem, classes.sortByItem)}
        >
          <CustomSelect
            name="sort-by"
            label="Sort By"
            options={SORT_BY_OPTIONS}
            value={sortBy}
            defaultOption={SORT_BY_OPTIONS[0]?.value}
            variant="default"
            margin="dense"
            onChange={event => setSortBy(event.target.value)}
            disabled={debugFriendlyFileTransfers.length <= 1}
          />
        </GridItem>
        <GridItem
          xs={12}
          md={12}
        >
          <Box marginBottom={2} boxShadow={theme.shadow.elevation2}>
            <AccordionView
              defaultExpanded={false}
              mainLabel="Upload Errors"
              noHoverHighlight
            >
              <ReceiptTrackingTable
                fileTransfers={fileTransfers}
                uploadErrors={uploadErrors}
                handleNotesChange={handleNotesChange}
              />
            </AccordionView>
          </Box>
        </GridItem>
      </GridContainer>
      <FileTransferListing
        fileTransfers={debugFriendlyFileTransfers}
        setFileTransfers={setFileTransfers}
        filterTransferType={filterTransferType}
        searchFilter={searchFilter}
        sortBy={sortBy}
      />
      {!!isDevelopmentEnv && (
        <div className={classes.debugSection}>
          <H4 color="red" withMargin>Debug Options</H4>
          <div>
            <ButtonDefault
              onClick={() => setIsPaginationDebug(!isPaginationDebug)}
              background={isPaginationDebug ? "white" : "red"}
              color={isPaginationDebug ? "red" : "white"}
            >
              {isPaginationDebug ? "Disable " : "Enable "}
              Test Pagination (Development only)
            </ButtonDefault>
          </div>
        </div>
      )}
    </>
  );
}
