import React, { useCallback, useMemo, useState } from "react";
import { SORT_DESCENDING } from "components/utils/tableSortLabel.component";
import { Box, Dialog } from "@mui/material";
import FileTransferTagModal from "components/pages/consentOrder/fileTransferSummary/listing/fileTransferTagModal.component";
import moment from "moment";
import FileTransferPaginatedList from "components/pages/consentOrder/fileTransferSummary/listing/fileTransferPaginatedList.component";
import { LOG_ACTION_TYPE_DOWNLOAD } from "components/pages/consentOrder/fileTransferSummary/constants/fileTransfer.constants";


const FILE_TRANSFER_SEARCH_FIELDS = ["title", "fileName", "fileSize"];
const FILE_TRANSFER_ATTACHMENT_SEARCH_FIELDS = ["title", "fileName"];
const FILE_TRANSFER_TAG_SEARCH_FIELDS = ["label", "value"];

const sortByStringValue = (aString, bString, isDescending = false) => (
  isDescending ? bString.localeCompare(aString) : aString.localeCompare(bString)
);

const sortByDatetime = (aDatetime, bDatetime, isDescending = false) => {
  const sortValueA = aDatetime ? moment(aDatetime).valueOf() : 0;
  const sortValueB = bDatetime ? moment(bDatetime).valueOf() : 0;
  return isDescending ? sortValueA - sortValueB : sortValueB - sortValueA;
};


export default function FileTransferListing(props) {
  const {
    fileTransfers, setFileTransfers, filterTransferType, searchFilter, sortBy
  } = props;

  const [tagModalFileTransferId, setTagModalFileTransferId] = useState(null);

  const openTagModalFileTransfer = useMemo(() => (
    fileTransfers?.find?.(fileTransfer => (
      fileTransfer.fileTransferId === tagModalFileTransferId
    ))
  ), [fileTransfers, tagModalFileTransferId]);

  const filteredFileTransfers = useMemo(() => {
    if (!fileTransfers) {
      return [];
    }
    let output = [...fileTransfers];
    if (["incoming", "outgoing"].includes(filterTransferType)) {
      output = output.filter(fileTransfer => (
        fileTransfer.type === filterTransferType
      ));
    }
    if (searchFilter) {
      const query = searchFilter.toLowerCase();
      output = output.filter(fileTransfer => {
        const attachments = fileTransfer._associations.FileTransferAttachment;
        const tags = fileTransfer._associations.FileTransferTag;
        return (
          FILE_TRANSFER_SEARCH_FIELDS.some(fieldName => (
            fileTransfer[fieldName]?.toLowerCase?.()?.includes?.(query)
          )) ||
          FILE_TRANSFER_ATTACHMENT_SEARCH_FIELDS.some(fieldName => (
            attachments
              .concat(tags.flatMap(tag => (
                tag._associations.FileTransferAttachment
              )))
              .some(attachment => (
                attachment[fieldName]?.toLowerCase?.()?.includes?.(query)
              ))
          )) ||
          FILE_TRANSFER_TAG_SEARCH_FIELDS.some(fieldName => (
            tags.some(tag => (
              tag[fieldName]?.toLowerCase?.()?.includes?.(query)
            ))
          ))
        );
      });
    }
    return output;
  }, [fileTransfers, filterTransferType, searchFilter]);

  const sortedFileTransfers = useMemo(() => {
    if (filteredFileTransfers.length < 2) {
      return filteredFileTransfers;
    }
    const fileTransfersToSort = [...filteredFileTransfers];
    const isDescending = sortBy.includes(SORT_DESCENDING);
    if (sortBy.startsWith("name_")) {
      return fileTransfersToSort.sort((a, b) => (
        sortByStringValue(
          a.title || a.fileName,
          b.title || b.fileName,
          isDescending
        )
      ));
    } else if (sortBy.startsWith("fileName_")) {
      return fileTransfersToSort.sort((a, b) => (
        sortByStringValue(a.fileName, b.fileName, isDescending)
      ));
    } else if (sortBy.startsWith("uploaded")) {
      return fileTransfersToSort.sort((a, b) => (
        sortByDatetime(a.uploadedDatetime, b.uploadedDatetime, isDescending)
      ));
    } else if (sortBy.startsWith("downloaded")) {
      const downloadDatetimesByLog = Object.fromEntries(
        fileTransfersToSort.map(fileTransfer => {
          const logItems = fileTransfer?._associations?.FileTransferLog;
          const latestDownloadLogged = !logItems?.length ? null : (
            logItems.find(logItem => (
              logItem.actionType === LOG_ACTION_TYPE_DOWNLOAD
            ))
          );
          return [
            fileTransfer.fileTransferId,
            latestDownloadLogged?.actionDatetime || 0
          ];
        })
      );
      return fileTransfersToSort.sort((a, b) => {
        const isoTimeA = downloadDatetimesByLog[a.fileTransferId];
        const isoTimeB = downloadDatetimesByLog[b.fileTransferId];
        return sortByDatetime(isoTimeA, isoTimeB, isDescending);
      });
    }
    console.error(`Sort type not recognized: ${sortBy}`);
    return fileTransfersToSort;
  }, [sortBy, filteredFileTransfers]);

  const setOneFileTransfer = useCallback(nextFileTransfer => (
    setFileTransfers(prev => {
      const nextFileTransfers = [...prev];
      const fileTransferIndex = nextFileTransfers.findIndex(transfer => (
        transfer.fileTransferId === nextFileTransfer.fileTransferId
      ));
      if (fileTransferIndex >= 0) {
        nextFileTransfers[fileTransferIndex] = nextFileTransfer;
      } else {
        nextFileTransfers.push(nextFileTransfer);
      }
      return nextFileTransfers;
    })
  ), [setFileTransfers]);

  return (
    <Box marginBottom={8}>
      <FileTransferPaginatedList
        fileTransfers={sortedFileTransfers}
        setOneFileTransfer={setOneFileTransfer}
        setTagModalFileTransferId={setTagModalFileTransferId}
      />
      <Dialog
        open={!!tagModalFileTransferId}
        onClose={() => setTagModalFileTransferId(null)}
        maxWidth="lg"
        fullWidth
      >
        <FileTransferTagModal
          onClose={() => setTagModalFileTransferId(null)}
          fileTransfer={openTagModalFileTransfer}
          setFileTransfer={setOneFileTransfer}
        />
      </Dialog>
    </Box>
  );
}
