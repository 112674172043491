import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import AttachmentsUploader from "components/claims/documents/attachmentsUploader.component";
import AttachmentViewer from "components/claims/documents/attachmentViewer.component";
import DocCell from "components/claims/documents/docCell.component";
import ButtonDefault from "components/utils/buttonDefault.component";
import CustomModal from "components/utils/modal.component";
import SortableTable from "components/utils/tables/sortableTable.component";
import { useCallback, useMemo, useState } from "react";
import { ACTION_ADD_CLAIM_DOCUMENTS } from "reducers/shared/claimReview.reducer";
import DocumentService from "services/Document.service";
import PaymentService from "services/Payment.service";
import { isReadOnly } from "utils/roles.utils";

const TABLE_HEADERS = [
  { name: "fileName", display: "Document", alignment: "left" },
];

const ClaimDocumentTable = (props) => {
  const { handleDownloadDocument, attachmentDocs, dispatch } = props;
  const { claim } = props;
  const { payment, reissueRequest, onReissuePaymentSuccess } = props;
  const [copiedPasswordDocId, setCopiedPasswordDocId] = useState();
  const [attachmentToView, setAttachmentToView] = useState();
  const [openAttachmentUploader, setOpenAttachmentUploader] = useState();
  const [isSavingAttachments, setIsSavingAttachments] = useState(false)
  const isReissueDocument = !!reissueRequest;
  const readOnly = useMemo(isReadOnly, []);

  const tableData = useMemo(() => {
    if (!attachmentDocs) {
      return null;
    }
    return attachmentDocs.map((document) => {
      return {
        fileName: {
          node: (
            <DocCell
              setAttachmentToView={setAttachmentToView}
              documentData={document}
              setCopiedPasswordDocId={setCopiedPasswordDocId}
              copiedPasswordDocId={copiedPasswordDocId}
              handleDownloadDocument={handleDownloadDocument}
            />
          ),
        },
      };
    });
  }, [copiedPasswordDocId, handleDownloadDocument, attachmentDocs]);

  const closeAttachmentsUploadModal = useCallback(() => {
    setOpenAttachmentUploader(false)
  }, [])

  const submitClaimAttachments = useCallback(
    async (data, formDataInstance) => {
      try {
        setIsSavingAttachments(true)
        const body = new FormData();
        const formAttachments = formDataInstance.getAll("attachments");

        if (!formAttachments?.[0]?.name) {
          return;
        }
        const fileTransferAttachments = [];
        if (formAttachments?.length) {
          formAttachments
            .filter((file) => file.name)
            .forEach((file, index) => {
              const fileName = file.name;
              body.append(index, file, fileName);
              fileTransferAttachments.push({
                descriptionPassword: data[`descriptionPassword-${fileName}`],
                _fileIndex: index,
                fileName,
                claimId: claim.claimId,
              });
            });
        }
        body.append("documents", JSON.stringify(fileTransferAttachments));
        const response = await DocumentService.batchCreateDocuments(body);
        dispatch({
          type: ACTION_ADD_CLAIM_DOCUMENTS,
          payload: response.payload,
        })
        closeAttachmentsUploadModal()
      } catch (error) {
        console.error(error);
      } finally {
        setIsSavingAttachments(false)
      }
    },
    [claim?.claimId, dispatch, closeAttachmentsUploadModal]
  );

  const submitReissueAttachments = useCallback(async (data, formData, event) => {
    event.stopPropagation()
    const requestFormData = new FormData();
    const reissueDocuments = [];
    const documentFiles = formData.getAll("reissueDocument");

    documentFiles.forEach(file => {
      reissueDocuments.push({
        paymentReissueRequestId: reissueRequest.paymentReissueRequestId,
        claimId: payment.claimId,
        distributionDatasetId: payment.distributionDatasetId,
        descriptionPassword: data[`descriptionPassword-${file.name}`] || null,
        fileName: file.name,
      });
      requestFormData.append("reissueDocumentFiles", file, file.name);
    });

    requestFormData.set("reissueDocuments", JSON.stringify(reissueDocuments));

    try {
      const response = await PaymentService.upsertPaymentReissueRequest(
        payment.paymentId,
        requestFormData
      );
      onReissuePaymentSuccess(response);
      closeAttachmentsUploadModal()
    } catch (error) {
      console.error(error);
    } finally {
      setIsSavingAttachments(false)
    }
  }, [payment, reissueRequest, onReissuePaymentSuccess, closeAttachmentsUploadModal]);

  return (
    <div>
      <SortableTable
        data={tableData}
        headers={TABLE_HEADERS}
        denseHeader
        denseBody
      />
      {!readOnly && (
        <Box display="flex" justifyContent="end" marginRight={1} marginTop={1}>
          <ButtonDefault
            component="label"
            variant="small"
            background="secondary"
            startIcon={<AddCircleOutlineOutlined fontSize="small" />}
            data-cy="btn-add-documents"
            onClick={() => setOpenAttachmentUploader(true)}
          >
            <Box marginLeft={1}>Add Attachments</Box>
          </ButtonDefault>
        </Box>
      )}
      <AttachmentViewer
        attachmentToView={attachmentToView}
        setAttachmentToView={setAttachmentToView}
        setCopiedPasswordDocId={setCopiedPasswordDocId}
        copiedPasswordDocId={copiedPasswordDocId}
      />
      <CustomModal
        open={!!openAttachmentUploader}
        onClose={closeAttachmentsUploadModal}
      >
        <AttachmentsUploader
          onSubmit={isReissueDocument ? submitReissueAttachments : submitClaimAttachments}
          onClose={closeAttachmentsUploadModal}
          claim={claim}
          payment={payment}
          isSaving={isSavingAttachments}
        />
      </CustomModal>
    </div>
  );
};
export default ClaimDocumentTable;
