import React, { useState, useEffect, useCallback, useReducer, useContext } from "react";
import { makeStyles } from "mui-styles";
import {
  attachMetaToBuilderSections,
  isSectionHeaderMissing,
  makeSectionClientId,
  getInitialPractices,
  isMissingVersion,
  dispatchSaveAjaxError,
} from "components/builder/utils/builder.utils";
import BuilderService from "services/Builder.service";
import classNames from "classnames";
import Loader from "components/utils/loader.components";
import CustomLink from "components/utils/link.component";
import InlineSVGIcons from "components/utils/inlineSVGIcons.component.js";
import PolicyRegsTableSection from "components/builder/policy/policyRegsTableSection.component";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import BuilderHeaderInfo from "components/builder/shared/builderHeaderInfo.component";
import DeleteIconButton from "components/utils/deleteIconButton.component";
import BuilderSectionHeader from "../shared/builderSectionHeader.component";
import BuilderSectionRTE from "../shared/builderSectionRTE.component";
import { findMaxByField } from "utils/number.utils";
import useNumericParams from "hooks/useNumericParams";
import { builderModes, builderSectionTypes } from "components/builder/constants/builder.constants";
import builderReducer, {
  ACTION_ADD_POLICY_BUILDER_SECTION,
  ACTION_ADD_POLICY_REF_SECTIONS,
  ACTION_DELETE_POLICY_BUILDER_SECTION,
  ACTION_SET_BUILDER_AFTER_SAVE,
  ACTION_SET_BUILDER_IS_SAVING,
  ACTION_SET_BUILDER_LOAD_TEMPLATE,
  ACTION_SET_BUILDER_COPY_PREVIOUS,
  ACTION_SET_MISSING_HEADER_MESSAGE,
  ACTION_SET_REFERENCE_SUBGROUPS,
  policyBuilderInitialState,
  ACTION_SET_BUILDER_EDIT_CURRENT,
} from "reducers/shared/builder/builder.reducer";
import BuilderHeader from "../shared/builderHeader.component";
import BuilderButtonsColumn from "../shared/builderButtonsColumn.component";
import RegGuidelineFrameworks from "../shared/regGuidelineFrameworks.component";
import { Box } from "@mui/material";
import OrganizationContext from "contexts/organization.context";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  innerContentContainer: {
    paddingRight: 170,
    paddingBottom: 20,
  },
  sectionWrapper: {
    marginBottom: 40,
  },
  addSectionWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "left",
    marginBottom: 50,
    marginTop: 20,
  },
  deleteSectionWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },
  addSectionIcon: {
    fontSize: theme.typography.body1.fontSize,
    color: "white",
    marginRight: 6,
  },
  customIconWrapper: {
    height: 12,
    alignItems: "center",
    marginRight: 5,
    display: "inline-flex",
  },
}));

const PolicyBuilder = (props) => {
  const classes = useStyles();
  // ===== SECTIONS ==== \\
  const componentObject = props.componentObject;
  const { programId } = useNumericParams();
  //Dialogs
  const [openDialog, setOpenDialog] = useState(false);
  const { state: organizationState } = useContext(OrganizationContext);
  const [state, dispatch] = useReducer(
    builderReducer,
    policyBuilderInitialState
  );
  const {
    builderInfo,
    builderSections,
    referenceGroups,
    referenceSubGroups,
    loadedVersion,
    statusMessage,
    activeForm,
    builderInfoErrors,
    disableBuilder,
    previousVersions,
  } = state;

  useEffect(() => {
    if (props.mode === builderModes.LOAD_TEMPLATE) {
      const getFormattedPractices = async () => {
        const formattedPractices = await getInitialPractices(
          props.policyFrameworks
        );

        dispatch({
          type: ACTION_SET_REFERENCE_SUBGROUPS,
          payload: formattedPractices,
        });
      };

      const newBuilder = {
        ComponentObjects_ComponentObject_ID:
          props.chosenProgram.compObjId || componentObject.ComponentObject_ID,
        Title: "",
        Description: null,
        Version: "0.1",
        Approval_Date: moment(new Date()).format("YYYY-MM-DD"),
        Status: "Active",
        Type: props.type,
        ParentBuilderDoc_ID: null,
        BuilderDoc_ID: null,
        Show_Approval_Sections: 1,
      };

      dispatch({
        type: ACTION_SET_BUILDER_LOAD_TEMPLATE,
        payload: {
          builderInfo: newBuilder,
          builderSections: [],
          referenceGroups: props.policyFrameworks,
        },
      });
      getFormattedPractices();
    }
  }, [
    componentObject.ComponentObject_ID,
    props.builder,
    props.chosenProgram.compObjId,
    props.mode,
    props.policyFrameworks,
    props.type,
  ]);

  useEffect(() => {
    if (props.mode === builderModes.EDIT_CURRENT) {
      const getAllPolicyData = async () => {
        const [
          builderDataRes,
          builderSectionDataRes,
          referenceGroupsRes,
          practiceRefsRes,
        ] = await Promise.all([
          BuilderService.getBuilderById(
            props.builder.Builder_ID,
            organizationState.activeOrganizationId
          ),
          BuilderService.getBuilderSections(props.builder.Builder_ID),
          BuilderService.getBuilderRegRefs(props.builder.Builder_ID),
          BuilderService.getPracticeBuilderRegRefs(props.builder.Builder_ID),
        ]);
        const sections = attachMetaToBuilderSections(
          builderSectionDataRes.payload
        );

        dispatch({
          type: ACTION_SET_BUILDER_EDIT_CURRENT,
          payload: {
            builderInfo: builderDataRes.payload,
            builderSections: sections,
            referenceGroups: referenceGroupsRes.payload,
            referenceSubGroups: practiceRefsRes.payload,
            builderInfoErrors: isMissingVersion(builderDataRes.payload.Version),
            previousVersions: props.builder.previousVersions
          },
        });
      };
      getAllPolicyData();
    }
  }, [props.builder, props.mode, organizationState.activeOrganizationId]);

  useEffect(() => {
    if (props.mode === builderModes.COPY_PREV_BUILDER) {
      const getAllPolicyData = async () => {
        const [
          builderToCopyDataRes,
          builderSectionsToCopyRes,
          referenceGroupsToCopyRes,
          subGroupRefsToCopyRes,
        ] = await Promise.all([
          BuilderService.getBuilderById(
            props.prevBuilderId,
            organizationState.activeOrganizationId
          ),
          BuilderService.getBuilderSections(props.prevBuilderId),
          BuilderService.getBuilderRegRefs(props.prevBuilderId),
          BuilderService.getPracticeBuilderRegRefs(props.prevBuilderId),
        ]);
        const currentBuilderId = props.builderBeingOverwrittenByCopy.Builder_ID;
        const newBuilder = {
          ...builderToCopyDataRes.payload,
          Builder_ID: currentBuilderId,
          Status: "Active",
          Version: null,
          Approval_Date: null,
          Show_Approval_Sections: 1,
        };

        const formattedRefGroupsToCopy = referenceGroupsToCopyRes.payload.map(
          (refGroupToCopy) => ({
            ...refGroupToCopy,
            Reg_Refs_ID: null,
            Builder_Builder_ID: currentBuilderId,
            BuilderSection_ID: null,
          })
        );

        const formattedRefSubGroupsToCopy = subGroupRefsToCopyRes.payload.map(
          (refToCopy) => ({
            ...refToCopy,
            Reg_Refs_ID: null,
            Builder_Builder_ID: currentBuilderId,
            BuilderSection_ID: null,
          })
        );
        // Builder_Builder_ID in sections get assigned value on backend
        const sections = builderSectionsToCopyRes.payload
          .map((section, index) => ({
            SectionHeader: section.SectionHeader,
            SectionContent: section.SectionContent,
            Section_Order: section.Section_Order,
            Type: section.Type,
            RegRefs_Reg_Refs_ID: section.RegRefs_Reg_Refs_ID,
            RegFramework_ID: section.RegFramework_ID,
            _meta: { clientId: makeSectionClientId(null, index) },
          }));

        dispatch({
          type: ACTION_SET_BUILDER_COPY_PREVIOUS,
          payload: {
            builderInfo: newBuilder,
            builderSections: sections,
            referenceGroups: formattedRefGroupsToCopy,
            referenceSubGroups: formattedRefSubGroupsToCopy,
            loadedVersion: builderToCopyDataRes.payload.Version,
            previousVersions: props.builder.previousVersions,
          },
        });
      };
      getAllPolicyData();
    }
  }, [
    props.builder,
    props.mode,
    props.prevBuilderId,
    componentObject.ComponentObject_ID,
    props.chosenProgram.compObjId,
    props.builderBeingOverwrittenByCopy,
    organizationState.activeOrganizationId
  ]);

  const addNewSection = (sectionOrder) => {
    const newSection = {
      Section_Order: sectionOrder + 1,
      SectionContent: "",
      SectionHeader: "",
      Type: null,
      _meta: {
        clientId: makeSectionClientId(),
      },
    };
    dispatch({
      type: ACTION_ADD_POLICY_BUILDER_SECTION,
      payload: newSection,
    });
    // reference and referenceTable sections always have to be at the end in Policy Builder
  };

  const deleteSection = (section) => {
    dispatch({
      type: ACTION_DELETE_POLICY_BUILDER_SECTION,
      payload: section,
    });
  };

  const submitNewPolicy = useCallback(async () => {
    dispatch({
      type: ACTION_SET_BUILDER_IS_SAVING,
    });
    try {
      if (isSectionHeaderMissing(builderSections)) {
        dispatch({
          type: ACTION_SET_MISSING_HEADER_MESSAGE,
        });
        return;
      }
      const builderData = {
        ...builderInfo,
        Program_ID: props.chosenProgram.id ? props.chosenProgram.id : programId,
      };
      const tableHTML = document.getElementById("hiddenRegsTable");
      const builderSectionList = builderSections.map((builderSec) => {
        return {
          ...builderSec,
          SectionContent:
            builderSec.Type === builderSectionTypes.REFERENCE_TABLE
              ? `<table id="policyPractices">${tableHTML.innerHTML}</table>`
              : builderSec.SectionContent,
        };
      });

      const referenceGroupList = referenceGroups.map((fw) => ({
        Reg_Source: fw.Object_Ref, //Object_Ref Reg_Source
        Source_Object: fw.Source_Object,
        Source_ID: fw.Source_ID,
        Type: "Policy",
        RegFramework_ID: fw.RegFramework_id,
        Detail: fw.Detail,
        Status: "Active",
      }));

      const referenceSubGroupsArray = referenceSubGroups.map((prac) => ({
        Reg_Source: prac.Reg_Source, //Object_Ref Reg_Source
        Source_Object: prac.Source_Object,
        Source_ID: parseInt(prac.Source_ID),
        Type: "policyPractice",
        RegFramework_ID: prac.RegFramework_ID,
        Status: prac?.Status || "Active",
      }));

      const regRefList = [...referenceGroupList, ...referenceSubGroupsArray];
      const builderRes = await BuilderService.createBuilder(
        builderData,
        builderSectionList,
        regRefList
      );
      const formattedBuilderSections = attachMetaToBuilderSections(
        builderRes.payload.builderSectionData
      );

      dispatch({
        type: ACTION_SET_BUILDER_AFTER_SAVE,
        payload: {
          builderInfo: builderRes.payload.builderData,
          builderSections: formattedBuilderSections,
          referenceGroups: builderRes.payload.builderRegRefs,
          referenceSubGroups: builderRes.payload.practiceBuilderRegRefs,
        },
      });
    } catch (error) {
      dispatchSaveAjaxError(dispatch, error);
    }
  }, [
    builderSections,
    builderInfo,
    dispatch,
    programId,
    props.chosenProgram.id,
    referenceGroups,
    referenceSubGroups,
  ]);

  const submitUpdatedPolicy = useCallback(async () => {
    dispatch({
      type: ACTION_SET_BUILDER_IS_SAVING,
    });
    try {
      if (isSectionHeaderMissing(builderSections)) {
        dispatch({
          type: ACTION_SET_MISSING_HEADER_MESSAGE,
        });
        return;
      }
      const tableHTML = document.getElementById("hiddenRegsTable");
      const builderSectionList = builderSections.map((builderSec) => {
        return {
          ...builderSec,
          SectionContent:
            builderSec.Type === builderSectionTypes.REFERENCE_TABLE
              ? `<table id="policyPractices">${tableHTML.innerHTML}</table>`
              : builderSec.SectionContent,
        };
      });

      const referenceSubGroupsArray = referenceSubGroups.map((prac) => ({
        Builder_Builder_ID: prac.Builder_Builder_ID,
        Reg_Refs_ID: prac.Reg_Refs_ID,
        Reg_Source: prac.Reg_Source, //Object_Ref Reg_Source
        Source_Object: prac.Source_Object,
        Source_ID: parseInt(prac.Source_ID),
        Type: "policyPractice",
        RegFramework_ID: prac.RegFramework_ID,
        Status: prac?.Status || "Active",
      }));

      const builderRes = await BuilderService.updateBuilder(
        builderInfo.Builder_ID,
        builderInfo,
        builderSectionList,
        referenceSubGroupsArray
      );

      const formattedBuilderSections = attachMetaToBuilderSections(
        builderRes.payload.builderSectionData
      );

      dispatch({
        type: ACTION_SET_BUILDER_AFTER_SAVE,
        payload: {
          builderInfo: builderRes.payload.builderData,
          builderSections: formattedBuilderSections,
          referenceGroups: builderRes.payload.builderRegRefs,
          referenceSubGroups: builderRes.payload.practiceBuilderRegRefs,
        },
      });
    } catch (error) {
      dispatchSaveAjaxError(dispatch, error);
    }
  }, [
    builderSections,
    builderInfo,
    dispatch,
    referenceSubGroups,
  ]);

  const addRefAndRefTableSection = useCallback(() => {
    const sections = [...builderSections];
    const maxOrder = findMaxByField(sections, "Section_Order");
    const newSections = [
      {
        Section_Order: maxOrder + 1,
        SectionContent: "",
        SectionHeader: "",
        Type: builderSectionTypes.REFERENCE,
        _meta: {
          clientId: makeSectionClientId(null, "reference"),
        },
      },
      {
        Section_Order: maxOrder + 2,
        SectionContent: "",
        SectionHeader: "Regulation/Guideline References Table",
        Type: builderSectionTypes.REFERENCE_TABLE,
        _meta: {
          clientId: makeSectionClientId(null, "referenceTable"),
        },
      },
    ];

    dispatch({
      type: ACTION_ADD_POLICY_REF_SECTIONS,
      payload: newSections,
    });
  }, [builderSections]);

  const fetchRefSubGroups = useCallback(async () => {
    const formattedPractices = await getInitialPractices(referenceGroups);
    dispatch({
      type: ACTION_SET_REFERENCE_SUBGROUPS,
      payload: formattedPractices,
    });
  }, [referenceGroups]);

  const fetchSubgroupsAndLoadRefSections = useCallback(async () => {
    await fetchRefSubGroups();
    addRefAndRefTableSection();
  }, [fetchRefSubGroups, addRefAndRefTableSection]);

  if (builderSections && builderInfo) {
    const referenceSection = builderSections.find(
      (section) => section.Type === builderSectionTypes.REFERENCE
    );
    const referenceTableSection = builderSections.find(
      (section) => section.Type === builderSectionTypes.REFERENCE_TABLE
    );

    return (
      <>
        <BuilderHeader
          readOnly={props.readOnly}
          mode={props.mode}
          type={props.type}
          setBuilderMode={props.setBuilderMode}
          activeForm={activeForm}
          setOpenDialog={setOpenDialog}
          statusMessage={statusMessage}
          dispatch={dispatch}
        />

        {/*  FRAMEWORKS LISTING */}
        <RegGuidelineFrameworks frameworks={referenceGroups} />

        <div className={classes.innerContentContainer}>
          <BuilderHeaderInfo
            previousVersions={previousVersions}
            builderBeingOverwrittenByCopy={props.builderBeingOverwrittenByCopy}
            loadedVersion={loadedVersion}
            prevBuilderId={props.prevBuilderId}
            builderInfo={builderInfo}
            builderInfoErrors={builderInfoErrors}
            dispatch={dispatch}
            disableBuilder={disableBuilder}
          />
          <div>
            {(builderSections.length === 0 || (
              builderSections.length === 2 && (
                builderSections.some(
                  (section) => section.Type === builderSectionTypes.REFERENCE
                )
              )
            )) && (
              <div className={classes.addSectionWrapper}>
                <CustomLink
                  onClick={() => {
                    addNewSection(0);
                  }}
                  variant="linkBar"
                  startIcon={
                    <AddCircleOutlineOutlinedIcon
                      className={classes.addSectionIcon}
                    />
                  }
                  test="AddSection"
                  disableLinkBar={disableBuilder}
                >
                  Add Section
                </CustomLink>
              </div>
            )}
            {/* ======== BUILDER SECTIONS ======== */}
            {builderSections
              .filter((section) => !section.Type)
              .map((section) => {
                return (
                  <div
                    key={`${section._meta.clientId}_${section.Section_Order}`}
                    className={classNames(classes.sectionWrapper)}
                  >
                    {/* ======== SECTION HEADER ======== */}

                    <BuilderSectionHeader
                      section={section}
                      dispatch={dispatch}
                      disableBuilder={disableBuilder}
                    />
                    <BuilderSectionRTE
                      section={section}
                      dispatch={dispatch}
                      disableBuilder={disableBuilder}
                    />

                    <div className={classes.deleteSectionWrapper}>
                      <DeleteIconButton
                        target="Section"
                        onClick={() => {
                          const deleteNotice =
                            "Are you sure you want to delete this section?";
                          if (window.confirm(deleteNotice)) {
                            deleteSection(section);
                          }
                        }}
                        disabled={disableBuilder}
                      />
                    </div>
                    {/* ADD BUTTON */}
                    <div className={classes.addSectionWrapper}>
                      <CustomLink
                        onClick={() => {
                          addNewSection(section.Section_Order);
                        }}
                        variant="linkBar"
                        startIcon={
                          <AddCircleOutlineOutlinedIcon
                            className={classes.addSectionIcon}
                          />
                        }
                        test="AddSection"
                        disableLinkBar={disableBuilder}
                      >
                        Add Section
                      </CustomLink>
                    </div>
                  </div>
                );
              })}

            {!!referenceSection && (
              <div className={classNames(classes.sectionWrapper)}>
                <BuilderSectionHeader
                  section={referenceSection}
                  dispatch={dispatch}
                  test="reference-section"
                  disableBuilder={disableBuilder}
                />

                <BuilderSectionRTE
                  section={referenceSection}
                  dispatch={dispatch}
                  disableBuilder={disableBuilder}
                />
              </div>
            )}

            {!!referenceTableSection && (
              <div className={classNames(classes.sectionWrapper)}>
                <PolicyRegsTableSection
                  referenceGroups={referenceGroups}
                  referenceSubGroups={referenceSubGroups}
                  dispatch={dispatch}
                  readOnly={props.readOnly || disableBuilder}
                />
                <div className={classes.deleteSectionWrapper}>
                  <DeleteIconButton
                    target="Section"
                    onClick={() => {
                      const deleteNotice =
                        "Are you sure you want to delete the Regulation/Guideline References section?";
                      if (window.confirm(deleteNotice)) {
                        deleteSection(referenceTableSection);
                      }
                    }}
                    disabled={disableBuilder}
                  />
                </div>
              </div>
            )}
          </div>

          {
            !!referenceSubGroups &&
            !builderSections.some(
              (section) => section.Type === builderSectionTypes.REFERENCE
            ) && (
              <Box marginBottom={4}>
                <CustomLink
                  onClick={
                    referenceSubGroups.length === 0
                      ? fetchSubgroupsAndLoadRefSections
                      : addRefAndRefTableSection
                  }
                  variant="linkBarGreen"
                  startIcon={
                    <div className={classes.customIconWrapper}>
                      <InlineSVGIcons
                        variant="scales-icon"
                        className={classes.scalesIcon}
                      />
                    </div>
                  }
                  test="AddRegRefs"
                  disableLinkBar={disableBuilder}
                >
                  Add Regulation/Guideline References
                </CustomLink>
              </Box>
            )
          }
        </div>
        {/* SAVE & CANCEL BUTTONS */}
        <BuilderButtonsColumn
          readOnly={props.readOnly}
          state={state}
          setBuilderMode={props.setBuilderMode}
          sectionLess={builderSections.length === 0}
          mode={props.mode}
          submitNewBuilder={submitNewPolicy}
          submitUpdatedBuilder={submitUpdatedPolicy}
          setOpenDialog={setOpenDialog}
          openDialog={openDialog}
          componentObject={componentObject}
          chosenProgram={props.chosenProgram}
          associatedProgramId={
            props.chosenProgram.id ? props.chosenProgram.id : programId
          }
          dispatch={dispatch}
          statusMessage={statusMessage}
        />
      </>
    );
  } else {
    return <Loader />;
  }
};

export default PolicyBuilder;
