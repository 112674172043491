import React, { useContext, useMemo, useState } from "react";
import { makeStyles } from "mui-styles";
import HeaderPrimary from "components/utils/header.component";
import InlineSVGIcons from "components/utils/inlineSVGIcons.component";
import { Popover } from "@mui/material";
import AlertsContainer from "components/shared/alerts/alertsContainer.component";
import AlertContext from "contexts/alert.context";
import AlertsFeed from "components/shared/alerts/alertsFeed.component";
import { alertsFeedVariants } from "components/shared/alerts/constants/alerts.constants";
import ProgramsContext from "contexts/programs.context";
import useNumericParams from "hooks/useNumericParams";
import BadgeStyled from "components/utils/badgeStyled.component";
import { H4 } from "components/utils/headerV2.component";
import useActiveConsentOrder from "hooks/useActiveConsentOrder";

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
    height: 36,
    width: 36,
    padding: 7,
    borderRadius: 50,
    background: (
      `radial-gradient(
        ellipse farthest-side at 65% 38%,
        #175db5 14%,
        #164c93 58%,
        #11386c 93%,
        #0c294f 100%
      )`
    )
  },
  alertIconWrapper: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 10,
    "&:hover": {
      cursor: "pointer",
    },
  },
  badge: {
    right: -2,
    top: -8,
    border: `1px solid white`,
    padding: "0 2px",
    fontSize: theme.typography.caption.fontSize,
    height: 20,
    fontWeight: "bold",
    borderRadius: 40,
    backgroundColor: theme.palette.teal.main,
  },
  iconContainer: {
    fill: "white",
    display: "flex",
    height: 20,
  },
  alertIconContainer: {
  },
  icon: {
    height: 20,
  },
  headerWrapper: {
    display: "inline-flex",
    alignItems: "center",
  },
  remediationTitle: {
    marginRight: -64,
    whiteSpace: "nowrap"
  },
  dense: {
    margin: 0,
    paddingTop: 7,
    paddingBottom: 7,
  },
}));


export const Title = (props) => {
  const classes = useStyles();
  const [activeRemediation] = useActiveConsentOrder();
  const { componentObjectId, programId } = useNumericParams();
  const { state: alertState } = useContext(AlertContext);
  const { state: programsState } = useContext(ProgramsContext);

  //for popover
  const [titleAlertEl, setTitleAlertEl] = useState(null);
  const open = Boolean(titleAlertEl);
  const id = open ? "title-alerts" : undefined;

  const programName = useMemo(() => (
    programsState.programs?.[programId]?.label
  ), [programsState.programs, programId]);

  const handleClose = () => {
    setTitleAlertEl(null);
  };

  const openAlerts = (event) => {
    setTitleAlertEl(event.currentTarget);
  };

  return (
    <>
      <div>
        {!!activeRemediation?.title && (
          <H4
            color="tertiary"
            className={classes.remediationTitle}
            data-cy="rem-header-title"
          >
            {activeRemediation.title}
          </H4>
        )}
        <HeaderPrimary
          variant={props.variant ? props.variant : "h1Primary"}
          className={!!props.dense && classes.dense}
        >
          {/* TITLE ONLY = only used by Search & Admin page */}
          {!!props.titleOnly && !props.dashboardTitle && (
            <span className={classes.headerWrapper}>
              {!!props.startIcon && (
                <div className={classes.iconWrapper}>
                  <div className={classes.iconContainer}>
                    {props.startIcon}
                  </div>
                </div>
              )}
              {`${props.title}`}
            </span>
          )}

          {/* DASHBOARD TITLE */}
          {!!props.dashboardTitle && !!programName && (
            <span
              className={classes.headerWrapper}
              data-cy={`title-dashboard-${props.title}`}
            >
              {props.title}
            </span>
          )}

          {/* ===== DEFAULT ===== */}
          {!props.titleOnly && !props.dashboardTitle && !!programName && (
            <span
              className={classes.headerWrapper}
              data-cy={`title-${programName} ${props.title}`}
            >
              {programName} {props.title}
              {/* ALERTS ICON FOR COMP OBJECT PAGES */}
              {!!componentObjectId && !!alertState.componentObjectAlertCount && (
                <div
                  className={classes.alertIconWrapper}
                  onClick={(event) => openAlerts(event)}
                >
                  <div className={classes.alertIconContainer}>
                    <InlineSVGIcons
                      variant="Notifications"
                      className={classes.icon}
                    />
                    <BadgeStyled
                      data-cy="headerAlertBadge"
                      alertCount={alertState.componentObjectAlertCount}
                      classes={{ badge: classes.badge }}
                      color="secondary"
                    />
                  </div>
                </div>
              )}
            </span>
          )}
        </HeaderPrimary>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={titleAlertEl}
        onClose={handleClose}
      >
        <AlertsContainer variant="feedView">
          <AlertsFeed
            setTitleAlertEl={setTitleAlertEl}
            maxHeight={455}
            test="header"
            variant={alertsFeedVariants.COMPONENT_OBJECT}
          />
        </AlertsContainer>
      </Popover>
    </>
  );
};

export default Title;
