import React from "react";
import { makeStyles, useTheme } from "mui-styles";
import BarLoader from "react-spinners/BarLoader";
import { H3 } from "components/utils/headerV2.component";

/**
 * Use with usePortalEntry hook.
 */

const useStyles = makeStyles((theme) => ({
  messageWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: `calc(${200 + theme.layout.height.header}px)`,
    color: theme.palette.primary.main,
  },
  header: {
    paddingBottom: 20
  }
}));

const EnteringPortal = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { activeProgram } = props;
  if (!activeProgram) {
    return null;
  }
  return (
    <div className={classes.root}>
      <div
        className={classes.messageWrapper}
        data-cy={`portal-entry-${activeProgram?.name}`}
      >
        <H3 color="primary" className={classes.header}>
          Entering{` ${activeProgram.label}`} Portal
        </H3>
        <BarLoader height={6} color={theme.palette.secondary.light} />
      </div>
    </div>
  );
};

export default EnteringPortal;
