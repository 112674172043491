import LabelValuePair from "components/utils/labelValuePair.component";
import { useContext } from "react";
import moment from "moment";
import { DATE_FORMAT_DISPLAY_NUMERIC_SHORT } from "constants/date.constants";
import theme from "theme/theme";
import { GridItem } from "components/utils/grid/gridItem.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { makeStyles } from "mui-styles";
import CustomLink from "components/utils/link.component";
import DataTypeContext from "contexts/dataType.context";

const useStyles = makeStyles(() => ({
  customerInfoCard: {
    padding: "2em 2em",
    borderRadius: "5px",
    boxShadow: theme.shadow.inset2,
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "90%"
    },
    [theme.breakpoints.between(1500, 1800)]: {
      width: "80%"
    },
    [theme.breakpoints.between(1800, 1920)]: {
      width: "70%"
    },
    [theme.breakpoints.up("xl")]: {
      width: "60%"
    },
  }
}))
const CustomerInformationCard = (props) => {
  const {
    customer,
    customerSubmittedClaims,
    setActiveClaimId
  } = props

  const classes = useStyles();

  const { customerInfoMap } = useContext(DataTypeContext);

  if (!customer) {
    return null;
  }
  return (
    <div className={classes.customerInfoCard}>
      <GridContainer spacing={2} hasVerticalSpacing>
        <GridItem sm={12} md={6} lg={5}>
          {customerInfoMap.map((mapItem) => (
            <LabelValuePair
              label={mapItem.label}
              value={
                customer._derivedData.CustomerInfo[mapItem.name]
              }
              key={mapItem.customerInfoMapId}
            />
          ))}
        </GridItem>
        <GridItem sm={12} md={6} lg={4}>
          <LabelValuePair
            label="Submitted Claims"
            value={(
              <ul>
                {customerSubmittedClaims?.map((claim) => {
                  const formattedSubmittedDate = moment(claim.submittedDate)
                    .format(DATE_FORMAT_DISPLAY_NUMERIC_SHORT)
                  const customerClaimData = claim._derivedData.ClaimData
                  return (
                    <li key={claim.claimId}>
                      <CustomLink
                        onClick={() => setActiveClaimId(claim.claimId)}
                      >
                        {`#${customerClaimData.gsLoanNumber} -
                        ${formattedSubmittedDate}`}
                      </CustomLink>
                    </li>
                  )
                })}
              </ul>
            )}
            stacked
          />
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default CustomerInformationCard;