import AjaxService from "services/base/Ajax.service";


class SummaryCountServiceBase extends AjaxService {

  get shouldExtractResponseData() {
    return true
  }

  async getAll(abortController) {
    return this.getCancellable(
      `/summaryCount/`,
      abortController,
      { preventDuplicateRequests: true }
    );
  }

}

const SummaryCountService = new SummaryCountServiceBase();
export default SummaryCountService;
