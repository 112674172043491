export const CLAIM_VALUE_TYPE_NAMES = {
  CLAIMANT_PHONE: "claimantPhone",
  CIF_ID: "cifId",
  CLAIMANT_DOB: "claimantDOB",
  CLAIMANT_EMAIL: "claimantEmail",
  MAILED_DOCS: "mailedDocs",
  CLAIMANT_ADDRESS: "claimantAddress",
  CLAIMANT_SSN: "claimantSSN",
  ACTUAL_COST_DESC: "actualCostDesc",
  REQUESTED_DOLLAR_AMOUNT: "requestedDollarAmt",
  GS_LOAN_NUMBER: "gsLoanNumber",
  REQUEST_TYPE: "requestType",
  NARRATIVE: "narrative",
  CLAIMANT_NAME: "claimantName"
}
export const DATA_TYPE_TEXT = "text";
export const DATA_TYPE_NUMBER = "number";
export const DATA_TYPE_DATE = "date";
export const DATA_TYPE_CASH = "cash";
