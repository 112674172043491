import moment from "moment";

const statusComparisonOrder = ["Returned", "Cancelled", "Pending", "Paid"];

export const comparePayments = (p1, p2) => {
  if (!!p1.claimId !== !!p2.claimId) {
    return p1.claimId ? 1 : -1;
  }
  const statusComparison = (
    statusComparisonOrder.indexOf(p1.status) -
    statusComparisonOrder.indexOf(p2.status)
  );
  if (statusComparison === 0) {
    const p1DateValue = moment(p1.issueDate);
    const p2DateValue = moment(p2.issueDate);
    return p1DateValue - p2DateValue;
  }
  return statusComparison;
}
