import StorageService from "services/Storage.service";
import { decodeJwt } from "jose";


export const currentUserInfo = (preventRedirect = false) => {
  try {
    const parsedToken = StorageService.getSessionItem("accessToken");
    if (parsedToken) {
      const decodedToken = decodeJwt(parsedToken);
      if (decodedToken) {
        return decodedToken
      } else {
        return parsedToken;
      }
    } else if (preventRedirect) {
      return null;
    }
    window.location = "/logout";
  } catch (error) {
    return handleTokenError(error, preventRedirect);
  }
};

export const setUserInfo = (user) => {
  return StorageService.setSessionItem("accessToken", user);
}

export const currentUserToken = () => {
  return StorageService.getSessionItem("accessToken");
}

export const setRefreshToken = (token) => {
  return StorageService.setSessionItem("refreshToken", token);
}

export const currentRefreshToken = () => {
  return StorageService.getSessionItem("refreshToken");
}

export const getCurrentUserId = () => {
  return decodeJwt(StorageService.getSessionItem("accessToken")).id;
};

export const isActiveUser = (user) => user.status === "Active"

function handleTokenError(error, preventRedirect) {
  if (preventRedirect) {
    throw error;
  } else {
    window.location = "/logout";
    console.error(error);
  }
}
