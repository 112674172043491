import { makeStyles } from "mui-styles";

// component imports
import ProgramComponentPage from "components/layout/dynamic/programComponentPage.component";
import ComponentObjectPage from "components/layout/dynamic/componentObjectPage.component";
import EnteringPortal from "components/utils/enteringPortal.component";
//services
import usePortalEntry from "hooks/usePortalEntry";

import InnerProgramContextProviders from "./contextProviders/innerProgramContextProviders.component";
import { isAdmin } from "utils/roles.utils";
import { useCallback, useContext, useEffect, useMemo } from "react";
import ProgramsContext from "contexts/programs.context";
import ContactAdmin from "components/pages/auth/contactAdmin.component";
import ConsentOrderContext from "contexts/consentOrder.context";
import { generatePath, useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { PROGRAM_TYPE_CONSENT_ORDERS } from "components/constants/program.constants";
import { PATH_PROGRAM } from "components/layout/constants/standardPaths.constants";
import useActiveConsentOrder from "hooks/useActiveConsentOrder";
import { SEARCH_PARAM_KEY_CONSENT_ORDER } from "components/layout/context/contextProviders/constants/consentOrderContext.constants";
import CustomContainer from "components/utils/container.component";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));


const ProgramPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const { programId, componentObjectId } = params;
  const [activeConsentOrder, isFetchingConsentOrder] = useActiveConsentOrder();
  const [enteringProgram, activeProgram] = usePortalEntry();
  const {
    state: consentOrderState,
    removeActiveConsentOrderId,
  } = useContext(ConsentOrderContext);
  const { state: programState } = useContext(ProgramsContext);

  const isAdminRole = useMemo(isAdmin, [])

  const goToConsentOrderSelection = useCallback(() => {
    const nonConsentOrderProgram = Object.values(programState.programs)
      .find(program => program.type !== PROGRAM_TYPE_CONSENT_ORDERS);
    if (nonConsentOrderProgram) {
      history.push(generatePath(PATH_PROGRAM, {
        programId: nonConsentOrderProgram.name
      }));
    } else {
      console.error(
        "No remediation selection Programs found!",
        programState.programs
      );
    }
  }, [history, programState.programs])

  useEffect(function redirectConsentOrderRequired() {
    if (!programId || !programState.programs || isFetchingConsentOrder) {
      return;
    }
    const currentProgram = programState.programs?.[programId];
    if (currentProgram?.type === PROGRAM_TYPE_CONSENT_ORDERS) {
      const { activeConsentOrderId } = consentOrderState;
      if (!activeConsentOrder) {
        const { search } = history.location;
        if (search) {
          const searchParams = new URLSearchParams(search);
          const consentOrderSearchParam = searchParams.get(
            SEARCH_PARAM_KEY_CONSENT_ORDER
          );
          const isStorageSettingPending = !!consentOrderSearchParam;
          if (isStorageSettingPending) {
            return;
          }
        }
      }
      if (activeConsentOrder === null) {
        goToConsentOrderSelection();
      } else if (
        activeConsentOrderId &&
        !activeConsentOrder
      ) {
        console.error(
          `Invalid remediation, none found for id #${activeConsentOrderId}`
        );
        removeActiveConsentOrderId();
        goToConsentOrderSelection();
      }
    }
  }, [
    activeConsentOrder, consentOrderState, goToConsentOrderSelection,
    history, isFetchingConsentOrder, programId, programState.programs,
    removeActiveConsentOrderId
  ]);

  if (
    programState.programs &&
    !Object.keys(programState.programs).length &&
    !isAdminRole
  ) {
    return (
      <ContactAdmin errorMessage="No Programs Available" />
    )
  }
  return (
    <InnerProgramContextProviders>
      {enteringProgram ? (
        <EnteringPortal activeProgram={activeProgram} />
      ) : (
        <div className={classes.root}>
          <CustomContainer disableGutters>
            {componentObjectId ? (
              <ComponentObjectPage />
            ) : (
              <ProgramComponentPage />
            )}
          </CustomContainer>
        </div>
      )}
    </InnerProgramContextProviders>
  );
};

export default ProgramPage;
