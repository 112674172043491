import { ReactComponent as ArrowheadIcon } from "../../../assets/images/flowchart/arrowhead.svg"
import classNames from "classnames";
import { makeStyles, useTheme } from "mui-styles";
import { Fragment, useLayoutEffect, useMemo, useRef } from "react";
import { useMediaQuery } from "@mui/material";
import { GridItem } from "components/utils/grid/gridItem.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";

const BORDER_WIDTH_ARROW = 2;
const LINE_BORDER_RADIUS = 30;
const PADDING_MULTI_ROW_GUTTER = 35;
const WIDTH_ARROWHEAD = 12;
const SPACING_UNITS_ROW = 4;
const BREAKPOINT_LG = "lg"
const BREAKPOINT_MD = "md"
const BREAKPOINT_SM = "sm"
const BREAKPOINT_XL = "xl"
const BREAKPOINT_XS = "xs"
const BREAKPOINT_XXL = "xxl"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `15px 0 0`,
    padding: "0px 36px 24px",
    overflow: "auto"
  },
  gridAutoWidth: {
    flexWrap: "nowrap",
    justifyContent: "start",
    paddingRight: 0,
    paddingLeft: 0,
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  gridItemAutoWidth: {
    flex: "0 1 auto"
  },
  itemChildFirst: {
    marginLeft: "auto"
  },
  itemChildLast: {
    marginRight: "36px"
  },
  itemChild: {
    height: "100%",
    flex: 1
  },
  arrowNextRowLine: {
    display: "grid",
    gridTemplateColumns: "minmax(30px, 1fr) max-content minmax(30px, 1fr)"
  },
  arrowRowEndLine: {
    borderTop: `${BORDER_WIDTH_ARROW}px solid ${theme.palette.grey.medium}`,
    position: "absolute",
    bottom: 0,
    left: 35,
    right: 35,
  },
  arrowRowEndTop: {
    position: "absolute",
    top: "50%",
    right: 0,
    bottom: 0,
    width: "calc(100% - 10px)",
    border: `${BORDER_WIDTH_ARROW}px solid ${theme.palette.grey.medium}`,
    borderLeft: 0,
    borderBottom: 0,
    borderTopRightRadius: LINE_BORDER_RADIUS
  },
  arrowRowEndMiddle: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 10,
    borderRight: `${BORDER_WIDTH_ARROW}px solid ${theme.palette.grey.medium}`,
  },
  arrowRowEndBottom: {
    position: "absolute",
    top: -10,
    right: 0,
    bottom: 0,
    width: PADDING_MULTI_ROW_GUTTER,
    border: `${BORDER_WIDTH_ARROW}px solid ${theme.palette.grey.medium}`,
    borderTop: 0,
    borderLeft: 0,
    borderBottomRightRadius: LINE_BORDER_RADIUS,
  },
  arrowNextRowStart: {
    position: "absolute",
    top: "calc(100% - 2px)",
    height: theme.spacing(SPACING_UNITS_ROW),
    width: 30,
    border: `${BORDER_WIDTH_ARROW}px solid ${theme.palette.grey.medium}`,
    borderRight: 0,
    borderBottom: 0,
    borderTopLeftRadius: LINE_BORDER_RADIUS,
  },
  arrowNextRowBottom: {
    position: "absolute",
    top: theme.spacing(2),
    right: 15,
    left: 0,
    maxWidth: 40,
    bottom: "50%",
    border: `${BORDER_WIDTH_ARROW}px solid ${theme.palette.grey.medium}`,
    borderTop: 0,
    borderRight: 0,
    borderBottomLeftRadius: LINE_BORDER_RADIUS,
  },
  extendableArrow: {
    flex: 1,
    width: "auto",
    minWidth: 20,
    height: 12,
    position: "relative",
    display: "flex",
    alignItems: "center",
    marginRight: 5,
    marginLeft: 8,
  },
  arrowHead: {
    width: 70,
    height: 12,
    position: "absolute",
  },
  arrowHeadCurrentRow: {
    left: "calc(100% - 12px)",
  },
  arrowHeadNextRow: {
    bottom: -7,
    left: "calc(100% - 8px)",
  },
  arrowTail: {
    flex: 1,
    marginTop: BORDER_WIDTH_ARROW / -2,
    marginRight: WIDTH_ARROWHEAD / 2,
    borderTop: `${BORDER_WIDTH_ARROW}px solid ${theme.palette.grey.medium}`
  },
}));
const calculateSizeForColumns = columnCount => (
  12 / parseInt(columnCount, 10)
);
const LinearFlowchart = (props) => {
  const {
    columns, // One number or an object of breakpoints mapped to numbers
    disconnectedItems,
    items,
    ItemComponent,
  } = props;

  const scrollContainerRef = useRef();
  const latestNodeRef = useRef();
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.up(BREAKPOINT_XS));
  const isSm = useMediaQuery(theme.breakpoints.up(BREAKPOINT_SM));
  const isMd = useMediaQuery(theme.breakpoints.up(BREAKPOINT_MD));
  const isLg = useMediaQuery(theme.breakpoints.up(BREAKPOINT_LG));
  const isXl = useMediaQuery(theme.breakpoints.up(BREAKPOINT_XL));
  const isXxl = useMediaQuery(theme.breakpoints.up(BREAKPOINT_XXL));
  const columnsByBreakpoint = useMemo(() => {
    if (!columns) {
      return null;
    } else if (["string", "number"].includes(typeof columns)) {
      return { xs: Math.min(columns, items.length) };
    }
    const itemCount = (items?.length || 0) + (disconnectedItems?.length || 0);
    return Object.fromEntries(
      Object.entries(columns).map(([breakpoint, value]) => (
        [breakpoint, Math.max(Math.min(value, itemCount), 1)]
      ))
    );
  }, [columns, disconnectedItems?.length, items.length]);
  const gridItemSizeProps = useMemo(() => {
    if (!columnsByBreakpoint) {
      return { xs: "auto" };
    }
    return Object.fromEntries(
      Object.entries(columnsByBreakpoint).map(([breakpoint, columnCount]) => (
        [breakpoint, calculateSizeForColumns(columnCount)]
      ))
    );
  }, [columnsByBreakpoint]);
  const currentColumns = useMemo(() => {
    if (!columnsByBreakpoint) {
      return null;
    }
    return (
      (isXxl && columnsByBreakpoint[BREAKPOINT_XXL]) ||
      (isXl && columnsByBreakpoint[BREAKPOINT_XL]) ||
      (isLg && columnsByBreakpoint[BREAKPOINT_LG]) ||
      (isMd && columnsByBreakpoint[BREAKPOINT_MD]) ||
      (isSm && columnsByBreakpoint[BREAKPOINT_SM]) ||
      (isXs && columnsByBreakpoint[BREAKPOINT_XS])
    ) || null
  }, [columnsByBreakpoint, isXxl, isXl, isLg, isMd, isSm, isXs]);
  const rowCount = useMemo(() => (
    currentColumns ? Math.ceil(items.length / currentColumns) : 1
  ), [items, currentColumns]);
  const itemsRows = useMemo(() => {
    if (rowCount === 1) {
      return [items];
    }
    const rows = [];
    const remainingItems = [...items];
    for (let i = 0; i < rowCount; i++) {
      rows.push(remainingItems.splice(0, currentColumns));
    }
    return rows;
  }, [items, currentColumns, rowCount]);
  const isDisconnectedItemsNewLine = useMemo(() => {
    if (!disconnectedItems?.length) {
      return false;
    }
    const lastRowRemainderCount = (rowCount * currentColumns) - items.length;
    return lastRowRemainderCount < disconnectedItems.length;
  }, [currentColumns, disconnectedItems, items, rowCount]);
  useLayoutEffect(function scrollToLatestNode() {
    if (scrollContainerRef?.current && latestNodeRef?.current) {
      const containerElement = scrollContainerRef?.current;
      const containerBox = containerElement.getBoundingClientRect();
      const targetBoundingBox = latestNodeRef?.current.getBoundingClientRect();
      const containerRadius = containerBox.width / 2;
      const targetRadius = targetBoundingBox.width / 2;
      const containerLeftOrigin = (
        containerElement.scrollLeft - containerBox.left
      );
      const targetOffsetLeft = targetBoundingBox.left + containerLeftOrigin;
      const scrollToX = targetOffsetLeft - containerRadius + targetRadius;
      containerElement.scrollTo({ left: scrollToX });
    }
  }, [scrollContainerRef, latestNodeRef, theme]);

  return (
    <div
      className={classes.root}
      ref={scrollContainerRef}
    >
      <GridContainer
        className={
          classNames(!currentColumns && classes.gridAutoWidth)
        }
        alignItems="stretch"
        justifyContent="stretch"
        columnSpacing={1}
        rowSpacing={SPACING_UNITS_ROW * (currentColumns ? 1 : 1.5)}
      >
        {itemsRows.map((rowItems, rowIndex, all) => {
          const isLastRow = rowIndex === (all.length - 1);
          return (
            <Fragment key={rowIndex}>
              {rowItems.map((itemData, index) => {
                const isAfterNewRow = index === 0 && rowIndex !== 0;
                const isLastItem = (
                  (rowIndex + 1) === rowCount && (index + 1) === rowItems.length
                );
                const isBeforeNewRow = (
                  !isLastItem && index === (rowItems.length - 1)
                );
                return (
                  <GridItem
                    className={
                      classNames(
                        classes.gridItem,
                        !currentColumns && classes.gridItemAutoWidth,
                      )
                    }
                    key={index}
                    {...gridItemSizeProps}
                  >
                    <div
                      className={
                        classNames(
                          classes.itemChild,
                          isAfterNewRow && classes.itemAfterNextRow,
                          isBeforeNewRow && classes.itemBeforeNextRow,
                          rowIndex === 0 && index === 0 && classes.itemChildFirst,
                          isLastItem && classes.itemChildLast,
                        )
                      }
                      ref={itemData.isCurrentNode ? latestNodeRef : undefined}
                    >
                      <ItemComponent
                        allRowItems={rowItems}
                        connectors={{
                          left: isAfterNewRow ? (
                            <div className={classes.arrowNextRowBottom}>
                              <ArrowheadIcon
                                className={
                                  classNames(classes.arrowHead, classes.arrowHeadNextRow)
                                }
                              />
                            </div>
                          ) : (index === 0 && !!columns) && (
                            <div />
                          ),
                          right: !isLastItem && (
                            isBeforeNewRow ? (
                              <div className={classes.arrowRowEndTop} />
                            ) : (
                              <div className={classes.extendableArrow}>
                                <div className={classes.arrowTail} />
                                <ArrowheadIcon
                                  className={
                                    classNames(
                                      classes.arrowHead,
                                      classes.arrowHeadCurrentRow
                                    )
                                  }
                                />
                              </div>
                            )
                          ),
                          bottomRight: isBeforeNewRow && (
                            <div className={classes.arrowRowEndMiddle} />
                          )
                        }}
                        item={itemData}
                        tooltipPlacement={rowIndex === 0 ? "top" : "bottom"}
                      />
                    </div>
                  </GridItem>
                );
              })}
              {!isLastRow && (
                <GridItem xs={12} marginRight="auto" position="relative">
                  <div className={classes.arrowNextRowLine}>
                    <div className={classes.arrowNextRowStart} />
                    <div className={classes.arrowRowEndLine} />
                    <div className={classes.arrowRowEndBottom} />
                  </div>
                </GridItem>
              )}
            </Fragment>
          );
        })}
        {!!isDisconnectedItemsNewLine && (
          <GridItem xs={12}>
            <div />
          </GridItem>
        )}
        {disconnectedItems?.map?.((itemData, index, all) => (
          <GridItem
            marginLeft="auto"
            className={
              classNames(
                classes.gridItem,
                !currentColumns && classes.gridItemAutoWidth,
              )
            }
            key={index}
            {...gridItemSizeProps}
          >
            <ItemComponent
              allRowItems={[
                ...all,
                ...(isDisconnectedItemsNewLine ? [] : itemsRows[rowCount - 1])
              ]}
              connectors={{
                left: (
                  index === 0 &&
                  !columns ?
                    true :
                    !isDisconnectedItemsNewLine &&
                    ((rowCount * currentColumns) - 1) === items.length
                ),
                right: rowCount > 1 && !isDisconnectedItemsNewLine
              }}
              item={itemData}
              tooltipPlacement="bottom"
              disconnected
            />
          </GridItem>
        ))}
      </GridContainer>
    </div>
  );
};

export default LinearFlowchart;