import AxiosService from "./base/Axios.service";
import authHeader from "services/util/authHeader.util";

class WorkpackageServiceBase {
  getAll() {
    return AxiosService
      .get(`/workpackage`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getWorkpackageById(workpackageId) {
    return AxiosService
      .get(`/workpackage/${workpackageId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getWorkpackageByProgramId(programId) {
    return AxiosService
      .get(`/workpackage/programWorkpackages/${programId}`, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  getWorkpackageAndTaskByProgramId(programId) {
    return AxiosService
      .get(
        `/workpackage/programWorkpackages/tasks/${programId}`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      });
  }

  getWorkpackageAndTasksById(workpackageId) {
    return AxiosService
      .get(
        `/workpackage/${workpackageId}/tasks`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      });
  }

  // save new Workpackage
  saveNewWorkpackage(workpackage) {
    return AxiosService
      .post(
        `/workpackage/saveNewWorkpackage/`,
        { workpackage },
        { headers: authHeader() }
      )
      .then((response) => {
        return response.data;
      });
  }

  // update a Workpackages
  updateWorkpackages(workpackages) {
    return AxiosService
      .put(
        `/workpackage/workpackages/`,
        { workpackages },
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  updateWorkpackage(workpackage, workpackageId) {
    return AxiosService
      .put(
        `/workpackage/${workpackageId}/update`,
        { workpackage },
        { headers: authHeader() }
      )
      .then(response => response.data);
  }

  // Is Workpackage deleteable
  isWorkpackageDeleteAble(workpackageId) {
    return AxiosService
      .get(
        `/workpackage/isDeleteAble/${workpackageId}`,
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      });
  }

  // delete Workpackage
  deleteWorkpackage(workpackageId) {
    return AxiosService
      .post(
        `/workpackage/delete/${workpackageId}`,
        { workpackageId },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      });
  }
}

const WorkpackageService = new WorkpackageServiceBase();
export default WorkpackageService;
