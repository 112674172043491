import React, { useState, useContext, useMemo, useCallback, useEffect } from "react";
import { makeStyles } from "mui-styles";
import { useParams } from "react-router-dom";
import { MenuItem, Grid } from "@mui/material";
import CustomSelect from "components/utils/form-elements/select.component";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import ProgramService from "services/Program.service";
import FormBanner from "components/utils/form-elements/formBanner.component";
import Checkbox from "components/utils/form-elements/checkboxDeprecated.component";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { COMPONENT_OBJECT_TYPE_BUILDER } from "components/constants/program.constants";
import classNames from "classnames";
import ProgramsContext from "contexts/programs.context";
import { ACTION_PARTIAL_REPLACE_COMPONENT_OBJECTS, ACTION_REPLACE_COMPONENT_OBJECT_FAMILY } from "reducers/global/program.reducer";
import { findProgramBuilderComponent, matchBuilderTypeRefParts } from "components/builder/utils/builderProgram.utils";
import OrganizationContext from "contexts/organization.context";
import Loader from "components/utils/loader.components";
import { GridContainer } from "components/utils/grid/gridContainer.component";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: 500,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: 20,
  },
  contentContainer: {
    padding: "30px 20px 0px 20px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  selectsContainer: {
    paddingTop: 10,
    alignSelf: "center",
    width: 300,
    overflowX: "hidden",
  },
  loadingContainer: {
    height: 42
  },
  programsLoading: {
    display: "contents"
  },
  checkContainer: {
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
  },
  checkWrapper: {
    width: "fit-content",
    margin: "0px auto",
    paddingBottom: 20,
  },
  select: {
    marginBottom: 25,
    width: "100%",
  },
  continueIcon: {
    fill: "white",
    fontSize: theme.typography.h4.fontSize,
    marginRight: 8,
  }
}));


const OrchestrationBuilderForm = (props) => {
  const classes = useStyles();
  const { setChosenProgram } = props;
  const { state, dispatch } = useContext(ProgramsContext);
  const [mode, setMode] = useState("enterprise"); //"programLevel"
  const [errorMessage, setErrorMessage] = useState();
  const [isApplicableLoaded, setIsApplicableLoaded] = useState(false);
  const { componentObjectId } = useParams();
  const { state: organizationState } = useContext(OrganizationContext);

  const displayName = useMemo(() => (
    props.name?.replace?.(/s$/, "")
  ), [props.name]);

  const chosenProgramName = useMemo(() => {
    setErrorMessage();
    return state.programs[props.chosenProgram.id]?.Name
  }, [state.programs, props.chosenProgram.id])

  const displayNameAndArticle = useMemo(() => (
    displayName.match(/^[aeiou]/i) ? `an ${displayName}` : `a ${displayName}`
  ), [displayName]);

  const charterBuilderProgramSet = useMemo(() => (
    new Set(
      props.name !== "Charter" ?
        [] :
        props.builderInfo.map(builder => builder.programId)
    )
  ), [props.builderInfo, props.name]);

  const typeRef = useMemo(() => {
    if (props.name === "Assessments") {
      return "Assessment";
    } else if (["Plan", "Charter"].includes(props.name)) {
      return props.name;
    } else {
      return null;
    }
  }, [props.name]);

  const eligiblePrograms = useMemo(() => {
    const componentIds = new Set(
      Object.values(state.componentObjects).filter(componentObject => (
        componentObject.type === COMPONENT_OBJECT_TYPE_BUILDER &&
        matchBuilderTypeRefParts(componentObject.typeRef, typeRef)
      )).map(componentObject => (
        componentObject.programComponentId
      ))
    );
    const programIds = new Set(
      Object.values(state.programComponents)
        .filter(component => componentIds.has(component.programComponentId))
        .map(component => component.programId)
    );
    return Object.values(state.programs).filter(program => (
      program.status === "Active" &&
      programIds.has(program.programId) &&
      !charterBuilderProgramSet.has(program.programId)
    ));
  }, [state, charterBuilderProgramSet, typeRef]);

  const handleCheck = useCallback((event) => {
    setErrorMessage();
    if (event.target.checked === true) {
      setMode(event.target.name);
    } else {
      setMode("none");
    }
    setChosenProgram({ id: "", name: "" });
  }, [setChosenProgram]);

  const handleChange = (event) => {
    setChosenProgram({ id: event.target.value, name: event.currentTarget.id });
  };

  // need to call addNewSection function from Builder
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (mode === "enterprise") {
      props.setMode("loadTemplate", null);
      props.setEditModal(false);
      props.setChosenProgram({
        id: 8,
        name: "Enterprise Level",
        compObjId: componentObjectId,
      });
    } else {
      if (!typeRef) {
        throw new Error(`Invalid builder name prop: ${props.name}`);
      }
      let componentObject = findProgramBuilderComponent(
        props.chosenProgram.id,
        typeRef,
        state.programComponentsByProgramId,
        state.componentObjectsByComponentId,
      );
      if (!componentObject) {
        if (!isApplicableLoaded) {
          componentObject = (
            await ProgramService.getComponentObjectByProgramAndTypeValues(
              COMPONENT_OBJECT_TYPE_BUILDER,
              typeRef,
              organizationState.activeOrganizationId
            )
          )?.payload;
        }
        if (!componentObject) {
          setErrorMessage(`${chosenProgramName} does not contain a ${displayName} Builder`)
          return;
        }
        dispatch({
          type: ACTION_PARTIAL_REPLACE_COMPONENT_OBJECTS,
          payload: componentObject,
        });
      }
      props.setChosenProgram({
        ...props.chosenProgram,
        compObjId: componentObject.componentObjectId,
      });
      props.setMode("loadTemplate", null);
      props.setEditModal(false);
    }
  };

  const clearForm = useCallback(() => {
    setChosenProgram({ id: "", name: "" });
    setMode("none");
  }, [setChosenProgram]);

  useEffect(() => {
    (async function lazyLoadApplicableComponentObjects() {
      const response = (
        await ProgramService.getComponentObjectTypeRefFamily(
          COMPONENT_OBJECT_TYPE_BUILDER,
          typeRef,
          organizationState.activeOrganizationId
        )
      );

      dispatch({
        type: ACTION_REPLACE_COMPONENT_OBJECT_FAMILY,
        payload: response.payload,
      });
      setIsApplicableLoaded(true);
    })()
  }, [dispatch, organizationState.activeOrganizationId, typeRef]);

  return (
    <Grid className={classes.formContainer} data-cy="builder-form">
      <FormBanner
        closeErrorMessage={() => setErrorMessage()}
        errorMessage={errorMessage}
      >
        Create {displayName}
      </FormBanner>
      <div className={classes.contentContainer}>
        <div className={classes.checkWrapper}>
          <div className={classes.checkContainer}>
            <Checkbox
              checked={mode === "enterprise"}
              onChange={(event) => handleCheck(event)}
              name="enterprise"
              label={`Create ${displayNameAndArticle} at the enterprise level`}
              disabled={!isApplicableLoaded}
            />
            {!isApplicableLoaded ? (
              <GridContainer
                direction="column"
                className={classes.loadingContainer}
              >
                <Loader
                  size={24}
                  height={42}
                  className={classes.programsLoading}
                />
              </GridContainer>
            ) : (
              eligiblePrograms.length > 0 && (
                <Checkbox
                  checked={mode === "programLevel"}
                  onChange={(event) => handleCheck(event)}
                  name="programLevel"
                  label={`Create ${displayNameAndArticle} at the program level`}
                  test="builder-programLevel"
                />
              )
            )}
          </div>
        </div>

        {mode === "programLevel" && (
          <div className={classes.selectsContainer}>
            <CustomSelect
              className={classes.select}
              label="Select Program"
              value={props.chosenProgram.id}
              onChange={handleChange}
              name="program"
              test="builder-program"
            >
              {eligiblePrograms.map((item) => (
                <MenuItem
                  value={item.programId}
                  key={item.programId}
                  data-cy={`menu-item-program-${item.name}`}
                >
                  {item.label}
                </MenuItem>
              ))}
            </CustomSelect>
          </div>
        )}
      </div>

      <DualFormButtons
        variant="addFramework"
        startIcon={(
          <CheckOutlinedIcon
            className={classNames(classes.continueIcon, classes.icon)}
          />
        )}
        addText="Continue"
        cancelOnClick={() => {
          props.setEditModal(false);
          clearForm();
        }}
        saveOnClick={handleSubmit}
        disabled={
          (mode === "programLevel" && props.chosenProgram.id === "") ||
          mode === "none" ||
          !isApplicableLoaded ||
          !!errorMessage
        }
      />
    </Grid>
  );
};

export default OrchestrationBuilderForm;
