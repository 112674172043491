import React from "react";

/*
 * Values:
 * {
 *   dispatch: asyncDispatch,
 *   state: OrganizationReducer
 * }
 */
const OrganizationContext = React.createContext();

export default OrganizationContext;
