import { makeStyles } from "mui-styles";
import LabelValuePair from "components/utils/labelValuePair.component";
import { useCallback } from "react";
import FilterRadioCard from "components/pages/consentOrder/claims/shared/FilterRadioCard.component";
import { formatDisplayNumber } from "utils/localization.utils";

const useStyles = makeStyles(theme => ({
  description: {
    marginTop: 0,
    fontWeight: theme.typography.body2.fontWeight,
    textAlign: "left"
  },
}));


export default function ReviewRadioCard(props) {
  const classes = useStyles();
  const {
    activeFilter, children, claims, label, setActiveFilter, value
  } = props;

  const isDisabled = !claims?.length;

  const handleChange = useCallback(event => {
    setActiveFilter(event.currentTarget.value)
  }, [setActiveFilter]);

  return (
    <FilterRadioCard
      checked={activeFilter === value}
      disabled={isDisabled}
      label={label}
      name={value}
      onChange={handleChange}
      sublabel={(
        <>
          <LabelValuePair
            label="Results"
            value={formatDisplayNumber(claims?.length || "0")}
          />
          <div className={classes.description}>
            {children}
          </div>
        </>
      )}
      value={value}
    />
  );
}
