import React, { useCallback } from "react";
import Link from "components/utils/link.component";
import { truncate } from "components/shared/workpackages/utils/workpackage.utils";
import classNames from "classnames";
import { makeStyles } from "mui-styles";
import { Paper, Divider } from "@mui/material";
import { H4 } from "components/utils/headerV2.component";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const highlightBoxShadow = `1px 3px 4px -2px rgb(0 0 0 / 20%),
5px 4px 3px -4px rgb(0 122 204 / 20%),
2px 3px 2px 1px rgb(64 145 206 / 20%),
2px 1px 10px 4px rgb(120 197 253 / 30%)`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 20,
    color: theme.palette.text.secondary,
    height: "100%",
  },
  paperLink: {
    cursor: "pointer",
    "&:hover": {
      boxShadow: highlightBoxShadow,
    },
  },
  linkWrapper: {
    border: "1px solid transparent",
    "&:hover": {
      "& $title": {
        color: theme.palette.primary.main,
      },
    },
  },
  title: {
    padding: 0,
    paddingBottom: 3,
    margin: 0,
  },
  titleLink: {
    textDecoration: "underline",
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between"
  },
  content: {
    display: "flex",
    padding: "8px 16px",
    flexFlow: "column",
  },
}));

export default function ComsCard(props) {
  const classes = useStyles();
  const { title, subtitle, linkPath, children } = props;
  const history = useHistory();

  const cardClick = useCallback(() => {
    if (!linkPath) {
      return null;
    }
    return history.push(linkPath);
  }, [linkPath, history]);

  return (
    <Paper
      className={classNames(classes.paper, !linkPath ? "" : classes.paperLink)}
      onClick={cardClick}
      data-cy={title ? `coms-card-${title}` : "coms-card"}
    >
      {!!title && (
        <>
          <CardTitleSection
            title={title}
            subtitle={subtitle}
            linkPath={linkPath}
          />
          <Divider />
        </>
      )}

      <div className={classes.content}>{children}</div>
    </Paper>
  );
}

const CardTitleSection = ({ title, subtitle, linkPath }) => {
  const classes = useStyles();

  if (linkPath) {
    return (
      <div className={classes.linkWrapper} data-cy="card-title-link-wrapper">
        <Link variant="routerLink" to={linkPath} test={`cardlink-${title}`}>
          <div className={classes.headerWrapper}>
            <H4 className={classNames(classes.title, classes.titleLink)}>
              {truncate(title)}
            </H4>
          </div>
        </Link>
        {!!subtitle && (
          <div className={classes.subtitle}>
            {subtitle}
          </div>
        )}
      </div>
    );
  }
  return (
    <div className={classes.headerWrapper} data-cy="card-title-wrapper">
      <H4 className={classNames(classes.title, classes.iconDefaultColor)}>
        {truncate(title)}
      </H4>
      {!!subtitle && (
        <div className={classes.subtitle}>
          {subtitle}
        </div>
      )}
    </div>
  );
};
