import React, { useMemo } from "react";
import { H5, H6 } from "components/utils/headerV2.component";
import LabelValuePair from "components/utils/labelValuePair.component";
import { formatCash } from "utils/localization.utils";
import { DATE_FORMAT_DISPLAY_NUMERIC } from "constants/date.constants";
import moment from "moment";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import { Box, Paper } from "@mui/material";
import { groupObjectArrayByKey } from "utils/arrayOfObjects.utils";


export default function CustomerLoansAccordionSection({ customer }) {

  const distributionsGroupedByLoan = useMemo(() => (
    Object.values(
      groupObjectArrayByKey(
        customer._associations.DistributionDataset,
        "gsLoanNumber"
      )
    )
  ), [customer]);

  return (
    <GridContainer spacing={2} columns={30}>
      {distributionsGroupedByLoan.flatMap(distributionGroup => (
        distributionGroup.map((distribution, distributionIndex) => (
          <GridItem
            xs={30}
            md={15}
            lg={10}
            xl={6}
            marginTop={1}
            key={`${distribution.distributionDatasetId}-${distributionIndex}`}
          >
            <Paper>
              <Box padding={2} width="100%">
                <Box
                  display="flex"
                  alignItems="baseline"
                  justifyContent="space-between"
                  flexWrap="wrap"
                  width="100%"
                >
                  <H5>
                    <span>Loan {distribution.gsLoanNumber}</span>&nbsp;
                  </H5>
                  <H6>
                    <span>Distribution #{distributionIndex + 1}</span>
                  </H6>
                </Box>
                <LabelValuePair
                  label="Origination Balance"
                  value={formatCash(distribution.originationBalance, true)}
                />
                <LabelValuePair
                  label="Origination Date"
                  value={!distribution.originationDate ? "N/A" : (
                    moment(distribution.originationDate)
                      .format(DATE_FORMAT_DISPLAY_NUMERIC)
                  )}
                />
                <LabelValuePair
                  label="Remediation Amount"
                  value={formatCash(distribution.remediationAmount, true)}
                />
                <LabelValuePair
                  label="Remediation Cashed Date"
                  value={!distribution.cashedDate ? "N/A" : (
                    moment(distribution.cashedDate)
                      .format(DATE_FORMAT_DISPLAY_NUMERIC)
                  )}
                />
                <LabelValuePair
                  label="Servicer"
                  value={distribution.servicer}
                />
                <LabelValuePair
                  label="Population"
                  value={distribution.population}
                />
              </Box>
            </Paper>
          </GridItem>
        ))
      ))}
    </GridContainer>
  );
}
