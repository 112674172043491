import { IconButton, Tooltip } from "@mui/material";
import { makeStyles } from "mui-styles";
import { CloudDownloadOutlined } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: "0px 2px",
    marginRight: 10,
  },
  icon: {
    fontSize: theme.typography.h3.fontSize,
    margin: 0,
  },
}));

const DownloadFileIconButton = (props) => {
  const classes = useStyles();
  const { onClick } = props;

  return (
    <Tooltip title="Doawnload File" placement="top" enterDelay={500} enterNextDelay={800}>
      <IconButton className={classes.iconButton} onClick={onClick}>
        <CloudDownloadOutlined className={classes.icon} />
      </IconButton>
    </Tooltip>
  );
};

export default DownloadFileIconButton;
