import React from "react";

/*
 * Values:
 * {
 *   dispatch: asyncDispatch,
 *   state: ConsentOrderReducer,
 *   setActiveConsentOrderId: () => {
 *     // Set active consent order in session storage and reducer.
 *     // Do not use the reducer dispatch directly for this.
 *   },
 *   removeActiveConsentOrderId: () => {
 *     // Reset active consent order from session storage and reducer
 *     // Do not use the reducer dispatch directly for this.
 *   }
 * }
 */
const ConsentOrderContext = React.createContext();

export default ConsentOrderContext;
