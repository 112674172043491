import AjaxService from "services/base/Ajax.service.js";

class PaymentServiceBase extends AjaxService {
  shouldExtractResponseData() {
    return true;
  }

  async downloadReissueDocumentFile(reissueDocumentId) {
    return this.get(
      `/payment/${reissueDocumentId}/file/download`,
      { responseType: "blob", timeout: 30000 }
    )
  }

  async upsertPaymentReissueRequest(paymentId, data) {
    return this.post(
      `/payment/${paymentId}/paymentReissueRequest/upsert`,
      data,
      { cancelUnfinishedRequests: true }
    )
  }

  async updatePaymentPaid(paymentId) {
    return this.put(
      `/payment/${paymentId}/status/paid`,
      {},
      { cancelUnfinishedRequests: true }
    )
  }
}

const PaymentService = new PaymentServiceBase();
export default PaymentService;