import { ReactComponent as ArrowheadIcon } from "../../../assets/images/flowchart/arrowhead.svg"
import classNames from "classnames";
import { makeStyles } from "mui-styles";
import { useMemo } from "react";
import { GridItem } from "components/utils/grid/gridItem.component";

const BORDER_WIDTH_ARROW = 2;
const WIDTH_ARROWHEAD = 12;

const POSITION_LEFT = "left";
const POSITION_CENTER = "center";
const POSITION_RIGHT = "right";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  gridItemAutoWidth: {
    flex: "0 1 auto"
  },
  itemChildFirst: {
    marginLeft: "auto"
  },
  itemChildLast: {
    marginRight: "auto"
  },
  itemChild: {
    height: "100%",
    flex: 1
  },
  arrowRowEndTop: {
    position: "absolute",
    top: "50%",
    right: 0,
    bottom: 0,
    width: "calc(100% - 10px)",
    border: `${BORDER_WIDTH_ARROW}px solid ${theme.palette.grey.medium}`,
    borderLeft: 0,
    borderBottom: 0,
    borderTopRightRadius: 4
  },
  arrowRowEndMiddle: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 10,
    borderRight: `${BORDER_WIDTH_ARROW}px solid ${theme.palette.grey.medium}`,
  },
  arrowNextRowBottom: {
    position: "absolute",
    top: 0,
    right: 15,
    left: 0,
    maxWidth: 40,
    bottom: "50%",
    border: `${BORDER_WIDTH_ARROW}px solid ${theme.palette.grey.medium}`,
    borderTop: 0,
    borderRight: 0,
    borderBottomLeftRadius: 4
  },
  extendableArrow: {
    flex: 1,
    width: "auto",
    minWidth: 20,
    height: 12,
    position: "relative",
    display: "flex",
    alignItems: "center",
    marginRight: 5,
    marginLeft: 8,
  },
  arrowHead: {
    width: 70,
    height: 12,
    position: "absolute",
  },
  arrowHeadCurrentRow: {
    left: "calc(100% - 12px)",
  },
  arrowHeadNextRow: {
    bottom: -7,
    left: "calc(100% - 8px)",
  },
  arrowTail: {
    flex: 1,
    marginTop: BORDER_WIDTH_ARROW / -2,
    marginRight: WIDTH_ARROWHEAD / 2,
    borderTop: `${BORDER_WIDTH_ARROW}px solid ${theme.palette.grey.medium}`
  },
}));


export default function LinearFlowchartMultiLineGridItem(props) {
  const classes = useStyles();
  const {
    allRowItems,
    childItems,
    columnIndex,
    currentColumns,
    gridItemSizeProps,
    height,
    itemData,
    itemCount,
    ItemComponent,
    paperRef,
    rowIndex,
    workflowInstance
  } = props;

  const rowCount = useMemo(() => (
    currentColumns ? Math.ceil(itemCount / currentColumns) : 1
  ), [itemCount, currentColumns]);

  const isRowEnd = (columnIndex + 1) === allRowItems.length;
  const isAfterNewRow = columnIndex === 0 && rowIndex !== 0;
  const isLastItem = (rowIndex + 1) === rowCount && isRowEnd;
  const isBeforeNewRow = !isLastItem && isRowEnd;

  const rowPosition = useMemo(() => {
    if (columnIndex === 0) {
      return POSITION_LEFT;
    }
    if (isRowEnd) {
      return POSITION_RIGHT;
    }
    return POSITION_CENTER;
  }, [columnIndex, isRowEnd]);

  return (
    <GridItem
      className={
        classNames(
          classes.gridItem,
          !currentColumns && classes.gridItemAutoWidth,
        )
      }
      {...gridItemSizeProps}
      data-cy={`diagram-item-${itemData.label}`}
    >
      <div
        className={
          classNames(
            classes.itemChild,
            isAfterNewRow && classes.itemAfterNextRow,
            isBeforeNewRow && classes.itemBeforeNextRow,
            rowIndex === 0 && columnIndex === 0 && classes.itemChildFirst,
            isLastItem && classes.itemChildLast,
          )
        }
      >
        <ItemComponent
          allRowItems={allRowItems}
          childItems={childItems}
          connectors={{
            left: isAfterNewRow ? (
              <div className={classes.arrowNextRowBottom}>
                <ArrowheadIcon
                  className={
                    classNames(
                      classes.arrowHead,
                      classes.arrowHeadNextRow
                    )
                  }
                />
              </div>
            ) : (columnIndex === 0 && !!currentColumns) && (
              <div />
            ),
            right: isLastItem ? (
              !currentColumns ? null : (
                <div />
              )
            ) : (
              isBeforeNewRow ? (
                <div className={classes.arrowRowEndTop} />
              ) : (
                <div className={classes.extendableArrow}>
                  <div className={classes.arrowTail} />
                  <ArrowheadIcon
                    className={
                      classNames(
                        classes.arrowHead,
                        classes.arrowHeadCurrentRow
                      )
                    }
                  />
                </div>
              )
            ),
            bottomRight: isBeforeNewRow && (
              <div className={classes.arrowRowEndMiddle} />
            )
          }}
          height={height}
          item={itemData}
          paperRef={paperRef}
          rowPosition={rowPosition}
          tooltipPlacement={rowIndex === 0 ? "top" : "bottom"}
          workflowInstance={workflowInstance}
        />
      </div>
    </GridItem>
  );
}
