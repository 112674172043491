import { Box } from "@mui/material";
import SearchResultsWrapper from "components/pages/consentOrder/claims/shared/SearchResultsWrapper.component";
import SubpageHeader from "components/pages/consentOrder/claims/shared/SubpageHeader.component";
import WorkflowNodeSearchFilter from "components/pages/consentOrder/claims/shared/WorkflowNodeSearchFilter.component";
import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import DistributionDatasetCard from "./DistributionDatasetCard.component";
import DistributionDatasetService from "services/DistributionDataset.service";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ScrollTargetContainer from "components/shared/scroll/ScrollTargetContainer.component";
import { ID_URL_DISTRIBUTION_HASH_SCROLL_TARGET } from "components/pages/consentOrder/constants/consentOrderPage.constants";
import StatisticsContext from "contexts/statistics.context";

const PAGINATION_LIMIT = 10;
export default function DistributionWorkflows() {
  const location = useLocation();
  const history = useHistory();
  const scrollTargetRef = useRef();
  const distributionRequestAbortRef = useRef();

  const { cancelStatsRequests } = useContext(StatisticsContext);

  const [hash, searchParamString] = useMemo(() => (
    location.search ?
      [location.hash, location.search] :
      location.hash?.slice?.(1)?.split?.("?")
  ), [location.hash, location.search])
  const searchParams = useMemo(() => (
    searchParamString ? new URLSearchParams(searchParamString) : null
  ), [searchParamString])
  const filterNodeId = searchParams?.get?.("filterNodeId")
  const [distributionResults, setDistributionResults] = useState(null);
  const [hasSearchError, setHasSearchError] = useState(false);
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const defaultActiveNodeSet = filterNodeId ? new Set([filterNodeId]) : new Set();
  const [activeNodeIdSet, setActiveNodeIdSet] = useState(defaultActiveNodeSet);
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  useEffect(() => {
    if (filterNodeId) {
      setIsSearchLoading(true);
      const fetchDistributions = async () => {
        distributionRequestAbortRef.current = new AbortController();
        const result = await DistributionDatasetService
          .filterAndSearchDistributionsByNodeIds(
            [filterNodeId],
            searchQuery,
            distributionRequestAbortRef.current,
            PAGINATION_LIMIT,
            paginationIndex
          )
        setDistributionResults(result.payload)
        setTotalResults(result.totalSearchResults)
      }
      fetchDistributions();
      setIsSearchLoading(false);
    }
  }, [filterNodeId, searchQuery, paginationIndex])

  const handleSearchSubmit = useCallback(async () => {
    try {
      setIsSearchLoading(true);
      distributionRequestAbortRef.current = new AbortController();
      const result = await DistributionDatasetService
        .filterAndSearchDistributionsByNodeIds(
          [...activeNodeIdSet],
          searchQuery,
          distributionRequestAbortRef.current,
          PAGINATION_LIMIT,
          paginationIndex
        )
      setTotalResults(result.totalSearchResults)
      if (result.payload) {
        setDistributionResults(result.payload);
        setAccordionExpanded(false);
      }
    } catch (error) {
      console.error(error)
      setHasSearchError(true);
    } finally {
      setIsSearchLoading(false);
    }
  }, [activeNodeIdSet, searchQuery, paginationIndex])

  useEffect(() => (
    function cancelSearchOnUnmount() {
      DistributionDatasetService.cancel(distributionRequestAbortRef.current);
    }
  ), [cancelStatsRequests]);

  useLayoutEffect(function scrollToAnchorAndReset() {
    if (scrollTargetRef.current && hash) {
      if (hash === ID_URL_DISTRIBUTION_HASH_SCROLL_TARGET) {
        scrollTargetRef.current.scrollIntoView(true)
      }
    }
    if (filterNodeId) {
      history.replace(location.pathname)
    }
  }, [hash, history, location, location.history, filterNodeId])

  const handlePageChange = useCallback(async (pageIndex) => {
    try {
      setIsSearchLoading(true);
      setPaginationIndex(pageIndex)
      distributionRequestAbortRef.current = new AbortController();
      const result = await DistributionDatasetService
        .filterAndSearchDistributionsByNodeIds(
          [...activeNodeIdSet],
          searchQuery,
          distributionRequestAbortRef.current,
          PAGINATION_LIMIT,
          pageIndex
        )
      setTotalResults(result.totalSearchResults)
      if (result.payload) {
        setDistributionResults(result.payload);
        setAccordionExpanded(false);
      }
    } catch (error) {
      console.error(error)
      setHasSearchError(true);
    } finally {
      setIsSearchLoading(false);
    }
  }, [activeNodeIdSet, searchQuery])

  const makeTotalSearchResultsPages = useCallback((totalResultsRes) => {
    return Math.ceil(totalResultsRes / PAGINATION_LIMIT);
  }, [])

  const totalPaginatedPages = useMemo(() => (
    makeTotalSearchResultsPages(totalResults)
  ), [totalResults, makeTotalSearchResultsPages])

  return (
    <>
      <SubpageHeader title="Distribution Workflows" />
      <WorkflowNodeSearchFilter
        size="small"
        activeNodeIdSet={activeNodeIdSet}
        setActiveNodeIdSet={setActiveNodeIdSet}
        accordionExpanded={accordionExpanded}
        setAccordionExpanded={setAccordionExpanded}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        searchResults={distributionResults}
        onSubmit={handleSearchSubmit}
        autoFocus
        searchDistributions
      />
      <ScrollTargetContainer
        id={ID_URL_DISTRIBUTION_HASH_SCROLL_TARGET}
        scrollTargetRef={scrollTargetRef}
      >
        <SearchResultsWrapper
          error={hasSearchError}
          loading={isSearchLoading}
          results={distributionResults}
          paginationIndex={paginationIndex}
          totalPageCount={totalPaginatedPages}
          handlePageChange={handlePageChange}
          totalResults={totalResults}
        >
          {distributionResults?.map?.(distribution => (
            <Box marginTop={4} key={distribution.distributionDatasetId}>
              <DistributionDatasetCard
                distributionDataset={distribution}
                setDistributionDatasets={setDistributionResults}
              />
            </Box>
          ))}
        </SearchResultsWrapper>
      </ScrollTargetContainer>

    </>
  )
}