import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import FileTransferCard from "components/pages/consentOrder/fileTransferSummary/listing/card/fileTransferCard.component";
import { Box, Pagination } from "@mui/material";
import { makeStyles } from "mui-styles";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CustomSelect from "components/utils/form-elements/select.component";
import { makeElementRefs } from "utils/refElement.utils";

const useStyles = makeStyles(theme => ({
  cardWrapper: {
    marginBottom: 16
  },
  paginationGrid: {
    marginTop: 16
  },
  paginationSelect: {
    height: 32
  },
  fileTransferRef: {
    scrollMarginTop: "6em",
  }
}));


const ITEMS_PER_PAGE_DEFAULT = 20;

const ITEMS_PER_PAGE_OPTIONS = [
  { label: 20, value: 20 },
  { label: 50, value: 50 },
  { label: 100, value: 100 }
];

const URL_PARAM_PAGE = "page";
const URL_SEARCH_PARAM_FILE_TRANSFER_ID = "fileTransferId";


export default function FileTransferPaginatedList(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const scrollTargetRef = useRef({});

  const searchParamString = useMemo(() => (
    location?.search
  ), [location.search])

  const urlFileTransferId = useMemo(() => (
    searchParamString ?
      new URLSearchParams(searchParamString).get(URL_SEARCH_PARAM_FILE_TRANSFER_ID) :
      undefined
  ), [searchParamString])

  const {
    fileTransfers: unpaginatedFileTransfers,
    setOneFileTransfer,
    setTagModalFileTransferId,
  } = props;

  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE_DEFAULT);

  const isPaginationEnabled = (
    unpaginatedFileTransfers?.length > ITEMS_PER_PAGE_OPTIONS[0].value
  );

  const currentPage = useMemo(() => {
    const query = new URLSearchParams(location.search);
    return parseInt(query.get(URL_PARAM_PAGE) || "1", 10);
  }, [location]);

  const pageCount = useMemo(() => (
    Math.ceil(unpaginatedFileTransfers?.length / itemsPerPage) || 1
  ), [itemsPerPage, unpaginatedFileTransfers?.length]);

  const paginatedFileTransfers = useMemo(() => {
    const start = itemsPerPage * (currentPage - 1);
    const end = start + itemsPerPage;
    return unpaginatedFileTransfers.slice(start, end);
  }, [unpaginatedFileTransfers, currentPage, itemsPerPage]);

  useLayoutEffect(() => {
    if (urlFileTransferId) {
      scrollTargetRef?.current[urlFileTransferId]?.scrollIntoView?.(true)
    }
  }, [scrollTargetRef, urlFileTransferId])

  useEffect(function handleInvalidPage() {
    if (currentPage > 1 && paginatedFileTransfers.length === 0) {
      history.replace(history.location.pathname);
    }
  }, [currentPage, history, paginatedFileTransfers]);

  const handlePageChange = useCallback((_event, page) => {
    history.push(
      page > 1 ?
        `${location.pathname}?${URL_PARAM_PAGE}=${page}` :
        location.pathname
    );
  }, [history, location]);

  return (
    <div>
      {!unpaginatedFileTransfers.length ? (
        <Box width="100%" textAlign="center" marginTop={8}>
          No File Transfers found.
        </Box>
      ) : (
        <GridContainer spacing={4}>
          {paginatedFileTransfers.map(fileTransfer => (
            <GridItem
              xs={12}
              xl={6}
              className={classes.cardWrapper}
              key={fileTransfer.fileTransferId}
            >
              <div
                ref={(el) => (
                  makeElementRefs(
                    el,
                    scrollTargetRef,
                    fileTransfer.fileTransferId
                  )
                )}
                className={classes.fileTransferRef}
              >
                <FileTransferCard
                  fileTransfer={fileTransfer}
                  setFileTransfer={setOneFileTransfer}
                  setTagModalFileTransferId={setTagModalFileTransferId}
                  readOnly={!!fileTransfer._meta?.debug}
                  highlight={fileTransfer.fileTransferId === urlFileTransferId}
                />
              </div>
            </GridItem>
          ))}
        </GridContainer>
      )}
      {!!isPaginationEnabled && (
        <GridContainer className={classes.paginationGrid}>
          <GridItem lg />
          <GridItem xs lg="auto">
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={handlePageChange}
            />
          </GridItem>
          <GridItem xs display="flex" alignItems="center" justifyContent="end">
            <span>Items per page: </span>
            <Box width={100} marginLeft={2}>
              <CustomSelect
                name="itemsPerPage"
                value={itemsPerPage}
                options={ITEMS_PER_PAGE_OPTIONS}
                margin="dense"
                variant="default"
                className={classes.paginationSelect}
                onChange={event => setItemsPerPage(event.target.value)}
                hideHelperText
              />
            </Box>
          </GridItem>
        </GridContainer>
      )}
    </div>
  );
}
