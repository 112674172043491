import { Box } from "@mui/material";
import { useCallback } from "react";

export const SANDBOX_FORM_NAME = "form-sandbox";

/*
 * Sandboxed HTML <form> to isolate inputs whose values shouldn't be
 *   sent as FormData when their "real" <form /> in the DOM hierarchy is submitted.
 *
 * Usage:
 * To sandbox an <input />, set its `form` attribute to
 *   the exported SANDBOX_FORM_NAME above.
 *
 * Example: The Autocomplete filter box's <input /> field.
 */
export default function GlobalFormSandbox() {
  const handleSubmit = useCallback(event => (
    event.preventDefault() && false
  ), []);

  return (
    <Box position="absolute" top="-9999px" right="-9999px" visibility="hidden">
      <form
        name={SANDBOX_FORM_NAME}
        id={SANDBOX_FORM_NAME}
        onSubmit={handleSubmit}
        autoCapitalize="none"
        autoComplete="off"
        noValidate
        tabIndex={-1}
      />
    </Box>
  );
}
