import { Box, Divider } from "@mui/material";
import classNames from "classnames";
import WorkflowDiagramProvider from "components/layout/context/contextProviders/workflowDiagramContextProvider.component";
import WorkflowsPageDiagram from "components/pages/workflows/WorkflowsPageDiagram.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import { H4 } from "components/utils/headerV2.component";
import LabelValuePair from "components/utils/labelValuePair.component";
import CustomLink from "components/utils/link.component";
import Loader from "components/utils/loader.components";
import PageHeader from "components/utils/pageHeader.component";
import { WORKFLOW_NODE_ID_ADDITIONAL_PAYMENT_INFO, WORKFLOW_NODE_ID_INITIAL_PAYMENT_PAID, WORKFLOW_TEMPLATE_ID_ADJUDICATION, WORKFLOW_TEMPLATE_ID_CLAIMS_PAYMENT, WORKFLOW_TEMPLATE_ID_INITIAL_PAYMENT, WORKFLOW_TEMPLATE_ID_RECEIPT } from "components/utils/workflows/workflow.constants";
import { buildEventChain, getNodesFromEventChain } from "components/utils/workflows/workflow.utils";
import ProgramsContext from "contexts/programs.context";
import StatisticsContext from "contexts/statistics.context";
import WorkflowTemplateContext from "contexts/workflowTemplate.context";
import { makeStyles } from "mui-styles";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { formatDisplayNumber } from "utils/localization.utils";

const useStyles = makeStyles(theme => ({
  workflowsSummaryGrid: {
    alignItems: "start",
    width: "100%",
    marginTop: 32,
    [theme.breakpoints.up("lg")]: {
      flexWrap: "nowrap",
      justifyContent: "space-evenly",
    }
  },
  linkLarge: {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.lineHeight.proportional2
  },
  templateScrollTarget: {
    marginTop: -1 * (theme.layout.height.header + 32),
    paddingTop: theme.layout.height.header + 32,
  },
  summaryProgressCounts: {
    fontSize: theme.typography.body2.fontSize,
  },
  summaryLine: {
    padding: 0,
  },
  topLine: {
    justifyContent: "end",
  },
  templateProgressCounts: {
    fontSize: theme.typography.body2.fontSize,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("lg")]: {
      width: "min-content"
    }
  },
}));

const RESET_SCROLL_HANDLER_TIMEOUT = 200;
const ADD_RESET_SCROLL_HANDLER_TIMEOUT = 1000;

const completedSummaryTypesForWfTemplate = {
  [WORKFLOW_TEMPLATE_ID_INITIAL_PAYMENT]: "workflowInitialPaymentCompleted",
  [WORKFLOW_TEMPLATE_ID_RECEIPT]: "workflowClaimsReceiptCompleted",
  [WORKFLOW_TEMPLATE_ID_ADJUDICATION]: "workflowClaimsAdjudicationCompleted",
  [WORKFLOW_TEMPLATE_ID_CLAIMS_PAYMENT]: "workflowClaimsPaymentCompleted",
};

const WF_NODES_COUNT_COMPLETED_SET = new Set([
  WORKFLOW_NODE_ID_INITIAL_PAYMENT_PAID,
  WORKFLOW_NODE_ID_ADDITIONAL_PAYMENT_INFO
]);

export default function WorkflowsPage() {
  const history = useHistory();
  const location = useLocation();
  const { componentObjectId } = useParams();
  const classes = useStyles();

  const { state: programState } = useContext(ProgramsContext);
  const {
    state: { summaryCounts, summaryCountsByWorkflow },
    cancelStatsRequests,
  } = useContext(StatisticsContext);
  const wfTemplates = useContext(WorkflowTemplateContext);

  const [highlightedTemplateId, setHighlightedTemplateId] = useState(null);

  const countsByTemplate = useMemo(() => (
    (!summaryCountsByWorkflow || !wfTemplates) ? {} : (
      Object.fromEntries(
        wfTemplates.map(wfTemplate => ([
          wfTemplate.wfTemplateId,
          getNodesFromEventChain(buildEventChain(wfTemplate))
            .reduce((sum, node) => (
              WF_NODES_COUNT_COMPLETED_SET.has(node.wfNodeId) ?
                sum :
                sum + summaryCountsByWorkflow[node.wfNodeId]?.count
            ), 0)
        ]))
      )
    )
  ), [summaryCountsByWorkflow, wfTemplates]);

  useEffect(() => (
    function cancelRequestOnUnmount() {
      cancelStatsRequests();
    }
  ), [cancelStatsRequests]);

  useEffect(function updateHighlightedTemplate() {
    function resetHighlightedScrollHandler() {
      setTimeout(() => {
        setHighlightedTemplateId(null);
        window.removeEventListener("scroll", resetHighlightedScrollHandler);
      }, RESET_SCROLL_HANDLER_TIMEOUT);
    }

    const isolatedHashId = location.hash?.slice?.(1);
    if (isolatedHashId && isolatedHashId !== highlightedTemplateId) {
      setHighlightedTemplateId(isolatedHashId);
      setTimeout(() => {
        window.addEventListener("scroll", resetHighlightedScrollHandler);
      }, ADD_RESET_SCROLL_HANDLER_TIMEOUT);
      return () => {
        window.removeEventListener("scroll", resetHighlightedScrollHandler);
      }
    }
  }, [highlightedTemplateId, history, location]);

  if (!wfTemplates) {
    return (
      <Loader />
    );
  }
  if (!wfTemplates?.length) {
    return (
      <p>No applicable Workflows found for the current Remediation.</p>
    )
  }
  return (
    <Box marginTop={-3} marginBottom={48}>
      <GridContainer
        columnSpacing={8}
        rowSpacing={4}
        paddingBottom={4}
      >
        <GridItem xs={12} lg="auto" marginBottom={-4}>
          <PageHeader
            title={programState.componentObjects[componentObjectId].label}
          />
        </GridItem>
        <GridItem xs={12} lg display="flex">
          <GridContainer
            columns={24}
            columnSpacing={4}
            rowSpacing={2}
            className={classes.workflowsSummaryGrid}
            hasVerticalSpacing
            data-cy="wf-jump-links-wrapper"
          >
            {wfTemplates.map((workflowTemplate, _index, all) => {
              const { wfTemplateId, templateName } = workflowTemplate;
              const completedSummaryItem = summaryCounts?.[
                completedSummaryTypesForWfTemplate[wfTemplateId]
              ];
              return (
                <GridItem
                  xs={24}
                  sm={12}
                  md={Math.floor(24 / all.length)}
                  lg
                  minWidth={160}
                  key={wfTemplateId}
                >
                  <CustomLink
                    href={`${location.pathname}#${wfTemplateId}`}
                    className={classes.linkLarge}
                    test={templateName}
                  >
                    {templateName}
                  </CustomLink>
                  <GridContainer
                    className={classes.summaryProgressCounts}
                    columnSpacing={4}
                  >
                    <GridItem xs={12} xl>
                      <LabelValuePair
                        label={(
                          <span>In&nbsp;Progress</span>
                        )}
                        value={
                          formatDisplayNumber(
                            countsByTemplate[wfTemplateId] || 0
                          )
                        }
                        isLoading={!summaryCountsByWorkflow}
                        className={classes.summaryLine}
                      />
                    </GridItem>
                    {!!completedSummaryItem && (
                      <GridItem xs={12} xl>
                        <LabelValuePair
                          label={(
                            <span>Completed</span>
                          )}
                          value={
                            formatDisplayNumber(
                              completedSummaryItem?.count || 0
                            )
                          }
                          isLoading={!summaryCounts}
                          className={classes.summaryLine}
                        />
                      </GridItem>
                    )}
                  </GridContainer>
                </GridItem>
              );
            })}
          </GridContainer>
        </GridItem>
      </GridContainer>
      {wfTemplates.map(workflowTemplate => {
        const {
          wfTemplateId, templateName, templateDescription
        } = workflowTemplate;
        const completedSummaryItem = summaryCounts?.[
          completedSummaryTypesForWfTemplate[wfTemplateId]
        ];
        return (
          <div
            key={wfTemplateId}
            data-cy={`wf-diagram-wraapper-${templateName}`}
          >
            <Box paddingTop={4} paddingBottom={4}>
              <Divider />
            </Box>
            <div
              id={wfTemplateId}
              className={classes.templateScrollTarget}
            />
            <Box
              display="flex"
              alignItems="baseline"
              justifyContent="space-between"
              flexWrap="wrap"
              marginBottom={3}
            >
              <GridContainer
                marginRight={8}
                columnSpacing={8}
                flexWrap="nowrap"
                alignItems="baseline"
                justifyContent="space-between"
              >
                <GridItem xl>
                  <H4
                    color={
                      highlightedTemplateId === wfTemplateId ?
                        "secondary" :
                        "tertiary"
                    }
                  >
                    {templateName}
                  </H4>
                  <div>
                    {templateDescription}
                  </div>
                </GridItem>
                <GridItem>
                  <GridContainer
                    className={classes.templateProgressCounts}
                    columnSpacing={4}
                    justifyContent="end"
                  >
                    <GridItem xs={12} lg="auto">
                      <LabelValuePair
                        label={(
                          <span>In&nbsp;Progress</span>
                        )}
                        value={
                          formatDisplayNumber(
                            countsByTemplate[wfTemplateId] || 0
                          )
                        }
                        isLoading={!summaryCountsByWorkflow}
                        className={
                          classNames(classes.summaryLine, classes.topLine)
                        }
                      />
                    </GridItem>
                    {!!completedSummaryItem && (
                      <GridItem xs={12} lg="auto">
                        <LabelValuePair
                          label={(
                            <span>Completed</span>
                          )}
                          value={
                            formatDisplayNumber(
                              completedSummaryItem.count || 0
                            )
                          }
                          isLoading={!summaryCounts}
                          className={
                            classNames(classes.summaryLine, classes.topLine)
                          }
                        />
                      </GridItem>
                    )}
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </Box>
            <WorkflowDiagramProvider
              workflowTemplate={workflowTemplate}
            >
              <WorkflowsPageDiagram />
            </WorkflowDiagramProvider>
          </div>
        );
      })}
    </Box>
  );
}
