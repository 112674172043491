import { HTTP_STATUS_FORBIDDEN, HTTP_STATUS_NOT_AUTHORIZED } from "services/constants/response.constants";
import axios from "axios";
import { currentUserToken } from "services/util/authSession.utils";

const baseURL = process.env.REACT_APP_BASEURL


const AxiosService = axios.create({
  baseURL,
  headers: {
    "Content-type": "application/json",
  },
});

AxiosService.interceptors.response.use(
  response => response,
  error => {
    if (axios.isCancel(error)) {
      return { data: error };
    } else if (error?.response?.status === HTTP_STATUS_NOT_AUTHORIZED) {
      const accessToken = currentUserToken();
      if (accessToken) {
        window.location = "/logout?tokenExpiration";
      } else {
        throw error;
      }
    } else if (error?.response?.status === HTTP_STATUS_FORBIDDEN) {
      window.location = "/logout?noToken";
    }
    return Promise.reject(error)
  }
);

export default AxiosService;
