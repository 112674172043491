import theme from "theme/theme";
import { adaptV4Theme, createTheme } from "@mui/material/styles";

const dataTableTheme = createTheme(adaptV4Theme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiTooltip: {
      tooltip: {
        fontSize: theme.typography.body1.fontSizeXs,
        lineHeight: "normal",
        fontWeight: "bold",
        paddingTop: 5,
        paddingBottom: 6,
      }
    },
    MuiTableSortLabel: {
      root: {
        fontFamily: theme.typography.fontFamily,
        "&$active": {
          "&& $icon": {
            opacity: 1,
            color: theme.palette.secondary.light,
          },
        }
      },
    },
    MUIDataTable: {
      paper: {
        boxShadow: (
          `0px 2px 1px -1px rgb(0 0 0 / 20%),
          0px 1px 1px 0px rgb(0 0 0 / 14%),
          0px 1px 3px 0px rgb(0 0 0 / 12%)`
        ),
      },
      responsiveBase: {
        "@media print": {
          maxHeight: "none !important"
        }
      }
    },
    MUIDataTableFilter: {
      checkboxFormControlLabel: {
        marginLeft: 4,
        marginRight: 16
      },
      checkboxListTitle: {
        minWidth: 140
      }
    },
    MUIDataTableFilterList: {
      chip: {
        margin: "4px 8px 4px 0"
      }
    },
    // ROW STYLING
    MuiTableRow: {
      // HOVER
      hover: {
        "&$hover:hover": {
          backgroundColor: theme.palette.grey.highlight,

          MuiTableCell: {
            borderColor: "white",
          }
        },
      },
      // FOOTER
      footer: {
        backgroundColor: theme.palette.tertiaryGrey.main,
        color: "white",
        "&$hover:hover": {
          backgroundColor: theme.palette.tertiaryGrey.main,
        },
        MuiToolbar: {
          backgroundColor: theme.palette.tertiaryGrey.main,
          root: {
            backgroundColor: theme.palette.tertiaryGrey.main,
          },
        },
      },
    },
    // DROPDOWN ARROW ICON
    MuiSelect: {
      icon: {
        color: "black",
      },
    },
    /*TOOLBAR ICON BUTTON COLORS*/
    MUIDataTableToolbar: {
      icon: {
        color: theme.palette.tertiaryGrey.main,
        "&:hover": {
          color: `${theme.palette.primary.main} !important;`,
        },
      },
      iconActive: {
        color: `${theme.palette.primary.light} !important;`,
      },
    },
    MUIDataTableToolbarSelect: {
      root: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
      title: {
        fontSize: 12
      }
    },
    MUIDataTableSearch: {
      clearIcon: {
        "&:hover": {
          color: `${theme.palette.error.light} !important;`,
        },
      }
    },
    MUIDataTableFooter: {
      root: {
        MuiTableRow: {
          footer: {
            "&:hover": {
              backgroundColor: theme.palette.tertiaryGrey.main
            }
          }
        }
      }
    },
    // SEARCH FIELD
    MuiInput: {
      underline: {
        // CHANGES UNDERLINE COLOR ON HOVER
        "&&&&:hover:before": {
          borderBottom: (
            `2px solid ${theme.palette.primary.main}`
          ),
        },
        // CHANGES UNDERLINE COLOR ON ACTIVE
        "&:after": {
          borderBottom: (
            `2px solid ${theme.palette.primary.light}`
          ),
        },
        "&.Mui-disabled": {
          [["&&&&:hover:before", "&:after"]]: {
            borderBottom: "1px dotted rgba(0, 0, 0, 0.42)"
          }
        }
      },
    },
    // TOOLBAR
    MuiToolbar: {
      regular: {
        minHeight: "auto !important;",
      },
      root: {
        minHeight: 20,
        backgroundColor: "transparent",
      },
    },
    //HEADER
    MUIDataTableHeadCell: {
      sortActive: {
        color: theme.palette.secondary.light,
        "& + $sortAction": {
          marginRight: -26
        }
      },
      sortAction: {
        alignItems: "center",
        justifyContent: "center",
      },
      toolButton: {
        justifyContent: "center",
      },
      fixedHeader: {
        backgroundColor: theme.palette.tertiaryGrey.main,
        color: "white",
        fontWeight: "bold",
        padding: "10px 20px",
        lineHeight: "normal",
        position: "sticky",
        textAlign: "center"
      },
      root: {
        justifyContent: "center",
      },
    },

    // PAGEINATION CONTROLS
    MuiTablePagination: {
      root: {
        color: "white",
      },
    },
    MuiTableCell: {
      body: {
        borderBottom: `1px solid ${theme.palette.border.main}`,
        borderRight: `1px solid ${theme.palette.border.main}`,
        borderTop: "none",
        color: theme.palette.text.secondary,
        padding: "10px 20px",
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.body2.fontSize,

        "&:last-of-type": {
          borderRight: 0
        }
      },
    },
  }
}), [theme]);

export default dataTableTheme;
