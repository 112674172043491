import { adaptV4Theme, createTheme } from "@mui/material/styles";
import variables from "./styleVariables";

const theme = createTheme(adaptV4Theme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      light: variables.primaryLight,
      main: variables.primaryMain,
      dark: variables.primaryDark,
      highlight: variables.primaryHighlight,
    },
    secondary: {
      light: variables.secondaryLight,
      main: variables.secondaryMain,
      dark: variables.secondaryDark,
      highlight: variables.secondaryHighlight,
    },
    grey: {
      light: variables.greyLight,
      medium: variables.greyMedium,
      dark: variables.greyDark,
      disabled: variables.greyDisabled,
      highlight: variables.greyHighlight,
      100: variables.greyLight,
      300: variables.greyDisabledBg,
      400: variables.greyDisabled,
      500: variables.greyMedium,
      700: variables.greyDark
    },
    tertiaryGrey: {
      light: variables.tertiaryGreyLight,
      main: variables.tertiaryGrey,
    },
    teal: {
      light: variables.tealLight,
      main: variables.teal,
      dark: variables.tealDark,
    },
    purple: {
      main: variables.purple,
      dark: variables.purpleDark,
    },
    error: {
      light: variables.errorLight,
      main: variables.errorMain,
    },
    alert: {
      main: variables.statusAlert,
      dark: variables.statusAlertDark,
    },
    warning: {
      main: variables.statusWarning,
      dark: variables.statusWarningDark,
    },
    success: {
      main: variables.statusSuccess,
      dark: variables.statusSuccessDark,
    },
    text: {
      primary: variables.greyDark,
      secondary: variables.greyDark,
      black: variables.black,
      disabled: variables.greyMedium,
      error: variables.errorMain,
    },
    background: {
      default: "#ffffff",
      disabled: variables.greyDisabledBg
    },
    border: {
      light: variables.rowBorderLight,
      main: variables.rowBorder,
      dark: variables.rowBorderDark,
    },
  },
  spacing: 8,
  typography: {
    fontFamily: variables.fontFamilyDefault,
    fontWeightLight: variables.fontWeightLight,
    fontWeightRegular: variables.fontWeightRegular,
    fontWeightMedium: variables.fontWeightMedium,
    fontWeightBold: variables.fontWeightBold,
    fontWeightXBold: variables.fontWeightXBold,
    h1: {
      fontSize: variables.fontH1,
      fontWeight: variables.fontWeightBold,
    },
    h2: {
      fontSize: variables.fontH2,
      fontWeight: variables.fontWeightBold,
    },
    h3: {
      fontSize: variables.fontH3,
      fontWeight: variables.fontWeightBold,
    },
    h4: {
      fontSize: variables.fontH4,
      fontWeight: variables.fontWeightBold,
    },
    h5: {
      fontSize: variables.fontH5,
      fontWeight: variables.fontWeightBold,
    },
    h6: {
      fontSize: variables.fontH6,
      fontWeight: variables.fontWeightBold,
    },
    subtitle1: {
      fontSize: variables.fontXl,
    },
    subtitle2: {
      fontSize: variables.fontLarge,
    },
    body1: {
      fontSize: variables.fontMedium
    },
    body2: {
      fontSize: variables.fontSmall,
      fontWeight: variables.fontWeightMedium,
      lineHeight: variables.fontLarge
    },
    badge: {
      fontSize: variables.fontXxs,
      fontWeight: variables.fontWeightBold,
      lineHeight: variables.fontSmall
    },
    caption: {
      fontSize: variables.fontXs,
    },
    button: {
      fontSize: variables.fontMedium
    },
    lineHeight: {
      proportional1: 1.15,
      proportional2: 1.25,
      proportional5: 1.5
    },
  },
  filters: {
    dropShadow: {
      bookTab: variables.filterBookTab,
      bookTabInactive: variables.filterBookTabInactive,
    }
  },
  layout: {
    width: {
      mainMenu: 250,
      mainMenuXl: 300,
    },
    height: {
      appBar: 80,
      header: 70
    },
  },
  shadow: {
    accordionInset: variables.boxShadowAccordionInset,
    appBar: variables.boxShadowAppbar,
    bottomElevation2: variables.boxShadowBottom2,
    drawer: variables.boxShadowDrawer,
    elevation1: variables.boxShadow1,
    elevation2: variables.boxShadow2,
    elevation3: variables.boxShadow3,
    inset2: variables.boxShadowInset2,
    glowSecondary3: variables.glowSecondary3,
    stickyTableColumn: variables.boxShadowStickyTableColumn,
  },
  opacity: {
    disabled: "0.6",
    linkStale: "0.6",
    menuItemDisabled: "0.6",
  },
  // Make All Tables have Row Hover
  props: {
    MuiTableRow: {
      hover: true,
    },
  },
  MuiTableRow: {
    root: {
      "&:hover": {
        background: variables.rowHover,
      },
    },
  },
  overrides: {
    MuiFormControl: {
      marginDense: {
        marginTop: 0,
      },
    },
    MuiInput: {
      underline: {
        "&:hover:not($disabled):before": {
          borderBottom: `2px solid ${variables.secondaryMain}`,
        },
      },
    },
    MuiToggleButton: {
      sizeSmall: {
        padding: "12px 20px",
      },
      root: {
        lineHeight: "normal",
        color: variables.tertiaryGrey,
        "&:hover": {
          color: variables.secondaryMain,
          backgroundColor: "none",
        },
        "&$selected": {
          color: "white",
          backgroundColor: variables.secondaryMain,
          "&:hover": {
            backgroundColor: "none",
            color: "white",
          },
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: variables.fontSmall,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: variables.fontSmall,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: variables.fontXs,
        lineHeight: "normal",
        fontWeight: "bold",
        paddingTop: 5,
        paddingBottom: 6,
        color: "white",
        backgroundColor: variables.primaryMain,
      },
    },
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      asterisk: {
        color: "inherit",
        "&$error": {
          color: "inherit",
        },
      },
      root: {
        color: variables.secondaryMain,
        "&$focused": {
          color: variables.primaryMain,
        },
        "&$error": {
          color: variables.errorLight,
        },
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "rgba(119, 197, 253, 0.3)",
          "&:hover": {
            backgroundColor: "rgba(119, 197, 253, 0.3)",
          },
        },
      },
    },
    MuiTableRow: {
      hover: {
        "&$hover:hover": {
          backgroundColor: variables.rowHover,
        },
        "&$selected": {
          backgroundColor: variables.chosenElement,
          color: "yellow",
          "&:hover": {
            backgroundColor: variables.chosenElement,
          },
        },
      },
    },
    MuiAlert: {
      root: {
        marginTop: 30,
      },
    },
    MuiStepLabel: {
      label: {
        color: variables.greyMedium,
      },
    },
  },
}));

export default theme;
