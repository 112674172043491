import React from "react";
import { makeStyles } from "mui-styles";
import HeaderPrimary from "components/utils/header.component";

const useStyles = makeStyles((theme) => ({
  frameworkSection: {
    backgroundColor: theme.palette.grey.light,
    display: "flex",
    flexDirection: "column",
    marginRight: 170,
    paddingLeft: 20,
    paddingTop: 15,
    paddingBottom: 15,
    marginTop: 16,
    borderRadius: 6,
  },
  frameworkList: {
    marginTop: 7,
    marginLeft: 10,
    paddingLeft: 10,
  },
  frame: {
    marginBottom: 4,
  },
}));

export const RegGuidelineFrameworks = ({
  frameworks,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.frameworkSection}>
      <div>
        <HeaderPrimary variant="h4Primary">
          {`Associated Regulatory/Guideline Framework${
            frameworks.length > 1 ? "s" : ""
          }:`}
        </HeaderPrimary>
        <div className={classes.frameworkList}>
          {frameworks.map((frame, index) => (
            <div key={index} className={classes.frame}>
              <HeaderPrimary variant="h5TertiaryFramework">
                {`${frame.FrameworkName} ${frame.Version} - ${frame.GroupName}`}
                {!!frame.Code && ` (${frame.Code})`}
                {` ${frame.Group_Name}`}
              </HeaderPrimary>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RegGuidelineFrameworks;
