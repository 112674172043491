import { TextField } from "@mui/material";
import classNames from "classnames";
import { makeStyles } from "mui-styles";

const useStyles = makeStyles(theme => ({
  root: {
    "&.Mui-focused fieldset": {
      borderLeftWidth: "6px !important",
      padding: "4px !important",
    },
    "& fieldset": {
      borderColor: theme.palette.secondary.main,
      borderWidth: 2,
    },
    "& input:valid + fieldset": {
      borderColor: theme.palette.secondary.main,
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
    "& input:invalid + fieldset": {
      borderColor: `${theme.palette.error.main} !important;`,
      borderWidth: 2,
    },
  },
}));

export default function StaticLabelOutlinedInput(props) {
  const classes = useStyles();
  const { className, ...muiProps } = props;
  return (
    <TextField {...muiProps} className={classNames(classes.root, className)} />
  );
}
