import { ERROR_PATH_CONTACT_ADMIN, ERROR_PATH_NOT_FOUND } from "components/layout/constants/publicPaths.constants";
import ProgramsContext from "contexts/programs.context";
import useNumericParams from "hooks/useNumericParams";
import useReducerAsync from "hooks/useReducerAsync";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import programReducer, { ACTION_FULL_REPLACE_COMPONENT_OBJECTS, ACTION_FULL_REPLACE_PROGRAM_COMPONENTS, ACTION_SET_PROGRAMS, ACTION_SET_VALID_COMPONENT_OBJECT_IDS, ACTION_SET_VALID_PROGRAM_COMPONENT_IDS, programInitialState } from "reducers/global/program.reducer";
import { batchDispatch } from "reducers/utils/dispatch.utils";
import ProgramService from "services/Program.service";
import { setStateFetchEffect } from "utils/ajax.utils";
import { getRoleGroupPrograms, isAdmin } from "utils/roles.utils";

/*
 * Loads and provides Program reducer as global context for app
 */
export default function ProgramContextProvider(props) {
  const history = useHistory();
  const {
    programId: programName,
    programComponentId: programComponentName,
    componentObjectId: componentObjectName
  } = useNumericParams();
  const { children } = props;
  const isAdminRole = useMemo(isAdmin, [])

  const requestedProgramIds = useRef(new Set());
  const [state, dispatch, forceDispatch] = useReducerAsync(
    programReducer,
    programInitialState
  );
  const [isResettingContext, setIsResettingContext] = useState(false)

  const {
    programs,
    programComponents,
    componentObjects,
    validComponentObjectIds,
    validProgramIds,
    validProgramComponentIds,
    fullyLoadedProgramComponents,
  } = state;

  const program = programs?.[programName];
  const programComponent = programComponents?.[programComponentName];
  const componentObject = componentObjects?.[componentObjectName];

  const isValidIdsAvailable = useMemo(() => (
    !!validComponentObjectIds &&
    validProgramIds.size > 0 &&
    !!validProgramComponentIds
  ), [validComponentObjectIds, validProgramIds, validProgramComponentIds])

  const resetPrograms = useCallback(() => {
    setIsResettingContext(true)
  },[])

  useEffect(() => (
    function fetchValidIds() {
      if (isValidIdsAvailable && !isResettingContext) {
        return;
      }
      return setStateFetchEffect(
        [
          ProgramService.getActiveComponentObjectIds(),
          ProgramService.getActiveProgramComponentIds(),
        ],
        responses => (
          batchDispatch(forceDispatch, responses, [
            ACTION_SET_VALID_COMPONENT_OBJECT_IDS,
            ACTION_SET_VALID_PROGRAM_COMPONENT_IDS
          ])
        )
      );
    }
  )(), [forceDispatch, isValidIdsAvailable, isResettingContext]);

  useEffect(function redirectInvalidProgramCombination() {
    if (!isValidIdsAvailable) {
      return;
    }
    if (!programName || !programs || !programComponents) {
      return;
    }
    if (program) {
      if (!programComponentName) {
        return;
      }
      if (validProgramIds.has(program.name) && programComponent) {
        const matchedValidProgramId = (
          validProgramComponentIds[programComponent.programComponentId]
        );
        if (matchedValidProgramId === program.programId) {
          if (!componentObject) {
            return;
          }
          const validComponentId = (
            validComponentObjectIds[componentObject.componentObjectId]
          );
          if (validComponentId === programComponent.programComponentId) {
            return;
          }
        }
      }
    }
    const activeRoleGroupPrograms = (
      getRoleGroupPrograms(programs)
    );
    if (activeRoleGroupPrograms.length <= 0) {
      history.push(ERROR_PATH_CONTACT_ADMIN);
      return;
    }
    history.push(ERROR_PATH_NOT_FOUND);
  }, [
    componentObject, history, isAdminRole, isValidIdsAvailable, program,
    programName, programComponent, programComponents, programComponentName,
    programs, validProgramIds, validProgramComponentIds, validComponentObjectIds
  ]);

  useEffect(() => {
    (async function fetchPrograms() {
      if (!isResettingContext && programs) {
        return;
      }
      const programsResponse = await ProgramService.getAllPrograms();
      forceDispatch({
        type: ACTION_SET_PROGRAMS,
        payload: programsResponse.payload
      });
      setIsResettingContext(false);
    })()
  }, [forceDispatch, programs, isResettingContext]);

  useEffect(function fetchProgramDependants() {
    (async () => {
      const programId = programs?.[programName]?.programId;
      if (!programId || requestedProgramIds.current.has(programId)) {
        return;
      }
      requestedProgramIds.current.add(programId);
      const [componentsResponse, componentObjectResponse] = await Promise.all([
        ProgramService.getProgramComponentsByProgramId(programId),
        ProgramService.getProgramComponentObjectsByProgramId(programId)
      ]);
      forceDispatch({
        type: ACTION_FULL_REPLACE_PROGRAM_COMPONENTS,
        payload: componentsResponse.payload
      });
      forceDispatch({
        type: ACTION_FULL_REPLACE_COMPONENT_OBJECTS,
        payload: componentObjectResponse.payload
      })
    })();
  }, [
    forceDispatch, fullyLoadedProgramComponents, isResettingContext,
    programs, programName, requestedProgramIds
  ]);

  return (
    <ProgramsContext.Provider value={{ dispatch, state, resetPrograms }}>
      {children}
    </ProgramsContext.Provider>
  )
}