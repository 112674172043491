import { ROLES, ROLE_GROUPS, SINGLE_PROGRAM_ROLES } from "constants/roles.constants";
import AuthService from "services/base/Auth.service";
import StorageService from "services/Storage.service";
import { currentUserInfo, isActiveUser } from "./userSession.utils";


const getRoleGroupPrograms = programsById => {
  if (isAdmin()) {
    return Object.values(programsById);
  }
  return Object.values(programsById).filter(program => !program.Is_Admin);
}

// This function checks against any role with read only permissions,
//   regardless of program access
const hasReadOnlyAccess = () => {
  if (!StorageService.getHasAnySessionItems()) {
    return false;
  } else {
    return AuthService.checkPermissions([ROLES.READONLY]);
  }
}

const isReadOnly = () => {
  if (!StorageService.getHasAnySessionItems()) {
    return false;
  } else {
    return AuthService.checkPermissions([ROLES.READONLY]);
  }
};

const isAdmin = () => {
  if (!StorageService.getHasAnySessionItems()) {
    return false;
  } else {
    return AuthService.checkPermissions([ROLES.ADMIN]);
  }
};

const isEdit = () => {
  if (!StorageService.getHasAnySessionItems()) {
    return false;
  } else {
    return AuthService.checkPermissions([ROLES.EDIT]);
  }
}

/* USERS BY GROUP */
const isGlobalUser = () => {
  if (!StorageService.getHasAnySessionItems()) {
    return false;
  } else {
    return AuthService.checkPermissions(ROLE_GROUPS.GLOBAL_USERS)
  }
}

const activeGlobalUsersFilter = (users) => {
  return users.filter(user => (
    ROLE_GROUPS.GLOBAL_USERS.includes(user.Role_ID) && isActiveUser(user)
  ))
}

const getRoleSingleProgramId = () => {
  const user = currentUserInfo();
  if (!user) {
    return null;
  }
  return SINGLE_PROGRAM_ROLES[user.role] || null;
}

const activeUsersFilter = (users) => {
  return users.filter(user => isActiveUser(user))
}


export {
  ROLE_GROUPS,
  hasReadOnlyAccess,
  isReadOnly,
  isAdmin,
  isEdit,
  isGlobalUser,
  activeGlobalUsersFilter,
  getRoleSingleProgramId,
  activeUsersFilter,
  getRoleGroupPrograms
};
