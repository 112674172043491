import React, { useContext } from "react";
import ConsentOrderCards from "components/consentOrder/consentOrderCards.componenrt";
import Loader from "components/utils/loader.components";
import ConsentOrderContext from "contexts/consentOrder.context.js";
import ProgramsContext from "contexts/programs.context";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { PROGRAM_TYPE_CONSENT_ORDERS, PROGRAM_TYPE_DEFAULT } from "components/constants/program.constants";
import ConsentOrderDashboard from "components/consentOrder/consentOrderDashboard.component";


const ProgramDashboard = () => {
  const { state } = useContext(ConsentOrderContext);
  const { state: programsState } = useContext(ProgramsContext);
  const { consentOrdersById } = state;
  const { programId } = useParams();

  if (!consentOrdersById) {
    return <Loader />;
  }
  switch (programsState?.programs?.[programId]?.type) {
    case PROGRAM_TYPE_CONSENT_ORDERS:
      return (
        <ConsentOrderDashboard />
      );
    case PROGRAM_TYPE_DEFAULT:
    default:
      /* Unless future Programs are created, "default" refers to
       * the Remediation selection page. */
      return (
        <ConsentOrderCards />
      );
  }
};

export default ProgramDashboard;
