
export const mapToDictionary = (array, keyAttributeName) => (
  array.reduce((accumulator, object) => ({
    ...accumulator,
    [object[keyAttributeName]]: object
  }), {})
);

// Use when column keys are non-unique
export const groupRowsByColumn = (rows, columnName) => (
  rows && rows.reduce((accumulator, row) => {
    if (!accumulator[row[columnName]]) {
      accumulator[row[columnName]] = [];
    }
    accumulator[row[columnName]].push(row);
    return accumulator;
  }, {})
);

/*
 * Takes an array of objects, object fieldname, and an old and new value,
 * and replaces the array with the updated objected at the same index
 */

export const replaceObjInArrByValue = (arr, fieldName, oldValue, newValue) => {
  const indexToReplace = arr.findIndex((obj) => obj[fieldName] === oldValue);
  const newObj = { ...arr[indexToReplace], [fieldName]: newValue };
  arr.splice(indexToReplace, 1, newObj);

  return arr;
};

/*
 * Takes an array of objects, fieldname, and an edited object
 */
export const replaceObjInArrByID = (arr, fieldName, editedObj) => {
  const indexToReplace = arr.findIndex((obj) => obj[fieldName] === editedObj[fieldName]);
  arr.splice(indexToReplace, 1, editedObj);

  return arr;
};

/**
 * Takes two arrays, replaces objects in array in place from scopedArray into mainArr
 * in place
 */
export const replaceObjInArrByArrInPlace = (mainArr, scopedArr, fieldName) => {
  for (const newObj of scopedArr) {
    const indexToReplace = mainArr.findIndex(
      (obj) => obj[fieldName] === newObj[fieldName]
    );
    mainArr.splice(indexToReplace, 1, newObj);
  }
  return mainArr;
};

/**
 * Removes an object by field value
 */
export const removeObjInArray = (array, fieldName, fieldValue) => {
  const removeIndex = array.findIndex((doc) => doc[fieldName] === fieldValue);
  if (removeIndex > -1) {
    array.splice(removeIndex, 1);
    return [...array]
  }
  return array;
};

/**
 * Similar to replaceObjInArrayByArray but index order not maintained
 */
export const removeOldObjsAndReplace = (mainArr, scopedArr, fieldName) => {
  const scopedArrFieldVals = scopedArr.map((obj) => obj[fieldName]);
  const filteredMainArr = mainArr.filter(
    (obj) => !scopedArrFieldVals.includes(obj[fieldName])
  );

  return [...filteredMainArr, ...scopedArr];
};

export const mapObjectArrayByKey = (objectArray, key, original = {}) => (
  objectArray && objectArray.reduce((accumulator, object) => {
    accumulator[object[key]] = object;
    return accumulator;
  }, original)
);

export const groupObjectArrayByKey = (objectArray, key, original = {}) => (
  objectArray && objectArray.reduce((accumulator, object) => {
    if (!accumulator[object[key]]) {
      accumulator[object[key]] = [];
    }
    accumulator[object[key]].push(object);
    return accumulator;
  }, original)
);

export const countItemsByKeyValue = (objectArray, key) => {
  const countMap = {};
  objectArray.forEach(objectItem => {
    const newField = objectItem[key];
    if (!countMap[newField]) {
      countMap[newField] = 1;
    } else {
      countMap[newField] = countMap[newField] + 1;
    }
  });
  return countMap;
};

export const updateEditableOrderFields = (
  arr,
  prevPosition,
  newPosition,
  fieldName
) => {
  let updatedArr;
  // Update Object that was dragged
  const movedItem = arr.find((item) => item[fieldName] === prevPosition);
  if (!movedItem) {
    console.error("Moved item could not be found! Items not reordered.");
    return arr;
  }
  const updatedMovedItem = { ...movedItem, [fieldName]: newPosition };
  // Update other affected items
  if (newPosition > prevPosition) {
    updatedArr = arr
      .filter(item => (
        item[fieldName] > prevPosition && item[fieldName] <= newPosition
      ))
      .map(item => ({
        ...item,
        [fieldName]: item[fieldName] - 1
      }));
  } else if (newPosition < prevPosition) {
    updatedArr = arr
      .filter(item => (
        item[fieldName] < prevPosition && item[fieldName] >= newPosition
      ))
      .map(item => ({
        ...item,
        [fieldName]: item[fieldName] + 1
      }));
  }

  return [...updatedArr, updatedMovedItem];
};

/**
 * Updates and returns a re-ordered list
 *
 * 1. Update dragged item
 * 2. Capture items in list not affected by re-order
 * 3. Capture and update items in list affected by re-order
 * 4. return the entire array
 */
export const updateOrderedArray = (
  arr,
  prevPosition,
  newPosition,
  fieldName
) => {
  let affectedItems;
  let unAffectedItems;
  // Update Object that was dragged
  const movedItem = arr.find((item) => {
    return item[fieldName] === prevPosition
  });
  if (!movedItem) {
    console.error("Moved item could not be found! Items not reordered.");
    return arr;
  }
  const updatedMovedItem = { ...movedItem, [fieldName]: newPosition };
  if (newPosition > prevPosition) {
    // MOVING DOWN TO A GREATER ORDER POSITION
    unAffectedItems = arr
      .filter(item => (
        item[fieldName] > newPosition ||
        item[fieldName] < prevPosition
      ))
    affectedItems = arr
      .filter(item => (
        item[fieldName] > prevPosition &&
        item[fieldName] <= newPosition
      )).map(item => ({
        ...item,
        [fieldName]: item[fieldName] - 1
      }));
  } else if (newPosition < prevPosition) {
    // MOVING UP TO A LESSER ORDER POSITION
    unAffectedItems = arr.filter(item => (
      item[fieldName] < newPosition ||
      item[fieldName] > prevPosition
    ));

    affectedItems = arr
      .filter(item => (
        item[fieldName] < prevPosition &&
        item[fieldName] >= newPosition
      ))
      .map(item => ({
        ...item,
        [fieldName]: item[fieldName] + 1
      }));
  }

  return [...unAffectedItems, ...affectedItems, updatedMovedItem];
};

export const increaseFieldValueByOne = (arr, fieldName) => {
  return arr.map((ele) => ({ ...ele, [fieldName]: ele[fieldName] - 1 }));
};
