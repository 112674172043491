import Loader from "components/utils/loader.components";
import { useEffect, useMemo, useReducer } from "react";
import ClaimService from "services/Claim.service";
import CustomerService from "services/Customer.service";
import DocumentService from "services/Document.service";
import EvaluationService from "services/Evaluation.service";
import ClaimReview from "components/claims/ClaimReview.component";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import WorkflowService from "services/Workflow.service";
import claimReviewReducer, {
  claimReviewInitialState,
  ACTION_SET_CLAIMS,
  ACTION_SET_CLAIM_DOCUMENTS,
  ACTION_SET_EVALUATION_TEMPLATES,
  ACTION_SET_CUSTOMER,
  ACTION_SET_CLAIM_VALUE_TYPES,
  ACTION_SET_WORKFLOW_INSTANCES,
} from "reducers/shared/claimReview.reducer";

const ClaimReviewPage = () => {
  const [state, dispatch] = useReducer(claimReviewReducer, claimReviewInitialState);

  const {
    claims,
    customer,
    documents,
    claimValueTypesByName,
    evaluationTemplates,
    wfInstances
  } = state;

  const location = useLocation();
  const searchParams = useMemo(() => (
    location.search ? new URLSearchParams(location.search) : null
  ), [location.search])

  const cifId = searchParams.get("cifId")

  useEffect(() => {
    const fetchClaimsState = async () => {
      const [
        claimsRes,
        documentsRes,
        evaluationRes,
        customersRes,
        claimValueTypesResponse,
        wfInstancesResponse
      ] = await Promise.all([
        ClaimService.getClaimsByCifId(cifId),
        DocumentService.getDocumentsByCifId(cifId),
        EvaluationService.getEvaluationTemplates(),
        CustomerService.getCustomerByCifId(cifId),
        ClaimService.getClaimValueTypes(),
        WorkflowService.getWorkflowInstancesByCifId(cifId)
      ])
      dispatch({
        type: ACTION_SET_CUSTOMER,
        payload: customersRes.payload,
      })
      dispatch({
        type: ACTION_SET_CLAIMS,
        payload: claimsRes.payload
      })
      dispatch({
        type: ACTION_SET_CLAIM_DOCUMENTS,
        payload: documentsRes.payload
      })
      dispatch({
        type: ACTION_SET_EVALUATION_TEMPLATES,
        payload: evaluationRes.payload
      })
      dispatch({
        type: ACTION_SET_CLAIM_VALUE_TYPES,
        payload: claimValueTypesResponse.payload
      })
      dispatch({
        type: ACTION_SET_WORKFLOW_INSTANCES,
        payload: wfInstancesResponse.payload
      })
    }
    fetchClaimsState()
  }, [cifId])


  if (!cifId || !claims || !documents || !customer ||
    !evaluationTemplates || !claimValueTypesByName || !wfInstances) {
    return (
      <Loader />
    )
  }

  return (
    <ClaimReview
      claims={claims}
      documents={documents}
      customer={customer}
      evaluationTemplates={evaluationTemplates}
      workflowInstances={wfInstances}
      claimValueTypesByName={claimValueTypesByName}
      dispatch={dispatch}
    />
  )
}

export default ClaimReviewPage;