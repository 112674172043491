import { buildEventChain, getNodesFromEventChain } from "components/utils/workflows/workflow.utils";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import { mapObjectArrayByKey } from "utils/arrayOfObjects.utils";
import CheckboxGroup from "components/utils/form-elements/checkboxGroup.component";
import CheckboxV2 from "components/utils/form-elements/checkboxV2.component";
import WorkflowTemplateContext from "contexts/workflowTemplate.context";
import { useCallback, useContext, useMemo } from "react";
import AccordionView from "components/utils/accordionView.component";
import { makeStyles } from "mui-styles";
import { H5 } from "components/utils/headerV2.component";
import FilterSvg from "assets/icons/Filter.svg";
import { Chip, InputAdornment } from "@mui/material";
import LabelInput from "components/utils/form-elements/labelInput.component";
import { Search } from "@mui/icons-material";
import classNames from "classnames";
import ButtonDefault from "components/utils/buttonDefault.component";
import { formatDisplayNumber } from "utils/localization.utils";
import Loader from "components/utils/loader.components";
import StatisticsContext from "contexts/statistics.context";

const useStyles = makeStyles(theme => ({
  searchContainer: {
    zIndex: 100
  },
  filterContainer: {
    [theme.breakpoints.up("lg")]: {
      marginTop: -76
    },
  },
  accordionSummary: {
    display: "flex",
    minWidth: "max-content",
    justifyContent: "start",
    maxWidth: "100%",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "end",
    },
  },
  accordionSummaryContent: {
    display: "block",
    maxWidth: "100%",
    paddingLeft: 40,
    paddingRight: 4,
    fontWeight: theme.typography.body2.fontWeight,
    textAlign: "left",
    margin: "12px 0 24px",
    "&.Mui-expanded": {
      margin: "12px 0 24px",
    },
    [theme.breakpoints.up("lg")]: {
      textAlign: "right",
    },
  },
  activeSublabel: {
    color: theme.palette.secondary.main
  },
  checkboxGroupGridItem: {
    "&:not(:first-of-type)": {
      marginLeft: 16,
      marginRight: -16,
      paddingRight: 16,
      borderLeft: `1px solid ${theme.palette.border.main}`,
    }
  },
  checkboxRow: {
    display: "flex",
  },
  checkboxRowSmall: {
    [theme.breakpoints.only("lg")]: {
      marginLeft: -10
    }
  },
  checkboxCountLoader: {
    display: "flex",
    marginLeft: 8
  },
  checkboxLabelSmall: {
    paddingTop: 4,
    paddingBottom: 4,
    lineHeight: theme.typography.lineHeight.proportional2,
  },
  filterMainLabelIcon: {
    position: "relative",
    "&:before": {
      content: "''",
      position: "absolute",
      top: 8,
      left: -40,
      height: 24,
      width: 24,
      backgroundImage: "url(" + FilterSvg + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      fill: theme.palette.text.primary,
    }
  },
  filterMainLabelEmptyIcon: {
    "&:before": {
      left: -48,
    }
  },
  selectedFilterTags: {
    paddingLeft: 100,
    justifyContent: "start",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "end",
    },
  }
}));

const CHECKBOX_GRID_SPACING = { xs: 4, lg: 2, xl: 4 };


export default function WorkflowNodeSearchFilter(props) {
  const classes = useStyles();
  const {
    activeNodeIdSet,
    setActiveNodeIdSet,
    setSearchQuery,
    searchQuery,
    searchResults,
    size,
    accordionExpanded = undefined,
    setAccordionExpanded = null,
    autoFocus = false,
    onSubmit,
    searchDistributions = false
  } = props;

  const { state: { summaryCountsByWorkflow }} = useContext(StatisticsContext);
  const workflowTemplates = useContext(WorkflowTemplateContext);

  const workflowTemplatesById = useMemo(() => (
    mapObjectArrayByKey(workflowTemplates, "wfTemplateId")
  ), [workflowTemplates]);

  const wfTemplates = useMemo(() => (
    searchDistributions ?
      workflowTemplates :
      (workflowTemplates || []).filter((template) => template.isClaimTemplate)
  ), [workflowTemplates, searchDistributions])

  const eventChainsByTemplate = useMemo(() => (
    wfTemplates &&
    Object.fromEntries(wfTemplates?.map?.(workflowTemplate => ([
      workflowTemplate.wfTemplateId,
      buildEventChain(workflowTemplate)
    ])))
  ), [wfTemplates]);

  // The chain needs to be built to get the correct sort order.
  const nodeChainsByTemplate = useMemo(() => (
    !eventChainsByTemplate ? null : Object.fromEntries(
      Object.entries(eventChainsByTemplate).map(([wfTemplateId, chain]) => ([
        wfTemplateId,
        getNodesFromEventChain(chain)
      ]))
    )
  ), [eventChainsByTemplate]);

  const activeNodes = useMemo(() => (
    nodeChainsByTemplate &&
    Object.values(nodeChainsByTemplate).flatMap(chain => (
      chain.filter(node => activeNodeIdSet?.has(node.wfNodeId))
    ))
  ), [activeNodeIdSet, nodeChainsByTemplate]);

  const lgCheckboxColumns = useMemo(() => (
    Math.max(3, 12 / wfTemplates?.length)
  ), [wfTemplates?.length]);

  const xlCheckboxColumns = useMemo(() => (
    Math.max(2, Math.floor(12 / wfTemplates?.length))
  ), [wfTemplates?.length]);

  const handleNodeCheckboxChange = useCallback(event => {
    const { name } = event.target;
    setActiveNodeIdSet(prevSet => {
      const nextSet = new Set(prevSet);
      if (!nextSet.has(name)) {
        nextSet.add(name);
      } else {
        nextSet.delete(name);
      }
      return nextSet;
    });
  }, [setActiveNodeIdSet]);

  if (!activeNodes) {
    return null;
  }
  return (
    <>
      <GridContainer spacing={2}>
        <GridItem sm={12} lg={8} xl={10}
          className={classes.searchContainer}
        >
          <LabelInput
            value={searchQuery}
            placeholder="Search by CIF ID, Loan Number, Claimant Name or Email"
            variant="default"
            test="allClaims"
            name="allClaims"
            autoFocus={autoFocus}
            onChange={(event) => {
              setSearchQuery(event.target.value)
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                onSubmit()
              }
            }}
            startAdornment={(
              <InputAdornment position="start">
                <Search fontSize="small" />
              </InputAdornment>
            )}
            readOnly={false}
          />
        </GridItem>
        <GridItem xs={12}
          className={classes.filterContainer}
        >

          <AccordionView
            mainLabel={(
              <span
                className={
                  classNames(
                    classes.filterMainLabelIcon,
                    !activeNodeIdSet?.size && classes.filterMainLabelEmptyIcon
                  )
                }
              >
                <H5 color="tertiary">
                  {searchQuery.length > 2 ?
                    "Filter" :
                    "Find"
                  } by Status
                </H5>
              </span>
            )}
            subLabel={
              activeNodeIdSet?.size ? (
                <span className={classes.activeSublabel}>
                  {activeNodeIdSet?.size} selected
                </span>
              ) : (
                "No filters applied"
              )
            }
            classes={{
              summary: classes.accordionSummary,
              content: classes.accordionSummaryContent,
              detail: classes.accordionDetail,
              expandIcon: classes.accordionExpandIcon
            }}
            expanded={accordionExpanded}
            onChange={() => (
              setAccordionExpanded?.(prevExpanded => !prevExpanded)
            )}
            dense
            noHoverHighlight
          >
            <GridContainer
              spacing={CHECKBOX_GRID_SPACING}
              alignItems="stretch"
            >
              {Object.entries(nodeChainsByTemplate).map(([templateId, nodeArray]) => (
                <GridItem
                  className={classes.checkboxGroupGridItem}
                  sm={12}
                  md={6}
                  lg={lgCheckboxColumns}
                  xl={xlCheckboxColumns}
                  key={templateId}
                >
                  <CheckboxGroup
                    name={workflowTemplatesById[templateId].templateName}
                    label={workflowTemplatesById[templateId].templateName}
                  >
                    {nodeArray.map(node => {
                      const wfNodeCount = formatDisplayNumber(
                        summaryCountsByWorkflow?.[node.wfNodeId]?.count || 0
                      );
                      return (
                        <div
                          className={
                            classNames(
                              classes.checkboxRow,
                              size === "small" && classes.checkboxRowSmall
                            )
                          }
                          key={node.wfNodeId}
                        >
                          <CheckboxV2
                            name={node.wfNodeId}
                            label={(
                              <div>
                                <span>{node.nodeName}&nbsp;</span>
                                {!!summaryCountsByWorkflow && (
                                  <span>({wfNodeCount})</span>
                                )}
                              </div>
                            )}
                            checked={activeNodeIdSet?.has(node.wfNodeId)}
                            labelClassName={
                              classNames(
                                size === "small" && classes.checkboxLabelSmall
                              )
                            }
                            size={size}
                            readOnly={false}
                            onChange={handleNodeCheckboxChange}
                          />
                          <div className={classes.checkboxCountLoader}>
                            {!summaryCountsByWorkflow && (
                              <Loader size={8} variant="search" inline />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </CheckboxGroup>
                </GridItem>
              ))}
            </GridContainer>
          </AccordionView>
        </GridItem>
      </GridContainer>
      <GridContainer spacing={2}>
        <GridItem marginBottom={3}>
          <ButtonDefault
            background="primary"
            disabled={!(searchQuery.length >= 2 || activeNodeIdSet.size)}
            onClick={onSubmit}
            data-cy="btn-submit-search"
          >
            {searchResults?.length ? "Update" : "Submit"}
          </ButtonDefault>
        </GridItem>
        <GridItem xs>
          <GridContainer
            spacing={1}
            marginBottom={2}
            className={classes.selectedFilterTags}
            hasVerticalSpacing
          >
            {activeNodes.map(node => (
              <GridItem key={node.wfNodeId}>
                <Chip
                  value={node.nodeName}
                  label={node.nodeName}
                />
              </GridItem>
            ))}
          </GridContainer>
        </GridItem>
      </GridContainer>
    </>
  );
}
