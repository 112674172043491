
import ProgramsContext from "contexts/programs.context";
import WorkpackageTasksContext from "contexts/workpackageTasks.context";
// import useNumericParams from "hooks/useNumericParams";
import useReducerAsync from "hooks/useReducerAsync";
import { useCallback, useContext, useEffect } from "react";
// import { ACTION_REQ_ATTRIBUTE } from "reducers/global/utils/globalReducer.utils";
import workpackageTasksReducer, { ACTION_DISABLE_TASKS_FOR_PROGRAMS, ACTION_DISABLE_WORKPACKAGES_FOR_PROGRAMS, workpackageTasksInitialState } from "reducers/global/workpackageTasks.reducer";
// import TaskService from "services/Task.service";
// import WorkpackageService from "services/Workpackage.service";
// import { isGlobalUser } from "utils/roles.utils";

/*
 * Loads and provides Task and Workpackage reducer as global context for app.
 *
 * Fetches both types of data as needed for the current page's Program.
 */
export default function WorkpackageTaskContextProvider(props) {
  // const { programId } = useNumericParams();
  const { children } = props;
  const [state, dispatch] = useReducerAsync(
    workpackageTasksReducer,
    workpackageTasksInitialState
  );
  const { state: programState } = useContext(ProgramsContext);

  // const isWorkpackageTaskRole = useMemo(isGlobalUser, []);

  // Todo: Fetch when implemented
  // const fetchTasksForProgram = useCallback(async forProgramId => {
    //
    // if (!forProgramId) {
    //   return;
    // }
    // const tasksResponse = await TaskService.getProgramTasksByProgramId(
    //   forProgramId
    // );
    // dispatch({
    //   type: ACTION_REPLACE_TASKS,
    //   payload: tasksResponse.payload,
    //   [ACTION_REQ_ATTRIBUTE]: { programId: forProgramId }
    // });
  // }, [dispatch]);

  // Todo: Fetch when implemented
  // const fetchWorkpackagesForProgram = useCallback(async forProgramId => {
    //
    // if (!forProgramId) {
    //   return;
    // }
    // const workpackagesResponse = await WorkpackageService.getWorkpackageByProgramId(
    //   forProgramId
    // );
    // dispatch({
    //   type: ACTION_REPLACE_PROGRAM_WORKPACKAGES,
    //   payload: workpackagesResponse.payload,
    //   [ACTION_REQ_ATTRIBUTE]: { programId: forProgramId }
    // });
  // }, [dispatch]);

  // Todo: Fetch when implemented
  // const fetchAll = useCallback(async () => {
    //
    // const [taskResponse, workpackageResponse] = await Promise.all([
    //   TaskService.getAllTasks(),
    //   WorkpackageService.getAll()
    // ]);
    // dispatch({
    //   type: ACTION_REPLACE_TASKS,
    //   payload: taskResponse.payload
    // });
    // dispatch({
    //   type: ACTION_REPLACE_WORKPACKAGES,
    //   payload: workpackageResponse.payload
    // });
  // }, [dispatch]);

  const disableWorkpackageTasks = useCallback(() => {
    if (
      !programState.programs ||
      (state.isTasksDisabled && state.isWorkpackagesDisabled)
    ) {
      return;
    }
    const programIds = Object.keys(programState.programs);
    dispatch({
      type: ACTION_DISABLE_TASKS_FOR_PROGRAMS,
      payload: programIds
    });
    dispatch({
      type: ACTION_DISABLE_WORKPACKAGES_FOR_PROGRAMS,
      payload: programIds
    });
  }, [
    dispatch, programState.programs,
    state.isTasksDisabled, state.isWorkpackagesDisabled
  ]);

  useEffect(function fetchForCurrentProgram() {
    // if (isWorkpackageTaskRole) {
    //   fetchAll();
    //   if (programId) {
    //     fetchTasksForProgram(programId);
    //     fetchWorkpackagesForProgram(programId);
    //   }
    // } else {
    disableWorkpackageTasks();
    // }
  }, [
    disableWorkpackageTasks/*, isWorkpackageTaskRole, fetchAll,
    fetchTasksForProgram, fetchWorkpackagesForProgram, programId */
  ]);

  return (
    <WorkpackageTasksContext.Provider
      value={{
        dispatch,
        state,
        // fetchTasksForProgram,
        // fetchWorkpackagesForProgram,
      }}
    >
      {children}
    </WorkpackageTasksContext.Provider>
  )
}
