import { Box, Divider, IconButton, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { makeStyles } from "mui-styles";
import { useCallback, useMemo } from "react";
import { isDevelopmentEnv } from "core/environment";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: "0px 2px",
    marginRight: 6,
  },
  icon: {
    fontSize: theme.typography.h3.fontSize,
    margin: 0,
  },
  tooltipDivider: {
    marginTop: 4,
    marginBottom: 4,
    backgroundColor: "white",
  },
}));

const AttachmentPasswordViewer = (props) => {
  const classes = useStyles();

  const {
    documentData,
    copiedPasswordDocId,
    setCopiedPasswordDocId,
  } = props;

  const docPrimaryKey = useMemo(() => {
    if (documentData.reissueDocumentId) {
      return "reissueDocumentId";
    } else if (documentData.documentId) {
      return "documentId";
    } else {
      if (isDevelopmentEnv) {
        console.error("Valid documentData was not provided");
      }
      return null;
    }
  }, [documentData]);

  const iconClick = useCallback(() => {
    navigator.clipboard.writeText(documentData.descriptionPassword);
    setCopiedPasswordDocId(documentData[docPrimaryKey]);
  }, [documentData, setCopiedPasswordDocId, docPrimaryKey]);

  const makeTooltipText = useCallback(() => {
    return (
      <Box display="flex" flexDirection="column">
        <div>
          {!!copiedPasswordDocId &&
          copiedPasswordDocId === documentData[docPrimaryKey]
            ? "Copied!"
            : "Click to copy"}
        </div>
        <div>Description and / or Password:</div>
        <Divider orientation="horizontal" className={classes.tooltipDivider} />
        {documentData.descriptionPassword}
      </Box>
    );
  }, [documentData, copiedPasswordDocId,
    classes.tooltipDivider, docPrimaryKey]);
  return (
    <Tooltip title={makeTooltipText()} placement="top" enterDelay={300}>
      <IconButton
        className={classes.iconButton}
        onClick={() => iconClick(documentData)}
      >
        <InfoOutlinedIcon className={classes.icon} />
      </IconButton>
    </Tooltip>
  );
};

export default AttachmentPasswordViewer;
