import React from "react";
import rmsLogo from "assets/images/rms-logo.png";
import { makeStyles } from "mui-styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    height: 80,
    width: "100%",
    display: "block",
    backgroundImage: "url(" + rmsLogo + ")",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    marginBottom: 50,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const RMSLogo = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.logoContainer} onClick={() => history.push("/")} />
  );
};

export default RMSLogo;
