
export const REISSUE_REASON_LABELS = {
  checkReturned: "Check was returned",
  checkDamaged: "Check was damaged",
  payeeCorrection: "Payee corrections needed",
  payeeDeath: "Payee has died"
};

export const OPTIONS_REISSUE_REASON = [
  { label: REISSUE_REASON_LABELS.checkReturned, value: "checkReturned" },
  { label: REISSUE_REASON_LABELS.checkDamaged, value: "checkDamaged" },
  { label: REISSUE_REASON_LABELS.payeeCorrection, value: "payeeCorrection" },
  { label: REISSUE_REASON_LABELS.payeeDeath, value: "payeeDeath" }
];
