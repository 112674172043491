import { CheckCircle } from "@mui/icons-material";
import { Box, List, ListItem } from "@mui/material";
import WorkflowTemplateContext from "contexts/workflowTemplate.context";
import { makeStyles } from "mui-styles";
import { useContext, useMemo } from "react";
import { mapObjectArrayByKey } from "utils/arrayOfObjects.utils";

const useStyles = makeStyles(theme => ({
  checkIcon: {
    paddingLeft: 4,
  },
  listItem: {
    display: "flex",
    justifyContent: "end",
    padding: "0px 0px 10px 0px",
  }
}))

const ClaimReviewWorkflowHistory = (props) => {
  const {
    workflowInstances,
  } = props;
  const classes = useStyles();
  const wfTemplates = useContext(WorkflowTemplateContext);
  const wfTemplatesById = useMemo(() => {
    return mapObjectArrayByKey(wfTemplates, "wfTemplateId")
  }, [wfTemplates])

  return (
    <List>
      {workflowInstances.map((instance) => {
        return (
          <ListItem key={instance.wfInstanceId} className={classes.listItem}>
            <Box fontWeight="bold">
              {wfTemplatesById[instance.wfTemplateId]?.templateName}
            </Box>
            {instance.status === "Active" ?
              (
                <Box paddingLeft={1} fontStyle="italic">
                  In Progress
                </Box>
              ) :
              (
                <CheckCircle
                  fontSize="medium"
                  color="success"
                  classes={{ root: classes.checkIcon }}
                />
              )}
          </ListItem>
        )
      })}
    </List>
  )
}

export default ClaimReviewWorkflowHistory;