import ConsentOrderContext from "contexts/consentOrder.context";
import consentOrderReducer, { ACTION_REMOVE_ACTIVE_CONSENT_ORDER, ACTION_SET_ACTIVE_CONSENT_ORDER, ACTION_SET_CONSENT_ORDERS, consentOrderInitialState } from "reducers/global/consentOrder.reducer";
import useReducerAsync from "hooks/useReducerAsync";
import { setStateFetchEffect } from "utils/ajax.utils";
import ConsentOrderService from "services/ConsentOrder.service";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import StorageService from "services/Storage.service";
import ProgramsContext from "contexts/programs.context";
import { PROGRAM_TYPE_CONSENT_ORDERS } from "components/constants/program.constants";
import { SEARCH_PARAM_KEY_CONSENT_ORDER } from "components/layout/context/contextProviders/constants/consentOrderContext.constants";

const ACTIVE_CONSENT_ORDER_ACTION_SET = new Set(
  ACTION_SET_ACTIVE_CONSENT_ORDER,
  ACTION_REMOVE_ACTIVE_CONSENT_ORDER
);


export default function ConsentOrderContextProvider(props) {
  const { children } = props;
  const location = useLocation();
  const { programId } = useParams();
  const { state: programsState } = useContext(ProgramsContext);

  const [state, dispatch, forceDispatch] = useReducerAsync(consentOrderReducer, {
    ...consentOrderInitialState,
    activeConsentOrderId: StorageService.getSessionItem(
      StorageService.STORAGE_KEY_ACTIVE_CONSENT_ORDER_ID,
      false
    )
  });

  const searchParams = useMemo(() => (
    location.search ? new URLSearchParams(location.search) : null
  ), [location.search]);

  const handleSetActiveId = useCallback((activeId, isForceDispatch = false) => {
    StorageService.setSessionItem(
      StorageService.STORAGE_KEY_ACTIVE_CONSENT_ORDER_ID,
      activeId,
      false
    );
    const dispatchFn = isForceDispatch ? forceDispatch : dispatch;
    dispatchFn({
      type: ACTION_SET_ACTIVE_CONSENT_ORDER,
      payload: activeId
    });
  }, [dispatch, forceDispatch]);

  const handleRemoveActiveId = useCallback(() => {
    StorageService.removeSessionItem(
      StorageService.STORAGE_KEY_ACTIVE_CONSENT_ORDER_ID
    );
    forceDispatch({ type: ACTION_REMOVE_ACTIVE_CONSENT_ORDER })
  }, [forceDispatch]);

  const handleChildDispatch = useCallback((action, ...otherArgs) => {
    if (ACTIVE_CONSENT_ORDER_ACTION_SET.has(action.type)) {
      throw new Error(
        "Cannot set active remediation directly by dispatch." +
        "Use helper methods from context instead."
      );
    }
    return dispatch(action, ...otherArgs);
  }, [dispatch]);

  useEffect(function setStoredActiveConsentOrderFromParams() {
    if (!searchParams) {
      return;
    }
    const consentOrderId = searchParams.get(SEARCH_PARAM_KEY_CONSENT_ORDER);
    if (!consentOrderId) {
      return;
    }
    searchParams.delete(SEARCH_PARAM_KEY_CONSENT_ORDER);
    handleSetActiveId(consentOrderId, true);
  }, [handleSetActiveId, searchParams]);

  useEffect(function resetStoredActiveConsentOrderForProgram() {
    if (!programsState.programs || !programId || !state.activeConsentOrderId) {
      return;
    }
    const activeProgram = programsState.programs[programId];
    if (activeProgram?.type !== PROGRAM_TYPE_CONSENT_ORDERS) {
      handleRemoveActiveId();
    }
  }, [
    handleRemoveActiveId, programsState.programs, programId,
    state.activeConsentOrderId
  ]);

  useEffect(function fetchConsentOrders() {
    if (state.consentOrdersById) {
      return;
    }
    return setStateFetchEffect(
      ConsentOrderService.getConsentOrderList(),
      ([response]) => {
        forceDispatch({
          type: ACTION_SET_CONSENT_ORDERS,
          payload: response.payload
        })
      }
    );
  }, [state.consentOrdersById, forceDispatch]);

  return (
    <ConsentOrderContext.Provider
      value={{
        state,
        dispatch: handleChildDispatch,
        setActiveConsentOrderId: handleSetActiveId,
        removeActiveConsentOrderId: handleRemoveActiveId
      }}
    >
      {children}
    </ConsentOrderContext.Provider>
  )
}
