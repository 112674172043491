// packages
import React from "react";
// component imports
import AuthFormWrapper from "components/pages/auth/shared/authFormWrapper.component";
import LoginForm from "components/pages/auth/loginForm.component";
import AuthBackground from "components/pages/auth/shared/authBackground.component";

const Login = () => {

  return (
    <AuthBackground>
      <AuthFormWrapper>
        <LoginForm />
      </AuthFormWrapper>
    </AuthBackground>
  );
};

export default Login;
