import React, { useContext, useMemo, useRef, useState } from "react";
import Link from "components/utils/link.component";
import classNames from "classnames";
import moment from "moment";
import { makeStyles } from "mui-styles";
import { Box, Popover } from "@mui/material";
import {
  DATE_FORMAT_DISPLAY_NUMERIC_SHORT,
  DATE_FORMAT_DISPLAY_TEXT_LONG,
} from "constants/date.constants";
import { pluralize } from "utils/string.utils";
import ComsCard from "components/utils/cards/comsCard.component";
import LabelValuePair from "components/utils/labelValuePair.component";
import { SEARCH_PARAM_KEY_CONSENT_ORDER } from "components/layout/context/contextProviders/constants/consentOrderContext.constants";
import { alertsFeedVariants } from "components/shared/alerts/constants/alerts.constants";
import AlertsContainer from "components/shared/alerts/alertsContainer.component";
import AlertsFeed from "components/shared/alerts/alertsFeed.component";
import AlertContext from "contexts/alert.context";

/**
 * Based on Runbook component
 * TODOS:
 * Replace TEMP_LINK_PATH with valid paths
 * Remove or replace DEMO_MEETING_LIST with real data
*/

const useStyles = makeStyles((theme) => ({
  infoLabel: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
  },
  valueWrapper: {
    marginLeft: 5,
    display: "flex",
  },
  valueItemWrapper: {
    marginTop: 2,
  },
}));

const EMPTY_DISPLAY = "--";
const TEMP_LINK_PATH = "/";
const SHOW_DEMO_MEETING_LIST = false;
const DEMO_MEETING_LIST = [
  {
    id: "12345xyz",
    title: "Status Report Review",
    date: "2024-06-05",
    meetingPath: TEMP_LINK_PATH,
    time: "16:30:00",
  },
  {
    id: "54321abc",
    title: "Account Opening Process Review with Ops",
    date: "2024-10-29",
    meetingPath: TEMP_LINK_PATH,
    time: "11:30:00",
  },
];

const cardDateFormatter = (date) => (
  date ? moment(date).format(DATE_FORMAT_DISPLAY_TEXT_LONG) : EMPTY_DISPLAY
);

const CONSENT_ORDER_PROGRAM_NAME = "remediation-management";

export default function ConsentOrderCard(props) {
  const { consentOrder } = props;
  const [alertAnchorEl, setAlertAnchorEl] = useState(null);
  const alertRef = useRef(null);
  const { state } = useContext(AlertContext);

  const handleCloseAlert = () => {
    setAlertAnchorEl(null);
  };

  const openAlerts = () => {
    setAlertAnchorEl(alertRef.current);
  };

  const newAlerts = useMemo(() => {
    return state.userAlerts.filter(alert => (
      ((alert.consentOrderId === consentOrder.consentOrderId) &&
      alert.status === "Active")
    ))
  }, [consentOrder, state.userAlerts]);

  const consentOrderPath = useMemo(() => {
    return `/home/program/${CONSENT_ORDER_PROGRAM_NAME}?${SEARCH_PARAM_KEY_CONSENT_ORDER}=${consentOrder.consentOrderId}`;
  }, [consentOrder]);


  return (
    <ComsCard
      title={consentOrder.title}
      linkPath={!alertAnchorEl && consentOrderPath}
    >
      <>
        <LabelValuePair label="Subject" value={consentOrder.subject} />
        <LabelValuePair
          label="Regulatory Authority"
          value={consentOrder.regulatoryAuthority}
        />
        <div ref={alertRef}>
          <LabelValuePair
            label="Alerts"
            value={`${newAlerts.length} New ${pluralize(
              "Alert",
              newAlerts.length
            )}`}
            onClick={(event) => {
              event.stopPropagation();
              openAlerts();
            }}
          />
          <Popover
            open={Boolean(alertAnchorEl)}
            anchorEl={alertAnchorEl}
            onClose={handleCloseAlert}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <AlertsContainer variant="feedView">
              <AlertsFeed
                variant={alertsFeedVariants.HEADER}
                maxHeight={455}
                handleCloseAlert={handleCloseAlert}
                consentOrder={consentOrder}
              />
            </AlertsContainer>
          </Popover>
        </div>
        <LabelValuePair
          label="Started"
          value={cardDateFormatter(consentOrder.startDate)}
        />
        <LabelValuePair
          label="Report Due Date"
          value={cardDateFormatter(consentOrder.reportDueDate)}
        />
        <LabelValuePair
          label="Last Updated"
          value={cardDateFormatter(consentOrder.changeDatetime)}
        />
        <LabelValuePair label="Status" value={consentOrder.status} />
        {!!SHOW_DEMO_MEETING_LIST && (
          <LabelListValue
            label="Upcoming Meetings"
            values={DEMO_MEETING_LIST}
          />
        )}
      </>
    </ComsCard>
  );
}


// TODO: Break this out into own component
const LabelListValue = (props) => {
  const classes = useStyles();
  const { label, values = [] } = props;

  return (
    <Box>
      <span className={classes.infoLabel}>{label}:</span>
      <Box marginLeft={4}>
        {!values.length ? (
          <span className={classNames(classes.valueWrapper)}>
            There are no upcoming meetings
          </span>
        ) : (
          values.map((value) => {
            return (
              <Link
                variant="routerLink"
                className={classNames(classes.valueItemWrapper, classes.link)}
                key={value.id}
                to={value.meetingPath}
              >
                {/* TODO: Update this when meetings data is developed */}
                {`${value.title} - ${moment(value.date).format(
                  DATE_FORMAT_DISPLAY_NUMERIC_SHORT
                )} ${moment(value.time, "HH:mm:ss").format("h:mm A")} Eastern`}
              </Link>
            );
          })
        )}
      </Box>
    </Box>
  );
};
