import { useContext, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

// components
import Loader from "components/utils/loader.components";
import { isAdmin } from "utils/roles.utils";
import ProgramsContext from "contexts/programs.context";
import { ERROR_PATH_CONTACT_ADMIN, ERROR_PATH_NOT_FOUND } from "components/layout/constants/publicPaths.constants";
import { PATH_PROGRAM } from "components/layout/constants/standardPaths.constants";
import { generatePath } from "react-router-dom/cjs/react-router-dom.min";
import { PROGRAM_TYPE_DEFAULT } from "components/constants/program.constants";


const HomeRouter = () => {
  const history = useHistory();
  const { state } = useContext(ProgramsContext);

  const isAdminRole = useMemo(isAdmin, []);

  useEffect(() => {
    if (!state.programs) {
      return;
    }
    const programList = Object.values(state.programs);
    if (!programList?.length) {
      if (isAdminRole) {
        return;
      }
      return history.push(ERROR_PATH_CONTACT_ADMIN);
    }
    const defaultActiveProgram = programList
      .sort((p1, p2) => p1.order - p2.order)
      .find(program => (
        program.type === PROGRAM_TYPE_DEFAULT &&
        program.status === "Active"
      ));

    if (!defaultActiveProgram) {
      return history.push(ERROR_PATH_NOT_FOUND);
    }
    return history.push(
      generatePath(PATH_PROGRAM, {
        programId: defaultActiveProgram.name
      })
    );
  }, [history, state.programs, isAdminRole]);

  return (
    <Loader />
  );
}

export default HomeRouter;
