export const filterClaimsByNodeIdSet = (claims, nodeIdSet) => (
  (claims || []).filter(claim => (
    claim._associations.WFInstance?.some?.(instance => (
      instance.status === "Active" &&
      nodeIdSet.has(instance?.currentNodeId)
    ))
  ))
);

export const groupClaimsByInstanceNodeIds = (claims = []) => (
  claims.reduce((accumulator, claim) => {
    const wfInstance = claim._associations.WFInstance?.find?.(instance => (
      instance.status === "Active"
    ));
    const nodeId = wfInstance?.[claim.distributionDatasetId]?.currentNodeId;
    if (nodeId) {
      if (!accumulator[nodeId]) {
        accumulator[nodeId] = [];
      }
      accumulator[nodeId].push(claim);
    }
    return accumulator;
  }, {})
);
