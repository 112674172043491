import React, { useContext, useEffect, useState } from "react";
import { Grid, Link } from "@mui/material";
import { makeStyles } from "mui-styles";
import DocumentControlTable from "components/types/documentControlTable.component";
import { useMemo } from "react";
import moment from "moment";
import OrganizationContext from "contexts/organization.context";
import { downloadFile } from "services/util/file.utils";
import FileService from "services/File.service";

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.secondary.dark,
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
  reviewDateListItem: {
    paddingBottom: 4
  },
  reviewDateList: {
    paddingLeft: 0,
    marginLeft: 14
  }
}));

const DocumentType = (props) => {
  const classes = useStyles();
  const [allCompFiles, setAllCompFiles] = useState();
  const { state: organizationState } = useContext(OrganizationContext);

  const isCurrentProgramDoc = (
    props.componentObject.typeRef === "Current" &&
    props.componentObject.navIcon !== "Implementation-tools"
  );

  useEffect(() => {
    let isSubscribed = true;
    const getAllCompFiles = async (componentObjectId) => {
      const response = await FileService.fileGetAllByComponentObjectId(
        componentObjectId,
        organizationState.activeOrganizationId
      )
      if (isSubscribed) {
        setAllCompFiles(response.payload)
      }
    };
    getAllCompFiles(props.componentObject.componentObjectId);
    return () => {
      isSubscribed = false;
    };
  },
  [
    props.componentObject.componentObjectId,
    classes.link,
    organizationState.activeOrganizationId
  ]);

  const tableCells = useMemo(() => {
    if (!allCompFiles?.length) {
      return []
    }
    return allCompFiles.map((row) => {
      return {
        DocID: row.ProgramDoc_ID,
        key: row.ProgramDoc_ID,
        DocName: row.Title,
        Title: (
          <Link
            className={classes.link}
            onClick={() => downloadFile(row.ProgramDoc_ID, row.File_Ref)}
          >
            {row.Title}
          </Link>
        ),
        Description: row.Description,
        Version: row.Version,
        PublishDate: row.PublishDate,
        Publisher: row.Publisher,
        Status: row.status,
        File_Name: row.File_Ref,
        Review_Date: (
          <ul
            className={classes.reviewDateList}
          >
            {row._associations.ReviewHistory.map((reviewData) => (
              <li
                key={reviewData.ProgramDocReviewHistory_ID}
                className={classes.reviewDateListItem}
              >
                {moment(reviewData.Review_Date).format("M/D/YYYY")}
              </li>
            ))}
          </ul>
        ),
        Builder_ID: row.Builder_Builder_ID,
        componentObjectId: row.componentObjectId
      }
    })
  }, [
    allCompFiles, classes.link, classes.reviewDateListItem,
    classes.reviewDateList
  ])

  if (tableCells) {
    return (
      <Grid item sm={12} className={classes.tableContainer}>
        <DocumentControlTable
          variant={
            props.componentObject.Name.includes("Previous") ? "previous" : null
          }
          data={tableCells}
          title="Documents"
          tableTitle={props.tableTitle}
          isCurrentProgramDoc={isCurrentProgramDoc}
          setAllCompFiles={setAllCompFiles}
          allCompFiles={allCompFiles}
          tempFromTrainingProp={props.tempFromTrainingProp} // TODO: Remove
        />
      </Grid>
    );
  } else {
    return ""
  }
};

export default DocumentType;
