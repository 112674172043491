import AjaxService from "services/base/Ajax.service.js";

class FileTransferServiceBase extends AjaxService {
  shouldExtractResponseData() {
    return true;
  }

  async getFileTransfers(abortController, _limit) {
    return this.getCancellable(
      "/fileTransfer",
      abortController,
      { preventDuplicateRequests: true }
    );
  }

  async upsertFileTransfer(formData) {
    return this.post("/fileTransfer/group/upsert", formData);
  }

  async batchUpsertFileTransferTags(formData) {
    return this.post(
      "/fileTransfer/fileTransferTag/group/batchReplace",
      formData
    );
  }

  async downloadFileTransferAttachment(id) {
    return this.get(
      `/fileTransfer/fileTransferAttachment/${id}/download`,
      { responseType: "blob", timeout: 30000 }
    );
  }

  async getUploadErrors() {
    return this.get("/fileTransfer/uploadErrors")
  }

  async updateUploadErrorNotes(uploadErrorId, updatedError) {
    return this.post(
      `/fileTransfer/uploadError/update/${uploadErrorId}`,
      updatedError
    )
  }
}

const FileTransferService = new FileTransferServiceBase();
export default FileTransferService;
