import React from "react";
import { makeStyles } from "mui-styles";
import HeaderPrimary from "components/utils/header.component";
import classNames from "classnames";
import { Box, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "components/utils/tooltip.component";


const useStyles = makeStyles((theme) => ({
  default: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.secondary,
  },
  label: {
    display: "flex",
    alignItems: "baseline",
    marginBottom: 3
  },
  primaryMain: {
    color: theme.palette.primary.main
  },
  warning: { //warning not being applied correctly if defined higher in the styles object
    color: theme.palette.text.error,
  },
  infoIcon: {
    fontSize: theme.typography.body1.fontSize,
    marginLeft: 5,
    fill: theme.palette.grey.dark
  },
  sublabel: {
    marginLeft: 8
  }
}));

const InfoTooltip = ({ labelTooltip, placement }) => {
  const classes = useStyles();
  return (
    <Tooltip
      placement={placement || "top"}
      title={
        <Box paddingLeft={1.5} paddingRight={1.5}>
          {labelTooltip}
        </Box>
      }
    >
      <InfoIcon className={classes.infoIcon} />
    </Tooltip>
  );
}

export const FormLabel = (props) => {
  const classes = useStyles();
  if (props.variant === "default") {
    return (
      <label
        className={classNames(props.label && classes.label, props.className)}
        htmlFor={props.htmlFor}
      >
        <Typography
          className={
            classNames(
              classes.default,
              props.labelClassName,
              props.primaryMain && classes.primaryMain,
              props.error && classes.warning
            )
          }
          variant={props.headerType || "h5"}
        >
          {props.label}
        </Typography>
        {!!props.labelTooltip && (
          <InfoTooltip
            labelTooltip={props.labelTooltip}
            placement={props.tooltipPlacement}
          />
        )}
        {!!props.sublabel && (
          <Typography
            className={classes.sublabel}
            variant={props.headerType || "caption"}
          >
            {props.sublabel}
          </Typography>
        )}
      </label>
    );
  } else {
    return (
      <label
        className={classNames(props.label && classes.label, props.className)}
        htmlFor={props.htmlFor}
      >
        <HeaderPrimary
          variant={props.headerType ? props.headerType : "h5Tertiary"}
          noTransform={props.noTransform}
        >
          {props.label}
        </HeaderPrimary>
      </label>
    );
  }

};

export default FormLabel;
