import { mergeReplace } from "reducers/utils/payload.utils";
import { isDevelopmentEnv } from "core/environment";


export const ACTION_SET_CONSENT_ORDER_INDEX = "consent_order__index__set";
export const ACTION_SET_CONSENT_ORDERS = "consent_order__set";
export const ACTION_SET_ACTIVE_CONSENT_ORDER = "consent_order__active__set";
export const ACTION_REPLACE_CONSENT_ORDER_INDEX_ITEM = (
  "consent_order__index__replace"
);
export const ACTION_REPLACE_CONSENT_ORDER_ITEM = (
  "consent_order__replace"
);
export const ACTION_REMOVE_ACTIVE_CONSENT_ORDER = (
  "consent_order__active__remove"
);

const setToId = (action, primaryKey) => {
  const list = [].concat(action.payload);
  const mappedById = Object.fromEntries(
    list.map(item => (
      [item[primaryKey], item]
    ))
  );
  return mappedById
}

const replaceToId = (stateItem, action, primaryKey) => {
  const list = [].concat(action.payload);
  const mappedById = Object.fromEntries(
    list.map(item => (
      [item[primaryKey], item]
    ))
  );
  return {
    ...(stateItem || {}),
    ...mappedById
  };
};

export const consentOrderInitialState = {
  activeConsentOrderId: null,
  consentOrderIndex: null,
  consentOrdersById: null,
};

export default function consentOrderReducer(state, action) {
  switch (action.type) {
    case ACTION_SET_ACTIVE_CONSENT_ORDER:
      return {
        ...state,
        activeConsentOrderId: action.payload
      };
    case ACTION_REMOVE_ACTIVE_CONSENT_ORDER:
      return {
        ...state,
        activeConsentOrderId: null
      };
    case ACTION_SET_CONSENT_ORDER_INDEX:
      return {
        ...state,
        consentOrderIndex: action.payload
      };
    case ACTION_REPLACE_CONSENT_ORDER_INDEX_ITEM:
      return {
        ...state,
        consentOrderIndex: mergeReplace(
          state.consentOrderIndex,
          action.payload,
          "consentOrderId"
        )
      };
    case ACTION_SET_CONSENT_ORDERS:
      return {
        ...state,
        consentOrdersById: setToId(action, "consentOrderId")
      };
    case ACTION_REPLACE_CONSENT_ORDER_ITEM:
      return {
        ...state,
        consentOrdersById: replaceToId(action, "consentOrderId")
      };
    default:
      if (isDevelopmentEnv) {
        throw new Error(
          `Unrecognized action in ConsentOrderReducer: "${action.type}"`
        )
      }
      return state;
  }
}
