import { Container } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/system";
import "assets/css/app.css";
import theme from "theme/theme";
import AppRouter from "components/appRouter";
import { makeStyles } from "mui-styles";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: false,
    marginLeft: "unset",
    marginRight: "unset",
  },
}));

const App = () => {
  const classes = useStyles();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Container
          className={classes.root}
          maxWidth={false}
        >
          <AppRouter />
        </Container>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
