import React, { useMemo } from "react";
import { H4 } from "components/utils/headerV2.component";
import LabelValuePair from "components/utils/labelValuePair.component";
import { makeStyles } from "mui-styles";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  statusList: {
    margin: 0,
    paddingLeft: 16,
    listStyle: "none"
  },
  statusListItem: {
    paddingLeft: 0,
    lineHeight: theme.typography.lineHeight.proportional1
  }
}));

export default function CustomerStatusSection(props) {
  const {
    workflowNodesById,
    workflowTemplates,
    customerClaims
  } = props;

  const classes = useStyles();

  const statusNodes = useMemo(() => {
    const wfNodeCounts = {}
    customerClaims.forEach(claim => {
      const wfInstance = claim._associations.WFInstance?.find(instance => (
        instance.status === "Active"
      ));
      const wfNode = workflowNodesById[wfInstance?.currentNodeId];
      const currentTemplate = workflowTemplates.find(
        (wfTemplate) => wfTemplate.wfTemplateId === wfNode?.wfTemplateId
      )
      if (wfInstance && wfNode) {
        wfNodeCounts[currentTemplate.templateName] = (
          wfNodeCounts[currentTemplate.templateName] || {}
        )
        wfNodeCounts[currentTemplate.templateName][wfNode?.nodeName] = (
          wfNodeCounts[currentTemplate.templateName][wfNode?.nodeName] ?
            wfNodeCounts[currentTemplate.templateName][wfNode?.nodeName] + 1 : 1
        )
      } else {
        wfNodeCounts.Other = (
          wfNodeCounts.Other || {}
        )
        wfNodeCounts.Other.Unknown = wfNodeCounts.Other.Unknown ?
          wfNodeCounts.Other.Unknown + 1 : 1
      }
    })
    return Object.entries(wfNodeCounts).map(([templateName, templateNodes]) => (
      <div key={templateName}>
        <LabelValuePair label="Workflow" value={templateName} stacked />
        {/* TODO:  ordering is lost by creating an array from object
          which could affect card order when filtering */}
        {Object.entries(templateNodes).map(([nodeName, nodeValue]) => (
          <ul key={nodeName} className={classes.statusList}>
            <li className={classes.statusListItem}>
              <LabelValuePair
                label={nodeName}
                value={nodeValue}
              />
            </li>
          </ul>
        ))}
      </div>
    ))
  }, [workflowNodesById, customerClaims, classes, workflowTemplates]);

  return (
    <>
      <Box lineHeight="30px">
        <H4>Claims by Status</H4>
      </Box>
      {statusNodes.map((node) => node)}
    </>
  );
}
