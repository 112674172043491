import { createNodeCellParams, createNodeHeaderParams } from "components/utils/tables/utils/dataTable.utils";
import { useMemo } from "react";
import DataTable from "components/utils/tables/dataTable.component";
import moment from "moment";
import { DATETIME_DISPLAY_NUMERIC_LONG } from "constants/date.constants";
import { Box } from "@mui/material";
import CustomLink from "components/utils/link.component";
import { PATH_PROGRAM_COMPONENT } from "components/layout/constants/standardPaths.constants";
import { generatePath } from "react-router-dom/cjs/react-router-dom.min";
import LabelInput from "components/utils/form-elements/labelInput.component";
import { mapObjectArrayByKey } from "utils/arrayOfObjects.utils";
import Form from "components/utils/form-elements/form.component";
import { makeStyles } from "mui-styles";

const TABLE_COLUMNS = [
  createNodeHeaderParams("message", "Message", {
    align: "left",
    filter: false,
  }),
  createNodeHeaderParams("fileTransferId", "File Transfer", {
    align: "center",
    filter: true,
  }),
  createNodeHeaderParams("createdDatetime", "Date/Time", {
    align: "center",
    filter: false,
  }),
  createNodeHeaderParams("notes", "Notes", {
    align: "left",
    filter: false,
    sort: false
  }),
]

const useStyles = makeStyles(() => ({
  notesInput: {
    fontSize: 12,
  }
}))

export default function ReceiptTrackingTable(props) {
  const { uploadErrors, handleNotesChange, fileTransfers } = props;
  const classes = useStyles();
  const fileTransfersById = useMemo(() => {
    return mapObjectArrayByKey(fileTransfers, "fileTransferId")
  }, [fileTransfers])

  const tableCells = useMemo(() => (
    uploadErrors.map(uploadError => {
      const {
        message,
        createdDatetime,
        notes
      } = uploadError;

      const fileTransfer = fileTransfersById[
        uploadError.fileTransferId
      ]

      const fileTransferName = fileTransfer?.fileName
      const fileTransferTitle = fileTransfer?.title;
      const formattedErrorDatetime = moment(createdDatetime).format(
        DATETIME_DISPLAY_NUMERIC_LONG
      )

      return {
        message: createNodeCellParams(
          message,
          message,
          (
            message
          )
        ),
        fileTransferId: createNodeCellParams(
          fileTransferTitle,
          fileTransferTitle + fileTransferName,
          (
            fileTransfer ?
              <Box display="flex" flexDirection="column" alignItems="center">
                <CustomLink
                  to={generatePath(
                    `${PATH_PROGRAM_COMPONENT}/table?fileTransferId=${fileTransfer.fileTransferId}`,
                    {
                      programId: "remediation-management",
                      programComponentId: "file-transfers"
                    }
                  )}
                  variant="routerLink"
                >
                  {fileTransferTitle}
                </CustomLink>
                <span>{fileTransferName}</span>
              </Box>
              :
              <>No Linked File Transfer</>
          )
        ),
        notes: createNodeCellParams(
          notes,
          notes,
          (
            <LabelInput
              variant="default"
              multiline
              minRows={3}
              margin="dense"
              name={`${uploadError.uploadErrorId}-${notes}`}
              defaultValue={notes}
              key={`${uploadError.uploadErrorId}-${notes}`}
              className={classes.notesInput}
              onAutosave={(name, value) => handleNotesChange(
                name,
                value,
                uploadError.uploadErrorId
              )}
            />
          )
        ),
        createdDatetime: createNodeCellParams(
          formattedErrorDatetime,
          formattedErrorDatetime,
          (
            formattedErrorDatetime
          )
        ),
      }
    })
  ), [uploadErrors, fileTransfersById, handleNotesChange,
    classes.notesInput
  ])

  return (
    <Form>
      <DataTable
        columns={TABLE_COLUMNS}
        data={tableCells}
        options={{
          filterType: "checkbox",
          fixedHeader: true,
          fixedSelectColumn: true,
          filter: true,
          pagination: true,
          rowsPerPage: 5,
          selectableRowsHideCheckboxes: true,
          viewColumns: false,
          tableBodyMaxHeight: "calc(60vh)",
          responsive: "simple",
          rowsPerPageOptions: [5],
          sortOrder: { name: "createdDatetime", direction: "desc" }
        }}
      />
    </Form>
  )
}