//https://www.npmjs.com/package/mui-datatables

import React,{ memo, useCallback, useContext, useMemo, useState } from "react";
import { TableCell, ThemeProvider } from "@mui/material"
import { makeStyles } from "mui-styles";
import MUIDataTable, { debounceSearchRender } from "mui-datatables";
import InlineSVGIcons from "components/utils/inlineSVGIcons.component";
import { IconButton } from "@mui/material";
import FileService from "services/File.service";
import ClipLoader from "components/utils/clipLoad.component";
import Tooltip from "components/utils/tooltip.component";
import moment from "moment";
import StyledTableSortLabel, { SORT_ASCENDING } from "../tableSortLabel.component";
import classNames from "classnames";
import theme from "theme/theme";
import ProgramsContext from "contexts/programs.context";
import useNumericParams from "hooks/useNumericParams";
import { DATE_FORMAT_ISO } from "constants/date.constants";
import dataTableTheme from "./theme/dataTableTheme";
import { isDevelopmentEnv } from "core/environment";

const useStyles = makeStyles((defaultTheme) => ({
  headerCell: {
    backgroundColor: theme.palette.tertiaryGrey.main,
    color: "white",
    fontWeight: 600,
    lineHeight: "normal",
    padding: "10px 20px",
    [defaultTheme.breakpoints.down("md")]: {
      fontSize: theme.typography.body1.fontSize,
    },
  },
  headerCellLeft: {
    textAlign: "left"
  },
  headerCellCenter: {
    textAlign: "center"
  },
  headerCellRight: {
    textAlign: "right"
  },
  headerCellSmall: {
    fontSize: theme.typography.body2.fontSize,
  },
  headerCellDefaultSize: {
    fontSize: theme.typography.subtitle2.fontSize,
  },
  iconButton: {
    fill: theme.palette.tertiaryGrey.main,
    "&:hover": {
      fill: theme.palette.primary.main
    },
  },
  iconWrapper: {
    height: 22
  },
}));


const DEFAULT_SEARCH_DEBOUNCE_MS = 300;

const defaultOptions = {
  customSearchRender: debounceSearchRender(DEFAULT_SEARCH_DEBOUNCE_MS),
};


const DataTable = memo(
  function DataTable(props) {
    const {
      title, data, options, columns, className, excelData, excelColumns,
      excelFileTitle, headerSize
    } = props;
    const customColumns = useMemo(() => (
      columns.map(column => {
        if (!column.options?.customHeadLabelRender) {
          column.options = {
            ...(column.options || {}),
            customHeadRender: (columnMeta, handleToggleColumn, sortOrderBy) => (
              <TableHeaderCell
                key={column.name}
                column={column}
                columnMeta={columnMeta}
                handleToggleColumn={handleToggleColumn}
                sortOrder={sortOrderBy}
                size={headerSize}
              />
            )
          };
        }
        // Match body TableCell alignment to header alignment by default
        if (column.options.align === "center") {
          const existingSetter = column.options?.setCellProps;
          column.options.setCellProps = (...args) => ({
            align: "center",
            ...(existingSetter?.(...args) || {})
          });
        }
        return column;
      })
    ), [columns, headerSize]);

    const sortOrder = useMemo(() => {
      if (options.sortOrder) {
        return options.sortOrder;
      }
      const firstSortableColumn = customColumns.find(col => (
        col.options.sort !== false &&
        ![false, "false", "excluded"].includes(col.options.display)
      ));
      if (!firstSortableColumn) {
        return undefined;
      }
      return {
        name: firstSortableColumn.name,
        direction: firstSortableColumn.options.sortDescFirst ? "desc" : "asc"
      }
    }, [customColumns, options.sortOrder]);

    const customSearch = useCallback((searchQuery, currentRow) => {
      const lowerCaseSearchQuery = searchQuery.toLowerCase().trim();
      return currentRow.some(col => {
        const cellValue = col?.metadata?.textValue || col;
        if (typeof cellValue !== "string" && typeof cellValue !== "number") {
          if (isDevelopmentEnv) {
            console.info(
              `Search for cellValue: ${cellValue} was skipped
                as it is not a valid data type`
            );
          }
          return false;
        }
        if (cellValue) {
          const caseInsensitiveValue = `${cellValue.toLowerCase?.() || cellValue}`;
          return caseInsensitiveValue.includes(lowerCaseSearchQuery)
        }
        return false;
      })
    }, []);

    const finalOptions = useMemo(() => {
      const result = {
        ...defaultOptions,
        ...options,
        sortOrder,
        customSearch
      };
      if (excelData) {
        result.customToolbar = () => (
          <ExcelButton
            excelColumns={excelColumns}
            excelDataOrFn={excelData}
            excelFileTitle={excelFileTitle}
          />
        );
      }
      return result;
    }, [excelColumns, excelData, options, sortOrder, customSearch, excelFileTitle]);

    return (
      <ThemeProvider theme={dataTableTheme}>
        <MUIDataTable
          title={title}
          data={data}
          columns={customColumns}
          options={finalOptions}
          className={className}
        />
      </ThemeProvider>
    );
  }
);

const TableHeaderCell = memo(
  function TableHeaderCell(
    { column, columnMeta, handleToggleColumn, size, sortOrder }
  ) {
    const classes = useStyles();
    const active = sortOrder?.name === columnMeta.name;
    const align = (
      columnMeta.align ||
      columnMeta.setCellProps?.()?.style?.textAlign ||
      "left"
    );
    const isSortable = columnMeta.sort !== false;
    const tableHeaderProps = columnMeta?.setCellHeaderProps?.() || {};
    const customLabel = column.options?.customHeadLabelRender?.();

    return (
      <TableCell
        {...tableHeaderProps}
        className={classNames(
          tableHeaderProps.className,
          classes.headerCell,
          align === "left" && classes.headerCellLeft,
          align === "center" && classes.headerCellCenter,
          align === "right" && classes.headerCellRight,
          size === "small" && classes.headerCellSmall,
          size !== "small" && classes.headerCellDefaultSize,
        )}
      >
        {customLabel || (
          <StyledTableSortLabel
            active={active}
            align={align}
            direction={active ? sortOrder.direction : SORT_ASCENDING}
            hideSortIcon={!isSortable}
            onClick={() => {
              isSortable && handleToggleColumn(columnMeta.index)
            }}
            sortDisabled={!isSortable}
          >
            {columnMeta.label}
          </StyledTableSortLabel>
        )}
      </TableCell>
    );
  }
);

//https://codesandbox.io/s/muidatatables-custom-toolbar-hvpyy?file=/index.js:1181-1205
const ExcelButton = memo(
  function ExcelButton(props) {
    const classes = useStyles();
    const {
      excelColumns, excelDataOrFn, excelFileTitle = "ControlMatrix"
    } = props;
    const { state } = useContext(ProgramsContext);
    const { programId } = useNumericParams();
    const [isDownloading, setIsDownloading] = useState(false);

    const activeProgram = useMemo(() => (
      state.programs?.[programId]
    ), [state.programs, programId]);

    const generateExcel = useCallback((
      tableColumns, tableCellDataOrFn, companyName
    ) => {
      setIsDownloading(true);
      const nowDisplay = moment(new Date()).format(DATE_FORMAT_ISO);
      const headerNameList = [];
      const headers = [];
      tableColumns.filter((i) => i.label !== " ").forEach((column) => {
        headerNameList.push(column.name)
        headers.push(column.label)
      });
      const tableCellData = typeof tableCellDataOrFn === "function" ?
        tableCellDataOrFn?.() :
        tableCellDataOrFn;
      const tableDataList = tableCellData.map(row => (
        headerNameList.map((column) => row[column])
      ));
      const tableData = {
        header: [headers],
        rows: tableDataList,
      };
      const excelTableData = {
        data: tableData,
        fileName: (
          `${companyName}_${excelFileTitle}_${nowDisplay}.xlsx`
        ),
      };

      FileService.getExcelTable(excelTableData).then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${excelTableData.fileName}`);
        // Append to html page
        document.body.appendChild(link);
        // Force download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }).then(() => setIsDownloading(false));
    }, [excelFileTitle]);

    return (
      <Tooltip disableFocusListener title="Download .XLSX">
        <IconButton
          onClick={() => (
            generateExcel(
              excelColumns, excelDataOrFn, activeProgram.Organization
            )
          )}
          className={classes.iconButton}
          data-cy="btn-dataTable-excel"
        >
          {isDownloading ? (
            <div className={classes.iconWrapper} data-cy="spinning-loader">
              <ClipLoader color={theme.palette.primary.main} size={19} />
            </div>
          ) : (
            <div className={classes.iconWrapper}>
              <InlineSVGIcons variant="xls" />
            </div>
          )}
        </IconButton>
      </Tooltip>
    );
  }
);

/*
 * This is an unfortunate hack needed to fix a console error in mui-datatable.
 * The version with the fix only supports Mui v5.
 */
const oldRender = TableCell.render

TableCell.render = function(...args) {
  const [props, ...otherArgs] = args
  if (typeof props === "object" && props && "isEmpty" in props) {
    const { isEmpty, ...propsWithoutEmpty } = props
    return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs])
  } else {
    return oldRender.apply(this, args)
  }
}

export default DataTable;
