/*
 * HEADER SIZES
 * 28.8pt
 */
const fontH1 = "1.8rem";
//24pt
const fontH2 = "1.5rem";
//20.8pt
const fontH3 = "1.3rem";
//16pt
const fontH4 = "1rem";
//14.4pt
const fontH5 = "0.9rem";
//12.8pt
const fontH6 = "0.8rem";

/*
 * FONT SIZES
 * 9.6pt
 */
const fontXxs = "0.6rem";
//11.2pt
const fontXs = "0.7rem";
//12.8pt
const fontSmall = "0.8rem";
//14.4pt
const fontMedium = "0.9rem";
//16pt
const fontLarge = "1rem";
//17.6pt
const fontXl = "1.1rem";

const fontFamilyDefault = "Helvetica, Arial, sans-serif";

// FONT WEIGHT
const fontWeightLight = 200;
const fontWeightRegular = 400;
const fontWeightMedium = 500;
const fontWeightBold = 700;
const fontWeightXBold = 900;

//PRIMARY BLUE - Navy Blue
const primaryLight = "#175db5";
const primaryMain = "#164c93";
const primaryDark = "#11386c";
const primaryHighlight = "rgba(23, 94, 181, 0.7)";

//SECONDARY BLUE - Medium Blue
const secondaryLight = "#78c5fd";
const secondaryMain = "#4091ce";
const secondaryDark = "#007acc";
const secondaryHighlight = "rgba(119, 197, 253, 0.3)";

//TERTIARY COLORS
const tertiaryGrey = "#5f778c";
const tertiaryGreyLight = "#DAE1E7";
const tealLight = "#4BE6E1";
const teal = "#00a1b3";
const tealDark = "#00829b";
const purple = "#4746a0";
const purpleDark = "#393880";

//TEXT
const black = "#000000";


//STATUS COLORS

//ERROR RED
const errorMain = "#9c2129";
const errorLight = "#bc2e38";

//CAUTION ORANGE
const statusAlert = "#fa5b05";
const statusAlertDark = "#d74e04";

//CAUTION YELLOW
const statusWarning = "#edbf00";
const statusWarningDark = "#d1a700";

//GREEN
const statusSuccess = "#28a228";
const statusSuccessDark = "#118a0f";

//GRAY
const greyLight = "#f3f6f6";
const greyMedium = "#878f97";
const greyDark = "#5f778c";
const greyDisabledBg = "#d1d4d7";
const greyDisabled = "#bdbdbd";
const greyHighlight = "rgba(95, 119, 140, 0.15)";


//ROWBORDER
const rowBorder = "rgba(22, 76, 146, 0.22)";
const rowBorderLight = "rgba(22, 76, 146, 0.16)";
const rowBorderDark = "rgba(22, 76, 146, 0.3)";

// CUSTOM BOX SHADOW
const boxShadowAccordionInset = "inset 0px 1px 4px -2px rgba(0,0,0,0.89)";
const boxShadowAppbar = (
  `0px 2px 4px 1px rgb(0 0 0 / 20%),
  0px 4px 5px 0px rgb(0 0 0 / 14%),
  0px 1px 10px 0px rgb(0 0 0 / 12%)`
);
const boxShadowStickyTableColumn = "inset -1px 0px 0px 0px rgba(22, 76, 146, 0.22)";
const boxShadow1 = (
  `rgb(0 0 0 / 20%) 0px 2px 1px -1px,
  rgb(0 0 0 / 14%) 0px 1px 1px 0px,
  rgb(0 0 0 / 12%) 0px 1px 3px 0px`
);
const boxShadow2 = (
  `rgb(0 0 0 / 20%) 0px 3px 1px -2px,
  rgb(0 0 0 / 14%) 0px 2px 2px 0px,
  rgb(0 0 0 / 12%) 0px 1px 5px 0px`
);
const boxShadow3 = (
  `rgb(0 0 0 / 20%) 0px 3px 3px -2px,
  rgb(0 0 0 / 14%) 0px 3px 4px 0px,
  rgb(0 0 0 / 12%) 0px 1px 8px 0px`
);
const boxShadowInset2 = "inset 1px 0px 6px -1px rgb(0 0 0 / 60%)";
const boxShadowBottom2 = (
  "-1px 5px 9px -6px rgb(0 0 0 / 15%), 0px 2px 1px -1px rgb(0 0 0 / 10%)"
);
const boxShadowDrawer = (
  "0px 0px 7px 1px rgba(22,76,147,0.4)"
);
const glowSecondary3 = (
  "0px 0px 40px 4px rgba(23, 94, 181, 0.86)"
);

// CSS Filters
const filterBookTab = "drop-shadow(2px 1px 4px rgba(0, 0, 0, 0.3))";
const filterBookTabInactive = "drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.3))";

const styleVariables = {
  //HEADER SIZES
  fontH1,
  fontH2,
  fontH3,
  fontH4,
  fontH5,
  fontH6,

  //FONT SIZES
  fontXxs,
  fontXs,
  fontSmall,
  fontMedium,
  fontLarge,
  fontXl,

  //FONT FAMILY
  fontFamilyDefault,

  //FONT WEIGHT
  fontWeightLight,
  fontWeightRegular,
  fontWeightMedium,
  fontWeightBold,
  fontWeightXBold,

  //PRIMARY BLUE - Navy Blue
  primaryLight,
  primaryMain,
  primaryDark,
  primaryHighlight,

  //SECONDARY BLUE - Medium Blue
  secondaryLight,
  secondaryMain,
  secondaryDark,
  secondaryHighlight,

  //TERTIARY COLORS
  tertiaryGrey,
  tertiaryGreyLight,
  tealLight,
  teal,
  tealDark,
  purple,
  purpleDark,

  //TEXT
  black,
  greyDark,

  //STATUS COLORS

  // ERROR RED
  errorMain,
  errorLight,

  // CAUTION ORANGE
  statusAlert,
  statusAlertDark,

  // CAUTION YELLOW
  statusWarning,
  statusWarningDark,

  // GREEN
  statusSuccess,
  statusSuccessDark,

  // GRAY
  greyLight,
  greyDisabled,
  greyMedium,
  greyDisabledBg,
  greyHighlight,


  //ROWBORDER
  rowBorder,
  rowBorderLight,
  rowBorderDark,

  // CUSTOM BOX SHADOW
  boxShadowAccordionInset,
  boxShadowAppbar,
  boxShadow1,
  boxShadow2,
  boxShadow3,
  boxShadowBottom2,
  boxShadowDrawer,
  boxShadowInset2,
  boxShadowStickyTableColumn,
  glowSecondary3,

    // CSS Filters
  filterBookTab,
  filterBookTabInactive,
}

export default styleVariables;
