import React from "react";
import dompurify from "dompurify";
import classNames from "classnames";
import { makeStyles, useTheme } from "mui-styles";

const useStyles = makeStyles(theme => ({
  transparent: {
    backgroundColor: "transparent",
  },
  defaultColor: {
    color: `${theme.palette.grey.dark}!important`
  },
  noPadding: {
    padding: "0px !important"
  },
  defaultFont: {
    fontFamily: theme.typography.fontFamily
  }
}));


const DisplayHTML = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const sanitizer = dompurify.sanitize;
  const regex = new RegExp(`(${props.searchTerm})`, "gi");
  let reformattedHTML = props.html || "";

  // Replaces default opening and closing p tags with span
  if (
    !props.slideText &&
    reformattedHTML.startsWith("<p>") &&
    reformattedHTML.endsWith("</p>")
  ) {
    reformattedHTML = `<span>${reformattedHTML.slice(3, -4)}</span>`;
  }

  if (props.searchTerm) {
    reformattedHTML = reformattedHTML.replace(
      regex,
      `<span style="background-color:${theme.palette.teal.light};">$1</span>`
    );
  }

  if (!reformattedHTML) {
    return <div />;
  }
  return (
    <div
      className={classNames(
        props.unstyled || props.searchTerm ? "" : "sun-editor-editable",
        !props.unstyled && classes.transparent,
        !props.unstyled && classes.defaultColor,
        classes.noPadding,
        classes.defaultFont,
        props.className,
      )}
      dangerouslySetInnerHTML={{
        __html: sanitizer(reformattedHTML),
      }}
    />
  );
};

export default DisplayHTML;
