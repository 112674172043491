import React from "react";

/*
 * Context for all global db data type definitions which never change.
 *
 * Values:
 * {
 *   customerInfoMap: [{}]
 *   customerInfoMapById: {},
 *
 *   // Unused, but may be used
 *   alertTypeById: [{}],
 *   metricTypeById: [{}],
 *   roleById: [{}],
 * }
 */
const DataTypeContext = React.createContext();

export default DataTypeContext;
