import { Search } from "@mui/icons-material";
import { Box, InputAdornment } from "@mui/material";
import ButtonDefault from "components/utils/buttonDefault.component";
import Form from "components/utils/form-elements/form.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import { H3 } from "components/utils/headerV2.component";
import { useCallback, useMemo, useState } from "react";
import DistributionDatasetService from "services/DistributionDataset.service";
import DistributionDatasetCard from "./DistributionDatasetCard.component";
import PopulationFilterCheckboxes from "components/pages/consentOrder/claims/shared/PopulationFilterCheckboxes.component";
import SearchResultsWrapper from "components/pages/consentOrder/claims/shared/SearchResultsWrapper.component";

const PAGINATION_LIMIT = 10;


export default function PopulationDistributionSearch(props) {
  const {
    populationTypes,
  } = props;
  const [requestPending, setRequestPending] = useState();
  const [distributionResults, setDistributionResults] = useState();
  const [searchError, setSearchError] = useState(false);
  const [searchDisabled, setSearchDisabled] = useState(true);
  const [populationFilters, setPopulationFilters] = useState(new Set());

  const [searchQuery, setSearchQuery] = useState();
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const handleSearchChange = useCallback(event => {
    const { value } = event.target;
    setSearchError(false);
    setSearchDisabled(!value);
  }, [])

  const handleSearchSubmit = useCallback(async data => {
    try {
      setRequestPending(true);
      setSearchQuery(data.search)
      setPaginationIndex(0)
      const response = await DistributionDatasetService.searchDistributionDatasets(
        data.search,
        PAGINATION_LIMIT,
        0
      )
      setDistributionResults(response.payload);
      setTotalResults(response.totalSearchResults)
      setRequestPending(false)
    } catch (error) {
      setDistributionResults(null);
      setSearchError(true);
      console.error(error);
    }
  }, [])

  const handlePopulationFilterToggle = useCallback(event => {
    const { name } = event.target;
    setPopulationFilters(prevSet => {
      const nextSet = new Set(prevSet);
      if (!nextSet.has(name)) {
        nextSet.add(name);
      } else {
        nextSet.delete(name);
      }
      return nextSet;
    })
  }, [setPopulationFilters])

  const filteredDistributions = useMemo(() => {
    return (!populationFilters.size || (populationFilters.size === 0)) ?
      distributionResults : (
        distributionResults?.filter?.(distribution => (
          populationFilters.has(distribution.population)
        ))
      )
  }, [populationFilters, distributionResults])

  const makeTotalSearchResultsPages = useCallback((totalResultsRes) => {
    return Math.ceil(totalResultsRes / PAGINATION_LIMIT);
  }, [])

  const handlePageChange = useCallback(async (pageIndex) => {
    try {
      setRequestPending(true);
      setPaginationIndex(pageIndex);
      const response = await DistributionDatasetService.searchDistributionDatasets(
        searchQuery,
        PAGINATION_LIMIT,
        pageIndex
      )
      setTotalResults(response.totalSearchResults)
      setDistributionResults(response.payload)
      setRequestPending(false)
    } catch (error) {
      setDistributionResults(null)
      setSearchError(true)
      console.error(error)
    }
  }, [searchQuery])

  const totalPaginatedPages = useMemo(() => (
    makeTotalSearchResultsPages(totalResults)
  ), [totalResults, makeTotalSearchResultsPages])

  return (
    <Box marginBottom={10}>
      <GridContainer
        columnSpacing={16}
        alignItems="center"
        justifyContent="space-between"
      >
        <GridItem xs={12} md>
          <Form
            name="distribution-search"
            onSubmit={handleSearchSubmit}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              paddingBottom={3}
            >
              <H3>Search Distributions</H3>
              <Box
                display="flex"
                alignItems="center"
                marginLeft="auto"
                minWidth="392px"
                maxWidth="540px"
                flex={1}
                paddingTop={1}
                paddingBottom={1}
              >
                <LabelInput
                  name="search"
                  placeholder="Search by CIF ID, Loan Number, Customer Name or Email"
                  variant="default"
                  onChange={handleSearchChange}
                  readOnly={false}
                  startAdornment={(
                    <InputAdornment position="start">
                      <Search fontSize="small" />
                    </InputAdornment>
                  )}
                  autoFocus
                  hideHelperText
                />
                <Box marginLeft={2}>
                  <ButtonDefault
                    type="submit"
                    background="primary"
                    disabled={searchDisabled}
                  >
                    Search
                  </ButtonDefault>
                </Box>
              </Box>
            </Box>
          </Form>
        </GridItem>
      </GridContainer>
      <GridContainer spacing={2}>
        <GridItem md={4} xs={12}>
          <PopulationFilterCheckboxes
            populationFilters={populationFilters}
            populations={populationTypes}
            onChange={handlePopulationFilterToggle}
          />
        </GridItem>
      </GridContainer>
      <SearchResultsWrapper
        error={searchError}
        loading={requestPending}
        results={filteredDistributions}
        paginationIndex={paginationIndex}
        totalPageCount={totalPaginatedPages}
        handlePageChange={handlePageChange}
        totalResults={totalResults}
      >
        {filteredDistributions?.map?.(distribution => (
          <Box marginTop={4} key={distribution.distributionDatasetId}>
            <DistributionDatasetCard
              distributionDataset={distribution}
              setDistributionDatasets={setDistributionResults}
            />
          </Box>
        ))}
      </SearchResultsWrapper>
    </Box>
  )
}