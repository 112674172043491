import React from "react";
import CustomSunEditor from "components/utils/sunEditor.component";
import { sunEditorBuilderLang } from "components/shared/forms/constants/sunEditor.constants";
import { Box } from "@mui/material";
import { ACTION_REPLACE_SECTION_CONTENT } from "reducers/shared/builder/builder.reducer";

function BuilderSectionRTE({ section, dispatch, disableBuilder }) {

  const sectionContentOnBlur = (event, content) => {
    if (content === section.SectionContent) {
      return;
    }
    dispatch({
      type: ACTION_REPLACE_SECTION_CONTENT,
      payload: {
        id: section._meta.clientId,
        content,
      },
    });
  };

  return (
    <Box marginBottom={0.5}>
      <CustomSunEditor
        name={`section-content-${section._meta.clientId}`}
        height="auto"
        sectionDetail={section}
        defaultValue={section.SectionContent}
        onBlur={sectionContentOnBlur}
        lang={sunEditorBuilderLang}
        disabled={disableBuilder}
      />
    </Box>
  );
}

export default BuilderSectionRTE;
