import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import ButtonDefault from "components/utils/buttonDefault.component";
import { makeStyles } from "mui-styles";
import { useMemo } from "react";
import { isReadOnly } from "utils/roles.utils";

const useStyles = makeStyles((theme) => ({
  iconSmall: {
    marginRight: 8,
    fontSize: theme.typography.h4.fontSize,
  },
}));

const ResetButton = props => {
  const {
    onClick, testProp, label, disableReadOnlyUsers, disabled, ...otherProps
  } = props;
  const classes = useStyles();

  const readOnly = useMemo(isReadOnly, []);

  return (
    <ButtonDefault
      variant="small"
      color="primary"
      startIcon={(
        <RotateLeftIcon className={classes.iconSmall} />
      )}
      onClick={onClick}
      data-cy={readOnly || !testProp ? "btn-Reset" : `btn-Reset-${testProp}`}
      disableReadOnlyUsers={disableReadOnlyUsers}
      disabled={disabled}
      fullWidth
      {...otherProps}
    >
      {label || "Reset"}
    </ButtonDefault>
  );
};

export default ResetButton;
