import React from "react";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import { H3 } from "components/utils/headerV2.component";
import LabelValuePair from "components/utils/labelValuePair.component";


export default function CustomerInfoSection(props) {
  const {
    customer,
  } = props;

  const customerInfo = customer._derivedData.CustomerInfo;

  return (
    <>
      <H3>{customerInfo.customerName}</H3>
      <GridContainer spacing={2}>
        <GridItem xl={6} lg={6} md={12}>
          <LabelValuePair
            label="Email"
            value={customerInfo.customerEmail}
          />
        </GridItem>
        <GridItem xl={6} lg={6} md={12}>
          <LabelValuePair
            label="Phone"
            value={customerInfo.customerPhone}
          />
        </GridItem>
        <GridItem xl={6} lg={6} md={12}>
          <LabelValuePair
            label="SSN"
            value={customerInfo.customerSSN}
          />
        </GridItem>

        <GridItem xl={6} lg={6} md={12}>
          <LabelValuePair
            label="DOB"
            value={customerInfo.customerDOB}
          />
        </GridItem>
        <GridItem md={12}>
          <LabelValuePair
            label="Address"
            value={customerInfo.customerAddress}
          />
        </GridItem>
      </GridContainer>
    </>
  );
}
