
import { useEffect, useRef } from "react";
import ProgramContextProvider from "./programContextProvider.component";
import AlertContextProvider from "./alertContextProvider.component";
import WorkpackageTaskContextProvider from "./workpackageTaskContextProvider.component";
import ConsentOrderContextProvider from "components/layout/context/contextProviders/consentOrderContextProvider.component";
import DataTypeContextProvider from "components/layout/context/contextProviders/dataTypeContextProvider.component";

export default function AuthenticatedContextProviders(props) {
  const { children } = props;

  const isMountedRef = useRef(true);

  useEffect(function flagWhenUnmounted() {
    return () => {
      isMountedRef.current = false;
    }
  }, []);

  return (
    <ProgramContextProvider>
      <DataTypeContextProvider>
        <ConsentOrderContextProvider>
          <AlertContextProvider>
            <WorkpackageTaskContextProvider>
              {children}
            </WorkpackageTaskContextProvider>
          </AlertContextProvider>
        </ConsentOrderContextProvider>
      </DataTypeContextProvider>
    </ProgramContextProvider>
  )
}
