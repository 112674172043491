import React, { useCallback, useContext, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "mui-styles";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import FormBanner from "components/utils/form-elements/formBanner.component";
import moment from "moment";
import LabelInput from "components/utils/form-elements/labelInput.component";
import FileService from "services/File.service";
import DateInput from "components/utils/form-elements/dateInput.component";
import useNumericParams from "hooks/useNumericParams";
import Form from "components/utils/form-elements/form.component";
import { DATE_FORMAT_ISO } from "constants/date.constants";
import SingleFileUpload from "components/utils/form-elements/singleFileUpload.component";
import { getUploadHttpErrorMessage } from "services/util/http.util";
import OrganizationContext from "contexts/organization.context";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 8,
  },
  content: {
    width: 600,
    padding: "0 32px 24px 32px",
  },
  form: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingTop: 32,
    paddingBottom: 0,
  },
  inputWrapper: {
    paddingTop: 0,
    paddingBottom: 8,
  },
  attachmentWrapper: {
    display: "flex",
    paddingTop: 16,
    paddingBottom: 8,
    flexDirection: "column",
  },
  dualButtons: {
    paddingBottom: 4,
  },
  cellNarrow: {
    paddingTop: 0,
    maxWidth: 200,
    paddingBottom: 8,
  },
}));

const acceptableExtensionTypes = [
  "PDF", "DOC", "DOCX", "XLSX", "PPTX", "CSV", "PNG", "JPG", "TXT"
];

export default function UploadGenericDocumentForm(props) {
  const classes = useStyles();
  const params = useNumericParams();

  const {
    componentObject, editingDocument, onClose, onSuccess, folders
  } = props;

  const folder = useMemo(() => {
    if (!editingDocument?.ProgramDocsFolder_ID) {
      return null;
    }
    return folders.find(
      (progFolder) => (
        progFolder.ProgramDocsFolder_ID === editingDocument?.ProgramDocsFolder_ID
      )
    );
  }, [editingDocument, folders]);

  const [formData, setFormData] = useState(
    editingDocument ? {
      description: editingDocument.Description,
      date: moment(editingDocument.PublishDate).format(DATE_FORMAT_ISO),
      portCo: editingDocument.PortCo_ID
    } : {}
  );
  const [chosenFile, setChosenFile] = useState(null);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [message, setMessage] = useState("");
  const { state: organizationState } = useContext(OrganizationContext);

  const handleChange = useCallback((event) => {
    const { target } = event;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
    setIsFormChanged(true);
  }, [formData]);

  const disabledButton = useMemo(() => (
    !isFormChanged ||
    !formData?.description?.trim?.() ||
    (!chosenFile && !editingDocument?.File_Ref)
  ), [formData, isFormChanged, editingDocument?.File_Ref, chosenFile]);

  const chooseFile = useCallback((event) => {
    const extension = event.target.files[0].name.split(".").pop();
    if (!acceptableExtensionTypes.includes(extension.toUpperCase())) {
      setMessage(`Cannot upload ".${extension}" files`);

    } else {
      setChosenFile(event.target.files[0] || null);
      setMessage("");
      setIsFormChanged(true);
    }
  }, []);

  const handleSubmit = useCallback( async () => {
    try {
      setIsUploading(true);
      const body = new FormData();
      if (editingDocument?.File_Ref && !chosenFile) {
        body.append("File_Ref", editingDocument.File_Ref);
        body.append("Title", editingDocument.Title);
      } else {
        body.append("file", chosenFile);
        body.append("Title", chosenFile.name);
      }
      body.append(
        "PublishDate",
        moment(formData.date || new Date()).format(DATE_FORMAT_ISO)
      );
      body.append("Description", formData.description);
      body.append("Status", "Active");
      body.append("Program_Program_ID", params.programId);
      body.append(
        "ComponentObjects_ComponentObject_ID",
        componentObject.ComponentObject_ID
      );
      if (editingDocument?.ProgramDoc_ID) {
        body.append("ProgramDoc_ID", editingDocument?.ProgramDoc_ID);
      }
      if (editingDocument?.ProgramDoc_Group_ID) {
        body.append("ProgramDoc_Group_ID", editingDocument.ProgramDoc_Group_ID);
      }
      if (editingDocument?.ProgramDocsFolder_ID) {
        body.append(
          "ProgramDocsFolder_ID",
          editingDocument.ProgramDocsFolder_ID
        );
      }
      const response = await FileService.upsertProgramDocument(
        body,
        organizationState.activeOrganizationId
      );
      setIsUploading("published");
      return onSuccess(response);
    } catch (error) {
      const { status } = error.response || {};
      setMessage(getUploadHttpErrorMessage(status));
      setIsUploading(false);
    }
  },
  [
    chosenFile,
    componentObject,
    editingDocument,
    formData,
    onSuccess,
    params,
    organizationState.activeOrganizationId
  ]);

  return (
    <Grid className={classes.formContainer}>
      <FormBanner>
        {editingDocument?.ProgramDoc_ID ?
          `Update ${folder?.Title || ""} Document` :
          `Upload ${folder?.Title || "New"} Document`}
      </FormBanner>
      <div className={classes.content}>
        <Form className={classes.form} onSubmit={handleSubmit}>
          <div className={classes.inputWrapper}>
            <LabelInput
              variant="default"
              label="Description"
              required
              onChange={handleChange}
              defaultValue={formData.description}
              name="description"
              margin="dense"
              test="toolDescription"
              autoFocus
              multiline
            />
          </div>
          <div className={classes.cellNarrow}>
            <DateInput
              label="Date"
              required
              defaultValue={formData.date}
              name="date"
              onChange={handleChange}
              placeholder="mm/dd/yyyy"// For Safari
              margin="dense"
              test="toolApprovalDate"
            />
          </div>
          <div className={classes.attachmentWrapper}>
            <SingleFileUpload
              fileInputHandler={chooseFile}
              chosenFile={chosenFile}
              existingFile={editingDocument}
              error={message}
              existingFileDisplayName={editingDocument.Title}
            />
          </div>
          <DualFormButtons
            className={classes.dualButtons}
            cancelOnClick={onClose}
            variant="publish"
            disabled={disabledButton}
            publishLabel={
              (editingDocument?.Title && !chosenFile?.name) ? "Update" : "Upload"
            }
            isPublishing={isUploading}
            type="submit"
          />
        </Form>
      </div>
    </Grid>
  );
}

