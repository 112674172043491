import { Box } from "@mui/material";
import { H5 } from "components/utils/headerV2.component";
import CustomLink from "components/utils/link.component";
import { makeStyles } from "mui-styles";
import { useMemo } from "react";

const useStyles = makeStyles(theme => ({
  cifIdRow: {
    wordBreak: "break-all",
  }
}));


export default function TableCellClaim(props) {
  const classes = useStyles();
  const { claims, componentObjectPath } = props;

  const claimDataItems = useMemo(() => (
    claims.flatMap(claim => claim._derivedData.ClaimData)
  ), [claims]);

  const cifId = claimDataItems?.[0]?.cifId;

  const claimsReviewPath = useMemo(() => {
    if (!componentObjectPath) {
      return null;
    }
    return `${componentObjectPath}?cifId=${cifId}`
  }, [cifId, componentObjectPath]);

  return (
    <div>
      <Box className={classes.cifIdRow} marginTop={1}>
        <H5>CIF ID:</H5>
        <div>
          {cifId ? (
            <CustomLink
              to={claimsReviewPath}
              variant="routerLink"
              test={cifId}
            >
              {cifId}
            </CustomLink>
          ) : (
            "Unknown"
          )}
        </div>
      </Box>
      {claimDataItems?.map?.(claimData => (
        <Box marginTop={1} marginBottom={1} key={claimData.gsLoanNumber}>
          <H5 color="tertiary">Loan&nbsp;Number:</H5>
          <div data-cy={`loan-number-${claimData.gsLoanNumber}`}>
            {claimData.gsLoanNumber}
          </div>
        </Box>
      ))}
    </div>
  );
}
