import CheckListing from "components/shared/payments/CheckListing.component";
import { PATH_COMPONENT_OBJECT } from "components/layout/constants/standardPaths.constants";
import ComsCard from "components/utils/cards/comsCard.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import { H4 } from "components/utils/headerV2.component";
import LabelValuePair from "components/utils/labelValuePair.component";
import CustomLink from "components/utils/link.component";
import { DATE_FORMAT_DISPLAY_NUMERIC_SHORT } from "constants/date.constants";
import moment from "moment";
import { makeStyles } from "mui-styles";
import { useCallback, useMemo } from "react";
import { generatePath } from "react-router-dom/cjs/react-router-dom.min";
import { mapObjectArrayByKey } from "utils/arrayOfObjects.utils";
import { formatCash } from "utils/localization.utils";

const useStyles = makeStyles(() => ({
  textWrap: {
    wordBreak: "break-all"
  }
}));

// const mergePaymentAssociation = (parent, payments) => {
//   const newPaymentsById = mapObjectArrayByKey(payments, "paymentId");
//   return {
//     ...parent,
//     _associations: {
//       ...parent._associations,
//       Payment: parent._associations.Payment?.map(prevPayment => (
//         newPaymentsById[prevPayment.paymentId] || prevPayment
//       ))
//     }
//   };
// };

const mergePaymentAssociations = (parent, payloadPayments) => {
  const existingPaymentIds = new Set(
    parent._associations.Payment?.map?.(payment => payment.paymentId)
  );
  let matchedPayloadPayments = null;
  if (parent.claimId) {
    matchedPayloadPayments = payloadPayments.filter(payment => (
      payment.claimId === parent.claimId
    ));
  } else {
    matchedPayloadPayments = payloadPayments.filter(payment => (
      payment.distributionDatasetId === parent.distributionDatasetId
    ));
  }
  const payloadPaymentsById = (
    mapObjectArrayByKey(matchedPayloadPayments, "paymentId")
  );
  return {
    ...parent,
    _associations: {
      ...parent._associations,
      Payment: parent._associations.Payment?.map?.(prevPayment => (
        payloadPaymentsById[prevPayment.paymentId] || prevPayment
      )).concat(matchedPayloadPayments.filter(payment => (
        !existingPaymentIds.has(payment.paymentId)
      )))
    }
  };
};



export default function DistributionDatasetCard(props) {
  const {
    distributionDataset,
    setDistributionDatasets
  } = props
  const {
    gsLoanNumber,
    cifId,
    originationBalance,
    originationDate,
    population,
    remediationAmount,
    servicer,
  } = distributionDataset;
  const {
    Claim: claims,
    Customer: customers
  } = distributionDataset._associations;
  const classes = useStyles();
  const customer = customers[0];
  const customerName = customer._derivedData.CustomerInfo?.customerName;
  const formattedOriginationDate =
    moment(originationDate).format(DATE_FORMAT_DISPLAY_NUMERIC_SHORT);
  const formattedRemediationAmount =
    formatCash(remediationAmount, true);
  const formattedOriginationBalance =
    formatCash(originationBalance, true);

  const claimsReviewPath = useMemo(() => {
    const path = generatePath(PATH_COMPONENT_OBJECT, {
      programId: "remediation-management",
      programComponentId: "claims",
      componentObjectId: "claims-review-component-object"
    })
    return `${path}?cifId=${cifId}`
  }, [cifId]);

  const setDistributionPayments = useCallback(payments => {
    setDistributionDatasets(prevResults => {
      return prevResults.map(result => {
        return result.distributionDatasetId !== payments[0].distributionDatasetId ?
          result :
          mergePaymentAssociations(result, payments)
      })
    });
  }, [setDistributionDatasets]);

  const setAssociatedClaimPayments = useCallback(payments => {
    setDistributionDatasets(prevResults => (
      prevResults.map(result => ({
        ...result,
        _associations: {
          ...result._associations,
          Claim: result._associations.Claim?.map?.(prevClaim => (
            mergePaymentAssociations(prevClaim, payments)
          ))
        }
      }))
    ));
  }, [setDistributionDatasets]);

  return (
    <ComsCard
      title={`Loan #${gsLoanNumber}`}
      subtitle={
        claims.length > 0 ? (
          <GridItem sm={12} md={6} xl={4}>
            <CustomLink
              to={claimsReviewPath}
              variant="routerLinkBold"
            >
              View Claim
            </CustomLink>
          </GridItem>
        ) : (
          <span>No Claim</span>
        )
      }
    >
      <GridContainer spacing={2}>
        <GridItem sm={12} md={6} xl={4}>
          <LabelValuePair
            label="Customer Name"
            value={customerName}
          />
          <LabelValuePair
            label="CIF ID"
            value={cifId}
            className={classes.textWrap}
          />
          <LabelValuePair
            label="Population"
            value={population}
          />
        </GridItem>
        <GridItem sm={12} md={6} xl={4}>
          <LabelValuePair
            label="Remediation Amount"
            value={formattedRemediationAmount}
          />
          <LabelValuePair
            label="Origination Balance"
            value={formattedOriginationBalance}
          />
          <LabelValuePair
            label="Origination Date"
            value={formattedOriginationDate}
          />
        </GridItem>
        <GridItem sm={12} md={6} xl={4}>
          <LabelValuePair
            label="Servicer"
            value={servicer}
          />
        </GridItem>
        <GridItem sm={12} marginTop={2}>
          <H4>
            Checks
          </H4>
          <CheckListing
            claim={claims?.[0]}
            distributionDataset={distributionDataset}
            setClaimPayments={setAssociatedClaimPayments}
            setDistributionPayments={setDistributionPayments}
          />
        </GridItem>
      </GridContainer>
    </ComsCard>
  )
}