import AuthService from "services/base/Auth.service";

export const ROLES = AuthService.Type;

export const SINGLE_PROGRAM_ROLES = {
};

export const ROLE_GROUPS = {
  ALL: Object.values(ROLES),
  GLOBAL_USERS: [
    ROLES.READONLY,
    ROLES.EDIT,
    ROLES.ADMIN,
  ]
};
