import { DATETIME_FORMAT_24H, MOMENT_INVALID_DATE } from "constants/date.constants";
import moment from "moment";

export const findMomentHasTime = momentDate => (
  !moment(momentDate).seconds(0).isSame(momentDate)
);

export const formattedMomentCustomError = (formattedDate, customMessage) => {
  if (formattedDate === MOMENT_INVALID_DATE) {
    return customMessage || "No Date Provided";
  }
  return formattedDate
};

export const defaultChangeDate = () => (
  moment(new Date()).format(DATETIME_FORMAT_24H)
);

export const makeDefaultDateString = date => {
  return date ? moment(date).format("MMMM DD, YYYY") : "No Date Provided"
}
