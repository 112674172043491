import React, { useEffect, useState, useCallback, useMemo } from "react";
import { makeStyles } from "mui-styles";
import SortableTable from "components/utils/tables/sortableTable.component";
import UserService from "services/User.service";
import EditIcon from "components/utils/editIcon.component";
import CustomModal from "components/utils/modal.component";
import UserProfile from "components/pages/admin/userProfile.component";
import Alert from "@mui/lab/Alert";
import ResetButton from "components/utils/resetButton.component";
import ConfirmationDialog from "components/utils/dialogs/confirmationDialog.component";
import Loader from "components/utils/loader.components";
import AuthService from "services/base/Auth.service";
import { currentUserInfo } from "services/util/authSession.utils";
import PeopleIcon from "@mui/icons-material/People";
import { setStateFetchEffect } from "utils/ajax.utils";
import { mapObjectArrayByKey } from "utils/arrayOfObjects.utils";
import ButtonDefault from "components/utils/buttonDefault.component";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import { AddCircleOutline, Redo } from "@mui/icons-material";
import { ROLES } from "constants/roles.constants";
import UserMgmtForm from "components/pages/admin/userMgmtForm.component";
import { Divider } from "@mui/material";
import { H3 } from "components/utils/headerV2.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import { formatPhoneNum } from "utils/formatters.utils";

const useStyles = makeStyles((theme) => ({
  adminContentWrapper: {
    paddingBottom: 80
  },
  userMgmtWrapper: {
  },
  alert: {
    marginTop: 0,
    marginBottom: 40
  },
  alertMessage: {
    lineHeight: theme.typography.lineHeight.proportional5
  },
  actionButton: {
    height: "100%",
  },
  actionButtonLabel: {
    whiteSpace: "normal",
    wordBreak: "keep-all"
  },
  table: {
    whiteSpace: "nowrap",
    [theme.breakpoints.down("md")]: {
      whiteSpace: "normal",
      wordBreak: "keep-all",
    }
  },
  tableTitleSection: {
    display: "flex",
    marginBottom: 8,
    alignItems: "center",
  },
  tableTitle: {
    display: "flex",
    alignItems: "center",
  },
  tableCellUserId: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 8,
      paddingRight: 8
    }
  },
  tableCellEmail: {
    whiteSpace: "normal"
  },
  tableCellEmailPending: {
    marginTop: 4,
    color: theme.palette.text.disabled,
    fontStyle: "italic",
  },
  tableCellPhone: {
    whiteSpace: "nowrap"
  },
  peopleIcon: {
    marginRight: 8,
  },
  addItem: {
    margin: "16px 0",
  },
  statusIcon: {
    fontSize: theme.typography.h4.fontSize,
    marginRight: 8,
  },
  dividerGridItem: {
    marginTop: 8,
    marginBottom: 16,
    [theme.breakpoints.up("xl")]: {
      display: "none"
    }
  }
}));

const TABLE_HEADERS = [
  { name: "userId", display: "Edit", alignment: "center" },
  { name: "name", display: "Name", sort: true },
  { name: "email", display: "Email", sort: true },
  { name: "phone", display: "Phone", alignment: "center" },
  { name: "roleName", display: "Role", alignment: "center", sort: true },
  { name: "actions", display: "Actions", alignment: "center" },
];

const UserMgmtCentral = () => {
  const classes = useStyles();
  const [formMode, setFormMode] = useState(""); //create & edit
  const [editFormType, setEditFormType] = useState(""); //currentUser
  const [userToEdit, setUserToEdit] = useState({});
  const [emailSentMessage, setEmailSentMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [resetPasswordUser, setResetPasswordUser] = useState({});
  const [currentUser, setCurrentUser] = useState();
  const [otherUsers, setOtherUsers] = useState(null);
  const [passwordResetUserSet, setPasswordResetUserSet] = useState(new Set());
  const [roles, setRoles] = useState(null);
  //MODAL
  const [editModal, setEditModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [userToChangeStatus, setUserToChangeStatus] = useState();

  const user = useMemo(currentUserInfo, []);

  const openAddForm = () => {
    setEditModal(true);
    setFormMode("create");
    setEmailSentMessage("");
  };

  const openEditForm = (userID, type) => {
    UserService.getUserById(userID).then((res) => {
      setUserToEdit(res.payload);
      setEditFormType(type); //"" or "currentUser"
      setFormMode("edit");
      setEditModal(true);
      setEmailSentMessage("");
    });
  };

  const confirmChangeStatusModal = useCallback((currUser) => {
    setConfirmModal(true);
    setUserToChangeStatus(currUser);
  }, []);

  useEffect(() => {
    return setStateFetchEffect([
      UserService.getAll(),
      UserService.getAllRoles(),
    ], ([usersResponse, rolesResponse]) => {
      setRoles(rolesResponse.payload);
      const loggedInUser = usersResponse.payload.find(responseUser => (
        responseUser.userId === user.id
      ));
      setCurrentUser(loggedInUser);
      setOtherUsers(
        usersResponse.payload.filter(responseUser => (
          responseUser !== loggedInUser
        ))
      );
    });
  }, [user.id]);

  const rolesById = useMemo(() => (
    mapObjectArrayByKey(roles, "roleId")
  ), [roles]);

  const tableData = useMemo(() => (
    otherUsers?.map?.((userData) => {
      const userIsDeactivated = userData.status === "Inactive";
      return {
        userId: {
          value: userData.userId,
          node: userIsDeactivated ? " " : (
            <div className={classes.tableCellUserId}>
              <EditIcon
                key={`edit-${userData.email}-btn`}
                variant="matrix"
                onClick={() => openEditForm(userData.userId, "")}
                test="admin"
              />
            </div>
          )
        },
        name: `${userData.firstName} ${userData.lastName}`,
        email: {
          value: userData.email,
          node: (
            <>
              <div className={classes.tableCellEmail}>
                {userData.email || "--"}
              </div>
              {userData.mfaEnabled === 0 && (
                <div className={classes.tableCellEmailPending}>
                  Account setup pending
                </div>
              )}
            </>
          )
        },
        phone: {
          value: userData.phone,
          node: (
            <div className={classes.tableCellPhone}>
              {userData.phone ?
                (formatPhoneNum(userData.phone || "") || userData.phone) :
                "--"
              }
            </div>
          )
        },
        roleName: rolesById[userData.roleId]?.label,
        actions: {
          node: (
            <GridContainer padding={1} spacing={1} hasVerticalSpacing>
              <GridItem xs={12} md>
                <ResetButton
                  className={classes.actionButton}
                  classes={{ label: classes.actionButtonLabel }}
                  disabled={
                    passwordResetUserSet.has(userData.userId) ||
                    userData.roleId === ROLES.NO_ACCESS ||
                    userIsDeactivated
                  }
                  label={
                    userData.mfaEnabled === 0 ?
                      "Resend setup link" :
                      "Reset password"
                  }
                  onClick={() => {
                    setOpenDialog(true)
                    setResetPasswordUser(userData);
                  }}
                  key={`reset-${userData.email}-btn`}
                />
              </GridItem>
              <GridItem xs={12} md>
                <ButtonDefault
                  key={`deactivate-${userData.email}-btn`}
                  variant="small"
                  className={classes.actionButton}
                  classes={{ label: classes.actionButtonLabel }}
                  color={userIsDeactivated ? "secondary" : "red"}
                  disabled={userData.userId === user.id}
                  startIcon={
                    userIsDeactivated ?
                      <Redo className={classes.statusIcon} /> :
                      <BlockOutlinedIcon className={classes.statusIcon} />
                  }
                  onClick={() => confirmChangeStatusModal(userData)}
                  fullWidth
                >
                  {userIsDeactivated ? `Reactivate` : `Deactivate`}
                </ButtonDefault>
              </GridItem>
            </GridContainer>
          )
        }
      };
    })
  ), [
    classes, confirmChangeStatusModal, otherUsers, passwordResetUserSet,
    rolesById, user
  ]);

  const sendResetEmail = useCallback(async () => {
    //send reset email
    await AuthService.forgot(resetPasswordUser.email);
    setEmailSentMessage(
      `A reset password email has been sent to ${resetPasswordUser.email}`
    );
    setPasswordResetUserSet(prevSet => (
      new Set(Array.from(prevSet).concat(resetPasswordUser.userId))
    ));
    setOpenDialog(false);
  }, [resetPasswordUser]);

  const handleUserUpsert = useCallback(upsertUser => {
    if (upsertUser.userId === currentUser.userId) {
      setCurrentUser(upsertUser);
      return;
    }
    setOtherUsers(prevUsers => {
      const nextUsers = [...prevUsers];
      const index = prevUsers.findIndex(prevUser => (
        prevUser.userId === upsertUser.userId
      ));
      nextUsers.splice(index, 1, upsertUser);
      return nextUsers;
    });
  }, [currentUser?.userId, setOtherUsers]);

  const handleUserCreate = useCallback(upsertUser => {
    setPasswordResetUserSet(prevSet => (
      new Set(Array.from(prevSet).concat(upsertUser.userId))
    ));
    setOtherUsers(prevUsers => [...prevUsers, upsertUser]);
  }, []);

  const handleCloseModal = useCallback(() => {
    setEditModal(false);
    setUserToEdit({});
  }, []);

  const handleUserStatusChange = useCallback(async () => {
    let userResponse = null;
    try {
      if (userToChangeStatus.status === "Active") {
        userResponse = (
          await UserService.deactivateUser(userToChangeStatus.userId)
        );
      } else {
        userResponse = (
          await UserService.reactivateUser(userToChangeStatus.userId)
        );
      }
    } catch (error) {
      console.error(error);
    }
    return handleUserUpsert(userResponse.payload);
  }, [handleUserUpsert, userToChangeStatus]);

  if (!currentUser) {
    return (
      <Loader />
    );
  }
  return (
    <>
      <div className={classes.adminContentWrapper}>
        <GridContainer
          columnSpacing={6}
          rowSpacing={2}
          marginTop={-6}
          alignItems="start"
          hasVerticalSpacing
        >
          <GridItem xs={12} xl={7}>
            {!!emailSentMessage && (
              <Alert
                onClose={() => setEmailSentMessage("")}
                severity="info"
                classes={{
                  root: classes.alert,
                  message: classes.alertMessage
                }}
                data-cy="alert-emailSent"
              >
                {emailSentMessage}
              </Alert>
            )}
            <div className={classes.userMgmtWrapper}>
              <div className={classes.tableTitleSection}>
                <H3
                  className={classes.tableTitle}
                  startIcon={<PeopleIcon className={classes.peopleIcon} />}
                >
                  All Users
                </H3>
              </div>
              <div>
                <SortableTable
                  headers={TABLE_HEADERS}
                  data={tableData}
                  defaultSortBy="name"
                  emptyTableValue="No Users"
                  className={classes.table}
                  pagination
                  denseHeader
                  denseBody
                />
              </div>
              <div>
                <ButtonDefault
                  className={classes.addItem}
                  background="primary"
                  startIcon={(
                    <AddCircleOutline fontSize="small" />
                  )}
                  onClick={openAddForm}
                >
                  &nbsp;Add User
                </ButtonDefault>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} xl={0} className={classes.dividerGridItem}>
            <Divider />
          </GridItem>
          <GridItem xs={12} xl={5}>
            <UserProfile
              user={currentUser}
              roles={roles}
              openEditForm={openEditForm}
            />
          </GridItem>
        </GridContainer>
      </div>
      <CustomModal
        open={editModal}
        onClose={handleCloseModal}
      >
        <UserMgmtForm
          mode={formMode}
          userToEdit={userToEdit}
          roles={roles}
          onUserCreate={handleUserCreate}
          onUserUpdate={handleUserUpsert}
          setEmailSentMessage={setEmailSentMessage}
          editFormType={editFormType}
          onClose={handleCloseModal}
        />
      </CustomModal>
      <ConfirmationDialog
        open={confirmModal}
        confirm={() => {
          setConfirmModal(!confirmModal);
          handleUserStatusChange();
        }}
        onClose={() => setConfirmModal(false)}
        prompt={
          "Are you sure you want to " +
          (userToChangeStatus?.status === "Active" ? "deactivate" : "reactivate") +
          ` ${userToChangeStatus?.firstName} ${userToChangeStatus?.lastName}?`
        }
        continueAndCancel
      />
      <ConfirmationDialog
        open={openDialog}
        confirm={sendResetEmail}
        onClose={() => setOpenDialog(false)}
        prompt={
          <span>
            An email will be sent to <em>{resetPasswordUser.email}&nbsp;</em>
            with a link to&nbsp;
            {resetPasswordUser.mfaEnabled === 0 ?
              "set up their account." :
              "reset their password."}
          </span>
        }
        continueAndCancel
      />
    </>
  );
};

export default UserMgmtCentral;
