import React from "react";
import { makeStyles } from "mui-styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  blueCircle: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.badge.fontSize,
    height: 17,
    width: 17,
    "&:hover": {
      cursor: "pointer",
    },
  },
  disabledCircle: {
    color: theme.palette.grey.disabled,
  },
  wrap: {
    position: "relative",
    backgroundColor: "transparent",
    padding: 0,
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    minWidth: "unset",
    "&:active": {
      backgroundColor: "transparent",
    },
    "&:hover": {
      backgroundColor: "white",
    },

  },
  text: {
    position: "absolute",
    color: theme.palette.primary.main,
    opacity: "0",
    fontSize: theme.typography.badge.fontSize,
    fontWeight: theme.typography.badge.fontWeight,
    lineHeight: theme.typography.badge.lineHeight,
    backgroundColor: "transparent",
    textAlign: "center",
    paddingTop: 5,
    paddingBottom: 5,
    whiteSpace: "nowrap",
    "&:hover": {
      opacity: "1",
      backgroundColor: theme.palette.grey.light,
      cursor: "pointer"
    },
  },
}));

export const AddIcon = (props) => {
  const classes = useStyles();

  if (props.variant === "hoverText") {

    return (
      <div
        className={classes.wrap}
        onClick={props.onClick}
        data-cy={props.test ? `addIcon-hoverText-${props.test}` : `addIcon-hoverText`}
      >
        <span>
          <AddCircleOutlineIcon
            className={
              classNames(
                classes.blueCircle,
                props.disabled && classes.disabledCircle
              )
            }
            onClick={!props.disabled && props.onClick}
          />

        </span>
        <span className={classes.text}>
          {props.children}
        </span>

      </div>
    );

  }

  return (
    <AddCircleOutlineIcon
      className={classNames(classes.blueCircle, props.disabled && classes.disabledCircle)}
      onClick={!props.disabled && props.onClick}
    />
  );
};

export default AddIcon;
