import React, { useCallback, useMemo } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
} from "@mui/material";
import { makeStyles } from "mui-styles";
import CheckboxV2 from "components/utils/form-elements/checkboxV2.component";
import { contentTypeFilterOptions } from "components/search/constants/search.constants";
import CustomLink from "components/utils/link.component";
import { ExpandMore } from "@mui/icons-material";
import { H5 } from "components/utils/headerV2.component";

const useStyles = makeStyles((theme) => ({
  verticalDivider: {
    height: 18,
  },
  disableLink: {
    color: theme.palette.text.secondary,
    fontWeight: "bold",
    alignItems: "center",
    fontSize: theme.typography.body2.fontSize,
  },
  accordion: {
    marginBottom: 8,
  },
  accordionDetails: {
    flexDirection: "column",
  },
  checkBox: {
    marginTop: -2,
    marginBottom: -2,
  },
}));

const makeInitialCategoryFilters = () => {
  return contentTypeFilterOptions.reduce((accum, option) => {
    accum[option.name] = true;
    return accum;
  }, {});
};

const makeClearedCategoryFilters = () => {
  return contentTypeFilterOptions.reduce((accum, option) => {
    accum[option.name] = false;
    return accum;
  }, {});
};

const makeInitialProgramFilters = (programOptions) => {
  return programOptions.reduce((accum, option) => {
    const stringName = option.name;
    accum[stringName] = true;
    return accum;
  }, {});
};

const makeClearedProgramFilters = (programOptions) => {
  return programOptions.reduce((accum, option) => {
    accum[option.name] = false;
    return accum;
  }, {});
};

const hasMultipleActivePrograms = (programFilterObj) => {
  return Object.keys(programFilterObj).length > 1;
};

const makeFilterCheckedStats = (filterObj) => {
  const filterValues = Object.values(filterObj);
  const checkedCount = filterValues.reduce(
    (accum, parameter) => (parameter ? accum + 1 : accum),
    0
  );
  return {
    checkedCount,
    total: filterValues.length,
    allChecked: checkedCount === filterValues.length,
  };
};

const SearchFilter = (props) => {
  const classes = useStyles();
  const {
    categoryFilters,
    setCategoryFilters,
    setProgramFilters,
    programFilters,
    programFilterOptions,
    error,
  } = props;

  // CATEGORY FILTERS
  const checkAllContentTypeClick = useCallback(() => {
    setCategoryFilters(makeInitialCategoryFilters());
  }, [setCategoryFilters]);

  const clearAllContentTypeClick = useCallback(() => {
    setCategoryFilters(makeClearedCategoryFilters());
  }, [setCategoryFilters]);

  const filterCategoryHandler = useCallback(
    (event) => {
      const { name } = event.target;
      setCategoryFilters((prev) => ({ ...prev, [name]: !prev[name] }));
    },
    [setCategoryFilters]
  );

  const categoriesFiltersStats = useMemo(
    () => makeFilterCheckedStats(categoryFilters),
    [categoryFilters]
  );

  // PROGRAM FILTERS
  const checkAllProgramsClick = useCallback(() => {
    setProgramFilters(makeInitialProgramFilters(programFilterOptions));
  }, [setProgramFilters, programFilterOptions]);

  const clearAllProgramsClick = useCallback(() => {
    setProgramFilters(makeClearedProgramFilters(programFilterOptions));
  }, [setProgramFilters, programFilterOptions]);

  const filterProgramHandler = useCallback(
    (event) => {
      const { name } = event.target;
      setProgramFilters((prev) => ({ ...prev, [name]: !prev[name] }));
    },
    [setProgramFilters]
  );

  const programsFilterStats = useMemo(
    () => makeFilterCheckedStats(programFilters),
    [programFilters]
  );

  return (
    <Box marginRight={2} marginBottom={4}>
      <Accordion className={classes.accordion} defaultExpanded>
        <FilterAccordionSummary
          label="Categories"
          filterStats={categoriesFiltersStats}
        />
        <Divider />
        <AccordionDetails className={classes.accordionDetails}>
          <Box
            display="flex"
            justifyContent="space-around"
            marginTop={1}
            marginBottom={1}
          >
            <GroupSelectLink
              disabled={error || categoriesFiltersStats.allChecked}
              onClick={checkAllContentTypeClick}
            >
              Select All
            </GroupSelectLink>
            <Divider
              orientation="vertical"
              className={classes.verticalDivider}
            />

            <GroupSelectLink
              disabled={error || !categoriesFiltersStats.checkedCount}
              onClick={clearAllContentTypeClick}
            >
              Clear All
            </GroupSelectLink>
          </Box>
          <Divider />
          <Box marginTop={2} display="flex" flexDirection="column">
            {contentTypeFilterOptions.map((filterOption) => {
              const isChecked = !!categoryFilters[filterOption.name];
              return (
                <CheckboxV2
                  size="small"
                  checked={isChecked}
                  name={filterOption.name}
                  label={filterOption.label}
                  onChange={filterCategoryHandler}
                  className={classes.checkBox}
                  key={filterOption.name}
                  disabled={error}
                  readOnly={false}
                />
              );
            })}
          </Box>
        </AccordionDetails>
      </Accordion>

      {!!programFilters && hasMultipleActivePrograms(programFilters) && (
        <Accordion className={classes.accordion} defaultExpanded>
          <FilterAccordionSummary
            label="Programs"
            filterStats={programsFilterStats}
          />
          <Divider />
          <AccordionDetails className={classes.accordionDetails}>
            <Box
              display="flex"
              justifyContent="space-around"
              marginTop={1}
              marginBottom={1}
            >
              <GroupSelectLink
                disabled={error || programsFilterStats.allChecked}
                onClick={checkAllProgramsClick}
              >
                Select All
              </GroupSelectLink>
              <Divider
                orientation="vertical"
                className={classes.verticalDivider}
              />
              <GroupSelectLink
                disabled={error || !programsFilterStats.checkedCount}
                onClick={clearAllProgramsClick}
              >
                Clear All
              </GroupSelectLink>
            </Box>
            <Divider />
            <Box marginTop={2} display="flex" flexDirection="column">
              {programFilterOptions.map((filterOption) => {
                const isChecked = !!programFilters[filterOption.name];
                return (
                  <div key={filterOption.name}>
                    <CheckboxV2
                      size="small"
                      checked={isChecked}
                      name={filterOption.name}
                      label={filterOption.label}
                      onChange={filterProgramHandler}
                      className={classes.checkBox}
                      disabled={error}
                      readOnly={false}
                    />
                    {filterOption.name === 8 && <Divider />}
                  </div>
                );
              })}
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
};

const FilterAccordionSummary = ({ label, filterStats }) => {
  return (
    <AccordionSummary expandIcon={<ExpandMore />}>
      <Box display="flex" justifyContent="space-between" width="100%">
        <H5 color="tertiary">{label}</H5>
        <div>{`${filterStats.checkedCount}/${filterStats.total}`}</div>
      </Box>
    </AccordionSummary>
  );
};

const GroupSelectLink = ({ disabled, onClick, children }) => {
  const classes = useStyles();
  return disabled ? (
    <div className={classes.disableLink}>{children}</div>
  ) : (
    <CustomLink onClick={onClick}>{children}</CustomLink>
  );
};

export default SearchFilter;
