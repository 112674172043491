import { Box, Paper } from "@mui/material";
import classNames from "classnames";
import Tooltip from "components/utils/tooltip.component";
import { makeStyles } from "mui-styles";
import { useMemo } from "react";

const CONNECTOR_X_MIN_WIDTH = 30;
const SPACING_LOW = "low"

const useStyles = makeStyles((theme) => ({
  connectorBase: {
  },
  connectorGridItem: {
    height: "100%",
    display: "flex",
    justifyContent: "stretch",
    width: "100%",
    alignItems: "center",
    position: "relative"
  },
  connectorSpacingItem: {
    visibility: "hidden"
  },
  icon: {
  },
  label: {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.lineHeight.proportional2,
    padding: "16px 8px"
  },
  // gridTemplateColumns set dynamically by inline styles
  grid: {
    display: "grid",
    gridTemplateRows: "auto max-content",
    alignItems: "center",
    height: "inherit",
    width: "100%",
    fontWeight: theme.typography.body2.fontWeight,
  },
  paper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px"
  },
  paperWithTooltip: {
    cursor: "default"
  },
  itemSubsection: {
    marginTop: "auto",
  },
}));


export default function FlowchartItem(props) {
  const classes = useStyles();
  const {
    children,
    classes: classesProp = {},
    connectors,
    IconComponent,
    item,
    tooltipPlacement
  } = props;
  const { label, tooltip } = item;

  const minWidth = useMemo(() => {
    let output = 150;
    if (IconComponent) {
      output = output + 40;
    }
    if (!connectors?.left) {
      output = output + CONNECTOR_X_MIN_WIDTH;
    }
    if (!connectors?.right) {
      output = output + CONNECTOR_X_MIN_WIDTH;
    }
    return output;
  }, [connectors, IconComponent]);

  const gridTemplateColumns = useMemo(() => {
    let template = `minmax(${minWidth}px, 4fr)`;
    if (connectors?.left) {
      template = `minmax(${CONNECTOR_X_MIN_WIDTH}px, 1fr) ${template}`;
    }
    if (connectors?.right) {
      template = `${template} minmax(${CONNECTOR_X_MIN_WIDTH}px, 1fr)`;
    }
    return template;
  }, [connectors, minWidth]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      flex="1"
      height="100%"
      position="relative"
      zIndex={1}
    >
      <Box
        className={classes.grid}
        gridTemplateColumns={gridTemplateColumns}
      >
        {!!connectors?.left && (
          <div className={classes.connectorGridItem}>
            {connectors?.left}
          </div>
        )}
        <Tooltip title={tooltip} placement={tooltipPlacement}>
          <Paper
            className={
              classNames(
                classes.paper,
                classes.connectorBase,
                classesProp.paper,
                tooltip && classes.paperWithTooltip
              )
            }
            elevation={1}
            spacing={SPACING_LOW}
          >
            {!!IconComponent && (
              <IconComponent
                className={classNames(classes.icon, classesProp.icon)}
              />
            )}
            <span className={classNames(classes.label, classesProp.label)}>
              {label}
            </span>
          </Paper>
        </Tooltip>
        {!!connectors?.right && (
          <div className={classes.connectorGridItem}>
            {connectors?.right}
          </div>
        )}
        <>
          {connectors?.bottomLeft ? (
            <div className={classes.connectorGridItem}>
              {connectors.bottomLeft}
            </div>
          ) : (
            !!connectors?.left && (
              <div className={classes.connectorSpacingItem}>
                {connectors.left}
              </div>
            )
          )}
          <div
            className={
              classNames(classes.itemSubsection, classes.connectorBase)
            }
          >
            {children}
          </div>
          {connectors?.bottomRight ? (
            <div className={classes.connectorGridItem}>
              {connectors.bottomRight}
            </div>
          ) : (
            !!connectors?.right && (
              <div className={classes.connectorSpacingItem}>
                {connectors.right}
              </div>
            )
          )}
        </>
      </Box>
    </Box>
  );
}
