import moment from "moment";
import { makeStyles } from "mui-styles";
import { useMemo } from "react";
import { formatCash, formatDisplayNumber } from "utils/localization.utils";
import { CLAIM_VALUE_TYPE_NAMES, DATA_TYPE_CASH, DATA_TYPE_NUMBER, DATA_TYPE_DATE } from "components/constants/claim.constants";
import LabelValuePair from "components/utils/labelValuePair.component";
import { DATE_FORMAT_DISPLAY_TEXT_LONG } from "constants/date.constants";

const useStyles = makeStyles((theme) => ({
  value: {
    overflowWrap: "anywhere"
  }
}))


const ClaimLabelValuePair = ({ claim, valueType }) => {
  const classes = useStyles();
  const value = claim._derivedData.ClaimData[valueType.name]
  const label = valueType.label;
  const formattedValue = useMemo(() => {
    if (CLAIM_VALUE_TYPE_NAMES.MAILED_DOCS === valueType.name) {
      return value === 0 ? "No" : "Yes"
    } else if (DATA_TYPE_DATE === valueType.dataType) {
      return moment(value).format(DATE_FORMAT_DISPLAY_TEXT_LONG)
    } else if (DATA_TYPE_NUMBER === valueType.dataType) {
      return formatDisplayNumber(value);
    } else if (DATA_TYPE_CASH === valueType.dataType) {
      return formatCash(value);
    } else {
      return value;
    }
  }, [value, valueType.name, valueType.dataType]);

  const isStacked = useMemo(() => {
    const stackedTypes = [
      CLAIM_VALUE_TYPE_NAMES.ACTUAL_COST_DESC,
      CLAIM_VALUE_TYPE_NAMES.NARRATIVE,
    ]
    return stackedTypes.includes(valueType.name)
  }, [valueType.name])
  return (
    <LabelValuePair
      label={label}
      value={<div className={classes.value}>{formattedValue}</div>}
      stacked={!!isStacked}
    />
  )
}

export default ClaimLabelValuePair;
