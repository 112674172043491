import AjaxService from "services/base/Ajax.service.js";

class EvaluationServiceBase extends AjaxService {
  shouldExtractResponseData() {
    return true;
  }

  async getEvaluationTemplates() {
    return this.get(`/evaluation/evaluationTemplate`);
  }

  async upsertEvaluationDC(evaluationData) {
    return this.post(
      "/evaluation/evaluationDC/upsert",
      { evaluationDC: evaluationData }
    );
  }
}

const EvaluationService = new EvaluationServiceBase();
export default EvaluationService;