import { builderSectionTypes } from "components/builder/constants/builder.constants";

export const reOrderedSectionsWithNewSectionPolicy = (sections, payload) => {
  const refSection = sections.find(
    section => section.Type === builderSectionTypes.REFERENCE
  );
  const refTableSection = sections.find(
    section => section.Type === builderSectionTypes.REFERENCE_TABLE
  );

  const existingDefaultSectionsWithUpdatedOrder = sections
    .filter(section => !section.Type)
    .map((item) => {
      return {
        ...item,
        Section_Order: item.Section_Order >= payload.Section_Order ?
          item.Section_Order + 1 :
          item.Section_Order,
      };
    });

  const orderedDefaultSections = [
    ...existingDefaultSectionsWithUpdatedOrder,
    payload,
  ].sort((s1, s2) => s1.Section_Order - s2.Section_Order);

  let allSections = [...orderedDefaultSections];
  if (refSection) {
    allSections = [...allSections, refSection, refTableSection];
  }

  const updatedSections = allSections.map((section, index) => {
    return { ...section, Section_Order: index + 1 };
  });
  return updatedSections;
};

export const deletePolicyBuilderSection = (sections, payload) => {
  let newSections = sections.filter(
    section => section._meta.clientId !== payload._meta.clientId
  );

  if (payload.Type === builderSectionTypes.REFERENCE_TABLE) {
    newSections = newSections.filter(
      section => section.Type !== builderSectionTypes.REFERENCE
    );
  }

  newSections.sort((s1, s2) => s1.Section_Order - s2.Section_Order);
  const reOrderedSections = newSections.map((section, index) => {
    return { ...section, Section_Order: index + 1 };
  });
  return reOrderedSections;
};
