import { CheckCircleOutline, PendingActionsOutlined, HourglassTopOutlined, NotInterestedOutlined, BlockOutlined } from "@mui/icons-material";
import classNames from "classnames";
import FlowchartItem from "components/shared/flowchart/shared/FlowchartItem.component";
import { makeStyles } from "mui-styles";
import { useMemo } from "react";


const useStyles = makeStyles((theme) => ({
  disconnectedContent: {
    color: theme.palette.error.main,
    fill: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightRegular
  },
  paperSuccess: {
    color: theme.palette.success.dark,
    fill: theme.palette.success.dark,
  },
  paperPending: {
    fontWeight: theme.typography.body2.fontWeight
  },
  paperCurrent: {
    color: theme.palette.secondary.dark,
    fill: theme.palette.secondary.dark,
  },
  paperNotAppplicable: {
    opacity: theme.opacity.menuItemDisabled
  },
  status: {
    height: 25,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export default function FlowchartStatusItem(props) {
  const classes = useStyles();

  const { connectors, disconnected, item, tooltipPlacement } = props;
  const { status, isCurrentNode, isSkipped } = item;

  const paperClass = useMemo(() => {
    if (disconnected) {
      return classes.disconnectedContent;
    }
    let itemClass = classes.paperPending;
    if (isCurrentNode) {
      itemClass = classes.paperCurrent;
    } else if (status) {
      itemClass = classes.paperSuccess;
    } else if (isSkipped) {
      itemClass = classes.paperNotAppplicable;
    }
    return itemClass;
  }, [isCurrentNode, isSkipped, disconnected, status, classes]);

  const FlowItemIcon = useMemo(() => {
    if (disconnected) {
      return NotInterestedOutlined
    } else if (isCurrentNode) {
      return HourglassTopOutlined;
    } else if (isSkipped) {
      return BlockOutlined;
    } else if (status) {
      return CheckCircleOutline;
    }
    return PendingActionsOutlined;
  }, [isCurrentNode, isSkipped, status, disconnected]);

  return (
    <FlowchartItem
      classes={{
        icon: classNames(paperClass),
        label: classNames(paperClass),
      }}
      connectors={connectors}
      item={item}
      IconComponent={FlowItemIcon}
      tooltipPlacement={tooltipPlacement}
    >
      <div className={classes.status}>
        <p>
          {isSkipped ? "Not Applicable" : status || " "}
        </p>
      </div>
    </FlowchartItem>
  );
}
