
import ComsCard from "components/utils/cards/comsCard.component";
import { H3,H5 } from "components/utils/headerV2.component";
import { DATE_FORMAT_DISPLAY_NUMERIC } from "constants/date.constants";
import moment from "moment";
import { useCallback, useMemo } from "react";
import DocumentService from "services/Document.service";
import { downloadFileBlob } from "utils/download.utils";
import ClaimLabelValuePair from "./ClaimLabelValuePair.component";
import { Box } from "@mui/material";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import { CLAIM_VALUE_TYPE_NAMES } from "components/constants/claim.constants";
import ClaimDocumentTable from "./documents/claimDocumentTable.component";

const SubmittedClaimCard = (props) => {
  const {
    claim,
    stateDocuments,
    claimValueTypesByName,
    dispatch,
  } = props;

  const formattedSubmittedDate =
    moment(claim?.submittedDate)?.format(DATE_FORMAT_DISPLAY_NUMERIC)
  const documents = useMemo(() => (
    stateDocuments?.filter?.((document) => (document.claimId === claim.claimId))
  ), [claim.claimId, stateDocuments])

  const handleDownloadDocument = useCallback(async document => {
    const blob = await DocumentService.downloadDocumentFile(
      document.documentId,
      document.fileRef
    );
    return downloadFileBlob(blob, document.fileRef);
  }, []);

  const valueTypes = useMemo(() => {
    return Object.keys(claim._derivedData.ClaimData)
      .map((key) => (claimValueTypesByName[key]))
      .sort((item1, item2) => (item1.order - item2.order))
  }, [claim, claimValueTypesByName])

  return (
    <ComsCard>
      <Box paddingBottom={2}>
        <Box paddingBottom={1}>
          <H3>
            Claim Submitted {formattedSubmittedDate}
          </H3>
        </Box>
        <GridContainer spacing={2} hasVerticalSpacing>
          <GridItem xs={12} sm={12}>
            <GridContainer spacing={2}>
              {valueTypes.map((valueType) => {
                const smGridWidth = (
                  valueType.name === CLAIM_VALUE_TYPE_NAMES.NARRATIVE
                ) || (valueType.name === CLAIM_VALUE_TYPE_NAMES.ACTUAL_COST_DESC) ?
                  12 : 6;
                const xlGridWidth = (
                  valueType.name === CLAIM_VALUE_TYPE_NAMES.NARRATIVE
                ) ?
                  6 : 4;
                return (
                  <GridItem
                    xs={12}
                    sm={smGridWidth}
                    xl={xlGridWidth}
                    key={valueType.name}
                  >
                    <ClaimLabelValuePair
                      claim={claim}
                      valueType={valueType}
                    />
                  </GridItem>
                )
              })}
            </GridContainer>
          </GridItem>
        </GridContainer>
        <H5 color="tertiary">Evidence Submitted</H5>
        <ClaimDocumentTable
          attachmentDocs={documents}
          handleDownloadDocument={handleDownloadDocument}
          claim={claim}
          dispatch={dispatch}
        />
      </Box>
    </ComsCard>
  )
}

export default SubmittedClaimCard;