import { Box } from "@mui/material";

export default function ValueCellArrayItem({ value, index }) {
  if (!value) {
    return null;
  }
  return (
    <Box marginTop={index === 0 ? 0 : 0.5}>
      {value}
    </Box>
  );
}
