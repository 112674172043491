import React, { useContext, useMemo, useState } from "react";
import { makeStyles } from "mui-styles";
import { MenuItem, Grid } from "@mui/material";
import CustomSelect from "components/utils/form-elements/select.component";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import WorkpackageService from "services/Workpackage.service";
import TaskService from "services/Task.service";
import BuilderService from "services/Builder.service";
import FormBanner from "components/utils/form-elements/formBanner.component";
import Checkbox from "components/utils/form-elements/checkboxDeprecated.component";
import {
  ACTION_SET_PLAN_TASKS,
  ACTION_SET_PLAN_WORKPACKAGES,
} from "reducers/shared/builder/builder.reducer";
import {
  attachMetaToLoadedTasks,
  makeWpClientId,
} from "reducers/shared/builder/utils/planBuilder.utils";
import { PROGRAM_ID_ORCHESTRATION } from "components/constants/program.constants";
import { truncate } from "components/shared/workpackages/utils/workpackage.utils";
import { taskStatuses } from "components/shared/tasks/constants/task.constants";
import { ACTION_REPLACE_TASKS, ACTION_REPLACE_WORKPACKAGES } from "reducers/global/workpackageTasks.reducer";
import WorkpackageTasksContext from "contexts/workpackageTasks.context";
import OrganizationContext from "contexts/organization.context";
import { sortByStringKey } from "utils/sort.utils";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: 500,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: 20,
  },
  contentContainer: {
    padding: "30px 20px 0px 20px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  selectsContainer: {
    paddingTop: 10,
    alignSelf: "center",
    width: 300,
    overflowX: "hidden",
  },
  checkContainer: {
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
  },
  checkWrapper: {
    width: "fit-content",
    margin: "0px auto",
    paddingBottom: 20,
  },
  select: {
    marginBottom: 25,
    width: "100%",
  },
}));

const isHome = (program) => {
  return program.id === PROGRAM_ID_ORCHESTRATION
}

const CreateOrExistingWpForm = (props) => {
  const classes = useStyles();
  const { dispatch: wpTaskDispatch } = useContext(WorkpackageTasksContext);
  const [mode, setMode] = useState("none");
  const [programs, setPrograms] = useState([]);
  const [chosenProgram, setChosenProgram] = useState({ id: "", name: "" });
  const [wps, setWps] = useState([]);
  const [chosenWp, setChosenWp] = useState({ id: "", name: "" });
  const { dispatch, existingWorkpackageIdsInPlan } = props;
  const { state: organizationState } = useContext(OrganizationContext);

  const handleCheck = (event) => {
    if (event.target.checked === true) {
      setMode(event.target.name);
    } else {
      setMode("none");
    }

    if (event.target.name === "existing") {
      BuilderService.getWpPrograms(
        organizationState.activeOrganizationId
      ).then((res) => {
        const progs = res.payload.map((program) => {
          return {
            id: program.Program_ID,
            name: program.Name,
          };
        });
        setPrograms(progs);
      });
    } else {
      setChosenProgram({ id: "", name: "" });
    }
  };

  const handleChange = async event => {
    if (event.target.name === "program") {
      setChosenProgram({
        id: event.target.value,
        name: event.currentTarget.id
      });
      setChosenWp({ id: "", Name: "" });
      const response = (
        await WorkpackageService.getWorkpackageByProgramId(event.target.value)
      );
      const packages = response.payload
        .map(wp => ({
          Workpackage_ID: wp.Workpackage_ID,
          id: wp.Workpackage_ID.toString(),
          Name: wp.Name,
          Program_Program_ID: wp.Program_Program_ID,
          Description: wp.Description,
          ProgramComponents_Component_ID: wp.ProgramComponents_Component_ID,
        }))
        .filter(wp => (
          !existingWorkpackageIdsInPlan?.includes?.(wp.Workpackage_ID)
        ));
      setWps(packages);
      wpTaskDispatch({
        type: ACTION_REPLACE_WORKPACKAGES,
        payload: response.payload
      });
    } else {
      setChosenWp({ id: event.target.value, name: event.currentTarget.id });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const wpCopy = [...props.workpackages];
    // adjust order only if not first added wp
    if (wpCopy.length > 0) {
      wpCopy
        .filter((i) => i._meta.wp_order >= props.addWpSection + 1)
        .forEach((item) => {
          item._meta.wp_order = item._meta.wp_order + 1;
        });
    }
    if (mode === "create") {
      const newTempId = makeWpClientId();

      const newWp = {
        Workpackage_ID: null,
        Name: null,
        Description: null,
        Program_Program_ID: props.builderProgramId,
        _meta: {
          clientId: newTempId,
          wp_order: props.addWpSection + 1,
        },
      };

      const orderedWp = [...wpCopy, newWp].sort(
        (a, b) => a._meta.wp_order - b._meta.wp_order
      );

      dispatch({
        type: ACTION_SET_PLAN_WORKPACKAGES,
        payload: orderedWp,
      });
    } else {
      const selectedWp = wps.find(
        (pack) => pack.Workpackage_ID === chosenWp.id
      );

      const newWp = {
        ...selectedWp,
        ProgramComponents_Component_ID:
          selectedWp.ProgramComponents_Component_ID,
        _meta: {
          clientId: selectedWp.Workpackage_ID,
          wp_order: props.addWpSection + 1,
        },
      };

      const orderedWp = [...wpCopy, newWp].sort(
        (a, b) => a._meta.wp_order - b._meta.wp_order
      );

      dispatch({
        type: ACTION_SET_PLAN_WORKPACKAGES,
        payload: orderedWp,
      });
      // loadTasks
      TaskService.getWPTasksByworkpackageId(chosenWp.id).then((res) => {
        const openTasks = res.payload.filter(task => (
          task.Status !== taskStatuses.CLOSED
        ));
        const tasksWithTempID = attachMetaToLoadedTasks(openTasks);
        const combinedTasks = [...props.tasks, ...tasksWithTempID];

        dispatch({
          type: ACTION_SET_PLAN_TASKS,
          payload: combinedTasks,
        });
        wpTaskDispatch({
          type: ACTION_REPLACE_TASKS,
          payload: res.payload
        });
      });
    }
    props.setWpModal(false);
    clearForm();
  };

  const clearForm = () => {
    setChosenWp({ id: "", name: "" });
    setChosenProgram({ id: "", name: "" });
    setMode("");
  };

  const enterpriseProgramOption = useMemo(() => {
    return programs.find(isHome);
  }, [programs]);

  return (
    <Grid className={classes.formContainer}>
      <FormBanner>Add Workpackage</FormBanner>
      <div className={classes.contentContainer}>
        <div className={classes.checkWrapper}>
          <div className={classes.checkContainer}>
            <Checkbox
              checked={mode === "create"}
              onChange={(event) => handleCheck(event)}
              name="create"
              label="Create New Workpackage"
              test="CreateNewWP"
            />
            <Checkbox
              checked={mode === "existing"}
              onChange={(event) => handleCheck(event)}
              name="existing"
              label="Add an Existing Workpackage"
              test="AddExistingWP"
            />
          </div>
        </div>
        {mode === "existing" && programs.length > 0 && (
          <div className={classes.selectsContainer}>
            <CustomSelect
              className={classes.select}
              label="Select Program"
              value={chosenProgram?.id || ""}
              onChange={handleChange}
              name="program"
              test="program"
            >
              {!!enterpriseProgramOption && (
                <MenuItem
                  value={enterpriseProgramOption.id}
                  key={enterpriseProgramOption.name}
                  id={enterpriseProgramOption.name}
                  data-cy={`menu-item-${enterpriseProgramOption.name}`}
                >
                  Enterprise
                </MenuItem>
              )}
              {programs
                .filter((program) => !isHome(program))
                .map((item) => (
                  <MenuItem
                    value={item.id}
                    key={item.name}
                    id={item.name}
                    data-cy={`menu-item-${item.name}`}
                  >
                    {item.name}
                  </MenuItem>
                ))}
            </CustomSelect>

            <CustomSelect
              className={classes.select}
              label="Select Workpackage"
              disabled={wps.length === 0}
              value={chosenWp.id}
              onChange={(event) => handleChange(event)}
              name="wp"
              test="workpackage"
            >
              {sortByStringKey(wps, "Name").length > 0 &&
                wps.map((item) => (
                  <MenuItem
                    value={item.Workpackage_ID}
                    key={item.Workpackage_ID}
                    id={item.Workpackage_ID}
                    data-cy={`menu-item-${item.Name}`}
                  >
                    {truncate(item.Name || "")}
                  </MenuItem>
                ))}
            </CustomSelect>
          </div>
        )}
      </div>

      <DualFormButtons
        variant="addFramework"
        addText="Continue"
        cancelOnClick={() => {
          props.setWpModal(false);
          clearForm();
        }}
        saveOnClick={handleSubmit}
        disabled={
          (mode === "existing" && chosenWp.id === "") || mode === "none"
        }
      />
    </Grid>
  );
};

export default CreateOrExistingWpForm;
