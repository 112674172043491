import ButtonDefault from "components/utils/buttonDefault.component";
import { makeStyles } from "mui-styles";
import EditIcon from "@mui/icons-material/Edit";
import classNames from "classnames";
import { IconButton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  editIcon: {
    fontSize: theme.typography.h3.fontSize,
  },
  editButton: {
    minWidth: "unset",
  },
  editButtonPadding: {
    padding: 0
  },
  button: {
    color: theme.palette.secondary?.main,
    padding: 5,
  },
}));

const EditIconButton = ({
  target, onClick, testProp, variant, fontSize, disabled,
  disableReadOnlyUsers = true, noPadding
}) => {
  const classes = useStyles();
  if (variant === "iconButton") {
    return (
      <IconButton
        onClick={onClick}
        className={classes.button}
        data-cy={
          testProp ? `btn-edit-iconButton-${testProp}` : "btn-edit-iconButton"
        }
        disabled={disabled}
      >
        <EditIcon fontSize={fontSize || "small"} />
      </IconButton>
    );
  } else {
    return (
      <ButtonDefault
        className={classNames(classes.editButton, noPadding && classes.editButtonPadding)}
        variant="iconSmall"
        color="secondary"
        startIcon={<EditIcon className={classes.editIcon} />}
        component="label"
        onClick={onClick}
        data-cy={testProp ? `btn-edit-icon-${testProp}` : `btn-edit-icon`}
        disabled={disabled}
        disableReadOnlyUsers={disableReadOnlyUsers}
      >
        {`Edit ${target || ""}`}
      </ButtonDefault>
    );
  }
};

export default EditIconButton;
