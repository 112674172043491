import React, { useCallback, useMemo, useState } from "react";
import { Box, Grid } from "@mui/material";
import { makeStyles } from "mui-styles";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import FormBanner from "components/utils/form-elements/formBanner.component";
import LabelInput from "components/utils/form-elements/labelInput.component";
import DateInput, {
  TYPE_DATETIME,
} from "components/utils/form-elements/dateInput.component";
import Form from "components/utils/form-elements/form.component";
import ContactLogService from "services/ContactLog.service";
import ClaimIdentifiersDisplay from "components/claims/claimIdentifiersDisplay.copmonent";
import { ACTION_REPLACE_CONTACT_LOG } from "reducers/shared/claimReview.reducer";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 8,
  },
  content: {
    width: 600,
  },
  form: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingTop: 16,
    paddingBottom: 0,
  },
  inputWrapper: {
    paddingTop: 0,
    paddingBottom: 16,
  },
  dualButtons: {
    paddingBottom: 4,
  },
}));

export default function ContactLogForm(props) {
  const classes = useStyles();
  const { onClose, initialFormState = {}, claim, claimId, dispatch } = props;
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  // const [message, setMessage] = useState(""); todo: add later
  const isCreate = !initialFormState.contactDatetime;

  const disabledButton = useMemo(() => {
    return !isFormChanged || !!isSaving;
  }, [ isFormChanged, isSaving]);

  const handleSubmit = useCallback(async (body) => {
    try {
      setIsSaving(true);
      const response = await ContactLogService.upsertContactLog(body);

      dispatch({
        type: ACTION_REPLACE_CONTACT_LOG,
        payload: {
          contactLog: response.payload,
          claimId: response.payload?.[0]?.claimId
        }
      });
      setIsSaving(false);
      onClose();
    } catch (error) {
      setIsSaving(false);
      // setMessage(getUploadHttpErrorMessage(status));
      setIsSaving(false);
    }
  }, [onClose, dispatch]);

  return (
    <Grid className={classes.formContainer}>
      <FormBanner>
        {isCreate ? `Create Log Entry` : `Update Log Entry`}
      </FormBanner>
      <Box px={4} paddingTop={2} paddingBottom={4} className={classes.content}>
        <ClaimIdentifiersDisplay claim={claim} />
        <Form
          className={classes.form}
          onSubmit={handleSubmit}
          onChange={() => setIsFormChanged(true)}
        >
          <div className={classes.inputWrapper}>
            <LabelInput
              variant="default"
              label="Action"
              required
              defaultValue={initialFormState.action}
              name="action"
              margin="dense"
            />
          </div>
          <div className={classes.inputWrapper}>
            <DateInput
              type={TYPE_DATETIME}
              label="Action Date"
              defaultValue={initialFormState.contactDatetime}
              name="contactDatetime"
              required
            />
          </div>
          <div className={classes.inputWrapper}>
            <LabelInput
              variant="default"
              label="Notes"
              defaultValue={initialFormState.notes}
              name="notes"
              margin="dense"
              rows={3}
              multiline
            />
          </div>
          <input
            type="hidden"
            name="contactLogId"
            value={initialFormState.contactLogId}
          />
          <input type="hidden" name="claimId" value={claimId} />

          <DualFormButtons
            className={classes.dualButtons}
            cancelOnClick={onClose}
            disabled={disabledButton}
            isPublishing={isSaving}
            type="submit"
          />
        </Form>
      </Box>
    </Grid>
  );
}
