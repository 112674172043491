import { FormControl } from "@mui/material";
import LabelInput from "components/utils/form-elements/labelInput.component";
import TitledMultiFileUploadInput from "components/utils/form-elements/titledMultiFileUploadInput.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import RemoveIcon from "components/utils/removeIcon.component";
import { isDevelopmentEnv } from "core/environment";
import { useCallback, useMemo, useRef, useState } from "react";
import { HTTP_STATUS_CONTENT } from "services/constants/response.constants";
import FileTransferService from "services/FileTransfer.service";
import { downloadFileBlob } from "utils/download.utils";
const { makeStyles } = require("mui-styles");


const useStyles = makeStyles((theme) => ({
  itemFieldset: {
    width: "100%",
    padding: "8px 0 0 8px",
    "&:first-of-type": {
      paddingTop: 0
    }
  },
  itemNumber: {
    width: 16,
    marginRight: 8,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: "bold"
  },
  itemDelete: {
    height: 32,
    marginTop: 24,
    marginLeft: 4
  },
  labelField: {
    fontWeight: theme.typography.fontWeightBold
  },
  multiFileUpload: {
    marginTop: 8
  }
}));

const FIELD_NAME_DELIMITER = "|";


export default function KeyValueListItem(props) {
  const classes = useStyles();
  const valueInputRef = useRef();
  const { hasUploads, index, item, onChange, onRemove, primaryKey } = props;

  const [deletedAttachments, setDeletedAttachments] = useState([]);

  const clientId = useMemo(() => (
    item?.[primaryKey] || item?._meta?.clientId
  ), [item, primaryKey]);

  const fieldNamePrefix = useMemo(() => (
    item?.[primaryKey] ?
      `${item[primaryKey]}-savedItem` :
      item._meta?.clientId
  ), [item, primaryKey]);

  const uploadedAttachments = useMemo(() => (
    item?._associations?.FileTransferAttachment || []
  ), [item?._associations?.FileTransferAttachment]);

  const handleDeleteAttachment = useCallback(attachment => {
    setDeletedAttachments(prev => {
      return [...prev, attachment]
    });
  }, [setDeletedAttachments]);

  const handleUndoDeleteAttachment = useCallback(attachment => {
    setDeletedAttachments(prev => {
      const next = [...prev];
      const deleteIndex = next.findIndex(deletedAttachment => (
        deletedAttachment.name === attachment.name
      ));
      next.splice(deleteIndex, 1);
      return next;
    });
  }, [setDeletedAttachments]);

  const handleDownloadAttachment = useCallback(async file => {
    try {
      const blob = await FileTransferService.downloadFileTransferAttachment(
        file.fileTransferAttachmentId
      )
      return downloadFileBlob(blob, file.fileName);
    } catch (error) {
      if (isDevelopmentEnv && error?.response?.status === HTTP_STATUS_CONTENT) {
        alert(
          "DEVELOPMENT ERROR: File missing from shared cloud storage. " +
          "This file was likely deleted in another developer's local instance."
        );
      }
    }
  }, []);

  const getItemFieldName = useCallback(columnName => {
    return `${fieldNamePrefix}${FIELD_NAME_DELIMITER}${columnName}`;
  }, [fieldNamePrefix]);

  const handleLabelKeyDown = useCallback(event => {
    if (event.key === "Enter") {
      valueInputRef.current.focus();
      event.preventDefault();
    }
  }, []);

  const handleDeleteItem = useCallback(() => {
    onRemove(clientId)
  }, [clientId, onRemove]);

  const itemOrder = index + 1;

  return (
    <FormControl
      component="fieldset"
      name={`item-${clientId}`}
      className={classes.itemFieldset}
      onChange={onChange}
    >
      <GridContainer
        spacing={1}
        alignItems="center"
        marginTop={2}
      >
        <GridItem>
          <div className={classes.itemNumber}>{itemOrder}.</div>
        </GridItem>
        <GridItem flex={2} minWidth="200px">
          {/* <Autocomplete
            name={getItemFieldName("label")}
            label="Label"
            value={labelValue}
            options={options}
            onBlur={handleLabelBlur}
            onKeyDown={handleLabelKeyDown}
            getOptionLabel={(option) => option?.label || ""}
            renderTags={(value, getTagProps) => (
              value.map((option, optionIndex) => (
                <Chip
                  key={option.label}
                  variant="outlined"
                  label={option.label}
                  {...getTagProps({ index: optionIndex })}
                />
              ))
            )}
            autoFocus={!labelValue}
            clearOnBlur={false}
            blurOnSelect
            disableClearable
            freeSolo
            hideHelperText
          /> */}
          <LabelInput
            name={getItemFieldName("label")}
            placeholder="Label"
            defaultValue={item?.label}
            onKeyDown={handleLabelKeyDown}
            className={classes.labelField}
            margin="dense"
            variant="default"
            test={`${item?.[primaryKey]}-label`}
            inputRef={valueInputRef}
            hideHelperText
            multiline
          />
        </GridItem>
        <GridItem marginLeft={-0.5} marginRight={-0.5}>:&nbsp;</GridItem>
        <GridItem flex={3}>
          <LabelInput
            name={getItemFieldName("value")}
            placeholder="Value"
            defaultValue={item?.value}
            margin="dense"
            variant="default"
            test={`${item?.[primaryKey]}-value`}
            inputRef={valueInputRef}
            hideHelperText
            multiline
          />
        </GridItem>
        <GridItem>
          <RemoveIcon
            className={classes.itemDelete}
            onClick={handleDeleteItem}
            test={`order-${itemOrder}`}
          />
        </GridItem>
      </GridContainer>
      {!!hasUploads && (
        <GridContainer
          spacing={1}
          alignItems="center"
          marginTop={2}
        >
          <GridItem>
            <div className={classes.itemNumber}>&nbsp;</div>
          </GridItem>
          <GridItem xs paddingRight={4}>
            <TitledMultiFileUploadInput
              name="attachments"
              label="Attachments"
              className={classes.multiFileUpload}
              deletedFiles={deletedAttachments}
              uploadedFiles={uploadedAttachments}
              onDelete={handleDeleteAttachment}
              onDeleteUndo={handleUndoDeleteAttachment}
              onDownloadFile={handleDownloadAttachment}
            >
              Attach Files to Tag
            </TitledMultiFileUploadInput>
          </GridItem>
        </GridContainer>
      )}
      <input
        type="hidden"
        name={getItemFieldName(primaryKey)}
        value={item?.[primaryKey]}
      />
      <input
        type="hidden"
        name={getItemFieldName("displayOrder")}
        value={item?.displayOrder}
      />
    </FormControl>
  );
}
