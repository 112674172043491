import React, { useState, useEffect, useMemo, useContext } from "react";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import CustomModal from "components/utils/modal.component";
import PublishDocForm from "components/shared/forms/publishDocForm.component";
import { Prompt } from "react-router-dom";
import ProgramsContext from "contexts/programs.context";
import NotificationDialog from "components/utils/dialogs/notificationDialog.component";
import ConfirmationDialog from "components/utils/dialogs/confirmationDialog.component";

const BuilderButtonsColumn = ({
  state,
  dispatch,
  readOnly,
  sectionLess,
  submitNewBuilder,
  submitUpdatedBuilder,
  setOpenDialog,
  openDialog,
  setBuilderMode,
  componentObject,
  chosenProgram,
  associatedProgramId,
}) => {
  const {
    builderInfoErrors,
    builderInfo,
    isSaving,
    activeForm,
    statusMessage,
    tasks,
    workpackages,
    disableBuilder,
  } = state;
  const { state: programState } = useContext(ProgramsContext);
  const [openPublishDialog, setOpenPublishDialog] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);

  useEffect(() => {
    if (activeForm) {
      window.addEventListener("beforeunload", onUnload);
      return () => {
        window.removeEventListener("beforeunload", onUnload);
      };
    }
  }, [activeForm]);

  const onUnload = (event) => {
    // the method that will be used for both add and remove event
    event.preventDefault();
    event.returnValue = "";
  };

  const dialogOrForm = () => {
    return sectionLess ? setOpenPublishDialog(true) : setEditModal(true);
  };



  const disabledSaveButton = useMemo(() => {
    if (builderInfoErrors && Object.values(builderInfoErrors)?.length > 0) {
      return true;
    }
    if (tasks?.some((task) => task.Name && task.Name.trim() === "")) {
      return true;
    }
    if (workpackages?.some((wp) => wp.Name && wp.Name.trim() === "")) {
      return true;
    }
    return !activeForm || isSaving !== null
  },[activeForm, builderInfoErrors,isSaving, tasks, workpackages ]);

  const chosenComponentObject = useMemo(() => (
    programState.componentObjects?.[chosenProgram?.compObjId]
  ), [programState.componentObjects, chosenProgram]);

  return (
    <>
      <DualFormButtons
        variant="builder"
        disabled={disabledSaveButton || disableBuilder}
        disablePublish={disableBuilder}
        saveOnClick={
          builderInfo.Builder_ID ? submitUpdatedBuilder : submitNewBuilder
        }
        publishOnClick={() => (
          activeForm
            ? window.alert("Please save changes before publishing.")
            : dialogOrForm()
        )}
        cancelOnClick={
          activeForm ? () => setOpenDialog(true) : () => setBuilderMode()
        }
        isSaving={isSaving}
        isPublishing={isPublishing}
        builder={builderInfo}
        statusMessage={statusMessage}
      />

      <CustomModal open={editModal} onClose={() => setEditModal(false)}>
        <PublishDocForm
          setEditModal={setEditModal}
          componentObject={componentObject}
          builderInfo={builderInfo}
          isPublishing={isPublishing}
          setIsPublishing={setIsPublishing}
          dispatch={dispatch}
          chosenComponentObj={chosenComponentObject}
          associatedProgramId={associatedProgramId}
        />
      </CustomModal>
      <Prompt
        when={!!activeForm && !readOnly}
        message={() => `Are you sure you want to leave without saving?`}
      />
      <ConfirmationDialog
        open={openDialog}
        confirm={() => setBuilderMode()}
        onClose={() => setOpenDialog(false)}
        prompt="Are you sure you would like to leave without saving?"
      />

      <NotificationDialog
        open={openPublishDialog}
        onClose={() => setOpenPublishDialog(false)}
        prompt="Document does not contain sections. Please add sections in order to publish the report."
      />
    </>
  );
};

export default BuilderButtonsColumn;
