import { Box } from "@mui/material";
import { PATH_PROGRAM_COMPONENT } from "components/layout/constants/standardPaths.constants";
import { ID_URL_DISTRIBUTION_HASH_SCROLL_TARGET, ID_URL_HASH_SCROLL_TARGET, URL_SEARCH_PARAM_NODE_ID } from "components/pages/consentOrder/constants/consentOrderPage.constants";
import LinearFlowchartMultiLine from "components/shared/flowchart/LinearFlowchartMultiLine.component";
import FlowchartLinkMultiRowItem from "components/shared/flowchart/shared/FlowchartLinkMultiRowItem.component";
import Loader from "components/utils/loader.components";
import ProgramsContext from "contexts/programs.context";
import StatisticsContext from "contexts/statistics.context";
import WorkflowDiagramContext from "contexts/workflowDiagram.context";
import { useCallback, useContext, useMemo, useRef } from "react";
import { generatePath, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { mapObjectArrayByKey } from "utils/arrayOfObjects.utils";
import { formatDisplayNumber } from "utils/localization.utils";
import { pluralize } from "utils/string.utils";

const FLOWCHART_COLUMNS_BREAKPOINTS = { xs: 1, md: 3, lg: 4, xl: 5 };


export default function WorkflowsPageDiagram() {
  const { programId } = useParams();
  const { state } = useContext(ProgramsContext);
  const { state: { summaryCounts }} = useContext(StatisticsContext);
  const {
    nodeChain,
    workflowTemplate,
    nodeOrphanSet
  } = useContext(WorkflowDiagramContext);
  const scrollContainerRef = useRef();

  const claimsDashboardProgramComponent = useMemo(() => (
    Object.values(state.programComponents || {}).find(component => (
      component.typeRef === "ClaimsDashboard"
    ))
  ), [state.programComponents]);

  const distributionProgramComponent = useMemo(() => (
    Object.values(state.programComponents || {}).find(component => (
      component.typeRef === "DataAnalysis"
    ))
  ), [state.programComponents])

  const claimsProgramComponentPath = useMemo(() => (
    claimsDashboardProgramComponent &&
    generatePath(PATH_PROGRAM_COMPONENT, {
      programId,
      programComponentId: claimsDashboardProgramComponent.name,
    })
  ), [programId, claimsDashboardProgramComponent]);

  const distributionProgramComponentPath = useMemo(() => (
    distributionProgramComponent &&
    generatePath(PATH_PROGRAM_COMPONENT, {
      programId,
      programComponentId: distributionProgramComponent.name,
    })
  ), [programId, distributionProgramComponent])

  const summaryCountsByWfNode = useMemo(() => (
    summaryCounts &&
    mapObjectArrayByKey(Object.values(summaryCounts), "wfNodeId")
  ), [summaryCounts]);

  const makeFlowchartItem = useCallback(node => {
    const linkData = {};
    if (summaryCountsByWfNode) {
      const distributionCount = (
        summaryCountsByWfNode[node.wfNodeId]?.count || 0
      );
      const distributionCountDisplay = (
        !distributionCount ? "No" : formatDisplayNumber(distributionCount)
      );
      const pluralizedHeader = pluralize("item", distributionCount);
      const searchParams = !distributionCount ? "" : (
        `${URL_SEARCH_PARAM_NODE_ID}=${node.wfNodeId}`
      );
      const claimPath = !distributionCount ? null : (
        claimsProgramComponentPath +
        `#${ID_URL_HASH_SCROLL_TARGET}?${searchParams}`
      );
      const distributionPath = !distributionCount ? null : (
        `${distributionProgramComponentPath}/workflows` +
        `#${ID_URL_DISTRIBUTION_HASH_SCROLL_TARGET}?${searchParams}`
      )
      linkData.label = (
        <>{distributionCountDisplay}&nbsp;{pluralizedHeader}</>
      );
      linkData.path = (
        workflowTemplate?.isClaimTemplate ? claimPath : distributionPath
      );
    } else {
      linkData.label = (
        <Box display="flex">
          <Box fontWeight="400" marginRight={1}>Loading</Box>
          <Loader size={8} variant="search" inline noPadding />
        </Box>
      )
    }
    return {
      label: node.nodeName,
      link: linkData,
      tooltip: node.nodeDescription
    };
  }, [
    summaryCountsByWfNode, claimsProgramComponentPath, workflowTemplate,
    distributionProgramComponentPath
  ]);

  const flowchartItems = useMemo(() => (
    (nodeChain || []).map(makeFlowchartItem).filter(item => item)
  ), [makeFlowchartItem, nodeChain]);

  const flowchartDisconnectedItems = useMemo(() => (
    Array.from(nodeOrphanSet || []).map(makeFlowchartItem)
  ), [makeFlowchartItem, nodeOrphanSet]);

  if (!flowchartItems) {
    return null;
  }
  return (
    <div
      ref={node => {
        scrollContainerRef.current = node;
      }}
    >
      <LinearFlowchartMultiLine
        columns={FLOWCHART_COLUMNS_BREAKPOINTS}
        items={flowchartItems}
        ItemComponent={FlowchartLinkMultiRowItem}
        scrollContainerRef={scrollContainerRef}
        disconnectedItems={flowchartDisconnectedItems}
      />
    </div>
  );
}
