import { Box } from "@mui/material";
import { PATH_CONTACT_CHANGES_TRACKING, PATH_CLAIMS_BY_CUSTOMER, PATH_CLAIMS_BY_POPULATION, PATH_CLAIM_PAYMENTS, PATH_CLAIM_RECEIPT_TRACKING, PATH_CLAIMS_REVIEW_TRACKING } from "components/layout/constants/standardPaths.constants";
import ClaimsDashboardCard from "components/pages/consentOrder/claims/shared/ClaimsDashboardCard.component";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import AllClaimsTable from "components/pages/consentOrder/claims/dashboard/AllClaimsTable.component";
import { useContext, useMemo } from "react";
import StatisticsContext from "contexts/statistics.context";

const PLACEHOLDER_POPULATION_LABELS = [
  { name: "1", label: "Population -- Claims" },
  { name: "2", label: "Population -- Claims" },
  { name: "3", label: "Population -- Claims" },
];

const ClaimsDashboardPage = props => {
  const { state, dispatch } = props;

  const { state: statisticsState } = useContext(StatisticsContext);
  const { populations, summaryCounts } = statisticsState;

  const populationStatsLabels = useMemo(() => (
    !populations ? PLACEHOLDER_POPULATION_LABELS : (
      Object.keys(populations).sort().map(population => (
        { name: population, label: `Population ${population} Claims` }
      ))
    )
  ), [populations]);

  const statsLabels = useMemo(() => ({
    claimsByPopulation: populationStatsLabels,
    claimsByCustomer: [
      { name: "claimants", label: "Claimants" },
      { name: "claimsEligible", label: "Claims Eligible" },
      { name: "claimsReceived", label: "Claims Received" },
    ],
    claimReceiptTracking: [
      { name: "claimsFiled", label: "Filed Claims" },
      { name: "uploadErrors", label: "Upload Errors" },
    ],
    reviewTracking: [
      { name: "pendingFirstReview", label: "Pending First Review" },
      { name: "claimsReceiptEngagingClaimant", label: "Engaging Claimant" },
      { name: "awaitingDocuments", label: "Awaiting Documents" },
      { name: "incompleteClaims", label: "Incomplete Claims" },
      { name: "completeClaims", label: "Complete Claims" },
    ],
    dispositionTracking: [
      { name: "pendingClaims", label: "Pending Claims" },
      { name: "escalationClaims", label: "Claims in Escalation" }
    ],
    contactChangesTracking: [
      { name: "addressChanges", label: "Address Changes" },
      { name: "nameChanges", label: "Name Changes" },
    ],
    claimPayments: [
      { name: "claimPayments", label: "Claim Payments" },
      { name: "totalPayments", label: "Total Payments" },
    ],
    rustTransmissions: [
      { name: "returnReports", label: "Reports to return" }
    ],
  }), [populationStatsLabels]);

  const populationsAsStats = useMemo(() => (
    Object.fromEntries(
      Object.entries(populations).map(([name, population]) => ([
        name,
        { count: population.claimCount }
      ]))
    )
  ), [populations]);

  return (
    <div>
      <Box marginTop={-4} marginBottom={2}>
        <GridContainer spacing={2} hasVerticalSpacing>
          <GridItem>
            <ClaimsDashboardCard
              title="Claims by Population"
              description={`
                View Claims grouped by the Population group of their Claimants.
              `}
              path={PATH_CLAIMS_BY_POPULATION}
              statLabels={statsLabels.claimsByPopulation}
              statValues={populationsAsStats}
            />
          </GridItem>
          <GridItem>
            <ClaimsDashboardCard
              title="Claims by Customer"
              description="View Claims listed by Customer."
              path={PATH_CLAIMS_BY_CUSTOMER}
              statLabels={statsLabels.claimsByCustomer}
              statValues={summaryCounts}
            />
          </GridItem>
          <GridItem>
            <ClaimsDashboardCard
              title="Claim Receipt Tracking"
              description="Track status of each claim in progress."
              path={PATH_CLAIM_RECEIPT_TRACKING}
              statLabels={statsLabels.claimReceiptTracking}
              statValues={summaryCounts}
            />
          </GridItem>
          <GridItem>
            <ClaimsDashboardCard
              title="Review Tracking"
              description="Track progress of Claims undergoing data review."
              path={PATH_CLAIMS_REVIEW_TRACKING}
              statLabels={statsLabels.reviewTracking}
              statValues={summaryCounts}
            />
          </GridItem>
          <GridItem>
            <ClaimsDashboardCard
              title="Disposition Tracking"
              description={`
                View escalations and customer disposition information.
              `}
              statLabels={statsLabels.dispositionTracking}
              statValues={summaryCounts}
              disabled
            />
          </GridItem>
          <GridItem>
            <ClaimsDashboardCard
              title="Contact Changes Tracking"
              description={`
                Review corrections of previously invalid Contact data.
              `}
              path={PATH_CONTACT_CHANGES_TRACKING}
              statLabels={statsLabels.contactChangesTracking}
              statValues={summaryCounts}
            />
          </GridItem>
          <GridItem>
            <ClaimsDashboardCard
              title="Claim Payments"
              description="View distribution and remediation amounts."
              path={PATH_CLAIM_PAYMENTS}
              statLabels={statsLabels.claimPayments}
              statValues={summaryCounts}
            />
          </GridItem>
          <GridItem>
            <ClaimsDashboardCard
              title="Rust Transmissions"
              description={`
                Review aging reports that may need to be returned to Rust.
              `}
              statLabels={statsLabels.rustTransmissions}
              statValues={summaryCounts}
              disabled
            />
          </GridItem>
        </GridContainer>
      </Box>
      <AllClaimsTable
        state={state}
        dispatch={dispatch}
        titleHeader="Find Claims"
      />
    </div>
  );
}

export default ClaimsDashboardPage;