// packages
import { makeStyles } from "mui-styles";
// constants

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "block",
    left: 0,
  },
}));

const AuthBackground = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.background}>
      {props.children}
    </div>
  )
}

export default AuthBackground;
