import { Box } from "@mui/system";
import { H5 } from "components/utils/headerV2.component";
import { DATE_FORMAT_DISPLAY_NUMERIC } from "constants/date.constants";
import moment from "moment";
import { useMemo } from "react";


export default function TableCellClaimant(props) {
  const { claim } = props;
  const claimData = claim._derivedData.ClaimData;

  const displayDob = useMemo(() => (
    !claimData.claimantDOB ? null : (
      moment(claimData.claimantDOB).format(DATE_FORMAT_DISPLAY_NUMERIC)
    )
  ), [claimData.claimantDOB]);

  return (
    <div>
      <H5 color="tertiary">{claimData.claimantName}</H5>
      {!!claimData.claimantEmail && (
        <Box marginTop={1}>{claimData.claimantEmail}</Box>
      )}
      {!!claimData.claimantAddress && (
        <Box marginTop={1}>{claimData.claimantAddress}</Box>
      )}
      {!!displayDob && (
        <Box marginTop={1}>DOB: {displayDob}</Box>
      )}
    </div>
  );
}
