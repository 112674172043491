import React, { useState, useEffect, useContext, useMemo } from "react";
import { MenuItem, Grid } from "@mui/material";
import { makeStyles } from "mui-styles";
import LabelInput from "components/utils/form-elements/labelInput.component";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import CustomSelect from "components/utils/form-elements/select.component";
import UserService from "services/User.service";
import FormBanner from "components/utils/form-elements/formBanner.component";
import moment from "moment";
import classNames from "classnames";
import { sortByStringKey, moveToFrontByKey } from "utils/sort.utils";
import Checkbox from "components/utils/form-elements/checkboxDeprecated.component";
import { activeUsersFilter, isReadOnly } from "utils/roles.utils";
import AlertContext from "contexts/alert.context";
import {
  updateOrCreateTask,
  taskParentIDFinder,
  taskProgramIdFinder,
  taskFormTitle,
  wpTaskFormTitle,
} from "components/shared/tasks/utils/task.utils";
import {
  truncate,
} from "components/shared/workpackages/utils/workpackage.utils";
import {
  taskStatuses,
} from "components/shared/tasks/constants/task.constants";
import FormLabel from "components/utils/form-elements/formLabel.component";
import HeaderPrimary from "components/utils/header.component";
import { currentUserInfo } from "services/util/authSession.utils";
import DateInput from "components/utils/form-elements/dateInput.component";
import { PROGRAM_ID_ORCHESTRATION } from "components/constants/program.constants";
import { setStateFetchEffect } from "utils/ajax.utils";
import useNumericParams from "hooks/useNumericParams";
import ProgramsContext from "contexts/programs.context";
import { ACTION_REFETCH_USER_ALERTS } from "reducers/global/alert.reducer";
import { ACTION_REPLACE_TASKS } from "reducers/global/workpackageTasks.reducer";
import WorkpackageTasksContext from "contexts/workpackageTasks.context";
const formModes = {
  CREATE: "create",
  EDIT: "edit"
}

const parentComponents = {
  Task_Manager: "TaskManager",
  Tasks_Dash_Table: "TasksDashTable",
  Application_Schedule: "ApplicationSchedule"
}

const ALL_PARENT_COMPONENTS_SET = new Set([
  parentComponents.Task_Manager,
  parentComponents.Application_Schedule,
  parentComponents.Tasks_Dash_Table
]);

/*
 * Accessed via:
 * 1. Program Tasks table on dashboard
 * 2. Program Tasks table page
 * 3. Workpackage tasks table page - workpackage cannot be selected
 * 4. Each component object page
 * 5. Risk Assessment Responses Page
 * 6. Application Schedule Page
 */

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: "0px 20px 20px 20px",
    [theme.breakpoints.down("lg")]: {
      maxHeight: 680
    },
  },
  contentContainer: {
    padding: 30,
    paddingBottom: 20,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("lg")]: {
      padding: "0px 10px",
    },
  },
  formSectionRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 15,
    paddingBottom: 15,
    [theme.breakpoints.down("lg")]: {
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  statusWrapper: {
    width: 255,
  },
  formDataStatus: {
    paddingTop: 7,
    color: "black",
    fontSize: theme.typography.body2.fontSize
  },
  formSectionRowExtraPadding: {
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.down("lg")]: {
      paddingTop: 15,
      paddingBottom: 15,
    },
  },
  formSection: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 15,
    paddingBottom: 15,
    [theme.breakpoints.down("lg")]: {
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  firstCell: {
    paddingRight: 15,
  },
  lastCell: {
    paddingLeft: 15,
  },
  smallSection: {
    minWidth: 280,
  },
  formSectionWrapperIndent: {
    paddingLeft: 2,
    paddingRight: 2,
  },
  formSectionNoPadding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  fixedBanner: {
    position: "sticky",
    top: 0,
    zIndex: 10,
  },
  dualFormButtons: {
    paddingBottom: 40,
    [theme.breakpoints.down("lg")]: {
      paddingBottom: 15,
    },
  },
  paddingTop: {
    [theme.breakpoints.down("lg")]: {
      paddingTop: 35,
    },
  },
}));

const TaskForm = ({
  taskData,
  setHighlightRow,
  formMode,
  workpackageProp,
  setEditModal,
  parentComponent,
  setTaskData,
  setFormType,
  setWpDashRefresh,
  formType, //task or subTask
  setRefresh,
  parentTaskInfo,
  programName,
  variant,
  taskReferences,
  taskSource, //ex. "Design Review"
  onSuccess, //ex. function to be called
  setTaskMessage,
  onSubmitToParent
}) => {
  const classes = useStyles();
  const { state: programState } = useContext(ProgramsContext);
  const { dispatch: alertDispatch } = useContext(AlertContext);
  const { state: wpTaskState, dispatch: wpTaskDispatch } = useContext(
    WorkpackageTasksContext
  );

  const [formData, setFormData] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [workpackage, setWorkpackage] = useState({});
  const { programId, componentObjectId } = useNumericParams();

  const [dateErrorText, setDateErrorText] = useState("");
  const [actualDateErrorText, setActualDateErrorText] = useState("")
  const [possibleAssignees, setPossibleAssignees] = useState([]);
  const [assigneesLoaded, setAssigneesLoaded] = useState(false);

  const readOnly = useMemo(isReadOnly, []);

  const componentObject = useMemo(() => (
    programState.componentObjects?.[componentObjectId]
  ), [programState.componentObjects, componentObjectId]);

  const workpackages = useMemo(() => {
    if (!wpTaskState.workpackages) {
      return null;
    }
    if (programId === PROGRAM_ID_ORCHESTRATION) {
      const sortableWorkpackages = wpTaskState.workpackages.map(workpackageItem => ({
        ...workpackageItem,
        Program_Name:
          programState.programs?.[workpackageItem.Program_Program_ID]?.Name
      }));
      const orderedByProgName = sortByStringKey(
        sortableWorkpackages, "Program_Name"
      );
      orderedByProgName.forEach(workpackageItem => {
        delete workpackageItem.Program_Name
      });
      return moveToFrontByKey(
        orderedByProgName, "Program_Program_ID", PROGRAM_ID_ORCHESTRATION
      );
    } else {
      return wpTaskState.workpackagesByProgramId?.[programId];
    }
  }, [programId, programState.programs, wpTaskState]);

  useEffect(function setInitialFormData() {
    if (taskData && formMode !== formModes.CREATE) {
      setFormData({
        name: taskData.Name,
        proposedStart: taskData.Proposed_Start,
        proposedCompletion: taskData.Proposed_Completion,
        status: taskData.Status,
        actualStart: taskData.Actual_Start,
        actualCompletion: taskData.Actual_Completion,
        notes: taskData.Task_Notes,
        wpId: taskData.Workpackages_Workpackage_ID || "",
        type: taskData.Type === "Milestone",
        assignment: taskData.Assigned_User,
        compObjId: taskData.Component_Component_Object_ID
      });
    } else {
      setFormData({
        name: "",
        proposedStart: "",
        proposedCompletion: "",
        status: taskStatuses.NOT_STARTED,
        actualStart: "",
        actualCompletion: "",
        notes: "",
        wpId: workpackageProp ? workpackageProp.Workpackage_ID : "",
        type: false,
        assignment: null,
        compObjId: componentObjectId
      });
    }
  }, [componentObjectId, formMode, taskData, workpackageProp]);

  useEffect(function fetchRequiredData() {
    if (!assigneesLoaded) {
      return setStateFetchEffect(
        UserService.getTaskAssigneeUsers,
        ([usersResponse]) => {
          setPossibleAssignees(activeUsersFilter(usersResponse.payload));
          setAssigneesLoaded(true);
        }
      );
    }
  }, [assigneesLoaded]);

  useEffect(() => {
    if (setHighlightRow && !wpTaskState.workpackages) {
      setHighlightRow(null);
    }
  }, [setHighlightRow, wpTaskState.workpackages]);

  const errorChecker = (name, startValue, endValue) => {
    if (startValue > endValue && startValue !== "" && endValue !== "") {
      if (name === "actualStart" || name === "actualCompletion") {
        setActualDateErrorText(
          "Actual start date must come before actual end date."
        );
      } else {
        setDateErrorText("Start date must come before end date.")
      }
    } else if (name === "actualStart" || name === "actualCompletion") {
      setActualDateErrorText("")
    } else {
      setDateErrorText("")
    }
  }

  const statusFinder = (event) => {
    let updatedStatus;
    let actualCompletion;
    if (event.target.name === "actualCompletion") {
      errorChecker(event.target.name, formData.actualStart, event.target.value)
      actualCompletion = event.target.value
      if (event.target.value !== "") {
        updatedStatus = taskStatuses.COMPLETED
      } else if (formData.actualStart !== "") {
        updatedStatus = taskStatuses.STARTED
      } else {
        updatedStatus = taskStatuses.NOT_STARTED
      }
    } else {
      errorChecker(
        event.target.name, event.target.value, formData.actualCompletion
      );
      if (event.target.value !== "") {
        actualCompletion = formData.actualCompletion
        if (formData.actualCompletion !== "" && formData.actualCompletion) {
          updatedStatus = taskStatuses.COMPLETED
        } else {
          updatedStatus = taskStatuses.STARTED
        }
      } else {
        updatedStatus = taskStatuses.NOT_STARTED
        actualCompletion = ""
      }
    }
    return { updatedStatus, actualCompletion }
  }

  const handleChange = (event) => {
    if (
      event.target.name === "actualCompletion" ||
      event.target.name === "actualStart"
    ) {
      const { updatedStatus, actualCompletion } = statusFinder(event)
      setFormData({
        ...formData,
        status: updatedStatus,
        actualCompletion,
        [event.currentTarget.id]: event.target.value,
      });
    } else {
      if (event.target.name === "proposedStart") {
        errorChecker(
          event.target.name,
          event.target.value,
          formData.proposedCompletion
        );
      } else if (event.target.name === "proposedCompletion") {
        errorChecker(
          event.target.name,
          formData.proposedStart,
          event.target.value
        );
      }
      setFormData({
        ...formData,
        [event.currentTarget.id]: event.target.value,
      });
    }
  };

  const handleChangeSelect = (event, checkbox) => {
    if (event.target.name === "wpId") {
      const chosenWP = workpackages.find(wp => (
        wp.Workpackage_ID === parseInt(event.target.value)
      ));
      setWorkpackage(chosenWP || {})
    }
    if (checkbox === "checkbox") {
      setFormData({
        ...formData,
        type: event.target.checked,
        proposedStart: "",
      });
    } else {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const closeForm = () => {
    setEditModal(false);
  };

  const reset = () => {
    if (taskReferences) {
      setEditModal(false);
      return null
    }
    if (!ALL_PARENT_COMPONENTS_SET.has(parentComponent)) {
      setTaskData();
      setFormType("");
    } else if (parentComponent === parentComponents.Tasks_Dash_Table) {
      setWpDashRefresh(new Date());
    }
    setRefresh?.(true);
    setEditModal(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSaving(true);
    const user = currentUserInfo();
    const parentId = (
      taskParentIDFinder(formType, formMode, taskData, parentTaskInfo)
    );
    const taskProgramId = taskProgramIdFinder(
      workpackage.Program_Program_ID,
      programId,
      workpackageProp
    );
    const newTask = {
      Parent_ID: parentId,
      Name: formData.name,
      Proposed_Start: (
        formData.proposedStart === "" ? null : formData.proposedStart
      ),
      Proposed_Completion: (
        formData.proposedCompletion === "" ? null : formData.proposedCompletion
      ),
      Status: (
        formData.status === "" || formData.status === taskStatuses.NOT_STARTED ?
          taskStatuses.NOT_STARTED :
          formData.status
      ),
      Actual_Start: formData.actualStart === "" ? null : formData.actualStart,
      Actual_Completion: (
        formData.actualCompletion === "" ? null : formData.actualCompletion
      ),
      Task_Notes: formData.notes === "" ? null : formData.notes,
      Change_Date: moment(new Date()).format("YYYY-MM-DD"),
      Change_User: user.id,
      Workpackages_Workpackage_ID:
        (formData.wpId !== "None" && formData.wpId !== "") ? formData.wpId : null,
      Program_Program_ID: taskProgramId,
      Assigned_User: (
        formData.assignment !== "Not Assigned" ? formData.assignment : null
      ),
      Component_Component_Object_ID: formData.compObjId || null,
      Type: formData.type === true ? "Milestone" : "Task",
    };

    if (
      newTask.Proposed_Completion < newTask.Proposed_Start ||
      newTask.Actual_Completion < newTask.Actual_Start
    ) {
      setDateErrorText("Proposed Start date must come before Proposed End date.")
    } else if (onSubmitToParent) {
      if (formMode === formModes.EDIT) {
        newTask.Task_ID = taskData?.Task_ID
      }
      onSubmitToParent?.(newTask);
      reset();
    } else {
      updateOrCreateTask(
        formMode, newTask, taskData?.Task_ID, taskReferences
      ).then(currentTask => {
        // Endpoint doesn't return alerts data
        alertDispatch({ type: ACTION_REFETCH_USER_ALERTS });

        if (onSuccess) {
          onSuccess(currentTask);
        }
        wpTaskDispatch({
          type: ACTION_REPLACE_TASKS,
          payload: [currentTask]
        });
        reset();

        //This message is displayed in ResponseSection
        if (setTaskMessage && currentTask?.Task_ID) {
          setTaskMessage(`Task successfully created`);
        }
      });
    }
  };

  const disabledButton = () => {
    return (
      formData.name.trim() === "" ||
      dateErrorText.length > 0 ||
      actualDateErrorText.length > 0 ||
      (
        parentComponent === parentComponents.Application_Schedule &&
        !formData.wpId
      )
    );
  };

  //Logic for determining what is listed as select options for workpackages
  const wpMenuitemDisplay = (name, wpProgId) => {
    if (programId !== PROGRAM_ID_ORCHESTRATION) {
      return truncate(name)
    } else {
      return (
        <span>
          <span style={{ fontWeight: "bold" }}>
            {truncate(name)}
          </span>
          &nbsp;-&nbsp;
          {programState.programs?.[wpProgId]?.Name}
          &nbsp;Program
        </span>
      );
    }
  }

  const possibleAssigneesSelectOptions = useMemo(() => (
    possibleAssignees.map((assignee) => (
      {
        ...assignee,
        _displayLabel: `${assignee.First_Name} ${assignee.Last_Name}`,
      }
    ))),
    [possibleAssignees]);

  if (formData && assigneesLoaded) {
    const modeLabel = readOnly ? "View" : formMode;
    return (
      <>
        {variant !== "tasksByWorkpackage" ? (
          <FormBanner className={classes.fixedBanner}>
            {taskFormTitle(
              formType,
              modeLabel,
              componentObject,
              programName,
              taskSource,
            )}
          </FormBanner>
        ) : (
          <FormBanner className={classes.fixedBanner}>
            {wpTaskFormTitle(formType, modeLabel)}
          </FormBanner>
        )}
        <Grid className={classes.formContainer}>
          <div className={classes.contentContainer} data-cy="form-task">
            <div
              className={
                classNames(
                  classes.formSectionRow,
                  classes.formSectionWrapperIndent,
                  classes.paddingTop
                )
              }
            >
              {/* ONLY DISPLAYS WHEN ADDING FROM DASHBOARD */}
              <div
                className={classNames(classes.smallSection, classes.firstCell)}
              >
                {variant !== "tasksByWorkpackage" ? (
                  <div className={classes.selectWrapper} htmlFor="status">
                    <CustomSelect
                      label={
                        parentComponent !== parentComponents.Application_Schedule ?
                          "Select a Workpackage (Optional)" :
                          "Select a Workpackage"
                      }
                      variant="form"
                      value={formData.wpId || ""}
                      onChange={handleChangeSelect}
                      id="wpId"
                      name="wpId"
                      test="workpackage"
                      required={
                        parentComponent === parentComponents.Application_Schedule
                      }
                    >
                      {parentComponent !== parentComponents.Application_Schedule && (
                        <MenuItem value="None">
                          None
                        </MenuItem>
                      )}

                      {!!workpackages && (
                        sortByStringKey(workpackages, "Name").map((item) => (
                          <MenuItem
                            value={item.Workpackage_ID}
                            key={item.Workpackage_ID}
                            data-cy={`menuItem-wpTask-${item.Name}`}
                          >
                            {wpMenuitemDisplay(
                              item.Name,
                              item.Program_Program_ID
                            )}
                          </MenuItem>
                        ))
                      )}
                    </CustomSelect>
                  </div>
                ) : (
                  <>
                    <FormLabel variant="default" label="Workpackage" />
                    <HeaderPrimary variant="h4Primary">
                      {workpackageProp.Name}
                    </HeaderPrimary>
                  </>
                )}
              </div>
            </div>


            {/* DESCRIPTION */}
            <div className={classes.formSection}>
              <LabelInput
                variant="default"
                label="Task"
                required
                onChange={handleChange}
                value={formData.name || ""}
                id="name"
                test="TaskName"
                size="small"
              />
            </div>

            {/* MILESTONE */}
            <div
              className={
                classNames(
                  classes.formSectionNoPadding,
                  classes.formSectionWrapperIndent
                )
              }
            >
              <Checkbox
                checked={formData.type}
                onChange={(event) => handleChangeSelect(event, "checkbox")}
                name="type"
                label="Milestone"
                test="Milestone"
              />
            </div>

            {/* DATES */}
            <div
              className={
                classNames(
                  classes.formSectionRowExtraPadding,
                  classes.formSectionRow
                )
              }
            >
              {/* START DATE */}
              <div
                className={classNames(classes.smallSection, classes.firstCell)}
              >
                <DateInput
                  label="Proposed Start"
                  error={dateErrorText.length > 0}
                  errorMessage={dateErrorText}
                  disabled={formData.type}
                  value={formData.proposedStart || ""}
                  id="proposedStart"
                  name="proposedStart"
                  placeholder="yyyy-mm-dd"
                  onChange={handleChange}
                  test="ProposedStart"
                  size="small"
                />
              </div>
              {/* END DATE */}
              <div
                className={classNames(classes.smallSection, classes.lastCell)}
              >
                <DateInput
                  label="Proposed Completion"
                  error={dateErrorText.length > 0}
                  errorMessage={dateErrorText}
                  value={formData.proposedCompletion || ""}
                  placeholder="yyyy-mm-dd"
                  id="proposedCompletion"
                  name="proposedCompletion"
                  onChange={handleChange}
                  test="ProposedCompletion"
                  size="small"
                />
              </div>
            </div>

            {formMode !== formModes.CREATE && (
              <div
                className={
                  classNames(
                    classes.formSectionRowExtraPadding,
                    classes.formSectionRow
                  )
                }
              >
                <div
                  className={
                    classNames(classes.smallSection, classes.firstCell)
                  }
                >
                  <DateInput
                    errorMessage={actualDateErrorText}
                    error={actualDateErrorText.length > 0}
                    label="Actual Start"
                    value={formData.actualStart || ""}
                    placeholder="yyyy-mm-dd"
                    id="actualStart"
                    name="actualStart"
                    onChange={handleChange}
                    test="ActualStart"
                    size="small"
                  />
                </div>
                <div
                  className={
                    classNames(classes.smallSection, classes.lastCell)
                  }
                >
                  <DateInput
                    errorMessage={actualDateErrorText}
                    error={actualDateErrorText.length > 0}
                    label="Actual Completion"
                    value={formData.actualCompletion || ""}
                    placeholder="yyyy-mm-dd"
                    id="actualCompletion"
                    name="actualCompletion"
                    onChange={handleChange}
                    test="ActualCompletion"
                    size="small"
                    disabled={!formData.actualStart}
                  />
                </div>
              </div>
            )}


            {/* STATUS */}
            <div
              className={
                classNames(
                  classes.formSectionRow,
                  classes.formSectionWrapperIndent
                )
              }
            >
              {/* ASSIGNMENT */}
              <div
                className={classNames(classes.smallSection, classes.firstCell)}
              >
                <div className={classes.selectWrapper}>
                  <CustomSelect
                    label="Assignment"
                    variant="form"
                    value={formData.assignment || ""}
                    onChange={handleChangeSelect}
                    id="assignment"
                    name="assignment"
                    disabled={possibleAssignees.length === 0}
                    errorMessage={
                      possibleAssignees.length === 0 &&
                      "There are no assignees available."
                    }
                    test="Assignment"
                  >
                    <MenuItem
                      value="Not Assigned"
                    >
                      Not Assigned
                    </MenuItem>
                    {sortByStringKey(
                      possibleAssigneesSelectOptions,
                      "_displayLabel"
                    ).map(item => (
                      <MenuItem
                        value={item.User_ID}
                        key={item.User_ID}
                        id={item.User_ID}
                        data-cy={
                          `menuItem-taskForm-${item.First_Name}_${item.Last_Name}`
                        }
                      >
                        {item._displayLabel}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </div>
              </div>
              <div>
                <div className={classes.statusWrapper}>Status</div>
                <div className={classes.formDataStatus}>{formData.status}</div>
              </div>
            </div>
            {/* NOTES */}
            <div className={classes.formSection}>
              <LabelInput
                label="Task Notes"
                variant="default"
                multiline
                rows={3}
                onChange={handleChange}
                value={formData.notes || ""}
                id="notes"
                test="TaskNotes"
              />
            </div>
          </div>
          {/* BUTTONS */}
          <DualFormButtons
            addText={
              (formMode === formModes.CREATE) ? "Add" : "Update"
            }
            variant="smallButton"
            saveOnClick={handleSubmit}
            cancelOnClick={() => closeForm()}
            isSaving={isSaving}
            disabled={disabledButton()}
            className={classes.dualFormButtons}
          />
        </Grid>
      </>
    );
  } else {
    return "";
  }
};

export default TaskForm;
