import { Box } from "@mui/system";
import { renderBasicDataTypeCell } from "components/claims/table/claimTable.utils";
import TableCellClaim from "components/claims/table/TableCellClaim.component";
import TableCellClaimant from "components/claims/table/TableCellClaimant.component";
import DataTable from "components/utils/tables/dataTable.component";
import { createNodeCellParams, createNodeHeaderParams, createValueHeaderParams } from "components/utils/tables/utils/dataTable.utils";
import Tooltip from "components/utils/tooltip.component";
import { DATE_FORMAT_DISPLAY_NUMERIC } from "constants/date.constants";
import WorkflowTemplateContext from "contexts/workflowTemplate.context";
import { makeStyles } from "mui-styles";
import { useContext, useMemo } from "react";
import { mapObjectArrayByKey } from "utils/arrayOfObjects.utils";
import moment from "moment";
import { makeDateCell } from "components/utils/tables/utils/cell.utils";

const useStyles = makeStyles(theme => ({
  missingDataCell: {
    opacity: 0.6
  },
  tooltipHoverZone: {
    textDecoration: `underline dotted ${theme.palette.text.primary}`,
    cursor: "default",
    fontWeight: theme.typography.fontWeightBold
  }
}));


const CONSENT_ORDER_PROGRAM_NAME = "remediation-management";
const CLAIMS_REVIEW_PATH = `/home/program/${CONSENT_ORDER_PROGRAM_NAME}/claims/claims-review-component-object`

// Name of column corresponds to ClaimValueType.name or OTHER_COLUMNS value
const TABLE_COLUMNS_VALUE_TYPE = [
  createValueHeaderParams("caseNumber", "Case Number", {
    align: "center",
    filter: true,
  }),
  createValueHeaderParams("category", "Category", {
    filter: false,
  }),
  createValueHeaderParams("requestType", "Request Type", {
    filter: false,
  }),
  createValueHeaderParams("impactType", "Impact Type", {
    filter: false,
  }),
  createValueHeaderParams("narrative", "Justification Narrative", {
    filter: false,
    sort: false,
    setCellProps: () => ({
      style: { minWidth: 300 }
    })
  }),
  createValueHeaderParams("requestedDollarAmt", "Requested Amount", {
    filter: false,
  }),
  createValueHeaderParams("actualCostDesc", "Actual Cost Description", {
    filter: false,
    sort: false,
    setCellProps: () => ({
      style: { minWidth: 112 }
    })
  }),
];

const TABLE_COLUMNS_OTHER = [
  createNodeHeaderParams("claim", "Claim", {
    filter: false,
    sort: false
  }),
  createNodeHeaderParams("submittedDate", "Submitted Date", {
    filter: false,
  }),
  createNodeHeaderParams("claimant", "Claimant", {
    filter: false,
  }),
  createValueHeaderParams("mailedDocs", "Mailed Docs", {
    filter: true,
  }),
  createNodeHeaderParams("workflowStatus", "Status", {
    filter: false,
    setCellProps: () => ({
      style: { minWidth: 160 }
    })
  }),
];

const TABLE_COLUMNS_OTHER_NAMES = new Set(
  TABLE_COLUMNS_OTHER.map(columnData => columnData.name)
);

const ClaimsTable = (props) => {
  const {
    claims,
    fullPage = false,
    /* Array of columns to include, by cell name (not db column). */
    includeColumns = null,
    size,
    state,
    title,
  } = props;
  const classes = useStyles();

  const workflowTemplates = useContext(WorkflowTemplateContext);

  const workflowTemplatesById = useMemo(() => (
    mapObjectArrayByKey(workflowTemplates, "wfTemplateId")
  ), [workflowTemplates]);

  const workflowNodesById = useMemo(() => (
    mapObjectArrayByKey(
      workflowTemplates.flatMap(wfTemplate => ([
        wfTemplate._associations.WFEventFlow.flatMap(eventFlow => ([
          eventFlow._associations.WFReferenceNode,
          eventFlow._associations.WFTargetNode
        ]))
      ])).flat(),
      "wfNodeId"
    )
  ), [workflowTemplates]);

  const tableColumns = useMemo(() => {
    const valueTypeSet = new Set(Object.keys(state.claimValueTypesByName));
    const otherTableColumns = TABLE_COLUMNS_OTHER.filter(({ name }) => (
      name !== "workflowStatus"
    ));
    const remediationTableColumns = otherTableColumns.concat(
      TABLE_COLUMNS_VALUE_TYPE.filter(({ name }) => valueTypeSet.has(name))
    );
    if (includeColumns === null) {
      return remediationTableColumns;
    }
    const includedColumnSet = new Set(includeColumns);
    return remediationTableColumns.filter(columnData => (
      includedColumnSet.has(columnData.name)
    ));
  }, [includeColumns, state.claimValueTypesByName]);

  const tableColumnNames = useMemo(() => (
    tableColumns.map(columnData => columnData.name)
  ), [tableColumns])

  const tableCells = useMemo(() => {
    return claims?.map?.((claim) => {
      const claimData = claim._derivedData.ClaimData;
      const basicTableColumnNames = tableColumnNames.filter(name => (
        !TABLE_COLUMNS_OTHER_NAMES.has(name)
      ));

      const cells = {};
      basicTableColumnNames.forEach(name => {
        cells[name] = renderBasicDataTypeCell(
          state.claimValueTypesByName[name]?.dataType,
          claimData[name],
        )
      });
      cells.submittedDate = makeDateCell(claim.submittedDate);
      if (tableColumnNames.includes("claim")) {
        cells.claim = createNodeCellParams(
          claimData.cifId,
          `${claimData.cifId}|${claimData.gsLoanNumber}`,
          (
            <TableCellClaim
              claims={[claim]}
              componentObjectPath={CLAIMS_REVIEW_PATH}
            />
          )
        );
      }
      if (tableColumnNames.includes("claimant")) {
        const {
          claimantName: name, claimantEmail: email, claimantAddress: address,
          claimantDOB: dob, claimantSSN: ssn
        } = claimData;
        cells.claimant = createNodeCellParams(
          claimData.claimantName,
          `${name}|${email}|${address}||${ssn}|
            ${moment(dob).format(DATE_FORMAT_DISPLAY_NUMERIC)}`,
          (
            <TableCellClaimant claim={claim} />
          )
        );
      }
      if (tableColumnNames.includes("mailedDocs")) {
        if (["0", 0].includes(claimData.mailedDocs)) {
          cells.mailedDocs = "No";
        } else if (Number(claimData.mailedDocs) >= 1) {
          cells.mailedDocs = "Yes";
        } else {
          cells.mailedDocs = "Unknown";
        }
      }
      if (
        tableColumnNames.includes("workflowStatus") &&
        workflowNodesById
      ) {
        const wfInstance = claim._associations.WFInstance?.find(instance => (
          instance.status === "Active"
        ));
        const wfNode = workflowNodesById[wfInstance?.currentNodeId];
        if (wfInstance && wfNode) {
          const workflowTemplateName = (
            workflowTemplatesById[wfNode.wfTemplateId].templateName
          );
          cells.workflowStatus = createNodeCellParams(
            wfNode?.nodeName,
            `${wfNode?.nodeName}|${wfNode.nodeDescription}`,
            (
              <div>
                <Tooltip title={wfNode.nodeDescription}>
                  <div className={classes.tooltipHoverZone}>
                    {wfNode?.nodeName}
                  </div>
                </Tooltip>
                <Box marginTop={2}>
                  <div><em>Workflow:</em></div>
                  <div>{workflowTemplateName}</div>
                </Box>
              </div>
            )
          );
        } else {
          cells.workflowStatus = createNodeCellParams(
            null,
            null,
            (
              <div className={classes.missingDataCell}>Unknown</div>
            )
          );
        }
      }
      return cells;
    }) || []
  }, [
    claims, classes, state.claimValueTypesByName, tableColumnNames,
    workflowNodesById, workflowTemplatesById
  ])

  return (
    <DataTable
      columns={tableColumns}
      data={tableCells}
      headerSize={size}
      title={title}
      options={{
        disableHoverHighlight: true,
        filterType: "checkbox",
        fixedHeader: true,
        fixedSelectColumn: true,
        filter: true,
        rowsPerPage: 15,
        pagination: true,
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        tableBodyMaxHeight: fullPage ? undefined : "calc(60vh)",
        responsive: "simple",
      }}
    />
  )
}
export default ClaimsTable;