import React, { useCallback, useRef } from "react"; // { useState }
import { makeStyles } from "mui-styles";
import {
  Accordion,
  AccordionSummary,
  Typography,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  accordionWrapperHover: {
    "&:hover": {
      "& > .MuiPaper-root": {
        backgroundColor: "rgba(119, 197, 253, 0.5)",
      },
    },
  },
  accordionHeaderHover: {
    "&:hover": {
      backgroundColor: "rgba(119, 197, 253, 0.5)",
    },
  },
  accordionWrapper: {
    width: "100%",
  },
  accordionWrapperManualScroll: {
    overflow: "hidden",
  },
  accordionWrapperAutoScroll: {
    height: "100%",
  },
  accordionWrapperHeaderHighlight: {
    borderBottom: `1px solid ${theme.palette.border.light}`,
    borderRadius: 4,
  },
  accordionSummaryDisabled: {
    "&.Mui-disabled": {
      background: theme.palette.background.default,
      opacity: 0.5
    }
  },
  childrenWrapper: {
    backgroundColor: "transparent",
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    display: "flex",
    flexDirection: "column",
  },
  summaryContent: {
    justifyContent: "space-between",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.secondary,
  },
  denseHeading: {
    fontSize: theme.typography.body1.fontSize,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  scrollTarget: {
    position: "absolute",
    width: 1,
    height: 1,
    bottom: -100
  },
  emptyBox: {
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 3,
    color: theme.palette.text.secondary,
  },
}));

const AccordionView = ({
  children,
  mainLabel,
  subLabel,
  onChange,
  autoScroll = false,
  expanded,
  emptyMessage,
  defaultExpanded,
  dense,
  testProp,
  classes: classesProp,
  id,
  headerOnlyHighlight = false,
  noHoverHighlight,
  ...muiProps
}) => {
  const classes = useStyles();

  const scrollTargetRef = useRef(null);

  const handleChange = useCallback((event, isExpanded) => {
    onChange?.(event, isExpanded, id)
  }, [id, onChange]);

  const handleTransitionEntered = useCallback(() => {
    if (!autoScroll) {
      return;
    }
    scrollTargetRef.current?.scrollIntoView?.(
      { behavior: "smooth", block: "nearest" }
    );
  }, [autoScroll]);

  if (emptyMessage) {
    return (
      <Paper className={classNames(classes.root, classes.emptyBox)} data-cy={testProp}>
        {emptyMessage}
      </Paper>
    );
  } else {
    return (
      <div
        className={classNames(
          classes.accordionWrapper,
          !autoScroll && classes.accordionWrapperManualScroll,
          autoScroll && classes.accordionWrapperAutoScroll,
          !noHoverHighlight && !headerOnlyHighlight && (
            classes.accordionWrapperHover
          ),
          headerOnlyHighlight && classes.accordionWrapperHeaderHighlight,
          classesProp?.root
        )}
        data-cy={`accordion-wrapper-${id}`}
      >
        <Accordion
          expanded={expanded}
          onChange={handleChange}
          defaultExpanded={defaultExpanded === true}
          classes={classesProp}
          data-cy="accordion-view"
          TransitionProps={{
            mountOnEnter: true,
            onEntered: handleTransitionEntered
          }}
          {...muiProps}
        >
          <AccordionSummary
            classes={{
              root: classNames(
                classes.accordionSummary,
                classesProp?.summary,
                headerOnlyHighlight && classes.accordionHeaderHover
              ),
              content: classNames(
                classes.summaryContent,
                classesProp?.content,
              ),
              disabled: classes.accordionSummaryDisabled
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${id}-content`}
            data-cy={`accordion-summary-${testProp}`}
            id={id}
          >
            <div className={dense ? classes.denseHeading : classes.heading}>
              {mainLabel}
            </div>
            {!!subLabel && (
              <Typography className={classes.secondaryHeading}>
                {subLabel}
              </Typography>
            )}
          </AccordionSummary>
          <div
            className={
              classNames(classes.childrenWrapper, classesProp?.detail)
            }
          >
            {children}
            {!!autoScroll && (
              <div
                className={classes.scrollTarget}
                ref={scrollTargetRef}
              />
            )}
          </div>
        </Accordion>
      </div>
    );
  }
};

export default AccordionView;
