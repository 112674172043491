import React, { useState, useEffect, useContext, useCallback } from "react";
import { makeStyles } from "mui-styles";
import { MenuItem, Grid } from "@mui/material";
import CustomSelect from "components/utils/form-elements/select.component";
import DualFormButtons from "components/utils/form-elements/dualFormButtons.component";
import TaskService from "services/Task.service";
import FormBanner from "components/utils/form-elements/formBanner.component";
import PersonIcon from "@mui/icons-material/Person";
import UserService from "services/User.service";
import AlertContext from "contexts/alert.context";
import { setStateFetchEffect } from "utils/ajax.utils";
import { activeUsersFilter } from "utils/roles.utils";
import { ACTION_REFETCH_USER_ALERTS } from "reducers/global/alert.reducer";
import { ACTION_REPLACE_TASKS } from "reducers/global/workpackageTasks.reducer";
import WorkpackageTasksContext from "contexts/workpackageTasks.context";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: 500,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: 20,
  },
  contentContainer: {
    padding: "15px 20px 0px 20px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  selectsContainer: {
    paddingTop: 10,
    alignSelf: "center",
    width: 300,
    overflowX: "hidden",
  },
  select: {
    marginBottom: 25,
    width: "100%",
  },
  personIcon: {
    fontSize: theme.typography.body1.fontSize,
    marginRight: 6,
    color: "white",
  },
}));

const AssignTaskForm = (props) => {
  const {
    setAssignMode, setEditModal, setHighlightRow, setTaskData, taskData
  } = props;
  const classes = useStyles();
  const [possibleAssignees, setPossibleAssignees] = useState(null);
  const [assignee, setAssignee] = useState("");
  const { dispatch: alertDispatch } = useContext(AlertContext);
  const { dispatch: wpTaskDispatch } = useContext(WorkpackageTasksContext);

  useEffect(() => {
    if (setHighlightRow) {
      setHighlightRow(null); // Removes blue highlight from row
    }
    return setStateFetchEffect(
      UserService.getAll(),
      res => setPossibleAssignees(activeUsersFilter(res[0].payload))
    );
  }, [setHighlightRow, setPossibleAssignees]);

  const handleChange = (event) => {
    setAssignee(event.target.value);
  };

  const handleSubmit = useCallback(async event => {
    event.preventDefault();
    await TaskService.assignTask(taskData.Task_ID, assignee, taskData);
    // Endpoint doesn't return alerts data
    alertDispatch({ type: ACTION_REFETCH_USER_ALERTS });

    const assignedTask = { ...taskData, Assigned_User: assignee };
    wpTaskDispatch({ type: ACTION_REPLACE_TASKS, payload: [assignedTask] });

    setEditModal(false);
    setAssignMode(false);
  }, [
    assignee, alertDispatch, wpTaskDispatch,
    setAssignMode, setEditModal, taskData
  ]);

  const clearForm = () => {
    setTaskData();
    setAssignMode(false);
  };
  return (
    <Grid className={classes.formContainer} data-cy="assignForm">
      <FormBanner>{`Assign ${props.taskData.Name}`}</FormBanner>
      <div className={classes.contentContainer}>

        <div className={classes.selectsContainer}>
          <CustomSelect
            className={classes.select}
            label="Select Assignee"
            value={assignee}
            onChange={handleChange}
            disabled={!possibleAssignees?.length}
            errorMessage={possibleAssignees?.length === 0 && "There are no assignees available."}
            test="assignForm"
          >
            {possibleAssignees?.map?.((item) => (
              <MenuItem
                value={item.User_ID}
                key={item.User_ID}
                id={item.User_ID}
                data-cy={`menu-item-${item.First_Name} ${item.Last_Name}`}
              >
                {`${item.First_Name} ${item.Last_Name}`}
              </MenuItem>
            ))}
          </CustomSelect>
        </div>
      </div>

      <DualFormButtons
        variant="addFramework"
        addText="Assign"
        cancelOnClick={() => {
          setEditModal(false);
          clearForm();
        }}
        startIcon={<PersonIcon className={classes.personIcon} />}
        saveOnClick={handleSubmit}
        disabled={assignee === ""}
      />
    </Grid>
  );
};

export default AssignTaskForm;
