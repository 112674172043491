import { Box } from "@mui/material";
import CustomerClaimCard from "components/pages/consentOrder/claims/customer/CustomerClaimCard.component";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import CustomerService from "services/Customer.service";
import SearchResultsWrapper from "components/pages/consentOrder/claims/shared/SearchResultsWrapper.component";
import SubpageSearchbarHeader from "components/pages/consentOrder/claims/shared/SubpageSearchbarHeader.component";
import { H3 } from "components/utils/headerV2.component";
import LabelValuePair from "components/utils/labelValuePair.component";
import StatisticsContext from "contexts/statistics.context";

export default function ClaimsByCustomerPage(props) {
  const {
    state,
    workflowNodesById,
    workflowTemplates,
  } = props;

  const { state: { populations }} = useContext(StatisticsContext);
  const searchAjaxAbort = useRef();

  const [customerResults, setCustomerResults] = useState(null);
  const [hasSearchError, setHasSearchError] = useState(false);
  const [isSearchDisabled, setIsSearchDisabled] = useState(true);
  const [isRequestPending, setIsRequestPending] = useState(false);

  const handleSearchChange = useCallback(event => {
    const { value } = event.target;
    setHasSearchError(false);
    setIsSearchDisabled(!value);
  }, []);

  const handleSearchSubmit = useCallback(async data => {
    try {
      setIsRequestPending(true);
      searchAjaxAbort.current = new AbortController();
      const response = await CustomerService.searchCustomers(
        searchAjaxAbort.current,
        data.search,
        true
      );
      if (response.payload) {
        setCustomerResults(response.payload);
        setHasSearchError(false);
        setIsSearchDisabled(true);
      }
    } catch (error) {
      setCustomerResults(null);
      setHasSearchError(true);
      console.error(error);
    } finally {
      setIsRequestPending(false);
    }
  }, []);

  const distributionsByCustomer = useMemo(() => (
    (customerResults || []).flatMap(customer => (
      customer._associations.DistributionDataset
    )).reduce((accumulator, dataset) => {
      if (!accumulator[dataset.cifId]) {
        accumulator[dataset.cifId] = [];
      }
      accumulator[dataset.cifId].push(dataset);
      return accumulator;
    }, {})
  ), [customerResults]);

  const populationTypes = useMemo(() => (
    populations && Object.keys(populations).sort()
  ), [populations]);

  useEffect(() => (
    function cancelSearchOnUnmount() {
      CustomerService.cancel(searchAjaxAbort.current);
    }
  ), []);

  return (
    <Box marginBottom={10}>
      <SubpageSearchbarHeader
        title="Claims by Customer"
        formName="customer-search"
        placeholder="Search by CIF ID, Loan Number, Claimant Name or Email"
        disabled={isSearchDisabled}
        onChange={handleSearchChange}
        onSubmit={handleSearchSubmit}
      />
      <SearchResultsWrapper
        error={hasSearchError}
        loading={isRequestPending}
        results={customerResults}
      >
        <>
          <GridContainer
            spacing={8}
            alignItems="baseline"
            justifyContent="space-between"
          >
            <GridItem marginTop={3}>
              <H3 color="tertiary">Customer Results</H3>
            </GridItem>
            <GridItem>
              <LabelValuePair
                label="Total Results"
                value={customerResults?.length || 0}
              />
            </GridItem>
          </GridContainer>
          {customerResults?.map?.((customer) => (
            <Box marginTop={2} key={customer.customerId}>
              <CustomerClaimCard
                customer={customer}
                state={state}
                workflowNodesById={workflowNodesById}
                workflowTemplates={workflowTemplates}
                populationTypes={populationTypes}
                customerDistributions={distributionsByCustomer[customer.cifId]}
              />
            </Box>
          ))}
        </>
      </SearchResultsWrapper>
    </Box>
  );
}
