
// Todo: Handle users who have local/session storage blocked
// Todo: Support cookies


class StorageServiceBase {

  SIGNAL_JSON_ERROR = "jsonError";

  STORAGE_KEY_ACTIVE_CONSENT_ORDER_ID = "activeRemediationId";

  STORAGE_KEY_LAST_VISIBLE_ALERTS_COUNT = "lastSeenAlerts";

  getItem(name, isJson = true) {
    return this._get(name, window.localStorage, isJson);
  }

  getHasAnyItems() {
    return window.localStorage.length > 0;
  }

  setItem(name, value, isJson = true) {
    return this._set(name, value, window.localStorage, isJson);
  }

  removeItem(name) {
    window.localStorage.removeItem(name);
  }

  clear() {
    return window.localStorage.clear();
  }

  getSessionItem(name, isJson = true) {
    return this._get(name, window.sessionStorage, isJson);
  }

  getHasAnySessionItems() {
    return window.sessionStorage.length > 0;
  }

  setSessionItem(name, value, isJson = true) {
    return this._set(name, value, window.sessionStorage, isJson);
  }

  removeSessionItem(name) {
    window.sessionStorage.removeItem(name);
  }

  clearSession() {
    return window.sessionStorage.clear();
  }

  _get(name, storageModule, isJson = true) {
    const value = storageModule.getItem(name);
    if (isJson && value) {
      const parsed = this._parseJson(value);
      if (parsed === this.SIGNAL_JSON_ERROR) {
        return null;
      }
      return parsed;
    }
    return value;
  }

  _set(name, value, storageModule, isJson = true) {
    if (isJson && value) {
      const jsonValue = this._stringifyJson(value);
      if (jsonValue === this.SIGNAL_JSON_ERROR) {
        return false;
      }
      storageModule.setItem(name, jsonValue);
    } else {
      storageModule.setItem(name, value);
    }
    return true;
  }

  _parseJson(json) {
    try {
      return JSON.parse(json);
    } catch (error) {
      console.error("Failed to parse JSON in storage", error, json);
      return this.SIGNAL_JSON_ERROR;
    }
  }

  _stringifyJson(data) {
    try {
      return JSON.stringify(data);
    } catch (error) {
      console.error("Failed to stringify JSON for storage", error, data);
      return this.SIGNAL_JSON_ERROR;
    }
  }

}

const StorageService = new StorageServiceBase();
export default StorageService;
