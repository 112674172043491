import { FormLabel } from "@mui/material";
import CustomFormHelperText from "components/utils/form-elements/styled/formHelperText/customFormHelperText.component";
import FormContext from "contexts/form.context";
import { makeStyles } from "mui-styles";
import { useContext, useMemo } from "react";

const useStyles = makeStyles(theme => ({
  label: {
    marginBottom: 8,
    fontWeight: theme.typography.fontWeightBold
  }
}));

/*
 * Group of Checkbox items in a fieldset with common attributes.
 *
 * Form Validations involving the entire group (such as groupItemChecked)
 *   depend on the `name` prop to match validation objects' `group` attribute.
 */
export default function CheckboxGroup(props) {
  const {
    children,
    errorMessage: errorMessageProp,
    error,
    helperText,
    hideHelperText,
    label,
    name
  } = props;
  const classes = useStyles();
  const { errors } = useContext(FormContext);

  const errorMessage = useMemo(() => {
    if (!name) {
      throw new Error("`name` prop is requred.")
    }
    if (errorMessageProp) {
      return errorMessageProp;
    }
    const anyError = Object.values(errors || {}).find(formError => (
      formError.validation.group === name
    ));
    return anyError?.message;
  }, [errors, errorMessageProp, name]);

  return (
    <fieldset name={name}>
      {!!label && (
        <FormLabel component="legend" className={classes.label}>
          {label}
        </FormLabel>
      )}
      {children}
      {!hideHelperText && (
        <CustomFormHelperText
          error={!!errorMessage || !!error}
          variant="standard"
        >
          {errorMessage || helperText}
        </CustomFormHelperText>
      )}
    </fieldset>
  )
}