import { Box } from "@mui/material";
import { GridContainer } from "components/utils/grid/gridContainer.component";
import { GridItem } from "components/utils/grid/gridItem.component";
import TextFieldBase from "components/utils/form-elements/textField.component";
import { DATETIME_DISPLAY_NUMERIC_LONG } from "constants/date.constants";
import moment from "moment";
import { makeStyles } from "mui-styles";
import { useMemo } from "react";
import { isReadOnly } from "utils/roles.utils";
import classNames from "classnames";
import LabelValuePair from "components/utils/labelValuePair.component";
import CustomLink from "components/utils/link.component";

const useStyles = makeStyles((theme) => ({
  titleTextField: {
    width: "100%",
    maxWidth: 600,
    [theme.breakpoints.down("lg")]: {
      maxWidth: 432,
    }
  },
  titleTextInput: {
    paddingBottom: 0,
    color: theme.palette.primary.main,
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
  },
  fileNameSection: {
    fontWeight: theme.typography.fontWeightBold,
    "& $downloadFileIcon": {
      color: "inherit",
    },
    [theme.breakpoints.between("lg", 1400)]: {
      minWidth: "50%",
      maxWidth: "50%",
    }
  },
  unreadableFileName: {
    fontSize: theme.typography.body1.fontSize,
  },
  detailsSection: {
    [theme.breakpoints.between("lg", 1400)]: {
      minWidth: "50%",
      maxWidth: "50%",
    }
  },
  logLink: {
    fontSize: theme.typography.body2.fontSize,
  },
  fileDetails: {
    paddingTop: 10,
    [theme.breakpoints.down("lg")]: {
      paddingTop: 0,
    }
  },
  fileDetailsLine: {
    paddingTop: 0,
    paddingBottom: 0,
    fontWeight: theme.typography.fontWeightRegular
  },
  detailColumn: {
    [theme.breakpoints.between("lg", 1400)]: {
      minWidth: "100%"
    }
  },
}));


export default function FileTransferFileDetails(props) {
  const {
    fileTransfer,
    lastAccessedLogItems,
    onLogClick,
    onTitleAutosave,
  } = props;
  const classes = useStyles();

  const isReadOnlyUser = useMemo(isReadOnly, []);

  const uploadedDatetimeDisplay = useMemo(() => {
    if (!fileTransfer?.uploadedDatetime) {
      return null;
    }
    const datetimeMoment = moment(fileTransfer.uploadedDatetime);
    return `${datetimeMoment.format(DATETIME_DISPLAY_NUMERIC_LONG)} (GMT)`;
  }, [fileTransfer]);

  const lastAccessedDatetimeDisplay = useMemo(() => {
    if (!lastAccessedLogItems?.length) {
      return null;
    }
    const datetimeMoment = moment(lastAccessedLogItems[0].actionDatetime);
    return `${datetimeMoment.format(DATETIME_DISPLAY_NUMERIC_LONG)} (GMT)`;
  }, [lastAccessedLogItems]);

  return (
    <GridContainer spacing={6} paddingBottom={1}>
      <GridItem xs={12} lg={5} xl={6} className={classes.fileNameSection}>
        <Box marginRight={2} marginBottom={0.5}>
          <span>
            <TextFieldBase
              name="title"
              defaultValue={fileTransfer.title}
              className={classes.titleTextField}
              inputProps={{
                className: classes.titleTextInput
              }}
              placeholder="Title (optional)"
              disabled={isReadOnlyUser || fileTransfer._meta?.debug}
              variant="standard"
              onAutosave={onTitleAutosave}
            />
          </span>
          <div
            className={
              classNames(classes.linkText, classes.unreadableFileName)
            }
          >
            {fileTransfer?.fileName}
          </div>
          <LabelValuePair
            label="File Size"
            value={fileTransfer?.fileSize || <em>Unknown</em>}
            className={classes.fileDetailsLine}
          />
          <LabelValuePair
            label="Uploaded At"
            value={uploadedDatetimeDisplay || <em>Unknown</em>}
            className={classes.fileDetailsLine}
          />
        </Box>
      </GridItem>
      <GridItem xs={12} md={6} lg={7} xl={6} className={classes.detailsSection}>
        <GridContainer className={classes.fileDetails}>
          <GridItem xs={12} lg={6} xl={12} className={classes.detailColumn}>
            <LabelValuePair
              label="Transfer Type"
              value={
                fileTransfer?.type === "outgoing" ? "Outgoing" : "Incoming"
              }
              className={classes.fileDetailsLine}
            />
          </GridItem>
          <GridItem xs={12} lg={6} xl={12} className={classes.detailColumn}>
            <LabelValuePair
              label="Last Accessed At"
              value={lastAccessedDatetimeDisplay || "N/A"}
              className={classes.fileDetailsLine}
            />
          </GridItem>
          <GridItem xs={12} lg={6} xl={12} className={classes.detailColumn}>
            <LabelValuePair
              label="Last Accessed By"
              value={lastAccessedLogItems?.[0]?.actionUser || "N/A"}
              className={classes.fileDetailsLine}
            />
          </GridItem>
          <GridItem
            xs={12}
            lg={6}
            xl={12}
            className={classes.detailColumn}
          >
            <LabelValuePair
              label="Times Accessed"
              value={(
                <span>
                  <Box display="inline">
                    {lastAccessedLogItems?.length || 0}&nbsp;
                  </Box>
                  {!!lastAccessedLogItems?.length && (
                    <CustomLink
                      className={classes.logLink}
                      onClick={onLogClick}
                      variant="underline"
                    >
                      View Access Log
                    </CustomLink>
                  )}
                </span>
              )}
              className={classes.fileDetailsLine}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  )
}