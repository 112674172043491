import React from "react";
import { makeStyles } from "mui-styles";
import CssBaseline from "@mui/material/CssBaseline";
import MainHeader from "components/layout/main/header.component"
import MainMenu from "components/layout/dynamic/mainMenu.component"
import GlobalFormSandbox from "components/shared/forms/globalFormSandbox.component";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    marginTop: theme.layout.height.header,
    marginLeft: theme.layout.width.mainMenuXl,
    paddingTop: 32,
    paddingLeft: 24,
    [theme.breakpoints.down("lg")]: {
      marginLeft: theme.layout.width.mainMenu,
      paddingLeft: 16,
    }
  }
}));

const Layout = (props) => {
  const classes = useStyles();
  const { children } = props;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <MainHeader />
      <MainMenu />
      <GlobalFormSandbox />
      <div className={classes.content}>
        {children}
      </div>
    </div>
  );
}

export default Layout;
