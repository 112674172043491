import React from "react";

export const initialContext = {
  workflowTemplate: {},
  eventChain: [],
  nodeChain: [],
  eventAlternativeSet: new Set(),
  nodeOrphanSet: new Set(),
};

/*
 * Workflow template data prepared for display in a flowchart diagram.
 * To be used with WorkflowDiagramProvider.component.jsx
 *
 * Example data:
 * {
 *   eventChain: Ordered array of WFEventFlow items according to the default flow
 *   nodeChain: Ordered array of WFNodes according to the default flow
 *   eventAlternativeSet: Set of any WFEventFlow items outside of the default flow
 *   nodeOrphanSet: Set of any WFNodes disconnected from the default flow
 *   workflowTemplate: Current WFTemplate for diagram
 * }
 */
const WorkflowDiagramContext = React.createContext(initialContext);

export default WorkflowDiagramContext;
