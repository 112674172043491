import AjaxService from "services/base/Ajax.service";


class ClaimServiceBase extends AjaxService {

  getClaimsBySearch(
    abortController, searchQuery = null, filters = null
  ) {
    let url = `/claim/search`;
    let connectorCharacter = "?";
    if (searchQuery) {
      url = `${url}${connectorCharacter}query=${searchQuery}`;
      connectorCharacter = "&";
    }
    if (filters) {
      url = `${url}${connectorCharacter}filters=${filters}`;
      connectorCharacter = "&";
    }
    const requestConfig = { cancelUnfinishedRequests: true };
    return this.getCancellable(
      url,
      abortController,
      requestConfig
    ).then(response => response.data);
  }

  getClaimValueTypes() {
    return this
      .get("/claim/claimValueTypes")
      .then(response => response.data);
  }

  getApplicableClaimValueTypes() {
    return this
      .get("/claim/claimValueTypes/applicable")
      .then((response) => {
        return response.data;
      })
  }

  getClaimsByCifId(cifId) {
    return this.get(`/claim/${cifId}`).then(response => response.data);
  }
}

const ClaimService = new ClaimServiceBase();
export default ClaimService;