import React from "react";
import Link from "components/utils/link.component";
import { makeStyles } from "mui-styles";
import classNames from "classnames";
import Loader from "components/utils/loader.components";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    paddingBottom: 3,
    paddingTop: 3,
  },
  infoLabel: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
  },
  valueWrapper: {
    display: "flex",
  },
  loadingWrapper: {
    marginTop: -2,
    marginBottom: -2
  },
  stackedRow: {
    flexDirection: "column"
  },
  stackedValueWrapper: {
    marginLeft: 0,
  }
}));

const LabelValuePair = (props) => {
  const classes = useStyles();
  const {
    className, label, value, linkPath, onClick,
    emptyValue = "--", isLoading = false, stacked = false
  } = props;
  const displayValue = (value || value === 0) ? value : emptyValue;
  return (
    <div
      className={
        classNames(
          classes.row,
          className,
          stacked && (
            classes.stackedRow
          )
        )
      }
    >
      <span className={classes.infoLabel}>{label}:&nbsp;</span>
      {isLoading ? (
        <div
          className={
            classNames(classes.valueWrapper, classes.loadingWrapper)
          }
        >
          <Loader size={14} inline noPadding />
        </div>
      ) : (
        <>
          {!!linkPath && (
            <Link
              variant="routerLink"
              className={classNames(classes.valueWrapper, classes.link)}
              to={linkPath}
            >
              {displayValue}
            </Link>
          )}
          {!!onClick && (
            <Link
              variant="noHref"
              className={classNames(classes.valueWrapper, classes.link)}
              onClick={onClick}
            >
              {displayValue}
            </Link>
          )}
          {(!linkPath && !onClick) && (
            <span
              className={
                classNames(
                  classes.valueWrapper,
                  stacked && (
                    classes.stackedValueWrapper
                  )
                )
              }
            >
              {displayValue}
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default LabelValuePair;
