import AjaxService from "services/base/Ajax.service.js";

class DistributionDatasetServiceBase extends AjaxService {
  shouldExtractResponseData() {
    return true;
  }

  async searchDistributionDatasets(query, limit, pageIndex) {
    return this.get(
      `/distributionDataset/limit/${limit}/pageIndex/${pageIndex}/search?query=${query}`,
      { cancelUnfinishedRequests: true }
    )
  }

  async searchDistributionDatasetsWithClaim(query) {
    return this.get(
      `/distributionDataset/matches/claimId/search?query=${query}`,
      { cancelUnfinishedRequests: true }
    )
  }

  async getPopulationStatsForDistributions(abortController) {
    return this.getCancellable(
      `/distributionDataset/populationCounts`,
      abortController,
      { preventDuplicateRequests: true }
    );
  }

  async filterAndSearchDistributionsByNodeIds(
    currentNodeIds, query, abortController,
    limit, pageIndex
  ) {
    return this
      .postCancellable(
        `/distributionDataset/limit/${limit}/pageIndex/${pageIndex}/matches/search?query=${query}`,
        { currentNodeIds },
        abortController,
        { cancelUnfinishedRequests: true }
      )
  }
}

const DistributionDatasetService = new DistributionDatasetServiceBase();
export default DistributionDatasetService;