import { Box, Paper } from "@mui/material";
import { H3 } from "components/utils/headerV2.component";
import classNames from "classnames";
import { makeStyles } from "mui-styles";
import LinearFlowchart from "components/shared/flowchart/LinearFlowchart.component";
import FlowchartStatusItem from "components/shared/flowchart/shared/FlowchartStatusItem.component";
import { useCallback, useContext, useMemo, useRef } from "react";
import WorkflowDiagramContext from "contexts/workflowDiagram.context";
import { makeDisconnectedItems, makeWorkflowTimeline } from "components/utils/workflows/workflow.utils";
import Loader from "components/utils/loader.components";
import WorkflowService from "services/Workflow.service";
import { ACTION_REPLACE_WORKFLOW_INSTANCES } from "reducers/shared/claimReview.reducer";
import { isDevelopmentEnv } from "core/environment";
import ClaimsWorkflowAction from "components/shared/flowchart/ClaimsWorkflowAction.component";
import StatisticsContext from "contexts/statistics.context";
import SummaryCountService from "services/SummaryCount.service";
import { ACTION_SET_SUMMARY_COUNT } from "reducers/global/statistics.reducer";

const useStyles = makeStyles(theme => ({
  workflowWrapper: {
    boxShadow: theme.shadow.inset2,
  }
}));

const ClaimReviewWorkflowDiagram = (props) => {
  const {
    claim,
    claimWorkflowInstance,
    dispatch,
    workflowTemplatesById
  } = props;
  const classes = useStyles();

  const { dispatch: statisticsDispatch } = useContext(StatisticsContext);
  const {
    nodeChain,
    eventFlowsByReferenceNodeId,
    nodeOrphanSet
  } = useContext(WorkflowDiagramContext);

  const summaryRequestAbortRef = useRef();

  const eventChainOptions = eventFlowsByReferenceNodeId?.[
    claimWorkflowInstance.currentNodeId
  ] || []

  const workflowTimeline = useMemo(() => {
    if (!claimWorkflowInstance || !nodeChain.length) {
      return []
    }
    return makeWorkflowTimeline(nodeChain, claimWorkflowInstance)
  }, [nodeChain, claimWorkflowInstance])

  const disconnectedItems = useMemo(() => (
    makeDisconnectedItems(nodeOrphanSet, claimWorkflowInstance)
  ), [nodeOrphanSet, claimWorkflowInstance]);

  const workflowName = useMemo(() => {
    return workflowTemplatesById[
      claimWorkflowInstance.wfTemplateId
    ]?.templateName
  }, [workflowTemplatesById, claimWorkflowInstance])

  const moveWfInstanceToNextWFNode = useCallback(async (wfOptionId) => {
    try {
      const result = await WorkflowService.moveWFInstanceToNextWFNode(
        claimWorkflowInstance.wfInstanceId,
        wfOptionId
      )
      dispatch({
        type: ACTION_REPLACE_WORKFLOW_INSTANCES,
        payload: result.payload,
      });
    } catch (error) {
      if (isDevelopmentEnv) {
        console.error(error)
      }
    } finally {
      summaryRequestAbortRef.current = new AbortController();
      const statsResponse = await SummaryCountService.getAll(
        summaryRequestAbortRef.current
      );
      statisticsDispatch({
        type: ACTION_SET_SUMMARY_COUNT,
        payload: statsResponse.payload
      });
    }
  }, [claimWorkflowInstance.wfInstanceId, dispatch, statisticsDispatch])

  if (!workflowTimeline || !eventChainOptions) {
    return <Loader inline />
  }
  if (workflowTimeline.length === 0) {
    return (
      <p>No workflow found for current Claim</p>
    )
  }
  return (
    <>
      <Paper
        className={classNames(classes.mockData, classes.workflowWrapper)}
      >
        <Box padding="36px 36px 20px">
          <H3>
            {workflowName}
          </H3>
        </Box>
        <LinearFlowchart
          items={workflowTimeline}
          ItemComponent={FlowchartStatusItem}
          disconnectedItems={disconnectedItems}
          eventChainOptions={eventChainOptions}
          moveWfInstanceToNextWFNode={moveWfInstanceToNextWFNode}
        />
      </Paper>
      <ClaimsWorkflowAction
        claim={claim}
        dispatch={dispatch}
        eventChainOptions={eventChainOptions}
        moveWfInstanceToNextWFNode={moveWfInstanceToNextWFNode}
      />
    </>
  )
}

export default ClaimReviewWorkflowDiagram;